import React from 'react';

import DataManagementRoleModal from '../DataManagementRoleModal';

import {
  listGlobalDataManagementRoles,
  createGlobalDataManagementRole,
  updateGlobalDataManagementRole,
  deleteGlobalDataManagementRole,
} from '../../services/api';


function GlobalDataManagementRoleModal({ open, onClose, }) {
  async function onFetch() {
    const response = await listGlobalDataManagementRoles();
    return response.data.options;
  }

  async function onDelete(id) {
    return await deleteGlobalDataManagementRole(id);
  }

  async function onUpdate(data) {
    const response = await updateGlobalDataManagementRole(data.id, data);
    return response.data
  }

  async function onCreate(data) {
    const response = await createGlobalDataManagementRole(data);
    return response.data;
  }


  return (
    <DataManagementRoleModal
      open={open}
      onFetch={onFetch}
      onDelete={onDelete}
      onUpdate={onUpdate}
      onCreate={onCreate}
      onClose={onClose}
    />
  );
}


export default GlobalDataManagementRoleModal;
