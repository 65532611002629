import React from 'react';
import { useTranslation, } from 'react-i18next';

import { Divider, TextField, Typography, } from '@material-ui/core';

import FileUploadInput from '../FileUploadInput';

import FileUploaderImg from '../../assets/Vector.svg';

import { WidgetType, } from '../../entities/impact-report-widget';


export default function ImageWidget({ data, readOnly, onChange, }) {
  const { t, } = useTranslation();


  function handleChange(ev) {
    onChange({
      ...data,
      model: {
        ...data.model,
        [ev.target.name]: ev.target.value,
      },
    });
  }

  /**
   * @param {File} imageFile
   */
  function onChangeImage(imageFile) {
    const reader = new FileReader();
    reader.onloadend = () => {
      handleChange({
        target: {
          name: 'image',
          value: reader.result,
        },
      });
    }
    reader.readAsDataURL(imageFile);
  }


  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {
          readOnly ?
          <img
            src={data.model.image}
            style={{
              maxWidth: '100%',
            }}
          /> :
          <FileUploadInput
            id={`file-input-${data.id}`}
            onChange={onChangeImage}
            maxFileSize={1048576} // 1MB
          >
            <img
              src={data.model.image || FileUploaderImg}
              style={{
                maxWidth: '100%',
              }}
            />

            <p>
              {
                data.model.image ?
                t(`PAGES.IMPACT_REPORTS.v2.WIDGETS.${WidgetType.IMAGE}.change_image_label`) :
                t(`PAGES.IMPACT_REPORTS.v2.WIDGETS.${WidgetType.IMAGE}.upload_image_label`)
              }
            </p>
            {t(`PAGES.IMPACT_REPORTS.v2.WIDGETS.${WidgetType.IMAGE}.restrictions_warning`)}
          </FileUploadInput>

        }
      </div>

      <Divider
        style={{
          margin: '10px 0px',
        }}
      />

      <Typography
        style={{
          fontWeight: 500,
        }}
      >
        Legenda:
      </Typography>
      {
        readOnly ?
        <Typography
          display="block"
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          {data.model.legend}
        </Typography> :
        <TextField
          multiline
          fullWidth
          value={data.model.legend || ''}
          variant="outlined"
          name="legend"
          size="small"
          onChange={handleChange}
        />
      }
    </>
  );
}
