import { memo, } from 'react';
import { Chip, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { COURSE_LEVELS, } from '../../utils/constants';


const BASIC_COLOR = COURSE_LEVELS.BASIC.color;

const useStyles = makeStyles({
  chip: {
    backgroundColor: (props) => props.backgroundColor,
    borderRadius: 2,
    fontWeight: 500,
    color: 'white',
  },
});


function CourseLevelChip({ level, ...props }) {
  const levelInfo = COURSE_LEVELS[`${level}`];

  const classes = useStyles({
    backgroundColor: levelInfo ? levelInfo.color : BASIC_COLOR,
  });


  return (
    <Chip
      className={classes.chip}
      size="small"
      label={ levelInfo ? levelInfo.label : '' }
      { ...props }
    />
  );
}

export default memo(CourseLevelChip);
