export const ACTION_STATUSES = Object.freeze({
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  CLOSED: 'CLOSED',
});

export const ACTION_STATUS_COLOR = Object.freeze({
  [ACTION_STATUSES.PENDING]: '#98A4D0',
  [ACTION_STATUSES.IN_PROGRESS]: '#FFB54D',
  [ACTION_STATUSES.CLOSED]: '#31D9A6',
});
