import {
  getCompanyProgress,
  getPerguntasByEmpresa,
  getPerguntasPorRespostaSemCalc,
} from '../../services/api';
import 'rsuite/dist/styles/rsuite-default.css';
import './styles.css';
import { selectCompany, } from '../../features/companySlice';

import { useState, useEffect, memo, } from 'react';
import { useSelector, } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt-br';

import { Grid, Typography, Container, Paper,  } from '@material-ui/core';
import { makeStyles, } from '@material-ui/styles';
import { Progress, } from 'rsuite';
const { Line, } = Progress;




const useStyles = makeStyles({
  paragraph: {
    color: '#3B66FF',
    fontWeight: 'bold',
    fontFamily: 'Inter',
  },
  subparagraph: {
    color: '#54708C',
    fontWeight: 'normal',
  },
});


function ProgressBarCustom({ disableElevation, }) {
  const classes = useStyles();
  const statusColor = '#3B66FF';
  const company = useSelector(selectCompany);
  const [response, setResponse] = useState([]);
  const [responseanswered, setResponseanswered] = useState([]);
  const [companyProgress, setCompanyProgress] = useState({
    progress: 0,
    maturity: 0,
  });

  moment.locale('pt-br');


  async function getData() {
    try {
      const [
        { data: response, },
        { data: answered, },
        { data: companyProgress, },
      ] = await Promise.all([
        getPerguntasByEmpresa(),
        getPerguntasPorRespostaSemCalc(1),
        getCompanyProgress(company._id),
      ]);

      setResponse(response);
      setResponseanswered(answered);
      setCompanyProgress(companyProgress);
    } catch {
      console.log('Failed to load info');
    }
  }

  useEffect(() => {
    getData();
  }, []);


  return (
    <Container
      component={Paper}
      maxWidth="xl"
      style={{
        padding: '0.8rem',
        display: 'flex',
        flexGrow: 1,
      }}
      elevation={disableElevation ? 0 : undefined}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
      >
        <Grid
          style={{
            marginBottom: '0.4rem',
          }}
          item
        >
          <Typography
            variant="subtitle1"
            style={{
              color: '#54708C',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: 14,
            }}
          >
            Tarefas respondidas
          </Typography>
          <Typography
            style={{
              color: '#1C265A',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: 24,
            }}
            variant="h4"
          >
            <strong
              style={{
                color: '#3B66FF',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 24,
              }}
            >
              {responseanswered.length}
            </strong>{' '}
            de {response.length}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle1"
            style={{
              color: '#54708C',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: 14,
            }}
          >
            Tempo já decorrido
          </Typography>
          <Typography
            style={{
              color: '#1C265A',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: 24,
            }}
            variant="h4"
          >
            {moment(company.createdAt).fromNow(true)}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            marginTop: '0.5rem',
          }}
        >
          <p className={classes.paragraph}>
            Progresso{' '}
            <span className={classes.subparagraph}>
              (Orientações dadas)
            </span>
          </p>
          <Line
            percent={companyProgress.progress}
            strokeColor={statusColor}
          />

          <p className={classes.paragraph}>
            Aderência{' '}
            <span className={classes.subparagraph}>
              (Atitudes tomadas pela empresa)
            </span>
          </p>
          <Line
            percent={companyProgress.maturity}
            strokeColor={statusColor}
          />
        </Grid>
      </Grid>
    </Container>
  );
}


export default memo(ProgressBarCustom);
