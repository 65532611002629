import React from 'react';
import { withStyles, makeStyles, } from "@material-ui/styles";
import _ from 'lodash';

import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Paginator from '../../components/Paginator/Paginator';

import DeleteIcon from '@material-ui/icons/Delete';

import CATEGORIES_ICONS from './cookies-categories-icons';


//? Components used to create a table with loading backdrop
const LimitedBackdrop = withStyles({
  root: {
    position: 'absolute',
    zIndex: 10,
  }
})(Backdrop);

const StyledTableContainer = withStyles({
  root: {
    position: 'relative',
    marginBottom: 10,
  }
})(TableContainer);


const useStyles = makeStyles(() => ({
  chip: {
    margin: 2,
    backgroundColor: '#3B66FF40',
  },
}));


/**
 * Table to categorize cookies
 *
 * @param {string} popup ID - company popup ID
 *
 * @returns Rendered component
 */
function CookieClassificationTable({
  popupId,
  cookies,
  categories,
  onUpdate,
  onDelete,
  loading,
  showError,
}) {
  const classes = useStyles();
  const totalCookies = cookies.length;
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);


  /**
   * Handle page change
   *
   * @param {object} event - event that triggered the change
   * @param {number} newPage - new page
   */
  const onPageChange = (event, newPage) => {
    setPage(newPage);
  }

  /**
   * Handle change in page size
   *
   * @param {object} event - event thar triggered the change
   */
  const onPageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  }

  /**
   * Use effect to get cookies
   */
  React.useEffect(() => {
    setPage(0);
  }, [popupId]);


  return (
    <Container
      maxWidth="xl"
      disableGutters
    >
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Categoria</TableCell>
              <TableCell align="center">Identificado em</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              cookies.slice(page * pageSize, page * pageSize + pageSize).map((cookie) => {
                const category = _.isString(cookie.category) ?
                  categories.find(({ name, }) => cookie.category === name) : cookie.category;

                return (
                  <TableRow key={cookie.id}>
                    <TableCell align="center">
                      {cookie.name}
                    </TableCell>
                    <TableCell align="center">
                      <Select
                        style={{
                          width: '100%',
                          maxWidth: 300
                        }}
                        value={category}
                        onChange={(event) => onUpdate(cookie, event.target.value)}
                        input={<Input id={`select-cookie-${cookie.id}-category`} disableUnderline />}
                        renderValue={(selectedCategory) => {
                          const CookieCategoryIcon = CATEGORIES_ICONS[selectedCategory.name];
                          return (
                            <Chip
                              avatar={CookieCategoryIcon != undefined ? <CookieCategoryIcon /> : null}
                              label={selectedCategory.data.namePt}
                              className={classes.chip}
                            />
                          );
                        }}
                      >
                        {
                          categories.map((category) => {
                            const CategoryIcon = CATEGORIES_ICONS[category.name];

                            return (
                              <MenuItem
                                key={category.id}
                                value={category}
                              >
                                <CategoryIcon
                                  style={{
                                    marginRight: 10,
                                  }}
                                />
                                {category.data.namePt}
                              </MenuItem>
                            );
                          })
                        }
                      </Select>
                    </TableCell>
                    <TableCell align="center">
                      {moment(cookie.created_at).format('DD/MM/YYYY, HH:MM:ss')}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => onDelete(cookie)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
        <LimitedBackdrop open={loading && (cookies.length > 0)}>
          <CircularProgress />
        </LimitedBackdrop>
      </StyledTableContainer>

      {
        showError || loading || (cookies.length === 0) ?
        <Grid
          container
          justifyContent="center"
          fullWidth
          style={{
            marginTop: 10,
          }}
        >
          { loading && (cookies.length === 0) ? <CircularProgress /> : null }
          {
            !loading && showError ?
            <Typography
              variant="body1"
              align="center"
              display="block"
              color="error"
            >
              Falhar ao carregar cookies identificados
            </Typography> :
            null
          }
          {
            !loading && !showError && (cookies.length === 0) ?
            <Typography
              variant="body1"
              align="center"
              display="block"
              style={{
                color: '#808080',
              }}
            >
              Ainda não identificamos nenhum cookie no seu site
            </Typography> :
            null
          }
        </Grid> :
        null
      }
      <TablePagination
        labelRowsPerPage={`Linhas por página`}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        rowsPerPageOptions={[5, 10, 15, 20, 25]}
        component="div"
        rowsPerPage={pageSize}
        page={page}
        count={totalCookies}
        onPageChange={onPageChange}
        onRowsPerPageChange={onPageSizeChange}
        ActionsComponent={() => <Paginator page={page + 1} rowsPerPage={pageSize} count={totalCookies} onChange={onPageSizeChange} />}
      />
    </Container>
  )
}

export default React.memo(CookieClassificationTable);
