import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';

import { Grid, TablePagination, Button, Badge, Container, } from '@material-ui/core';

import ActionButton from './ActionButton';
import Header from '../../components/CorpoResposta/Header';
import ShowPerguntas from '../../components/CorpoResposta';

import {
  setItens,
  loadingFalse,
} from '../../features/responderPerguntas'
import Loading from '../../components/Global/Loading'
import Label from '../../components/Global/Label'
import MaturityChecklistShareLinkDialog from '../../components/MaturityChecklistShareLinkDialog/index.jsx';
import { getPerguntasOrdem } from '../../services/api'

import ToastAnimated from '../../ui-lib/toast'
import './styles.css'

import {
  listShareLinks,
  selectShareLinks,
} from '../../features/maturityChecklistShareLinksSlice.js';
import { listUsers, } from '../../features/usersSlice';
import useModal from '../../hooks/useModal.jsx';
import UpdateTasks from '../../components/Modals/UpdateTasks/UpdateTasks.jsx';
import Paginator from '../../components/Paginator/Paginator.jsx';
import CustomMenuFixed from '../../components/CustomMenuFixed/index.jsx';
import { setMenuLeftName, setGlobalMenuFixed } from '../../features/fixedMenuPages.js';
import DiagnosticMenuFixed from '../../components/CustomMenuFixed/DiagnosticMenuFixed.jsx';

function MaturityChecklist() {
  const { t, } = useTranslation();
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const body = useSelector((state) => state.ResponderPerguntas.value);
  const loading = useSelector((state) => state.ResponderPerguntas.loading);
  const shareLinks = useSelector(selectShareLinks);
  const [shareLinksModalOpen, openShareLinksModal, closeShareLinksModal] = useModal();


  const getPerguntas = async () => {
    await getPerguntasOrdem().then(({ data }) => {
      dispatch(setItens(data))
      dispatch(loadingFalse())
    })
  }

  useEffect(() => {
    getPerguntas();
    dispatch(listShareLinks());
    dispatch(listUsers());
    dispatch(setGlobalMenuFixed(false))
    return ()  => {
      dispatch(setGlobalMenuFixed(true))
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }


  return (
    <>
      <Container
        maxWidth="xl"
        disableGutters
        style={{
          marginBottom: '3rem',
        }}
      >
        <ToastAnimated />
        <Header showTitle={false} />
        <br />
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Label
            description={_.capitalize(t('PAGES.MATURITY_CHECKLIST.all_questions'))}

            fontSize={18}
          />

          <Badge
            badgeContent={shareLinks.length}
            color="error"
          >
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={openShareLinksModal}
            >
              {t('PAGES.MATURITY_CHECKLIST_SHARE_LINKS.share_links')}
            </Button>
          </Badge>
        </Grid>
        <br />

        {
          loading ?
            <Loading value={loading} /> :
            body.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((body) => (
              <ShowPerguntas
                key={body.id}
                data={body}
              />
            ))
        }

        <TablePagination
          rowsPerPageOptions={[
            5,
            10,
            25,
            { label: 'Todos', value: body.length },
          ]}
          component="div"
          SelectProps={{
            inputProps: { 'aria-label': 'Linhas' },
            native: true,
          }}
          labelRowsPerPage={`Linhas por página`}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          count={body.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={() => <Paginator page={page + 1} rowsPerPage={rowsPerPage} count={body.length} onChange={handleChangePage} />}
        />
      </Container>

      <MaturityChecklistShareLinkDialog
        open={shareLinksModalOpen}
        onClose={closeShareLinksModal}
      />
      <DiagnosticMenuFixed />
      <UpdateTasks />
    </>
  );
}

export default MaturityChecklist;
