import styles from "../../components/MapaDados/PersonalizedReport/style.module.scss";
import {Icon} from "rsuite";
import Dialog from "../../components/Dialog";
import ReportCover1 from '../../assets/report-cover-1.png';


export default function ReportDownloadDialog({open, onClose}) {

  return <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="sm"
  >
    <div className={styles.headerModal}>
      <h3>Selecione uma Capa de Relatório</h3>
      <Icon icon="close" onClick={onClose}/>
    </div>

    <div className="mt-3 d-flex">
      <div className="p-2" style={{border: 'var(--primary-color) solid 1px', borderRadius: 5, cursor: 'pointer'}} onClick={() => onClose('cover-1')}>
        <img src={ReportCover1} alt="Capa 1" style={{maxHeight: 200}}/>
      </div>
    </div>
  </Dialog>
}
