import React from 'react';

import TypeOptionsModal from '../TypeOptionsModal';


/**
* @param {boolean} props.open
* @param {() => Promise<Object[]>} props.onFetch - fetch callback
* @param {(optionId: string | number) => Promise<void>} props.onDelete - delete callback
* @param {(optionData: Object) => Promise<boolean>} props.onUpdate - update callback
* @param {(optionData: Object) => Promise<boolean>} props.onCreate - create callback
* @param {() => void} props.onClose - close modal callback
*/
function DataProcessingTypeModal(props) {
  return (
    <TypeOptionsModal
      {...props}
      type="DATA_PROCESSING_TYPE_MANAGEMENT"
    />
  )
}

export default DataProcessingTypeModal;
