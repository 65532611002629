import useBaseDataMappingPanel from "../../hooks/useBaseDataMappingPanel";
import DataMappingAnswerPanelContext from "../DataMappingAnswerPanel/context";
import DataMappingAnswerPanel from "../DataMappingAnswerPanel";
import React, {useMemo} from "react";
import Dialog from "../Dialog";
import {Typography} from "@mui/material";


/**
 *
 * @param items uuid of the line
 * @param pendencies {{mappingAnswers: {uid: string}[], questions: {problema: string}[]}}
 * @param onClose
 * @returns {Element}
 * @constructor
 */
export default function CompanySectorMappingDialog({ open = false, pendency = null, onClose = () => ({})}) {
  const base = useBaseDataMappingPanel();

  const mappingUuids = useMemo(() => pendency?.mappingAnswers.map((d) => d.uid) || [], [pendency]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='lg' title="Existem algumas pendências a serem resolvidas antes de remover o setor" >
      { mappingUuids.length > 0 &&
        <>
        <Typography>Pendências no Mapeamento</Typography>
        <DataMappingAnswerPanelContext.Provider value={{...base, answers: base.answers.filter((a) => mappingUuids.includes(a.uid)), allowCreation: false,}}>
          <DataMappingAnswerPanel/>
        </DataMappingAnswerPanelContext.Provider>
      </>
      }
      {
        pendency?.questions.length > 0 && <>
          <Typography>Pendências nas Tarefas do Diagnostico</Typography>
          {
            pendency?.questions.map((q, index) => (
              <Typography key={index} sx={{pl: 2, pb: 2}}>{q.problema}</Typography>
            ))
          }
        </>
      }
    </Dialog>
  );
}
