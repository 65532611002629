import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation, } from 'react-i18next';
import { useSelector, useDispatch, } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';


import { Grid, Typography, AppBar, Tabs, Tab, IconButton, Button } from '@material-ui/core'
import { Form } from 'react-bootstrap'
import { Notification, } from 'rsuite';

import Label from '../Global/Label'
import Container from '../Global/ContainerWithPaper'
import CircularProgressWithLabel from '../Global/CircularProgressWithLabel'
import SearshComponent from '../Global/LupaSearch'
import FilesHandler from '../FilesComponent'
import ShareLinkModal from './ShareDocumentModal';

import { setDocs } from '../../features/docsSlice'
import { deleteDocument, downloadFile, getDocs, getFileVisualizationLink, postDocs } from '../../services/api'

import useModal from '../../hooks/useModal';

import { errorCodes } from '../../errors/standard-error';

import './MonitoraStyles.css'
import validateFile from '../utils/validate-file';
import useFoldersGet from "../../hooks/useCases/Documents/useFoldersGet";
import { selectCompany } from "../../features/companySlice";
import useToggle from "../../hooks/useToggle";
import { TextField } from "@mui/material";
import Dialog from "../Dialog";
import CreateFolderDialog from "./CreateFolderDialog";
import DefaultTable from '../DefaultTable/DefaultTable';
import moment from 'moment';
import { Delete, Share, GetApp, RemoveRedEye } from '@material-ui/icons'
import fileDownload from 'js-file-download';
import DocumentViewer from '../DocumentViewer';
import DefaultModal from '../Modals/DefaultModal/DefaultModal';
import TypeTable from '../DefaultTable/componentsTable/TypeTable';


/**
 * Component used to show company documents
 *
 * @returns Rendered component
 */
export default function Docs() {
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const dispatch = useDispatch();
  const body = useSelector((state) => state.docs.value)
  const [shareModalOpen, openShareModal, closeShareModal] = useModal(selectDocument, () => selectDocument());
  const [viewerOpen, openViewerModal, closeViewerModal] = useModal();
  const [deleteModal, openDeletModal, closeDeletModal] = useModal();
  const [folderIdx, setFolderIdx] = useState();
  const [file, setFile] = useState('');
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [clickSearsh, setClickSearsh] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [objectDocument, setObjectDocument] = useState(null);
  const [openFolderDialog, toggleFolderDialog] = useToggle(false);
  const [search, setSearch] = useState('');
  const [fileLink, setFileLink] = useState(null);
  const [typeShowItens, setTypeShowItens] = useState('LIST');

  const { folders, add: addFolder, addFile, reload } = useFoldersGet(company);

  const folder = [...folders].reverse()[folderIdx];

  function selectDocument(documentId) {
    setSelectedDocument(documentId);
  }

  /**
   * Get docs and update the store
   */
  async function updateDocs() {
    try {
      const { data, } = await getDocs();
      dispatch(setDocs(data));
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar documentos!',
      });
    }
  }

  /**
   * Send user docs to be stored at the cloud
   */
  async function handleSendDoc() {
    const form = new FormData();
    form.append('doc', file);

    try {
      setOpen(true);

      const newFile = await postDocs(form, folder, {
        onUploadProgress: (progressEvent) => {
          setOpen(true);
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setProgress(percent);
        },
      });

      await reload();

      Notification['success']({
        placement: 'bottomEnd',
        title: t('PAGES.DOCUMENT_MANAGEMENT.document_successfully_sent'),
      });
    } catch (err) {
      if ((undefined != err.response) && (undefined != err.response.data) && (
        errorCodes.INVALID_FILE_ERROR == err.response.data.code)) {
        Notification['error']({
          placement: 'bottomEnd',
          title: t('ERRORS.ERROR_CODES.INVALID_FILE_ERROR'),
          description: t('PAGES.DOCUMENT_MANAGEMENT.send_valid_document'),
        });
      } else {
        Notification['error']({
          placement: 'bottomEnd',
          title: t('ERRORS.DOCUMENT_MANAGEMENT.failed_to_send_document'),
          description: t('ERRORS.try_again_later'),
        });
      }
    }

    setOpen(false);
    setProgress(0);
  }

  /**
   * Handle click in the close button in the upload dialog
   */
  function onRequestClose() {
    setOpen(false);
  }

  const onSelectedDocumentChanged = (e) => {
    const file = e.target.files[0];
    const name = file.name.toLowerCase();

    const invalidExtensions = ['.exe', '.js'];
    const isInvalid = invalidExtensions.some(extension => name.endsWith(extension));

    if (isInvalid) {
      Notification['warning']({
        placement: 'bottomEnd',
        title: 'Arquivo inválido!',
        description: 'Tipo de arquivo não permitido!',
      });
      e.target.value = '';
      return
    }

    if (validateFile(file)) {
      setFile(file);
      setOpen(true);
    }
  }

  function handlerFilterInputSearch(array) {
    return array.filter((docs) => !docs.workshop)
      .filter((doc) => search ? doc.name_doc.includes(search) : true)
  }

  const FormatDataComponent = ({ row, column, field }) => {
    const formattedData = useMemo(() => {
      return moment(row[field]).format('DD/MM/YYYY HH:mm');
    }, [row, field]);
    return <div>{formattedData}</div>;
  };


  function handlerOpenModalSearch(id) {
    setSelectedDocument(id);
    openShareModal(id);
  }

  async function getArchive(data) {
    try {
      const response = await downloadFile(company._id, data.id);
      fileDownload(response.data, data.orginalname);
      Notification['success']({
        placement: 'bottomEnd',
        title: 'Arquivo baixado com sucesso!',
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao baixar arquivo, tente novamente dentro de alguns instantes',
      });
    }
  }

  async function __openViewer(data) {
    setObjectDocument(data);
    try {
      if (!fileLink?.length) {
        let link = `https://s3.sa-east-1.amazonaws.com/octo.legal.images/${data.name_doc}`;

        if (!data.workshop) {
          const { data: resp, } = await getFileVisualizationLink(company._id, data.id);
          link = resp.fileLink;
        }

        setFileLink(link);
      }
      openViewerModal();
    } catch (error) {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar arquivo, tente novamente dentro de alguns instantes',
      });
    }
  }

  function handlerCloseOpenViewerModal() {
    setFileLink(null);
    setObjectDocument(null);
    closeViewerModal();
  }


  function deleteFile(data) {
    deleteDocument(company._id, data.id)
      .then(() => {
        updateDocs()
        if (window.location.pathname === '/Workshop')
          return window.location.reload()
      })
      .catch(() => {
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Falha ao excluir arquivo, tente novamente dentro de alguns instantes',
        });
      })
  }

  function handlerOpenModalDelete(row) {
    setObjectDocument(row);
    openDeletModal();

  }


  function handlerCloseModalDelete() {
    closeDeletModal()
  }

  const Actions = ({ row, column, field }) => {
    const id = row[field];

    const actionButtons = useMemo(() => (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <IconButton onClick={() => getArchive(row)}>
          <GetApp />
        </IconButton>
        <IconButton onClick={() => handlerOpenModalSearch(id)}>
          <Share />
        </IconButton>
        <IconButton onClick={() => __openViewer(row)}>
          <RemoveRedEye />
        </IconButton>
        <IconButton onClick={() => handlerOpenModalDelete(row)}>
          <Delete color="error" />
        </IconButton>
      </div>
    ), [row, column, field]);

    return actionButtons;
  };

  function handlerTest(value) {
    console.log(value);
  }

  const columnsTable = [
    {
      label: 'Nome',
      field: 'orginalname'
    },
    {
      label: 'Autor',
      field: 'author'
    },
    {
      label: 'Tamanho',
      field: 'size'
    },
    {
      label: 'Data',
      field: 'data',
      componentBody: FormatDataComponent
    },
    {
      label: 'Expira em',
      field: ''
    },
    {
      label: 'Ações',
      field: 'id',
      componentBody: Actions
    }
  ];

  useEffect(() => {
    if (folders.length) setFolderIdx(0);
  }, [folders]);

  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          marginTop: 20,
        }}
      >
        <Container>
          <Grid container alignItems="center">
            <Grid container alignItems="center" className="ContainerHeader" item xs={12}>
              <AppBar position="static" color="transparent" elevation={0} sx={{ mb: 1 }}>
                <Tabs
                  value={folderIdx}
                  onChange={(_, e) => setFolderIdx(e)}
                  indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
                  {[...folders].reverse().map((folder) => <Tab label={folder.name} id={folder.id} />)}
                  <AddIcon sx={{ mt: 1.2, cursor: 'pointer' }} onClick={toggleFolderDialog} />
                </Tabs>
              </AppBar>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 20 }}>
                <div style={{ display: 'flex', }}>
                  <div onClick={() => { setClickSearsh(true) }}>
                    <SearshComponent onSearch={setSearch} />
                  </div>
                  <TypeTable value={typeShowItens} onPress={setTypeShowItens} />
                </div>

                <Form.Label htmlFor="contained-button-file">
                  <Button variant="contained" component="buttom">Adicionar arquivo</Button>
                </Form.Label>
              </div>
            </Grid>

            <Grid
              item
              xs={3}
            >
              <input
                id="contained-button-file"
                type="file"
                style={{ display: 'none' }}
                onChange={onSelectedDocumentChanged}
                onClick={(ev) => {
                  //! Ensures the current value of the input will be null so the onChange event is
                  //! triggered
                  ev.target.value = null;
                }}
              />
              <br />
            </Grid>
          </Grid>
          <Form>
            <Dialog open={open} onClose={onRequestClose}>
              {progress > 0 ?
                <div className="modalArchiveFeedBack">
                  <Typography variant="body1">
                    Enviando arquivo...
                  </Typography>
                  <br />
                  <CircularProgressWithLabel value={progress} />
                </div> :
                <div className="modalArchiveFeedBack">
                  <Typography
                    variant="body1"
                    style={{
                      marginBottom: 5,
                    }}
                  >
                    {`Arquivo Selecionado: ${file.name}`}
                  </Typography>
                  <button onClick={handleSendDoc}>
                    Enviar
                  </button>
                </div>
              }
            </Dialog>

            <Grid container item className="ContainerArchives" xs={12} justifyContent="start">
              <div className="boxOverflow">
                {/* //colocar uma tabela aqui */}
                {typeShowItens === 'LIST' ?
                  <DefaultTable
                    columns={columnsTable}
                    rows={folder && handlerFilterInputSearch(folder.files)}
                  />
                  :
                  <div style={{ paddingTop: 20, display: 'flex', flexWrap: 'wrap' }}>
                    {folder && folder.files
                      .filter((docs) => !docs.workshop)
                      .filter((doc) => search ? doc.name_doc.includes(search) : true)
                      .map((docs) => <FilesHandler key={docs.id} data={docs} onShareClick={openShareModal} />)}
                  </div>

                }
              </div>
            </Grid>
            <br />
          </Form>
          <br />
        </Container>

        {selectedDocument &&
          <ShareLinkModal
            open={shareModalOpen}
            onClose={closeShareModal}
            documentId={selectedDocument}
          />
        }
        <CreateFolderDialog open={openFolderDialog} onClose={toggleFolderDialog} onConfirm={addFolder} />
      </Grid>

      {!!objectDocument &&
        <Dialog
          open={viewerOpen}
          onClose={handlerCloseOpenViewerModal}
          PaperProps={{
            style: {
              width: '800px',
              maxWidth: '95%',
            },
          }}
        >
          <DocumentViewer
            fileLink={fileLink}
            fileType={objectDocument.type}
            googleRender
          />
        </Dialog>
      }

      {!!objectDocument &&
        <DefaultModal open={deleteModal} onClose={handlerCloseModalDelete} title={'Deletar documento?'}>
          <div style={{ padding: 20 }}>
            <p>{`Tem certeza de que deseja excluir o documento "${objectDocument.orginalname}"?`}</p>
            <p>{`Esta ação não pode ser desfeita.`}</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row-reverse', padding: 20 }}>
            <Button variant="contained" style={{ marginLeft: 20 }}>Continuar</Button>
            <Button variant="outlined">Cancelar</Button>
          </div>
        </DefaultModal>
      }
    </>
  );
}
