import { createSlice } from '@reduxjs/toolkit'

export const mapeamentoResposta = createSlice({
  name: 'value',
  initialState: {
    value: [],
    setores: [],
    finalidade: [],
  },

  reducers: {
    resetCampos: (state) => {
      state.value = []
    },
    getCampos: (state, action) => {
      state.value = action.payload
    },
    getSetores: (state, action) => {
      state.setores = action.payload
    },
    getFinalidade: (state, action) => {
      state.finalidade = action.payload
    },
  },
})

export const {
  getCampos,
  resetCampos,
  getFinalidade,
  getSetores,
} = mapeamentoResposta.actions

export default mapeamentoResposta.reducer
