import React from "react";
import { Typography, } from '@material-ui/core';

function calculateLuminosity(color) {
  if (!color) {
    return;
  }
  let r = parseInt(color.substring(1, 3), 16);
  let g = parseInt(color.substring(3, 5), 16);
  let b = parseInt(color.substring(5, 7), 16);
  let luminosity = (0.2126 * r + 0.7152 * g + 0.0722 * b);
  return luminosity;
}

function typeBanner(backgroundColor) {
  if(!backgroundColor){
    return true;
  }

  let luminosity = calculateLuminosity(backgroundColor);
  if (luminosity > 128) {
    return true;
  } else {
    return false;
  }
}

export const modeBanner = (data) => {

  if (typeBanner(data.backgroundColor)) {
    return {
      background: data.backgroundColor || '#fff',
      color: data.highlightColor || 'rgba(59, 102, 255, 1)',
      borderColor: data.highlightColor || 'rgba(59, 102, 255, 1)',
      colorBtn: data.highlightColor || 'rgba(59, 102, 255, 1)',
      filledBtnColor: data.backgroundColor || '#fff',
      srcImage: 'https://c.octo.legal/light-mode-logo.png',
      btnBackGround: data.highlightColor || 'rgba(59, 102, 255, 1)'
    }
  } else {
    return {
      background: data.backgroundColor || 'rgba(59, 102, 255, 1)',
      color: data.highlightColor || '#fff',
      filledBtnColor: data.backgroundColor || 'rgba(59, 102, 255, 1)',
      colorBtn: data.highlightColor || "#fff",
      srcImage: 'https://c.octo.legal/dark-mode-logo.png'
    }
  }
}

export const InternalBtn = ({ theme, text, type }) => {
  return (
    <div item style={{ borderRadius: "6px", backgroundColor: type === "filled" ? (theme?.btnBackGround ? theme.btnBackGround : "#fff") : "rgba(255, 255, 255, 0.2)", marginRight: "10px", border: theme.borderColor ? '1px solid ' + theme.borderColor : '' }}>
      <Typography
        variant="subtitle2"
        component="div"
        color="primary"
        style={{ fontWeight: 500, color: type === "filled" ? theme.filledBtnColor : theme.colorBtn, padding: "6px 15px" }}
      >
        {text}
      </Typography>
    </div>
  )
}
