export const errorCodes = Object.freeze({
  COMPANY_CATEGORY_REFERENCED_ERROR: '2gszvm',
  LINK_EXPIRED_ERROR: 'xsv9mx',
  MAIL_SERVICE_ERROR: 'a2x1zx',
  SECTOR_IN_USE_ERROR: '6x18vo',
  SERVER_ERROR: '7utv4k',
  CHARTING_SERVICE_ERROR: 'a2x1zx',
  USER_IS_INACTIVE: '5zempx',
  CSV_SERVICE_ERROR: '8kiix2',
  MISSING_PARAM_ERROR: 'p230sc',
  INCOMPLETE_CHECKLIST_CSV_FILE_ERROR: 'ajykvk',
  EMPTY_FILE_ERROR: 'usf64v',
  INVALID_EMAIL_ERROR: 'kevsns',
  SHEET_SERVICE_ERROR: 'xze1px',
  EVIDENCE_SERVICE_ERROR: 'p4ziby',
  INVALID_ACTION_STATUS_ERROR: '9ksgec',
  NOT_GLOBAL_TEMPLATE_ERROR: '4y58uy',
  INVALID_DATA_MAPPING_FIELD_TYPE_ERROR: '8y95by',
  SAME_NAME_FIELD_ERROR: 'ev54yh',
  DATA_MAPPING_FIELD_MUST_HAVE_OPTIONS_ERROR: '890gtr',
  INVALID_FILE_ERROR: '5gb10r',
});
