import {useEffect, useState} from "react";
import {listFolders} from "../../../services/document.api";


export default function useFoldersGet(company,) {

  const [folders, setFolders] = useState([]);

  async function getData() {
    const response = await listFolders(company);
    setFolders(response);
  }

  useEffect(() => {
    getData();
  }, []);

  return {
    folders,
    add: (folder) => setFolders([...folders, folder]),
    addFile: (file) => {
      setFolders(folders.map((f) => {
        if (f.id === file.folderId) f.files = [...f.files, file];
        return f;
      }));
    },
    reload: getData,
  }
}
