import {useState, useMemo} from 'react';


/**
 *
 * @param items {array}
 * @returns {(*|string|((value: (((prevState: string) => string) | string)) => void))[]}
 */
export function useSearch(items) {
  const [search, setSearch] = useState('');

  const searchItems = useMemo(() => {
    return items.filter(item => {
      return item.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [items, search]);
  return [
    searchItems,
    search,
    setSearch
  ]
}
