import React from 'react';

import { Grid, Typography, } from '@material-ui/core';


function MetricCard({ value, label, color = '#3B66FF', variant = 'outlined', }) {
  const borderRadius = 15;

  return (
    <Grid
      container
      style={{
        height: '100%',
        backgroundColor: 'filled' === variant ? '#DCE0EE' : undefined,
        border: 'outlined' === variant ? '1px solid #95ADC3' : undefined,
        borderRadius: borderRadius,
        minHeight: '100px',
      }}
      alignItems="center"
      direction="row"
    >
      <Grid
        item
        xs={4}
        container
        style={{
          backgroundColor: 'filled' === variant ? color : undefined,
          borderTopLeftRadius: 'filled' === variant ? borderRadius : undefined,
          borderBottomLeftRadius: 'filled' === variant ? borderRadius : undefined,
          height: '100%',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          style={{
            color: 'filled' === variant ? 'white' : '#3B66FF',
            fontWeight: 'bold',
            fontFamily: 'Inter',
          }}
          variant="h3"
        >
          { value }
        </Typography>
      </Grid>

      <Grid
        item
        xs={8}
        container
        alignItems="center"
        justifyContent="center"
        style={{
          padding: '0.5rem',
        }}
      >
        <Typography
          style={{
            color: '#54708C',
            fontFamily: 'Inter',
          }}
          variant="subtitle1"
        >
          { label }
        </Typography>
      </Grid>
    </Grid>
  );
}

export default MetricCard;
