import React, { useEffect, useState, } from 'react';
import { useTranslation, } from 'react-i18next';

import {
  Typography,
  Grid,
  TableCell,
  TableRow,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles,} from '@material-ui/styles';
import { Notification, } from 'rsuite';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import DeleteDialog from '../DeleteDialog';
import Table from '../Table';
import TypeOptionForm from './TypeOptionForm';

import useModal from '../../hooks/useModal';


const useStyles = makeStyles({
  sectionHeader: {
    color: '#1C265A',
    fontWeight: 'bolder',
    fontSize: '1.2rem',
    fontFamily: 'Inter',
    marginBottom: '2rem',
  },
});


/**
 * @param {
 *  'PERSONAL_DATA_TYPE_MANAGEMENT' |
 *  'DATA_PROCESSING_TYPE_MANAGEMENT' |
 *  'DATA_MANAGEMENT_ROLE_MANAGEMENT'
 * } type - type
 * @param {boolean} open
 * @param {() => Promise<Object[]>} onFetch - fetch callback
 * @param {(optionId: string | number) => Promise<void>} onDelete - delete callback
 * @param {(optionData: Object) => Promise<boolean>} onUpdate - update callback
 * @param {(optionData: Object) => Promise<boolean>} onCreate - create callback
 * @param {() => void} onClose - close modal callback
 */
function TypeOptionsModal({ open, type, onFetch, onDelete, onUpdate, onCreate, onClose, isModal = true }) {
  const classes = useStyles();
  const { t, } = useTranslation();
  const HEADERS = React.useMemo(() => Object.freeze([
    {
      label: t('name', { context: 'male', }),
      align: 'center',
    },
    {
      label: '',
      align: 'right',
    },
  ]), [t]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [optionId, setOptionId] = useState(undefined);
  const currOption = options.find((item) => item.id == optionId);
  const [deleteConfirmation, openDeleteConfirmation, closeDeleteConfirmation] = useModal(
    (optionId) => setOptionId(optionId), () => setOptionId(undefined));


  async function _onDelete() {
    const id = optionId;
    closeDeleteConfirmation();
    if (undefined == id) {
      return;
    }

    try {
      await onDelete(id);
      setOptions(options.filter((item) => id != item.id));
      return Notification['success']({
        placement: 'bottomEnd',
        title: t(`PAGES.${type}.successfully_deleted`),
      });
    } catch {
      return Notification['warning']({
        placement: 'bottomEnd',
        title: t(`ERRORS.${type}.failed_to_delete`),
        description: t('ERRORS.try_again_later'),
      });
    }
  }

  async function _onFetch() {
    setLoading(true);
    try {
      const options = await onFetch();
      setOptions(options);
    } catch (err) {
      Notification['warning']({
        placement: 'bottomEnd',
        title: t(`ERRORS.${type}.failed_to_load`),
        description: t('ERRORS.try_again_later'),
      });
    }
    setLoading(false);
  }

  async function _onUpdate(data) {
    try {
      const updatedOption = await onUpdate(data);
      const updatedOptions = [...options];
      const idx = updatedOptions.findIndex((item) => optionId == item.id);
      if (idx > -1) {
        updatedOptions[idx] = updatedOption;
      }
      setOptions(updatedOptions);
      Notification['success']({
        placement: 'bottomEnd',
        title: t(`PAGES.${type}.successfully_updated`),
      });
      setOptionId(undefined);
      return true;
    } catch {
      Notification['warning']({
        placement: 'bottomEnd',
        title: t(`ERRORS.${type}.failed_to_update`),
        description: t('ERRORS.try_again_later'),
      });
      return false;
    }
  }

  async function _onCreate(data) {
    try {
      const createdOption = await onCreate(data);
      setOptions([...options, createdOption]);
      Notification['success']({
        placement: 'bottomEnd',
        title: t(`PAGES.${type}.successfully_created`),
      });

      return true;
    } catch {
      Notification['warning']({
        placement: 'bottomEnd',
        title: t(`ERRORS.${type}.failed_to_create`),
        description: t('ERRORS.try_again_later'),
      });
      return false;
    }
  }

  async function onSubmitForm(data) {
    return await (undefined != optionId ? _onUpdate(data) : _onCreate(data));
  }

  function clearSelectedOption() {
    setOptionId(undefined);
  }

  function onCancel() {
    clearSelectedOption();
  }

  function _onClose() {
    clearSelectedOption();
    onClose();
  }

  useEffect(() => {
    if (open) {
      _onFetch();
    }
  }, [open]);


  return (


    <>
      {isModal &&
        <Dialog
          open={open}
          onClose={_onClose}
          maxWidth="lg"
        >
          <DialogTitle
            style={{
              backgroundColor: '#3B66FF',
              color: 'white',
            }}
          >
            {t(`PAGES.${type}.title`)}
          </DialogTitle>

          <DialogContent>
            <Grid
              container
              justifyContent="space-around"
            >
              <Grid
                item
                md={5}
                xs={12}
                style={{
                  paddingRight: '1.0rem',
                }}
              >
                <Typography
                  variant="h6"
                  className={classes.sectionHeader}
                >
                  {t(`PAGES.${type}.add_new`)}
                </Typography>
                <TypeOptionForm
                  data={deleteConfirmation ? undefined : currOption}
                  onCancel={onCancel}
                  onSubmit={onSubmitForm}
                />
              </Grid>

              <Grid
                item
                md={7}
                xs={12}
              >
                <Typography
                  variant="h6"
                  className={classes.sectionHeader}
                >
                  {t(`PAGES.${type}.all_items`)}
                </Typography>
                <Table
                  headers={HEADERS}
                  data={options}
                  loading={loading}
                  disablePagination
                  errorMessage={t(`ERRORS.${type}.failed_to_load`)}
                  noDataMessage={t(`PAGES.${type}.no_items_found`)}
                  maxHeight={300}
                >
                  {
                    (option, props) => {
                      return (
                        <TableRow {...props}>
                          <TableCell align="center">
                            {option.name}
                          </TableCell>
                          <TableCell
                            align="right"
                            size="small"
                          >
                            <IconButton
                              size="small"
                              onClick={() => setOptionId(option.id)}
                            >
                              <EditIcon />
                            </IconButton>

                            <IconButton
                              size="small"
                              color="secondary"
                              onClick={() => openDeleteConfirmation(option.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  }
                </Table>
              </Grid>
            </Grid>

            <DeleteDialog
              open={deleteConfirmation}
              confirmationMessage={t(`PAGES.${type}.delete_confirmation`, currOption || { name: '', })}
              onClose={closeDeleteConfirmation}
              onConfirm={_onDelete}
            />
          </DialogContent>
        </Dialog>}
      {!isModal &&
        <>
          <DialogContent>
            <Grid
              container
              justifyContent="space-around"
            >
              <Grid
                item
                md={5}
                xs={12}
                style={{
                  paddingRight: '1.0rem',
                }}
              >
                <Typography
                  variant="h6"
                  className={classes.sectionHeader}
                >
                  {t(`PAGES.${type}.add_new`)}
                </Typography>
                <TypeOptionForm
                  data={deleteConfirmation ? undefined : currOption}
                  onCancel={onCancel}
                  onSubmit={onSubmitForm}
                />
              </Grid>

              <Grid
                item
                md={7}
                xs={12}
              >
                <Typography
                  variant="h6"
                  className={classes.sectionHeader}
                >
                  {t(`PAGES.${type}.all_items`)}
                </Typography>
                <Table
                  headers={HEADERS}
                  data={options}
                  loading={loading}
                  disablePagination
                  errorMessage={t(`ERRORS.${type}.failed_to_load`)}
                  noDataMessage={t(`PAGES.${type}.no_items_found`)}
                  maxHeight={300}
                >
                  {
                    (option, props) => {
                      return (
                        <TableRow {...props}>
                          <TableCell align="center">
                            {option.name}
                          </TableCell>
                          <TableCell
                            align="right"
                            size="small"
                          >
                            <IconButton
                              size="small"
                              onClick={() => setOptionId(option.id)}
                            >
                              <EditIcon />
                            </IconButton>

                            <IconButton
                              size="small"
                              color="secondary"
                              onClick={() => openDeleteConfirmation(option.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  }
                </Table>
              </Grid>
            </Grid>

            <DeleteDialog
              open={deleteConfirmation}
              confirmationMessage={t(`PAGES.${type}.delete_confirmation`, currOption || { name: '', })}
              onClose={closeDeleteConfirmation}
              onConfirm={_onDelete}
            />
          </DialogContent>
        </>
      }
    </>

  );
}

export default TypeOptionsModal;
