import React from 'react';
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';

import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,


  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Scatter,
  LabelList

} from 'recharts';

function QuestionsCategoriesMaturityLevelChart({ data, typeGraphic }) {

  const { t, } = useTranslation();

  const RadarGraphic = () => {
    return (
      <ResponsiveContainer
        width="100%"
        height="100%"
        minHeight={500}
      >
        <RadarChart
          cx="50%"
          cy="50%"
          outerRadius="80%"
          data={data}
        >
          <PolarGrid />
          <PolarAngleAxis dataKey="name" />
          <PolarRadiusAxis
            domain={[0, 5]}
            scale="linear"
          />
          <Tooltip formatter={(v) => `${((v / 5) * 100).toFixed(2)}%`} />
          <Radar
            name={_.capitalize(t('maturity'))}
            dataKey="value"
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.6}
          />
        </RadarChart>
      </ResponsiveContainer>
    );
  }

  function translate(value, entry) {

    const translations = {
      value: 'Valor',
    };
    return translations[value];
  }

  function translateTooltip(value, name, props) {

    const translations = {
      value: 'Valor',
    };

    const newValue = (value != 0 ? `${((value / 5) * 100).toFixed(2)}%`: null);

    return [newValue];
  }


  const ColumnGraphic = () => {
    return (
      <ResponsiveContainer width="100%" height="100%" minHeight={500}>
        <ComposedChart
          layout="vertical"
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 30,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            type="number"
            tickFormatter={(tick) => `${tick*100/5}%`}
          />
          <YAxis dataKey="name" type="category" scale="band" />
          <Tooltip formatter={translateTooltip} />
          <Legend formatter={translate} />
          <Bar dataKey="value" barSize={20} fill="#413ea0">
            <LabelList formatter={translateTooltip} dataKey="value" position="inside" fill="#ffffff" />
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    );
  }

  return (
    typeGraphic === 'radar' ? <RadarGraphic /> : <ColumnGraphic />
  );
};

export default React.memo(QuestionsCategoriesMaturityLevelChart);
