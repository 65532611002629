import React, {useMemo} from "react";
import { Typography, ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Icon } from 'rsuite';
import history from "../../../history";



export default function Tab({ icon, title, onClick, go, setSelectedObjectMenu, isActive }) {
    const className = styles({ isActive });

    const style = useMemo(() => ({
      background: isActive ? "#fff" : "rgba(239, 239, 239, 1)",
    }));
    return (
        <div className={className.containerBtn}>
            <ButtonBase onClick={onClick ? onClick : () => { history.push(go); setSelectedObjectMenu() }}>
                <div className={className.containerInternal} style={style}>
                    <div className={className.containerIcon}>
                        <Icon icon={icon || ""} />
                    </div>
                    <Typography className={className.textBtn}>
                        {title || "Workshop"}
                    </Typography>
                </div>
            </ButtonBase>
        </div>
    )
}
const styles = makeStyles(() => ({
    containerBtn: {
        borderTopLeftRadius: '10px',
        borderTopRightRadius: "10px",
        overflow: "hidden"
    },
    containerInternal: {
        display: "flex",
        flexDirection: "row",
        background: "#fff",
        padding: "10px 20px", borderTopLeftRadius: '10px',
        borderTopRightRadius: "10px",
        minWidth: "120px",
        color: "#0000FF",
        borderTop: props => props.isActive ? "1px solid rgba(59, 102, 255, 1)" : "none",
        borderLeft: props => props.isActive ? "1px solid rgba(59, 102, 255, 1)" : "none",
        borderRight: props => props.isActive ? "1px solid rgba(59, 102, 255, 1)" : "none",
    },
    containerIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: '5px',
        marginBottom: '2px'
    },
    textBtn: {
        display: "flex",
        color: "rgba(59, 102, 255, 1)",
        whiteSpace: "nowrap",
        fontWeight:"800"
    }
}));
