import React from 'react'
import {
  Input,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  inputLabelMark: {
    color: '#CE79DC',
  },
  inputLabel: {
    color: '#54708C',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    fontFamily: 'Inter',
  },
  inputLabelLight: {
    color: '#54708C',
    fontWeight: 400,
    fontSize: '0.9rem',
    fontFamily: 'Inter',
  },
  input: {
    width: '100%',
    background: '#F2F4F8',
    padding: '0.5rem',
    borderRadius: '0.4rem',
  },
  error: {
    border: '2px solid #fc9a8d',
  },
  errorMessage: {
    color: '#ff614d',
  },
  container: {
    marginBottom: '1rem',
  },
})

export const InputCadastro = ({
  label,
  value,
  error,
  onChange,
  type,
  required,
  light,
  endAdornment,
  placeholder,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography
        variant="body1"
        className={
          light
            ? classes.inputLabelLight
            : classes.inputLabel
        }
      >
        {label}
        <span className={classes.inputLabelMark}>*</span>
      </Typography>

      <Input
        margin="normal"
        value={value}
        type={type ? type : 'text'}
        placeholder={placeholder ? placeholder : ''}
        className={`${classes.input} ${
          error ? classes.error : ''
        }`}
        disableUnderline
        required={required}
        onChange={onChange}
        endAdornment={endAdornment ? endAdornment : null}
      />

      <Typography
        variant="caption"
        className={classes.errorMessage}
      >
        {error}
      </Typography>
    </div>
  )
}
