import React, { useEffect, useState } from 'react'
import {
  Container,
  TablePagination,
  Paper,
  Grid,
  Dialog,
  Collapse
} from '@material-ui/core'
import {
  Table,
  InputGroup,
  FormControl,
} from 'react-bootstrap'
import { Icon } from 'rsuite'
import CadastroUser from '../../pages/CriarUsuarios/CadUserMaster'
import PainelConsoleMaster from './ConfigUsers'
import { getUsers, getHistoricoLogins, } from '../../services/api';

export default function Users({ master, sm }) {
  const [clickSearch, setClickSearch] = useState(false)
  const [search, setSearch] = useState('')
  const [openUser, setOpenUser] = useState(false)
  const [body, setBody] = useState([])
  const [hist, setHist] = useState([])

  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [page, setPage] = useState(0)

  const [collapsed, setCollapsed] = useState(false)
  const [iconCollapse, setIconCollapse] = useState('angle-down')
  const [classSetaCollapse, setClassSetaCollpase] = useState('SetaCollapse')

  function HandleCollapse() {
    setCollapsed(!collapsed)
  }
  function HandleIcon() {
    if (collapsed === true) {
     setIconCollapse('angle-up')
     setClassSetaCollpase('SetaCollapseActive')
    } else {
      setIconCollapse('angle-down')
      setClassSetaCollpase('SetaCollapse')
    }
  }

  useEffect(() => {
    HandleIcon()
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  function closeModalNewUser() {
    setOpenUser(false)
  }

  async function getdata() {
    const { data: users, } = await getUsers();
    setBody(users);
    const { data: loginHistory, } = await getHistoricoLogins();
    setHist(loginHistory);
  }

  useEffect(() => {
    getdata()
  }, [])

  const styles = {
    width: 230,
    height: 29,
    borderRadius: 29,
    borderColor: '#95ADC3',
    padding: '3px',
  }

  const filteredUsers = body.filter((user) => {
    return user.name
      .toLowerCase()
      .includes(search.toLocaleLowerCase())
  })


  return (
    <Grid item xs={12} sm={sm}>
      <Container
        component={Paper}
        disableGutters
        maxWidth="xl"
      >
        <div className="BoxHeaderPaineis">
            <div className="TitlePainel">
              <h5>1Todos os Usuários</h5>
              <div className={classSetaCollapse}>
                  <Icon onClick={HandleCollapse} icon={iconCollapse} />
                </div>
            </div>
            <div className="boxInputNewConsult">
            {clickSearch ? (
              <InputGroup style={styles}>
                <FormControl
                  placeholder="Pesquisar por usuario"
                  value={search}
                  onChange={(event) => {
                    setSearch(event.target.value)
                  }}
                />
              </InputGroup>
            ) : (
              <div
                onClick={() => {
                  setClickSearch(true)
                }}
                className="BoxLupa"
              >
                <Icon
                  style={{
                    color: '#95ADC3',
                    cursor: 'pointer',
                    width: 20,
                    right: 0,
                  }}
                  icon="search"
                />
                <p>Pesquisar por nome de usuário</p>
              </div>
            )}
          <div className="buttonNewConsult">
            <button
              onClick={() => {
                setOpenUser(true)
              }}
            >
              <Icon icon="plus" />
              Adicionar um novo consultor
            </button>
          </div>
          </div>
        </div>
        <Dialog open={openUser} onClose={closeModalNewUser}>
          <Container
            maxWidth="xl"
            component={Paper}
            style={{ marginTop: '2%' }}
          >
            <br />
            <CadastroUser onClose={closeModalNewUser} />
            <br />
          </Container>
        </Dialog>
      </Container>
      <Collapse in={collapsed} >
      <Container component={Paper} className="PainelUsers">
        <Table hover responsive>
          <thead>
            <th>Nome</th>
            <th>Grupo</th>
            <th>Configuração</th>
            <th>Deletar</th>
          </thead>
          {
            filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => {
              return (
                <PainelConsoleMaster
                  key={user._id}
                  body={user}
                  logs={hist}
                />
              );
            })
          }
        </Table>

        {master && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={body.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Container>
      </Collapse>
    </Grid>
  )
}
