import React from 'react';
import { Icon, } from 'rsuite';

import './styles.css';


const QuestionItem = ({ id, index, question, sector, onEdit, onDelete, color, }) => {
  return (
    <div
      className="question-item-container"
      style={{
        backgroundColor: undefined != color ? color : undefined,
      }}
    >
      <div className="question-item-boxLineLeft">
        <div className="question-item-itemIndex">
          Item { index + 1 }
        </div>
        <span className="question-item-question">
          { question }
        </span>
      </div>
      <div className="question-item-boxRight">
        <div className="question-item-setor">
          <p>
            { sector }
          </p>
        </div>
        <div className="question-item-iconsOptions">
          <Icon
            onClick={() => onEdit(id)}
            className="question-item-iconQuestion"
            size="lg"
            icon="edit"
          />
          <Icon
            onClick={() => onDelete(id)}
            className="question-item-iconQuestion"
            icon="trash-o"
            size="lg"
          />
        </div>
      </div>
    </div>
  );
}

export default QuestionItem;
