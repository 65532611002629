import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Notification, } from 'rsuite';

import * as api from '../services/api';
import { CRUD_STATUSES, } from '../utils/constants';
import { camelCaseObjectKeys, } from '../utils';


const initialState = {
  processingAgents: [],
  processingAgentsMap: {},
  status: CRUD_STATUSES.IDLE,
  managementModalOpen: false,
};


export const listProcessingAgents = createAsyncThunk('processingAgents/fetch', async (_, thunkAPI) => {
  const { data, status, } = await api.listProcessingAgents(thunkAPI.getState().company.company._id);
  if (200 === status) {
    return data;
  } else {
    Notification['warning']({
      placement: 'bottomEnd',
      title: 'Falha ao carregar terceiros!',
      description: 'Tente novamente dentro de alguns instantes.'
    });
  }
});

export const createProcessingAgent = createAsyncThunk('processingAgents/create', async (processingAgent, thunkAPI) => {
  const { data, } = await api.createProcessingAgent(thunkAPI.getState().company.company._id, processingAgent);
  return data;
});


export const updateProcessingAgent = createAsyncThunk('processingAgents/update', async (processingAgent, thunkAPI) => {
  const { data, } = await api.updateProcessingAgent(thunkAPI.getState().company.company._id, processingAgent.id, processingAgent);
  return data;
});

export const createManyProcessingAgentsFromCSVFile = createAsyncThunk('processingAgents/createManyFromCSV', async (file, thunkAPI) => {
  try {
    const { data, } = await api.createManyProcessingAgentsFromCSVFile(thunkAPI.getState().company.company._id,  file);
    return data;
  } catch (e) {

    if (e.response?.data?.errors) {
      const errors = e.response.data.errors;

      if(errors.some(error => error?.type === "emailEmpty")) {
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Falha ao Importar Terceiros!',
          description: 'Campo Email é Obrigatorio',
        })
      } else if (errors.some(error => error?.type === "email")) {
        const error = errors.find(error => error?.type === "email");
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Falha ao Importar Terceiros!',
          description: `O Email ${error.actual} não é válido`,
        })
      }  else if (errors.some(error => error?.type === "stringEmpty")) {
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Falha ao Importar Terceiros!',
          description: `Campo Nome é Obrigatorio`,
        })
      } else {
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Falha ao Importar Terceiros!',
          description: errors[0].message,
        })
      }
    } else {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao Importar Terceiros!',
        description: 'Tente novamente dentro de alguns instantes.',
      })
    }
  }
});

export const deleteProcessingAgent = createAsyncThunk('processingAgents/delete', async (processingAgentId, thunkAPI) => {
  const { status, } = await api.deleteProcessingAgent(thunkAPI.getState().company.company._id, processingAgentId);
  return 200 === status ? processingAgentId : null;
});


export const processingAgentsSlice = createSlice({
  name: 'processingAgents',
  initialState,
  reducers: {
    setStatus: (state, { payload: status, }) => {
      if (status != null) {
        state.status = status;
      } else {
        state.status = initialState.status;
      }
    },
    setProcessingAgentsModal(state, { payload, }) {
      state.managementModalOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listProcessingAgents.pending, (state) => {
      state.status = CRUD_STATUSES.LOADING;
    }).addCase(listProcessingAgents.fulfilled, (state, { payload: _processingAgents, }) => {
      state.status = CRUD_STATUSES.FETCH_SUCCESSFUL;
      const processingAgents = _processingAgents.map(camelCaseObjectKeys);
      state.processingAgents = processingAgents;
      state.processingAgentsMap = Object.fromEntries(processingAgents.map((item) => [item.id, item]));
    }).addCase(listProcessingAgents.rejected, (state) => {
      state.status = CRUD_STATUSES.FETCH_FAILED;
      state.processingAgents = initialState.processingAgents;
    });

    builder.addCase(createProcessingAgent.fulfilled, (state, { payload: _processingAgent, }) => {
      const processingAgent = camelCaseObjectKeys(_processingAgent);
      state.processingAgents.push(camelCaseObjectKeys(processingAgent));
      state.processingAgentsMap[processingAgent.id] = processingAgent;
    });

    builder.addCase(updateProcessingAgent.fulfilled, (state, { payload: _processingAgent, }) => {
      const index = state.processingAgents.findIndex(({ id, }) => id === _processingAgent.id);
      if (index > -1) {
        const processingAgent = camelCaseObjectKeys(_processingAgent);
        state.processingAgents[index] = camelCaseObjectKeys(processingAgent);
        state.processingAgentsMap[processingAgent.id] = processingAgent;
      }
    });

    builder.addCase(deleteProcessingAgent.fulfilled, (state, { payload: processingAgentId, }) => {
      const index = state.processingAgents.findIndex(({ id, }) => id === processingAgentId);
      if (index > -1) {
        state.processingAgents.splice(index, 1);
        delete state.processingAgentsMap[processingAgentId];
      }
    });
  }
});

/**
 * Custom select to get status
 *
 * @param {object} state - processing agents slice state
 *
 * @returns the feature status
 */
export const selectFeatureStatus = (state) => {
  return state.processingAgents.status;
}

/**
 * Custom select to get processing agents
 *
 * @param {object} state - processing agents slice state
 *
 * @returns processingAgents
 */
export const selectProcessingAgents = (state) => {
  return state.processingAgents.processingAgents;
}

export const selectProcessingAgentsMap = (state) => {
  return state.processingAgents.processingAgentsMap;
}

/**
 * Custom select to get processing agents by type
 *
 * @param {object} state - processing agents slice state
 * @param {string | undefined} type - processing agent type used to filter processing agents state
 *
 * @returns processingAgents
 */
export const selectProcessingAgentsByType = (state, type) => {
  if (undefined != type) {
    return state.processingAgents.processingAgents.filter((agent) => agent.types.includes(type));
  } else {
    return state.processingAgents.processingAgents;
  }
}

/**
 * Custom select to get processing agents management modal state
 *
 * @param {object} state - processing agents slice state
 *
 * @returns processing agents management modal state
 */
export const selectProcessingAgentModalState = (state) => {
  return state.processingAgents.managementModalOpen;
}

export const {
  setStatus,
  setProcessingAgentsModal,
} = processingAgentsSlice.actions;
export default processingAgentsSlice.reducer;
