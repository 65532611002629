import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    color: 'rgba(255, 255, 255, 1)',
    boxShadow: theme.shadows[1],
    fontSize: theme.typography.pxToRem(13),
  },
}))(Tooltip);

export default StyledTooltip;
