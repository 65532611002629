import React from "react";
import { useSelector, useDispatch, } from 'react-redux';
import { selectCompanySectors } from "../../../features/companySectorsSlice";
import Dropdown from "../../Dropdown";



export default function SelectSetor({ value = null, setValue = () => { } }) {
    const companySectors = useSelector(selectCompanySectors);

    function handlerSetValue(value) {
        if (typeof setValue === 'function') {
            setValue(value);
        }
    }

    return (
        <Dropdown
            defaultItem="Escolha um setor"
            items={companySectors}
            value={value}
            setValue={handlerSetValue}
        />
    );
}