import React, { useState, useEffect, } from 'react';
import { Form, } from 'react-bootstrap';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { useSelector, useDispatch, } from 'react-redux';
import { useTranslation, } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import Button from '../Button';
import './styles.css';
import { selectCompanySectors, setCompanySectorsModal, } from '../../features/companySectorsSlice';
import {
  selectCompanyCategories,
  setCompanyCategoriesModal,
} from '../../features/companyCategoriesSlice';


const INITIAL_VALUES = Object.freeze({
  problem: '',
  action: '',
  sector: 0,
  risk: '',
  category: 0,
});


const MaturityQuestionForm = ({ data, onSubmit, }) => {
  const [loading, setLoading] = useState(false);
  const companySectors = useSelector(selectCompanySectors);
  const companyCategories = useSelector(selectCompanyCategories);
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const VALID_SECTORS = companySectors.map((sector) => sector.id);
  const VALID_CATEGORIES = companyCategories.map((category) => category.id);

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: handleSubmit,
    validationSchema: Yup.object({
      problem: Yup.string().required(_.capitalize(t('VALIDATION_MESSAGES.required', {
        context: 'male',
      }))),
      action: Yup.string().nullable(),
      sector: Yup.number().required(_.capitalize(t('VALIDATION_MESSAGES.required', {
        context: 'male',
      }))).oneOf(VALID_SECTORS, _.capitalize(t('VALIDATION_MESSAGES.must_be_a_valid_sector'))),
      risk: Yup.string().nullable(),
      category: Yup.number().required(_.capitalize(t('VALIDATION_MESSAGES.required', {
        context: 'female',
      }))).oneOf(VALID_CATEGORIES, _.capitalize(t('VALIDATION_MESSAGES.must_be_a_valid_category'))),
    }),
  });


  async function handleSubmit(values) {
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
  }

  function __onSubmit(event) {
    event.preventDefault();
    formik.handleSubmit();
  }

  function openSectorManagementModal() {
    dispatch(setCompanySectorsModal(true));
  }

  function openCategoryManagementModal() {
    dispatch(setCompanyCategoriesModal(true));
  }

  useEffect(() => {
    if (data && _.isObject(data)) {
      formik.setValues({
        problem: data.problema,
        risk: data.risco,
        action: data.acao,
        sector: (null == data.sector_id) ? 0 : data.sector_id,
        category: (null == data.category_id) ? 0 : data.category_id,
      });
    }
  }, [data]);


  return (
    <>
      <form className="maturityQuestionFormContainer">
        <Form.Group className="mb-3">
          <Form.Label className="inputLabel">
          {'Tarefa'}
          </Form.Label>
          <Form.Control
            className="formInput"
            as="textarea"
            id="problem"
            isInvalid={formik.touched.problem && formik.errors.problem}
            {...formik.getFieldProps('problem')}
          />

          <Form.Control.Feedback type="invalid">
            { formik.errors.problem }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="inputLabel">
            {_.capitalize(t('risk_consequence'))}
          </Form.Label>
          <Form.Control
            className="formInput"
            as="textarea"
            id="risk"
            isInvalid={formik.touched.risk && formik.errors.risk}
            {...formik.getFieldProps('risk')}
          />

          <Form.Control.Feedback type="invalid">
            { formik.errors.risk }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="inputLabel">
          {_.capitalize(t('action'))}
          </Form.Label>
          <Form.Control
            className="formInput"
            as="textarea"
            id="action"
            isInvalid={formik.touched.action && formik.errors.action}
            {...formik.getFieldProps('action')}
          />

          <Form.Control.Feedback type="invalid">
            { formik.errors.action }
          </Form.Control.Feedback>
        </Form.Group>

        <div className="sectorSelectorContainer">
          <div className="boxSelectModal">
            <Form.Group className="mb-3">
              <Form.Label className="inputLabel">
              {_.capitalize(t('sector'))}
              </Form.Label>
              <Form.Control
                className="selectContent"
                as="select"
                id="sector"
                isInvalid={formik.touched.sector && formik.errors.sector}
                {...formik.getFieldProps('sector')}
              >
                <option
                  key={0}
                  value={0}
                >
                  {_.capitalize(t('PAGES.MATURITY_QUESTION_FORM.choose_a_sector'))}
                </option>
                {
                  companySectors.map(({ id, name, }) => (
                    <option
                      key={id}
                      value={id}
                    >
                      { name }
                    </option>
                  ))
                }
              </Form.Control>

              <Form.Control.Feedback type="invalid">
                { formik.errors.sector }
              </Form.Control.Feedback>
            </Form.Group>

          </div>
          <IconButton
            aria-label="add sector"
            onClick={openSectorManagementModal}
            color="primary"
          >
            <AddCircleIcon />
          </IconButton>
        </div>

        <div className="sectorSelectorContainer">
          <div className="boxSelectModal">
            <Form.Group className="mb-3">
              <Form.Label className="inputLabel">
                {_.capitalize(t('category'))}
              </Form.Label>
              <Form.Control
                className="selectContent"
                as="select"
                id="category"
                isInvalid={formik.touched.category && formik.errors.category}
                {...formik.getFieldProps('category')}
              >
                <option
                  key={0}
                  value={0}
                >
                  {_.capitalize(t('PAGES.MATURITY_QUESTION_FORM.choose_a_category'))}
                </option>
                {
                  companyCategories.map(({ id, name, }) => (
                    <option
                      key={id}
                      value={id}
                    >
                      { name }
                    </option>
                  ))
                }
              </Form.Control>

              <Form.Control.Feedback type="invalid">
                { formik.errors.category }
              </Form.Control.Feedback>
            </Form.Group>

          </div>
          <IconButton
            aria-label="add sector"
            onClick={openCategoryManagementModal}
            color="primary"
          >
            <AddCircleIcon />
          </IconButton>
        </div>
      </form>

      <div className="buttonContainer">
        <Button
          className="updateButton"
          onClick={__onSubmit}
          loading={loading}
          color="primary"
          variant="contained"
        >
          {_.capitalize(t('save'))}
        </Button>
      </div>
    </>
  );
}

export default MaturityQuestionForm;
