import React from "react";
import { Container, Grid, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useThemeContext } from "../../context/ThemeAppContext";

export default function ContainerTimeLine({ children, number, title = "HelloWord", lineVerical = true }) {
    const { stylesLayout } = useThemeContext();
    const className = useAspectRatioStyles({ stylesLayout });

    const Circle = () => (
        <div className={className.circle}>
            <p className={className.number}>{number}</p>
        </div>
    );

    const VerticalLine = () => (
        <div className={className.containerLine}>
            <div className={className.lineVertical}></div>
        </div>
    )

    const Title = () => (
        <p className={className.title}>{title}</p>
    );

    const ItemTimeLine = () => (
        <div className={className.itemTimeLine}>
            <Circle />
            {lineVerical && <VerticalLine />}
        </div>
    );

    return (
        <Container row maxWidth={false} style={{ width: "100%" }}>
            <Grid container direction="row" spacing={2}>
                <Grid item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <ItemTimeLine />
                </Grid>
                <Grid item xs style={{ marginLeft: '50px', marginBottom: "20px" }}>
                    {title && <Title />}
                    {children}
                </Grid>
            </Grid>
        </Container>
    )
}


const useAspectRatioStyles = makeStyles({
    circle: {
        display: "flex",
        width: "32px",
        height: "32px",
        borderRadius: "16px",
        justifyContent: "center",
        alignItems: "center",
        background: 'var(--secondary-color)'
    },
    number: {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "19.36px",
        color: "#fff"
    },
    containerLine: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "1"
    },
    lineVertical: {
        height: "100%",
        border: '1px dashed #95ADC3'
    },
    itemTimeLine: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: '0px'
    },
    vertical: {
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    title: {
        color: 'var(--secondary-color)',
        fontWeight: "600",
        fontSize: "20px",
        lineHeight: "22px",
        marginBottom: "20px"
    }
});
