import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getRisco, listCompanyMeetings, } from '../../services/api';
import {
  setItens,
  loadingFalse,
  updateTitle,
} from '../../features/matrizDeRisco'
import { updateReunion } from '../../features/reunionsSlice'

import ListItems from '../../components/PDCA/ListITems'
import Loading from '../../components/Global/Loading'


function PDCACycle() {
  const dispatch = useDispatch();
  const [aceitos, setAceitos] = useState([]);
  const loading = useSelector((state) => state.matriz.loading);
  const body = useSelector((state) => state.matriz.value);


  async function getData() {
    await getRisco(true, 1).then(({ data }) => {

      // const filtered = data.risco.filter((item) => item.resposta !== 'Aceitar')
      // dispatch(setItens(filtered))
      //numero de itens com risco aceitar;

      setAceitos(data.pdcaAceite)

      console.log(data);
      dispatch(
        updateTitle(
          `Itens com a resposta diferente de aceitar`
        )
      )
    })
    await listCompanyMeetings().then(({ data }) => {
      dispatch(updateReunion(data))
    })
    dispatch(loadingFalse())
  }

  useEffect(() => {
    getData();
  }, []);


  return (
    <>
      {loading && <Loading value={loading} />}

      {body[0] && !loading && (
        <ListItems aceitos={aceitos} />
      )}
    </>
  );
}

export default PDCACycle;
