import styled from 'styled-components';

export const ContainerWorkshop = styled.div`
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 991px) {
    display: block;
    max-width: 48rem;
    max-height: 100%;
  }
`;

export const TitleWorkshop = styled.div`
  position: relative;
  margin-top: -1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  padding: 0;
  align-items: left;
  justify-content: left;
  strong {
    color: #4d5884;
    font-size: 28px;
    margin-left: inherit;
  }
`;

export const ContentWorkshop = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 1rem;
  padding: 0 0 0.5rem 0;
  margin: 0 2rem;
  max-width: 63rem;
  min-height: 38rem;
  background: #ffffff;

  border-radius: 0.25rem;

  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    max-width: 40rem;
    margin-left: 30px;
    margin-right: 0;
    margin-bottom: 0;
  }
`;


export const ButtonSalvarApresWork = styled.div`
  position: relative;
  top: 0;
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 1rem;
  padding: 0;
  margin: 0;

  @media (max-width: 991px) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 40rem;
    padding: 0 0 2rem 0;
    margin: -2rem 0 0 0;
    top: 0;
  }

  button {
    width: 15rem;
    height: 2rem;
    border: 1px solid blue;
    border-radius: 0.25rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: rgb(59, 102, 255);
    color: white;

    margin-top: -2rem;
    margin-right: -31rem;

    transition: filter 0.2s;

    @media (max-width: 991px) {
      margin: 0 0 2rem 0;
    }

    &:hover {
      filter: brightness(0.9);
    }
  }
`;
