import { makeStyles, } from '@material-ui/styles';
import _ from 'lodash';


const SEPARATOR = ', ';
const SEPARATOR_SPLIT = /[,|;]/;


/**
 * Convert mapping field, of type select, response to string
 *
 * @param {string[]} items - field response items
 *
 * @returns {string} stringified field response
 */
export function stringifyFieldResponse(items, separator=SEPARATOR) {
  console.log("itens", items);
  
  return _.isArray(items) ? items.join(separator) : '';
}

/**
 * Parse field options from string to array
 *
 * @param {string} response - field response
 *
 * @returns {string[]} items in array response
 */
export function parseFieldResponse(response, separator=SEPARATOR_SPLIT) {
  return _.isString(response) && response.length > 0 ?
    response
      .split(separator)
      .map(item => item.trim())
      .filter(Boolean) :
    [];
}


export function getSelectedStyles(value, selectedValue) {
  const selected = (selectedValue == value);

  return {
    backgroundColor: selected ? 'lightblue' : 'white',
    color: selected ? 'blue' : 'black',
  };
}

export function getMultipleSelectItemsStyles(value, items) {
  const selected = -1 < items.indexOf(value);

  return {
    backgroundColor: selected ? 'lightblue' : 'white',
    color: selected ? 'blue' : 'black',
  };
}

export const useSelectStyles = makeStyles({
  control: {
    marginBottom: '1.44px',
    height: '38px',
    width: '100%',
    border: '1px solid #CED4DA',
    borderRadius: '0.25rem',
    color: '#495057',
    backgroundColor: 'white',
    paddingRight: '1rem',
    paddingLeft: '1rem',
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const SelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
