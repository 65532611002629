import React from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import { Notification, } from 'rsuite';
import styled from 'styled-components';


export const CSVFileContainer = styled.div`
  width: 100%;
  height: 15%;
  border-radius: 0.75rem;
  background: transparent;
  align-items: center;
  justify-content: center;

  input {
    display: none;
    height: 100%;
    width: 100%;
  }

  label {
    height: 15%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(59, 102, 255);
    font-weight: bold;
    font-size: 13px;
    cursor: pointer;

    span {
      font-family: Inter;
      font-weight: normal;
      font-size: 11px;
      color: #54708C;
    }
}`;


function UploadFile({ onChange, title, subtitle, image, }) {
  const { t, } = useTranslation();


  return (
    <CSVFileContainer>
      <label
        for="upload-file-input"
        style={{
          textAlign: 'center',
        }}
      >
        { undefined != image ? image : null }
        { title }
        <span>
          { subtitle }
        </span>
      </label>

      <input
        id="upload-file-input"
        type="file"
        onChange={(event) => {
          if (event.target.files && event.target.files[0].size >= 102400000) {
            Notification['warning']({
              placement: 'bottomEnd',
              title: _.capitalize(t('ERRORS.select_a_smaller_file')),
            });
          } else {
            onChange(event.target.files[0]);
          }
        }}
      />
    </CSVFileContainer>
  );
}

export default UploadFile;
