import React, { useEffect, useState } from "react";

import AppBarWeb from "./Web/AppBar";
import AppBarMobile from "./Mobile/AppBar";
import { useThemeContext, } from "../../../context/ThemeAppContext";
import { TypeDevices, } from "../../../entities/app-bar";

const DEVICE_MAP = Object.freeze({
    [TypeDevices.WEB]: AppBarWeb,
    [TypeDevices.MOBILE]: AppBarMobile
});



export default function AppBarMobileOrWeb({ data, }) {

    const MenuWeb = DEVICE_MAP['WEB'];
    const MenuMobile = DEVICE_MAP['MOBILE'];
    return (
        <MenuWeb data={data} />
    );
}

