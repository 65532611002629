import React from 'react';
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';

import { Icon, } from 'rsuite';
import { Avatar, Chip, Container, IconButton, } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import DeleteDialog from '../DeleteDialog';

import styles from './styles.module.scss';
import useModal from '../../hooks/useModal';


function ObservationItem({ obs, users, collaborators, onDelete, hideDelete, }) {
  const { t, } = useTranslation();
  const poster = React.useMemo(() => {
    let ret = _.capitalize(t('observation', {
      count: 1,
    }));

    let poster = undefined;
    if (undefined != obs.user_id) {
      poster = users.find((item) => item._id == obs.user_id);
    } else if (undefined != obs.collaborator_id) {
      poster = collaborators.find((item) => item.id == obs.collaborator_id);
    }

    if (undefined != poster) {
      ret = poster.name;
    }

    return ret;
  }, [obs, users, collaborators]);


  return (
    <div className={styles.ObservationContainer}>
      <div className={styles.PosterContainer}>
        <Chip
          variant="filled"
          style={{
            border: 'none',
          }}
          avatar={
            <Avatar>
              <Icon icon="user" />
            </Avatar>
          }
          label={`${poster} - ${moment(obs.data).format('DD/MM/YYYY HH:mm')}`}
        />

        {
          hideDelete ?
          null :
          <IconButton
            size="small"
            color="secondary"
            onClick={() => onDelete(obs.id)}
          >
            <DeleteIcon
              style={{
                fontSize: 15,
              }}
            />
          </IconButton>
        }
      </div>
      <p>
        { obs.observacao }
      </p>
    </div>
  );
}


export default function QuestionObservationList({ observations, users, collaborators, onDelete, }) {
  const { t, } = useTranslation();
  const [selectedObsId, setSelectedObsId] = React.useState(undefined);
  const [delModalOpen, openDelModal, closeDelModal] = useModal((obsId) => {
    setSelectedObsId(obsId);
  }, () => {
    setSelectedObsId(undefined);
  });


  function _onDelete() {
    onDelete(selectedObsId);
    closeDelModal();
  }


  return (
    <Container
      maxWidth="xl"
      disableGutters
      className={styles.ObservationList}
    >
      {
        observations.map((obs) => {
          return (
            <ObservationItem
              key={obs.id}
              obs={obs}
              users={users}
              collaborators={collaborators}
              hideDelete={undefined == onDelete}
              onDelete={(obsId) => openDelModal(obsId)}
            />
          );
        })
      }

      <DeleteDialog
        open={delModalOpen}
        confirmationMessage={_.capitalize(t('PAGES.QUESTION_ITEM_CARD.delete_observation_confirmation'))}
        onClose={closeDelModal}
        onConfirm={() => _onDelete(selectedObsId)}
      />
    </Container>
  );
}
