import { useState, } from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';


export default function DeleteDialog({
  open,
  confirmationMessage,
  onClose,
  onConfirm,
  secondaryText,
}) {
  const [loading, setLoading] = useState(false);


  const onCancel = () => {
    onClose();
  }

  const __onConfirm = async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
  }


  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          width: '500px',
          maxWidth: '90%',
        },
      }}
    >
      <DialogContent>
        <Typography
          variant="body1"
          display="block"
          align="center"
          style={{
            fontWeight: undefined != secondaryText ? 500 : 'normal',
          }}
        >
          {confirmationMessage}
        </Typography>

        {
          undefined != secondaryText ?
          <Typography
            variant="body2"
            display="block"
            align="center"
            style={{
              marginTop: '0.25rem',
            }}
          >
            {secondaryText}
          </Typography> :
          null
        }
      </DialogContent>

      <DialogActions>
      <Grid
        container
        justifyContent="center"
      >
          <Button
            onClick={onCancel}
            style={{
              marginRight: '0.5rem',
              borderColor: "black",
            }}
            variant="outlined"
          >
            Cancelar
          </Button>

          <Button
            onClick={__onConfirm}
            variant="contained"
            style={{
              backgroundColor: "red",
              color: 'white'
            }}
            disableElevation={true}
            loading={loading}
          >
            Confirmar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
