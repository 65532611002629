import React, { useState, useEffect, } from "react";
import { Typography, } from '@material-ui/core';
import { modeBanner, InternalBtn, } from "./Common";

export default function DefaultBanner({ data }) {

  const [theme, setTheme] = useState(modeBanner(data));

  useEffect(() => {
    setTheme(modeBanner(data));
  }, [data]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          borderRadius: 20,
          border: "1px solid rgba(204, 210, 233, 0.5)", padding: "10px 20px",
          marginBottom: "20px", background: theme.background, width: "auto",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography
            variant="subtitle1"
            component="div"
            color="primary"
            style={{ fontWeight: 700, color: theme?.color, marginBottom: "20px" }}
          >
            Preferências
          </Typography>

          <Typography>
            <img src={theme.srcImage} alt="Logo octo" />
          </Typography>
        </div>

        <div container>
          <Typography
            variant="subtitle2"
            component="div"
            color="primary"
            style={{ fontWeight: 600, color: theme?.color, marginBottom: "10px" }}
          >
            Usamos cookies para melhorar sua navegação.
          </Typography>

          <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <Typography variant="subtitle2" style={{ color: theme?.color, textDecoration: 'underline', marginRight: "10px" }}>
                Aviso de Privacidade
              </Typography >

              <Typography variant="subtitle2" style={{ color: theme?.color, textDecoration: 'underline', marginRight: "10px" }}>
                Termos de uso
              </Typography>
            </div>
            <Typography variant="subtitle2" style={{ color: theme?.color, textDecoration: 'underline' }}>
              Falar com DPO
            </Typography>
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <InternalBtn theme={theme} text={"Opções"} />
            <InternalBtn theme={theme} text={"Rejeitar"} />
            <InternalBtn theme={theme} text={"Aceitar"} type="filled" />
          </div>
        </div>
      </div>
    </div>
  )
}
