import React from 'react';

import {MenuItem, Input, Select, FormControl,} from '@material-ui/core';

import BaseField from './BaseField';

import {
    useSelectStyles,
    parseFieldResponse,
    stringifyFieldResponse,
    getMultipleSelectItemsStyles,
    SelectMenuProps,
} from './utils';


/**
 * It uses the same props as BaseField component
 */
export default function LegalBasesField(props) {
    const classes = useSelectStyles();


    return (
        <BaseField
            {...props}
            render={(_resp, onChangeResp) => {
                const resp = parseFieldResponse(_resp).map(i => Number(i));

                console.log(props.field);
                

                return (
                    <FormControl className={classes.control}>
                        <Select
                            multiple
                            classes={{
                                root: classes.select,
                            }}
                            value={resp}
                            name="select"
                            onChange={(ev) => {
                                onChangeResp(stringifyFieldResponse(ev.target.value));
                            }}
                            input={<Input/>}
                            disableUnderline
                            MenuProps={SelectMenuProps}
                            disabled={props.disabled}
                        >
                            {
                                (props.field.options || []).map((opt) => (
                                    <MenuItem
                                        key={opt.id}
                                        value={opt.id}
                                        style={getMultipleSelectItemsStyles(opt.id, resp)}
                                    >
                                        {opt.title}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                );
            }}
        />
    );
}
