import styled from "styled-components";

export const MainWorkshopInputDescription = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 1.5rem 2rem 2rem 2rem;
  margin: 0;
`;

export const InputTitleWorckshopDescription = styled.div`
  top: 0;
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;

  h1 {
    top: 0;
    color: rgb(59, 102, 255);
    text-align: left;
    font-size: 1.5rem;
    line-height: 1.5rem;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
  }

  input {
    border: none;
    border-radius: 0.25rem;
    width: 100%;
    height: 2rem;
    margin: 0.5rem 0;
    padding-left: 0.7rem;
  }

  p {
    text-align: left;
    padding: 0;
    margin: 0.5rem  0;
  }
`;

export const BoxBTNAll = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BTNNewReunion = styled.button`
  width: 150px;
  max-width: 150px;
  height: 35px;
  border: 2px solid rgb(59, 102, 255);
  background: #ffffff;
  border-radius: 4px;

  font-family: Inter, sans-serif;
  font-weight: 600;
  color: rgb(59, 102, 255);

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9)
  }
`;

