import React, { useState, useEffect, } from 'react';
import { Form, } from 'react-bootstrap';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import Button from '../Button';
import './styles.css';


const INITIAL_VALUES = Object.freeze({
  problem: '',
  action: '',
  sector: '',
});


const TemplateQuestionForm = ({ data, onSubmit, sectors, categories, }) => {
  const [loading, setLoading] = useState(false);
  const [newSector, setNewSector] = useState(false);
  const [newCategory, setNewCategory] = useState(false);
  const { t, } = useTranslation();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: handleSubmit,
    validationSchema: Yup.object({
      problem: Yup.string().required(_.capitalize(t('VALIDATION_MESSAGES.required', { context: 'male' }))),
      action: Yup.string().required(_.capitalize(t('VALIDATION_MESSAGES.required', { context: 'female' }))).nullable(),
      sector: Yup.string().required(_.capitalize(t('VALIDATION_MESSAGES.required', { context: 'male' }))).nullable(),
      category: Yup.string().required(_.capitalize(t('VALIDATION_MESSAGES.required', { context: 'female' }))).nullable(),
    }),
  });


  async function handleSubmit(values) {
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
  }

  function __onSubmit(event) {
    event.preventDefault();
    formik.handleSubmit();
  }

  function toggleNewSector() {
    setNewSector((val) => !val);
  }

  function toggleNewCategory() {
    setNewCategory((val) => !val);
  }

  useEffect(() => {
    if (data && _.isObject(data)) {
      formik.setValues({
        problem: data.problema,
        action: data.acao,
        sector: (null == data.setor) ? 0 : data.setor,
        category: (null == data.category) ? 0 : data.category,
      });
    }
  }, [data]);


  return (
    <>
      <form className="maturityQuestionFormContainer">
        <Form.Group className="mb-3">
          <Form.Label className="inputLabel">
            {_.capitalize(t('question'))}
          </Form.Label>
          <Form.Control
            className="formInput"
            as="textarea"
            id="problem"
            isInvalid={formik.touched.problem && formik.errors.problem}
            {...formik.getFieldProps('problem')}
          />

          <Form.Control.Feedback type="invalid">
            { formik.errors.problem }
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="inputLabel">
            {_.capitalize(t('action'))}
          </Form.Label>
          <Form.Control
            className="formInput"
            as="textarea"
            id="action"
            isInvalid={formik.touched.action && formik.errors.action}
            {...formik.getFieldProps('action')}
          />

          <Form.Control.Feedback type="invalid">
            { formik.errors.action }
          </Form.Control.Feedback>
        </Form.Group>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={true}
          >
            {
              !newSector ?
              <Form.Group className="mb-3">
                <Form.Label className="inputLabel">
                  {_.capitalize(t('sector'))}
                </Form.Label>
                <Form.Control
                  className="formInput"
                  as="select"
                  id="sector"
                  isInvalid={formik.touched.sector && formik.errors.sector}
                  {...formik.getFieldProps('sector')}
                >
                  <option></option>
                  {
                    sectors.map((sector, idx) => {
                      return (
                        <option key={idx}>
                          { sector }
                        </option>
                      );
                    })
                  }
                </Form.Control>

                <Form.Control.Feedback type="invalid">
                  { formik.errors.sector }
                </Form.Control.Feedback>
              </Form.Group> :
              <Form.Group className="mb-3">
                <Form.Label className="inputLabel">
                  {_.capitalize(t('sector'))}
                </Form.Label>
                <Form.Control
                  className="formInput"
                  id="sector"
                  isInvalid={formik.touched.sector && formik.errors.sector}
                  {...formik.getFieldProps('sector')}
                />

                <Form.Control.Feedback type="invalid">
                  { formik.errors.sector }
                </Form.Control.Feedback>
              </Form.Group>
            }
          </Grid>
          <Grid
            item
            xs={false}
          >
            <IconButton onClick={toggleNewSector}>
              {
                !newSector ?
                <AddCircleIcon /> :
                <CancelIcon/>
              }
            </IconButton>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={true}
          >
            {
              !newCategory ?
              <Form.Group className="mb-3">
                <Form.Label className="inputLabel">
                  {_.capitalize(t('category'))}
                </Form.Label>
                <Form.Control
                  className="formInput"
                  as="select"
                  id="category"
                  isInvalid={formik.touched.category && formik.errors.category}
                  {...formik.getFieldProps('category')}
                >
                  <option></option>
                  {
                    categories.map((category, idx) => {
                      return (
                        <option key={idx}>
                          { category }
                        </option>
                      );
                    })
                  }
                </Form.Control>

                <Form.Control.Feedback type="invalid">
                  { formik.errors.category }
                </Form.Control.Feedback>
              </Form.Group> :
              <Form.Group className="mb-3">
                <Form.Label className="inputLabel">
                  {_.capitalize(t('category'))}
                </Form.Label>
                <Form.Control
                  className="formInput"
                  id="category"
                  isInvalid={formik.touched.category && formik.errors.category}
                  {...formik.getFieldProps('category')}
                />

                <Form.Control.Feedback type="invalid">
                  { formik.errors.category }
                </Form.Control.Feedback>
              </Form.Group>
            }
          </Grid>
          <Grid
            item
            xs={false}
          >
            <IconButton onClick={toggleNewCategory}>
              {
                !newCategory ?
                <AddCircleIcon /> :
                <CancelIcon/>
              }
            </IconButton>
          </Grid>
        </Grid>
      </form>

      <div className="buttonContainer">
        <Button
          className="updateButton"
          onClick={__onSubmit}
          loading={loading}
          color="primary"
          variant="contained"
        >
          {_.capitalize(t('save'))}
        </Button>
      </div>
    </>
  );
}

export default TemplateQuestionForm;
