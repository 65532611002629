import React from 'react';
import { Grid, Typography, Button, IconButton, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Close, } from '@material-ui/icons';
import bgRegister from '../../assets/background_cadastrar.svg';


const useStyles = makeStyles({
  container: {
    margin: 0,
    minWidth: '75%',
    background: '#8DA6FF',
    position: 'relative',
    height: '100%',
  },
  alertModal: {
    background: '#8DA6FF',
    zIndex: 1,
    padding: '4rem 10rem 4rem',
    boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
  },
  closeButton: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    '& .MuiIconButton-label': {
      color: '#fff !important',
    },
  },
  alertModalTitle: {
    color: '#fff',
    fontFamily: 'Sora',
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: '1.3',
    textAlign: 'center',
  },
  divider: {
    background: '#fff',
    width: '50%',
    margin: '1rem auto 2rem ',
    height: '2px',
  },
  alertModalSubTitle: {
    color: '#fff',
    fontFamily: 'Sora',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.8',
    textAlign: 'center',
  },
  button: {
    color: '#fff',
    display: 'block',
    border: '1px solid #fff',
    paddingInline: '2rem',
    margin: 'auto',
    marginTop: '1rem',
    width: '100%',
    maxWidth: 250,
  },
  alertModalButton: {
    background: 'transparent',
    '&:hover': {
      color: '#fff ',
      background: '#54708C',
      border: '1px solid #54708C',
    },
  },
  loginButton: {
    background: '#3B66FF',
    '&:hover': {
      color: '#fff ',
      background: '#3B66FF',
      border: '1px solid #3B66FF',
    },
  }
});


const ModalSuccess = ({ openNewLogin }) => {
  const classes = useStyles();


  return (
    <Grid
      container
      className={classes.alertModal}
      id="alertModal"
    >
      <Grid item>
        <div
          className={classes.closeButton}
          onClick={openNewLogin}
        >
          <IconButton>
            <Close />
          </IconButton>
        </div>
        <img
          src={bgRegister}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: -1,
            width: '100%',
          }}
        />
        <Typography
          variant="body1"
          className={classes.alertModalTitle}
        >
          Sua solicitação de cadastro foi <br />
          efetuada com sucesso.
        </Typography>
        <div className={classes.divider}></div>
        <Typography
          variant="body2"
          className={classes.alertModalSubTitle}
        >
          Em breve, você receberá um e-mail <br />
          para ativação da sua conta.
        </Typography>

        <div>
          <Button
            variant="outlined"
            className={clsx(classes.button, classes.loginButton)}
            onClick={openNewLogin}
          >
            Login
          </Button>

          <a
            href="https://wa.me/5541988377401"
            target="_blank"
            style={{
              textDecoration: 'none',
            }}
          >
            <Button
              variant="outlined"
              className={clsx(classes.button, classes.alertModalButton)}
            >
              Suporte Whatsapp
            </Button>
          </a>
        </div>
      </Grid>
    </Grid>
  );
}

export default ModalSuccess;
