import React, { useEffect, useState, memo, } from 'react';
import { MenuItem, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FormControl, InputLabel, Select } from '@mui/material';


const useStyles = makeStyles({
  container: {
    minWidth: 250,
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  input: {
    borderRadius: 500,
    maxHeight: 24,
    backgroundColor: 'white',
  },
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const Dropdown = ({ items, value, setValue, defaultItem, labelKey, valueKey, disabled = false, ...props }) => {
  const classes = useStyles();
  const [model, setModel] = useState(value == null ? '' : value);

  const onChange = (selected) => {
    setModel(selected);
    setValue(selected);
  }

  useEffect(() => {
    setModel(value == null ? '' : value);
  }, [value]);

  return (
    <Grid
      className={classes.container}
      {...props}
    >
      <FormControl variant="outlined" fullWidth size="small" disabled={disabled}>
        <InputLabel id="demo-simple-select-outlined-label">{defaultItem}</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          label={defaultItem}
          id="demo-simple-select-outlined"
          value={model || ''}
          onChange={(ev) => onChange(ev.target.value)}
          style={{ background: "#fff" }}
          MenuProps={MenuProps}
        >
          {defaultItem != undefined ?
            <MenuItem
              value=""
            >
              <em>{defaultItem}</em>
            </MenuItem>
            : null
          }

          {items.map((item) => {
            return (
              <MenuItem
                key={null != valueKey ? item[valueKey] : item.id}
                value={null != valueKey ? item[valueKey] : item.id}
              >
                {labelKey !== undefined ? item[labelKey] : item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
}

export default memo(Dropdown);
