import React from "react";
import Icon from "../../assets/+.svg"

export default function Plus() {
    return (
        <img
            className="BoxIconSvg"
            src={Icon}
            width="14px"
            height="14px"
        />
    )
}