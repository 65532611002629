import {Card, CardContent, CardHeader, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import SearchField from "../SearchField";
import imgLogo from "../../assets/relatorio_logo.svg";
import LimitedBackdropLoader from "../LimitedBackdropLoader";
import React, {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {selectCompany} from "../../features/companySlice";
import _ from "lodash";
import {checkSearch} from "../../utils";
import { listCompanyDataMappingReport } from "../../services/api";
import DataMappingAnswerPanelContext from "./context";
import {Icon, IconButton, Notification} from "rsuite";
import DataMappingLineField from "./Field/DataMappingLineField";
import Pagination from "@material-ui/lab/Pagination";

const COLORS = ['#FFB54D', '#54708C', '#B2DD7B', '#C0A1F2', '#F0988E'];
const defaultColor = '#54708C';

const useStyles = makeStyles({
  reportContainer: {
    padding: '2rem',
    background: '#fff',
  },
  title: {
    color: defaultColor,
    fontWeight: 600,
    fontSize: '1rem',
  },
  dateSpan: {
    fontWeight: 'normal',
  },
  reportHeader: {
    marginBottom: 15,
  },
  reportPhaseCard: {
    marginBottom: 20,
    borderRadius: 25,
  },
  reportFieldCard: {
    marginBottom: 10,
  },
  reportFieldName: {
    color: '#FFFFFF',
    fontWeight: 550,
  },
  reportFieldAnswer: {
    color: defaultColor,
    padding: 7,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: '#F2F4F8',
  },
  defaultColor: {
    color: defaultColor,
  },
});


export default function DataMappingListMode({ phases,orderByField }) {
  const context = React.useContext(DataMappingAnswerPanelContext);
  const { listPhaseFields, listAnswers, onCreateAnswer, onDeleteAnswer, filters } = context;
  const classes = useStyles();
  const company = useSelector(selectCompany);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(10);
  const [phaseFields, setPhaseFields] = useState([]);
  const [reportPage, setReportPage] = useState({
    phases: [],
  });
  const [loadingBT, setLoadingBT] = useState(false);

  const [search, setSearch] = useState('');
  const { report, } = useSelector((state) => state.customMappingReport);
  const customReport = useMemo(() => (report || {}).id, [report]);
  const [prevCustomReport, setPrevCustomReport] = useState(customReport);
  if (prevCustomReport != customReport) {
    if (0 !== page) {
      setPage(0);
    }
    setPrevCustomReport(customReport);
  }

  const filteredReportPage = useMemo(() => {
    let currReportPage = _.cloneDeep(reportPage);

    if (report.id !== null) {
      currReportPage.phases = currReportPage.phases
        .map((phase) => {
          const fields = phase.fields
            .filter(({ fieldId, }) => report.items.includes(fieldId));
          return {
            ...phase,
            fields,
          };
        })
        .filter(({ fields, }) => fields.length > 0);
    }

    if (search.length > 0) {
      currReportPage = {
        phases: currReportPage.phases
          .map(({ name, fields, }) => {
            const filteredFields = fields
              .filter(({ campo, resp, }) => {
                return checkSearch(campo, search) || checkSearch(resp, search);
              });
            const phaseNameMatchesSearch = checkSearch(name, search);

            return {
              name,
              fields: (filteredFields.length === 0) && phaseNameMatchesSearch ?
                fields : filteredFields,
            };
          })
          .filter(({ fields, }) => fields.length > 0),
      };
    }

    return currReportPage;
  }, [search, reportPage, report]);

  async function loadPhaseFields() {
    setLoading(true);

    try {
      const request = phases.map(phase => listPhaseFields(phase.id));
      const fields = await Promise.all(request);
      setPhaseFields(fields);
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar informações do campo!',
      });
    }
    setLoading(false);
    setLoadingBT(false);
  }

  useEffect(() => {
    loadPhaseFields();
  }, [phases]);


  async function getReportPage() {
    setLoading(true);

    try {
      const { data, } = await listCompanyDataMappingReport(company._id, page, 1, orderByField,
        customReport);
      const { report, total, } = data;
      setTotalPages(total);
      if (report.length > 0) {
        const firstElement = _.first(report);
        if(!firstElement) return
        setReportPage({...firstElement, phases: firstElement.phases.map(phase => ({...phase, fields: phase.fields.sort((a, b) => a.ordem - b.ordem) }))});
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  }

  useEffect(() => {
    getReportPage();
  }, [customReport, orderByField, page]);

  function changePage(newPage) {
    setPage(newPage - 1);
  }

  async function createNewPage() {
    await onCreateAnswer(null, null);
    await getReportPage();
    setPage(totalPages);
  }

  async function createNewNextPage() {
    await onCreateAnswer(page + 1, 'down');
    await getReportPage();
    setPage(page + 1);
  }

  async function deletePage() {
    const firstElement = _.first(filteredReportPage.phases);

    if(!firstElement) return;

    const fieldFirstElement = _.first(firstElement.fields);

    if(!fieldFirstElement) return;
    await onDeleteAnswer(fieldFirstElement.uid);
    await getReportPage();
    if((page + 1) === totalPages && totalPages > 1) {
      changePage(page);
    }
  }

  const updateFieldOptions = (fieldId, newOption) => {
    const updatedPhaseFields = _.cloneDeep(phaseFields);
    const fieldIdx = updatedPhaseFields.findIndex(({ id, }) => id === fieldId);
    if (fieldIdx > -1 && Array.isArray(updatedPhaseFields[fieldIdx].options)) {
      updatedPhaseFields[fieldIdx].options.push(newOption);
      setPhaseFields(updatedPhaseFields);
    }
  }

  function findFieldOptions(field, index) {
    const phaseOptions = phaseFields[index];
    const findField = phaseOptions.find((phase) => phase.id === field.fieldId);
    return findField || null;
  }
  return (
    <Grid
      className={classes.reportContainer}
      style={{
        position: 'relative'
      }}
    >
      <Grid
        className={classes.reportHeader}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <SearchField
          value={search}
          label="Pesquisar abas"
          onChange={setSearch}
        />

        <img
          src={imgLogo}
          style={{
            height: '50px',
          }}
        />
      </Grid>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0.5rem' }}>
        <Pagination count={totalPages} page={(page + 1)} onChange={(_, value) => changePage(value)} color={"primary"} />
      </div>

      {
        totalPages ? filteredReportPage.phases.map((phase, index) => {
          //? Phase card color
          const color = COLORS[index%COLORS.length];

          return (
            <Card
              className={classes.reportPhaseCard}
              key={phase.name}
            >
              <CardHeader
                style={{
                  borderTop: `8.65px solid ${color}`,
                  textAlign: 'center',
                  paddingBottom: 0,
                  paddingTop: 2,
                }}
                titleTypographyProps={{
                  style: {
                    fontWeight: 500,
                    color: color,
                  }
                }}
                title={phase.name}
              />

              <CardContent
                style={{
                  paddingBottom: 10,
                }}
              >
                {
                  phase.fields.map((field) =>
                    {
                      return (
                        <Card
                          className={classes.reportFieldCard}
                          elevation={0}
                          key={field.campo}
                        >
                          <Grid
                            container
                            spacing={0}
                          >
                            {/* Do not change this component to item, it was configured this to
                          center the text inside it. Only change this if you find another way to
                          center the text.
                          */}

                            <Grid
                              xs={3}
                              lg={4}
                              container
                              alignItems="center"
                              style={{
                                backgroundColor: color,
                                padding: 7,
                              }}
                            >
                              <Typography
                                variant="body1"
                                className={classes.reportFieldName}
                              >
                                {field.campo}
                              </Typography>
                            </Grid>

                            <Grid
                              xs={9}
                              lg={8}
                              item
                              className={classes.reportFieldAnswer}
                            >
                              <DataMappingLineField
                                token={context.token}
                                isPublic={context.isPublic}
                                answer={field}
                                field={findFieldOptions(field, index)}
                                updateOptions={(newOption) => updateFieldOptions(field.id, newOption)}
                                key={field.uid}
                                style={{
                                  width: '100%',
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Card>
                      )
                    }
                  )
                }
              </CardContent>
            </Card>
          );
        }) : <div style={{ textAlign: 'center' }}>
          <div>Não há páginas disponíveis.</div>
          <div>Por favor, adicione novas páginas.</div>
        </div>
      }

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0.5rem' }}>
        <Pagination count={totalPages} page={(page + 1)} onChange={(_, value) => changePage(value)} color={"primary"} />
      </div>

      <LimitedBackdropLoader
        open={loading}
        invisible
      />

      <IconButton
        loading={loadingBT || loading}
        icon={
          <Icon
            icon="plus"
            style={{
              backgroundColor: '#54708C',
            }}
          />
        }
        appearance="primary"
        size="sm"
        onClick={() => createNewPage()}
        style={{
          backgroundColor: '#54708C',
        }}
      >
        Nova página
      </IconButton>

      <IconButton
        loading={loadingBT || loading}
        icon={
          <Icon
            icon="plus"
            style={{
              backgroundColor: '#54708C',
            }}
          />
        }
        appearance="primary"
        size="sm"
        onClick={() =>  createNewNextPage()}
        style={{
          backgroundColor: '#54708C',
          marginLeft: '0.5rem',
        }}
      >
        Nova página a seguir
      </IconButton>

      <IconButton
        loading={loadingBT || loading}
        icon={
          <Icon
            icon="trash"
            style={{
              backgroundColor: '#54708C',
            }}
          />
        }
        appearance="primary"
        size="sm"
        onClick={() =>  deletePage()}
        style={{
          backgroundColor: '#54708C',
          marginLeft: '0.5rem',
        }}
      >
        Deletar página
      </IconButton>
    </Grid>
  );
}
