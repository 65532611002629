import React, { memo, } from 'react';
import { useSelector, } from 'react-redux';

import { Notification } from 'rsuite';

import { createMaturityQuestion, } from '../../services/api';

import MaturityQuestionForm from '../../components/MaturityQuestionForm';

import { selectCompany, } from '../../features/companySlice';


function CreateQuestion({ closeModal, }) {
  const company = useSelector(selectCompany);


  async function handleCreateQuestion(values) {
    try {
      await createMaturityQuestion(company._id, {
        problem: values.problem,
        sector_id: Number(values.sector),
        action: values.action,
        risk: values.risk,
        category: values.category,
      });

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Tarefa criada com sucesso!',
      });

      closeModal();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao criar tarefa!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }
  }

  return (
    <div style={{ padding: '20px' }}>
      <MaturityQuestionForm
        onSubmit={handleCreateQuestion}
      />
    </div>
  );
}

export default memo(CreateQuestion);
