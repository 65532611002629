import React from 'react';
import { useTranslation, } from 'react-i18next';

import { TextField, Typography, } from '@material-ui/core';

import { WidgetType, } from '../../entities/impact-report-widget';


export default function ObjectiveWidget({ data, readOnly, onChange, }) {
  const { t, } = useTranslation();


  function handleChange(ev) {
    onChange({
      ...data,
      model: {
        ...data.model,
        [ev.target.name]: ev.target.value,
      },
    });
  }


  return (
    <>
      <Typography
        variant="h5"
        style={{
          marginBottom: '20px',
          textAlign: 'center',
        }}
      >
        { t(`PAGES.IMPACT_REPORTS.v2.WIDGETS.${WidgetType.OBJECTIVE}.label`) }
      </Typography>

      {
        readOnly ?
        <Typography
          display="block"
          style={{
            whiteSpace: 'pre-line',
          }}
        >
          {data.model.content}
        </Typography> :
        <TextField
          multiline
          fullWidth
          value={data.model.content}
          variant="outlined"
          name="content"
          size="small"
          onChange={handleChange}
        />
      }
    </>
  );
}
