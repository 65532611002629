import { useState, useEffect, } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import _ from 'lodash';

import { Container, Paper, Typography, IconButton, Grid, } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import ImpactReportReviewHistoryTable from '../../components/ImpactReportReviewHistoryTable';

import {
  selectCurrentReportId,
  selectCurrentReport,
  getImpactReport,
} from '../../features/impactReportsSlice';


/**
 * Impact report review history component
 *
 * @returns Rendered page
 */
export default function ImpactReportReviewHistory() {
  const reportId = useSelector(selectCurrentReportId);
  const report = useSelector(selectCurrentReport);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);


  useEffect(() => {
    if (report != null) {
      dispatch(getImpactReport(report.id));
    }
  }, [reportId]);


  return (
    <Container
    maxWidth="xl"
    component={Paper}
    style={{
      marginTop: '3rem',
      marginBottom: '3rem',
    }}
    disableGutters
  >
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      style={{
        backgroundColor: '#0000FF',
      }}
      onClick={() => setOpen(!open)}
    >
      <IconButton>
        { open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
      </IconButton>
    </Grid>

    {
      open ?
      <div
        style={{
          paddingBottom: '1rem',
        }}
      >
        <Typography
          variant="h5"
          align="center"
          display="block"
        >
          Histórico de Revisões
        </Typography>

        <ImpactReportReviewHistoryTable />
      </div> :
      null
    }
  </Container>
  );
}
