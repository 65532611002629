import React from 'react';
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';

import { Notification, } from 'rsuite';

import EditDataMappingContext from '../../components/EditDataMapping/context';

import * as api from '../../services/api';


export default function EditDataMappingTemplateContextProvider({
  children,
  dataMappingTemplate,
  isGlobal = false,
}) {
  const { t, } = useTranslation();
  const [phases, setPhases] = React.useState([]);
  const [loading, setLoading] = React.useState(true);


  React.useEffect(() => {
    if ((undefined != dataMappingTemplate) && (undefined != dataMappingTemplate.id)) {
      listPhases();
    }
  }, [dataMappingTemplate]);

  async function listPhases() {
    setLoading(true);
    try {
      const func = isGlobal ? api.listGlobalDataMappingTemplatePhases :
        api.listDataMappingTemplatePhases;
      const { data, } = await func(dataMappingTemplate.id);
      setPhases(data.tabs);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DATA_MAPPING.failed_to_list_phases')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
    setLoading(false);
  }

  async function deletePhase(phaseId) {
    let ret = false;
    try {
      if (isGlobal) {
        await api.deleteGlobalDataMappingTemplatePhase(dataMappingTemplate.id, phaseId);
      } else {
        await api.deleteDataMappingTemplatePhase(dataMappingTemplate.id, phaseId);
      }
      Notification['success']({
        title: _.capitalize(t('PAGES.DATA_MAPPING.phase_successfully_deleted')),
        placement: 'bottomEnd',
      });
      setPhases(phases.filter((phase) => phase.id != phaseId));
      ret = true;
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DATA_MAPPING.failed_to_delete_phase')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }

    return ret;
  }

  async function updatePhase(updatedPhase) {
    try {
      if (isGlobal) {
        await api.updateGlobalDataMappingTemplatePhase(dataMappingTemplate.id, updatedPhase);
      } else {
        await api.updateDataMappingTemplatePhase(dataMappingTemplate.id, updatedPhase);
      }
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DATA_MAPPING.phase_successfully_updated')),
      });

      const _phases = [...phases];
      const idx = _phases.findIndex((phase) => updatedPhase.id == phase.id);
      if (idx > -1) {
        _phases[idx] = {
          ..._phases[idx],
          ...updatedPhase,
        };
        setPhases(_phases);
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DATA_MAPPING.failed_to_update_phase')),
        description: _.capitalize(t('ERRORS.try_again_later')),
    });
    }
  }

  async function createPhase(phase) {
    try {
      const func = isGlobal ? api.createGlobalDataMappingTemplatePhase :
        api.createDataMappingTemplatePhase;
      const { data, } = await func(dataMappingTemplate.id, phase);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DATA_MAPPING.phase_successfully_created')),
      });

      setPhases([
        ...phases,
        data,
      ]);
      return data;
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DATA_MAPPING.failed_to_create_phase')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function createField(phase, field) {
    try {
      const func = isGlobal ? api.createGlobalDataMappingTemplatePhaseField :
        api.createDataMappingTemplatePhaseField;
      const { data, } = await func(dataMappingTemplate.id, phase.id, {
          ...field,
          order: field.ordem,
      });
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DATA_MAPPING.field_successfully_created')),
      });

      return data;
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DATA_MAPPING.failed_to_create_field')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function updateField(phase, updatedField) {
    try {
      const func = isGlobal ? api.updateGlobalDataMappingTemplatePhaseField :
        api.updateDataMappingTemplatePhaseField;
      const { data, } = await func(dataMappingTemplate.id, phase.id, {
        ...updatedField,
        order: updatedField.ordem || updatedField.order,
      });

      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DATA_MAPPING.field_successfully_updated')),
      });

      return {
        ...data,
        ordem: data.order,
      };
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DATA_MAPPING.failed_to_update_field')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function deleteTabField(phaseId, fieldId) {
    let ret = false;

    try {
      const func = isGlobal ? api.deleteGlobalDataMappingTemplatePhaseField :
        api.deleteDataMappingTemplatePhaseField;
      await func(dataMappingTemplate.id, phaseId,
        fieldId);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DATA_MAPPING.field_successfully_deleted')),
      });

      ret = true;
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DATA_MAPPING.failed_to_delete_field')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }

    return ret;
  }

  async function listTabFields(phaseId) {
    try {
      const func = isGlobal ? api.listGlobalDataMappingTemplatePhaseFields :
        api.listDataMappingTemplatePhaseFields;
      const { data, } = await func(dataMappingTemplate.id, phaseId);
      return data.fields.map((field) => ({
        ...field,
        ordem: field.order,
      }));
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DATA_MAPPING.failed_to_list_fields')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
      return [];
    }
  }

  async function updateTabFieldOrder(phase, field, updatedOrder) {
    try {
      const func = isGlobal ? api.updateGlobalDataMappingTemplatePhaseField :
        api.updateDataMappingTemplatePhaseField;
      await func(dataMappingTemplate.id, phase.id, {
        ...field,
        order: updatedOrder,
      });
    } catch {
      //TODO: handle error
    }
  }


  return (
    <EditDataMappingContext.Provider
      value={{
        phases,
        loading,
        deletePhase,
        updatePhase,
        createPhase,
        createField,
        updateField,
        deleteTabField,
        listTabFields,
        updateTabFieldOrder,
      }}
    >
      { children }
    </EditDataMappingContext.Provider>
  );
}
