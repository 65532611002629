import React from 'react';
import { useSelector, } from 'react-redux';
import { useTranslation, } from 'react-i18next';

import { Button, Container, Grid, Typography, } from '@material-ui/core';
import { Notification, } from 'rsuite';

import WidgetList from './WidgetList';
import Dialog from '../../../../components/Dialog';
import WidgetSelectionPanel from './WidgetSelectionPanel';
import RegisterImpactReportTemplateDialog from
  '../../../../components/RegisterImpactReportTemplateDialog/RegisterImpactReportTemplateDialog';

import useModal from '../../../../hooks/useModal';

import {
  selectCurrentReportId,
  selectCurrentVersionId,
  selectIsGlobal,
} from '../../../../features/impactReportsSlice';
import { selectCompany, } from '../../../../features/companySlice';

import {
  listGlobalImpactReportTemplateWidgets,
  createGlobalImpactReportTemplateWidget,
  updateGlobalImpactReportTemplateWidget,
  deleteGlobalImpactReportTemplateWidget,
  listCompanyImpactReportWidgets,
  createCompanyImpactReportWidget,
  updateCompanyImpactReportWidget,
  deleteCompanyImpactReportWidget,
} from '../../../../services/api';

import { WidgetType, } from '../../../../entities/impact-report-widget';


const DEFAULT_WIDGET_TO_CREATE_DATA = {
  model: {},
  type: WidgetType.TEXT,
  position: 0,
};


export default function ImpactReportEditorV2({ readOnly: _readOnly, isPrintMode, }) {
  const { t, } = useTranslation();
  const [panelOpen, onOpenPanel, onClosePanel] = useModal(() => {}, () => {
    widgetToCreate.current = { ...DEFAULT_WIDGET_TO_CREATE_DATA, };
  });
  const [
    registerTemplateOpen,
    onOpenRegisterTemplateDialog,
    onCloseRegisterTemplateDialog
  ] = useModal();
  const reportId = useSelector(selectCurrentReportId);
  const versionId = useSelector(selectCurrentVersionId);
  const isGlobal = useSelector(selectIsGlobal);
  const company = useSelector(selectCompany);
  const readOnly = _readOnly || Boolean(versionId);
  const [widgets, setWidgets] = React.useState([]);
  const widgetToCreate = React.useRef(DEFAULT_WIDGET_TO_CREATE_DATA);


  async function listWidgets() {
    try {
      const { data, } = await (isGlobal ?
        listGlobalImpactReportTemplateWidgets(reportId) :
        listCompanyImpactReportWidgets(company._id, reportId, versionId)
      );
      data.widgets.sort((a, b) => a.position - b.position);
      setWidgets(data.widgets);
      return true;
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: t('ERRORS.IMPACT_REPORTS.v2.failed_to_list_widgets'),
        description: t('ERRORS.try_again_later'),
      });
      return false;
    }
  }

  /**
   * Send request to create new widget
   */
  async function createWidget(data) {
    try {
      const { data: newWidget, } = await (isGlobal ?
        createGlobalImpactReportTemplateWidget(reportId, data) :
        createCompanyImpactReportWidget(company._id, reportId, data)
      );

      Notification['success']({
        placement: 'bottomEnd',
        title: t('PAGES.IMPACT_REPORTS.v2.widget_successfully_created'),
      });

      const updatedWidgets = [...widgets];
      updatedWidgets.splice(newWidget.position - 1, 0, newWidget);
      setWidgets(updatedWidgets.map((widgetData, idx) => ({
        ...widgetData,
        position: idx + 1,
      })));
      return true;
    } catch (error) {
      Notification['error']({
        placement: 'bottomEnd',
        title: t('ERRORS.IMPACT_REPORTS.v2.failed_to_create_widget'),
        description: t('ERRORS.try_again_later'),
      });
      return false;
    }
  }

  async function updateWidget(updatedData) {
    try {
      await (isGlobal ?
        updateGlobalImpactReportTemplateWidget(reportId, updatedData.id, updatedData) :
        updateCompanyImpactReportWidget(company._id, reportId, updatedData.id, updatedData)
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  async function deleteWidget(widgetId) {
    try {
      await (isGlobal ?
        deleteGlobalImpactReportTemplateWidget(reportId, widgetId) :
        deleteCompanyImpactReportWidget(company._id, reportId, widgetId)
      );

      setWidgets(widgets
        .filter((widget) => widgetId !== widget.id)
        .map((widget, idx) => ({
          ...widget,
          position: idx + 1,
        }))
      );

      Notification['success']({
        placement: 'bottomEnd',
        title: t('PAGES.IMPACT_REPORTS.v2.widget_successfully_deleted'),
      });
      return true;
    } catch (error) {
      Notification['error']({
        placement: 'bottomEnd',
        title: t('ERRORS.IMPACT_REPORTS.v2.failed_to_delete_widget'),
        description: t('ERRORS.try_again_later'),
      });
      return false;
    }
  }

  /**
   *
   * @param {ImpactReportWidgetData} reorderedWidget
   */
  async function onPositionChanged({ widgetId, position, }) {
    const reorderedWidgets = [...widgets];
    const prevWidgetIdx = reorderedWidgets.findIndex((w) => widgetId === w.id);
    if (undefined === prevWidgetIdx) {
      return;
    }
    const [reorderedWidget] = reorderedWidgets.splice(prevWidgetIdx, 1);

    if (updateWidget({ ...reorderedWidget, position, })) {
      reorderedWidgets.splice(position - 1, 0, reorderedWidget);
      setWidgets(reorderedWidgets.map((widgetData, idx) => ({
        ...widgetData,
        position: idx + 1,
      })));
    }
  }

  /**
   * Event handler
   *
   * @param {number | undefined} position - position in which the new widget should be, if
   * undefined the widget will be added to the end of the list
   */
  function onCreateWidget(position) {
    widgetToCreate.current = {
      ...DEFAULT_WIDGET_TO_CREATE_DATA,
      position: !position ? widgets.length + 1 : position,
    };
    onOpenPanel();
  }

  function onWidgetTypeSelected(widgetType) {
    createWidget({
      ...widgetToCreate.current,
      type: widgetType,
    });
    onClosePanel();
  }

  function onChange(updatedWidget) {
    if (readOnly) {
      return;
    }

    const updatedWidgets = [...widgets];
    const widgetIdx = updatedWidgets.findIndex((widget) => widget.id === updatedWidget.id);
    if (widgetIdx < 0) {
      return;
    }
    updatedWidgets[widgetIdx] = updatedWidget;
    setWidgets(updatedWidgets);
  }

  React.useEffect(() => {
    listWidgets();
  }, [reportId, versionId]);


  return (
    <Container
      disableGutters
      maxWidth="xl"
    >
      {
        !_readOnly && (versionId != null) ?
        <Typography
          variant="caption"
          display="block"
          align="right"
          color="error"
        >
          Edição desabilitada pois uma revisão foi selecionada!
        </Typography> :
        null
      }

      <WidgetList
        widgets={widgets}
        readOnly={readOnly}
        onPositionChanged={onPositionChanged}
        onCreateWidget={onCreateWidget}
        onDeleteWidget={deleteWidget}
        updateWidget={updateWidget}
        onChange={onChange}
        isPrintMode={isPrintMode}
      />

      {
        !readOnly ?
        <Grid
          container
          alignContent="center"
          justifyContent="flex-end"
          style={{
            margin: '0.5rem 0',
          }}
        >
          {
            !isGlobal ?
            <Button
              onClick={onOpenRegisterTemplateDialog}
              variant="contained"
              color="primary"
              disableElevation
            >
              Salvar como template
            </Button> :
            null
          }
        </Grid> :
        null
      }

      <Dialog
        open={panelOpen}
        onClose={onClosePanel}
        title={t('PAGES.IMPACT_REPORTS.v2.select_widget')}
      >
        <WidgetSelectionPanel onSelected={onWidgetTypeSelected} />
      </Dialog>

      <RegisterImpactReportTemplateDialog
        open={registerTemplateOpen}
        reportId={reportId}
        onClose={onCloseRegisterTemplateDialog}
      />
    </Container>
  );
}
