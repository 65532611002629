import styled from "styled-components";


export const ContentInfoRight = styled.div`
  grid-column-start: 2;
  grid-row-start: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin: -0.5rem 0 0 1rem;
  padding: 1rem;
`;

export const InfoRight = styled.div`
  margin: 0 auto;
  width: 100%;
  min-height: calc(100% - 4rem);
  background: #e1e1e6;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

`;

export const ButtonFinalizar = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-top: 2%;
    background: #0069d9;
    width: 15rem;
    height: 2.3rem;
    border: none;
    border-radius: 0.25rem;
    color: #fff;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
  }

`;

export const NavWorkShopInfoRight = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  margin: 0 auto;
  padding: 0 1rem;
`;

export const ContentNavInfoRight = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  padding: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #a8a8b3;

  div {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    height: 4rem;
    line-height: 4rem;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
        color: rgb(59, 102, 255);
        font-weight: bold;
        &::after {
        content: ' ';
        height: 3px;
        border-radius: 3px 3px 0 0;
        width: 100%;
        background: rgb(59, 102, 255);
        position: absolute;
        bottom: 6px;
        left: 0;
      }
      }

      &.active {
        color: rgb(59, 102, 255);
        font-weight: bold;
        bottom: 5px;
      }

      &.active::after {
        content: ' ';
        height: 3px;
        border-radius: 3px 3px 0 0;
        width: 100%;
        background: rgb(59, 102, 255);
        position: absolute;
        bottom: 1px;
        left: 0;
      }
  }
`;

