import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Row} from 'react-bootstrap'
import {DragDropContext, Draggable, Droppable,} from 'react-beautiful-dnd'
import {AppBar, Container, Dialog, Grid, Paper, Toolbar, Typography,} from '@material-ui/core'
import {Icon} from 'rsuite'
import {Alert, AlertTitle} from '@material-ui/lab'

import CreateTemplateQuestion from '../../pages/Perguntas/TemplateAdd'
import EditPergunta from '../EditPergunta/edit_template'
import {
  useCreateQuestionTemplate, useDeleteQuestionTemplate,
  useQuestionTemplates, useUpdateQuestionTemplate
} from "../../hooks/useCases/QuestionTemplate/useQuestionTemplates";
import useModal from "../../hooks/useModal";
import Dropdown from "../Dropdown";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {red} from "@material-ui/core/colors";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteDialog from "../DeleteDialog";
import _ from "lodash";
import {DEFAULT_QUESTION_CATEGORIES} from "../../utils/constants";
import ToastAnimated from "../../ui-lib/toast";


export default function TemplatePerguntas() {
  const [template, setTemplate] = useState(null);
  const questionTemplates = useQuestionTemplates();
  const createTemplate = useCreateQuestionTemplate();
  const updateTemplate = useUpdateQuestionTemplate();
  const deleteTemplate = useDeleteQuestionTemplate();
  const [cadastroPergunta, setCadastroPergunta] = useState(false);
  const [open, openModal, closeModal] = useModal();
  const [createTemplateModal, openCreateTemplateModal, closeCreateTemplateModal] = useModal();
  const [updateTemplateModal, openUpdateTemplateModal, closeUpdateTemplateModal] = useModal();
  const [deleteModal, openDeleteModal, closeDeleteModal] = useModal();

  async function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(template.questions)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setTemplate(items)
  }

  async function onCreateTemplate() {
    const newTemplate = await createTemplate.send();
    questionTemplates.add(newTemplate);
    closeCreateTemplateModal();
    createTemplate.reset();
  }

  async function onUpdateTemplate() {
    const newTemplate = await updateTemplate.send();
    questionTemplates.update(newTemplate);
    closeUpdateTemplateModal();
  }

  async function onAddQuestion(question) {
    questionTemplates.addQuestion(template, question);
    setCadastroPergunta(!cadastroPergunta);
  }

  async function onRemoveTemplate() {
    await deleteTemplate.request(template);
    questionTemplates.remove(template);
    closeDeleteModal();
  }

  useEffect(() => {
    if (questionTemplates.value.length) setTemplate(questionTemplates.value[0])
  }, [questionTemplates.value]);

  useEffect(() => {
    if (template) updateTemplate.reset(template);
  }, [template]);

  return (
    <Grid item xs={12}>
      <div className="boxHeaderPoliticas">
        <h3>Template de Tarefas</h3>
        <button onClick={openModal}>Visualizar</button>
      </div>

      <Dialog open={open} onClose={closeModal} fullScreen>
        <AppBar sx={{position: 'relative'}}>
          <Toolbar className="HeaderTempĺate">
            <div className="boxLeftHeader">
              <Icon icon="close" onClick={closeModal}/>
              <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">Template de Tarefas</Typography>
            </div>
            <Button color="inherit" onClick={() => setCadastroPergunta(!cadastroPergunta)}>
              Cadastrar tarefa
            </Button>
          </Toolbar>
        </AppBar>

        <Container component={Paper} fullScreen style={{paddingTop: 65, maxWidth: 1920}}>

          <Grid container justifyContent="flex-end" alignItems="center">
            <Dropdown
              items={questionTemplates.value}
              value={template?.id}
              setValue={(id) => setTemplate(questionTemplates.value.find(a => a.id === id))}
              labelKey="name"
              valueKey="id"
            />
            { template && <>
              <IconButton aria-label="edit" onClick={openUpdateTemplateModal}><EditIcon/></IconButton>
              <IconButton aria-label="delete" onClick={openDeleteModal}>
                <DeleteIcon style={{color: red[500],}}/>
              </IconButton>
            </>
            }

            <IconButton aria-label="add" onClick={openCreateTemplateModal}><AddCircleIcon/></IconButton>
          </Grid>

          {template ? <>

              <DeleteDialog
                open={deleteModal}
                confirmationMessage={`Tem certeza que deseja excluir ${template.name}?`}
                onClose={closeDeleteModal}
                onConfirm={onRemoveTemplate}
              />

              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="problema">
                  {(provided) => (
                    <ul className="problema"{...provided.droppableProps} ref={provided.innerRef}>
                      {template.questions.map((body, index) => {
                        return (
                          <Draggable key={body.problema} draggableId={body.problema} index={index}>
                            {(provided) => (<EditPergunta key={body.id} data={body} provided={provided} index={index}/>)}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </>:
            <Alert severity="info">
              <AlertTitle>Nenhum template de tarefa disponível</AlertTitle>
            </Alert>
          }
        </Container>
      </Dialog>

      <Dialog open={createTemplateModal} onClose={closeCreateTemplateModal} PaperProps={{style: {width: '700px', maxWidth: '90%',},}}>
        <Alert severity="info" action={<Icon icon="close" onClick={closeCreateTemplateModal}/>}>
          <AlertTitle>Adicionar Template</AlertTitle>
        </Alert>
        <Container>
          <br />
          <Form>
            <Form.Group controlId="textarea">
              <Form.Label>Nome</Form.Label>
              <Form.Control {...createTemplate.form.name} as="input" name="name"/>
            </Form.Group>
            <br/>
            <div className="mb-2">
              <ToastAnimated/>
              <Button onClick={onCreateTemplate} variant="success" size="lg">Enviar</Button>
              <Button onClick={closeCreateTemplateModal} variant="secondary" size="lg">Cancelar</Button>
            </div>
          </Form>
          <br />
        </Container>
      </Dialog>

      <Dialog open={updateTemplateModal} onClose={closeUpdateTemplateModal} PaperProps={{style: {width: '700px', maxWidth: '90%',},}}>
        <Alert severity="info" action={<Icon icon="close" onClick={closeUpdateTemplateModal}/>}>
          <AlertTitle>Editar Template</AlertTitle>
        </Alert>
        <Container>
          <br />
          <Form>
            <Form.Group controlId="textarea">
              <Form.Label>Nome</Form.Label>
              <Form.Control {...updateTemplate.form.name} as="input" name="name"/>
            </Form.Group>
            <br/>
            <div className="mb-2">
              <ToastAnimated/>
              <Button onClick={onUpdateTemplate} variant="success" size="lg">Enviar</Button>
              <Button onClick={closeUpdateTemplateModal} variant="secondary" size="lg">Cancelar</Button>
            </div>
          </Form>
          <br />
        </Container>
      </Dialog>

      <Dialog open={cadastroPergunta} onClose={() => setCadastroPergunta(!cadastroPergunta)} PaperProps={{style: {width: '700px', maxWidth: '90%',},}}>
        <Alert severity="info" action={<Icon icon="close" onClick={() => setCadastroPergunta(!cadastroPergunta)}/>}>
          <AlertTitle>Adicionar uma pergunta ao template</AlertTitle>
        </Alert>
        <CreateTemplateQuestion template={template} onCreate={onAddQuestion}/>
      </Dialog>
    </Grid>
  );
}
