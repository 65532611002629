import React from 'react';
import _ from 'lodash';

import { Container, Grid, Typography, } from '@material-ui/core';
import CheckImg from '../../assets/check.png';

import { CompanyMaturityDiagnosisContext, } from './context';



export default function CompanyMaturityDiagnosisFinished() {
  const context = React.useContext(CompanyMaturityDiagnosisContext);
  const email = (context.questions.find((item) => item.key == 'email') || {}).value;


  return (
    <Container
      maxWidth="sm"
      disableGutters
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{
          marginTop: '5rem',
        }}
        direction="column"
      >
        <img src={CheckImg} />
        <Typography
          variant="h4"
          align="center"
          style={{
            color: '#3B66FF',
          }}
          paragraph
        >
          Pronto, recebemos as informações, vamos analisar e gerar um diagnóstico para você!
        </Typography>

        <Typography
          variant="body1"
          align="center"
        >
          Assim que estiver pronto enviaremos para o e-mail{` `}
          <strong
            style={{
              color: '#3B66FF',
            }}
          >
            { email }
          </strong>
        </Typography>
      </Grid>
    </Container>
  );
}

