import React from 'react';

import Sheet from '../../components/Sheet';
import Monitoring from '.';

const PAGES = Object.freeze({
    monitoring: {
      id: 'monitoring',
      titleTab: "Monitoramento",
      titlePage: "Monitoramento",
      component: <Monitoring />,
    },
});

const ARRAY_PAGES = Object.values(PAGES);

export default function MapMonitoramento() {

  return (
    <Sheet pages={ARRAY_PAGES} />
  );
}
