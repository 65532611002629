import React from 'react';
import { useTranslation, } from 'react-i18next';

import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SortIcon from '@material-ui/icons/Sort';

import { DataMappingFieldType, } from '../../data/constants';
import { getFieldType, DataMappingFieldData, } from '../../core/entities/data-mapping-field';


const ALLOWED_FIELD_TYPES = Object.freeze([
  DataMappingFieldType.PROCESSING_AGENTS,
  DataMappingFieldType.SECTOR,
  DataMappingFieldType.COLLABORATORS,
]);

const ITEM_HEIGHT = 48;


/**
 * @param {Object} props - component props
 * @param {DataMappingFieldData[]} props.fields - the available fields
 * @param {(fieldId: number | undefined) => void} props.onChange - callback called when the
 * selected field is changed
 * @param {undefined | number} props.value - selected field
 */
function DataMappingSortFieldSelector(props) {
  const chipRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const fields = React.useMemo(() => {
    return props.fields
      .filter((f) => {
        const fieldType = getFieldType(f.asType, f.typePre, f.inputType);
        return undefined != fieldType && ALLOWED_FIELD_TYPES.includes(fieldType.value);
      });
  }, [props.fields]);
  const selectedField = React.useMemo(() => {
    return fields.find((f) => f.id == props.value);
  }, [props.value, fields]);


  function onDelete() {
    props.onChange(undefined)
  }

  function onCloseFieldList() {
    setAnchorEl(null);
  };

  function onToggleFieldList(ev) {
    setAnchorEl(null == anchorEl ? ev.currentTarget : null);
  }

  function onFieldClick(fieldId) {
    onCloseFieldList();
    props.onChange(props.value != fieldId ? fieldId : undefined);
  }


  return (
    <div
      style={{
        marginRight: '0.25rem',
      }}
    >
      <Chip
        size="small"
        icon={<SortIcon />}
        label={`Ordenar por: ${undefined != selectedField ? selectedField.name : 'índice da resposta'}`}
        onClick={onToggleFieldList}
        onDelete={onDelete}
        innerRef={chipRef}
        disabled={0 === fields.length}
      />

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onCloseFieldList}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: null != chipRef.current ? chipRef.current.offsetWidth : '20ch',
          },
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        getContentAnchorEl={null}
      >
        {
          fields.map((f) => {
            return (
              <MenuItem
                dense
                key={f.id}
                selected={f.id === props.value}
                onClick={() => onFieldClick(f.id)}
              >
                { f.name }
              </MenuItem>
            );
          })
        }
      </Menu>
    </div>
  );
}

export default React.memo(DataMappingSortFieldSelector);
