import _ from 'lodash';


/**
 * Normalize string
 *
 * @param {string} value - string to normalize
 *
 * @returns Normalized string
 */
export function normalizeString(value) {
  return value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}

/**
 * Check if value matches search
 * @param {string} value - value to search
 * @param {string} search - search string
 * @returns returns true if the value matches the search, otherwise returns false
 */
export function checkSearch(value, search) {
  return normalizeString(value).includes(normalizeString(search));
}

/**
 * Pluralize string
 *
 * @param {string} value - value (must be integer)
 * @param {string} singular - singular string
 * @param {string} plural - plural string
 *
 * @returns pluralized string
 */
export function pluralizeString(value, singular, plural) {
  return Math.abs(value).toFixed(0) === '1' ? singular : plural;
}

export function getUserCompanyId() {
  return localStorage.getItem('empresa');
}

/**
 * Get user ID
 *
 * @returns {string} user ID
 */
export function getUserId() {
  return localStorage.getItem('user');
}

export function getRefreshToken() {
  return localStorage.getItem('refreshToken');
}

export function setRefreshToken(rt) {
  return localStorage.setItem('refreshToken', rt);
}

export function setUserSession(data) {
  localStorage.setItem('token', data.token);
  localStorage.setItem('name', data.name);
  localStorage.setItem('email', data.email);
  localStorage.setItem('avatar', data.avatar);
  localStorage.setItem('user', data.user);
  localStorage.setItem('group', data.group);
  localStorage.setItem('admin', data.admin);
  localStorage.setItem('empresa', data.empresa);
  localStorage.setItem('contract', data.contract);
  localStorage.setItem('createdAt', data.createdAt);
  localStorage.setItem('refreshToken', data.refreshToken);
  localStorage.setItem('groupName', data.groupName);

  //! used to erase missing pwd in local storage
  localStorage.removeItem('pwd');
}

export function getUserSession() {
  return {
    token: localStorage.getItem('token'),
    name: localStorage.getItem('name'),
    email: localStorage.getItem('email'),
    avatar: localStorage.getItem('avatar'),
    user: localStorage.getItem('user'),
    group: localStorage.getItem('group'),
    admin: localStorage.getItem('admin') === 'true',
    empresa: localStorage.getItem('empresa'),
    contract: localStorage.getItem('contract'),
    createdAt: localStorage.getItem('createdAt'),
    refreshToken: localStorage.getItem('refreshToken'),
    groupName: localStorage.getItem('groupName'),
  };
}

const ENTRIES_TO_REMOVE = Object.freeze([
  'token',
  'name',
  'email',
  'admin',
  'avatar',
  'empresa',
  'user',
  'group',
  'createdAt',
  'contract',
  'refreshToken',
  'groupName'
]);

/**
 * Clear local storage
 *
 * @returns {string} company ID
 */
export function clearLocalStorage() {
  for (const key of ENTRIES_TO_REMOVE) {
    localStorage.removeItem(key);
  }
}

/**
 * Format currency value to BRL
 */
export function formatCurrencyValue(value) {
  const currencyConfig = {
    style: 'currency',
    currency: 'BRL',
  };

  return new Intl.NumberFormat('pt-BR', currencyConfig).format(value);
}

/**
 * Camel case object keys
 *
 * @returns {object} new object with camel case keys
 */
export function camelCaseObjectKeys(obj) {
  const __obj = {};
  for (const key of Object.keys(obj)) {
    __obj[_.camelCase(key)] = obj[key];
  }
  return __obj;
}
