import { useState, useEffect, } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';


/**
 * Search field component
 *
 * @param {function} onChange - callback called when the search string is committed
 *
 * @returns Rendered component
 */
export default function SearchField({ onChange, }) {
  const [searchString, setSearchString] = useState('');
  const [initialized, setInitialized] = useState(false);

  /**
   * Use effect to set timeout to trigger onChange callback
   */
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      return;
    }
    const timeoutId = setTimeout(() => onChange(searchString), 300);
    return () => clearTimeout(timeoutId);
  }, [searchString]);


  return (
    <TextField
      variant="standard"
      placeholder="Pesquisar"
      value={searchString}
      onChange={(event) => setSearchString(event.target.value)}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment
            position="start"
            style={{
              paddingLeft: 7,
            }}
          >
            <SearchIcon size="small" />
          </InputAdornment>
        ),
      }}
      style={{
        border: '1px solid #AAAAAA',
        borderRadius: 50,
      }}
    />
  );
}
