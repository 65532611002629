import React from 'react';
import { useSelector, } from 'react-redux';

import { selectCurrentReport, } from '../../../features/impactReportsSlice';
import ImpactReportEditorV1 from './v1';
import ImpactReportEditorV2 from './v2';

import { ImpactReportVersion, } from '../../../entities/impact-report-widget';


const EDITOR_MAP = Object.freeze({
  [ImpactReportVersion.VERSION_1]: ImpactReportEditorV1,
  [ImpactReportVersion.VERSION_2]: ImpactReportEditorV2,
});


export default function ImpactReportEditor({ readOnly, isPrintMode, }) {
  const impactReport = useSelector(selectCurrentReport);
  const Editor = EDITOR_MAP[impactReport.version || ImpactReportVersion.VERSION_2];


  return (
    <Editor readOnly={readOnly} isPrintMode={isPrintMode} />
  );
}
