import { useEffect, useState } from "react";
import {getReportConfig, createReportHeader, setReportLogo, getReportLogo} from "../../services/report.api";
import imgLogo from "../../assets/relatorio_logo.svg";
/**
 *
 * @param company {{_id: string}}
 * @returns {{companyId: string, headers: [{id: number, name: string, value: string}] }}
 */
export function useReportConfigGet(company) {

  const [reportConfig, setHeaders] = useState({ headers: [] });

  async function getData() {
    const response = await getReportConfig(company);
    setHeaders(response.data);
  }

  useEffect(() => {
    getData();
  }, []);

  return [reportConfig, setHeaders]
}


export function useReportConfigLogo(company) {
  const [logo, setLogo] = useState(imgLogo);

  async function setLogoData(file) {
    const response = await setReportLogo(company, file);
    setLogo(URL.createObjectURL(file));
  }

  useEffect(() => {
    async function getData() {
      try {
        const response = await getReportLogo(company);
        if (response.data) {
          setLogo(URL.createObjectURL(response.data));
        }
      } catch (e) {
      }
    }
    getData();
  }, []);


  return [logo, setLogoData];
}
