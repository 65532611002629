import React from 'react';

import { Typography, Divider, } from '@material-ui/core';


//TODO: implement component, this is just boilerplate
export default function RiskTreatmentMeasuresWidget({ data, readonly, onChange, }) {
  return (
    <>
      <Typography
        variant="subtitle1"
        style={{
          fontWeight: 500,
          marginBottom: '10px'
        }}
      >
        Medidas para tratar os riscos
      </Typography>



      <Divider style={{ marginBottom: '10px' }} />
      <Typography
        style={{
          fontWeight: 500,
          marginBottom: '10px'
        }}
      >
        Legenda:
      </Typography>

      <Typography
        variant='subtitle2'
        style={{
          fontWeight: 500,
          marginBottom:'5px'
        }}
      >
        1 - <span style={{ color: "#54708C", fontWeight: "normal" }}>
          Efeito resultante do tratamento do risco com a aplicação da(s) medida(s) descrita(s) na tabela. As seguintes opções podem ser selecionadas: Reduzir, Evitar, Compartilhar e Aceitar
        </span>
      </Typography>
      <Typography
        variant='subtitle2'
        style={{
          fontWeight: 500,
          marginBottom:'5px'
        }}
      >
        2 - <span style={{ color: "#54708C", fontWeight: "normal" }}>
          Risco residual é o risco que ainda permanece mesmo após a aplicação de medidas para tratar o risco.
        </span>
      </Typography>
      <Typography
        variant='subtitle2'
        style={{
          fontWeight: 500,
          marginBottom:'5px'
        }}
      >
        3 - <span style={{ color: "#54708C", fontWeight: "normal" }}>
          Medida aprovada pelo controlador dos dados pessoais. Preencher a coluna com: Sim ou Não.
        </span>
      </Typography>
    </>
  );
}
