import React, { useState, } from "react";
import { Container, Paper, Box, } from "@material-ui/core";
import pathBackground from '../../assets/path.png';
import StepCard from './StepCard';
import VideoCard from '../Global/VideoCard';


//todo: use right video URL in DPO channel and Monitoring
const STEPS = Object.freeze([
  {
    title: 'Workshop',
    actions: [
      'Workshop de sensibilização',
      'Criação do comitê',
      'Definir as responsabilidades dos integrantes do comitê',
    ],
    left: 100,
    top: 50,
    videoUrl: 'https://www.youtube.com/embed/I2fOXSR_AHE',
  },
  {
    title: 'Diagnóstico',
    actions: [
      'Diagnóstico de aderência',
      'Tarefas editáveis',
      'Possível avaliar probabilidades e impacto',
    ],
    left: 430,
    top: 50,
    videoUrl: 'https://www.youtube.com/embed/BbWmoStNEwg',
  },
  {
    title: 'Mapeamento',
    actions: [
      'Criar o Relatório das Operações de Tratamento',
      'Mapeável 100% customizável',
      'Envio de links para preenchimento',
    ],
    left: 430,
    top: 265,
    videoUrl: 'https://www.youtube.com/embed/Sk7gqUn3SNA',
  },
  {
    title: 'Classificação',
    actions: [
      'Visão macro das hipóteses de tratamento (bases legais) utilizadas no mapeamento',
      'Analisar a sensibilidade e tipos de dados tratados, forma de armazenagem, pessoas envolvidas no processo',
    ],
    left: 100,
    top: 265,
    videoUrl: 'https://www.youtube.com/embed/bSxvgSERJIM',
  },
  {
    title: 'Matriz de riscos',
    actions: [
      'Calcular a matriz de risco',
      'Análise de probabilidade',
      'Análise de impacto',
      'Mapa de calor',
    ],
    left: 100,
    top: 485,
    videoUrl: 'https://www.youtube.com/embed/glI4L5D0eLU',
  },
  {
    title: 'Plano de ação',
    actions: [
      'Criar um plano de ação envolvendo os integrantes do comitê',
      'Definir prazos e acompanhar tarefas',
    ],
    left: 430,
    top: 485,
    videoUrl: 'https://www.youtube.com/embed/hhfWfnUjgZA',
  },
  {
    title: 'Monitoramento',
    actions: [
      'Acompanhamento do programação de adequação',
      'Indicadores dos itens em andamento e concluídos',
      'Gestão de documentos',
    ],
    left: 430,
    top: 700,
    videoUrl: 'https://www.youtube.com/embed/p8pZEd5WS48',
  },
  {
    title: 'Canal do DPO',
    actions: [
      'Treinamento de equipe',
      'Gestão de consentimento',
      'Portal de comunicação com DPO',
    ],
    left: 100,
    top: 700,
    videoUrl: 'https://www.youtube.com/embed/p8pZEd5WS48',
  },
]);


export default function Stepper() {
  const [openVideoCard, setOpenVideoCard] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');


  /**
   * Open modal to show step video
   *
   * @param {string} videoUrl - video URL
   */
  const onOpenVideo = (videoUrl) => {
    setOpenVideoCard(true);
    setVideoUrl(videoUrl);
  }

  /**
   * Close modal to show step video
   */
  const onClose = () => {
    setOpenVideoCard(false);
    setVideoUrl('');
  }


  return (
    <Container
      component={Paper}
      maxWidth
      style={{
        marginTop: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          flexShrink: 1
        }}
      >
        <img
          src={pathBackground}
          alt="Steps path"
          width={800}
          style={{
            marginTop: 150,
            marginBottom: 150,
          }}
        />
        {
          STEPS.map(({title, actions, videoUrl, top, left, }, index) => {
            return (
              <StepCard
                key={index}
                avatarLabel={index + 1}
                title={title}
                actions={actions}
                left={left}
                top={top}
                videoUrl={videoUrl}
                zIndex={STEPS.length - index}
                onOpenVideo={onOpenVideo}
              />
            );
          })
        }
      </Box>
      <VideoCard
        onClose={onClose}
        open={openVideoCard}
        videoUrl={videoUrl}
        title={''}
      />
    </Container>
  );
}
