import React from 'react';

import DataProcessingTypeModal from '../DataProcessingTypeModal';

import {
  listGlobalDataProcessingTypes,
  createGlobalDataProcessingType,
  updateGlobalDataProcessingType,
  deleteGlobalDataProcessingType,
} from '../../services/api';


function GlobalDataProcessingTypeModal({ open, onClose, }) {
  async function onFetch() {
    const response = await listGlobalDataProcessingTypes();
    return response.data.options;
  }

  async function onDelete(id) {
    return await deleteGlobalDataProcessingType(id);
  }

  async function onUpdate(data) {
    const response = await updateGlobalDataProcessingType(data.id, data);
    return response.data
  }

  async function onCreate(data) {
    const response = await createGlobalDataProcessingType(data);
    return response.data;
  }


  return (
    <DataProcessingTypeModal
      open={open}
      onFetch={onFetch}
      onDelete={onDelete}
      onUpdate={onUpdate}
      onCreate={onCreate}
      onClose={onClose}
    />
  );
}


export default GlobalDataProcessingTypeModal;
