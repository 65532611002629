import React from 'react';

import { Box, } from '@material-ui/core';


function TabPanel({ children, value, index, ...other }) {
  const hidden = value !== index;

  return (
    <div
      role="tabpanel"
      hidden={hidden}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {
        !hidden ?
        <Box p={1}>
          { children }
        </Box> :
        null
      }
    </div>
  );
}

export default TabPanel;
