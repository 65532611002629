import { useState, useEffect, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import {Dialog, DialogContent, DialogTitle, Grid, TextField, MenuItem, Select, FormControl, InputLabel, FormHelperText,} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import { Notification } from 'rsuite';
import _ from 'lodash';

import Button from '../Button';

import { ImpactReportVersion, } from '../../entities/impact-report-widget';

import { listImpactReportTemplates, } from '../../services/api';
import {
  createImpactReport,
  selectIsGlobal,
} from '../../features/impactReportsSlice';
import { selectCompany, } from '../../features/companySlice';

import logo from '../../assets/logo-blue.svg';


const INITIAL_VALUES = Object.freeze({
  title: '',
  template: '',
});


const useStyles = makeStyles({
  actions: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    color: 'white',
    backgroundColor: '#3B66FF',
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


/**
 * Dialog used to register a impact report
 *
 * @param {boolean} open - whether the dialog is open or not
 * @param {function} onClose - callback called when dialog is closed
 *
 * @returns Rendered dialog
 */
function RegisterImpactReportDialog({ open, onClose, }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);

  const isGlobal = useSelector(selectIsGlobal);

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: handleRegister,
    validationSchema: Yup.object({
      title: Yup.string().required('Obrigatório'),
      template: Yup.number().min(1),
    }),
  });


  /**
   * Handle register
   *
   * @param {object} values - new impact report info
   */
  async function handleRegister(values) {
    setLoading(true);

    try {
      const data = _.cloneDeep(values);
      data.templateId = data.template || undefined;
      data.isTemplate = false;

      await dispatch(createImpactReport(data)).unwrap();

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Relatório de impacto criado com sucesso',
      });

      __onClose();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao criar relatório de impacto!',
        description: 'Tente novamente dentro de alguns instantes.'
      });
    }

    setLoading(false);
  }

  /**
   * Submit event handler
   *
   * @param {object} event - event that triggered the handler
   */
  function onSubmit(event) {
    event.preventDefault();
    formik.handleSubmit();
  }

  /**
   * Handle cancel
   */
  function __onClose() {
    formik.resetForm();
    onClose();
  }

  /**
   * Get impact report templates from the API
   */
  async function getTemplates() {
    try {
      const { data, } = await listImpactReportTemplates(company._id);
      const reports = _.cloneDeep(data.reports)
        .filter(({ version, }) => ImpactReportVersion.VERSION_1 != version);
      reports.sort((a, b) => {
        if (a.isGlobal && !b.isGlobal) {
          return 1;
        } else if (!a.isGlobal && b.isGlobal) {
          return -1;
        } else {
          return 0;
        }
      });
      setTemplates(reports);
    } catch {
      // drop error
    }
  }

  useEffect(() => {
    if (open && !isGlobal) {
      getTemplates();
    }
  }, [open]);


  return (
    <Dialog
      open={open}
      onClose={__onClose}
      maxWidth="md"
    >
      <DialogTitle className={classes.title}>
        { isGlobal ? 'Criar template de relatório de impacto' : 'Criar relatório de impacto'}
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={onSubmit}
        >
          <Grid
            item
            xs={12}
            container
            direction="row"
          >
            <TextField
              margin="dense"
              variant="filled"
              label="Nome"
              InputProps={{
                disableUnderline: true,
              }}
              fullWidth
              id="title"
              type="text"
              error={formik.errors.title && formik.touched.title}
              helperText={formik.touched.title && formik.errors.title}
              {...formik.getFieldProps('title')}
            />
          </Grid>

          {
            !isGlobal ?
            <FormControl
              fullWidth
              variant="filled"
              margin="dense"
              error={formik.errors.template && formik.touched.template}
            >
              <InputLabel
                id="impact-report-template-label"
              >
                Template
              </InputLabel>
              <Select
                labelId="impact-report-template-label"
                id="impact-report-template"
                disableUnderline
                {...formik.getFieldProps('template')}
                MenuProps={MenuProps}
              >
                {
                  templates.map((template) => (
                    <MenuItem
                      key={template.id}
                      value={template.id}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      {template.title}
                      {
                        template.isGlobal ?
                        <img
                          src={logo}
                          height="12px"
                          style={{
                            marginLeft: '0.45rem',
                          }}
                        /> :
                        null
                      }
                    </MenuItem>
                  ))
                }
              </Select>
              <FormHelperText>
                {formik.touched.template && formik.errors.template}
              </FormHelperText>
            </FormControl> :
            null
          }

          <Grid
            className={classes.actions}
          >
            <Button
              variant="contained"
              loading={loading}
              onClick={__onClose}
            >
              Cancelar
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              loading={loading}
            >
              Cadastrar
            </Button>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

RegisterImpactReportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RegisterImpactReportDialog;
