import { useState, useEffect, } from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';
import { useSelector, } from 'react-redux';

import { Notification, } from 'rsuite';

import DueDiligenceRequests from './DueDiligenceRequests';
import DueDiligenceTemplates from './DueDiligenceTemplates';
import PageSelector from '../../components/PageSelector/PageSelector';
import Title from '../../components/Global/PageTitle';

import { listCompanyDueDiligenceTemplates, } from '../../services/api';
import { selectCompany, } from '../../features/companySlice';


export default function DueDiligencePage() {
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const PAGE_OPTIONS = Object.freeze({
    DUE_DILIGENCES_SENT: {
      id: 'DUE_DILIGENCES_SENT',
      label: _.capitalize(t('PAGES.DUE_DILIGENCE.due_diligences_sent')),
    },
    TEMPLATES: {
      id: 'TEMPLATES',
      label: _.capitalize(t('PAGES.DUE_DILIGENCE.templates')),
    },
  });
  const PAGE_OPTIONS_ARRAY = Object.values(PAGE_OPTIONS);

  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedOption, setSelectedOption] = useState('TEMPLATES');


  async function getTemplates() {
    try {
      const { data, } = await listCompanyDueDiligenceTemplates(company._id);
      setTemplates(data.templates);
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_load_company_due_diligence_templates')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function onNewTemplate(template) {
    setTemplates((templates) => {
      templates.push(template);
      return templates;
    });
  }

  async function onTemplateUpdated(updatedTemplate) {
    setTemplates((templates) => {
      const idx = templates.findIndex((item) => item.id === updatedTemplate.id);
      if (idx > -1) {
        templates[idx] = updatedTemplate;
      }
      return templates;
    });
  }

  async function onTemplateDeleted(templateId) {
    setTemplates((templates) => templates.filter((item) => item.id !== templateId));
  }

  function onSelectedOption(option) {
    setSelectedOption(option);
  }

  async function initialize() {
    setLoading(true);
    getTemplates();
    setLoading(false);
  }

  useEffect(() => {
    initialize();
  }, []);


  return (
    <>
      <Title description={_.capitalize(t('PAGES.DUE_DILIGENCE.due_diligence'))} />
        {
          PAGE_OPTIONS.DUE_DILIGENCES_SENT.id === selectedOption ?
          <DueDiligenceRequests templates={templates} /> :
          <DueDiligenceTemplates
            templates={templates}
            onNewTemplate={onNewTemplate}
            onTemplateUpdated={onTemplateUpdated}
            onTemplateDeleted={onTemplateDeleted}
          />
        }
    </>
  );
}
