import React from 'react';
import { useSelector, } from 'react-redux';

import PersonalDataTypeModal from '../PersonalDataTypeModal';

import {
  listCompanyPersonalDataTypes,
  createCompanyPersonalDataType,
  updateCompanyPersonalDataType,
  deleteCompanyPersonalDataType,
} from '../../services/api';

import { selectCompany, } from '../../features/companySlice';


function CompanyPersonalDataTypeModal({ open, onClose, }) {
  const company = useSelector(selectCompany);


  async function onFetch() {
    const response = await listCompanyPersonalDataTypes(company._id);
    return response.data.options;
  }

  async function onDelete(id) {
    return await deleteCompanyPersonalDataType(company._id, id);
  }

  async function onUpdate(data) {
    const response = await updateCompanyPersonalDataType(company._id, data.id, data);
    return response.data
  }

  async function onCreate(data) {
    const response = await createCompanyPersonalDataType(company._id, data);
    return response.data;
  }


  return (
    <PersonalDataTypeModal
      open={open}
      onFetch={onFetch}
      onDelete={onDelete}
      onUpdate={onUpdate}
      onCreate={onCreate}
      onClose={onClose}
    />
  );
}


export default CompanyPersonalDataTypeModal;
