import React from 'react';

import { TextField, Typography, } from '@material-ui/core';


export default function TextWidget({ data, readOnly, onChange, }) {
  function handleChange(ev) {
    onChange({
      ...data,
      model: {
        ...data.model,
        [ev.target.name]: ev.target.value,
      },
    });
  }


  return (
    readOnly ?
    <Typography
      display="block"
      style={{
        whiteSpace: 'pre-line',
      }}
    >
      {data.model.content}
    </Typography> :
    <TextField
      multiline
      fullWidth
      value={data.model.content}
      variant="outlined"
      name="content"
      size="small"
      onChange={handleChange}
    />
  );
}
