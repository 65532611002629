import React, { useState } from 'react'

import { AppBar, Tabs, Tab, } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import {Icon} from "rsuite";


export default function DataMappingTabBar({ phases, onChange}) {
  const [value, setValue] = useState(0);


  function handleChange(_, newValue) {
    setValue(newValue);
    onChange(newValue);
  }


  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {
          phases.map((phase) => {
            return (
              <Tab
                key={phase.id}
                label={phase.name}
              />
            );
          })
        }
      </Tabs>
    </AppBar>
  );
}
