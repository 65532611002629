import { Typography, Grid } from "@material-ui/core";

export default function Title({ description }) {
  return (
    <Grid item xs={12}>
      <Typography variant="h4" style={{ color: "#4D5884", fontSize: 22 }}>
        {description}
      </Typography>
    </Grid>
  );
}
