import React from 'react';

import SensitiveDataOptionModal from '../SensitiveDataOptionModal';

import {
  listGlobalSensitiveDataOptions,
  createGlobalSensitiveDataOption,
  updateGlobalSensitiveDataOption,
  deleteGlobalSensitiveDataOption,
} from '../../services/api';


function GlobalSensitiveDataOptionModal({ open, onClose, }) {
  async function onFetch() {
    const response = await listGlobalSensitiveDataOptions();
    return response.data.options;
  }

  async function onDelete(id) {
    return await deleteGlobalSensitiveDataOption(id);
  }

  async function onUpdate(data) {
    const response = await updateGlobalSensitiveDataOption(data.id, data.name);
    return response.data
  }

  async function onCreate(data) {
    const response = await createGlobalSensitiveDataOption(data.name);
    return response.data;
  }


  return (
    <SensitiveDataOptionModal
      open={open}
      onFetch={onFetch}
      onDelete={onDelete}
      onUpdate={onUpdate}
      onCreate={onCreate}
      onClose={onClose}
    />
  );
}

export default GlobalSensitiveDataOptionModal;
