import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import { Notification, } from 'rsuite';
import api from '../services/api';
import _ from 'lodash';

const initialState = {
  groups: [],
  userGroup: {},
  initialized: false,
};

/**
 * Thunk used to get the groups the user has access to
 */
export const fetchGroups = createAsyncThunk('groups/fetchGroups', async () => {
  try {
    const { data, } = await api.get('/groups');
    return data;
  } catch {
    console.log('Failed to fetch groups');
    return {
      groups: [],
    };
  }
});

/**
 * Thunk used to get the user group info
 */
export const getUserGroup = createAsyncThunk('groups/getUserGroup', async () => {
  const group = localStorage.getItem('group');

  if ((group == null) || (group.length == 0)) {
    return {};
  }

  try {
    const { data, } = await api.get(`/groups/${group}`);
    return data;
  } catch {
    console.log('Failed to get current user group');
    return {};
  }
});

/**
 * Thunk used to delete user
 *
 * @param {string} groupId - group ID
 */
export const deleteGroup = createAsyncThunk('groups/deleteGroup', async (groupId) => {
  console.log(`Send request to delete group ${groupId}`);
  const response = await api.delete(`/groups/${groupId}`);
  return response;
});

/**
 * Thunk to change the group active state
 */
export const changeGroupActiveState = createAsyncThunk('groups/changeActiveState', async (args) => {
  const { groupId, active, } = args;
  try {
    const { status, } = await api.post(`/groups/${groupId}/change-active-state`, {
      active,
    });

    if (status === 204) {
      Notification['success']({
        placement: 'bottomEnd',
        title: `Grupo ${ active ? 'ativado' : 'inativado' } com sucesso!`,
      });

      return {
        groupId,
        active,
      };
    } else {
      Notification['warning']({
        placement: 'bottomEnd',
        title: `Não foi possível ${ active ? 'ativar' : 'inativar' } grupo!`,
      });
    }
  } catch {
    Notification['error']({
      placement: 'bottomEnd',
      title: `Falha ao ${ active ? 'ativar' : 'inativar' } grupo!`,
      description: 'Tente novamente dentro de alguns instantes.',
    });
  }

  return null;
});

/**
 * Thunk to change the group active state
 */
export const updateGroup = createAsyncThunk('groups/updateGroup', async (group) => {
  const { id: groupId, name, } = group;
  try {
    const { status, data, } = await api.put(`/groups/${groupId}`, {
      name,
    });

    if (status === 200) {
      Notification['success']({
        placement: 'bottomEnd',
        title: `Grupo atualizado com sucesso!`,
      });

      return data;
    } else {
      Notification['warning']({
        placement: 'bottomEnd',
        title: `Não foi possível atualizar grupo!`,
      });
    }
  } catch {
    Notification['error']({
      placement: 'bottomEnd',
      title: `Falha ao atualizar grupo!`,
      description: 'Tente novamente dentro de alguns instantes.',
    });
  }
});


export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroups.pending, (state) => {
      state.groups = initialState.groups;
    }).addCase(fetchGroups.fulfilled, (state, { payload, }) => {
      state.groups = payload.groups;
      state.initialized = true;
    });

    builder.addCase(getUserGroup.pending, (state) => {
      state.userGroup = initialState.userGroup;
    }).addCase(getUserGroup.fulfilled, (state, { payload: group, }) => {
      state.userGroup = group;
    });

    builder.addCase(deleteGroup.fulfilled, (state, { payload: response, }) => {
      const { data: group, status, } = response;

      if (status === 200) {
        Notification['success']({
          placement: 'bottomEnd',
          title: 'Grupo deletado com sucesso!',
        });

        const index = state.groups.findIndex(({ id, }) => id === group.id);
        if (index > -1) {
          state.groups.splice(index, 1);
        }
      } else {
        Notification['warning']({
          placement: 'bottomEnd',
          title: 'Não foi possível deletar o grupo!',
        });
      }
    }).addCase(deleteGroup.rejected, (state) => {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao deletar grupo!',
        description: 'Tente novamente dentro de alguns instantes.',
      });
    });

    builder.addCase(changeGroupActiveState.fulfilled, (state, { payload, }) => {
      if (payload == undefined) {
        return;
      }

      const { groupId, active, } = payload;

      const group = state.groups.find(({ id, }) => id === groupId);
      if (group != undefined) {
        group.active = active;
      }
    });

    builder.addCase(updateGroup.fulfilled, (state, { payload, }) => {
      if (payload == undefined) {
        return;
      }

      const group = state.groups.find(({ id, }) => id === payload.id);
      if (group != undefined) {
        _.extend(group, payload);
      }
    });
  }
});

export function selectGroups(state) {
  return state.groups.groups;
}

export function selectGroupsMap(state) {
  return Object.fromEntries(state.groups.groups.map((g) => [g.id, g]));
}

export default groupsSlice.reducer;
