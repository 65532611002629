import React, { useMemo, useRef, useState } from "react";
import {
  // Avatar,
  Button,
  DialogActions,
  DialogContentText,
  Divider,
  InputAdornment,
  Paper,
  TablePagination,
  TextField,
  Typography
} from "@mui/material";

// import Avatar from '@mui/material/Avatar';
import { Avatar, Grid, Box } from "@material-ui/core";


import { makeStyles, } from "@material-ui/styles";
import { useThemeContext } from "../../context/ThemeAppContext";
import { Icon } from 'rsuite';
import Paginator from "../../components/Paginator/Paginator";
import imgLogo from "../../assets/relatorio_logo.svg";
import DefaultModal from "../../components/Modals/DefaultModal/DefaultModal";
import { useReportConfigGet, useReportConfigLogo } from "../../hooks/useCases/ReportConfigHook";
import { usePaginator } from "../../hooks/usePaginator";
import { setItensPerPage } from "../../features/dashboard";
import { useSelector } from "react-redux";
import { selectCompany } from "../../features/companySlice";
import { useSearch } from "../../hooks/useSearch";
import { createReportHeader, removeHeader, updateHeader } from "../../services/report.api";
import { Notification } from 'rsuite';

export default function Organization() {
  const company = useSelector(selectCompany);
  const { stylesLayout } = useThemeContext();
  const className = useStyles({ colors: stylesLayout.colors });
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalDeletLine, setModalDeletLine] = useState(false);
  const [itemModalDelet, setItemModalDelet] = useState(false);
  const [text, setText] = useState('');

  const [logo, setLogo] = useReportConfigLogo(company);
  const fileInput = useRef();
  const [reportConfig, setReportConfig] = useReportConfigGet(company);
  const [searchItems, search, setSearch] = useSearch(reportConfig.headers);
  const { totalItems, currentPage, itemsPerPage, setCurrentPage, paginate } = usePaginator(searchItems);


  async function fetchSaveReport() {
    const response = await createReportHeader(company, { name: text, value: '' });
    setReportConfig(response.data);
    setText('');
    setModalIsVisible(false);
    Notification['success']({
      placement: 'bottomEnd',
      title: 'Linha criada com sucesso',
      description: `A linha de organização foi salva com sucesso`,
    })
  }

  async function fetchDeletLine(id) {
    const response = await removeHeader(company, { id });
    setReportConfig(response.data);
    setModalDeletLine(false);
    Notification['success']({
      placement: 'bottomEnd',
      title: 'Item removido com sucesso',
      description: `O item de organização foi removido com sucesso`,
    })
  }

  async function fetchEditLine(id, name, event) {
    const response = await updateHeader(company, { id, name: name, value: event.target.value });
    setReportConfig(response.data);
    Notification['success']({
      placement: 'bottomEnd',
      title: 'Linha atualizado com sucesso',
      description: `Linha item de organização foi atualizado com sucesso`,
    })
  }

  function onAvatarClick() {
    fileInput.current.click();
  }

  function onFileSelected(e) {
    if (e.target.files[0]) {
      setLogo(e.target.files[0])
    }
  }

  const InternalPaginator = () => {
    return (
      <Box style={{ flexDirection: "row-reverse", width: "100%", marginBottom: '20px' }}>
        <TablePagination
          rowsPerPageOptions={[
            5,
            10,
            25,
            { label: 'Todos', value: totalItems },
          ]}
          component="div"
          SelectProps={{
            inputProps: { 'aria-label': 'Linhas' },
            native: true,
          }}
          labelRowsPerPage={`Linhas por página`}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          count={totalItems}
          rowsPerPage={itemsPerPage}
          page={currentPage - 1}
          onPageChange={() => {
          }}
          onRowsPerPageChange={(e) => {
            setItensPerPage(e.target.value); setCurrentPage(1)
          }}
          ActionsComponent={() => <Paginator page={currentPage} rowsPerPage={itemsPerPage} count={totalItems}
            onChange={(_, page) => setCurrentPage(page)} />}
        />
      </Box>
    );
  }

  return (
    <Grid
      container
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={className.containerHead}
      >
        <Grid container item xs={5}>
          <TextField
            label="Pesquisar"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            style={{ background: "#fff", marginLeft: "10px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon icon="search" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid container item xs={7}>
          <input ref={fileInput} type="file" accept="image/*" onChange={onFileSelected} style={{ display: 'none' }} />
          <Box className={className.containerImage}>
            <Avatar alt="Remy Sharp" className={className.sizeImage} onClick={onAvatarClick}>
              <img src={logo} style={{ height: '70px', width: '50px', objectFit: 'contain' }} />
            </Avatar>
            <Box className={className.editImage}>
              <Icon style={{ color: stylesLayout.colors.primaryColor }} icon="edit2" />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid component={Paper} container className={className.table}>

        <Box className={className.titleTable}>
          <Typography variant="h4">Organização</Typography>
        </Box>

        <InternalPaginator />
        {paginate.map((obj, index) => {
          return (
            <Grid key={index} container direction="row" className={className.line}>
              <Grid container item sm={12} md={5} className={className.lineContainer + ' ' + className.containerTitleVariable}>
                <Typography variant="body1" style={{ fontWeight: '500' }}>{obj.name}</Typography>
              </Grid>
              <Grid container item sm={12} md className={className.lineContainer}>
                <TextField
                  defaultValue={obj.value}
                  variant="outlined"
                  size="small"
                  placeholder="Adicione um valor ao campo"
                  onBlur={(e) => fetchEditLine(obj.id, obj.name, e)}
                  InputProps={{
                    endAdornment: (
                      <div onClick={() => { setModalDeletLine(true), setItemModalDelet(obj.id) }} className={className.containerIconEdit}>
                        <Icon style={{ color: "#fff" }} icon="trash-o" />
                      </div>
                    ),
                  }}
                  className={className.inputLine}
                />
              </Grid>
            </Grid>
          );
        })}
        <InternalPaginator />
        <Button variant="contained" color="primary" onClick={() => setModalIsVisible(true)}>
          + Linha
        </Button>
      </Grid>
      <DefaultModal open={modalIsVisible} title={'Criar registro'} onClose={() => setModalIsVisible(false)}>
        <Grid container direction="row" style={{ padding: '20px' }}>
          <Grid container item sm={12}>
            <Typography variant="subtitle1" gutterBottom>
              Este nome será usado para identificar o campo em sua aplicação, portanto, escolha um nome claro e descritivo.
            </Typography>
            <TextField
              value={text}
              label="Nome do campo"
              variant="outlined"
              size="small"
              onChange={(event) => { setText(event.target.value) }}
              fullWidth
            />

          </Grid>
        </Grid>
        <Box>
          <Divider flexItem style={{ borderColor: 'rgba(0, 0, 0, 0.5)' }} />
          <DialogActions style={{ padding: '20px' }}>
            <Button variant="contained" autoFocus color="primary" onClick={() => fetchSaveReport()}>
              salvar campo
            </Button>
          </DialogActions>
        </Box>
      </DefaultModal>

      <DefaultModal title={'Excluir linha'} open={modalDeletLine} onClose={() => setModalDeletLine(false)}>
        <Grid container direction="row" style={{ padding: '20px' }}>
          <Grid container item sm={12}>
            <Typography variant="subtitle1" gutterBottom>
              Você realmente deseja excluir esta linha? Esta ação não pode ser desfeita.
            </Typography>
          </Grid>
        </Grid>
        <Box>
          <Divider flexItem style={{ borderColor: 'rgba(0, 0, 0, 0.5)' }} />
          <DialogActions style={{ padding: '20px' }}>
            <Button variant="contained" autoFocus color="primary" onClick={() => setModalDeletLine(false)}>
              Cancelar
            </Button>
            <Button variant="contained" autoFocus color="error" onClick={() => fetchDeletLine(itemModalDelet)}>
              Continuar
            </Button>
          </DialogActions>
        </Box>
      </DefaultModal>
    </Grid>


  );
}

const useStyles = makeStyles({
  containerHead: {
    marginBottom: '40px'
  },
  line: {
    borderRadius: '5px',
    overflow: "hidden",
    marginBottom: '20px'
  },
  lineContainer: {
    padding: '10px 20px',
    background: '#F2F4F8'
  },
  inputLine: {
    width: "100%",
    background: "#fff",
    position: "relative"
  },
  containerIconEdit: {
    cursor: 'pointer',
    position: "absolute",
    // top: '0',
    right: '0',
    width: '32px',
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: 'var(--primary-color)',
    borderRadius: '50%',
    marginRight: '5px'
  },
  containerTitleVariable: {
    background: "#f2f4f8",
    color: `var(--primary-color)`,
    display: "flex",
    alignItems: "center",
    border: `2px solid var(--primary-color)`,
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',

  },
  table: {
    borderTop: `10px solid var(--primary-color)`,
    borderRadius: '5px',
    padding: "0px 20px 20px 20px"
  },
  titleTable: {
    color: 'var(--primary-color)',
    textAlign: "center",
    width: "100%",
    margin: '20px 0px',
  },
  sizeImage: {
    width: '70px',
    height: '70px',
    background: "#f2f4f8",
    border: props => `2px solid ${props.colors.primaryColor}`
  },
  containerImage: {
    display: "flex",
    width: "100%",
    flexDirection: "row-reverse",
    position: 'relative'
  },
  editImage: {
    position: "absolute",
    top: 0,
    right: 0,
    background: "#fff",
    width: '18px',
    height: '18px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: '14px'
  }
});
