import styled from "styled-components";


export const ContentInfoLeft = styled.div`
  margin: 0 1rem 0 0;
  padding: 2rem 0 0 0;
  max-width: 100%;
  height: calc(100% - 4rem);
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 20rem;
  }

  h1 {
    padding: 1rem;
    color: rgb(59, 102, 255);
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  p {
    padding: 0;
    color: #121214;
    text-align: center;
    font-size: 1rem;
  }

`;
