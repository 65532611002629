import { useState, useEffect, } from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';
import { useDispatch, useSelector, } from 'react-redux';
import { useHistory, } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Icon, Notification, } from 'rsuite';
import { listCompanyDueDiligenceTemplates, } from '../../services/api';
import CustomPaginator from '../../components/Paginators/CustomPaginator';

import AddIcon from '@material-ui/icons/Add';

import DueDiligenceRequestsTable from '../../components/DueDiligenceRequestsTable';
import DueDiligenceRequestForm from '../../components/DueDiligenceRequestForm';

import useModal from '../../hooks/useModal';

import {
  createDueDiligenceRequest,
  listCompanyDueDiligenceRequests,
  resendCompanyDueDiligenceRequestNotification,
} from '../../services/api';

import {
  selectProcessingAgents,
  selectProcessingAgentsMap,
  setProcessingAgentsModal,
} from '../../features/processingAgentsSlice';
import { selectCompany, } from '../../features/companySlice';
import { InputAdornment, TextField } from '@mui/material';


export default function DueDiligenceRequests() {
  const { t, } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [formModalOpen, openFormModal, closeFormModal] = useModal();
  const [dueDiligenceRequests, setDueDiligenceRequests] = useState([]);
  const processingAgentsMap = useSelector(selectProcessingAgentsMap);
  const processingAgents = useSelector(selectProcessingAgents);
  const [templates, setTemplates] = useState([]);
  const [objTemplates, setObjTemplates] = useState([]);
  const [newData, setNewData] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itensPerPage, setItensPerPage] = useState(5);


  async function getTemplates() {
    try {
      const { data, } = await listCompanyDueDiligenceTemplates(company._id);
      setTemplates(data.templates);
      handlerSetObjectTemplates(data.templates);
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_load_company_due_diligence_templates')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  function handlerSetObjectTemplates(array) {
    const objetoPorId = array.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
    setObjTemplates(objetoPorId);
  }

  useEffect(() => {
    getTemplates();
  }, []);


  function onView(dueDiligenceRequestId) {
    history.push(`/companies/${company._id}/due-diligence/${dueDiligenceRequestId}`);
  }

  async function onCreateRequest(data) {

    try {
      const response = await createDueDiligenceRequest(company._id, {
        templateId: data.template,
        processingAgents: data.processingAgents,
      });

      const newDueDiligenceRequests = response.data.requests || [];
      setDueDiligenceRequests([...newDueDiligenceRequests, ...dueDiligenceRequests]);

      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DUE_DILIGENCE.due_diligence_request_successfully_created')),
      });
      closeFormModal();
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_create_due_diligence_request')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function onResend(dueDiligenceRequestId) {
    try {
      await resendCompanyDueDiligenceRequestNotification(company._id, dueDiligenceRequestId);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DUE_DILIGENCE.due_diligence_request_notification_successfully_resent')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_resend_due_diligence_request_notification')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  function onCreateNewProcessingAgent() {
    dispatch(setProcessingAgentsModal(true));
  }

  async function initialize() {
    try {
      const { data, } = await listCompanyDueDiligenceRequests(company._id);
      setDueDiligenceRequests(data.requests);
      setShowError(false);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_load_due_diligence_requests')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
      setShowError(true);
    }

    setLoading(false);
  }

  function setValues() {
    const newArray = dueDiligenceRequests.map((obj) => {
      return {
        ...processingAgentsMap[obj.processingAgentId],
        template: objTemplates[obj.dueDiligenceTemplateId],
        ...obj,
      };
    });
    const newItensFilter = filterItens(newArray);
    const groupItens = group(newItensFilter);
    return groupItens;
  }

  function group(data) {
    const groupedData = data.reduce((acc, current) => {
      const groupKey = `${current.processingAgent.id}-${current.template.id}`;
      if (!acc[groupKey]) acc[groupKey] = [];
      acc[groupKey].push(current);
      return acc;
    }, {});
    for (let key in groupedData) {
      groupedData[key].sort((a, b) => ((a.score || 0) < (b.score || 0)) && (a.createdAt < b.createdAt) ? 1 : -1);
    }
    return Object.values(groupedData);
  }

  function filterItens(data) {
    let newData = data;
    if (search != '') {
      newData = newData.filter(item => !!item.name?.toLowerCase().includes(search.toLowerCase()));
    }
    return newData;
  }

  function handlerSplitData(array, pageNumber, itemsPerPage) {
    if (itemsPerPage >= array.length) {
      return array;
    }
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return array.slice(startIndex, endIndex);
  }

  function handlerPageChange(event, newPage) {
    const page = newPage;
    setCurrentPage(page);
  }

  function handlerRowsChange(event) {
    const rowsPerPage = parseInt(event.target.value, 10);
    setItensPerPage(rowsPerPage);
  }

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    setNewData(setValues());
  }, [processingAgentsMap, dueDiligenceRequests, search]);


  return (
    <Grid container >
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        justifyContent="space-between"
        style={{
          marginBottom: '1rem',
        }}
      >
        <div style={{ marginLeft: 10 }}>
          <TextField
            label="Pesquisar"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            style={{ background: "#fff", marginLeft: "10px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon icon="search" />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Button
          variant="contained"
          disableElevation
          color="primary"
          startIcon={<AddIcon />}
          onClick={openFormModal}
        >
          {_.capitalize(t('PAGES.DUE_DILIGENCE.send_due_diligence'))}
        </Button>
      </Grid>
      <Container
        maxWidth="xl"
        component={Paper}
      >
        <CustomPaginator data={newData} rowsPerPage={itensPerPage} page={currentPage} onPageChange={handlerPageChange} onRowsPerPageChange={handlerRowsChange} />
          <DueDiligenceRequestsTable
            loading={loading}
            showError={showError}
            data={handlerSplitData(newData, currentPage, itensPerPage)}
            processingAgentsMap={processingAgentsMap}
            onView={onView}
            onResend={onCreateRequest}
          />
        <CustomPaginator data={newData} rowsPerPage={itensPerPage} page={currentPage} onPageChange={handlerPageChange} onRowsPerPageChange={handlerRowsChange} />
      </Container>

      <Dialog
        open={formModalOpen}
        onClose={closeFormModal}
        PaperProps={{
          style: {
            width: '650px',
            maxWidth: '90%',
          },
        }}
      >
        <DialogTitle>
          {_.capitalize(t('PAGES.DUE_DILIGENCE.send_due_diligence'))}
        </DialogTitle>

        <DialogContent>
          <DueDiligenceRequestForm
            onSubmit={onCreateRequest}
            processingAgents={processingAgents}
            templates={templates}
            onCreateNewProcessingAgent={onCreateNewProcessingAgent}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
