import React from "react";
import Sheet from "../Sheet";
import MainProfile from "../../pages/Profile";
import CreateUser from "../../pages/CriarUsuarios/ModalCriarUser";
import CollaboratorsPage from "../CollaboratorsModal/CollaboratorsPage";
import ProcessingAgentsPage from "../ProcessingAgents/ProcessingAgentsPage";
import CompanySectorsPage from "../CompanySectorsModal/CompanySectorsPage";
import CompanyCategoriesPage from "../CompanyCategoriesModal/CompanyCategoriesPage";
import CompanySensitiveDataOptionPage from "../CompanySensitiveDataOptionModal/CompanySensitiveDataOptionPage";
import CompanyPersonalDataTypePage from "../CompanyPersonalDataTypeModal/CompanyPersonalDataTypePage";
import Organization from "../../pages/Organization/Organization";

const PAGES = Object.freeze({
  perfil: {
    id: 'perfil',
    titleTab: "Perfil",
    component: <MainProfile />,
  },
  usuarios: {
    id: "usuarios",
    titleTab: "Usuários",
    component: <CreateUser />,
  },
  colaboradores: {
    id: "colaboradores",
    titleTab: "Colaboradores",
    component: <CollaboratorsPage />,
  },
  terceiros: {
    id: "terceiros",
    titleTab: "Terceiros",
    component: <ProcessingAgentsPage />,
  },
  setores: {
    id: "setores",
    titleTab: "Setores",
    component: <CompanySectorsPage />,
  },
  categorias: {
    id: "categorias",
    titleTab: "Categorias",
    component: <CompanyCategoriesPage />,
  },
  dadospessoais: {
    id: "dadospessoais",
    titleTab: "Dados pessoais",
    component: <CompanyPersonalDataTypePage />,
  },
  dadossensiveis: {
    id: "dadossensiveis",
    titleTab: "Dados sensíveis",
    component: <CompanySensitiveDataOptionPage />,
  },
  organization: {
    id: 'organization',
    titleTab: "Organização",
    titlePage: "Organização",
    component: <Organization />,
  },
});

const ARRAY_PAGES = Object.values(PAGES);


export default function ConfigCompany() {
  return <Sheet pages={ARRAY_PAGES} />;
}
