import React, { memo, useEffect, } from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';
import { useFormik, } from 'formik';
import * as Yup from 'yup';

import {
  Container,
  TextField,
  Grid,
} from '@material-ui/core';
import { Button, } from 'rsuite';


const INITIAL_VALUES = Object.freeze({
  question: '',
});


function DueDiligenceTemplateQuestionForm({ data, onSubmit, loading, }) {
  const { t, } = useTranslation();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: onSubmit,
    validationSchema: Yup.object({
      question: Yup.string().required(_.capitalize(t('VALIDATION_MESSAGES.required', {
        context: 'male',
      }))),
    }),
  });

  useEffect(() => {
    if (undefined != data && null != data.id) {
      formik.setValues(data);
    } else {
      formik.setValues(INITIAL_VALUES);
    }
  }, [data]);


  return (
    <Container
      disableGutters
      maxWidth="xl"
    >
      <TextField
        margin="dense"
        label={_.capitalize(t('question'))}
        fullWidth
        id="question"
        variant="filled"
        multiline
        rows={4}
        type="text"
        error={formik.errors.question && formik.touched.question}
        helperText={formik.touched.question && formik.errors.question}
        {...formik.getFieldProps('question')}
      />

      <Grid
        alignItems="center"
        justifyContent="flex-end"
        container
        style={{
          marginTop: '1rem',
        }}
      >
        <Button
          appearance="primary"
          loading={loading}
          onClick={formik.handleSubmit}
        >
          {_.capitalize(t('save'))}
        </Button>
      </Grid>
    </Container>
  );
}

export default memo(DueDiligenceTemplateQuestionForm);
