import { createSlice } from '@reduxjs/toolkit'

export const ReponderPerguntas = createSlice({
  name: 'perguntas',
  initialState: {
    value: [],
    loading: true,
    title: 'Todas as tarefas',
    modalTasks: false
  },
  reducers: {
    resetItens: (state) => {
      state.value = []
    },
    setItens: (state, action) => {
      state.value = action.payload
    },
    loadingTrue: (state) => {
      state.loading = true
    },
    loadingFalse: (state) => {
      state.loading = false
    },
    updateTitle: (state, action) => {
      state.title = action.payload
    },
    setModalTasks: (state, action) => {
      state.modalTasks = action.payload
    },
  },
})

export const {
  setItens,
  resetItens,
  updateTitle,
  loadingFalse,
  loadingTrue,
  setModalTasks
} = ReponderPerguntas.actions

export default ReponderPerguntas.reducer
