import React from 'react';
import { useTranslation, } from 'react-i18next';
import { useFormik, } from 'formik';
import * as Yup from 'yup';

import {
  Container,
  TextField,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button, } from 'rsuite';


const INITIAL_VALUES = Object.freeze({
  name: '',
});


const useStyles = makeStyles({
  cancelBtn: {
    marginRight: '1rem',
    borderColor: '#3B66FF',
    color: '#3B66FF !important',
    background: 'transparent !important',
    boxShadow: 'none',
    borderWidth: 2,
    border: '2px solid #3B66FF !important',
    '&:hover': {
      background: 'transparent !important',
      border: '2px solid #3B66FF !important',
    },
  },
});


/**
 * Type option form
 *
 * @param {Object | undefined} data - type option data
 * @param {() => void} onCancel - cancel callback
 * @param {(data: Object) => boolean} onSubmit - submit callback, returns whether the form should be
 * cleared or not
 */
function TypeOptionForm({ data, onCancel, onSubmit, }) {
  const { t, } = useTranslation();
  const classes = useStyles();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: _onSubmit,
    validationSchema: Yup.object({
      name: Yup.string().required(t('VALIDATION_MESSAGES.required', { context: 'male', })),
    }),
  });
  const [loading, setLoading] = React.useState(false);


  async function _onSubmit(values) {
    setLoading(true);
    if (await onSubmit({ ...data, ...values, })) {
      formik.resetForm({
        values: INITIAL_VALUES,
      });
    }
    setLoading(false);
  }

  function _onCancel() {
    formik.resetForm({
      values: INITIAL_VALUES,
    });
    onCancel();
  }

  React.useEffect(() => {
    formik.setValues(data || INITIAL_VALUES);
  }, [data]);


  return (
    <Container
      disableGutters
      fullWidth
      maxWidth="xl"
    >
      <TextField
        margin="dense"
        label={t('name')}
        fullWidth
        id="name"
        variant="filled"
        InputProps={{
          disableUnderline: true,
        }}
        type="text"
        error={formik.errors.name && formik.touched.name}
        helperText={formik.touched.name && formik.errors.name}
        {...formik.getFieldProps('name')}
      />

      <Grid
        container
        justifyContent="flex-end"
        style={{
          marginTop: '0.5rem',
        }}
      >
        {
          undefined != data ?
          <Button
            appearance="primary"
            className={classes.cancelBtn}
            onClick={_onCancel}
            loading={loading}
          >
            { t('cancel') }
          </Button> :
          null
        }

        <Button
          appearance="primary"
          loading={loading}
          onClick={formik.handleSubmit}
          disabled={formik.values.name == (data || {}).name}
        >
          { t('save') }
        </Button>
      </Grid>
    </Container>
  );
}

export default React.memo(TypeOptionForm);
