import { createSlice } from '@reduxjs/toolkit'

export const perguntasSlice = createSlice({
  name: 'perguntas',
  initialState: {
    value: [],
    modalCreateNewTaskIsVisible: false,
  },
  reducers: {
    resetPerguntas: (state) => {
      state.value = []
    },
    getPerguntas: (state, action) => {
      state.value = action.payload
    },
    setModalCreateNewTaskIsVisible: (state, action) => {
      state.modalCreateNewTaskIsVisible = action.payload
    },
  },
})

export const { getPerguntas, resetPerguntas, setModalCreateNewTaskIsVisible } =
  perguntasSlice.actions

export default perguntasSlice.reducer
