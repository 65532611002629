import React, { useState, memo, useEffect, } from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';
import { useFormik, } from 'formik';
import * as Yup from 'yup';

import {
  Container,
  TextField,
  Grid,
} from '@material-ui/core';
import { Button, } from 'rsuite';


const INITIAL_VALUES = Object.freeze({
  name: '',
  description: '',
});


function DueDiligenceTemplateForm({ data, onSubmit, loading, }) {
  const { t, } = useTranslation();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: onSubmit,
    validationSchema: Yup.object({
      name: Yup.string().required(_.capitalize(t('VALIDATION_MESSAGES.required', {
        context: 'male',
      }))),
      description: Yup.string(),
    }),
  });

  useEffect(() => {
    if (undefined != data && null != data.id) {
      formik.setValues(data);
    } else {
      formik.setValues(INITIAL_VALUES);
    }
  }, [data]);


  return (
    <Container
      disableGutters
      maxWidth="xl"
    >
      <TextField
        margin="dense"
        label={_.capitalize(t('name'))}
        fullWidth
        id="name"
        variant="filled"
        type="text"
        error={formik.errors.name && formik.touched.name}
        helperText={formik.touched.name && formik.errors.name}
        {...formik.getFieldProps('name')}
      />

      <TextField
        margin="dense"
        label={_.capitalize(t('description', { count: 1, }))}
        fullWidth
        id="description"
        variant="filled"
        multiline
        rows={4}
        type="text"
        error={formik.errors.description && formik.touched.description}
        helperText={formik.touched.description && formik.errors.description}
        {...formik.getFieldProps('description')}
      />

      <Grid
        alignItems="center"
        justifyContent="flex-end"
        container
        style={{
          marginTop: '1rem',
        }}
      >
        <Button
          appearance="primary"
          loading={loading}
          onClick={formik.handleSubmit}
        >
          {_.capitalize(t('save'))}
        </Button>
      </Grid>
    </Container>
  );
}

export default memo(DueDiligenceTemplateForm);
