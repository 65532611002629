import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FilterListIcon from '@mui/icons-material/FilterList';

import Field from './Field';
import StyledTooltip from '../Tooltip';

import DataMappingAnswerPanelContext from './context';

import { MAPPING_FIELD_TYPES, } from '../../data/constants';
import { getFieldType, } from '../../core/entities/data-mapping-field';
import { Box, Button, DialogActions, Menu, Typography } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DataMappingFilterDialog from "./DataMappingFilterDialog";


export const FieldColumnContainer = styled.div`
  display: block;
  width: 13.5rem !important;
  padding: 0;
  margin: 2px 2px 0 0;
`;

export const ColumnTitleContainer = styled.div`
  display: flex;
  width: 13.5rem;
  height: 2.5rem;
  padding: 5px;
  margin: 0;
  margin-bottom: 2px;
  border: none;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #54708c;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 5;

  strong {
    color: #fff;
    font-family: Inter;
    flex-grow: 1;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    justify-content: center;
  }
`;

export const ColumnContentContainer = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;

  .FormPerso {
    border: 1px solid #54708c;
    outline: 0;
    color: #54708c;
    justify-content: left;

    option {
      color: #54708c;
      justify-content: left;
    }
  }

  .StyleCheck {
    width: 100%;
    height: 2.39rem;
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #54708c;
    justify-content: center;
    align-items: center;
  }
`;


/**
 * Component to render field column in data mapping
 *
 * @param {Object} phase - phase data
 * @param {Object} field - field data
 * @param {Array} answers - answers to load
 * @param {Function} updateOptions - callback called when field options are updated
 * @param {Function?} onAddFilter - callback called when filter is added
 */
export default function DataMappingFieldColumn({ phase, field, answers, updateOptions, onAddFilter, setTemporaryFilter = () => { } }) {
  const context = React.useContext(DataMappingAnswerPanelContext);
  const type = getFieldType(field.asType, field.typePre, field.inputType) || MAPPING_FIELD_TYPES.TEXT;

  return (
    <FieldColumnContainer>
      <ColumnTitleContainer>
        {
          (field.description != undefined) && (field.description != '') ?
            <StyledTooltip
              arrow
              title={field.description}
              PopperProps={{
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: '0px, 0px',
                  }
                }
              }}
              placement="top"
            >
              <strong>
                {field.name}
              </strong>
            </StyledTooltip> :
            <strong>
              {field.name}
            </strong>
        }
        <DataMappingFilterDialog field={field} onFilter={onAddFilter} setTemporaryFilter={setTemporaryFilter}/>

      </ColumnTitleContainer>

      <ColumnContentContainer>
        {
          answers
            .map((answer, index) => {
              return (
                <Field
                  key={answer.uid}
                  answer={answer}
                  index={index}
                  phase={phase}
                  field={field}
                  token={context.token}
                  isPublic={context.isPublic}
                  updateOptions={updateOptions}
                  readOnly={(type.value == MAPPING_FIELD_TYPES.SECTOR.value) && context.isPublic}
                />
              );
            })
        }
      </ColumnContentContainer>

    </FieldColumnContainer>
  );
}
