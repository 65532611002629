// https://v5.mui.com/material-ui/react-dialog/#optional-sizes
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@material-ui/core";
import React from "react";
import { Icon } from 'rsuite';
import { useThemeContext } from "../../../context/ThemeAppContext";


export default function DefaultModal({ children, title, onClose = () => { }, open, maxWidth = 'xs', fullWidth = true }) {

    const { stylesLayout } = useThemeContext();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
        >
            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", background: stylesLayout.colors.secondaryColor, color: "#fff" }}>
                <DialogTitle id="confirmation-dialog-title">{title || 'Adicionar campo'}</DialogTitle>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <IconButton aria-label="close" style={{ marginRight: '20px' }} onClick={onClose}>
                        <Icon style={{ fontWeight: 'bold', color: "#fff" }} icon="close" />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent dividers style={{ padding: '0px' }}>
                {children}
            </DialogContent>
        </Dialog>
    )
}

