import { withStyles, } from "@material-ui/styles";
import Backdrop from '@material-ui/core/Backdrop';


const LimitedBackdrop = withStyles({
  root: {
    position: 'absolute',
    zIndex: 10,
  }
})(Backdrop);


export default LimitedBackdrop;
