import styles from './styleIndex.module.scss';
import { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';

import { Icon, Notification, Button, } from 'rsuite';
import { Row, Col, Form, ListGroup, } from 'react-bootstrap'
import {
  Container,
  Paper,
  Collapse,
  Divider,
  Chip,
  Avatar,
  Dialog,
  TableCell,
  TableRow,
  IconButton,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';



import api, {
  updateDPOContactRequest,
  listCompanyContactRequestObservations,
} from '../../services/api';
import DefaultModal from '../../components/Modals/DefaultModal/DefaultModal';
import { Box, Button as ButtonMui, ButtonBase, Typography } from '@mui/material';


export default function RowSolicitacoes({ dados, getRequests, companyId, }) {
  const [open, setOpen] = useState(false)
  const [status, setStatus] = useState(dados.status)
  const [resposta, setResposta] = useState(dados.resposta)
  const [obs, setObs] = useState('')
  const [observacao, setObservacao] = useState([])
  const [openFileViewer, setOpenFileViewer] = useState(false)
  const [position, setPosition] = useState(0)
  const [modalRequestIsVisible, setModalRequestIsVisible] = useState(false);


  async function getObs() {
    try {
      const response = await listCompanyContactRequestObservations(companyId, dados.id);
      setObservacao(response.data.observations);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getObs()
  }, []);

  const sendObs = async () => {
    if (!resposta)
      return Notification['warning']({
        placement: 'bottomEnd',
        title: 'Sem conteudo de observação',
      })
    await api
      .post(`/solicitacao/obs`, {
        solicitacao: dados.id,
        value: resposta,
        user: 'DPO',
      })
      .then(() => {
        Notification['success']({
          placement: 'bottomEnd',
          title: 'Observação salva com sucesso',
        })
        updateSolicitacao()
        setObs('')
        getObs()
      })
  }

  async function updateSolicitacao() {
    try {
      await updateDPOContactRequest({
        id: dados.id,
        response: resposta,
        status: status,
        email: dados.email,
        name: dados.name,
      });

      if (status === 'Concluido') {
        getRequests();
      }

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Notificação respondida com sucesso',
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Erro ao atualizar a notificação',
      });
    }
  }

  async function updateStep() {
    try {
      if (!status || (status === 'Em aberto')) {
        const { status, } = await updateDPOContactRequest({
          id: dados.id,
          response: 'O DPO está analisando a sua solicitação, aguarde.',
          status: 'Em andamento',
          email: dados.email,
          name: dados.name,
        });

        if (status === 200) {
          setStatus('Em andamento')
          Notification['success']({
            placement: 'bottomEnd',
            title: 'Status alterado com sucesso',
          });
        } else if (status === 204) {
          Notification['error']({
            placement: 'bottomEnd',
            title: 'Erro ao alterar status',
          });
        }
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Erro ao alterar status',
      });
    }
  }

  function getStatusColor(status) {
    return (status === 'Concluido') ? 'green' :
      (status === 'Em andamento') ? '#FFB54D' :
        '#F73944';
  }

  function handlerContinueModal() {
    updateStep();
    setOpen(!open);
    setModalRequestIsVisible(false);
  }

  const cellStyle = {
    color: open ? 'white' : 'black',
  };


  return (
    <>
      <TableRow className={!open ? styles.RowSolicitacoes : styles.RowSolicitacoesOpen}>
        <TableCell
          style={cellStyle}
          align="center"
        >
          {dados.protocol_id}
        </TableCell>
        <TableCell
          style={cellStyle}
          align="center"
        >
          {dados.origin}
        </TableCell>
        <TableCell
          style={cellStyle}
          align="center"
        >
          <Chip
            label={status}
            style={{
              backgroundColor: getStatusColor(status),
            }}
            className={styles.boxStatus}
          />
        </TableCell>
        <TableCell
          style={cellStyle}
          align="center"
        >
          {dados.name}
        </TableCell>

        <TableCell
          style={cellStyle}
          align="center"
        >
          {dados.typeReq != undefined ? dados.typeReq : ''}
        </TableCell>

        <TableCell
          style={cellStyle}
          align="center"
        >
          {moment(dados.created_at).fromNow()}
        </TableCell>
        <TableCell
          style={cellStyle}
          align="center"
        >
          {
            status === 'Concluido' && dados.closed_at != null ?
              moment(dados.closed_at).from(moment(dados.created_at), true) :
              ''
          }
        </TableCell>
        <TableCell
          style={cellStyle}
          align="center"
        >
          <IconButton
            onClick={() => {
              if (status === 'Em aberto') {
                setModalRequestIsVisible(true);
                return;
              }
              updateStep();
              setOpen(!open);
            }}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      {
        open ?
          <TableRow>
            <TableCell
              colSpan={8}
              style={{
                padding: '0.1rem',
                margin: 0,
              }}
            >
              <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
              >
                <Container
                  maxWidth="xl"
                  component={Paper}
                >
                  <br />
                  <Form>
                    <br />
                    <Row>
                      <Col xs={6}>
                        <Form.Label
                          style={{
                            color: '#1C265A',
                            fontSize: '14px',
                          }}
                        >
                          Solicitação de {dados.email}
                        </Form.Label>
                        <Form.Control
                          disabled
                          as="textarea"
                          value={dados.obs}
                        />
                        <br />
                        <Form.Label
                          style={{
                            color: '#3B66FF',
                            fontSize: '19px',
                            fontWeight: 'bold',
                          }}
                        >
                          Resposta
                        </Form.Label>
                        <Form.Control
                          value={resposta}
                          onChange={(e) => {
                            setResposta(e.target.value)
                          }}
                          rows={5}
                          as="textarea"
                        />
                      </Col>
                      <Col
                        xs={1}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Divider orientation="vertical" />
                      </Col>
                      <Col
                        xs={5}
                        className={styles.boxExternMapObs}
                      >
                        <ListGroup>
                          {observacao.map((obs, index) => {
                            return (
                              <div
                                className={obs.user === 'DPO' ? styles.BoxAllObsDPO : styles.BoxAllObs}
                              >
                                <div
                                  key={obs.id}
                                  className={obs.user === 'DPO' ? styles.ChatLeftUserDPO : styles.ChatLeftUser}
                                >
                                  <div>
                                    <Chip
                                      variant="filled"
                                      style={{ border: 'none' }}
                                      avatar={
                                        <Avatar
                                          className={styles.Avatar}
                                        >
                                          {<Icon icon="user" />}
                                        </Avatar>
                                      }
                                      label={
                                        'Mensagem' + ' ' + obs.user
                                      }
                                    />
                                  </div>
                                  {
                                    !obs.file ?
                                      <p
                                        style={{
                                          width: '100ch',
                                          maxWidth: '100%',
                                          whiteSpace: 'pre-line',
                                          wordBreak: 'break-word',
                                        }}
                                      >
                                        {obs.resposta}
                                      </p> :
                                      <p
                                        style={{
                                          width: '100ch',
                                          maxWidth: '100%',
                                          whiteSpace: 'pre-line',
                                          wordBreak: 'break-word',
                                        }}
                                      >
                                        {obs.resposta} - {obs.size}
                                        Kb
                                        <Icon
                                          style={{
                                            marginLeft: '20px',
                                            color: '#3B66FF',
                                            cursor: 'pointer',
                                          }}
                                          onClick={() =>
                                            (window.location.href = `https://s3.sa-east-1.amazonaws.com/octo.legal.images/${obs.file}`)
                                          }
                                          size="lg"
                                          icon="cloud-download"
                                        />
                                        <Icon
                                          style={{
                                            marginLeft: '10px',
                                            color: '#3B66FF',
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => {
                                            setOpenFileViewer(true)
                                            setPosition(index)
                                          }}
                                          size="lg"
                                          icon="external-link"
                                        />
                                      </p>
                                  }

                                  {index === position && (
                                    <Dialog
                                      open={openFileViewer}
                                      onClose={() =>
                                        setOpenFileViewer(false)
                                      }
                                      maxWidth="xl"
                                    >
                                      <Container
                                        maxWidth="xl"
                                        style={{
                                          width: '800px',
                                          height: '600px',
                                        }}
                                        disableGutters
                                      >
                                        {obs.doc_type ===
                                          'application/pdf' ? (
                                          <iframe
                                            src={`https://docs.google.com/viewer?url=https://s3.sa-east-1.amazonaws.com/octo.legal.images/${obs.file}&hl=en&pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
                                            width="100%"
                                            height="100%"
                                          />
                                        ) : (
                                          <img
                                            src={`https://s3.sa-east-1.amazonaws.com/octo.legal.images/${obs.file}`}
                                            width="100%"
                                            height="100%"
                                          />
                                        )}
                                      </Container>
                                    </Dialog>
                                  )}
                                  <span className={styles.dateMSG}>
                                    {moment(obs.created_at).format('HH:mm - DD/MM/YYYY')}
                                  </span>
                                </div>
                              </div>
                            )
                          })}
                        </ListGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs={6} className={styles.boxBTN}>
                        <Form.Control
                          value={status}
                          onChange={(e) => {
                            setStatus(e.target.value)
                          }}
                          style={{ maxWidth: '40%' }}
                          as="select"
                        >
                          <option>Em aberto</option>
                          <option>Em andamento</option>
                          <option>Concluido</option>
                        </Form.Control>
                        <Button onClick={sendObs} color="blue ">
                          Enviar resposta
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  <br />
                </Container>
              </Collapse>
            </TableCell>
          </TableRow> :
          null
      }

      <DefaultModal title={"Aviso!"} open={modalRequestIsVisible} onClose={() => setModalRequestIsVisible(false)}>
        <Box sx={{ p: 3 }}>
          <Typography variant="body1">
            Ao clicar na mensagem, o titular receberá um e-mail informando que a solicitação está sendo tratada e o status da solicitação muda para "EM ANDAMENTO". Deseja continuar?
          </Typography>
        </Box>
        <Divider style={{ borderColor: "rgba(0, 0, 0, 0.5)" }} />
        <Box sx={{ px: 3, py: 2, flexDirection: "row-reverse", display: "flex" }}>
          <Box>
            <ButtonMui onClick={() => setModalRequestIsVisible(false)} variant="outlined" sx={{ mr: 2 }}>Cancelar</ButtonMui>
            <ButtonMui onClick={handlerContinueModal} variant="contained">Continuar</ButtonMui>
          </Box>
        </Box>
      </DefaultModal>
    </>
  );
}
