import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import { TableCell, TableRow, IconButton, } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

import Table from '../Table';


export default function DocumentSignaturesTable({
  data,
  loading,
  showError,
  collaboratorsMap,
  onResend,
}) {
  const { t, } = useTranslation();


  return (
    <Table
      headers={[
        {
          label: _.capitalize(t('name')),
          align: 'center',
        },
        {
          label: _.capitalize(t('email')),
          align: 'center',
        },
        {
          label: _.capitalize(t('PAGES.DOCUMENT_SIGNATURE_REQUEST.sent_at')),
          align: 'center',
        },
        {
          label: _.capitalize(t('PAGES.DOCUMENT_SIGNATURE_REQUEST.opened_at')),
          align: 'center',
        },
        {
          label: _.capitalize(t('PAGES.DOCUMENT_SIGNATURE_REQUEST.signed_at')),
          align: 'center',
        },
        {
          label: 'IP',
          align: 'center',
        },
        {
          label: '',
          align: 'center',
        },
      ]}
      data={data}
      loading={loading}
      errorMessage={_.capitalize(t('ERRORS.DOCUMENT_SIGNATURE_REQUEST.failed_to_load_document_signatures'))}
      noDataMessage={_.capitalize(t('PAGES.DOCUMENT_SIGNATURE_REQUEST.no_document_signature_requests'))}
      disablePagination
      showError={showError}
      style={{
        maxHeight: 350,
        overflowY: 'auto',
      }}
    >
      {
        (item, props) => {
          const collaborator = collaboratorsMap[item.collaboratorId] || {};

          return (
            <TableRow {...props}>
              <TableCell align="center" >
                { collaborator.name }
              </TableCell>
              <TableCell align="center" >
                { collaborator.email }
              </TableCell>
              <TableCell align="center" >
                { moment(item.createdAt).format('HH:mm, DD/MM/YYYY') }
              </TableCell>
              <TableCell align="center" >
                { item.openedAt ? moment(item.openedAt).format('HH:mm, DD/MM/YYYY') : '-' }
              </TableCell>
              <TableCell align="center" >
                { item.signedAt ? moment(item.signedAt).format('HH:mm, DD/MM/YYYY') : '-' }
              </TableCell>
              <TableCell align="center" >
                { item.ip ? item.ip : '-' }
              </TableCell>
              <TableCell align="center">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    onClick={() => onResend(item.id)}
                    size="small"
                    style={{
                      marginRight: '0.5rem',
                    }}
                  >
                    <SendIcon fontSize="small" />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          );
        }
      }
    </Table>
  );
}
