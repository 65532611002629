import React from 'react';
import _ from 'lodash';
import fileDownload from 'js-file-download';
import { useTranslation, } from 'react-i18next';

import { Grid, Typography, } from '@material-ui/core';

import Dialog from '../Dialog';
import UploadFile from '../UploadFile';

import FileUploaderImg from '../../assets/Vector.svg';
import FileDownloadImg from '../../assets/DowloadIcon.svg';
import { downloadChecklistCSVFileTemplate } from '../../services/api';


function UploadChecklistDialog({ open, onClose, onChange, }) {
  const { t, } = useTranslation();


  async function onDownloadTemplate() {
    const response = await downloadChecklistCSVFileTemplate();
    fileDownload(response.data, 'template-diagnóstico.xlsx');
  }


  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={_.capitalize(t('PAGES.MATURITY_CHECKLIST.import_checklist'))}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="space-around"
      >
        <Grid
          item
          xs={6}
          container
          alignItems="center"
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              align="left"
              display="block"
              variant="subtitle2"
              color="textSecondary"
            >
              { _.capitalize(t('PAGES.step', { count: 1, })) }
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            direction="column"
            alignItems="center"
            onClick={onDownloadTemplate}
            style={{
              cursor: 'pointer',
            }}
          >
            <img
              src={FileDownloadImg}
              alt="Imagem de Documento"
              style={{
                marginBottom: '1rem',
                height: 44,
              }}
            />

            <Grid
              item
              xs={12}
            >
              <Typography
                align="left"
                display="block"
                style={{
                  color: 'rgb(59, 102, 255)',
                  fontWeight: 'bold',
                  fontSize: '13px',
                }}
              >
                a) { t('PAGES.MATURITY_CHECKLIST.download_template') }
              </Typography>

              <Typography
                align="left"
                display="block"
                style={{
                  color: 'rgb(59, 102, 255)',
                  fontWeight: 'bold',
                  fontSize: '13px',
                }}
              >
                b) { t('PAGES.MATURITY_CHECKLIST.import_checklist_guide') }
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={6}
          container
          alignItems="center"
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              align="left"
              display="block"
              variant="subtitle2"
              color="textSecondary"
            >
              { _.capitalize(t('PAGES.step', { count: 2, })) }
            </Typography>
          </Grid>

          <UploadFile
            title={t('PAGES.MATURITY_CHECKLIST.select_a_file')}
            subtitle=""
            onChange={onChange}
            image={(
              <img
                src={FileUploaderImg}
                alt="Imagem de Documento"
                style={{
                  marginBottom: '1rem',
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default React.memo(UploadChecklistDialog);
