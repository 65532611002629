import { useState, } from 'react';
import { Container, Paper, Grid, } from '@material-ui/core';

import { FooterGlobal } from '../../components/Site/footer/footerIndex';
import { HeaderGlobal } from '../../components/Site/header/headerIndex';
import { GlobalStyle } from '../../assets/styles/global';
import LoginComponent from './NewComponentLogin';
import CreateNewAccount from './CreateNewAccount';
import PasswordRecovery from './passRecovery';


const OPTIONS = Object.freeze({
  LOGIN: 1,
  REGISTRATION: 2,
  PASSWORD_RECOVERY: 3,
});


export default function Login() {
  const [selectedOption, setSelectedOption] = useState(OPTIONS.LOGIN);

  function showRegistration() {
    setSelectedOption(OPTIONS.REGISTRATION);
  }

  function showLogin() {
    setSelectedOption(OPTIONS.LOGIN);
  }

  function showPasswordRecovery() {
    setSelectedOption(OPTIONS.PASSWORD_RECOVERY);
  }


  return (
    <Container
      disableGutters
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        minWidth: '100vw',
      }}
    >
      <HeaderGlobal />

      <Grid
        style={{
          flexGrow: 1,
          padding: '0.75rem',
        }}
        container
        alignItems="center"
        justifyContent="center"
        >
        <Container
          maxWidth="xs"
          component={Paper}
          style={{
            paddingTop: '2.5rem',
            paddingBottom: '2.5rem',
          }}
        >
          {
            selectedOption === OPTIONS.LOGIN &&
              <LoginComponent
                openRegistration={showRegistration}
                openPasswordRecovery={showPasswordRecovery}
              />
          }
          {
            selectedOption === OPTIONS.REGISTRATION &&
            <CreateNewAccount
              openLogin={showLogin}
            />
          }
          {
            selectedOption === OPTIONS.PASSWORD_RECOVERY &&
            <PasswordRecovery
              back={showLogin}
            />
          }
        </Container>
      </Grid>

      <FooterGlobal />
      <GlobalStyle />
    </Container>
  );
}
