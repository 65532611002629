import React, { useCallback, useState } from "react";
import { makeStyles } from '@material-ui/styles';
import { useDropzone } from "react-dropzone";
import { Chip } from "@material-ui/core";
import { Delete, ArrowCircleDownOutlined } from '@mui/icons-material';

export default function FileInput({ onDrop: externalOnDrop, initFiles = [], onClick, onDelete, deletItem = true, icon }) {
    const [files, setFiles] = useState(initFiles);
    const [loading, setLoading] = useState(false);

    function handlerRemoveOneFile(id, index) {
        if (typeof onDelete === 'function') {
            onDelete(id);
        }

        if (index > -1 && index < files.length && deletItem) {
            const newFiles = [...files];
            newFiles.splice(index, 1);
            setFiles(newFiles);
        }
    }

    const onDrop = useCallback((files) => {
        setFiles(files);
        if (typeof externalOnDrop === 'function') {
            externalOnDrop(files);
        }
    }, []);

    //quando ele soltar o arquivo o que deve acontecer
    const dropzone = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf']
        }
    })


    if (files.length) return <HasFile files={files} onDelete={handlerRemoveOneFile} onClick={onClick} icon={icon} />;

    return <Input dropzone={dropzone} />;
}

const Input = ({ dropzone }) => {


    const classes = useStyles();

    return (
        <div {...dropzone.getRootProps()} className={classes.dropzone} style={{ backgroundColor: dropzone.isDragActive ? '#e0f2ff' : '#f9f9f9' }}>
            <label htmlFor="dropzone-file" className={classes.label}>
                <div>
                    {dropzone.isDragActive ? (
                        <p style={{ marginBottom: 20 }}>
                            <span style={{ fontWeight: 'bold' }}>Solte para adicionar</span>
                        </p>
                    ) : (
                        <>
                            <p style={{ marginBottom: 20 }}>
                                <span style={{ fontWeight: 'bold' }}>Clique para enviar</span> ou arraste aqui
                            </p>
                            <p style={{ fontWeight: 400 }}>
                                PDF
                            </p>
                        </>
                    )}
                </div>
            </label>
            <input {...dropzone.getInputProps()} className="hidden" />
        </div>
    )
}

const HasFile = ({ files = [], onDelete = () => { }, onClick = () => { }, icon = 'delete' }) => {

    const classes = useStyles();

    return (
        <div className={classes.hasFile}>
            <div>
                {files.map((file, index) => (
                    <Chip
                        label={file?.name}
                        onClick={() => onClick(file?.id)}
                        onDelete={() => onDelete(file?.id, index)}
                        deleteIcon={icon === 'delete' ? <Delete /> : <ArrowCircleDownOutlined />}
                        variant="outlined"
                    />
                ))}
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    dropzone: {
        height: '120px',
        border: '4px dashed #4a90e2',
        borderRadius: '8px',
        textAlign: 'center',
        color: '#4a90e2',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: '#e0f2ff',
        },
    },
    label: {
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        padding: theme.spacing(2.5)
    },
    hasFile: {
        minHeight: '120px',
        border: '4px dashed #4a90e2',
        borderRadius: '8px',
        color: '#4a90e2',
        transition: 'background-color 0.3s ease',
        padding: 20
    },
    contentHasFile: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    }
}));