import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  Menu,
  MenuItem,
  Input,
  Select,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Risk, * as RISK from '../../../core/entities/risk';

import BaseField from './BaseField';


const useStyles = makeStyles({
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

function SelectField({ label, name, value, onChange, options, }) {
  const classes = useStyles();
  const { t, } = useTranslation();


  function getStyles(value, selectedValue) {
    const selected = selectedValue == value;

    return {
      backgroundColor: selected ? 'lightblue' : 'white',
      color: selected ? 'blue' : 'black',
    };
  }


  return (
    <Grid
      item
      xs={true}
    >
      <Typography variant="caption">
        {label}
      </Typography>

      <Select
        classes={{
          root: classes.select,
        }}
        name={name}
        value={value}
        onChange={onChange}
        input={<Input />}
        fullWidth
      >
        {
          options.map((opt) => (
            <MenuItem
              key={opt.value}
              value={opt.value}
              style={getStyles(opt.value, value)}
            >
              { t(opt.key) }
            </MenuItem>
          ))
        }
      </Select>
    </Grid>
  );
}


/**
 * It uses the same props as BaseField component
 */
export default function RiskField(props) {
  const { t, } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [color, setColor] = React.useState(RISK.RISK_STATUSES.UNKNOWN.color);


  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  };


  return (
    <BaseField
      {...props}
      borderColor={color}
      render={(resp, onChangeResp) => {
        let value = {
          probability: 0,
          impact: 0,
        };

        function onChange(ev) {
          const updatedValue = {
            ...value,
            [ev.target.name]: Number(ev.target.value),
          };
          onChangeResp(JSON.stringify(updatedValue));
        }

        try {
          value = JSON.parse(resp);
        } catch {
        }
        const risk = Risk.getRisk(value.probability, value.impact);
        if (risk.color != color) {
          setColor(risk.color);
        }

        return (
          <div
            style={{
              height: '100%',
            }}
          >
            <div
              onClick={handleClick}
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: risk.color,
                textTransform: 'uppercase',
                cursor: 'pointer',
                fontSize: 12,
                fontWeight: 600
              }}
            >
              {t(`RISK_STATUSES.${risk.key}`)}
            </div>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Grid
                container
                style={{
                  padding: '0.5rem',
                  maxWidth: 280,
                }}
                spacing={1}
              >
                <SelectField
                  label={_.capitalize(t('probability'))}
                  name="probability"
                  value={value.probability}
                  onChange={onChange}
                  options={RISK.PROBABILITY_OPTIONS}
                />

                <SelectField
                  label={_.capitalize(t('impact'))}
                  name="impact"
                  value={value.impact}
                  onChange={onChange}
                  options={RISK.IMPACT_OPTIONS}
                />
              </Grid>
            </Menu>
          </div>
        );
      }}
    />
  );
}
