import {useCallback, useState} from "react";
import useAsyncState from "./useAsyncState";
import html2pdf from "html-to-pdf-js";

/**
 *
 * @param htmlContainer {Ref<HTMLElement>}
 * @param options {{
 *   margin?: {
 *     top: number,
 *     bottom: number,
 *     left: number,
 *     right: number,
 *   },
 *   filename?: string,
 *   image?: { type: string },
 *   html2canvas?: { scale: number,},
 *   jsPDF?: { unit: 'cm', format: 'A4', orientation: 'landscape' | 'portrait' },
 *   pagebreak?: { mode: ('avoid-all' | 'css' | 'legacy')[]},
 * }}
 * @returns {[function(): Promise<void>, boolean, Error]}
 */
export default function usePdf(htmlContainer, options = {}) {

  const generate = useCallback(async () => {
    await html2pdf().from(htmlContainer.current).set({
      margin: options.margin ? [options.margin.top, options.margin.left, options.margin.bottom, options.margin.right] : undefined,
      filename: options.filename,
      image: options.image,
      html2canvas: options.html2canvas,
      jsPDF: options.jsPDF,
      pagebreak: options.pagebreak,
    }).save();
  }, [htmlContainer.current, options]);

  const {_, loading, convert, error} = useAsyncState(generate, null);
  return [convert, loading, error];
}
