import React, { useEffect, useState } from "react";
import { Breadcrumbs, Link } from "@material-ui/core";
import { useSelector, } from 'react-redux';
import { selectCompany } from "../../features/companySlice";


function getRoutes(compaanyId) {
  const routeMaain = `/companies/${compaanyId}`

  return {
    Respostas: {
      titles: ['Home', 'Diagnóstico'],
      hrefs: [routeMaain, `Respostas`]
    },
    Mapeamento: {
      titles: ['Home', 'Mapeamento'],
      hrefs: [routeMaain, `Mapeamento`]
    },
    ['risk-matrix']: {
      titles: ['Home', 'Matriz de Risco'],
      hrefs: [routeMaain, `risk-matrix`]
    },
    ['Plano-Acao']: {
      titles: ['Home', 'Plano de Ação'],
      hrefs: [routeMaain, `Plano-Acao`]
    },
    DPO: {
      titles: ['Home', 'DPO', 'Portal do titular'],
      hrefs: [routeMaain, `DPO`, `DPO`]
    },
    ['due-diligence']: {
      titles: ['Home', 'DPO', 'Avaliação de terceiros'],
      hrefs: [routeMaain, routeMaain + `/DPO`, `due-diligence`]
    },
    GestaoCookies: {
      titles: ['Home', 'DPO', 'Cookies'],
      hrefs: [routeMaain, routeMaain + `/DPO`, `GestaoCookies`]
    },
    documentos: {
      titles: ['Home', 'DPO', 'Documentos'],
      hrefs: [routeMaain, routeMaain + `/DPO`, `documentos`]
    },
    ativiadades: {
      titles: ['Home', 'DPO', 'LOGS'],
      hrefs: [routeMaain, routeMaain + `/DPO`, routeMaain + `/registro/ativiadades`]
    },
    ['registro-incidentes']: {
      titles: ['Home', 'DPO', 'Incidentes'],
      hrefs: [routeMaain, routeMaain + `/DPO`, `registro-incidentes`]
    },
    ['impact-reports']: {
      titles: ['Home', 'DPO', 'RIPD'],
      hrefs: [routeMaain, routeMaain + `/DPO`, `impact-reports`]
    },
    ['meetings']: {
      titles: ['Home', 'DPO', 'Reuniões'],
      hrefs: [routeMaain, routeMaain + `/DPO`, `meetings`]
    },
    ['treinamentos']: {
      titles: ['Home', 'DPO', 'Treinamentos'],
      hrefs: [routeMaain, routeMaain + `/DPO`, `treinamentos`]
    },
    Config: {
      titles: ['Usuarios', 'Configuração'],
      hrefs: [`/Usuarios`, `/Usuarios/Config`]
    },
    config: {
      titles: ['Home', 'Configuração'],
      hrefs: [routeMaain, `config`]
    },

  }
}

export default function CustomBreadcrumbs() {


  const company = useSelector(selectCompany);

  const [breadcrumbs, setBreadcrumbs] = useState(null);
  const [routeName, setRouteName] = useState(null);
  const [routes, setRoutes] = useState(getRoutes(company._id || 'undefined'));

  useEffect(() => {
    const arrayPath = location.pathname.split('/');
    const path = arrayPath[arrayPath.length - 1];
    setRouteName(path);
  }, [location.pathname]);


  return (
    <>
      {(!!routeName && !!routes) &&
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{ paddingBottom: "10px" }}
        >
          {routes[routeName]?.titles &&
            routes[routeName]?.titles.map((title, index) => {
              return (
                <Link
                  key={index}
                  color={(routes[routeName]?.titles.length - 1) === index ? "textPrimary" : "inherit"}
                  aria-current={(routes[routeName]?.titles.length - 1) === index && "page"}
                  href={routes[routeName]?.hrefs[index]}
                >
                  {title}
                </Link>
              )
            })
          }
        </Breadcrumbs>}
    </>
  )
}