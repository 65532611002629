import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Container,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';


export default function ConfirmDialog({
  open,
  confirmationMessage,
  onClose,
  onConfirm,
  secondaryText,
}) {
  const { t, } = useTranslation();


  return (
    <Container>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: '600px',
            maxWidth: '90%',
          },
        }}
      >
        <DialogContent>
          <Typography
            variant="body1"
            display="block"
            align="center"
          >
            { confirmationMessage }
          </Typography>

          {
            undefined != secondaryText ?
            <Typography
              variant="body2"
              display="block"
              align="center"
              style={{
                marginTop: '0.25rem',
              }}
            >
              { secondaryText }
            </Typography> :
            null
          }
        </DialogContent>

        <DialogActions>
        <Grid
          container
          justifyContent="center"
        >
            <Button
              onClick={onClose}
              style={{
                margin: '0.5rem',
                borderColor: "#3B66FF",
              }}
              variant="outlined"
            >
              {_.capitalize(t('cancel'))}
            </Button>

            <Button
              onClick={onConfirm}
              variant="contained"
              style={{
                margin: '0.5rem',
              }}
              color="primary"
              disableElevation={true}
            >
              {_.capitalize(t('confirm'))}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
