import { Box, Button, DialogActions, Menu, Typography } from "@material-ui/core";
import React, { useEffect, useState, useCallback, useContext } from "react";
import { MAPPING_FIELD_TYPES } from "../../data/constants";
import MenuItem from "@material-ui/core/MenuItem";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Close } from '@mui/icons-material';
import debounce from 'lodash/debounce';

const filters = [
  MAPPING_FIELD_TYPES.SECTOR.typePre,
];

function FilterForm({ field, onForm }) {

  const [op, setOp] = useState('Equal');
  const [values, setValues] = useState([]);

  const updateForm = useCallback(
    debounce((newValues) => {
      onForm({
        fieldId: field.id,
        field: field.typePre,
        op,
        values: newValues,
        items: field.options.filter((option) => newValues.includes(option.id)),
      });
    }, 200),
    [op, field.id, field.typePre, field.options]
  );

  useEffect(() => {
    updateForm(values);
    return () => updateForm.cancel(); // Clean up debounce on unmount
  }, [values, updateForm]);

  const onChange = (value) => {
    setValues((prevValues) => {
      const newValues = prevValues.includes(value)
        ? prevValues.filter((v) => v !== value)
        : [...prevValues, value];
      return newValues;
    });
  };

  return field.typePre === MAPPING_FIELD_TYPES.SECTOR.typePre ? (
    <>
      {field.options.map((option) => (
        <MenuItem
          selected={values.includes(option.id)}
          key={option.id}
          value={option.id}
          onClick={() => onChange(option.id)}
        >
          {option.title}
        </MenuItem>
      ))}
    </>
  ) : (
    'Sem Filtros disponiveis'
  );
}

export default function DataMappingFilterDialog({ field, onFilter, setTemporaryFilter = () => { } }) {
  const [form, setForm] = useState({ field: field.typePre, op: 'EQUAL', value: [] });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const close = () => setAnchorEl(null);

  const confirm = () => {
    onFilter(form);
    close();
  };

  const onIconClick = (event) => setAnchorEl(event.currentTarget);

  const handlerSetForm = (form) => {
    setForm(form);
    setTemporaryFilter(form);
  };

  function handlerCloseModal() {
    close();
    setTemporaryFilter([]);
  };

  useEffect(() => {
    if (open) {
      setTemporaryFilter([]);
    }
  }, [open]);

  return filters.includes(field.typePre) ? (
    <>
      <FilterListIcon onClick={onIconClick} fontSize="small" style={{ color: 'white', cursor: 'pointer' }} />
      <Menu open={open} anchorEl={anchorEl} onClose={confirm} sx={{ p: 2 }}>
        <Box style={{ width: 500 }} sx={{ padding: 1 }}>
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: "space-between" }}>
            <Typography variant="h5">Filtro do {field.name}</Typography>
            <div onClick={handlerCloseModal} style={{ paddingRight: 10, cursor: 'pointer' }}>
              <Close />
            </div>
          </div>
          <FilterForm onForm={handlerSetForm} field={field} />
          <DialogActions>
            <Button onClick={handlerCloseModal}>Cancelar</Button>
            <Button onClick={confirm} color="primary" variant="contained">Confirmar</Button>
          </DialogActions>
        </Box>
      </Menu>
    </>
  ) : null;
}
