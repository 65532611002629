import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'rsuite';
import 'react-toastify/dist/ReactToastify.css';
import { getHistoricoObservacoes, } from '../../services/api';
import { Typography } from '@material-ui/core';
import { ModalPlanoAcao2 } from './ModalCorpoPlanAcao2';
import './styles.css';
import moment from 'moment';


export default function CorpoPlanoDeAcao({ data, users, reunionUnique, }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [obs, setObs] = useState([]);

  const dateClass = getDateClass();


  function getData() {
    getHistoricoObservacoes(data.id).then(({ data }) => {
      setObs(data);
    });
  }

  function handleOpenModalInstrucao() {
    setIsOpenModal(true);
  }

  function handleCloseModalInstrucao() {
    setIsOpenModal(false);
  }

  useEffect(() => {
    getData();
  }, []);

  function getDateClass() {
    const isOverdue = moment(data.quando).isBefore(moment(), 'day');
    return isOverdue ? 'tdTableMonitoraDateRed' : 'tdTableMonitoraDate';
  }

  return (
    <tbody>
      <ModalPlanoAcao2
        isOpen={isOpenModal}
        onRequestClose={handleCloseModalInstrucao}
        users={users}
        reunionUnique={reunionUnique}
        data={data}
      />
      <tr>
        <td className="tdTableMonitoraName">
          <Typography variant="body1">
            {data.quem}
          </Typography>
        </td>
        <td
          className="tdTableMonitoraOQue"
          colSpan="2"
        >
          <Typography variant="body1">
            {data.problema}
          </Typography>
          <br />
          {
            obs.slice(0, 1).map((obs) => {
              return (
                <div>
                  <small>
                    - {obs.observacao} <br />
                    {moment(obs.data).format(
                      'DD/MM/YYYY HH:MM'
                    )}
                  </small>
                </div>
              );
            })
          }
        </td>
        <td className={dateClass} >
          <Form.Control
            disabled
            type="date"
            value={data.quando}
          />
        </td>
        <td className="tdTableMonitoraBTN">
          <Button
            appearance="ghost"
            block
            onClick={handleOpenModalInstrucao}
          >
            Acessar
          </Button>
        </td>
      </tr>
    </tbody>
  );
}
