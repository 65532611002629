import { useState, useEffect } from 'react'

import { Icon, } from 'rsuite'
import { useDispatch } from 'react-redux'
import { setDocs } from '../../features/docsSlice'
import { searchDocs } from '../../services/api'
import { InputAdornment, TextField } from '@mui/material'
export default function SearshComponent({ onSearch }) {

  const [clickSearch, setClickSearch] = useState(false)

  const handleOnChange = (event) => {
    onSearch(event.target.value)
  }

  const styles = {
    width: 210,
    height: 35,
    borderRadius: 29,
    borderColor: '#95ADC3',
    padding: '3px',
  }

  return (
    <>
      <TextField
        label="Pesquisar"
        id="outlined-size-small"
        variant="outlined"
        size="small"
        // value={search}
        onChange={handleOnChange}
        style={{ background: "#fff" }}
        autoComplete={false}
        autoCapitalize={false}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Icon icon="search" />
            </InputAdornment>
          ),
        }}
      />
      {/* {clickSearch ? (
        <InputGroup
          inside
          style={styles}
          onChange={handleOnChange}
        >
          <Input />
          <InputGroup.Button>
            <Icon icon="search" />
          </InputGroup.Button>
        </InputGroup>
      ) : (
        <Icon
          onClick={() => {
            setClickSearch(true)
          }}
          style={{
            color: '#95ADC3',
            cursor: 'pointer',
            width: 20,
            right: 0,
          }}
          icon="search"
        />
      )} */}
    </>
  )
}
