import { Container, Grid, Paper, Typography } from "@material-ui/core";

import "./StylesClassificacao.css";

export function CardsNumeros({ value, description }) {
  return (
    <Grid className="ContentCards" item xs={12} sm={4}>
      <Container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "160px",
        }}
        component={Paper}
      >
        <Grid item xs={12} sm={4}>
          <strong
            style={{
              fontSize: 50,
              color: "#3B66FF",
              fontFamily: "Inter",
              fontWeight: "700",
            }}
          >
            {value}
          </strong>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography
            variant="h6"
            style={{
              fontFamily: "Inter",
              fontWeight: "600",
              fontSize: "20px",
              color: "#54708C",
            }}
          >
            {description}
          </Typography>
        </Grid>
      </Container>
    </Grid>
  );
}
