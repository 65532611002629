import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt-br';

import { Grid, } from '@material-ui/core';

import BaseWidget from './BaseWidget';
import MetricCard from './components/MetricCard';

import { DashboardWidgetType, } from '../../../core/entities/dashboard-widget';

import { getCompanyContactRequests, } from '../../../services/api';

import { selectCompany, } from '../../../features/companySlice';


function formatValue(value, { min = 0, max, }) {
  if ((undefined !== max) && value > max) {
    return `>${max.toFixed()}`;
  }

  if (value < min) {
    return `<${min.toFixed()}`;
  }
  return value.toFixed();
}


function DpoContactRequestReportWidget(props) {
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);


  async function initialize() {
    setLoading(true);
    try {
      const result = await getCompanyContactRequests(company._id);
      const averageCompletionTime = Math.round(result.data.requests
        .filter((req) => req.status === 'Concluido' && req.closed_at != null)
        .reduce((prev, req, idx, arr) => {
          return prev + (moment(req.closed_at) - moment(req.created_at)) / (24 * 3600 * 1e3 * arr.length);
        }, 0));

      setData([
        {
          value: result.data.requests.length,
          label: t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.DPO_CONTACT_REQUEST_REPORT}.total_requests`),
        },
        {
          value: formatValue(averageCompletionTime, { min: 1, max: 99, }),
          label: t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.DPO_CONTACT_REQUEST_REPORT}.average_completion_time`),
        },
        {
          value: result.data.statusCount.closed,
          label: t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.DPO_CONTACT_REQUEST_REPORT}.finished_requests`),
        },
      ]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    initialize();
  }, []);


  return (
    <BaseWidget
      {...props}
      loading={loading}
    >
      <Grid
        container
        style={{
          padding: '0.5rem',
        }}
      >
        {
          data
            .map(({ value, label, }, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={4}
                  style={{
                    paddingLeft: '0.5rem',
                  }}
                >
                  <MetricCard
                    value={value}
                    label={label}
                    variant="filled"
                  />
                </Grid>
              );
            })
        }
      </Grid>
    </BaseWidget>
  );
}

export default DpoContactRequestReportWidget;
