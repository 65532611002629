import { useEffect, useState } from "react";

import { Grid, Container, Paper } from "@material-ui/core";
import { Table } from "react-bootstrap";
import { Divider } from "rsuite";

import { ModalPlanoAcao2 } from "../../CorpoMon/ModalCorpoPlanAcao2";
import Label from "../../Global/Label";

import { listCompanyMeetings, getUsers, } from "../../../services/api";

import "../styles.css";


export default function TableActions({ body, variant, title, size, hSize }) {
  const [openModalPerguntas, setOpenModalPerguntas] = useState(false);
  const [dataReunions, setDataReunions] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [bodyItem, setBoryItem] = useState([]);

  function handlerCloseModalPergunta() {
    setOpenModalPerguntas(false);
  }

  function handlerOpenModalPergunta(value) {
    setOpenModalPerguntas(true);
    setBoryItem(value);
  }

  async function getData() {
    await listCompanyMeetings().then(({ data }) => {
      setDataReunions(data);
    });

    await getUsers().then(({ data }) => {
      setAllUsers(data);
    });
  }

  useEffect(() => {
    getData();
  }, []);


  return (
    <Grid
      item
      xs={12}
      sm={size}
    >
      <ModalPlanoAcao2
        isOpen={openModalPerguntas}
        onRequestClose={handlerCloseModalPergunta}
        users={allUsers}
        reunionUnique={dataReunions}
        data={bodyItem}
      />
      <Label
        className="TableInfGrafico"
        description={title}
      />
      <Container
        style={{ maxHeight: hSize }}
        className="TableInfGrafico"
        component={Paper}
        disableGutters
      >
        <Table variant={variant}>
          <thead>
            <tr>
              <th>Tarefa</th>
            </tr>
          </thead>
          {body.map((body) => {
            return (
              <tbody key={body.id} >
                <tr>
                  <td
                    onClick={() => handlerOpenModalPergunta(body)}
                    style={{ cursor: "pointer" }}
                  >
                    {body.problema}
                  </td>
                </tr>
              </tbody>
            );
          })}
        </Table>
      </Container>
      <Divider />
    </Grid>
  );
}
