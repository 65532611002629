import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { Icon } from 'rsuite';

import HeatMap from '../../HeatMap';


export default function CollapsibleHeatMap({ diagnosisItems, dataMappingItems, }) {
  const [expanded, setExpanded] = useState(true);


  useEffect(function initialize() {
    const heatMap = localStorage.getItem('heatMap');
    const __expanded = (heatMap === null) || (heatMap === 'true');
    setExpanded(__expanded);
  }, []);

  function onClick() {
    setExpanded(prev => !prev);
    localStorage.setItem('heatMap', !expanded);
  }


  return (
    <div
      style={{
        width: '100%',
        alignSelf: 'center',
        marginBottom: '1rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          height: 40,
          fontFamily: 'Inter',
          alignItems: 'center',
          backgroundColor: 'blue',
          color: 'white',
          padding: '0 1rem',
        }}
        onClick={onClick}
      >
        <span
          style={{
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        >
          Mapa de Calor
        </span>
        <Icon
          icon={!expanded ? 'arrow-down-line' : 'arrow-up-line'}
          style={{
            fontSize: '20px',
          }}
        />
      </div>

      <div
        style={{
          alignSelf: 'center',
          backgroundColor: 'white',
          padding: '0.5rem',
        }}
        hidden={!expanded}
      >
        <HeatMap
          diagnosisItems={diagnosisItems}
          dataMappingItems={dataMappingItems}
        />
      </div>
    </div>
  );
}
