import { createSlice } from "@reduxjs/toolkit";

export const mapeamentoCadastro = createSlice({
  name: "campos",
  initialState: {
    value: [],
  },

  reducers: {
    resetCampos: (state) => {
      state.value = [];
    },
    getCampos: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { getCampos, resetCampos } = mapeamentoCadastro.actions;

export default mapeamentoCadastro.reducer;
