import { useEffect, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import {
  Typography,
  Grid,
  TableCell,
  TableRow,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import {makeStyles,} from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Table from '../../components/Table';
import { Notification, } from 'rsuite';
import clsx from 'clsx';

import CompanySectorForm from '../CompanySectorForm/CompanySectorForm';
import {
  deleteCompanySector,
  selectCompanySectors,
  selectFeatureStatus,
  listCompanySectors,
  selectCompanySectorModalState,
  setCompanySectorsModal,
} from '../../features/companySectorsSlice';

import { CRUD_STATUSES, } from '../../utils/constants';

import useModal from '../../hooks/useModal';
import DeleteDialog from '../DeleteDialog';
import useToggle from "../../hooks/useToggle";
import CompanySectorMappingDialog from "./CompanySectorMappingDialog";


const useStyles = makeStyles({
  title: {
    color: '#1C265A',
    fontWeight: 'bolder',
    fontSize: '1.2rem',
    fontFamily: 'Inter',
    marginBottom: '2rem',
  },
  companySectorForm: {
    paddingRight: '1.0rem',
  },
  action: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  deleteButton: {
    marginLeft: '0.5rem',
    color: 'red',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

const HEADERS = Object.freeze([
  {
    label: 'Nome',
    align: 'center',
  },
  {
    label: '',
    align: 'right',
  },
]);


function CompanySectorsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const companySectors = useSelector(selectCompanySectors);
  const status = useSelector(selectFeatureStatus);
  const companySectorsModal = useSelector(selectCompanySectorModalState);
  const [companySectorId, setCompanySectorId] = useState(undefined);
  const [sectorPendency, setSectorPendency] = useState(null);
  const [deleteConfirmation, openDeleteConfirmation, closeDeleteConfirmation] = useModal(
    (item) => setCompanySectorId(_.cloneDeep(item.id)),
    () => setCompanySectorId(undefined)
  );
  const currCompanySector = companySectors.find((item) => item.id === companySectorId);


  async function onDeleteCompanySector() {
    closeDeleteConfirmation();
    if (undefined == companySectorId) {
      return;
    }

    try {
      const result = await dispatch(deleteCompanySector(companySectorId)).unwrap();
      console.log(result);
      if (companySectorId == result) {
        return Notification['success']({
          placement: 'bottomEnd',
          title: 'Setor deletado com sucesso!',
        });
      } else if (409 == result.status) {
        setSectorPendency(result.data);
        // return Notification['warning']({
        //   placement: 'bottomEnd',
        //   title: 'Falha ao deletar setor!',
        //   description: 'Setor é referenciado no mapeamento.',
        // });
      }
    } catch {
    }

    Notification['error']({
      placement: 'bottomEnd',
      title: 'Falha ao deletar setor!',
      description: 'Tente novamente dentro de alguns instantes.'
    });
  }

  /**
   * Handle click in button to update processing agent
   *
   * @param {object} companySector - processing agent data
   */
  function onEditCompanySector(companySector) {
    setCompanySectorId(companySector.id);
  }

  /**
   * Handle update in processing agent
   */
  function onUpdateFinished() {
    setCompanySectorId(undefined);
  }

  useEffect(() => {
    if (companySectorsModal) {
      dispatch(listCompanySectors());
    }

    if (!companySectorsModal) {
      setCompanySectorId(undefined);
    }
  }, [companySectorsModal]);

  function onClose() {
    dispatch(setCompanySectorsModal(false));
  }


  return (
    <DialogContent>
      <Grid
        container
        justifyContent="space-around"
      >
        <Grid
          item
          md={5}
          xs={12}
          className={classes.companySectorForm}
        >
          <Typography
            variant="h6"
            className={classes.title}
          >
            {_.capitalize(t('PAGES.COMPANY_SECTORS.add_new_sector'))}
          </Typography>
          <CompanySectorForm
            data={currCompanySector}
            onUpdateFinished={onUpdateFinished}
          />
        </Grid>

        <Grid
          item
          md={7}
          xs={12}
        >
          <Typography
            variant="h6"
            className={classes.title}
          >
            Todos os setores
          </Typography>
          <Table
            headers={HEADERS}
            data={companySectors}
            loading={(status == CRUD_STATUSES.IDLE) || (status == CRUD_STATUSES.LOADING)}
            disablePagination
            errorMessage="Falha ao carregar setores"
            noDataMessage="Nenhum setor encontrado"
            maxHeight={300}
          >
            {
              (companySector, props) => {
                return (
                  <TableRow {...props}>
                    <TableCell align="center">
                      {companySector.name}
                    </TableCell>
                    <TableCell
                      align="right"
                      size="small"
                    >
                      <div
                        className={classes.actions}
                      >
                        <EditIcon
                          fontSize="small"
                          className={classes.action}
                          onClick={() => onEditCompanySector(companySector)}
                        />

                        <DeleteIcon
                          fontSize="small"
                          className={clsx(classes.action, classes.deleteButton)}
                          onClick={() => openDeleteConfirmation(companySector)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              }
            }
          </Table>
        </Grid>
      </Grid>

      <CompanySectorMappingDialog open={!!sectorPendency} pendency={sectorPendency} onClose={() => setSectorPendency(null)} />

      <DeleteDialog
        open={deleteConfirmation}
        confirmationMessage={t('PAGES.COMPANY_SECTORS.delete_confirmation', currCompanySector || {})}
        secondaryText={t('PAGES.COMPANY_SECTORS.delete_info')}
        onClose={closeDeleteConfirmation}
        onConfirm={onDeleteCompanySector}
      />
    </DialogContent>
  );
}

export default CompanySectorsPage;
