import React from 'react';
import { Container, AppBar, Toolbar, Typography, } from '@material-ui/core';
import Title from '../Global/PageTitle';


function PublicPageLayout({ title = "", companyName = "", children, }) {
  return (
    <Container
      maxWidth="xl"
      disableGutters
    >
      <AppBar
        position="static"
        style={{
          backgroundColor: '#1C265A',
          marginBottom: '2%',
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            style={{
              marginLeft: '47%',
              fontFamily: 'Roboto',
              fontWeight: 'bold',
            }}
          >
            {
              companyName ?
              `OCTO | ${companyName}` :
              'OCTO'
            }
          </Typography>
        </Toolbar>
      </AppBar>

      <Title description={title} />

      { children }

    </Container>
  );
}

export default React.memo(PublicPageLayout);
