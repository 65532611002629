import { useEffect, useState } from 'react';
import { useSelector, } from 'react-redux';

import { Container, Grid, Paper, Typography, } from '@material-ui/core';
import { makeStyles, } from '@material-ui/styles';
import { Notification } from 'rsuite';

import Card from './Cards';
import Label from '../Global/Label';
import history from '../../history';
import './MonitoraStyles.css';
import { getRisco, listCompanyMeetings, } from '../../services/api';
import { formatCurrencyValue, } from '../../utils';
import { RISK_RESPONSES, ACTION_STATUSES, } from '../../utils/constants';

import { selectCompany } from '../../features/companySlice';


const useStyles = makeStyles({
  costField: {
    cursor: 'pointer',
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 10,
  },
  costValue: {
    width: 50,
    fontSize: '1rem',
    color: '#3B66FF',
    marginRight: 50,
  },
});


export default function Header() {
  const classes = useStyles();
  const company = useSelector(selectCompany);
  const [meetings, setMeetings] = useState([]);

  const [totalPrevisto, setTotalPrevisto] = useState(0);
  const [expectedCosts, setExpectedCosts] = useState(0);
  const [totalInvestido, setTotalInvestido] = useState(0);

  const [compliantItems, setCompliantItems] = useState([]);
  const [inProgressItems, setInProgressItems] = useState([]);
  const [inReviewItems, setInReviewItems] = useState([]);
  const [acceptRiskItems, setAcceptRiskItems] = useState([]);


  /**
   * Initialize component fetching data from the API
   */
  async function initialize() {
    //? The number of items in each category is explained in https://app.clickup.com/t/2kptu49
    try {
      const { data, } = await getRisco(true, 1);

      const { data: meetings, } = await listCompanyMeetings();
      setMeetings(meetings);

      setAcceptRiskItems(data.pdcaAceite);

      setCompliantItems(data.compliant.filter((item) => item.resposta !== RISK_RESPONSES.ACCEPT));

      setInProgressItems(data.risco.filter((item) => item.resposta !== RISK_RESPONSES.ACCEPT &&
        item.status === ACTION_STATUSES.IN_PROGRESS.label));

      setInReviewItems(data.risco.filter((item) => item.resposta !== RISK_RESPONSES.ACCEPT &&
        item.status !== ACTION_STATUSES.IN_PROGRESS.label));

      const count = data.risco.reduce((__count, item) => __count + item.quanto, 0);
      const countInvest = data.compliant.reduce((__count, item) => __count + item.quanto, 0);

      setTotalPrevisto(count + countInvest);
      setTotalInvestido(countInvest);
      setExpectedCosts(count);
    } catch (_) {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao informações de monitoramento, tente novamente dentro de alguns instantes',
      });
    }
  }

  /**
   * Use effect to initialize component data
   */
  useEffect(() => {
    initialize();
  }, []);


  return (
    <Grid
      item
      xs={12}
      className="titleHeaderCards"
    >
      <Label
        description="Seus números"
        fontSize={16}
      />

      <Grid
        container
        spacing={1}
      >
        <Card
          description="Itens adequados"
          value={compliantItems.length}
          type="adequados"
          itens={compliantItems}
        />
        <Card
          description="Itens em andamento"
          value={inProgressItems.length}
          type="andamento"
          itens={inProgressItems}
        />
        <Card
          description="Itens para revisão"
          value={inReviewItems.length}
          type="revisao"
          itens={inReviewItems}
        />

        <Grid
          item
          xs={12}
          sm={4}
        >
          <Container
            component={Paper}
            style={{
              height: '101px',
              cursor: 'pointer',
            }}
            onClick={() => history.push(`/companies/${company._id}/meetings`)}
          >
            <Typography
              variant="body1"
              style={{
                color: '#54708C',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <strong
                style={{
                  fontSize: 48,
                  color: '#3B66FF',
                  padding: 20,
                }}
              >
                {meetings.length}
              </strong>
              Reuniões do comite
            </Typography>
          </Container>
        </Grid>
        <Card
          description="Itens com risco aceitar"
          type="risco-aceitar"
          itens={acceptRiskItems}
          value={acceptRiskItems.length}
        />

        <Grid
          item
          xs={12}
          sm={4}
        >
          <Container
            component={Paper}
            style={{
              height: '101px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="body1"
              className={classes.costField}
            >
              <strong
                className={classes.costValue}
                style={{
                  color: '#31D9A6',
                }}
              >
                { formatCurrencyValue(totalInvestido) }
              </strong>
              Valor investido
            </Typography>

            <Typography
              variant="body1"
              className={classes.costField}
            >
              <strong
                className={classes.costValue}
              >
                { formatCurrencyValue(expectedCosts) }
              </strong>
              Valor previsto
            </Typography>

            <Typography
              variant="body1"
              className={classes.costField}
            >
              <strong
                className={classes.costValue}
              >
                { formatCurrencyValue(totalPrevisto) }
              </strong>
              Valor total estimado
            </Typography>
          </Container>
        </Grid>
      </Grid>
    </Grid>
  );
}
