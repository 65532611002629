import history from "../../../history";

export default function routesMenuFixed(companyId) {

  return Object.freeze({
    // 'CREATE_TASK': {
    //   id: 'CREATE_TASK',
    //   title: 'Nova Tarefa',
    //   onClick: () => history.push(`/`)
    // },
    'CREATE_MEETING': {
      id: 'CREATE_MEETING',
      title: 'Nova Reunião',
      // onClick: () => history.push(`/companies/${companyId}/meetings`)
    },
    // 'CREATE_COURSE': {
    //   id: 'CREATE_COURSE',
    //   title: 'Novo Curso',
    //   onClick: () => history.push('/')
    // },
    'DPO': {
      id: 'DPO',
      title: 'Portal do Titular',
      onClick: () => history.push(`/companies/${companyId}/DPO`)
    },

    'NEW_TASK': {
      id: 'NEW_TASK',
      title: 'Nova tarefa',
      // onClick: () => history.push(`/companies/${companyId}/DPO`)
    },

    'NEW_COURSE': {
      id: 'NEW_COURSE',
      title: 'Novo curso',
      onClick: () => history.push(`/companies/${companyId}/treinamentos`)
    },
  });

}
