import styled from "styled-components";


export const HeaderContainer = styled.header`
  width: 100%;
  height: 4rem;

  margin: 0;
  padding: 0 3rem;

  background: var(--white);

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    max-width: 100%;
  }

  @media (max-width: 414px) {
    width: 100%;
  }
`;

export const HeaderContent = styled.div`
  width: 100%;
  max-width: 90%;
  height: 100%;

  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    max-width: 90%;
  }

  @media (max-width: 425px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 414px) {
    max-width: 90%;
    justify-content: center;
  }

  img {
    max-width: 5rem;
  }
`;

export const LogoContainer = styled.div`
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: left;

  .LogoDecoration {
    cursor: pointer;
  }
`;
