import React from 'react';

import BaseWidget from './BaseWidget';
import RiskOverviewCard from '../../RiskOverviewCard';


function RiskReportWidget(props) {
  return (
    <BaseWidget {...props} >
      <RiskOverviewCard />
    </BaseWidget>
  );
}

export default RiskReportWidget;
