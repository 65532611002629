import React, { useState, useEffect } from "react";
import { Grid, Typography } from '@material-ui/core';
import { modeBanner, InternalBtn, } from "./Common";

export default function ExtendBanner({ data }) {

  const [theme, setTheme] = useState(modeBanner(data));

  useEffect(() => {
    setTheme(modeBanner(data));
  }, [data]);

  return (
    <Grid xs={12}
      md={12}
      lg={12}
      style={{
        borderRadius: 20,
        borderRadius: 20,
        border: "1px solid rgba(204, 210, 233, 0.5)",
        padding: "10px 20px",
        marginBottom: "20px",
        width: "100%",
        background: theme.background
      }}
    >

      <Grid container direction="row" justifyContent="space-between">
        <Typography
          variant="subtitle1"
          component="div"
          color="primary"
          style={{ fontWeight: 500, color: theme.color, marginBottom: "5px" }}
        >
          Suas preferências de cookies
        </Typography>

        <Typography>
          <img src={theme.srcImage} alt="Logo octo" />
        </Typography>
      </Grid>

      <Grid container>
        <Typography
          variant="subtitle2"
          component="div"
          color="primary"
          style={{ fontWeight: 500, color: theme.color, marginBottom: "20px" }}
        >
          Usamos cookies para melhorar sua navegação.
        </Typography>

        <Grid container direction="row" justifyContent="space-between" style={{ marginBottom: "10px" }}>
          <Grid style={{ flexDirection: "row", display: "flex", }}>
            <Typography variant="subtitle2" style={{ color: theme.color, textDecoration: 'underline', marginRight: "10px", alignSelf: "end" }}>
              Aviso de Privacidade
            </Typography >

            <Typography variant="subtitle2" style={{ color: theme.color, textDecoration: 'underline', marginRight: "10px", alignSelf: "end" }}>
              Termos de uso
            </Typography>

            <Typography variant="subtitle2" style={{ color: theme.color, textDecoration: 'underline', alignSelf: "end" }}>
              Falar com DPO
            </Typography>
          </Grid>

          <Grid style={{ flexDirection: "row", display: "flex" }}>
            <InternalBtn theme={theme} text={"Opções"} />
            <InternalBtn theme={theme} text={"Rejeitar"} />
            <InternalBtn theme={theme} text={"Aceitar"} type="filled" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

}
