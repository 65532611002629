import { useEffect, useState } from 'react'
import { useSelector, } from 'react-redux';

import { Grid, CircularProgress } from '@material-ui/core'
import { Button } from 'react-bootstrap'
import { Icon } from 'rsuite'

import Title from '../../components/Global/PageTitle'
import NotificacoesListContainer from '../../components/DPO/NotificacoesList'
import ModalAddNewNotification from './ModalNewNotification'

import styles from './styleIndex.module.scss';

import { getNotification, } from '../../services/api';

import { selectCompany, } from '../../features/companySlice';


export default function IncidentRegistry() {
  const company = useSelector(selectCompany);
  const [openModalAddNotification, setOpenModalAddNotification] = useState(false);
  const [dataNotifications, setDataNotifications] = useState([]);
  const [loadingData, setLoadingData] = useState(true);


  async function getData() {
    await getNotification(company._id)
      .then((response) => {
        setDataNotifications(response.data)
        setLoadingData(false)
      })
      .catch((error) => {
        window.alert(
          `Erro ao buscar notificações, ${error}`
        )
        setLoadingData(false)
      })
  }

  useEffect(() => {
    getData()
  }, [])


  return (
    <>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Title
            className={styles.TitleNotification}
            description="Registro de Incidentes"
          />
          <Button
            onClick={() => {
              setOpenModalAddNotification(true)
            }}
            className={styles.BTNAddNotification}
            variant="outline-primary"
          >
            <Icon icon="plus" /> Adicionar
          </Button>
        </Grid>
        {loadingData && (
          <div className={styles.boxProgress}>
            <CircularProgress />
          </div>
        )}
        {!loadingData && (
          <NotificacoesListContainer
            dados={dataNotifications}
            getData={getData}
          />
        )}
      </Grid>
      <ModalAddNewNotification
        getData={getData}
        openModalNotification={openModalAddNotification}
        onClose={() => {
          setOpenModalAddNotification(false)
        }}
      />
    </>
  )
}
