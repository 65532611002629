import { useState } from 'react'

import { Icon, Input, InputGroup } from 'rsuite';
import { Grid, Container, Paper, Typography, } from '@material-ui/core';

import RowNotificacoes from './RowShowNotificacoes';

import styles from './styleList.module.scss';


export default function NotificacoesListContainer({ dados, getData, }) {
  const [filterFor, setFilterFor] = useState('problema')
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')


  const handleSearch = dados.filter((body) => {
    if (filterFor === 'solicitante') {
      return body.name
        .toLowerCase()
        .includes(search.toLocaleLowerCase())
    } else if (filterFor === 'problema') {
      return body.problema
        .toLowerCase()
        .includes(search.toLocaleLowerCase())
    }
  });

  const style = {
    width: '55%',
    height: 35,
    borderRadius: 29,
    borderColor: '#95ADC3',
    padding: '3px',
    marginRight: '5px',
  };


  return (
    <Grid
      item
      xs={12}
      spacing={3}
    >
      <Grid
        item
        xs={12}
        sm={12}
        style={{
          marginBottom: 13,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">
          <strong className={styles.titleList}></strong>
        </Typography>
        <div className={styles.labelSelect}>
          <InputGroup
            inside
            style={style}
            onChange={(e) => setSearch(e.target.value)}
          >
            <Input />
            <InputGroup.Button loading={loading}>
              <Icon icon="search" />
            </InputGroup.Button>
          </InputGroup>
          <Typography variant="h6">
            <strong>Filtrar por</strong>
          </Typography>
          <select
            className={styles.SelectEmpresa}
            id="OptionReunion"
            value={filterFor}
            onChange={(e) => setFilterFor(e.target.value)}
          >
            <option>problema</option>
            <option>solicitante</option>
          </select>
        </div>
      </Grid>
      <Container
        component={Paper}
        maxWidth="xl"
      >
        <table className={styles.ContainerShowSolic}>
          <thead className={styles.HeaderShowSolic}>
            <th>Solicitante</th>
            <th>Problema</th>
            <th></th>
            <th>Data e Hora</th>
            <th>Detalhes</th>
          </thead>
          <tbody className={styles.bodyShowSolic}>
            {handleSearch.map((data) => {
              return (
                <RowNotificacoes
                  key={data.id}
                  dados={data}
                  getData={getData}
                />
              )
            })}
          </tbody>
        </table>
      </Container>
    </Grid>
  );
}
