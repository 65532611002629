import React, { useEffect, useState, } from 'react';
import { useLocation, } from 'react-router-dom';
import {ButtonBase, Container, Paper, Typography,} from '@material-ui/core';
import queryString from 'query-string';
import { useDispatch, useSelector, } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { getPublicCompany, } from '../../services/api';

import PageError from '../../components/PageError';
import Loading from '../../components/Global/Loading';
import PublicPageLayout from '../../components/PublicPageLayout';
import DataMappingAnswerPanel from '../../components/DataMappingAnswerPanel';
import DataMappingAnswerPanelContext from '../../components/DataMappingAnswerPanel/context';

import {
  listCompanySectors,
  selectCompanySectors,
  selectCompanySectorById,
} from '../../features/companySectorsSlice';
import { setCompany, } from '../../features/companySlice';
import useBaseDataMappingPanel from '../../hooks/useBaseDataMappingPanel';
import SheetContext from "./SheetContext";


function DataMappingAnswerPanelProvider({ sector, }) {
  const { t, } = useTranslation();
  const base = useBaseDataMappingPanel(true, true);
  const [viewMode, setViewMode] = React.useState('table');

  if (base.error?.response.data.error === 'Invalid token') {
    return (
      <>
        <DataMappingAnswerPanelContext.Provider
          value={{
            ...base,
            onCreateAnswer: (pos, posType) => base.onCreateAnswer(pos, posType, sector),
          }}
        >
          <PageError
            title={_.capitalize(t('ERRORS.LINK_EXPIRED_ERROR.title'))}
            description={_.capitalize(t('ERRORS.LINK_EXPIRED_ERROR.description'))}
          />
        </DataMappingAnswerPanelContext.Provider>
      </>
    )
  }

  return (
    <DataMappingAnswerPanelContext.Provider
      value={{...base, onCreateAnswer: (pos, posType) => base.onCreateAnswer(pos, posType, sector),}}
    >
      <SheetContext.Provider value={{ viewMode }}>
        <div>
          <div style={{
            background: "#fff",
            marginTop: 40,
            borderRadius: "10px",
            border: "1px solid rgba(59, 102, 255, 1)",
            boxShadow: '0 4px 2px -2px gray',
            display: 'flex',
            width: '140px',
          }}>
            <ButtonBase onClick={() => setViewMode('table')}>
              <Typography style={{margin: 10, textDecoration: viewMode === 'table' ? 'underline' : 'none'}}>Tabela</Typography>
            </ButtonBase>
            <ButtonBase onClick={() => setViewMode('list')}>
              <Typography style={{margin: 10, textDecoration: viewMode === 'list' ? 'underline' : 'none'}}>Lista</Typography>
            </ButtonBase>
          </div>
        </div>
        <DataMappingAnswerPanel />
      </SheetContext.Provider>
    </DataMappingAnswerPanelContext.Provider>
  );
}


export default function PublicDataMapping() {
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const { search, } = useLocation();
  const [loading, setLoading] = useState(true);
  const [landing, setLanding] = useState(true);
  const companySectors = useSelector(selectCompanySectors);
  const { idEmpresa, setor: sector, empresa, } = queryString.parse(search);
  const companySector = useSelector((state) => selectCompanySectorById(state, sector));


  async function initialize() {
    try {
      const { data: company, } = await getPublicCompany(idEmpresa);
      dispatch(setCompany(company));
      dispatch(listCompanySectors());
      setLanding(false);
    } catch (err) {
      setLanding(true);
    }

    setLoading(false);
  }

  useEffect(() => {
    initialize();
  }, []);

  const showError = landing || (companySectors.length > 0 && companySector == undefined);


  return (
    <PublicPageLayout
      companyName={empresa}
      title={showError ? '' : `Resposta do mapeamento - ${undefined != companySector ? companySector.name : 'Setor'}`}
    >
      {
        loading ?
        <Loading value={loading} /> :
        showError ?
        (
          undefined == companySector ?
          <PageError
            title={_.capitalize(t('ERRORS.SECTOR_NOT_FOUND_ERROR.title'))}
            description={_.capitalize(t('ERRORS.SECTOR_NOT_FOUND_ERROR.description'))}
          /> :
          <PageError
            title={_.capitalize(t('ERRORS.LINK_EXPIRED_ERROR.title'))}
            description={_.capitalize(t('ERRORS.LINK_EXPIRED_ERROR.description'))}
          />
        ) :
        <Container
          component={Paper}
          maxWidth="xl"
          style={{ marginBottom: '2%' }}
        >
          {
            !loading && !landing ?
            <DataMappingAnswerPanelProvider sector={sector} /> :
            null
          }
        </Container>
      }
    </PublicPageLayout>
  );
}
