import React, { useState } from "react";
import { makeStyles, } from '@material-ui/styles';

export default function TimeLineCustom({ itens }) {

    const classes = styles();
    const [mapItensKeys,] = useState(Object.keys(itens));
    function verifyPositionFragment(index) {
        if (index === (mapItensKeys.length - 1)) {
            return 'end'
        } else if (index === 0) {
            return 'start'
        } else {
            return 'center'
        }

    }


    const Top = ({ index, data }) => {
        return (
            <div style={{ flexDirection: "column", display: "flex", flex: 1 }}>
                {data?.topComponent}
            </div>
        );
    }
    const Bootom = ({ index, data }) => {
        return (
            <div style={{ flexDirection: "column", display: "flex", flex: 1 }}>
                {data?.bootomComponent}
            </div>
        );
    }

    const FragmentTimeLine = ({ index, data }) => {

        return (
            <div style={{ flexDirection: "column", display: "flex", flex: 1 }}>
                <div className={classes.container}>
                    <div className={classes.itemTimeLine}>
                        <div className={classes.containerCircleAndBorder} style={{ marginLeft: index === 0 ? "48%" : "0px", marginRight: (index === (mapItensKeys.length - 1)) ? "48%" : "0px" }}>
                            <div className={classes.borderCircle}></div>
                            <div className={classes.containerCircle} style={{ justifyContent: verifyPositionFragment(index) }}>
                                <div className={classes.circle}>
                                    <p>{index + 1}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        )
    }

    return (
        <div>
            <div className={classes.row}>
                {mapItensKeys.map((id, index) => {
                    return (
                        <Top key={index} index={index} data={itens[id]} />
                    )
                })}
            </div>
            <div className={classes.row}>
                {mapItensKeys.map((id, index) => {
                    return (
                        <FragmentTimeLine key={index} index={index} data={itens[id]} />
                    )
                })}
            </div>
            <div className={classes.row}>
                {mapItensKeys.map((id, index) => {
                    return (
                        <Bootom key={index} index={index} data={itens[id]} />
                    )
                })}
            </div>
        </div>
    );
}

const styles = makeStyles(() => ({
    rootContainer: {
        flexDirection: "column",
        display: "flex",
        flex: 1
    },
    container: {
        flexDirection: "row",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
    },
    itemTimeLine: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        flex: 1
    },
    containerCircleAndBorder: {
        position: "relative",
        width: "100%",
        margin: "20px 0px",
    },
    containerCircle: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "center"
    },
    circle: {
        width: "40px",
        height: "40px",
        background: "#3b66ff",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        fontSize: "16px",
        fontWeight: "700",
        position: "relative"
    },
    borderCircle: {
        border: '1px dashed #b2b6c9',
        position: "absolute",
        top: "50%",
        width: "100%"
    },
    topContainer: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        justifyContent: "center"
    },
    bottomContainer: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        justifyContent: "center"
    },
    row: {
        flexDirection: "row",
        display: "flex",
        flex: 1
    }

}));
