import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';
import { useSelector, } from 'react-redux';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { FaPaperclip, } from 'react-icons/fa';
import FileUploaderImg from '../../assets/Vector.svg';

import Dialog from '../Dialog';
import UploadFile from '../UploadFile';
import DocumentViewer from '../DocumentViewer';

import useModal from '../../hooks/useModal';

import { getDownloadEvidenceEndpoint, } from '../../utils/api-endpoints';
import DefaultModal from '../Modals/DefaultModal/DefaultModal';
import FileInput from '../FileInput/FileInput';
import { Button } from '@mui/material';
import { selectCompany } from '../../features/companySlice';
import { Notification, } from 'rsuite';
import { addQuestionEvidence, deleteEvidenceToResponses, deleteSharedEvidenceToResponses, getEvidencesToResponses, getSharedEvidencesToResponses, setPublicMaturityQuestionEvidence } from '../../services/api';


export default function EvidenceButton({
  questionId,
  evidenceId,
  existEvidences,
  onAttach = () => { },
  onDownload = () => { },
}) {
  const { t, } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [uploadModalOpen, openUploadModal, closeUploadModal] = useModal();
  const [viewModalOpen, openViewModal, closeViewModal] = useModal();
  const [showModalOpen, showViewModal, closeShowViewModal] = useModal();
  const [dowloadEvidences, showDowloadEvidences, closeDowloadEvidences] = useModal();
  const disable = React.useMemo(() => undefined == evidenceId, [evidenceId]);
  const { _id: companyId, } = useSelector(selectCompany);
  const [files, setFiles] = useState([]);
  const [evidences, setEvidences] = useState([]);
  const [idShowEvidence, setIdShowEvidence] = useState(null);
  const [internalExistEvidences, setInternalExistEvidences] = useState(null);
  const access_token = localStorage.getItem('token');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function _onAttach(file) {
    closeUploadModal();
    handleClose();
    onAttach(file);
  }

  function _onDownload() {
    handleClose();
    onDownload();
  }

  async function handlerSendFiles() {
    closeUploadModal();
    files.map(async (file) => await onAttachEvidence(file));
  }

  const onAttachEvidence = async (file) => {
    try {
      if (access_token) {
        const response = await addQuestionEvidence(companyId, questionId, file);

        if (response.status === 200) {
          await getEvidencesToResponses(questionId);
          const resendGetEvidences = await getEvidencesToResponses(questionId);
          setInternalExistEvidences(!!resendGetEvidences?.data?.length);
        }
      } else {
        const response = await setPublicMaturityQuestionEvidence(questionId, file);

        if (response.status === 200) {
          await getSharedEvidencesToResponses(questionId);
          const resendGetEvidences = await getSharedEvidencesToResponses(questionId);
          setInternalExistEvidences(!!resendGetEvidences?.data?.length);
        }
      }

      Notification['success']({
        placement: 'bottomEnd',
        title: `${file?.name} enviado com sucesso.`,
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Erro',
      });
    }
  }

  async function fetchGetEvidences() {
    try {
      if (access_token) {
        const response = await getEvidencesToResponses(questionId);
        setEvidences(response.data);
        setInternalExistEvidences(!!response.data?.length);
      } else {
        const response = await getSharedEvidencesToResponses(questionId);
        setEvidences(response.data);
        setInternalExistEvidences(!!response.data?.length);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handlerOpenViewModal() {
    await fetchGetEvidences();
    openViewModal();
  }


  function handlerOpenShowModal(evidenceId) {
    setIdShowEvidence(evidenceId);
    showViewModal();
  }

  async function fetchDeletEvidence(evidenceId) {
    try {
      if (!access_token) {
        const response = await deleteSharedEvidenceToResponses(questionId, evidenceId);
        if (response.status === 200) {
          await getEvidencesToResponses(questionId);
          const resendGetEvidences = await getEvidencesToResponses(questionId);
          setInternalExistEvidences(!!resendGetEvidences?.data?.length);
        }
      } else {
        const response = await deleteEvidenceToResponses(questionId, evidenceId);
        if (response.status === 200) {
          await getEvidencesToResponses(questionId);
          const resendGetEvidences = await getEvidencesToResponses(questionId);
          setInternalExistEvidences(!!resendGetEvidences?.data?.length);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handlerDeletEvidece(id) {
    await fetchDeletEvidence(id);
  }


  async function openDowloadModal() {
    await fetchGetEvidences();
    showDowloadEvidences();
  }

  function handlerDowloadEvidence(id) {
    onDownload(id)
  }

  useEffect(() => {
    fetchGetEvidences();
  }, []);

  return (
    <div>
      <Tooltip title={_.capitalize(t('evidence', { count: 1, }))}>
        <IconButton
          aria-haspopup="true"
          aria-controls="evidence-management-button"
          onClick={handleClick}
        >
          <FaPaperclip />
        </IconButton>
      </Tooltip>

      <Menu
        keepMounted
        id="evidence-management-button"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={openUploadModal}>
          {_.capitalize(t('PAGES.EVIDENCE_MANAGEMENT.attach'))}
        </MenuItem>
        <MenuItem
          onClick={handlerOpenViewModal}
          disabled={!internalExistEvidences}
        >
          {_.capitalize(t('PAGES.EVIDENCE_MANAGEMENT.view'))}
        </MenuItem>
        <MenuItem
          onClick={openDowloadModal}
          disabled={!internalExistEvidences}
        >
          {_.capitalize(t('PAGES.EVIDENCE_MANAGEMENT.download'))}
        </MenuItem>
      </Menu>

      {/* add */}
      <DefaultModal
        title={_.capitalize(t('PAGES.EVIDENCE_MANAGEMENT.attach_evidence'))}
        open={uploadModalOpen}
        onClose={closeUploadModal}
        maxWidth='ls'
      >
        <div style={{ padding: 20 }}>
          <FileInput onDrop={setFiles} />
          <div style={{ flexDirection: 'row-reverse', display: 'flex', paddingTop: 20 }}>
            <Button onClick={handlerSendFiles} variant="contained">Enviar</Button>
          </div>
        </div>
      </DefaultModal>

      {/* {show} */}
      <DefaultModal
        title={'Visualizar evidências'}
        open={viewModalOpen}
        onClose={closeViewModal}
        maxWidth='ls'
      >
        <div style={{ padding: 20 }}>
          <FileInput initFiles={evidences} onClick={handlerOpenShowModal} onDelete={handlerDeletEvidece} />
        </div>
      </DefaultModal>

      {/* dowload */}
      <DefaultModal
        title={'Dowload evidências'}
        open={dowloadEvidences}
        onClose={closeDowloadEvidences}
        maxWidth='ls'
      >
        <div style={{ padding: 20 }}>
          <FileInput initFiles={evidences} onClick={handlerOpenShowModal} onDelete={handlerDowloadEvidence} deletItem={false} icon='' />
        </div>
      </DefaultModal>

      {idShowEvidence &&
        <Dialog
          open={showModalOpen}
          onClose={closeShowViewModal}
          maxWidth="xl"
          PaperProps={{
            style: {
              width: '800px',
              maxWidth: '90%',
            },
          }}
        >
          <DocumentViewer
            fileLink={getDownloadEvidenceEndpoint(idShowEvidence)}
            fileType="application/pdf"
          />
        </Dialog>
      }
    </div>
  );
}
