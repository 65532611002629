const loading = false;

export default function useDownloadProcessingAgentsCVSTemplate() {


  function downloadProcessingAgentsCSVTemplate() {
    const text = 'Nome;CNPJ;Nome do DPO;Email;Telefone';

    let link = document.createElement('a');
    link.style.display = 'none';
    link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(text);
    link.setAttribute(
      'download',
      `template.csv`,
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  return {
    downloadProcessingAgentsCSVTemplate,
  }
}
