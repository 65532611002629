import { useState } from 'react';
import {
  Container,
  Paper,
  Grid,
  Typography,
  Divider,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import clsx from 'clsx';
import { useDispatch, } from 'react-redux';

import { PROCESSING_AGENTS_TYPES, PROCESSING_AGENTS_TYPES_ARRAY, } from '../../utils/constants';
import ProcessingAgentsTablePerType from '../../components/ProcessingAgentsTablePerType/ProcessingAgentsTablePerType';
import { setProcessingAgentsModal, } from '../../features/processingAgentsSlice';


const useStyles = makeStyles({
  text: {
    color: '#3B66FF',
  },
  button: {
    textTransform: 'none',
  },
  buttonContainer: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  buttonIcon: {
    color: '#3B66FF',
  },
});


function ProcessingAgentTypeItem({ currType, onClick, src, title, type }) {
  return (
    <div
      onClick={() => onClick(type)}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '1rem 0',
        borderRadius: '0.25rem',
        padding: '15px',
        cursor: 'pointer',
      }}
    >
      <img
        style={{
          width: '7rem',
          borderRadius: '100%',
          border: (type === currType) ? '3px solid #3B66FF' : null,
          marginBottom: '1rem',
        }}
        src={src}
      />
      <Typography
        variant="h6"
        align="center"
        style={{
          color: (type === currType) ? '#3B66FF' : null,
        }}
      >
        { title }
      </Typography>
      <Typography
        variant="body2"
        align="center"
      >
        Ver lista completa
      </Typography>
    </div>
  );
}


/**
 * Impact report processing agents component
 *
 * @returns Rendered page
 */
export default function ImpactReportProcessingAgents() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState(PROCESSING_AGENTS_TYPES.MANAGER.value);


  /**
   * Handle user click in one agent type
   */
  function onSelectAgentType(updatedType) {
    setSelectedType(updatedType);
  }

  /**
   * Open processing agents management modal
   */
  function openProcessingAgentsModal() {
    dispatch(setProcessingAgentsModal(true));
  }


  return (
    <Container
    maxWidth="xl"
    component={Paper}
    style={{
      padding: '1rem',
      marginTop: '1rem',
      marginBottom: '1rem',
    }}
    disableGutters
  >
    <Grid
      item
      xs={12}
    >
      <Typography
        variant="h6"
        display="block"
        className={classes.text}
      >
        Identificação dos terceiros
      </Typography>
    </Grid>
    <Grid
      container
      direction="row"
      justifyContent="space-between"
    >
      {/* <Grid
        item
        xs={12}
        md={5}
        container
        direction="row"
        justifyContent="space-between"
      >
        <Grid
          item
          xs={12}
        >
        <Typography
          variant="h6"
          display="block"
          className={classes.text}
        >
          Identificação dos terceiros
        </Typography>
        </Grid>

        {
          PROCESSING_AGENTS_TYPES_ARRAY.map((type) => {
            return (
              <Grid
              item
              xs={4}
              key={type.value}
            >
              <ProcessingAgentTypeItem
                currType={selectedType}
                onClick={onSelectAgentType}
                src={type.imageSrc}
                title={type.label}
                type={type.value}
              />
            </Grid>
            );
          })
        }
      </Grid>

      <Divider
        orientation="vertical"
        flexItem
      /> */}

      <Grid
        item
        container
        xs={12}
        direction="column"
        justifyContent="space-between"
      >
        <ProcessingAgentsTablePerType type={selectedType} />

        <div className={classes.buttonContainer} >
          <Button
            className={clsx(classes.button, classes.text)}
            startIcon={<PersonAddIcon className={classes.buttonIcon} />}
            onClick={openProcessingAgentsModal}
          >
            Terceiros
          </Button>
        </div>
      </Grid>
    </Grid>

  </Container>
  );
}
