import { useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Icon, Notification, Loader, } from 'rsuite';

import FileUploaderImg from '../../../../assets/Vector.svg';
import Docs from '../../../FilesComponent';

import { getDocsWorkshop, uploadWorkshop, } from '../../../../services/api';

import {
  MainWorkshopInputApresenta,
  InputTitleWorckshopApresenta,
  InputUploadWorkshopApres,
} from './styles';

import { setDocs } from '../../../../features/docsSlice';
import { selectCompany, } from '../../../../features/companySlice';

import { errorCodes, } from '../../../../errors/standard-error';

import validateFile from '../../../utils/validate-file';


export function ContainerApresentacaoWorkshop({ id, }) {
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const [loading, setLoading] = useState(false);
  const [fileChecked, setFileChecked] = useState(false);
  const fileData = useSelector((state) => state.docs.value[0]);


  function updateDocs() {
    getDocsWorkshop().then(({ data }) => {
      dispatch(setDocs(data));
    });
  }

  async function handleSendDoc(file) {
    setFileChecked(true);
    setLoading(true);

    try {
      await uploadWorkshop(company._id, id, file);

      Notification['success']({
        placement: 'bottomEnd',
        title: t('PAGES.DOCUMENT_MANAGEMENT.document_successfully_sent'),
      });

      updateDocs();
    } catch (err) {
      if ((undefined != err.response) && (undefined != err.response.data) &&
        (errorCodes.INVALID_FILE_ERROR == err.response.data.code)) {
        Notification['error']({
          placement: 'bottomEnd',
          title: t('ERRORS.ERROR_CODES.INVALID_FILE_ERROR'),
          description: t('PAGES.DOCUMENT_MANAGEMENT.send_valid_document'),
      });
      } else {
        Notification['error']({
          placement: 'bottomEnd',
          title: t('ERRORS.DOCUMENT_MANAGEMENT.failed_to_send_document'),
          description: t('ERRORS.try_again_later'),
        });
      }
    }

    setLoading(false);
  }

  function onChangeDocument(event) {
    const file = event.target.files[0];

    if (validateFile(file)) {
      handleSendDoc(file);
    }
  }


  return (
    <MainWorkshopInputApresenta>
      <InputTitleWorckshopApresenta>
        <h1>Apresentação</h1>
        <p>
          Faça o upload de um arquivo de apresentação
          explicando a importância da LGPD, quais os
          direitos dos titulares e os deveres da
          Organização.
        </p>
      </InputTitleWorckshopApresenta>

      {loading && <Loader content="Enviando..." />}

      { (undefined != fileData) ?
        <div>
          <Docs
            data={fileData}
            workshop={false}
          />
        </div> :
        <InputUploadWorkshopApres>
          <div>
            <label for="contained-button-file-workshop">
              {!fileChecked ? (
                <img
                  src={FileUploaderImg}
                  alt="Imagem de Documento"
                />
              ) : (
                <Icon icon="check" size="lg" />
              )}
              Click aqui e selecione um arquivo
              <span>
                Suportamos arquivos .PDF, .PPT, PPTX, DOC,
                DOCX, XLS, XLSX menores que 10Mb.
              </span>
            </label>
            <input
              id="contained-button-file-workshop"
              type="file"
              onChange={onChangeDocument}
              onClick={(ev) => {
                ev.target.value = null;
              }}
            />
          </div>
        </InputUploadWorkshopApres>
      }
    </MainWorkshopInputApresenta>
  );
}
