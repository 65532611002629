import { useState, } from "react";
import { useSelector, } from 'react-redux';
import { v4 as uuidv4 } from "uuid";
import { useParams, } from 'react-router-dom';

import { Dialog } from "@material-ui/core";
import { Row, Col, Form } from "react-bootstrap";
import { Icon, IconButton, Notification, } from "rsuite";

import api, {
  insertReunionParticipant,
  removeMeetingParticipant,
} from "../../../services/api";

import styles from "./styles.module.scss";

import { selectCompany, } from "../../../features/companySlice";


export default function EditParticipantsModal({
  openModal,
  closeModal,
  participants,
  users,
  getData,
}) {
  const { _id: empresa, group, } = useSelector(selectCompany);
  const { meetingId, } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(uuidv4().split('').map(char => {
    return Math.random() > 0.5 ? char.toUpperCase() : char;
  }).join(''));
  const filteredArray = users.filter((item) => {
    return (
      participants.findIndex(
        (participant) => participant.name === item.name
      ) === -1
    );
  });


  async function handleCreateUsers(event) {
    event.preventDefault();

    await api
      .post("/singup/reunion", {
        name: name,
        email: email,
        password: password,
        admin: false,
        empresa,
        group,
        autorizado: true,
        ativo: false,
      })
      .then(() => {
        addParticipante([name]);
        getData();

        Notification["success"]({
          title: "Success",
          placement: "bottomEnd",
          description: "Usuario criado",
        });
      })
      .catch(() => {
        Notification["error"]({
          title: "Erro!",
          placement: "bottomEnd",
          description: "Erro ao criar Usuario",
        });
      });
  }

  const deleteParticipants = async (value) => {
    await removeMeetingParticipant(empresa, meetingId, value)
      .then(() => {
        Notification["success"]({
          placement: "bottomEnd",
          title: "Participante removido",
        });
        getData();
      })
      .catch(() => {
        Notification["error"]({
          placement: "bottomEnd",
          title: "Erro ao remopver participante",
        });
      });
  };

  async function addParticipante(value) {
    await insertReunionParticipant(empresa, meetingId, value)
      .then(() => {
        getData();
        Notification["success"]({
          placement: "bottomEnd",
          title: "Participante adicionado com sucesso ",
        });
      })
      .catch(() => {
        alert("Erro ao inserir Participante");
      });
  }


  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
    >
      <div className={styles.containerModal}>
        <div className={styles.boxTitle}>
          <h5>Editar lista de participantes</h5>
          <Icon icon="close" onClick={closeModal} />
        </div>
        <div className={styles.boxBodyModal}>
          <div className={styles.listMembers}>
            <h5>Todos os usuarios</h5>
            <div className={styles.boxMembers}>
              {filteredArray.map((user) => {
                if (user.empresa === empresa)
                  return (
                    <div className={styles.LineMembers} key={user._id}>
                      <span>{user.name}</span>
                      <Icon
                        icon="plus"
                        onClick={() => {
                          addParticipante([user.name]);
                        }}
                      />
                    </div>
                  );
              })}
            </div>
            <h5>Lista atual</h5>
            <div className={styles.boxMembers}>
              {participants.map((user, index) => {
                return (
                  <div className={styles.LineMembers} key={index}>
                    <span>{user.name}</span>
                    <Icon
                      icon="trash"
                      onClick={() => deleteParticipants(user.id)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className={styles.newMenber}>
          <Form>
            <Row>
              <Col xs={5}>
                <Form.Group>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    placeholder="Nome"
                  />
                </Form.Group>
              </Col>
              <Col xs={5}>
                <Form.Group>
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    type="email"
                    placeholder="Enter email"
                  />
                </Form.Group>
              </Col>
              <Col xs={2}>
                <IconButton
                  color="blue"
                  circle
                  style={{ marginTop: "20px" }}
                  icon={<Icon icon="plus" />}
                  onClick={(e) => {
                    handleCreateUsers(e);
                  }}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Dialog>
  );
}
