import React from 'react';

import { AppBar, Tabs, Tab, } from '@material-ui/core';
import { IconButton, Icon, InputGroup, Input, } from 'rsuite';


const NEW_TAB_VALUE = -1;


export default function TabBar({
  tab,
  onChangePhase,
  phases,
  onDeletePhase,
  onUpdatePhase,
  onNewPhase,
}) {
  const [selectedPhase, setSelectedPhase] = React.useState({});


  function _onUpdatePhase() {
    onUpdatePhase(selectedPhase);
    setSelectedPhase({});
  }

  function onEditModeClick(phase) {
    setSelectedPhase(phase);
  }

  function onChangeName(ev) {
    setSelectedPhase({
      ...selectedPhase,
      name: ev.target.value,
    });
  }

  function a11yProps(id) {
    return {
      id: `scrollable-auto-tab-${id}`,
      'aria-controls': `scrollable-auto-tabpanel-${id}`,
    };
  }

  function _onChangePhase(value) {
    if (value != NEW_TAB_VALUE) {
      onChangePhase(value);
    }
  }


  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
    >
      <Tabs
        value={tab}
        onChange={(_, value) => _onChangePhase(value)}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {
          phases.map((phase) => {
            return (
              <Tab
                key={phase.id}
                value={phase.id}
                label={
                  <div>
                    {
                      selectedPhase.id !== phase.id ?
                      <IconButton
                        block
                        appearance="link"
                        placement="right"
                        icon={
                          <Icon
                            icon="edit"
                            onClick={() => onEditModeClick(phase)}
                          />
                        }
                      >
                        { phase.name }
                      </IconButton> :
                      <InputGroup
                        inside
                        style={{
                          marginBottom: 10,
                          height: '2.5rem',
                        }}
                        value={selectedPhase.name}
                        onChange={onChangeName}
                      >
                        <InputGroup.Button>
                          <Icon
                            icon="trash"
                            onClick={() => onDeletePhase(phase.id)}
                          />
                        </InputGroup.Button>
                        <Input value={selectedPhase.name} />
                        <InputGroup.Button>
                          <Icon
                            icon="save"
                            onClick={_onUpdatePhase}
                          />
                        </InputGroup.Button>
                      </InputGroup>
                    }
                  </div>
                }
                {...a11yProps(phase.id)}
              />
            );
          })
        }

        <Tab
          label={
            <IconButton
              block
              appearance="link"
              placement="right"
              icon={<Icon icon="plus" />}
            >
              Nova aba
            </IconButton>
          }
          value={NEW_TAB_VALUE}
          onClick={onNewPhase}
        />
      </Tabs>
    </AppBar>
  );
}
