import { useState, } from 'react';
import PropTypes from 'prop-types';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import { useSelector, } from 'react-redux';

import { Notification, } from 'rsuite';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Button from '../../components/Button';

import { createCompanyPopup, } from '../../services/api';

import { selectCompany, } from '../../features/companySlice';


const INITIAL_VALUES = Object.freeze({
  host: '',
});


const useStyles = makeStyles({
  actions: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    color: 'white',
    backgroundColor: '#3B66FF',
  },
});


/**
 * Dialog used to register a new site
 *
 * @param {boolean} open - whether the dialog is open or not
 * @param {function} onClose - callback called when dialog is closed
 * @param {function} onCreated - callback called when new config is created
 *
 * @returns Rendered dialog
 */
function RegisterNewSiteDialog({ open, onClose, onCreated, }) {
  const classes = useStyles();
  const company = useSelector(selectCompany);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: handleRegister,
    validationSchema: Yup.object({
      host: Yup.string().required('Obrigatório'),
    }),
  });

  /**
   * Handle register
   * @param {object} values - new site config info
   */
  async function handleRegister(values) {
    setLoading(true);

    try {
      const { data, status, } = await createCompanyPopup(company._id, values);
      if (200 === status) {
        Notification['success']({
          placement: 'bottomEnd',
          title: 'Novo site registrado com sucesso!',
        });
      }
      onCreated(data);
      onClose();
      formik.resetForm();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao registrar novo site, tente novamente dentro de alguns instantes.',
      });
    }

    setLoading(false);
  }

  /**
   * Submit event handler
   *
   * @param {object} event - event that triggered the handler
   */
  function onSubmit(event) {
    event.preventDefault();
    formik.handleSubmit();
  }

  /**
   * Handle cancel
   */
  function onCancel() {
    onClose();
    formik.resetForm();
  }


  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="md"
    >
      <DialogTitle className={classes.title}>
        Registrar site
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={onSubmit}
        >
          <Grid
            item
            xs={12}
            container
            direction="row"
          >
            <TextField
              margin="dense"
              variant="filled"
              label="Host"
              fullWidth
              id="host"
              type="text"
              error={formik.errors.host && formik.touched.host}
              helperText={formik.touched.host && formik.errors.host}
              {...formik.getFieldProps('host')}
            />
          </Grid>

          <Grid
            className={classes.actions}
          >
            <Button
              variant="contained"
              loading={loading}
              onClick={onCancel}
            >
              Cancelar
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              loading={loading}
            >
              Cadastrar
            </Button>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}


RegisterNewSiteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreated: PropTypes.func.isRequired,
};

export default RegisterNewSiteDialog;
