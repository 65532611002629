import React, { useState, } from 'react';
import _ from 'lodash';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { FieldArray, useFormikContext, } from 'formik';


export default function AnswerQuestionOptionsForm({ questionIdx, }) {
  const { values, setFieldValue, } = useFormikContext();
  let value = null;


  function handleChange(ev) {
    const selectedAltId = ev.target.value;
    const alternatives = _.cloneDeep(values.questions[questionIdx].alternatives);
    for (const alt of alternatives) {
      alt.isCorrect = (alt.id == selectedAltId);
    }
    setFieldValue(`questions[${questionIdx}].alternatives`, alternatives);
  }

  if (values.questions[questionIdx].alternatives &&
    values.questions[questionIdx].alternatives.length > 0) {
      const selectedAlt = values.questions[questionIdx].alternatives.find((alt) => alt.isCorrect == true);
      if (selectedAlt != undefined) {
        value = selectedAlt.id;
      }
  }


  return (
    <FieldArray name={`questions[${questionIdx}].alternatives`}>
      {
        () => {
          return (
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Alternativas
              </FormLabel>

              <RadioGroup
                aria-label={`questions[${questionIdx}].alternatives`}
                name="alternatives"
                value={value}
                onChange={handleChange}
              >
                {
                  values.questions[questionIdx].alternatives &&
                  values.questions[questionIdx].alternatives.length > 0 &&
                  values.questions[questionIdx].alternatives.map((alternative, idx) => {
                    return (
                      <FormControlLabel
                        value={alternative.id}
                        control={<Radio />}
                        label={alternative.statement}
                      />
                    );
                  })
                }
              </RadioGroup>
            </FormControl>
          );
        }
      }
    </FieldArray>
  );
}
