import _ from 'lodash';
import { useEffect, } from 'react';
import fileDownload from 'js-file-download';
import { useParams, } from 'react-router-dom';
import { useTranslation, } from 'react-i18next';

import { Notification, } from 'rsuite';
import { Grid, Typography, } from '@material-ui/core';

import PublicPageLayout from '../../components/PublicPageLayout';

import { downloadEvidence, } from '../../services/api';

import pageImage from '../../assets/workoshop_new_ilustra.svg';


export default function DownloadEvidencePage() {
  const { t, } = useTranslation();
  const { evidenceId, } = useParams();


  async function download() {
    try {
      const response = await downloadEvidence(evidenceId);

      const headerLine = response.headers['content-disposition'];
      const startIdx = headerLine.indexOf('"') + 1;
      const endIdx = headerLine.lastIndexOf('"');
      const filename = headerLine.substring(startIdx, endIdx);

      fileDownload(response.data, filename);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.EVIDENCE_MANAGEMENT.failed_to_download_evidence')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  useEffect(() => {
    download()
  }, []);


  return (
    <PublicPageLayout>
      <Grid
        container
        alignItems="center"
        direction="column"
        style={{
          marginTop: '5rem',
        }}
      >
        <img
          src={pageImage}
          alt="Executivo de terno ilustração"
          style={{
            maxWidth: '90%',
            width: 500,
            marginBottom: '1rem',
          }}
        />

        <Typography
          display="block"
          align="center"
          variant="h3"
          style={{
            color: '#3B66FF',
          }}
        >
          {_.capitalize(t('PAGES.DOWNLOAD_EVIDENCE.title'))}
        </Typography>
        <Typography
          display="block"
          align="center"
          variant="h6"
        >
          {_.capitalize(t('PAGES.DOWNLOAD_EVIDENCE.subtitle'))}
        </Typography>
      </Grid>
    </PublicPageLayout>
  );
}
