import { useState, } from 'react';


/**
 * Hook to control a modal
 *
 * @param {function} onOpen - callback function called when the modal is opened
 * @param {function} onClose - callback function called when the modal is closed
 */
export default function useModal(onOpen, onClose) {
  const [open, setOpen] = useState(false);


  function closeModal(...args) {
    setOpen(false);
    if (typeof onClose === 'function') {
      onClose(...args);
    }
  }

  function openModal(...args) {
    setOpen(true);
    if (typeof onOpen === 'function') {
      onOpen(...args);
    }
  }


  return [open, openModal, closeModal];
}
