import { useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import { Notification } from 'rsuite';

import Button from '../Button';

import { createImpactReportReview, } from '../../services/api';

import { getImpactReport } from '../../features/impactReportsSlice';
import { selectCompany } from '../../features/companySlice';


const INITIAL_VALUES = Object.freeze({
  description: '',
});


const useStyles = makeStyles({
  actions: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    color: 'white',
    backgroundColor: '#3B66FF',
  },
});

/**
 * Dialog used to register a impact report
 *
 * @param {boolean} open - whether the dialog is open or not
 * @param {function} onClose - callback called when dialog is closed
 *
 * @returns Rendered dialog
 */
function RegisterImpactReportReviewDialog({ open, onClose, reportId, }) {
  const classes = useStyles();
  const company = useSelector(selectCompany);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: handleRegister,
    validationSchema: Yup.object({
      description: Yup.string().required('Obrigatório').max(300, 'Descrição deve possuir no máximo 300 caracteres'),
    }),
  });


  /**
   * Handle register
   *
   * @param {object} values - new impact report info
   */
  async function handleRegister(values) {
    setLoading(true);

    try {
      await createImpactReportReview(company._id, reportId, values);

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Revisão criada com sucesso',
      });
      dispatch(getImpactReport(reportId));
      __onClose();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao criar revisão!',
        description: 'Tente novamente dentro de alguns instantes.'
      });
    }

    setLoading(false);
  }

  /**
   * Submit event handler
   *
   * @param {object} event - event that triggered the handler
   */
  function onSubmit(event) {
    event.preventDefault();
    formik.handleSubmit();
  }

  /**
   * Handle cancel
   */
  function __onClose() {
    formik.resetForm();
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={__onClose}
      maxWidth="md"
    >
      <DialogTitle className={classes.title}>
        Criar revisão de relatório de impacto
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={onSubmit}
        >
          <Grid
            item
            xs={12}
            container
            direction="row"
          >
            <TextField
              margin="dense"
              variant="filled"
              label="Descrição"
              multiline
              InputProps={{
                disableUnderline: true,
              }}
              fullWidth
              id="description"
              type="text"
              error={formik.errors.description && formik.touched.description}
              helperText={formik.touched.description && formik.errors.description}
              {...formik.getFieldProps('description')}
            />
          </Grid>

          <Grid
            className={classes.actions}
          >
            <Button
              variant="contained"
              loading={loading}
              onClick={__onClose}
            >
              Cancelar
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              loading={loading}
            >
              Cadastrar
            </Button>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

RegisterImpactReportReviewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reportId: PropTypes.number.isRequired,
};

export default RegisterImpactReportReviewDialog;
