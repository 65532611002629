import React from 'react';
import { useSelector, } from 'react-redux';

import BaseWidget from './BaseWidget';
import HeatMap from '../../HeatMap';

import { selectCompany, } from '../../../features/companySlice';
import { getRisco, getDataMappingRiskAnalysis, } from '../../../services/api';


function HeatMapWidget(props) {
  const company = useSelector(selectCompany);
  const [actions, setActions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [dataMappingItems, setDataMappingItems] = React.useState([]);


  async function initialize() {
    setLoading(true);
    try {
      getDataMappingRiskAnalysis(company._id).then(({ data, }) => setDataMappingItems(data.items)).catch(console.error);

      getRisco(true, 1)
        .then(({ data }) => {
          const filtered = data.risco.filter((item) => {
            return ('Aceitar' !== item.resposta) && ('Concluido' !== item.status);
          });
          setActions(filtered);
        });
    } catch (err) {
      console.log(err);
      setDataMappingItems([]);
      setActions([]);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    initialize();
  }, []);


  return (
    <BaseWidget
      {...props}
      loading={loading}
    >
      <HeatMap
        diagnosisItems={actions}
        dataMappingItems={dataMappingItems}
      />
    </BaseWidget>
  );
}

export default HeatMapWidget;
