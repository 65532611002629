import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import _ from 'lodash';

import * as api from '../services/api';
import { CRUD_STATUSES, } from '../utils/constants';


const initialState = {
  users: [],
  usersMap: {},
  status: CRUD_STATUSES.IDLE,
  renewing: false,
};


export const listUsers = createAsyncThunk('users/fetch', async () => {
  const { data, status, } = await api.getUsers();
  if (200 === status) {
    return data;
  }
});


export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setStatus: (state, { payload: status, }) => {
      if (status != null) {
        state.status = status;
      } else {
        state.status = initialState.status;
      }
    },
    logout() {
      //! Handled by root reducer
    },
    setRenewing(state, { payload: renewing, }) {
      state.renewing = renewing;
    },
    setUsers(state, { payload: users }) {
      state.users = users;
      state.usersMap = Object.fromEntries(users.map((item) => [item._id, item]));
    }
  },
  extraReducers: (builder) => {
    builder.addCase(listUsers.pending, (state) => {
      state.status = CRUD_STATUSES.LOADING;
    }).addCase(listUsers.fulfilled, (state, { payload: users, }) => {
      state.status = CRUD_STATUSES.FETCH_SUCCESSFUL;
      state.users = users;
      state.usersMap = Object.fromEntries(users.map((item) => [item._id, item]));
    }).addCase(listUsers.rejected, (state) => {
      state.status = CRUD_STATUSES.FETCH_FAILED;
      state.users = initialState.users;
    });
  }
});

export const selectFeatureStatus = (state) => {
  return state.users.status;
}

export const selectUsers = (state) => {
  return state.users.users;
}

export const selectUsersMap = (state) => {
  return state.users.usersMap;
}

export const selectUserById = (state, id) => {
  return state.users.usersMap[id];
}


export const { setStatus, logout, setRenewing, setUsers, } = usersSlice.actions;
export default usersSlice.reducer;
