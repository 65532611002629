import React, { useState, } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Container from '@material-ui/core/Container';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import Table from '../../components/Table';
import SearchField from '../../components/SearchField';
import { checkSearch } from '../../utils';
import { makeStyles, } from '@material-ui/styles';


const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  allSelected: {
    paddingLeft: 16,
  },
});


/**
 * Table to show and select processing agents
 *
 * @param {number[]} selected - array of selected processing agents
 * @param {(selectedProcessingAgents: number[]) => void} setSelected - function to request parent component to set selected
 * processing agents
 */
function ProcessingAgentsTable({
  processingAgents,
  selected,
  setSelected = () => {},
  onCreateNewProcessingAgent = () => {},
  loading = false,
}) {
  const classes = useStyles();
  const { t, } = useTranslation();
  const [allSelected, setAllSelected] = useState(false);
  const [searchString, setSearchString] = useState('');

  const filteredProcessingAgents = React.useMemo(() => {
    if (searchString.length > 0) {
      return processingAgents
        .filter((processingAgent) => checkSearch(processingAgent.name, searchString));
    } else {
      return processingAgents;
    }
  }, [processingAgents, searchString]);

  const HEADERS = Object.freeze([
    {
      label: _.capitalize(t('PAGES.DUE_DILIGENCE.operator')),
      align: 'left',
    },
    {
      label: _.capitalize(t('email')),
      align: 'left',
    },
  ]);


  /**
   * Toggle processing agent selection
   *
   * @param {number} processingAgentId - processing agent ID
   */
  function toggleProcessingAgent(processingAgentId) {
    if (selected.includes(processingAgentId)) {
      setSelected(selected.filter((id) => id != processingAgentId));
    } else {
      setSelected([...selected, processingAgentId]);
    }
  }

  function toggleAllProcessingAgentsSelected() {
    const updatedAllSelected = !allSelected;
    setSelected(updatedAllSelected ? processingAgents.map(({ id, }) => id) : []);
    setAllSelected(updatedAllSelected);
  }

  return (
    <Container
      maxWidth="xl"
      component={Paper}
      elevation={0}
      disableGutters
    >
      <div className={classes.container} >
        <FormControlLabel
          control={
            <Checkbox
              checked={allSelected}
              onChange={toggleAllProcessingAgentsSelected}
              name="select-all-processing-agents"
              color="primary"
            />
          }
          label="Selecionar todos"
          className={classes.allSelected}
        />
        <div className={classes.container} >
          <SearchField onChange={(searchString) => setSearchString(searchString || '')} />
          <IconButton onClick={onCreateNewProcessingAgent} >
            <AddCircleIcon
              color="primary"
              fontSize="large"
            />
          </IconButton>
        </div>
      </div>
      <Table
        headers={HEADERS}
        data={filteredProcessingAgents}
        loading={loading}
        errorMessage="Falha ao carregar terceiros"
        noDataMessage="Nenhum terceiro encontrado"
        disablePagination
        maxHeight={350}
      >
        {
          (processingAgent, props) => {
            return (
              <TableRow {...props} >
                <TableCell align="left" >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Checkbox
                      checked={selected.includes(processingAgent.id)}
                      onChange={() => toggleProcessingAgent(processingAgent.id)}
                      name={`select-${processingAgent.name}`}
                      color="primary"
                      style={{
                        paddingLeft: 0,
                      }}
                    />
                    {processingAgent.name}
                  </div>
                </TableCell>
                <TableCell align="left">
                  {processingAgent.email}
                </TableCell>
              </TableRow>
            )
          }
        }
      </Table>
    </Container>
  );
}

export default ProcessingAgentsTable;
