function routesNavBar(companyId) {
  const SUB_MENU_DPO = Object.freeze({
    DPO: {
      id: 'DPO',
      go: `/companies/${companyId}/DPO`,
      description: "Portal do titular",
      icon: 'user'
    },
    DueDiligence: {
      id: 'DueDiligence',
      go: `/companies/${companyId}/due-diligence`,
      description: "Avaliação de terceiros",
      icon: 'order-form'
    },
    // PDCA: {
    //   id: 'PDCA',
    //   go: `/companies/${companyId}/pdca`,
    //   description: "PDCA",
    // },
    Cookies: {
      id: 'Cookies',
      go: `/companies/${companyId}/GestaoCookies`,
      description: "Cookies",
      icon: 'wrench'
    },
    Documentos: {
      id: 'Documentos',
      go: `/companies/${companyId}/documentos`,
      description: "Documentos",
      icon: 'file-text'
    },
    Ativiadades: {
      id: 'Ativiadades',
      go: `/companies/${companyId}/registro/ativiadades`,
      description: "LOGS",
      icon: 'logo-analytics'
    },
    Incidentes: {
      id: 'Incidentes',
      go: `/companies/${companyId}/registro-incidentes`,
      description: "Incidentes",
      icon: 'window-restore'
    },
    // RiskReport: {
    //   id: 'RiskReport',
    //   go: `/companies/${companyId}/dpo/risk-report`,
    //   description: "RIPD",
    // },
    ImpactReports: {
      id: 'ImpactReports',
      go: `/companies/${companyId}/dpo/impact-reports`,
      description: "RIPD",
      icon: 'related-map'
    },
    // ImpactReports: {
    //   id: 'ImpactReports',
    //   go: `/companies/${companyId}/dpo/impact-reports`,
    //   description: "Relatório de impacto",
    // },
    Meetings: {
      id: 'Meetings',
      go: `/companies/${companyId}/meetings`,
      description: "Reuniões",
      icon: 'table'
    },
    Treinamentos: {
      id: 'Treinamentos',
      go: `/companies/${companyId}/treinamentos`,
      description: "Treinamentos",
      icon: 'child'
    },
  });


  const ROUTES_NAVBAR = Object.freeze({
    Diagnostico: {
      id: 'Diagnostico',
      go: `/companies/${companyId}/Respostas`,
      icon: "line-chart",
      description: "Diagnóstico"
    },
    Mapeamento: {
      id: 'Mapeamento',
      go: `/companies/${companyId}/Mapeamento`,
      icon: "sitemap",
      description: "Mapeamento",
    },
    Matrizderiscos: {
      id: 'Matrizderiscos',
      go: `/companies/${companyId}/risk-matrix`,
      icon: "exclamation-triangle",
      description: "Matriz de riscos"
    },
    Planodeacao: {
      id: 'Planodeacao',
      go: `/companies/${companyId}/Plano-Acao`,
      icon: "map-signs",
      description: "Plano de Ação"
    },
    DPO: {
      id: 'DPO',
      icon: "user",
      description: "DPO",
      subMenu: SUB_MENU_DPO
    },
  });

  return ROUTES_NAVBAR;
}

export default routesNavBar;

