import { useState, useEffect} from 'react';

import { Icon } from 'rsuite';

import { ModalPlanoAcao2, } from '../../components/CorpoMon/ModalCorpoPlanAcao2';

import styles from './styles.module.scss';

import { getHistoricoObservacoes, } from '../../services/api';


export default function ItensRetomada({ acao, users, reunion, }) {
  const [problemaSlice, setProblemaSlice] = useState("")
  const [observacoes, setObservacoes] = useState([])
  const [openModalEditItens, setOpenModalEditItens] = useState(false)
  const [classStatus, setClassStatus] = useState()
  const sliceProblem = acao.problema;
  const tamanhoTela = window.screen.width;
  const status = acao.status;


  function HandlerCloseModalEditItens() {
    setOpenModalEditItens(false);
  }

  function ColorStatus() {
    if (status === "Concluido") {
      setClassStatus(styles.statusConcluido)
    } else if (status === "Em andamento") {
      setClassStatus(styles.statusAndamento)
    } else if (status === "Pendente") {
      setClassStatus(styles.statusPendente)
    }
  }

  function SliceProblem() {
    if (tamanhoTela >= 1920) {
      setProblemaSlice(sliceProblem.slice(0, 130))
    } else if ( tamanhoTela >= 1808) {
      setProblemaSlice(sliceProblem.slice(0, 115))
    } else if ( tamanhoTela >= 1662) {
      setProblemaSlice(sliceProblem.slice(0, 105))
    } else if ( tamanhoTela >= 1545) {
      setProblemaSlice(sliceProblem.slice(0, 90))
    } else if ( tamanhoTela >= 1200) {
      setProblemaSlice(sliceProblem.slice(0, 74))
    } else if ( tamanhoTela >= 1014) {
      setProblemaSlice(sliceProblem.slice(0, 35))
    }
  }

  useEffect(() => {
    SliceProblem();
    ColorStatus();
  }, []);

  const getObs = () => {
    getHistoricoObservacoes(acao.id).then(({ data }) => {
      setObservacoes(data)
    })
  }

  useEffect(() => {
    getObs();
  }, []);


  return (
    <div className={styles.ContainerAllRetomada}>
      <div className={styles.headerRetomada}>
        <span className={styles.lineTitleRetomada}>{acao.ordem}<p>{problemaSlice}</p></span>
        <div className={styles.lineTitleRight}>
          <span>{acao.quem} alterou status para <p className={classStatus}>{acao.status}</p></span>
          {acao.setor ? <div>{acao.setor}</div> : ""}
        </div>
      </div>

      <div className={styles.bodyBoxRetomada}>
        <span>
          {acao.observacao}
        </span>
        <div
          onClick={() => setOpenModalEditItens(true)}
          className={styles.seeMore}
        >
          Ver mais detalhes
          <Icon icon="arrow-right" />
        </div>
      </div>

      <ModalPlanoAcao2
        isOpen={openModalEditItens}
        onRequestClose={HandlerCloseModalEditItens}
        users={users}
        reunionUnique={reunion}
        observacao={observacoes}
        data={acao}
      />
    </div>
  );
}
