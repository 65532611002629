import React from "react";
import Icon from "../../assets/pin.svg"

export default function Pin() {
    return (
        <img
            className="BoxIconSvg"
            src={Icon}
            width="16px"
            height="16px"
        />
    )
}