import React from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import SpeedDial from '@material-ui/lab/SpeedDial';
import { makeStyles } from '@material-ui/styles';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'

import BookIcon from '@material-ui/icons/Book';
import ViewListIcon from '@material-ui/icons/ViewList';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';


const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));


export default function SpeedDials({
  newQuestion,
  order,
  deleteAll,
  saveAsTemplate,
  onImportChecklist,
  onExportChecklist,
}) {
  const { t, } = useTranslation();
  const actions = [
    {
      icon: <AddCircleIcon onClick={newQuestion} />,
      name: 'Nova tarefa',
    },
    {
      icon: <ViewListIcon onClick={order} />,
      name: 'Reordenar tarefas',
    },
    {
      icon: <BookIcon onClick={saveAsTemplate} />,
      name: 'Salvar como template',
    },
    {
      icon: <CloudDownloadIcon onClick={onExportChecklist} />,
      name: _.capitalize(t('PAGES.MATURITY_CHECKLIST.export_checklist')),
    },
    {
      icon: <CloudUploadIcon onClick={onImportChecklist} />,
      name: _.capitalize(t('PAGES.MATURITY_CHECKLIST.import_checklist')),
    },
    {
      icon: <DeleteForeverIcon onClick={deleteAll} />,
      name: 'Deletar todas as tarefas',
    },
  ];

  const classes = useStyles()
  const [open, setOpen] = React.useState(false)


  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }


  return (
    <SpeedDial
      ariaLabel="SpeedDial example"
      className={classes.speedDial}
      hidden={false}
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onClick={handleOpen}
      open={open}
      direction="up"
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={handleClose}
        />
      ))}
    </SpeedDial>
  );
}
