import { useState, useEffect } from "react";
import _ from 'lodash';
import { useSelector, useDispatch, } from 'react-redux';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import { useTranslation, } from 'react-i18next';

import {
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import { Button, Icon, Notification, } from "rsuite";

import { createCompany, } from "../../services/api";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import Label from "../../components/Global/Label";
import './styles.css';
import {
  selectQuestionsTemplates,
  listQuestionsTemplates,
} from '../../features/questionsTemplatesSlice';
import { getUserId, } from "../../utils";
import { selectTemplates } from "../../features/dataMappingTemplatesSlice";

import logo from '../../assets/logo-blue.svg';




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


/**
 * Modal used to create new company for consultant
 *
 * @param {function} onClose - callback function called when modal is closed
 * @param {function} onCreated - callback function called when the company is successfully created
 *
 * @returns rendered component
 */
export default function CreateCompany({ onClose, onCreated, }) {
  const { t, } = useTranslation();
  const dispatch = useDispatch();
  const templates = useSelector(selectQuestionsTemplates);
  const dataMappingTemplates = useSelector(selectTemplates);
  const [loadingButton, setLoadingButton] = useState(false);

  const VALID_QUESTION_TEMPLATES = templates.map((template) => template.id);
  const VALID_DATA_MAPPING_TEMPLATES = dataMappingTemplates.map((template) => template.id);

  const INITIAL_VALUES = Object.freeze({
    name: '',
    template: 1,
    dataMappingTemplate: VALID_DATA_MAPPING_TEMPLATES[0],
  });

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: onSubmit,
    validationSchema: Yup.object({
      name: Yup.string()
        .required(_.capitalize(t('VALIDATION_MESSAGES.required', { context: 'male', }))),
      template: Yup.number()
        .required(_.capitalize(t('VALIDATION_MESSAGES.required', { context: 'male', })))
        .oneOf(VALID_QUESTION_TEMPLATES,
          _.capitalize(t('VALIDATION_MESSAGES.must_be_a_valid_template'))),
      dataMappingTemplate: Yup.number()
        .oneOf(VALID_DATA_MAPPING_TEMPLATES,
          _.capitalize(t('VALIDATION_MESSAGES.must_be_a_valid_template'))),
    }),
  });


  /**
   * Use effect to initialize component data
   */
  useEffect(() => {
    dispatch(listQuestionsTemplates({
      userId: getUserId(),
    }));
  }, []);

  /**
   * Send request to API to create a new company
   *
   */
  async function onSubmit(values) {
    setLoadingButton(true);

    try {
      await createCompany({
        name: values.name,
        owner: localStorage.getItem('name'),
        group: localStorage.getItem('group'),
        template: Number(values.template),
        dataMappingTemplate: Number(values.dataMappingTemplate),
      });

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Empresa criada com sucesso!',
      });

      formik.resetForm({
        values: INITIAL_VALUES,
      });

      setLoadingButton(false);
      onCreated();
    } catch {
      setLoadingButton(false);
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao criar empresa!',
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }


  return (

    !!VALID_QUESTION_TEMPLATES.length &&
    <Container>
      <br />
      <div className="IconClose">
        <Icon
          onClick={onClose}
          className="IconCloseStyle"
          icon="close"
        />
      </div>

      <Label
        description="Digite o nome da empresa no campo abaixo."
        fontSize={20}
      />

      <TextField
        margin="dense"
        label={_.capitalize(t('name'))}
        fullWidth
        id="name"
        type="text"
        error={formik.errors.name && formik.touched.name}
        helperText={formik.touched.name && formik.errors.name}
        {...formik.getFieldProps('name')}
      />

      <FormControl
        fullWidth
        margin="dense"
        error={formik.errors.template && formik.touched.template}
      >
        <InputLabel id="impact-report-template-label" >
          {_.capitalize(t('checklist_template', { count: 1, }))}
        </InputLabel>
        <Select
          labelId="impact-report-template-label"
          id="impact-report-template-select"
          {...formik.getFieldProps('template')}
          MenuProps={MenuProps}
        >
          {
            templates.map((template) => (
              <MenuItem
                key={template.id}
                value={template.id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {template.name}
                {
                  template.isGlobal ?
                    <img
                      src={logo}
                      height="12px"
                      style={{
                        marginLeft: '0.45rem',
                      }}
                    /> :
                    null
                }
              </MenuItem>
            ))
          }
        </Select>
        <FormHelperText>
          {formik.touched.template && formik.errors.template}
        </FormHelperText>
      </FormControl>

      <FormControl
        fullWidth
        margin="dense"
        error={formik.errors.dataMappingTemplate && formik.touched.dataMappingTemplate}
      >
        <InputLabel id="data-mapping-template-label" >
          {_.capitalize(t('data_mapping_template', { count: 1, }))}
        </InputLabel>
        <Select
          labelId="data-mapping-template-label"
          id="data-mapping-template-select"
          {...formik.getFieldProps('dataMappingTemplate')}
          MenuProps={MenuProps}
        >
          {
            dataMappingTemplates.map((template) => (
              <MenuItem
                key={template.id}
                value={template.id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {template.name}
                {
                  template.isGlobal ?
                    <img
                      src={logo}
                      height="12px"
                      style={{
                        marginLeft: '0.45rem',
                      }}
                    /> :
                    null
                }
              </MenuItem>
            ))
          }
        </Select>
        <FormHelperText>
          {formik.touched.dataMappingTemplate && formik.errors.dataMappingTemplate}
        </FormHelperText>
      </FormControl>

      <Button
        loading={loadingButton}
        appearance="primary"
        block
        onClick={formik.handleSubmit}
      >
        Criar Nova Empresa
      </Button>
      <br />
    </Container>
  );
}
