import {useEffect, useState} from "react";
import useAuth from "../../../context/hooks/useAuth";
import {
  createMaturityQuestionTemplate, deleteMaturityQuestionTemplate,
  getMaturityQuestionTemplates,
  updateMaturityQuestionTemplate
} from "../../../services/api";
import useAsyncState from "../../useAsyncState";


export function useQuestionTemplates() {

  useAuth();
  const {
    value,
    error,
    loading,
    request,
    setValue
  } = useAsyncState(getMaturityQuestionTemplates, []);

  useEffect(() => {
    request();
  }, []);


  return {
    value,
    error,
    loading,
    add: (template) => setValue([...value, template]),
    remove: (template) => setValue(value.filter(t => t.id !== template.id)),
    update: (template) => setValue(value.map(t => t.id === template.id ? template : t)),

    addQuestion: (template, question) => {
      const updated = value.map(t => t.id === template.id ? {
        ...t,
        questions: [...t.questions, question]
      } : t);
      setValue(updated);
    },
  }
}



export function useCreateQuestionTemplate() {
  const { error, loading, request } = useAsyncState(createMaturityQuestionTemplate);

  const [name, setName] = useState('');

  return {
    form: {
      name: {
        value: name,
        onChange: (e) => setName(e.target.value)
      }
    },
    reset: () => {
      setName('')
    },
    send: () => request({name})
  }
}


export function useUpdateQuestionTemplate() {
  const { error, loading, request } = useAsyncState(updateMaturityQuestionTemplate);

  const [id, setId] = useState(0);
  const [name, setName] = useState('');

  return {
    form: {
      name: {
        value: name,
        onChange: (e) => setName(e.target.value)
      }
    },
    reset: (template) => {
      console.log(template);
      setId(template.id);
      setName(template.name);
    },
    send: () => request({id, name})
  }
}

export function useDeleteQuestionTemplate() {
  const { error, loading, request } = useAsyncState(deleteMaturityQuestionTemplate);

  return {
    request,
  }
}
