import { useEffect, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';

import { FormControl, InputGroup, Form, Row, Col, } from 'react-bootstrap';
import { Container, } from '@material-ui/core';
import { Divider, Notification, Button, IconButton, Icon, } from 'rsuite';

import {
  saveCompanyAction,
  updateCompanyActionWho,
  getHistoricoObservacoes,
  updateCompanyActionStatus,
  createQuestionObservation,
  deleteQuestionObservation,
} from '../../services/api';

import QuestionObservationList from '../QuestionObservationList';
import { ModalCreateUser } from '../../pages/CriarUsuarios/ModalCreatUser';

import { ACTION_STATUSES as STATUSES } from '../../utils/constants';

import { selectCompany } from '../../features/companySlice';
import { setSave, updateAction, } from '../../features/matrizDeRisco';
import { selectCollaborators } from '../../features/collaboratorsSlice';


const STATUS_OPTIONS = Object.freeze(Object.values(STATUSES));
const CHOOSE_RESPONSIBLE = 'Escolha o responsável';


/**
 * Component used to manage plan related to the action
 *
 * @param {object} data - action data
 * @param {array} users - users in the system

 * @param {function} getUser - callback used to update the list of users
 *
 * @returns rendered container
 */
export default function ActionPlanContainer({ data, users, getUser, }) {
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const { _id: idEmpresa, } = useSelector(selectCompany);
  const collaborators = useSelector(selectCollaborators);
  const [open, setOpen] = useState(false);
  const [onde, setOnde] = useState(data.onde);
  const [quando, setQuando] = useState(data.quando);
  const [quem, setQuem] = useState(data.quem);
  const [como, setComo] = useState(data.como);
  const [quanto, setQuanto] = useState(data.quanto || 0);
  const [obs, setObs] = useState('');
  const [status, setStatus] = useState(data.status);
  const [statusBG, setStatusBG] = useState('');
  const [observations, setObservations] = useState([]);


  /**
   * Show success notification
   */
  function openSuccess() {
    Notification['success']({
      top: 70,
      placement: 'topEnd',
      title: 'Item salvo com sucesso',
      description: `O plano de ação para o risco foi salvo com sucesso`,
    })
  }

  /**
   * Show error notification
   */
  function openError() {
    Notification['error']({
      top: 70,
      placement: 'topEnd',
      title: 'Item não foi salvo',
      description: `O plano de ação para o risco não foi salvo`,
    })
  }

  /**
   * Handle change in action status
   */
  const changeStatus = () => {
    const item = STATUS_OPTIONS.find((item) => item.label === status);
    const color = item != null ? item.color : '';
    return setStatusBG(color);
  }

  /**
   * Send request to API to add a new observation
   */
  const insertObs = async () => {
    try {
      await createQuestionObservation(idEmpresa, data.id, obs);
      Notification['success']({
        top: 70,
        placement: 'topEnd',
        title: 'Observação registrada com sucesso',
      });
      await getObservations();
    } catch {
      Notification['error']({
        top: 70,
        placement: 'topEnd',
        title: 'Falha ao registrar observação',
      });
    }
  }

  /**
   * Handle click in save button
   */
  async function handleSend() {
    const updatedData = {
      id: data.id,
      onde: onde,
      quando: quando,
      quem: quem,
      como: como,
      quanto: quanto,
      status: status,
      obs: obs,
      usuario: quem,
    };

    try {
      await saveCompanyAction(idEmpresa, data.id, updatedData);
      const updatedAction = _.extend(_.cloneDeep(data), updatedData);
      dispatch(updateAction(updatedAction));
      openSuccess();
    } catch (_) {
      openError();
    }
  }

  /**
   * Update action status in the API
   *
   * @param {string} updatedStatus - updated action status
   */
  const updateStatus = async (updatedStatus) => {
    updateCompanyActionStatus(idEmpresa, data.id, updatedStatus);
    setStatus(updatedStatus);
  }

  /**
   * Update responsible for the action
   *
   * @param {string} updatedWho - responsible for the action
   */
  const updateWho = async (updatedWho) => {
    const responsible = (updatedWho === CHOOSE_RESPONSIBLE) ? null : updatedWho;
    setQuem(responsible);
    try {
      await updateCompanyActionWho(idEmpresa, data.id, responsible);
      if (status !== STATUSES.FINISHED.label) {
        const updatedStatus = (responsible !== null) ? STATUSES.IN_PROGRESS.label : STATUSES.PENDING.label;
        await updateStatus(updatedStatus);
      }

      return openSuccess();
    } catch (_) {
      return openError();
    }
  }

  /**
   * Handle change in action status
   *
   * @param {string} updatedStatus - updated status
   */
  const handleStatusChange = async (updatedStatus) => {
    try {
      await updateStatus(updatedStatus);
      return openSuccess();
    } catch (_) {
      return openError();
    }
  }

  async function deleteObservation(obsId) {
    try {
      await deleteQuestionObservation(idEmpresa, data.id, obsId);

      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.QUESTION_ITEM_CARD.observation_successfully_deleted')),
      });

      setObservations(observations.filter(({ id, }) => id != obsId));
    } catch (err) {
      if ((undefined != err.response) && (403 == err.response.status)) {
        Notification['error']({
          placement: 'bottomEnd',
          title: _.capitalize(t('ERRORS.not_allowed')),
        });
      } else {
        Notification['error']({
          placement: 'bottomEnd',
          title: _.capitalize(t('ERRORS.QUESTION_ITEM_CARD.failed_to_delete_observation')),
          description: _.capitalize(t('ERRORS.try_again_later')),
        });
      }
    }
  }

  async function getObservations() {
    try {
      const response = await getHistoricoObservacoes(data.id);
      setObservations(response.data);
    } catch {
      // Drop error
    }
  }

  async function initialize() {
    await Promise.all([
      getObservations(),
    ]);
  }

  useEffect(() => {
    initialize();
  }, []);

  /**
   * Use effect to handle changes to the action status
   */
  useEffect(() => {
    changeStatus()
  }, [status]);


  return (
    <Container
      maxWidth="xl"
      disableGutters
    >
      <label
        style={{
          color: '#3B66FF',
          fontSize: '25px',
        }}
      >
        Ações
      </label>

      <Row>
        <br />
        <Col
          xs={12}
          md={4}
        >
          <Form.Label>Alterar quem</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              as="select"
              defaultValue={ CHOOSE_RESPONSIBLE }
              value={quem}
              onChange={(e) => updateWho(e.target.value)}
            >
              <option>
                { CHOOSE_RESPONSIBLE }
              </option>
              {
                users.filter((user) => user.empresa === idEmpresa).map((user) => {
                  return (
                    <option key={user._id}>
                      { user.name }
                    </option>
                  );
                })
              }
            </Form.Control>
            <Divider vertical />

            <IconButton
              active
              style={{
                left: -15,
              }}
              size="lg"
              icon={<Icon icon="plus" alt="Sinal de +" />}
              onClick={() => setOpen(!open)}
              circle
            />
          </InputGroup>
        </Col>

        <Col
          xs={12}
          md={2}
        >
          <Form.Label>Onde</Form.Label>
          <Form.Control
            value={onde}
            onClick={() => dispatch(setSave(true))}
            onChange={(e) => setOnde(e.target.value)}
          />
        </Col>

        <Col
          xs={12}
          md={2}
        >
          <Form.Label>Quando?</Form.Label>
          <Form.Control
            value={quando}
            onClick={() => dispatch(setSave(true))}
            type="date"
            onChange={(event) => setQuando(event.target.value)}
          />
        </Col>

        <Col
          xs={12}
          md={2}
        >
          <Form.Label>Quanto?</Form.Label>
          <Form.Label
            htmlFor="inlineFormInputGroup"
            srOnly
          ></Form.Label>
          <InputGroup className="mb-2">
            <InputGroup.Text>R$</InputGroup.Text>

            <FormControl
              id="inlineFormInputGroup"
              onClick={() => dispatch(setSave(true))}
              value={quanto}
              onChange={(event) => setQuanto(Number(event.target.value))}
              type="number"
            />
          </InputGroup>
        </Col>

        <Col
          xs={12}
          md={2}
        >
          <Form.Label>Status</Form.Label>

          <Form.Control
            onChange={(e) => handleStatusChange(e.target.value)}
            value={status}
            as="select"
            style={{
              color: 'black',
              backgroundColor: statusBG,
            }}
          >
            {
              STATUS_OPTIONS.map((item, index) => {
                return (
                  <option
                    key={index}
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                    }}
                  >
                    { item.label }
                  </option>
                );
              })
            }
          </Form.Control>
        </Col>

        <Col xs={12}>
          <ModalCreateUser
            open={open}
            setOpen={setOpen}
            getUsers={getUser}
          />
        </Col>

        <Col
          xs={12}
          md={6}
        >
          <Form.Label>Como?</Form.Label>
          <Form.Control
            onClick={() => dispatch(setSave(true))}
            value={como}
            onChange={(event) => setComo(event.target.value)}
          />

          <br />

          <Form.Label>Observações</Form.Label>
          <Form.Control
            as="textarea"
            onClick={() => dispatch(setSave(true))}
            rows="3"
            placeholder="Escreva aqui sua observação"
            onChange={(e) => setObs(e.target.value)}
          />
          <div
            style={{
              display: 'flex',
              marginTop: 10,
              justifyContent: 'flex-end'
            }}
          >
            <Button
              onClick={() => {
                dispatch(setSave(true))
                insertObs()
              }}
              appearance="ghost"
            >
              Salvar
            </Button>
          </div>
        </Col>

        <Col
          xs={12}
          md={6}
        >
          <div
            style={{
              height: '224px',
            }}
          >
            <Container
              style={{
                float: 'right',
                height: '250px',
              }}
            >
              <strong
                style={{
                  color: '#3B66FF',
                  fontSize: '20px',
                  marginLeft: '5%',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                Observações:
              </strong>

              <QuestionObservationList
                users={users}
                observations={observations}
                collaborators={collaborators}
                onDelete={deleteObservation}
              />
            </Container>
          </div>
        </Col>
      </Row>

      <Row
        style={{
          marginBottom: 10,
          marginTop: 10,
        }}
      >
        <Col xs={10} />
        <Col xs={2}>
          <Button
            color="blue"
            onClick={() => {
              dispatch(setSave(false))
              handleSend()
            }}
            block
          >
            Salvar
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
