import React from 'react';

import DefaultBanner from './DefaultBanner';
import ExtendBanner from './ExtendBanner';

import { BannerType, } from '../../entities/banner-site';

const BANNER_COMPONENT_MAP = Object.freeze({
  [BannerType.BASIC]: DefaultBanner,
  [BannerType.EXTENDED]: ExtendBanner
});


export default function BannerSite({ data, }) {
  
  const Banner = BANNER_COMPONENT_MAP[data.type];

  return (
    <Banner data={data}/>
  );
}
