import React from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import { Grid, } from '@material-ui/core';

import DataMappingTemplatesDialog from '../DataMappingTemplatesDialog';

import useModal from '../../hooks/useModal';


export default function GlobalDataMappingTemplates() {
  const { t, } = useTranslation();
  const [open, openModal, closeModal] = useModal();


  return (
    <Grid
      item
      xs={12}
    >
      <div className="boxHeaderPoliticas">
        <h3>
          { _.capitalize(t('data_mapping_template', { count: 2, })) }
        </h3>

        <button onClick={openModal}>
          { _.capitalize(t('view')) }
        </button>
      </div>

      <DataMappingTemplatesDialog
        isGlobal
        open={open}
        closeModal={closeModal}
      />
    </Grid>
  );
}
