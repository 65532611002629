import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import Stepper from '../../components/StepperMain';
import PageSelector from '../../components/PageSelector/PageSelector';
import MainDashboard from './MainDashboard';
import Sheet from '../../components/Sheet';

import './styles.css';


// const PAGE_OPTIONS = Object.freeze({
//   PATH: {
//     id: 'PATH',
//     label: 'Caminho',
//     title: (
//       <Typography variant="h5">
//         Ciclo de adequação da{' '}
//         <strong
//           style={{
//             color: '#3B66FF',
//           }}
//         >
//           LGPD
//         </strong>
//       </Typography>
//     ),
//   },
//   DASHBOARD: {
//     id: 'DASHBOARD',
//     label: 'Dashboard',
//     title: (
//       <Typography variant="h5">
//         Veja como está o{' '}
//         <strong
//           style={{
//             color: '#3B66FF',
//           }}
//         >
//           progresso
//         </strong>
//       </Typography>
//     ),
//   },
// });
// const PAGE_OPTIONS_ARRAY = Object.values(PAGE_OPTIONS);


const PAGES = Object.freeze({
  caminho: {
    id: 'caminho',
    titleTab: "Caminho",
    titlePage: "Caminho",
    component: <Stepper />,
  },
  dashboard: {
    id: 'dashboard',
    titleTab: "Dashboard",
    titlePage: "Dashboard",
    component: <MainDashboard />,
  }
});

const ARRAY_PAGES = Object.values(PAGES);


function CompanyHomePage() {

  // const [optionId, setOptionId] = React.useState(PAGE_OPTIONS.DASHBOARD.id);
  // const option = PAGE_OPTIONS[optionId];

  return (
    <Sheet pages={ARRAY_PAGES} />
  );
}

export default CompanyHomePage;
