import ResourceClassificationItem from './ResourceClassificationItem';
import styles from './styleBodyNew.module.scss';

/**
 * Show classification data for company sectors
 *
 * @returns Rendered component
 */
export default function ResourceClassification({ title, classification, }) {
  return (
    <div className={styles.ColumnClassificação}>
      <div className={styles.HeaderColumnNotTitleRight} >
        <span>{title}</span>
      </div>
      <div className={styles.bodyColumnClassificacao}>
        {
          classification.map(({ id, amount, name, }) => (
            <ResourceClassificationItem
              key={id}
              name={name}
              amount={amount}
            />
          ))
        }
      </div>
    </div>
  );
}
