import _ from 'lodash';
import { useState, useEffect, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useSelector, } from 'react-redux';
import fileDownload from 'js-file-download';
import moment from 'moment';

import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { Notification } from 'rsuite';
import GetAppIcon from '@material-ui/icons/GetApp';

import CollaboratorsTable from '../DPO/CollaboratorsTable';
import DocumentSignaturesTable from '../DocumentSignaturesTable';

import {
  selectCollaboratorsMap,
} from '../../features/collaboratorsSlice';
import { selectCompany, } from '../../features/companySlice';

import {
  sendDocumentSignatureRequests,
  listDocumentSignatureRequests,
  resendDocumentSignatureRequest,
  downloadDocumentSignatureRequestsReport,
} from '../../services/api';


export default function ShareLinkModal({ open, documentId, onClose, }) {
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const [signatures, setSignatures] = useState([]);
  const collaboratorsMap = useSelector(selectCollaboratorsMap);
  const [selectedCollaborators, setSelectedCollaborators] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState(false);


  async function onSendToCollaborators() {
    if (selectedCollaborators.length === 0) {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DOCUMENT_SIGNATURE_REQUEST.select_at_least_one_collaborator')),
      });
      return;
    }

    try {
      await sendDocumentSignatureRequests(company._id, documentId, selectedCollaborators);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DOCUMENT_SIGNATURE_REQUEST.requests_successfully_sent_to_collaborators')),
      });
      setSelectedCollaborators([]);
      getSignatureRequests();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DOCUMENT_SIGNATURE_REQUEST.failed_to_send_to_collaborators')),
      });
    }
  }

  async function onDownloadReport() {
    setDownloading(true);

    try {
      Notification['info']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.downloading_report')),
        description: _.capitalize(t('PAGES.downloading_report_description')),
      });

      const { data, } = await downloadDocumentSignatureRequestsReport(company._id, documentId);
      const reportName = `relatório-de-requisições-de-assinaturas-de-documento-${moment().format('YYYYMMDD')}.pdf`;
      fileDownload(data, reportName);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.failed_to_download_report')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }

    setDownloading(false);
  }

  async function onResendToCollaborator(requestId) {
    try {
      await resendDocumentSignatureRequest(company._id, documentId, requestId);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DOCUMENT_SIGNATURE_REQUEST.request_successfully_resent_to_collaborator')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DOCUMENT_SIGNATURE_REQUEST.failed_to_resend_document_to_collaborator')),
      });
    }
  }

  async function getSignatureRequests() {
    setLoading(true);
    setError(false);

    try {
      const { data, } = await listDocumentSignatureRequests(company._id, documentId);
      setSignatures(data.requests);
    } catch {
      setError(true);
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DOCUMENT_SIGNATURE_REQUEST.failed_to_load_signature_requests')),
      });
    }

    setLoading(false);
  }

  useEffect(() => {
    if (undefined != documentId) {
      getSignatureRequests();
    }
  }, [documentId]);


  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: 900,
          maxWidth: '100%',
        },
      }}
    >
      <DialogContent>

        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="h6"
            style={{
              margin: '0.5rem 0px',
            }}
          >
            {_.capitalize(t('PAGES.DOCUMENT_SIGNATURE_REQUEST.signatures'))}
          </Typography>

          <Button
            variant="contained"
            disabled={downloading}
            disableElevation
            color="primary"
            onClick={onDownloadReport}
            startIcon={<GetAppIcon />}
          >
            {t('PAGES.download_report')}
          </Button>
        </Grid>

        <DocumentSignaturesTable
          data={signatures}
          loading={loading}
          showError={error}
          collaboratorsMap={collaboratorsMap}
          onResend={onResendToCollaborator}
        />

        <Typography
          variant="h6"
          style={{
            margin: '0.5rem 0px',
          }}
        >
          {_.capitalize(t('PAGES.DOCUMENT_SIGNATURE_REQUEST.send_to_collaborators'))}
        </Typography>

        <CollaboratorsTable
          selected={selectedCollaborators}
          setSelected={setSelectedCollaborators}
        />

        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={onSendToCollaborators}
          >
            {t('send')}
          </Button>
        </Grid>

      </DialogContent>
    </Dialog>
  );
}
