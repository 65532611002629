import React from 'react';

import { Icon } from 'rsuite';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { makeStyles } from '@material-ui/styles';
import TuneIcon from '@material-ui/icons/Tune';

import useModal from '../../../hooks/useModal';


const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));


export default function SpeedDials({
  todos,
  rapidas,
  notReunion,
  notResponse,
}) {
  const actions = [
    {
      icon: 'check',
      name: 'Mostrar todos',
      onClick: todos,
    },
    {
      icon: 'page-end',
      name: 'Ações Rápidas',
      onClick: rapidas,
    },
    {
      icon: 'calendar',
      name: 'Itens sem reunião',
      onClick: notReunion,
    },
    {
      icon: 'user',
      name: 'Itens sem responsável',
      onClick: notResponse,
    },
  ];

  const classes = useStyles();
  const [open, handleOpen, handleClose] = useModal();


  return (
    <SpeedDial
      ariaLabel="Action plan page speed dial"
      className={classes.speedDial}
      icon={<TuneIcon />}
      onClick={handleOpen}
      onClose={handleClose}
      open={open}
      direction="up"
    >
      {actions.map((action, index) => (
        <SpeedDialAction
          key={index}
          icon={(
            <Icon
              icon={action.icon}
              size="lg"
            />
          )}
          tooltipTitle={(
            <span style={{ fontSize: '15px' }}>
              { action.name }
            </span>
          )}
          onClick={action.onClick}
        />
      ))}
    </SpeedDial>
  );
}
