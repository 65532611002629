import React, { useState, } from 'react';

import { Dialog, } from '@material-ui/core';
import { Icon, Input, InputGroup, Message, } from 'rsuite';

import BaseField from './BaseField';


/**
 * It uses the same props as BaseField component
 */
export default function DefaultField(props) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <BaseField
      {...props}
      render={(resp, onChangeResp) => {
        return (
          <>
            <Dialog
              open={openModal}
              onClose={() => setOpenModal(!openModal)}
            >
              <Message
                closable
                onClose={() => setOpenModal(!openModal)}
                type="info"
                description={props.field.name}
              />
              <div
                style={{
                  padding: '20px',
                  minWidth: '30rem',
                }}
              >
                <br />
                <InputGroup
                  inside
                  style={{
                    marginBottom: 2.3,
                  }}
                  value={resp}
                  onChange={e => onChangeResp(e.target.value)}
                >
                  <Input
                    componentClass="textarea"
                    rows={5}
                    value={resp}
                    disabled={props.disabled}
                  />
                </InputGroup>
              </div>
            </Dialog>
            <InputGroup
              inside
              style={{
                height: '38px',
              }}
              value={resp}
              onChange={e => onChangeResp(e.target.value)}
            >
              <Input disabled={props.disabled} value={resp} />
              <InputGroup.Button onClick={() => setOpenModal(!openModal)}>
                <Icon icon="pencil" />
              </InputGroup.Button>
            </InputGroup>
          </>
        );
      }}
    />
  );
}
