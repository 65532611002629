import React from 'react';
import _ from 'lodash';

import { Badge, } from 'react-bootstrap';
import { Grid, } from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import styles from './index.module.scss';


const QuestionItemHeader = ({ data, open, questionStatus, sector, }) => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
    >
      <Grid
        item
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div className={styles.boxAllQuestion}>
          <span>
            Tarefa { data.ordem + 1 }
          </span>
          <p className={styles.question}>
            {data.problema}
          </p>
        </div>
        <div className={styles.BoxRight}>
          <div className={styles.badgeSetor}>
            <p>
              { sector }
            </p>
          </div>{' '}
          <Badge
            style={{
              width: '70%',
              height: 20,
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
            bg={questionStatus.status}
          >
            {questionStatus.label}
          </Badge>
          {open ? <ExpandLess /> : <ExpandMore />}
        </div>
      </Grid>
    </Grid>
  );
}

export default React.memo(QuestionItemHeader);
