import { useState, useEffect, } from 'react';
import { useCookies, } from 'react-cookie';
import { useDispatch, } from 'react-redux';

import api, { setAuthHeader, renewSession, vericaLoginSession, } from '../../services/api';
import history from '../../history';
import { showToast, } from '../../ui-lib/toast';
import 'react-toastify/dist/ReactToastify.css';
import { clearLocalStorage, getRefreshToken, setUserSession, getUserSession, } from '../../utils';
import { logout, setRenewing, } from '../../features/usersSlice';


const LOGIN_ERRORS = Object.freeze({
  SERVER_NOT_FOUND: 'Servidor não encontrado, tente novamente dentro de alguns minutos',
  INVALID_LOGIN: 'Usuário ou senha inválidos',
  NOT_AUTHORIZED: 'Usuário não autorizado a utilizar a plataforma, entre em contato com o suporte'
});

const VALIDATION_MESSAGES = Object.freeze({
  REQUIRED_EMAIL: 'Preencha seu email',
  REQUIRED_PASSWORD: 'Preencha sua senha',
});


/**
 * Hook used for authentication management
 *
 * @returns hook attributes
 */
export default function useAuth() {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name',]);
  const [authToken, setAuthToken] = useCookies(['name']);
  const [authenticated, setAuthenticated] = useState(false);
  const [adminauth, setAdminAuth] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [nameCompany, setNameCompany] = useState('');


  /**
   * Set local auth info
   *
   * @param {string} token - user access token
   * @param {string} refreshToken - token to refresh user session
   * @param {string} name - user name
   * @param {string} email - user email
   * @param {boolean} admin - if the user is an admin or not
   * @param {string} avatar - user avatar
   * @param {string} company - user company name
   * @param {string} companyId - user company ID
   * @param {string} userId - user ID
   * @param {string} userGroup - user group
   * @param {boolean} contractAccepted - if the contract was accepted or not
   * @param {string} createdAt - when the user was created
   */
  function setAuthenticatedUserInfo(token, refreshToken, name, email, admin, avatar, companyId, userId, userGroup,
    contractAccepted = false, createdAt = '', company = '', groupName = '') {
    setAuthHeader(token);
    setUserSession({
      token: JSON.stringify(token),
      name,
      email,
      avatar,
      user: userId,
      group: userGroup,
      admin,
      idEmpresa: companyId,
      empresa: company,
      contract: contractAccepted,
      createdAt,
      refreshToken,
      groupName
    });
    setAuthenticated(true);
    setAdminAuth(admin === true);
    setAuthToken('token', JSON.stringify(token));
    setNameCompany(company);
  }

  /**
   * Validate login data
   *
   * @returns {array<boolean, message>} if the data is valid and the error message, if any
   */
  function loginValidation() {
    if (!email) {
      return [false, VALIDATION_MESSAGES.REQUIRED_EMAIL];
    } else if (!password) {
      return [false, VALIDATION_MESSAGES.REQUIRED_PASSWORD];
    }

    return [true, ''];
  }

  /**
   * Handle login
   *
   * @param {object} event - event that triggered the login handler
   */
  async function HandleLogin(event) {
    setIsLoadingButton(true);
    setAuthHeader(undefined);

    const [isValid, message] = loginValidation();
    if (!isValid) {
      setIsLoadingButton(false);
      return showToast({
        type: 'error',
        message,
        pauseOnFocusLoss: true,
      })
    }

    if (email && password) {
      event.preventDefault();
    }

    try {
      const { data: { token, refreshToken, user, }, } = await api.post('/login', {
        email,
        password,
      });

      setAuthenticatedUserInfo(token, refreshToken, user.name, user.email, user.admin, user.avatar, user.empresa,
        user._id, user.group, user.accept_contract, user.createdAt, user.empresa, user.groupName);

      history.push(user.admin ? '/Dashboard' : `/companies/${user.empresa}`);
    } catch (err) {
      console.log(err);
      let errorMessage = '';
      const { response } = err;
      if (!response) {
        errorMessage = LOGIN_ERRORS.SERVER_NOT_FOUND;
      } else {
        const status = response.status;

        if (status === 401) {
          errorMessage = LOGIN_ERRORS.INVALID_LOGIN;
        } else if (status === 403) {
          errorMessage = LOGIN_ERRORS.NOT_AUTHORIZED;
        } else if (status === 404) {
          errorMessage = LOGIN_ERRORS.SERVER_NOT_FOUND;
        }
      }

      showToast({
        type: 'error',
        message: errorMessage,
        pauseOnFocusLoss: true,
      });
    } finally {
      setIsLoadingButton(false);
    }
  }

  /**
   * Clear local authentication info after logout
   */
  function HandleLogout() {
    dispatch(logout());
    clearLocalStorage();
    setAuthenticated(false);
    removeCookie('token');
    removeCookie('map_page');
    setAuthHeader(undefined);
    history.push('/login');
  }

  function getTokenExp() {
    const [_, payload] = (localStorage.getItem('token') || '').split('.');
    if (undefined == payload) {
      return undefined;
    }

    try {
      return JSON.parse(window.atob(payload)).exp;
    } catch {
      return undefined;
    }
  }

  function isTokenExpired() {
    const exp = getTokenExp();
    return undefined == exp || (Date.now() > exp * 1000);
  }

  async function renewUserSession() {
    let ret = false;

    dispatch(setRenewing(true));
    try {
      const refreshToken = getRefreshToken();
      if (undefined == refreshToken) {
        return false;
      }

      const { data: { token, refreshToken: newRefreshToken, user, }, } =
        await renewSession(refreshToken);

      setAuthenticatedUserInfo(token, newRefreshToken, user.name, user.email, user.admin, user.avatar,
        user.empresa, user._id, user.group, user.accept_contract, user.createdAt, user.empresa, user.groupName);

      ret = true;
    } catch {
    }

    dispatch(setRenewing(false));
    return ret;
  }

  async function initialize() {
    const { token, admin, } = getUserSession();
    if (!isTokenExpired()) {
      setAdminAuth(admin);
      setAuthHeader(JSON.parse(token));
      setAuthenticated(true);
    } else {
      dispatch(logout());
      clearLocalStorage();
      setAuthenticated(false);
      removeCookie('token');
      removeCookie('map_page');
      setAuthHeader(undefined);
    }

    setLoading(false);
  }

  useEffect(() => {
    initialize();
  }, []);

  useEffect(function setTokenCheckerInterval() {
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    if (authenticated) {
      const intervalId = setInterval(async () => {
        try {
          await vericaLoginSession();
        } catch (err) {
          console.debug(err);
        }
      }, 60 * 1000);

      return function cleanup() {
        clearInterval(intervalId);
      };
    }
  }, [authenticated]);


  return {
    email,
    loading,
    password,
    adminauth,
    authenticated,
    isLoadingButton,
    nameCompany,
    setEmail,
    setPassword,
    HandleLogin,
    getTokenExp,
    HandleLogout,
    isTokenExpired,
    renewUserSession,
    setIsLoadingButton,
  };
}
