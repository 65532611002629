import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, } from 'react-redux';

import { Grid, } from '@material-ui/core';

import BaseWidget from './BaseWidget';
import MetricCard from './components/MetricCard';

import { DashboardWidgetType, } from '../../../core/entities/dashboard-widget';

import { getCompanyPrivacyCultureStats, } from '../../../services/api';

import { selectCompany, } from '../../../features/companySlice';


function PrivacyCultureWidget(props) {
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);


  async function initialize() {
    setLoading(true);
    try {
      const result = await getCompanyPrivacyCultureStats(company._id);
      setData([
        {
          value: result.data.totalTrainings,
          label: t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.PRIVACY_CULTURE}.total_trainings`),
          color: '#F94A93C9',
        },
        {
          value: result.data.totalCommitteeMeetings,
          label: t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.PRIVACY_CULTURE}.committee_meetings`),
          color: '#8884d8',
        },
        {
          value: result.data.totalSecurityIncidents,
          label: t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.PRIVACY_CULTURE}.total_security_incidents`),
          color: '#31D9A6',
        },
      ]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    initialize();
  }, []);


  return (
    <BaseWidget
      {...props}
      loading={loading}
    >
      <Grid
        container
        style={{
          padding: '0.5rem',
        }}
      >
        {
          data
            .map(({ value, label, color, }, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={4}
                  style={{
                    paddingLeft: '0.5rem',
                  }}
                >
                  <MetricCard
                    value={value}
                    label={label}
                    color={color}
                    variant="filled"
                  />
                </Grid>
              );
            })
        }
      </Grid>
    </BaseWidget>
  );
}

export default PrivacyCultureWidget;
