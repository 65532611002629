import React from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';
import { useFormik, } from 'formik';
import * as Yup from 'yup';

import {
  Container,
  TextField,

  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import { Button, } from 'rsuite';


const INITIAL_VALUES = Object.freeze({
  name: '',
  category: 1,
});

const useStyles = makeStyles({
  buttonContainer: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '0.1rem',
  },
  cancelBtn: {
    marginLeft: '1rem',
    borderColor: '#3B66FF',
    color: '#3B66FF !important',
    background: 'transparent !important',
    boxShadow: 'none',
    borderWidth: 2,
    border: '2px solid #3B66FF !important',
    '&:hover': {
      background: 'transparent !important',
      border: '2px solid #3B66FF !important',
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const InputProps = {
  disableUnderline: true,
};


function CookieForm({ onSubmit, categories, }) {
  const classes = useStyles();
  const { t, } = useTranslation();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: handleSubmit,
    validationSchema: Yup.object({
      name: Yup.string().required(_.capitalize(t('VALIDATION_MESSAGES.required', {
        context: 'male',
      }))),
      category: Yup.number().required(_.capitalize(t('VALIDATION_MESSAGES.required', {
        context: 'female',
      }))).oneOf(categories.map(({ id, }) => id), _.capitalize(t('VALIDATION_MESSAGES.must_be_a_valid_category'))),
    }),
  });

  const [loading, setLoading] = React.useState(false);


  async function handleSubmit(values) {
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
  }

  function onCancel() {
    formik.resetForm({
      values: INITIAL_VALUES,
    });
  }


  return (
    <Container
      disableGutters
      fullWidth
      maxWidth="xl"
    >
      <TextField
        margin="dense"
        label={_.capitalize(t('name'))}
        fullWidth
        id="name"
        variant="filled"
        InputProps={InputProps}
        type="text"
        error={formik.errors.name && formik.touched.name}
        helperText={formik.touched.name && formik.errors.name}
        {...formik.getFieldProps('name')}
      />

      <FormControl
        fullWidth
        variant="filled"
        margin="dense"
        error={formik.errors.category && formik.touched.category}
      >
        <InputLabel
          id="cookie-category-select-label"
        >
          { _.capitalize(t('category', { count: 1, })) }
        </InputLabel>
        <Select
          labelId="cookie-category-select-label"
          id="cookie-category-select"
          disableUnderline
          {...formik.getFieldProps('category')}
          MenuProps={MenuProps}
        >
          {
            categories.map((category) => (
              <MenuItem
                key={category.id}
                value={category.id}
              >
                {category.data.namePt}
              </MenuItem>
            ))
          }
        </Select>
        <FormHelperText>
          {formik.touched.category && formik.errors.category}
        </FormHelperText>
      </FormControl>

      <Grid className={classes.buttonContainer}>
        <Button
          className={classes.saveButton}
          appearance="primary"
          loading={loading}
          onClick={formik.handleSubmit}
        >
          { _.capitalize(t('save')) }
        </Button>

        <Button
          appearance="primary"
          className={classes.cancelBtn}
          onClick={onCancel}
          loading={loading}
        >
          { _.capitalize(t('cancel')) }
        </Button>
      </Grid>
    </Container>
  );
}

export default React.memo(CookieForm);
