import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';

import { Dialog, } from '@material-ui/core';

import ChatRespDPO from './chatDPOMensage';

import styles from './styleChatResp.module.scss';

import useModal from '../../hooks/useModal';


export default function RowSolicitacoes({ dados, companyId, token, }) {
  const [open, onOpenModal, onCloseModal] = useModal();


  return (
    <>
      <tr
        className={styles.RowSolicitacoes}
        onClick={onOpenModal}
      >
        <td>
          <span>{dados.protocol_id}</span>
        </td>
        <td>
          <span>{dados.status}</span>
        </td>
        <td>
          <span>{dados.name}</span>
        </td>
        <td>
          <span>{dados.obs}</span>
        </td>
        <td>
          <span>
            { moment(dados.created_at).format('DD/MM/YYYY HH:mm') }
          </span>
        </td>
      </tr>

      <Dialog
        open={open}
        onClose={onCloseModal}
        fullWidth
        maxWidth="sm"
      >
        <ChatRespDPO
          solicitacaoDados={dados}
          companyId={companyId}
          token={token}
        />
      </Dialog>
    </>
  );
}
