import { useEffect, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import {
  Breadcrumbs,
  Typography,
  Link,
  Container,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import history from '../../history';
import { fetchCourses, } from '../../features/courseSlice';

import Trainings from '../../components/DPO/ContainerTreinamentos';
import PageSelector from '../../components/PageSelector/PageSelector.jsx';
import TrainingsDashboard from './TraningsDashboard.jsx';

import { selectLoadingCourses, } from '../../features/courseSlice';
import { selectCompany } from '../../features/companySlice';


const useStyles = makeStyles({
  title: {
    color: '#4D5884',
    fontSize: '1.4rem',
    fontFamily: 'Inter',
    marginTop: '0.3rem',
    marginBottom: '0.8rem',
  },
});

const PAGE_OPTIONS = Object.freeze({
  DASHBOARD: {
    id: 'DASHBOARD',
    label: 'Dashboard',
  },
  COURSES: {
    id: 'COURSES',
    label: 'Cursos',
  },
});
const PAGE_OPTIONS_ARRAY = Object.values(PAGE_OPTIONS);

/**
 * Page used to show all available courses to user
 */
export default function CoursesPage({ pageSelected }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const [selectedOption, setSelectedOption] = useState(pageSelected || PAGE_OPTIONS.DASHBOARD.id);
  const loading = useSelector(selectLoadingCourses);


  /**
   * Set selected option
   *
   * @param {string} option - option ID
   */
  function onSelectedOption(option) {
    setSelectedOption(option);
  }

  /**
   * Reroute user to selected route
   *
   * @param {string} route - selected route
   */
  const go = (route) => {
    history.push(route);
  }

  /**
   * Use effect to initialize component
   */
  useEffect(() => {
    dispatch(fetchCourses());
  }, []);

  return (
    <>
      <p className={classes.title}>Canal DPO</p>

      <Container
        fullWidth
        maxWidth="xl"
        disableGutters
      >
        <Typography
          variant="h5"
          display="block"
        >
          Treinamento de equipe
        </Typography>
        <Typography
          variant="body1"
          display="block"
          paragraph
        >
          Toda empresa precisa conduzir treinamentos de Privacidade de Dados para seus colaboradores e
          terceiros. Nossa ferramenta vai auxiliar nesta tarefa, auxiliando na condução dos treinamentos.
        </Typography>
        {
          loading ?
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              <CircularProgress disableShrink />
              <Typography
                variant="body1"
                display="block"
              >
                Carregando...
              </Typography>
            </Grid> :
            selectedOption === PAGE_OPTIONS.COURSES.id ? <Trainings /> :
              selectedOption === PAGE_OPTIONS.DASHBOARD.id ? <TrainingsDashboard /> :
                null
        }

      </Container>
    </>
  );
}
