import * as Yup from 'yup';
import { COURSE_LEVELS_ARRAY, } from '../utils/constants';

/**
 * Courses schemas
 */

const createQuestionOptionSchema = Yup.object({
  statement: Yup.string().required('Obrigatório'),
  isCorrect: Yup.boolean(),
});

const createQuestionSchema = Yup.object({
  statement: Yup.string().required('Obrigatório'),
  alternatives: Yup.array().of(createQuestionOptionSchema).min(2,
    'Deve possuir ao menos duas alternativas').test('at-least-one-and-only-one-correct',
    'Uma e apenas uma das alternativas deve ser a correta', (value) => {
      return value.filter((curr) => curr.isCorrect).length === 1 &&
        value.reduce((prev, curr) => prev || curr.isCorrect, false);
    }),
});

const createCourseTestSchema = Yup.object({
  questions: Yup.array().of(createQuestionSchema),
});

export const createCourseSchema = Yup.object({
  title: Yup.string().required('Obrigatório'),
  duration: Yup.number().max(10000).min(0),
  level: Yup.string().oneOf(COURSE_LEVELS_ARRAY.map(({ value, }) => {
    return value;
  }), 'Dificuldade inválida'),
  description: Yup.string().required('Obrigatório'),
  videoUrl: Yup.string().required('Obrigatório'),
  test: createCourseTestSchema,
});

const questionAltAnswerSchema = Yup.object({
  id: Yup.number(),
  statement: Yup.string().required('Obrigatório'),
  isCorrect: Yup.boolean(),
});

const answerCourseQuestionSchema = Yup.object({
  id: Yup.number(),
  statement: Yup.string().required('Obrigatório'),
  alternatives: Yup.array().of(questionAltAnswerSchema).test('must-answer',
    'Deve marcar uma alternativa', (value) => {
      return value.reduce((prev, curr) => prev || curr.isCorrect, false);
    }),
});

export const answerCourseTestSchema = Yup.object({
  id: Yup.number(),
  questions: Yup.array().of(answerCourseQuestionSchema),
});
