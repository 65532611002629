import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Dialog from "../../components/Dialog";
import Grid from "@material-ui/core/Grid";
import {Badge, Box, Card} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import React, {useEffect, useRef} from "react";
import useToggle from "../../hooks/useToggle";
import useSet from "../../hooks/useSet";
import usePdf from "../../hooks/usePdf";
import {LoadingButton} from "@material-ui/lab";
import {useSelector} from "react-redux";
import {selectCompany} from "../../features/companySlice";
import moment from "moment/moment";


export default function DashboardPdfDialog({layout}) {
  const company = useSelector(selectCompany);

  const [open, toggle] = useToggle();
  const [widgets, __, removeWidget, toggleWidget] = useSet([]);
  const pdfContainer = useRef(null);
  const [generatePdf, loading, pdfError] = usePdf(pdfContainer, {
    margin: {
      top: 1,
      bottom: 1,
      left: 1,
      right: 1
    },
    filename: `${company.name}-dashboard-${moment().format('YYYYMMDD')}.pdf`,
    image: { type: 'png'},
    html2canvas: { scale: 1.2 },
    jsPDF: { unit: 'cm', format: 'A4', orientation: 'landscape' },
    pagebreak: { mode: ['avoid-all', 'css', 'legacy']},
  });

  useEffect(() => {
    layout.forEach((widget) => {
      removeWidget(widget);
    });
  }, [open]);

  return (
    <Box className="w-100" display="flex"  justifyContent="flex-end">
      <PictureAsPdfIcon onClick={toggle} className="clickable" sx={{justifySelf: "end"}}/>
      <Dialog title="Selecione os Widgets" maxWidth="lg" open={open} onClose={toggle}>
        <Grid container spacing={1} sx={{p: 2}}>
          {
            layout
              .map((widget) => {
                return (
                  <Grid key={widget.type} item xs={12} sm={6}>
                    <Badge className="w-100 h-100" color="primary" badgeContent={widgets.indexOf(widget) + 1}
                           anchorOrigin={{horizontal: "left", vertical: "top"}}>
                      <Card className="clickable w-100" variant="outlined" onClick={() => toggleWidget(widget)}>
                        <Typography gutterBottom fontWeight={500} sx={{p: 1}}>{widget.name}</Typography>
                        {/*<Typography color="textSecondary" gutterBottom>{widget.type}</Typography>*/}
                      </Card>
                    </Badge>
                  </Grid>
                );
              })
          }
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
              <Button color="error" variant="contained" sx={{mr: 2}} onClick={toggle}>Cancelar</Button>
              <LoadingButton color="primary" variant="contained" loading={loading} onClick={generatePdf}>Gerar PDF</LoadingButton>
            </Grid>
            {pdfError ? String(pdfError) :  ''}
          </Grid>
        </Grid>
        <div style={{width: '100hv'}}>
          <div ref={pdfContainer} >
            {
              widgets.map((widget, index) => {
                const Widget = widget.component;
                return (
                  <Widget className="mb-3" key={widget.type} index={index} type={widget.type}/>
                );
              })
            }
          </div>
        </div>
      </Dialog>
    </Box>
  );
}
