import { useEffect, useState, memo, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';
import { Route, Switch, useRouteMatch, useParams, useHistory, } from 'react-router-dom';

import { Notification, } from 'rsuite';

import NavBar from '../../components/NavBar/NavBar';
import LoadingPage from '../Loading';

import Main from '../Main/Main';
import DueDiligenceRequestPage from '../DueDiligenceRequest';
import EditarPerguntas from '../EditarPerguntas';
import Resultado from '../Resultado/index';
import ResultadoSim from '../Resultado/ResultAppropriate';
import MapAnaliseDeRiscoInicial from '../AnaliseDeRiscoInicial/MapAnaliseDeRiscoInicial.jsx';
import AnaliseDeRiscoCategoria from '../AnaliseDeRiscoInicial/AcoesPorRisco';
import MapAcoesPorRisco from '../AnaliseDeRiscoInicial/MapAcoesPorRisco.jsx';
import Workshop from '../Workshop';
import PlanoAcao from '../PlanoDeAcao';
import MapPlanoDeAcao from '../PlanoDeAcao/MapPlanoDeAcao.jsx';
import Monitoramento from '../Monitoramento';
import MapMonitoramento from '../Monitoramento/MapMonitoramento.jsx';
import Meetings from '../Meetings';
import MapMeetings from '../Meetings/MapMeetings.jsx';
import DPO from '../DPO';
import MapDPO from '../DPO/MapDPO.jsx';
import ControleCookies from '../DPO/GestaoCookies';
import MapCookies from '../DPO/MapCookies.jsx';
import NotificacoesPrivacidade from '../NotificacoesPrivacidade/NotificacoesPrivacidadePage';
import MapNotificacoesPrivacidade from '../NotificacoesPrivacidade/MapNotificacoesPrivacidade.jsx';
import MapeamentoDados from '../MapeamentoDados';
import RelatorioMapeamentoDados from '../MapeamentoDados/Relatorio';
import CadastroMapeamentoDados from '../MapeamentoDados/Cadastro.js';
import MapClassificacao from '../Classificacao/MapClassificacao.jsx';
import ResultAllMaturidade from '../Resultado/ResultAppropriateAll';
import Reunions from '../Reunion';
import RegDPO from '../DPO/RegistroDPO';
import MapRegisterDpo from '../DPO/MapRegisterDpo.jsx';
import Documentos from '../Documentos/DocumentosPage';
import MapDocuments from '../Documentos/MapDocuments.jsx';
import Treinamentos from '../Treinamentos/CursosPage';
import CoursePage from '../Treinamentos/CoursePage';
import MapCursosPage from '../Treinamentos/MapCursosPage.jsx';
import PDCA from '../PDCA';
import ImpactReport from '../ImpactReport/ImpactReport';
import MapImpactReport from '../ImpactReport/MapImpactReport.jsx';
import DueDiligencePage from '../DueDiligence';
import MapDueDiligence from '../DueDiligence/MapDueDiligence.jsx';
import Diagnosis from '../Diagnosis';
import ConfigCompany from '../../components/ConfigCompany/index.jsx';
import { setModalReunion } from '../../features/reunionsSlice.js';
import Perguntas from '../Perguntas/Perguntas.js';

import { setCompany } from '../../features/companySlice';
import { fetchCollaborators, } from '../../features/collaboratorsSlice';
import { listCompanySectors, } from '../../features/companySectorsSlice';
import { listProcessingAgents, } from '../../features/processingAgentsSlice';
import { listCompanyCategories, } from '../../features/companyCategoriesSlice';

import { getCompany, } from '../../services/api';
import DiagnosticMenuFixed from '../../components/CustomMenuFixed/DiagnosticMenuFixed.jsx';
import CustomMenuFixed from '../../components/CustomMenuFixed/index.jsx';
import GlobalMenuFixed from '../../components/CustomMenuFixed/GlobalMenuFixed.jsx';
import ModalNewReunionWorkshop from '../../components/Workshop/ContainerRight/ContainerRightIntegrantes/modalNewReunion.js';
import { Dialog } from '@mui/material';
import DefaultModal from '../../components/Modals/DefaultModal/DefaultModal.jsx';
import { setModalCreateNewTaskIsVisible } from '../../features/perguntasSlice.js';
import { CourseModal } from '../../components/CourseModal/index.js';
import { setModalCreateNewTraining } from '../../features/trainings.js';


function CompanyArea() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const history = useHistory();
  const { companyId, } = useParams();
  const [loading, setLoading] = useState(true);
  const modalReunionIsOpen = useSelector(state => state.reunions.modalNewReunion);
  const modalCreateNewTaskIsOpen = useSelector(state => state.perguntas.modalCreateNewTaskIsVisible);
  const modalCreateNewTraining = useSelector(state => state.trainings.modalCreateNewTraining);


  async function initialize() {
    setLoading(true);

    try {
      const { data, } = await getCompany(companyId);
      dispatch(setCompany(data));

      dispatch(fetchCollaborators());
      dispatch(listProcessingAgents());
      dispatch(listCompanySectors());
      dispatch(listCompanyCategories());

      setLoading(false);
    } catch (err) {
      if ((undefined != err.response) && (403 === err.response.status)) {
        Notification['error']({
          placement: 'bottomEnd',
          title: _.capitalize(t('ERRORS.COMPANY_AREA.access_denied')),
          description: _.capitalize(t('ERRORS.COMPANY_AREA.not_allowed_to_access_company')),
        });
        history.replace('/');
      }
    }
  }

  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      {
        loading ?
          <LoadingPage /> :
          <NavBar>
            <Switch>
              <Route
                exact
                path={`${match.path}/Workshop`}
                component={Workshop}
              />

              <Route
                exact
                path={`${match.path}/Plano-Acao`}
                component={MapPlanoDeAcao}
              />

              <Route
                exact
                path={`${match.path}/Monitoramento`}
                component={Main}
              />

              <Route
                exact
                path={`${match.path}/risk-matrix`}
                component={MapAnaliseDeRiscoInicial}
              />

              <Route
                exact
                path={`${match.path}/Resultado`}
                component={Resultado}
              />

              <Route
                exact
                path={`${match.path}/Resultado/Adequado`}
                component={ResultadoSim}
              />

              <Route
                exact
                path={`${match.path}/Resultado/Maturidade/Todas`}
                component={ResultAllMaturidade}
              />

              <Route
                exact
                path={`${match.path}/Mapeamento`}
                component={MapeamentoDados}
              />

              <Route
                exact
                path={`${match.path}/Mapeamento/Relatorio`}
                component={RelatorioMapeamentoDados}
              />

              <Route
                exact
                path={`${match.path}/Cadastro/Mapeamento`}
                component={CadastroMapeamentoDados}
              />

              <Route
                exact
                path={`${match.path}/Classificacao`}
                component={MapClassificacao}
              />

              <Route
                exact
                path={`${match.path}/Perguntas/Editar`}
                component={EditarPerguntas}
              />

              <Route
                exact
                path={`${match.path}/Respostas`}
                component={Diagnosis}
              />

              <Route
                exact
                path={`${match.path}/due-diligence`}
                component={MapDueDiligence}
              />

              <Route
                exact
                path={`${match.path}/due-diligence/:dueDiligenceRequestId`}
                component={DueDiligenceRequestPage}
              />

              <Route
                exact
                path={`${match.path}/DPO`}
                component={MapDPO}
              />

              <Route
                exact
                path={`${match.path}/pdca`}
                component={PDCA}
              />

              <Route
                exact
                path={`${match.path}/GestaoCookies`}
                component={MapCookies}
              />

              <Route
                exact
                path={`${match.path}/documentos`}
                name="documentos"
                component={MapDocuments}
              />

              <Route
                exact
                path={`${match.path}/registro/ativiadades`}
                component={MapRegisterDpo}
              />

              <Route
                exact
                path={`${match.path}/registro-incidentes`}
                name="notificacoes-privacidade"
                component={MapNotificacoesPrivacidade}
              />

              <Route
                exact
                path={`${match.path}/dpo/risk-report`}
                component={MapAcoesPorRisco}
              />

              <Route
                exact
                path={`${match.path}/dpo/impact-reports`}
                component={MapImpactReport}
              />

              <Route
                exact
                path={`${match.path}/meetings`}
                component={MapMeetings}
              />

              <Route
                exact
                path={`${match.path}/meetings/:meetingId`}
                component={Reunions}
              />

              <Route
                exact
                path={`${match.path}/treinamentos`}
                component={MapCursosPage}
              />

              <Route
                exact
                path={`${match.path}/treinamentos/curso/:courseId`}
                component={CoursePage}
              />

              <Route
                exact
                path={`${match.path}/config`}
                component={ConfigCompany}
              />

              <Route
                exact
                path={match.path}
                component={Main}
              />
            </Switch>
          </NavBar>
      }
      <GlobalMenuFixed />
      {/* //modals */}
      <ModalNewReunionWorkshop
        openModal={modalReunionIsOpen}
        closeModal={() => dispatch(setModalReunion(false))}
      />

      <DefaultModal
        open={modalCreateNewTaskIsOpen}
        onClose={() => dispatch(setModalCreateNewTaskIsVisible(false))}
        maxWidth='ls'
        title={'Cadastrar nova tarefa'}
      >
        <Perguntas
          closeModal={() => dispatch(setModalCreateNewTaskIsVisible(false))}
        />
      </DefaultModal>

      <CourseModal
        open={modalCreateNewTraining}
        handleClose={() => dispatch(setModalCreateNewTraining(false))}
      />
      {/* //fim modals */}
    </>
  );
}

export default memo(CompanyArea);
