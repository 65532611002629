import React from 'react';
import DefaultField from "../../../DataMappingAnswerPanel/Field/DefaultField";
import CheckboxLineField from "../../../DataMappingAnswerPanel/Field/CheckBoxLineField";
import PurposeField from "../../../DataMappingAnswerPanel/Field/PurposeField";
import SectorField from "../../../DataMappingAnswerPanel/Field/SectorField";
import PreDefinedField from "../../../DataMappingAnswerPanel/Field/PreDefinedField";
import LegalBasesField from "../../../DataMappingAnswerPanel/Field/LegalBasesField";
import {DataMappingFieldType, MAPPING_FIELD_TYPES} from "../../../../data/constants";
import ProcessingAgentsField from "../../../DataMappingAnswerPanel/Field/ProcessingAgentsField";
import CollaboratorsField from "../../../DataMappingAnswerPanel/Field/CollaboratorsField";
import RiskFieldLine from "../../../DataMappingAnswerPanel/Field/RiskFieldLine";
import SensitiveDataCategoriesField from "../../../DataMappingAnswerPanel/Field/SensitiveDataCategoriesField";
import ProcessingTypeField from "../../../DataMappingAnswerPanel/Field/ProcessingTypeField";
import DataManagementRoleField from "../../../DataMappingAnswerPanel/Field/DataManagementRoleField";
import PersonalDataTypeField from "../../../DataMappingAnswerPanel/Field/PersonalDataTypeField";
import SensitiveDataOptionsField from "../../../DataMappingAnswerPanel/Field/SensitiveDataOptionField";
import {getFieldType} from "../../../../core/entities/data-mapping-field";
import RIPDField from "../../../DataMappingAnswerPanel/Field/RIPDField";


const FIELD_COMPONENT_MAP = Object.freeze({
  [DataMappingFieldType.TEXT]: DefaultField,
  [DataMappingFieldType.CHECKBOX]: CheckboxLineField,
  [DataMappingFieldType.PURPOSE]: PurposeField,
  [DataMappingFieldType.SECTOR]: SectorField,
  [DataMappingFieldType.PRESET]: PreDefinedField,
  [DataMappingFieldType.LEGAL_BASES]: LegalBasesField,
  [DataMappingFieldType.PROCESSING_AGENTS]: ProcessingAgentsField,
  [DataMappingFieldType.COLLABORATORS]: CollaboratorsField,
  [DataMappingFieldType.RISK]: RiskFieldLine,
  [DataMappingFieldType.SENSITIVE_DATA_CATEGORY]: SensitiveDataCategoriesField,
  [DataMappingFieldType.PROCESSING_TYPE]: ProcessingTypeField,
  [DataMappingFieldType.DATA_MANAGEMENT_ROLE]: DataManagementRoleField,
  [DataMappingFieldType.PERSONAL_DATA_TYPE]: PersonalDataTypeField,
  [DataMappingFieldType.SENSITIVE_DATA_OPTION]: SensitiveDataOptionsField,
  [DataMappingFieldType.RIPD]: RIPDField,
});


export default function DataMappingLineReadOnlyField({
  token,
  answer,
  field,
  updateOptions,
}) {
  const type = getFieldType(field.asType, field.typePre, field.inputType) ||
    MAPPING_FIELD_TYPES.TEXT;
  const Field = FIELD_COMPONENT_MAP[type.value];

  const response = answer ? answer.uid : undefined;

  return (
    <Field
      token={token}
      field={field}
      uid={response}
      readOnly
      updateOptions={updateOptions}
      disabled
    />
  );
}
