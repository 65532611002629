import React, { useRef } from 'react';
import html2pdf from 'html-to-pdf-js';
import {MdPictureAsPdf} from "react-icons/md";

const MyComponent = ({component, onLoading, filename='meu-documento.pdf'}) => {

  const handlePrint = async () => {
    if (!component || !component.current) return;
    onLoading(true);

    const margin = {
      top: 3,
      bottom: 2,
      left: 1,
      right: 2
    };

    // Configurações do html2pdf
    const options = {
      margin: [margin.top, margin.left, margin.bottom, margin.right],
      filename: filename.replace(/ /g, "-"),
      image: { type: 'jpeg', quality: 4 },
      html2canvas: { scale: 4, dpi: 72, x:0, y: -5
        // onclone: (doc) => {
        //   console.log(11111, doc);
        // }
      },
      jsPDF: { unit: 'cm', format: 'letter', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy']},
    };

    // Gera o PDF
    try {
      await html2pdf().from(component.current).set(options).save();
      onLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <MdPictureAsPdf style={{width: "25px", height: "25px", cursor: "pointer", marginLeft: "10px"}}  onClick={handlePrint}/>
    </div>
  );
};

export default MyComponent;
