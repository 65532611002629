import { useState, memo } from 'react'
import {
  Container,
  TablePagination,
} from '@material-ui/core'
import { Divider } from 'rsuite'
import SubtitleColorPrimary from '../Global/SubtitleColorPrimary'
import RiscAnalisisShow from '../RiscoAnaliseComponent/RiscAnalisisShow'
import Paginator from '../Paginator/Paginator'
import { useSelector } from 'react-redux'
function RiscContainerShow({ aceitos }) {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const [pageAceitos, setPageAceitos] = useState(0)
  const [rowsPerPageAceitos, setRowsPerPageAceitos] =
    useState(5)

  const reunions = useSelector(
    (state) => state.reunions.reunions
  )
  const body = useSelector((state) => state.matriz.value)
  const title = useSelector((state) => state.matriz.title)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePageAceitos = (event, newPage) => {
    setPageAceitos(newPage)
  }

  const handleChangeRowsPerPageAceitos = (event) => {
    setRowsPerPageAceitos(parseInt(event.target.value, 10))
    setPageAceitos(0)
  }
  return (
    <Container maxWidth="xl" disableGutters>

      <Divider />
      
      <SubtitleColorPrimary
        description={`Itens com riscos aceitar [${aceitos.length}]`}
        fontSize={23}
      />

      {aceitos &&
        aceitos
          .slice(
            pageAceitos * rowsPerPageAceitos,
            pageAceitos * rowsPerPageAceitos +
              rowsPerPageAceitos
          )
          .map((body, index) => {
            return (
              <RiscAnalisisShow
                key={body.id}
                data={body}
                index={index}
                reunions={reunions}
              />
            )
          })}

      <TablePagination
        rowsPerPageOptions={[
          5,
          10,
          25,
          { label: 'Todos', value: body.length },
        ]}
        component="div"
        SelectProps={{
          inputProps: { 'aria-label': 'Linhas' },
          native: true,
        }}
        labelRowsPerPage={`Linhas por página`}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        count={aceitos.length}
        rowsPerPage={rowsPerPageAceitos}
        page={pageAceitos}
        onPageChange={handleChangePageAceitos}
        onRowsPerPageChange={handleChangeRowsPerPageAceitos}
        ActionsComponent={() => <Paginator page={page + 1} rowsPerPage={rowsPerPage} count={aceitos.length} onChange={handleChangePage} />}
      />
    </Container>
  )
}
export default memo(RiscContainerShow)
