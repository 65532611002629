import { useState, useEffect, useContext, } from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';
import { useSelector, useDispatch, } from 'react-redux';
import { setIndexSelectSite } from '../../features/gestaoDeCookies';
import MappingContext from './mapping-context';
import DpoContext from './context';
import { Notification, } from 'rsuite';
import { getCompanyRegisteredPopups } from "../../services/api";

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, } from '@material-ui/styles';
import ShowCode from './ShowCode';

import Title from '../../components/Global/PageTitle';
import CookieConsentLogTable from './CookieConsentLogTable';
import CookiePopupConfigForm from './CookiePopupConfigForm';
import CookiePopupInstallation from './CookiePopupInstallation';
import CookieClassification from './CookieClassification';
import TimeLineItem from '../../components/TimeLine/TimeLineItem';
import InitialWorkshopImg from '../../assets/newImageWorkshop.svg'

import './dpostyles.css';
import { selectCompany } from '../../features/companySlice';
import { setSites, } from "../../features/gestaoDeCookies";

import ContainerTimeLine from '../../components/Containers/ContainerTimeLine';

import RegisterNewSiteDialog from './RegisterNewSiteDialog';


const useStyles = makeStyles({
  layout: {
    marginTop: '0.8rem',
    marginBottom: '0.5rem',
  },
  selectorContainer: {
    marginBottom: '1rem',
  },
  noItems: {
    paddingTop: '4rem',
    paddingBottom: '4rem',
    color: '#80808080',
  },
  loading: {
    marginTop: '4rem',
    marginBottom: '4rem',
  },
});


/**
 * Cookie consent management page
 *
 * @returns Rendered page
 */
export default function CookieConsentManagement() {
  const { t, } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const company = useSelector(selectCompany);
  const { sites, indexSelectSite } = useSelector(state => state.gestaoDeCookies);

  // const [sites, setSites] = useState([]);
  const [siteIdx, setSiteIdx] = useState(0);
  const [newSiteModalVisible, setNewSiteModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);


  async function initialize() {
    setLoading(true);

    try {
      const { data, } = await getCompanyRegisteredPopups(company._id);
      dispatch(setSites(data));
    } catch (error) {
      console.log(error);
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar sites registrados, tente novamente dentro de alguns instantes',
      });
    }

    setLoading(false);
  }

  /**
   * Callback handler called when current popup config is updated
   */
  async function onConfigUpdated(config) {
    await initialize();
  }

  /**
  * Open modal to register new site
  */
  async function openNewSiteModal() {
    setNewSiteModalVisible(true);
  }

  /**
   * Close modal to register new site
   */
  async function closeNewSiteModal() {
    setNewSiteModalVisible(false);
  }

  /**
   * Callback called when new popup config is created for a site
   * @param {object} popupConfig - new popup config
   */
  async function onNewSitePopupCreated(popupConfig) {
    const updatedSites = [...sites, popupConfig];
    dispatch(setSites(updatedSites));
    dispatch(setIndexSelectSite(updatedSites.length - 1));
  }

  /**
   * Use effect to initialize component data
   */
  // useEffect(initialize, []);

  const ShowCodeModal = () => {

    const { modalCode, setModalCode } = useContext(DpoContext);

    return (
      <ShowCode onClose={() => setModalCode(false)} open={modalCode}>
        {
          !loading && sites.length === 0 &&
          <Grid container>
            <Grid
              item
              md={5}
              style={{ height: "70vh" }}
            >
              <img src={InitialWorkshopImg} alt="Executivo de terno Ilustração" height={"100%"} style={{ objectFit: "contain" }} />
            </Grid>
            <Grid item xs>
              <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flex: "1", height: "100%" }}>
                <Button variant="contained" color="primary" style={{ padding: "8px 30px" }} onClick={openNewSiteModal}>Criar popup de cookies</Button>
              </div>
            </Grid>
          </Grid>
        }

        {
          !loading && sites.length > 0 &&
          <Grid
            item
          >
            <Typography
              variant="h6"
              display="block"
              color="primary"
            >
              Código para instalação do banner
            </Typography>
            <Divider
              style={{
                marginBottom: '0.5rem',
              }}
            />
            <CookiePopupInstallation
              popupId={sites[indexSelectSite].id}
            />
          </Grid>
        }

      </ShowCode>
    );
  }


  return (
    <MappingContext>
      <Container
        maxWidth="xl"
        style={{
          marginTop: 5,
        }}
      >
        {
          loading ?
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress className={classes.loading} />
            </Grid> :
            null
        }

        {
          !loading && sites.length === 0 ?
            <Grid container>
              <Grid
                item
                md={5}
                style={{ height: "70vh" }}
              >
                <img src={InitialWorkshopImg} alt="Executivo de terno Ilustração" height={"100%"} style={{ objectFit: "contain" }} />
              </Grid>
              <Grid item xs>
                <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flex: "1", height: "100%" }}>
                  <Button variant="contained" color="primary" style={{ padding: "8px 30px" }} onClick={openNewSiteModal}>Criar popup de cookies</Button>
                </div>
              </Grid>
            </Grid> :
            null
        }

        {
          !loading && sites.length > 0 ?
            <>
              {/* <Typography
                variant="h5"
                display="block"
                color="primary"
                style={{
                  paddingTop: 10,
                  paddingBottom: 5,
                }}
              >
                {_.capitalize(t('PAGES.COOKIE_MANAGEMENT.consent_logs'))}
              </Typography>
              <CookieConsentLogTable
                popupId={sites[indexSelectSite].id}
              /> */}

              <Container
                disableGutters
                maxWidth="xl"
              >
                <Grid
                  container
                  direction="row"
                  className={classes.layout}
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                  >
                    <CookiePopupConfigForm
                      popupId={sites[indexSelectSite].id}
                      onConfigUpdated={onConfigUpdated}
                    />

                    {
                      !loading && sites.length === 0 &&
                      <Grid container>
                        <Grid
                          item
                          md={5}
                          style={{ height: "70vh" }}
                        >
                          <img src={InitialWorkshopImg} alt="Executivo de terno Ilustração" height={"100%"} style={{ objectFit: "contain" }} />
                        </Grid>
                        <Grid item xs>
                          <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flex: "1", height: "100%" }}>
                            <Button variant="contained" color="primary" style={{ padding: "8px 30px" }} onClick={openNewSiteModal}>Criar popup de cookies</Button>
                          </div>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Container>
            </> :
            null
        }
      </Container>
      <ShowCodeModal />
      <RegisterNewSiteDialog
        open={newSiteModalVisible}
        onClose={closeNewSiteModal}
        onCreated={onNewSitePopupCreated}
      />
    </MappingContext>
  );
}
