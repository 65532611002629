import React from 'react';
import _ from 'lodash';

import { Button, Container, Grid, Typography, Link, } from '@material-ui/core';

import PublicPageLayout from '../../components/PublicPageLayout';
import ProgressBar from './progress-bar';
import {
  CompanyMaturityDiagnosisContext,
  CompanyMaturityDiagnosisContextProvider,
} from './context';
import LimitedBackdropLoader from '../../components/LimitedBackdropLoader';
import CompanyMaturityDiagnosisQuestionItem from './company-maturity-diagnosis-question-item';
import CompanyMaturityDiagnosisFinished from './company-maturity-diagnosis-finished';
import {useParams} from "react-router-dom";


function CompanyMaturityDiagnosisQuestions() {
  const {
    question,
    questions,
    nextEnabled,
    questionData,
    previousEnabled,
    onNext,
    onChange,
    onPrevious,
  } = React.useContext(CompanyMaturityDiagnosisContext);


  function _onNext() {
    if (nextEnabled) {
      onNext();
    }
  }

  function openPrivacyPolicy() {
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;
    const windowWidth = 800; // Adjust as needed
    const windowHeight = 600; // Adjust as needed

    const leftPosition = (screenWidth - windowWidth) / 2;
    const topPosition = (screenHeight - windowHeight) / 2;

    const newWindow = window.open(
      '/privacy-policy',
      "UsePolicyWindow",
      `width=${windowWidth}, height=${windowHeight}, left=${leftPosition}, top=${topPosition}, resizable=yes, scrollbars=yes`
    );

    newWindow.focus();
  }


  return (
    <>
      <Container
        maxWidth="sm"
        disableGutters
        style={{
          marginTop: '.5rem',
        }}
      >
        <Typography
          align="center"
          style={{
            color: '#54708C',
            fontWeight: '500',
            fontSize: '17px',
          }}
        >
          Por favor, insira as informações necessárias para gerar seu Diagnóstico da LGPD.
        </Typography>
        <ProgressBar
          current={question}
          total={questions.length}
        />
      </Container>

      <CompanyMaturityDiagnosisQuestionItem
        question={questionData}
        order={question + 1}
        onChange={onChange}
        onNext={_onNext}
      />

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          color="primary"
          disableElevation
          disabled={!previousEnabled}
          variant="contained"
          style={{
            textTransform: 'none',
          }}
          onClick={onPrevious}
        >
          Anterior
        </Button>
        <Button
          color="primary"
          disableElevation
          disabled={!nextEnabled}
          variant="contained"
          style={{
            textTransform: 'none',
          }}
          onClick={_onNext}
        >
          Avançar
        </Button>
      </Grid>

      <Typography
        variant="caption"
        display="block"
        align="center"
        color="textSecondary"
        style={{
          marginTop: '1rem',
        }}
      >
        Ao preencher este formulário, você concorda com o nosso {` `}
        <Link onClick={openPrivacyPolicy}>
          Aviso de Privacidade
        </Link>.
      </Typography>
    </>
  );
}


function CompanyMaturityDiagnosis({ finished, }) {
  const { loading, } = React.useContext(CompanyMaturityDiagnosisContext);


  return (
    <Container maxWidth="md" >
      {
        finished ?
        <CompanyMaturityDiagnosisFinished /> :
        <CompanyMaturityDiagnosisQuestions />
      }
  <LimitedBackdropLoader open={loading} />
    </Container>
  );
}


export default function CompanyMaturityDiagnosisWrapper() {

  const { affiliateId } = useParams();
  const [finished, setFinished] = React.useState(false);


  return (
    <PublicPageLayout>
      <CompanyMaturityDiagnosisContextProvider affiliateId={affiliateId} onFinished={() => setFinished(true)}>
        <CompanyMaturityDiagnosis finished={finished} />
      </CompanyMaturityDiagnosisContextProvider>
    </PublicPageLayout>
  );
}
