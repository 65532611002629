import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import { Notification, } from 'rsuite';
import * as api from '../services/api';
import { CRUD_STATUSES, } from '../utils/constants';


const initialState = {
  collaborators: [],
  collaboratorsMap: {},
  status: CRUD_STATUSES.IDLE,
  managementModalOpen: false,
};


export const fetchCollaborators = createAsyncThunk('collaborators/fetchCollaborators', async (_, thunkAPI) => {
  const { data, status, } = await api.getCollaborators(thunkAPI.getState().company.company._id);
  if (200 === status) {
    return data;
  } else {
    Notification['warning']({
      placement: 'bottomEnd',
      title: 'Falha ao carregar colaboradores!',
      description: 'Tente novamente dentro de alguns instantes.'
    });
  }
});


export const createCollaborator = createAsyncThunk('collaborators/createCollaborator', async (collaborator, thunkAPI) => {
  const { data, } = await api.createCollaborator(thunkAPI.getState().company.company._id, collaborator);
  return data;
});


export const updateCollaborator = createAsyncThunk('collaborators/updateCollaborator', async (collaborator, thunkAPI) => {
  const { data, } = await api.updateCollaborator(thunkAPI.getState().company.company._id, collaborator);
  return data;
});


export const deleteCollaborator = createAsyncThunk('collaborators/deleteCollaborator', async (collaboratorId, thunkAPI) => {
  const { data, } = await api.deleteCollaborator(thunkAPI.getState().company.company._id, collaboratorId);
  return data;
});


export const collaboratorsSlice = createSlice({
  name: 'collaborator',
  initialState,
  reducers: {
    setStatus: (state, { payload: status, }) => {
      if (status != null) {
        state.status = status;
      } else {
        state.status = initialState.status;
      }
    },
    setCollaboratorsModal: (state, { payload, }) => {
      state.managementModalOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCollaborators.pending, (state) => {
      state.status = CRUD_STATUSES.LOADING;
    }).addCase(fetchCollaborators.fulfilled, (state, { payload: collaborators, }) => {
      state.status = CRUD_STATUSES.FETCH_SUCCESSFUL;
      collaborators.sort(({ name: a, }, { name: b, }) => a > b ? 1 : b > a ? -1 : 0);
      state.collaborators = collaborators;
      state.collaboratorsMap = Object.fromEntries(collaborators.map((collaborator) => {
        return [collaborator.id, collaborator];
      }));
    }).addCase(fetchCollaborators.rejected, (state) => {
      state.status = CRUD_STATUSES.FETCH_FAILED;
      state.collaborators = initialState.collaborators;
    });

    builder.addCase(createCollaborator.fulfilled, (state, { payload: collaborator, }) => {
      state.collaborators.push(collaborator);
      state.collaboratorsMap[collaborator.id] = collaborator;
    });

    builder.addCase(updateCollaborator.fulfilled, (state, { payload: collaborator, }) => {
      const index = state.collaborators.findIndex(({ id, }) => id === collaborator.id);
      if (index > -1) {
        state.collaborators[index] = collaborator;
        state.collaboratorsMap[collaborator.id] = collaborator;
      }
    });

    builder.addCase(deleteCollaborator.fulfilled, (state, { payload: collaborator, }) => {
      const index = state.collaborators.findIndex(({ id, }) => id === collaborator.id);
      if (index > -1) {
        state.collaborators.splice(index, 1);
      }
      delete state.collaboratorsMap[collaborator.id];
    });
  }
});

/**
 * Custom select to select a collaborator by ID
 *
 * @param {object} state - collaborator slice state
 * @param {number} collaboratorId - collaborator ID
 *
 * @returns the selected collaborator
 */
export const selectCollaboratorById = (state, collaboratorId) => {
  return state.collaborators.collaboratorsMap[collaboratorId];
}

/**
 * Custom select to get status
 *
 * @param {object} state - collaborator slice state
 *
 * @returns the feature status
 */
export const selectFeatureStatus = (state) => {
  return state.collaborators.status;
}

/**
 * Custom select to get collaborators
 *
 * @param {object} state - collaborator slice state
 *
 * @returns collaborators
 */
export const selectCollaborators = (state) => {
  return state.collaborators.collaborators;
}

export function selectCollaboratorsModalState(state) {
  return state.collaborators.managementModalOpen;
}

export function selectCollaboratorsMap(state) {
  return state.collaborators.collaboratorsMap;
}

export const { setStatus, setCollaboratorsModal, } = collaboratorsSlice.actions;
export default collaboratorsSlice.reducer;
