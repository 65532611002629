import React from 'react';

import Sheet from '../../components/Sheet';
import ImpactReport from './ImpactReport';

const PAGES = Object.freeze({
    impactReport: {
      id: 'impactReport',
      titleTab: "Relatório de impacto",
      titlePage: "Relatório de impacto",
      component: <ImpactReport />,
    },
});

const ARRAY_PAGES = Object.values(PAGES);

export default function MapImpactReport() {

  return (
    <Sheet pages={ARRAY_PAGES} />
  );
}
