import { useEffect, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { useParams, } from 'react-router-dom';

import { Breadcrumbs, Link, Typography, } from '@material-ui/core';

import history from '../../history';
import Course from '../../components/DPO/Course.jsx';

import { fetchCourses, selectCourses, selectCourseById, } from '../../features/courseSlice';
import { selectCompany, } from '../../features/companySlice';

import { getUser, } from '../../services/api.js';


/**
 * Page to show single course
 *
 * @returns rendered course page
 */
export default function CoursePage() {
  const dispatch = useDispatch();
  const { courseId, } = useParams();
  const company = useSelector(selectCompany);
  const courses = useSelector(selectCourses);
  const course = useSelector((state) => selectCourseById(state, courseId));

  const [author, setAuthor] = useState({});

  /**
   * Fetch author info from the API
   */
  const getCourseAuthor = async () => {
    try {
      const { data, } = await getUser(course.author_id);
      setAuthor(data);
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Use effect to update author info
   */
  useEffect(() => {
    if ((undefined != course) && (course.author_id != author._id)) {
      getCourseAuthor();
    }
  }, [course]);

  /**
   * Use effect to initialize component info
   */
  useEffect(() => {
    if (courses.length === 0) {
      dispatch(fetchCourses());
    }
  }, []);

  /**
   * Reroute user to selected route
   *
   * @param {string} route - selected route
   */
  const go = (route) => {
    history.push(route);
  }


  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          color="inherit"
          onClick={() => go(`/companies/${company._id}`)}
        >
          Home
        </Link>
        <Link
          color="inherit"
          onClick={() => go(`/companies/${company._id}/DPO`)}
        >
          Canal do DPO
        </Link>
        <Link
          color="inherit"
          onClick={() => go(`/companies/${company._id}/treinamentos`)}
        >
          Treinamentos de equipe
        </Link>
        <Typography color="textPrimary">
          { course.title }
        </Typography>
      </Breadcrumbs>

      <Course
        course={course}
        author={author}
      />
    </>
  );
}
