import React from 'react';

import Sheet from '../../components/Sheet';
import Documents from './DocumentosPage';

const PAGES = Object.freeze({
    documents: {
      id: 'documents',
      titleTab: "Documentos",
      titlePage: "Documentos",
      component: <Documents />,
    }
});

const ARRAY_PAGES = Object.values(PAGES);

export default function MapDocuments() {

  return (
    <Sheet pages={ARRAY_PAGES} />
  );
}
