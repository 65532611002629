import React, { useState, memo, } from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';
import { useFormik, } from 'formik';
import * as Yup from 'yup';

import {
  Container,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import { Button, } from 'rsuite';

import ProcessingAgentsTable from '../ProcessingAgentsTable';


const INITIAL_VALUES = Object.freeze({
  processingAgents: [],
  template: '',
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function DueDiligenceRequestForm({
  onSubmit,
  templates,
  processingAgents,
  onCreateNewProcessingAgent = () => {},
}) {
  const { t, } = useTranslation();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: submitProcessingAgent,
    validationSchema: Yup.object({
      processingAgents: Yup.array()
        .of(Yup.number().integer())
        .min(1, t('VALIDATION_MESSAGES.select_one_operator')),
      template: Yup.string().required(_.capitalize(t('VALIDATION_MESSAGES.required', {
        context: 'male',
      }))),
    }),
  });
  const [loading, setLoading] = useState(false);


  async function submitProcessingAgent(values) {
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
  }

  function onChangeSelectedProcessingAgents(selectedProcessingAgents) {
    formik.setFieldValue('processingAgents', selectedProcessingAgents);
  }


  return (
    <Container
      disableGutters
      maxWidth="xl"
    >
      <FormControl
        fullWidth
        variant="filled"
        margin="dense"
        error={formik.errors.template && formik.touched.template}
      >
        <InputLabel id="request-template-label" >
          {_.capitalize(t('PAGES.DUE_DILIGENCE.template'))}
        </InputLabel>
        <Select
          labelId="request-template-label"
          id="template"
          disableUnderline
          {...formik.getFieldProps('template')}
          MenuProps={MenuProps}
        >
          {
            templates?.map((item) => (
              <MenuItem
                key={item.id}
                value={item.id}
              >
                {item.name}
              </MenuItem>
            ))
          }
        </Select>
        <FormHelperText>
          {formik.touched.template && formik.errors.template}
        </FormHelperText>
      </FormControl>

      <FormControl
        fullWidth
        variant="filled"
        margin="dense"
        error={formik.errors.processingAgents && formik.touched.processingAgents}
      >
        <FormHelperText>
          {formik.touched.processingAgents && formik.errors.processingAgents}
        </FormHelperText>
      </FormControl>

      <ProcessingAgentsTable
        selected={formik.values.processingAgents}
        processingAgents={processingAgents}
        setSelected={onChangeSelectedProcessingAgents}
        onCreateNewProcessingAgent={onCreateNewProcessingAgent}
      />
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          appearance="primary"
          loading={loading}
          onClick={formik.handleSubmit}
        >
          {_.capitalize(t('send'))}
        </Button>
      </Grid>
    </Container>
  );
}

export default memo(DueDiligenceRequestForm);
