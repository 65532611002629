import React from 'react';

import Sheet from '../../components/Sheet';
import Report from './Report';
import CookieConsentManagement from './GestaoCookies';
import SelectSite from './SelectSite';

const PAGES = Object.freeze({
  relatorio: {
    id: 'relatorio',
    titleTab: "Relatório",
    titlePage: "Relatório",
    component: <Report />,
  },
  cookies: {
    id: 'cookies',
    titleTab: "Configuração",
    titlePage: "Configuração",
    component: <CookieConsentManagement />,
  },
});

const ARRAY_PAGES = Object.values(PAGES);

export default function MapCookies() {

  return (
    <Sheet pages={ARRAY_PAGES} outsideTopComponent={<SelectSite />} />
  );
}
