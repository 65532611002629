import { memo, } from 'react';

import styles from './styleBodyNew.module.scss';


function ResourceClassificationItem({ name, amount, }) {
  return (
    <>
      <div className={styles.boxRow}>
        <div className={styles.boxText}>
          <span>{name}</span>
          <p>{amount}</p>
        </div>
      </div>
    </>
  );
}

export default memo(ResourceClassificationItem);
