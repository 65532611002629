import React, { useState, useEffect } from 'react'
import { Container, Paper, Dialog } from '@material-ui/core'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import parse from 'html-react-parser'

import api, { getPolitica } from '../../services/api'
import { Button } from 'rsuite'
import Notifications from '../../utils/Notification'
import './styles.css'
export default function Politica() {
  const [text, setText] = useState('')
  const [id, setId] = useState('')
  const [edit, setEdit] = useState(false)
  const [open, setOpen] = useState(false)


  function HandleClose() {
    setOpen(false)
  }

  async function getdata() {
    await getPolitica('politica').then(({ data }) => {
      setId(data[0].id)
      setText(data[0].value)
    })
  }

  useEffect(() => {
    getdata()
  }, [])

  async function handleSubmit(e) {
    e.preventDefault()
    await api
      .patch('politica', {
        id,
        value: text,
      })
      .then(() => {
        Notifications(
          'success',
          'Sucesso',
          'Politica atualizada com sucesso'
        )
      })
  }
  return (
    <div>
      <div className="boxHeaderPoliticas">
        <h3>Política de privacidade</h3>
        <button onClick={() => setOpen(true)}>Visualizar</button>
      </div>
      <Dialog 
      open={open} 
      onClose={HandleClose}
      fullWidth
      maxWidth="lg"
      >
      <Container
        component={Paper}
        maxWidth="xl"
        style={{ maxHeight: '100rem' }}
      >
        <br />

        <div className="PoliticaContent">
          <CKEditor
            editor={ClassicEditor}
            data={text}
            onChange={(event, editor) => {
              const data = editor.getData()
              setText(data)
            }}
          />
        </div>
        <Button
          color="blue"
          onClick={handleSubmit}
          style={{ marginLeft: '94%', marginTop: '1%' }}
        >
          Salvar
        </Button>
        <br />
        <br />
      </Container>
      </Dialog>
    </div>
  )
}
