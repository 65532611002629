import api from "./api";


export async function getReportConfig(company) {
  return api.get(`/companies/${company._id}/report`);
}

export async function createReportHeader(company, header) {
  return api.post(`/companies/${company._id}/report/header`, {
    name: header.name,
    value: header.value
  });
}

export async function updateHeader(company, header) {
  return api.put(`/companies/${company._id}/report/header/${header.id}`, {
    name: header.name,
    value: header.value
  });
}

export async function removeHeader(company, header) {
  return api.delete(`/companies/${company._id}/report/header/${header.id}`);
}


export async function setReportLogo(company, file) {
  const formData = new FormData();
  formData.append('logo', file);

  return api.post(`/companies/${company._id}/report/logo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}


export async function getReportLogo(company) {
  return api.get(`/companies/${company._id}/report/logo`, {
    responseType: 'blob'
  });
}

