import React from 'react';

import { withStyles } from '@material-ui/styles';
import { Container, LinearProgress as MUILinearProgress, Typography, } from '@material-ui/core';


const LinearProgress = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#D1D6E2',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#3B66FF',
  },
}))(MUILinearProgress);


export default function ProgressBar({ current, total, }) {
  return (
    <Container
      disableGutters
      style={{
        margin: '0.5rem 0',
      }}
    >
      <LinearProgress
        variant="determinate"
        value={100 * current/total}
        style={{
          height: 8,
          borderRadius: 4,
        }}
      />
      <Typography
        display="block"
        align="center"
        style={{
          marginTop: '0.5rem',
          color: '#98A4D0',
        }}
      >
        PERGUNTA{` `}
        <strong
          style={{
            color: '#3B66FF',
          }}
        >
          { current + 1 }
        </strong>{` `}
        DE{` `}
        <strong
          style={{
            color: '#3B66FF',
          }}
        >
          { total }
        </strong>
      </Typography>
    </Container>
  );
}
