import React from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, } from 'formik';
import { useTranslation, } from 'react-i18next';

import {
  Grid,
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  FormHelperText,
  Switch,
  Container,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Button from '../Button';


const INITIAL_VALUES = Object.freeze({
  createNew: false,
  name: '',
  template: '',
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const useStyles = makeStyles({
  actions: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    color: 'white',
    backgroundColor: '#3B66FF',
  },
});


function SaveAsTemplateForm({ items, onCancel, onSubmit, }) {
  const classes = useStyles();
  const { t, } = useTranslation();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: _onSubmit,
    validationSchema: Yup.object({
      createNew: Yup.boolean(),
      name: Yup.string().when('createNew', {
        is: true,
        then: (schema) => {
          return schema
            .required(_.capitalize(t('VALIDATION_MESSAGES.required', {
              context: 'male',
            })));
        },
      }),
      template: Yup.number().when('createNew', {
        is: false,
        then: (schema) => {
          return schema
            .required(_.capitalize(t('VALIDATION_MESSAGES.required', {
              context: 'male',
            })))
            .oneOf(items.map((template) => template.id));
        },
      }),
    }),
  });


  function cleanForm(values = INITIAL_VALUES) {
    formik.resetForm({
      errors: {},
      touched: {},
      values,
    });
  }

  function _onSubmit(data) {
    onSubmit(data);
    cleanForm();
  }

  function setCreateNew(value=false) {
    formik.setFieldValue('createNew', value);
  }

  function _onCancel() {
    cleanForm();
    onCancel();
  }

  React.useEffect(() => {
    cleanForm(formik.values);
  }, [formik.values.createNew]);


  return (
    <Container>
      <Grid
        item
        xs={12}
        container
        direction="row"
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid
            item
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setCreateNew()}
          >
            { _.capitalize(t('PAGES.overwrite')) }
          </Grid>
          <Grid item>
            <Switch
              checked={Boolean(formik.values.createNew)}
              color="primary"
              id="name"
              style={{
                color: '#3B66FF',
              }}
              {...formik.getFieldProps('createNew')}
            />
          </Grid>
          <Grid
            item
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setCreateNew(true)}
          >
            { _.capitalize(t('PAGES.new', { context: 'male', })) }
          </Grid>
        </Grid>

        {
          formik.values.createNew ?
          <TextField
            margin="dense"
            variant="filled"
            label={_.capitalize(t('name'))}
            InputProps={{
              disableUnderline: true,
            }}
            fullWidth
            id="name"
            type="text"
            error={formik.errors.name && formik.touched.name}
            helperText={formik.touched.name && formik.errors.name}
            {...formik.getFieldProps('name')}
          /> :
          <FormControl
            fullWidth
            variant="filled"
            margin="dense"
            error={formik.errors.template && formik.touched.template}
          >
            <InputLabel id="template-label">
              Template
            </InputLabel>
            <Select
              labelId="template-label"
              disableUnderline
              id="template"
              {...formik.getFieldProps('template')}
              MenuProps={MenuProps}
            >
              {
                items.map((template) => (
                  <MenuItem
                    key={template.id}
                    value={template.id}
                  >
                    {template.name}
                  </MenuItem>
                ))
              }
            </Select>
            <FormHelperText>
              {formik.touched.template && formik.errors.template}
            </FormHelperText>
          </FormControl>
        }
      </Grid>

      <Grid className={classes.actions} >
        <Button
          variant="contained"
          onClick={_onCancel}
        >
          { _.capitalize(t('cancel')) }
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={formik.handleSubmit}
        >
          { _.capitalize(t('save')) }
        </Button>
      </Grid>
    </Container>
  );
}

export default React.memo(SaveAsTemplateForm);
