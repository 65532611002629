import { useEffect, useState, memo, } from 'react';
import { useSelector, } from 'react-redux';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Notification, } from 'rsuite';
import { Divider, Avatar, ButtonBase, CircularProgress  } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ModalRanking from '../../components/CorpoPlano/new.plano/ModalRanking';

import Ranking from '../../components/CorpoPlano/new.plano/Ranking';
import RankingNotResponse from '../../components/CorpoPlano/new.plano/RankingNotResponse';

import { selectCompany, } from '../../features/companySlice';

import CardMenuLeft from '../../components/Menus/CardMenuLeft';

import { getRanking, listCompanyMeetings, getUsers, } from '../../services/api';


/**
 * Component to show activity rank in company
 *
 * @returns Rendered component
 */
function ActivityRank() {
  const company = useSelector(selectCompany);
  const loadingMatriz = useSelector((state) => state.matriz.loading);
  const actions = useSelector((state) => state.matriz.value);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const className = styles();
  const [rankingItens, setRankingItens] = useState([]);
  const [reunionUnique, setReunionUnique] = useState([]);
  const [name, setName] = useState('');
  const [usersAndActivities, setUsersAndActivities] = useState(null);

  /**
   * Get required data to render the component from the API
   */
  const loadData = async () => {
    try {
      const { data, } = await getUsers();
      if (Array.isArray(data) && !!data.length) {
        await getUsersAndActivities(data);
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar informações do ranking de atividade do servidor!',
      })
    }
  }


  async function getRankingAll(id) {
    setRankingItens(usersAndActivities[id]);

    await listCompanyMeetings().then(({ data }) => {
      setReunionUnique(data);
    });

    await getUsers().then(({ data }) => {
      setUsers(filterUsersCompany(data));
    });
  }

  function filterUsersCompany(data) {
    return data.filter(({ empresa, }) => empresa === company._id);
  }


  async function getUsersAndActivities(users) {
    const filter = (data) => data.filter(
      (item) => (item.resposta !== 'Aceitar') && (item.status !== 'Concluido')
    );

    const usersConpany = filterUsersCompany(users);
    setUsers(usersConpany);

    let updateUsersAndActivities = {};

    const allRequisitions = usersConpany.map(async (user) => {
      const response = await getRanking(user.name);
      updateUsersAndActivities[user._id] = filter(response.data);
    })

    await Promise.all(allRequisitions);

    setUsersAndActivities(updateUsersAndActivities);
    return;
  }

  /**
   * Use effect to initialize data
   */
  useEffect(() => {
    loadData();
  }, [actions, loadingMatriz]);

  const Line = ({ id, name, avatar, divider }) => {
    return (
      !!usersAndActivities &&
      <>
        <ButtonBase className={className.buttomBase} onClick={async () => { setName(name); await getRankingAll(id); setIsOpenModal(true) }}>
          <div className={className.item}>
            <div className={className.contentItens}>
              <div style={{ display: "flex" }}>
                <Avatar alt={name} src={avatar} className={className.avatar} />
                <p>{name}</p>
              </div>
              <p style={{ marginRight: "10px" }}>{'(' + (usersAndActivities[id]?.length || 0) + ')'}</p>
            </div>
          </div>
        </ButtonBase>
        {!divider &&
          <Divider />
        }
      </>
    )
  }


  return (
    <>
      <CardMenuLeft>
        {
          users.map((user, index) => {
            return (
              <Line key={user._id} name={user.name} avatar={user.avatar} totalUsers={users?.length} id={user._id} divider={users.length === (index + 1)} />
            );
          })
        }
      </CardMenuLeft>
      <ModalRanking
        isOpen={isOpenModal}
        onRequestClose={() => setIsOpenModal(false)}
        body={rankingItens}
        quem={name}
        users={users}
        reunionUnique={reunionUnique}
      />
    </>
  );
}


const styles = makeStyles({
  buttomBase: {
    width: "100%",
    justifyContent: "start"
  },
  item: {
    padding: "10px 0px",
    width: "100%"
  },
  contentItens: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  avatar: {
    width: '24px',
    height: '24px',
    marginRight: "10px"
  }
});

export default memo(ActivityRank);
