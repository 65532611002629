import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { red, } from '@material-ui/core/colors';
import { Notification, } from 'rsuite';

import Dropdown from '../Dropdown';
import DeleteDialog from '../DeleteDialog';
import QuestionsTemplateFormDialog from '../QuestionsTemplateFormDialog/index.jsx';
import {
  selectNonGlobalQuestionsTemplates,
  selectCurrentTemplate,
  setCurrentTemplate,
  deleteQuestionsTemplate,
  listQuestionsTemplates
} from '../../features/questionsTemplatesSlice';
import useModal from '../../hooks/useModal';
import { getUserId, } from '../../utils';


/**
 * Questions templates management page toolbar
 *
 * @returns Rendered page
 */
export default function QuestionsTemplateToolbar() {
  const questionsTemplates = useSelector(selectNonGlobalQuestionsTemplates);
  const currentTemplate = useSelector(selectCurrentTemplate);
  const dispatch = useDispatch();

  const [formModalOpen, openFormModal, closeFormModal] = useModal();
  const [deleteConfOpen, openDeleteConf, closeDeleteConf] = useModal();

  /**
   * Handle change in selected questions template
   */
  async function onTemplateSelected(templateId) {
    dispatch(setCurrentTemplate(templateId));
  }

  /**
   * Send request to API to delete questions template
   */
  async function onDelete() {
    closeDeleteConf();

    if (currentTemplate == null) {
      return;
    }

    try {
      await dispatch(deleteQuestionsTemplate({
        userId: getUserId(),
        templateId: currentTemplate.id,
      })).unwrap();

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Template de tarefas excluído com sucesso!',
      });
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao excluir template de tarefas!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }
  }

  function __openDeleteConf() {
    if (currentTemplate != null) {
      openDeleteConf();
    }
  }

  function onCreateQuestionsTemplate() {
    dispatch(setCurrentTemplate(null));
    openFormModal();
  }

  function initialize() {
    dispatch(listQuestionsTemplates({
      userId: getUserId(),
    }));
  }

  useEffect(() => {
    initialize();
  }, [])

  useEffect(() => {
    if(questionsTemplates){
      onTemplateSelected(currentTemplate?.id || questionsTemplates[questionsTemplates.length - 1]?.id);
    }
  }, [!!questionsTemplates?.length]);

  return (
    <>
      {questionsTemplates &&
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <Dropdown
            items={questionsTemplates}
            value={currentTemplate ? currentTemplate?.id : questionsTemplates[questionsTemplates.length - 1]?.id}
            setValue={onTemplateSelected}
            labelKey="name"
          />

          {
            currentTemplate != null ?
              <>
                <IconButton
                  aria-label="edit-questions-template"
                  onClick={openFormModal}
                >
                  <EditIcon />
                </IconButton>

                <IconButton
                  aria-label="delete-questions-template"
                  onClick={__openDeleteConf}
                >
                  <DeleteIcon
                    style={{
                      color: red[500],
                    }}
                  />
                </IconButton>
              </> :
              null
          }

          <IconButton
            aria-label="add-questions-template"
            onClick={onCreateQuestionsTemplate}
          >
            <AddCircleIcon />
          </IconButton>
        </Grid>
      }

      <QuestionsTemplateFormDialog
        open={formModalOpen}
        onClose={closeFormModal}
        templateData={currentTemplate}
      />

      <DeleteDialog
        open={deleteConfOpen}
        confirmationMessage={`Tem certeza que deseja excluir o template de tarefas ${currentTemplate != null ? currentTemplate.name : ''}?`}
        onClose={closeDeleteConf}
        onConfirm={onDelete}
      />
    </>
  );
}
