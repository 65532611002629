import { useState, useEffect, } from 'react';
import { v4 } from 'uuid';
import validator from 'validator';
import { useSelector, } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

import {
  TextField,
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import api, { getCompaines, listCompanyUsers, } from '../../services/api';
import { Notification, } from 'rsuite';

import ToastAnimated, { showToast, } from '../../ui-lib/toast';
import { InputCadastro } from '../../components/InputCadastroUsuario';
import SimpleUsersTable from '../../components/SimpleUsersTable';
import { selectCompany } from '../../features/companySlice';
import { simpleUserUpdate } from '../../services/api';


const useStyles = makeStyles({
  title: {
    color: '#1C265A',
    fontWeight: 'bolder',
    fontSize: '1.2rem',
    fontFamily: 'Inter',
    marginBottom: '2rem',
  },
  addBtnContainer: {
    width: '100%',
    display: 'flex',
    marginTop: '1rem',
  },
  addBtn: {
    textTransform: 'none',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    background: '#3B66FF',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginLeft: '1rem',
  },
  cancelBtn: {
    textTransform: 'none',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    borderColor: '#3B66FF',
    color: '#3B66FF',
    background: 'transparent !important',
    boxShadow: 'none',
    borderWidth: 2,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    border: '2px solid #3B66FF !important',
    '&:hover': {
      background: 'transparent !important',
      border: '2px solid #3B66FF !important',
    },
  },
});


function CreateUser({ onClick, }) {
  const company = useSelector(selectCompany);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [editUser, setEditUser] = useState(null);

  const classes = useStyles();

  const getUsers = async () => {
    setLoadingUsers(true);
    try {
      const { data, } = await listCompanyUsers(company._id);
      setUsers(data);
    } catch {
      showToast({
        type: 'error',
        message: 'Falha ao carregar lista de usuários',
      });
    }
    setLoadingUsers(false);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const handleCreateUsers = async (e) => {
    e.preventDefault();

    if (!name) {
      setNameError('Requer um nome');
    } else {
      setNameError();
    }

    if (!email || !validator.isEmail(email)) {
      setEmailError('Requer um email válido');
    } else {
      setEmailError();
    }

    if (!name || !email || !validator.isEmail(email)) {
      return;
    }

    if (editUser) {
      handlerUpdateUser();
      return;
    }

    try {
      const { data, status, } = await api.post('/singup/consultor', {
        name,
        email,
        password: v4().split('').map(char => {
          return Math.random() > 0.5 ? char.toUpperCase() : char;
        }).join(''),
        empresa: company._id,
        group: company.group,
        admin: false,
        autorizado: true,
        //TODO: change this to use user ID instead of user name
        whoCreated: localStorage.getItem('name'),
        whereCreated: window.location.pathname,
      });

      const toastConfig = {
        type: 'info',
        message: data,
        pauseOnFocusLoss: true,
      };

      if (status === 201) {
        toastConfig.type = 'success';
        toastConfig.message = 'Usuário criado';

        setName('');
        setEmail('');
      }

      showToast(toastConfig);
      await getUsers();
    } catch (error) {
      showToast({
        type: 'error',
        message: `Erro ao criar o usuario  ${error}`,
      });
    }

    getCompaines();
  }

  function handlerSetUser(user) {
    setNameError('');
    setEmailError('');
    setEditUser(user._id);
    setName(user.name);
    setEmail(user.email);
  }

  async function handlerUpdateUser() {
    try {
      const response = await simpleUserUpdate(editUser, {
        name: name,
        email: email,
      });
      hendlerCancelUpdate();
      await getUsers();
      Notification['success']({
        title: 'Usuário atualizado com sucesso',
        placement: 'bottomEnd',
      });
    } catch (error) {
      console.log(error);
      Notification['error']({
        title: 'Falha ao atualizar usuário',
        placement: 'bottomEnd',
      });
    }
  }

  function hendlerCancelUpdate() {
    setName('');
    setEmail('');
    setEditUser(null);
  }

  return (
    <>
      <ToastAnimated />
      <Grid
        container
        justifyContent="space-around"
        spacing={1}
      >
        <Grid
          item
          md={5}
          sm={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>
            <Typography
              variant="h6"
              className={classes.title}
            >
              {!editUser ? 'Adicionar novo usuário' : 'Editar usuário'}
            </Typography>

            <TextField
              margin="dense"
              label="Nome"
              fullWidth
              id="name"
              variant="filled"
              type="text"
              value={name}
              InputProps={{
                disableUnderline: true
              }}
              onChange={(e) => {
                setName(e.target.value)
              }}
              error={nameError}
              helperText={nameError}
            />

            <TextField
              margin="dense"
              label="E-mail"
              fullWidth
              id="email"
              variant="filled"
              type="text"
              value={email}
              InputProps={{
                disableUnderline: true
              }}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              error={emailError}
              helperText={emailError}
            />
          </div>

          <div className={classes.addBtnContainer}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleCreateUsers}
              className={classes.addBtn}
              style={{ marginLeft: 0 }}
            >
              Salvar
            </Button>
            {editUser &&
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                onClick={hendlerCancelUpdate}
                style={{ marginLeft: '1rem' }}
              >
                Cancelar
              </Button>
            }
          </div>
        </Grid>

        <Grid
          item
          sm={12}
          md={7}
          container
          direction="column"
          justifyContent="space-between"
        >
          <Typography
            variant="h6"
            className={classes.title}
          >
            Todos os Usuários
          </Typography>

          <SimpleUsersTable
            users={users}
            getUsers={getUsers}
            setUser={handlerSetUser}
            loading={loadingUsers}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default CreateUser;
