import { useEffect, } from 'react';
import { Container, Paper } from '@material-ui/core';
import Label from '../../components/Global/Label';
import Loading from '../../components/Global/Loading';
import DataMappingAnswerPanel from '../../components/DataMappingAnswerPanel';
import DataMappingAnswerPanelContext from '../../components/DataMappingAnswerPanel/context';
import useBaseDataMappingPanel from '../../hooks/useBaseDataMappingPanel';


export default function RespondMapping() {
  const base = useBaseDataMappingPanel();


  useEffect(() => {
    localStorage.removeItem('setorToken');
  }, []);


  return (
    <>
      <Container
        component={Paper}
        maxWidth="xl"
        style={{
          marginBottom: '2%',
        }}
      >
        {
          base.loading ?
          <Loading value={base.loading} /> :
          <DataMappingAnswerPanelContext.Provider value={base}>
            <DataMappingAnswerPanel />
          </DataMappingAnswerPanelContext.Provider>
        }
      </Container>
    </>
  );
}
