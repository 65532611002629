import { createSlice } from '@reduxjs/toolkit'

export const MatrizDeRisco = createSlice({
  name: 'participants',
  initialState: {
    value: [],
    loading: true,
    title: 'Itens para revisão',
    save: false,
  },
  reducers: {
    resetItens: (state) => {
      state.value = []
    },
    setItens: (state, action) => {
      state.value = action.payload
    },
    setSave: (state, action) => {
      state.save = action.payload
    },
    loadingTrue: (state) => {
      state.loading = true
    },
    loadingFalse: (state) => {
      state.loading = false
    },
    updateTitle: (state, action) => {
      state.title = action.payload
    },
    updateAction(state, action) {
      //? This updatedAction is the action plan item
      const updatedAction = action.payload;
      const index = state.value.findIndex(({ id, }) => id === updatedAction.id);
      if (index > -1) {
        state.value[index] = updatedAction;
      }
    }
  },
})

export const {
  setItens,
  resetItens,
  updateTitle,
  loadingFalse,
  loadingTrue,
  setSave,
  updateAction,
} = MatrizDeRisco.actions;

export default MatrizDeRisco.reducer;
