import { useEffect, useState } from "react";
import moment from "moment";

import { getHistoricoObservacoes, } from "../../services/api";
import "./styles.css";

import { Container, Paper, } from "@material-ui/core";
import { Icon } from "rsuite";
import { Badge } from "react-bootstrap";

import { ModalPlanoAcao2, } from "../../components/CorpoMon/ModalCorpoPlanAcao2";


function getStatusColor(status) {
  if (status === "Pendente") {
    return "StatusPendente";
  } else if (status === "Em andamento") {
    return "StatusAndamento";
  } else if (status === "Concluido") {
    return "StatusConcluido";
  }

  return "";
}


export default function ItensSelicionadosPauta({ index, acao, users, reunion, }) {
  const [observacoes, setObservacoes] = useState([]);
  const [classRisck, setClassRisck] = useState("");
  const [nameRisck, setNameRisck] = useState("");
  const [openModalEditItens, setOpenModalEditItens] = useState(false);

  const statusColor = getStatusColor(acao.status);


  function HandlerCloseModalEditItens() {
    setOpenModalEditItens(false);
  }

  useEffect(() => {
    if (acao.classificacao_risco === "Alto") {
      setClassRisck("Alto");
      setNameRisck("Alto");
    } else if (acao.classificacao_risco === "Médio") {
      setClassRisck("Medio");
      setNameRisck("Medio");
    } else if (acao.classificacao_risco === "Baixo") {
      setClassRisck("Baixo");
      setNameRisck("Baixo");
    } else {
      setClassRisck("SemRisco");
      setNameRisck("Não definido");
    }
  }, []);

  const getObs = () => {
    getHistoricoObservacoes(acao.id).then(({ data }) => {
      setObservacoes(data);
    });
  };

  useEffect(() => {
    getObs();
  }, []);


  return (
    <Container
      component={Paper}
      maxWidth="xl"
      style={{
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#54708C",
        borderRadius: 8,
        marginBottom: 20,
      }}
      className="ContainerAllItens"
    >
      <ModalPlanoAcao2
        isOpen={openModalEditItens}
        onRequestClose={HandlerCloseModalEditItens}
        users={users}
        reunionUnique={reunion}
        observacao={observacoes}
        data={acao}
      />
      <div className="boxAllItens">
        <div className="infoHeader">
          <h2 className="indexIten">Item: {index + 1}</h2>
          <Icon
            onClick={() => {
              setOpenModalEditItens(true);
            }}
            className="EditItem"
            icon="edit"
            size="lg"
          />
        </div>
        <div className="BoxProblem">
          <span>{acao.problema}</span>
        </div>
        <div className="BoxRiscoAcao">
          <p>Riscos / Ação:</p>
          <span>{acao.acao}</span>
        </div>
        <div className="BoxComoFeito">
          <p>Como será feito:</p>
          <span>{acao.como}</span>
        </div>
        <div className="BoxHistorico">
          <p>Histórico:</p>
          {observacoes.map((obs) => {
            return (
              <div className="BoxObs" key={obs.id}>
                <Badge className="BoxDataObs" bg="dark">
                  {moment(obs.data).format("DD/MM/YYYY HH:MM")}
                </Badge>
                - {obs.observacao}
              </div>
            );
          })}
        </div>
        <div className="InformacoesBotton">
          <div className="BoxQuem">
            <p>Responsável:</p>
            <span>{acao.quem}</span>
          </div>

          <div className="BoxData">
            {acao.quando && (
              <>
                <p>Data:</p>
                <span>{moment(acao.quando).format("L")}</span>
              </>
            )}
          </div>
          <div className="BoxRisco">
            <p>Risco:</p>
            <div className={classRisck}>{nameRisck}</div>
          </div>
          <div className="BoxComo">
            <p>Status:</p>
            <div className={statusColor}>{acao.status}</div>
          </div>
        </div>
      </div>
    </Container>
  );
}
