import React from 'react'
import { makeStyles } from '@material-ui/styles'

import {
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    background: '#F2F4F8',
    borderRadius: 5,
    height: '50px',
    marginBottom: '0.5rem',
    '& .MuiFormControlLabel-root': {
      margin: '0 !important',
      color: '#1C265A',
    },
  },
  root: {
    margin: '0',
    '&$checked': {
      color: '#1C265A !important',
    },
  },
})

function CheckboxLabels({ checked }) {
  const classes = useStyles()

  const option =
    checked === 'true ' ||
    checked === '1' ||
    checked === 'true'

  return (
    <FormGroup row className={classes.container}>
      <FormControlLabel
        control={
          <Checkbox
            checked={option}
            color="primary"
            className={classes.root}
          />
        }
        label={option ? 'Sim' : 'Não'}
      />
    </FormGroup>
  )
}

export default CheckboxLabels
