import React from 'react';
import { useTranslation, } from 'react-i18next';
import { useSelector, } from 'react-redux';

import { Grid, } from '@material-ui/core';

import BaseWidget from './BaseWidget';
import MetricCard from './components/MetricCard';

import { DashboardWidgetType, } from '../../../core/entities/dashboard-widget';

import { getCompanyDataMappingStats, } from '../../../services/api';

import { selectCompany, } from '../../../features/companySlice';


function DataMappingReportWidget(props) {
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);


  async function initialize() {
    setLoading(true);
    try {
      const result = await getCompanyDataMappingStats(company._id);
      setData([
        {
          value: result.data.mappedSectors,
          label: t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.DATA_MAPPING_REPORT}.mapped_sectors`),
        },
        {
          value: result.data.mappedProcessingAgents,
          label: t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.DATA_MAPPING_REPORT}.mapped_processing_agents`),
        },
        {
          value: result.data.mappedCollaborators,
          label: t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.DATA_MAPPING_REPORT}.mapped_collaborators`),
        },
        {
          value:  result.data.mappedPersonalDataTypes,
          label: t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.DATA_MAPPING_REPORT}.mapped_personal_data_types`),
        },
        {
          value: result.data.mappedSensitiveDataCategories,
          label: t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.DATA_MAPPING_REPORT}.mapped_sensitive_data_categories`),
        },
        {
          value:  result.data.mappedGaps,
          label: t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.DATA_MAPPING_REPORT}.mapped_gaps`),
        },
      ]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    initialize();
  }, []);


  return (
    <BaseWidget
      {...props}
      loading={loading}
    >
      <Grid
        container
        style={{
          padding: '0.5rem',
        }}
      >
        {
          data
            .map(({ value, label, }, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={6}
                  lg={4}
                  style={{
                    paddingLeft: '0.5rem',
                    paddingBottom: '0.5rem',
                  }}
                >
                  <MetricCard
                    value={value}
                    label={label}
                  />
                </Grid>
              );
            })
        }
      </Grid>
    </BaseWidget>
  );
}

export default DataMappingReportWidget;
