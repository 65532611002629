import { useState, useEffect, } from "react";
import { useSelector, } from 'react-redux';

import { Form, Button, } from "react-bootstrap";
import { Notification, } from "rsuite";

import { BoxBTNAll, InputTitleWorckshopDescription, MainWorkshopInputDescription, } from "./styles";

import api, { GetConstituicaoByWorkShop, } from "../../../../services/api";

import { selectCompany, } from "../../../../features/companySlice";


export function ContainerDescriptionWorkshop({ id, }) {
  const { _id: idEmpresa, } = useSelector(selectCompany);
  const [description, setDescription] = useState("");


  async function getConstituicao() {
    await GetConstituicaoByWorkShop().then(({ data }) => {
      if (!data[0]) {
        setDescription("");
      } else {
        const size = data.length
        setDescription(data[size -1 ].constituicao);
      }
    });
  }

  async function InsertConstituicao() {
    if (!description)
      return Notification["warning"]({
        title: "Preencha a descrição",
        placement: "bottomEnd",
      });

    let update = false;
    description !== "" ? (update = true) : (update = false);
    await api
      .post("/workshop/step?step=comite", {
        id: id,
        idEmpresa: idEmpresa,
        constituicao: description,
        update,
        idWorkshop: id,
      })
      .then(() => {
        Notification["success"]({
          title: "Constituição adicionada com sucesso",
          placement: "bottomEnd",
        });
      })
      .catch(() => {
        Notification["error"]({
          title: "Erro ao adicionar Constituição",
          placement: "bottomEnd",
        });
      });
  }

  useEffect(() => {
    getConstituicao();
  }, []);


  return (
    <MainWorkshopInputDescription>
      <InputTitleWorckshopDescription>
        <h1>Descrição</h1>
      </InputTitleWorckshopDescription>

      <form>
        <p>
          Informe aos integrantes do comitê de Privacidade e Proteção de Dados,
          quais as responsabilidades de cada um neste projeto
        </p>
        <Form.Group
          className="mb-3"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label></Form.Label>
          <Form.Control
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            as="textarea"
            rows={5}
            placeholder="Digite aqui uma descrição"
          />
        </Form.Group>
        <BoxBTNAll>
        <Button onClick={InsertConstituicao}>
          Salvar descrição
        </Button>
        </BoxBTNAll>
      </form>
    </MainWorkshopInputDescription>
  );
}
