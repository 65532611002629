import React, { useState } from 'react';

import DpoContext from './context';

import { Notification, } from 'rsuite';

//? Cookie banners
const COMPLETE_COOKIE_MANAGEMENT = Object.freeze({
    title: 'Banner para gestão de cookies',
    warnMessage: 'Atenção: Fornece a opção para o usuário aceitar ou rejeitar todos os cookies do site.',
    url: process.env.REACT_APP_OCTO_ENV === 'prod' ? 'https://c.octo.legal/popup.js' : 'https://dev.c.octo.legal/popup.js',
});

export default function MappingContext({ children }) {

    const [modalCode, setModalCode] = useState(false);

    const CONFIG = COMPLETE_COOKIE_MANAGEMENT;

    const url = CONFIG.url;
    
    /**
     * Copy script to clipboard
     */
    const onCopyScript = async (popupId) => {
        const __script = `<meta name="octo-website-id" content="${popupId}" />
  <script src="${url}" type="module"></script>`;
  
        await navigator.clipboard.writeText(__script);

        Notification['success']({
            placement: 'bottomEnd',
            title: 'Código copiado com sucesso!',
        });
    }

    return (
        <DpoContext.Provider
            value={{
                modalCode,
                setModalCode,
                onCopyScript
            }}
        >
            {children}
        </DpoContext.Provider>
    );
}
