import React from 'react';
import { useTranslation, } from 'react-i18next';

import {
  Container,
  Divider as MUIDivider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { WidgetType, } from '../../../entities/impact-report-widget';


function Divider() {
  return (
    <MUIDivider
      style={{
        margin: '2rem 0.5rem 0.5rem 0.5rem',
        backgroundColor: '#1C265A',
      }}
    />
  );
}


const EditableSignerCard = React.forwardRef(({ signer, onChange, onRemoveSigner, }, ref) => {
  const { t, } = useTranslation();


  return (
    <Container
      maxWidth="xl"
      component={Paper}
      style={{
        outline: '1px solid #3B66FFCC',
        padding: 0,
      }}
      ref={ref}
    >
      <Grid
        container
        style={{
          padding: '0.4rem',
          backgroundColor: '#3B66FF3B',
        }}
        alignItems="center"
        justifyContent="center"
        className="signerCardHeader"
      >
        <Grid
          item
          xs={true}
        >
          <input
            value={signer.agentTitle}
            onChange={onChange}
            name="agentTitle"
            style={{
              backgroundColor: 'transparent',
              border: '1px solid #AAA',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              textAlign: 'center',
              width: '100%',
              color: '#1C265A',
              fontSize: '12px',
              fontFamily: 'Roboto',
            }}
          />
        </Grid>

        <IconButton
          onClick={onRemoveSigner}
          size="small"
        >
          <HighlightOffIcon
            fontSize="small"
          />
        </IconButton>
      </Grid>

      <Divider />

      <Grid
        container
        direction="column"
        style={{
          padding: '0 0.5rem 0.5rem 0.5rem',
        }}
      >
        <input
          value={signer.name}
          onChange={onChange}
          name="name"
          style={{
            backgroundColor: 'transparent',
            border: '1px solid #AAA',
            textAlign: 'center',
            width: '100%',
            margin: '0.25rem 0',
            fontWeight: 400,
            fontSize: '12px',
            fontFamily: 'Roboto',
            color: '#54708C',
          }}
        />

        <Grid
          container
          direction="row"
          alignItems="center"
        >
          <Grid item >
            <Typography
              align="center"
              variant="caption"
              style={{
                color: '#54708C',
              }}
            >
              {t(`PAGES.IMPACT_REPORTS.v2.WIDGETS.${WidgetType.SIGNATURES}.registration_id_label`)}:
            </Typography>
          </Grid>

          <Grid
            item
            xs={true}
          >
            <input
              value={signer.registrationId}
              onChange={onChange}
              name="registrationId"
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #AAA',
                width: '100%',
                margin: '0.25rem 0',
                fontWeight: 400,
                fontFamily: 'Roboto',
                fontSize: '12px',
                color: '#54708C',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
})


const ReadOnlySignerCard = React.forwardRef(({ signer, }, ref) => {
  const { t, } = useTranslation();


  return (
    <Container
      maxWidth="xl"
      component={Paper}
      style={{
        outline: '1px solid #3B66FFCC',
        padding: 0,
      }}
      ref={ref}
    >
      <Grid
        container
        style={{
          padding: '0.4rem',
          backgroundColor: '#3B66FF3B',
        }}
        alignItems="center"
        justifyContent="center"
        className="signerCardHeader"
      >
        <Typography
          align="center"
          variant="overline"
          style={{
            fontWeight: 'bold',
            lineHeight: '1.4em',
            color: '#1C265A',
          }}
        >
          {signer.agentTitle}
        </Typography>
      </Grid>

      <Divider />

      <Typography
        align="center"
        variant="caption"
        display="block"
        style={{
          color: '#54708C',
        }}
      >
        {signer.name}
      </Typography>

      <Typography
        align="center"
        variant="caption"
        display="block"
        style={{
          color: '#54708C',
        }}
      >
        {t(`PAGES.IMPACT_REPORTS.v2.WIDGETS.${WidgetType.SIGNATURES}.registration_id_label`)}: {signer.registrationId}
      </Typography>
    </Container>
  );
})


const SignerCard = React.forwardRef(({ signer, readOnly, onChange, onRemoveSigner, }, ref) => {
  function _onChange(ev) {
    onChange({
      ...signer,
      [ev.target.name]: ev.target.value,
    });
  }

  return (
    readOnly ?
    <ReadOnlySignerCard
      signer={signer}
      ref={ref}
    /> :
    <EditableSignerCard
      signer={signer}
      ref={ref}
      onChange={_onChange}
      onRemoveSigner={onRemoveSigner}
    />
  );
});

export default SignerCard;
