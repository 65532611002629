import React, { useState, useRef, } from 'react';

import { Form, } from 'react-bootstrap';
import { makeStyles, } from '@material-ui/styles';

import BaseField from './BaseField';


const useStyles = makeStyles(() => ({
  popover: {
    zIndex: 100000000000000,
    backgroundColor: 'white',
    border: '1px solid #00000055',
    position: 'absolute',
    overflowY: 'auto',
    borderEndEndRadius: 5,
    borderEndStartRadius: 5,
    borderTop: '0px',
  },
  listItem: {
    paddingLeft: '0.6rem',
    paddingRight: '0.6rem',
    paddingBottom: '0.1rem',
    paddingTop: '0.1rem',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#0e7fe1',
      color: 'white',
    },
  },
}));


/**
 * It uses the same props as BaseField component
 *
 * @param {function} props.updateOptions - callback to update field options
 */
export default function SelectField(props) {
  const ref = useRef();
  const classes = useStyles();
  const [showOptions, setShowOptions] = useState(false);


  function closeOptions() {
    setShowOptions(false);
  }

  function openOptions() {
    setShowOptions(true);
  }


  return (
    <BaseField
      {...props}
      render={(resp, onChangeResp) => {
        const boundingRect = showOptions ? ref.current.getBoundingClientRect() : undefined;

        return (
          <div onBlur={closeOptions} style={{width: '100%'}}>
            <Form.Control
              placeholder="Escolha um valor..."
              value={resp}
              onChange={(event) => onChangeResp(event.target.value)}
              onFocus={openOptions}
              onClick={openOptions}
              onTouchEnd={openOptions}
              ref={ref}
            />
            {
              showOptions ?
              <div
                className={classes.popover}
                style={{
                  left: boundingRect.left,
                  top: boundingRect.top + boundingRect.height + window.scrollY,
                  minWidth: boundingRect.width,
                  maxHeight: Math.min(500, window.innerHeight - boundingRect.top - boundingRect.height - 20),
                }}
              >
                {
                  (props.field.options || []).map(({ id, title, }) => {
                    return (
                      <div
                        key={id}
                        className={classes.listItem}
                        onMouseDown={() => {
                          onChangeResp(title);
                          closeOptions();
                        }}
                      >
                        { title }
                      </div>
                    );
                  })
                }
              </div> :
              null
            }
          </div>
        );
      }}
    />
  );
}
