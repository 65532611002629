import React from "react";
import Dropdown from "../../Dropdown";

export default function SelectUsers({ items, value = null, setValue = () => { } }) {

    function handlerSetValue(value) {
        if (typeof setValue === 'function') {
            setValue(value);
        }
    }

    return (
        <Dropdown
            defaultItem="Escolha um responsável"
            items={items}
            value={value}
            labelKey="name"
            setValue={handlerSetValue}
            valueKey={'_id'}
        />
    );
}