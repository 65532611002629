import React, { memo, } from 'react';
import { useHistory, } from 'react-router-dom';

import moment from 'moment';
import _ from 'lodash';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckedIcon from '@material-ui/icons/CheckCircleOutline';
import UncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

import Table from '../../components/Table';


const HEADERS = Object.freeze([
  {
    label: 'Nome',
    align: 'center',
  },
  {
    label: 'Grupo',
    align: 'center',
  },
  {
    label: 'Criada por',
    align: 'center',
  },
  {
    label: 'Criada em',
    align: 'center',
  },
  {
    label: 'Configurações',
    align: 'center',
  },
  {
    label: 'Acessar',
    align: 'center',
  },
  {
    label: 'Ativa',
    align: 'center',
  },
  {
    label: 'Deletar',
    align: 'center',
  },
]);


/**
 * Pane used to show company data in the admin console
 *
 * @returns Rendered pane
 */
function CompaniesTable({
  companies,
  onDelete,
  groups,
  onOpenConfigModal,
  loading,
  toggleCompanyActiveState,
  showError,
}) {
  const history = useHistory();


  /**
   * Set data to access company
   *
   * @param {object} company - company to access
   */
  function accessCompany(company) {
    history.push(`/companies/${company._id}`);
  }

  function callIfDefined(func, ...args) {
    if (_.isFunction(func)) {
      func(...args);
    }
  }


  return (
    <Table
      headers={HEADERS}
      data={companies}
      loading={loading}
      errorMessage="Falha ao carregar empresas"
      noDataMessage="Nenhuma empresa encontrada"
      showError={showError}
    >
      {
        (company, props) => {
          const group = groups.find(({id}) => id === company.group);

          return (
            <TableRow {...props}>
              <TableCell align="center">
                { company.name }
              </TableCell>
              <TableCell align="center">
                { ((group != undefined) && (group.name != undefined)) ? group.name : '' }
              </TableCell>
              <TableCell align="center">
                { company.owner }
              </TableCell>
              <TableCell align="center">
                { moment(company.createdAt).format('DD/MM/YYYY, HH:MM:ss') }
              </TableCell>
              <TableCell align="center">
                <IconButton
                  onClick={() => callIfDefined(onOpenConfigModal, company)}
                >
                  <SettingsIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">
                <Button
                  onClick={() => accessCompany(company)}
                  color="primary"
                  variant="outlined"
                  disableElevation
                  size="small"
                >
                  Acessar
                </Button>
              </TableCell>
              <TableCell align="center">
                <IconButton
                  onClick={() => callIfDefined(toggleCompanyActiveState, company)}
                >
                  { company.active ?  <CheckedIcon /> : <UncheckedIcon /> }
                </IconButton>
              </TableCell>
              <TableCell align="center">
                <IconButton
                  onClick={() => callIfDefined(onDelete, company)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          )
        }
      }
    </Table>
  );
}

export default memo(CompaniesTable);
