import { ContentInfoLeft } from "./styles";
import InitialWorkshopImg from '../../../assets/workoshop_new_ilustra.svg'
export function ContainerLeft() {
  return (
    <ContentInfoLeft >
      <img src={InitialWorkshopImg} alt="Executivo de terno Ilustração" />
      <h1>Por que é necessário manter todos informados</h1>
      <p>Para iniciar um Programa de Privacidade e Proteção de Dados é importante um
        Workshop de Sensibilização participando todos os colaboradores da Organização.
      </p>
    </ContentInfoLeft>
  );
}