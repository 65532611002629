import React from 'react';

import Sheet from '../../components/Sheet';
import DPOChannel from '.';

const PAGES = Object.freeze({
    dpo: {
      id: 'dpo',
      titleTab: "DPO",
      titlePage: "DPO",
      component: <DPOChannel />,
    },
});

const ARRAY_PAGES = Object.values(PAGES);

export default function MapDPO() {

  return (
    <Sheet pages={ARRAY_PAGES} bgColor='transparent'/>
  );
}
