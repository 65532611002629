import React from 'react';

import Sheet from '../../components/Sheet';
import RiskAnalysis from '.';

const PAGES = Object.freeze({
    RiskAnalysis: {
      id: 'RiskAnalysis',
      titleTab: "Matriz de risco",
      titlePage: "Matriz de risco",
      component: <RiskAnalysis />,
    },
});

const ARRAY_PAGES = Object.values(PAGES);

export default function MapAnaliseDeRiscoInicial() {

  return (
    <Sheet pages={ARRAY_PAGES} />
  );
}
