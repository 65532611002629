import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Notification, } from 'rsuite';
import { t, } from 'i18next';

import * as api from '../services/api';
import { CRUD_STATUSES, } from '../utils/constants';
import { getCompanyId, } from '../utils';


const initialState = {
  shareLinks: [],
  shareLinksMap: {},
  status: CRUD_STATUSES.IDLE,
};


export const listShareLinks = createAsyncThunk('shareLinks/fetch', async (_, thunkAPI) => {
  const { data, status, } = await api.listCompanyMaturityChecklistShareLinks(
    thunkAPI.getState().company.company._id);
  if (200 === status) {
    return data.links;
  } else {
    Notification['warning']({
      placement: 'bottomEnd',
      title: _.capitalize(t('ERRORS.MATURITY_CHECKLIST_SHARE_LINKS.failed_to_load_share_links')),
      description: _.capitalize(t('ERRORS.try_again_later')),
    });
  }
});

export const createShareLink = createAsyncThunk('shareLinks/create', async (shareLinkData, thunkAPI) => {
  const { data, } = await api.createCompanyMaturityChecklistShareLink(
    thunkAPI.getState().company.company._id, shareLinkData);
  return data;
});

export const updateShareLink = createAsyncThunk('shareLinks/update', async (shareLinkData, thunkAPI) => {
  const { data, } = await api.updateCompanyMaturityChecklistShareLink(
    thunkAPI.getState().company.company._id, shareLinkData.id, shareLinkData);
  return data;
});

export const deleteShareLink = createAsyncThunk('shareLinks/delete', async (shareLinkId, thunkAPI) => {
  const { status, } = await api.deleteCompanyMaturityChecklistShareLink(
    thunkAPI.getState().company.company._id, shareLinkId);
  return 200 === status ? shareLinkId : null;
});

export const shareLinksSlice = createSlice({
  name: 'shareLinks',
  initialState,
  reducers: {
    setStatus: (state, { payload: status, }) => {
      if (status != null) {
        state.status = status;
      } else {
        state.status = initialState.status;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listShareLinks.pending, (state) => {
      state.status = CRUD_STATUSES.LOADING;
    }).addCase(listShareLinks.fulfilled, (state, { payload: shareLinks, }) => {
      state.status = CRUD_STATUSES.FETCH_SUCCESSFUL;
      state.shareLinks = shareLinks;
      state.shareLinksMap = Object.fromEntries(shareLinks.map((shareLink) => [shareLink.id, shareLink]));
    }).addCase(listShareLinks.rejected, (state) => {
      state.status = CRUD_STATUSES.FETCH_FAILED;
      state.shareLinks = initialState.shareLinks;
    });

    builder.addCase(createShareLink.fulfilled, (state, { payload: shareLink, }) => {
      state.shareLinks.push(shareLink);
      state.shareLinksMap[shareLink.id] = shareLink;
    });

    builder.addCase(updateShareLink.fulfilled, (state, { payload: shareLink, }) => {
      const index = state.shareLinks.findIndex(({ id, }) => id === shareLink.id);
      if (index > -1) {
        state.shareLinks[index] = shareLink;
        state.shareLinksMap[shareLink.id] = shareLink;
      }
    });

    builder.addCase(deleteShareLink.fulfilled, (state, { payload: shareLinkId, }) => {
      const index = state.shareLinks.findIndex(({ id, }) => id === shareLinkId);
      if (index > -1) {
        state.shareLinks.splice(index, 1);
        delete state.shareLinksMap[shareLinkId];
      }
    });
  }
});

/**
 * Custom select to get status
 *
 * @param {object} state - share links slice state
 *
 * @returns the feature status
 */
export const selectFeatureStatus = (state) => {
  return state.maturityChecklistShareLinks.status;
}

/**
 * Custom select to get share links
 *
 * @param {object} state - share links slice state
 *
 * @returns shareLinks
 */
export const selectShareLinks = (state) => {
  return state.maturityChecklistShareLinks.shareLinks;
}

export const selectShareLinkById = (state, shareLinkId) => {
  return state.maturityChecklistShareLinks.shareLinksMap[shareLinkId];
}

/**
 * Custom select to get share links
 *
 * @param {object} state - share links slice state
 *
 * @returns shareLinks
 */
export const selectLoadingShareLinks = (state) => {
  return (CRUD_STATUSES.FETCH_SUCCESSFUL != state.maturityChecklistShareLinks.status) &&
    (CRUD_STATUSES.FETCH_FAILED != state.maturityChecklistShareLinks.status);
}

export const {
  setStatus,
  setShareLinksModal,
} = shareLinksSlice.actions;
export default shareLinksSlice.reducer;
