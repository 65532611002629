import React from 'react';

import Sheet from '../../components/Sheet';
import RiskReport from './AcoesPorRisco';

const PAGES = Object.freeze({
    riskReport: {
      id: 'riskReport',
      titleTab: "Relatorio de riscos",
      titlePage: "Relatorio de riscos",
      component: <RiskReport />,
    },
});

const ARRAY_PAGES = Object.values(PAGES);

export default function MapAcoesPorRisco() {

  return (
    <Sheet pages={ARRAY_PAGES} />
  );
}
