import { useEffect, useState, } from 'react';
import { useParams, } from 'react-router-dom';
import { Container, AppBar, Toolbar, Typography, Paper, } from '@material-ui/core';
import { getPublicCompany, getCourse, getPublicUser, } from '../../services/api';
import { Notification, } from 'rsuite';
import Course from '../../components/DPO/Course';
import CourseTest from '../../components/DPO/CourseTest';
import useQuery from '../../hooks/useQuery';


export default function PublicCoursePage() {
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState({
    name: '',
  });
  const [course, setCourse] = useState({});
  const [author, setAuthor] = useState({});

  const { courseId, } = useParams();
  const collaboratorExternalId = useQuery().get('t');


  /**
   * Fetch component data from API
   */
  const getData = async () => {
    setLoading(true);

    try {
      const { data: course, } = await getCourse(courseId);
      setCourse(course);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar curso!',
        description: 'Tente novamente dentro de alguns instantes ou entre em contato com o suporte.',
      });
    }

    setLoading(false);
  }

  /**
   * Fetch author info from the API
   */
  const getCourseAuthor = async () => {
    try {
      const { data, } = await getPublicUser(course.author_id);
      setAuthor(data);
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Fetch course company info from the API
   */
  const getCourseCompany = async () => {
    try {
      const { data, } = await getPublicCompany(course.company_id);
      setCompany(data);
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Use effect to update author and company info
   */
  useEffect(() => {
    if (undefined != course) {
      if (course.company_id != company._id) {
        getCourseCompany();
      }

      if (course.author_id != author._id) {
        getCourseAuthor();
      }
    }
  }, [course]);

  /**
   * Use effect to initialize component data
   */
  useEffect(() => {
    getData();
  }, []);


  return (
    <Container
      maxWidth="xl"
      disableGutters
    >
      <AppBar
        position="static"
        style={{
          backgroundColor: '#1C265A',
          marginBottom: '0.5rem',
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            style={{
              marginLeft: '47%',
              fontFamily: 'Roboto',
              fontWeight: 'bold',
            }}
          >
            OCTO | { company.name }
          </Typography>
        </Toolbar>
      </AppBar>

      {
        (collaboratorExternalId == null) || loading ?
        <Container
          maxWidth="xl"
          component={Paper}
          style={{
            paddingTop: '2rem',
            paddingBottom: '2rem',
          }}
        >
          <Typography
            variant="body1"
            align="center"
            display="block"
            color={!loading ? "error" : undefined}
          >
            {
              loading ? 'Carregando' : 'ID de colaborador inválido'
            }
          </Typography>
        </Container> :
        <>
          <Course
            course={course}
            author={author}
          />

          {
            course.test && course.test.questions && course.test.questions.length > 0 ?
            <>
              <br />

              <CourseTest course={course} />

              <br />
            </> :
            null
          }
        </>
      }
    </Container>
  );
}
