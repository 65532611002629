import { useState, useEffect, } from 'react';
import { useSelector, } from 'react-redux';
import { Badge } from 'react-bootstrap'
import { Icon } from 'rsuite'
import ModalConfigUser from '../../pages/DashboardMaster/modalConfigUsers'


export default function PainelConsoleMaster({
  body,
  logs,
}) {
  const [openConfigUser, setOpenConfigUser] = useState(false);
  const { groups, } = useSelector(state => state.groups);
  const [userGroup, setUserGroup] = useState({
    id: null,
    name: '',
  });

  useEffect(() => {
    if (userGroup.id != null) {
      return;
    }

    const group = groups.find(({id}) => id === body.group);
    if (group != undefined) {
      setUserGroup(group);
    }
  }, [groups]);

  return (
    <>
      <tbody>
        <tr>
          <td>
            <Badge>{body.name}</Badge>
          </td>

          <td>
            <Badge>{userGroup.name}</Badge>
          </td>

          <td className="configIcon">
            <Icon
              icon="cog"
              size="lg"
              onClick={() => {
                setOpenConfigUser(true)
              }}
            />
          </td>
          <td className="DelteIcon">
            <Icon icon="trash" size="lg" />
          </td>
        </tr>
      </tbody>

      <ModalConfigUser
        open={openConfigUser}
        onClose={() => setOpenConfigUser(false)}
        infoUsers={body}
        logs={logs}
      />
    </>
  );
}
