import { memo, } from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { selectProcessingAgentsByType, } from '../../features/processingAgentsSlice';


const useStyles = makeStyles({
  table: {
    maxHeight: 300,
  },
});


function ProcessingAgentsTablePerType({ type, }) {
  const classes = useStyles();
  //! Type filtering temporally removed
  const agents = useSelector((state) => selectProcessingAgentsByType(state));


  return (
    <TableContainer className={classes.table} >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Nome
            </TableCell>
            <TableCell>
              CNPJ
            </TableCell>
            <TableCell>
              Nome do DPO
            </TableCell>
            <TableCell>
              E-mail
            </TableCell>
            <TableCell>
              Número de contato
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            agents.map((item) => (
              <TableRow key={item.id}>
                <TableCell
                  component="th"
                  scope="row"
                >
                  {item.name}
                </TableCell>
                <TableCell>
                  { item.company_identification }
                </TableCell>
                <TableCell>
                  { item.dpo_name }
                </TableCell>
                <TableCell>
                  { item.email }
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                >
                  { item.phoneNumber }
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(ProcessingAgentsTablePerType);
