//! When updating this entity, remember to update its version in other repos

import { normalizeString, } from '../../utils';


export const ALLOWED_DOCUMENT_EXTENSION = Object.freeze([
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'doc',
  'docx',
  'pdf',
  'doc',
  'dot',
  'txt',
  'ods',
  'odp',
  'odt',
  'ott',
  'md',
  'csv',
  'jpg',
  'jpeg',
  'png',
  'svg',
  'bmp',
  'zip',
]);


export default class Document {
  static ALLOWED_SYMBOLS = new RegExp(/[a-z\s\d\(\)\_\-\,\.\{\}\[\]ªº°–&!]/g);
  static FILENAME_VALIDATION_REGEX = new RegExp(/^[a-z\s\d\(\)\_\-\,\.\{\}\[\]ªº°–&!]{1,255}$/g);
  static EXT_REGEX = new RegExp(/\.[a-z\d]+$/g);

  static normalizeFilename(filename) {
    return normalizeString(filename.trim().toLowerCase());
  }

  static getFilenameExtension(filename) {
    return ((filename
      .trim()
      .toLowerCase()
      .match(Document.EXT_REGEX) || [])[0] || '')
      .replace('.', '');
  }

  static getNormalizedFilenameName(filename) {
    const ext = Document.getFilenameExtension(filename);;
    const normalizedFilename = filename.trim().toLowerCase();
    return normalizeString(normalizedFilename.replace(`.${ext}`, ''));
  }

  static validateFileName(filename) {
    const validNameOrError = Document.validateFilenameName(filename);
    if (validNameOrError.isError()) {
      return false;
    }

    const validExtensionOrError = Document.validateFilenameExtension(filename);
    if (validExtensionOrError.isError()) {
      return false;
    }

    return true;
  }

  static validateFilenameName(filename) {
    const normalizedName = Document.getNormalizedFilenameName(filename);
    if (0 !== normalizedName.search(Document.FILENAME_VALIDATION_REGEX)) {
      return false;
    }
    return true;
  }

  static validateFilenameExtension(filename) {
    const ext = Document.getFilenameExtension(filename);
    if (!ALLOWED_DOCUMENT_EXTENSION.includes(ext)) {
      return false;
    }
    return true;
  }

  static getInvalidSymbolsInFilename(filename) {
    const normalizedFilename = Document.normalizeFilename(filename);
    return [...new Set(normalizedFilename.replace(Document.ALLOWED_SYMBOLS, ''))];
  }
}
