import React from 'react';

import BaseWidget from './BaseWidget';
import ProgressBar from '../../ProgressBar';


function CompanyProgressWidget(props) {
  return (
    <BaseWidget {...props} >
      <ProgressBar disableElevation />
    </BaseWidget>
  );
}

export default CompanyProgressWidget;
