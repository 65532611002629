import React from 'react';
import { Dialog, } from '@material-ui/core';
import { Icon, } from 'rsuite';

import styles from './styleDescription.module.scss';
import RegisterMeeting from '../../../RegisterMeeting/RegisterMeeting';


export default function ModalNewReunionWorkshop({ openModal, closeModal, }) {
  return (
    <Dialog open={openModal} onClose={closeModal}>
      <div className={styles.boxTitle}>
        <h5>Registrar nova reunião</h5>
        <Icon icon="close" onClick={closeModal} />
      </div>
      <RegisterMeeting
        onNew={closeModal}
        hideTitle
      />
    </Dialog>
  );
}
