import React, { useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import Loading from '../../components/Global/Loading';
import Header from '../../components/RiscoAnaliseComponent/Header';
import RiscContainerShow from '../../components/RiscoAnaliseComponent/RiscContainerShow';

import { setItens, loadingFalse, updateTitle, } from '../../features/matrizDeRisco';
import { updateReunion } from '../../features/reunionsSlice';

import { getRisco, listCompanyMeetings, } from '../../services/api';


export default function RiskAnalysis() {
  const dispacth = useDispatch()
  const loading = useSelector((state) => state.matriz.loading);


  async function getData() {
    await getRisco(true, 1).then(({ data }) => {
      const filtered = data.risco.filter(
        (item) => item.resposta !== 'Aceitar'
      )
      dispacth(setItens(filtered))
      dispacth(updateTitle(`Itens para revisão`))
    })
    await listCompanyMeetings().then(({ data }) => {
      dispacth(updateReunion(data))
    })
    dispacth(loadingFalse())
  }

  useEffect(() => {
    getData();
  }, []);


  return (
    <>
      <Header />
      {
        loading ?
        <Loading value={loading} /> :
        <RiscContainerShow />
      }
    </>
  );
}
