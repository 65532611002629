import React from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import { Grid, } from '@material-ui/core';
import { IconButton, Icon, } from 'rsuite';
import { DragDropContext, Droppable, Draggable, } from 'react-beautiful-dnd';

import Loading from '../Global/Loading';
import MappingField from './mapping-field';
import DeleteDialog from '../DeleteDialog';
import FieldFormModal from '../FieldFormModal';
import EditDataMappingContext from './context';

import useModal from '../../hooks/useModal';

import { MAPPING_FIELD_TYPES, } from '../../data/constants';


const DEFAULT_FIELD_DATA = Object.freeze({
  name: '',
  description: '',
  options: [],
  asType: MAPPING_FIELD_TYPES.TEXT.asType,
  inputType: MAPPING_FIELD_TYPES.TEXT.inputType,
  typePre: MAPPING_FIELD_TYPES.TEXT.typePre,
});


export default function PhaseFields({ phase, }) {
  const { t, } = useTranslation();
  const {
    createField,
    updateField,
    deleteTabField,
    listTabFields,
    updateTabFieldOrder,
  } = React.useContext(EditDataMappingContext);
  const GRID = 4;
  const [fields, setFields] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [currField, setCurrField] = React.useState(DEFAULT_FIELD_DATA);
  const [openFormModal, onOpenModal, onCloseFormModal] = useModal(selectField, clearSelectedField);
  const [deleteModalOpen, openDeleteModal, closeDeleteModal] = useModal(selectField,
    clearSelectedField);


  function selectField(field=DEFAULT_FIELD_DATA) {
    setCurrField(_.cloneDeep(field));
  }

  function clearSelectedField() {
    setCurrField(DEFAULT_FIELD_DATA);
  }

  const getPhaseFields = async () => {
    try {
      const data = await listTabFields(phase.id);
      setFields(data);
    } catch {
      console.log(`Failed to get tab ${phase.id} fields`);
    }

    setLoading(false);
  }

  React.useEffect(() => {
    getPhaseFields();
  }, []);

  async function onSave() {
    const isCreate = currField.id == undefined;
    const func = isCreate ? createField : updateField;

    const field = await func(phase, !isCreate ? currField : {
      ...currField,
      ordem: fields.length,
    });
    if (undefined != field) {
      const _fields = [...fields];
      if (isCreate) {
        _fields.push(field)
      } else {
        const idx = _fields.findIndex((field) => currField.id == field.id);
        if (idx > -1) {
          _fields[idx] = field;
        }
      }

      setFields(_fields);
      onCloseFormModal();
    }
  }

  async function deleteField(field) {
    if (await deleteTabField(phase.id, field.id)) {
      setFields(fields.filter((item) => item.id != field.id));
    }
    closeDeleteModal();
  }

  async function updateFieldsOrder(updatedFields) {
    await Promise.all(updatedFields
      .map((field, index) => ({
        ...field,
        index,
      })).
      filter(({ index, ...field }) => field.ordem != index)
      .map(({ index, ...field }) => {
        return updateTabFieldOrder(phase, field, index);
      }));
  }

  async function handleOnDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = Array.from(fields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateFieldsOrder(items);
    setFields(items.map((item, index) => {
      return {
        ...item,
        ordem: index,
      };
    }));
  }

  const getItemStyle = (draggableStyle) => ({
    userSelect: 'none',
    padding: GRID * 0.5,
    display: 'flex',
    transformStream: 'rotate(5px)',
    display: 'block',
    width: '13.5rem',
    padding: 0,
    margin: '2px 2px 0 0',
    alignItems: 'center',
    justifyContent: 'center',
    ...draggableStyle,
  })

  const getListStyle = (isDraggingOver) => ({
    borderSize: 2,
    borderColor: isDraggingOver ? 'lightgray' : 'white',
    borderStyle: 'solid',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    padding: GRID,
  })


  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Loading value={loading} />

      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
      >
        <IconButton
          icon={<Icon icon="plus" />}
          placement="right"
          appearance="primary"
          size="lg"
          onClick={() => onOpenModal()}
        >
          Novo campo
        </IconButton>
      </Grid>

      <div
        style={{
          display: 'flex',
          width: '100%',
          overflowX: 'auto',
        }}
      >
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable
            droppableId="name"
            direction="horizontal"
          >
            {
              (provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  style={getListStyle(snapshot.isDraggingOver)}
                  ref={provided.innerRef}
                >
                  {
                    fields.map((field, index) => {
                      return (
                          <Draggable
                            key={field.id}
                            draggableId={field.name}
                            index={index}
                          >
                            {
                              (provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(provided.draggableProps.style)}
                                >
                                  <MappingField
                                    data={field}
                                    isDragging={snapshot.isDragging}
                                    onEdit={() => onOpenModal(field)}
                                    onDelete={() => openDeleteModal(field)}
                                  />
                                </div>
                              )
                            }
                          </Draggable>
                      );
                    })
                  }

                  { provided.placeholder }
                </div>
              )
            }
          </Droppable>
        </DragDropContext>
      </div>

      <FieldFormModal
        show={openFormModal}
        close={onCloseFormModal}
        fieldData={currField}
        setFieldData={setCurrField}
        post={onSave}
      />

      <DeleteDialog
        open={deleteModalOpen}
        confirmationMessage={_.capitalize(t('PAGES.DATA_MAPPING.delete_field_confirmation', { name: currField.name, }))}
        onClose={closeDeleteModal}
        onConfirm={() => deleteField(currField)}
      />
    </div>
  );
}
