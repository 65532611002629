import { createContext, useEffect, useRef, } from 'react';
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';

import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { Notification, } from 'rsuite';
import CloseIcon from '@material-ui/icons/Close';

import useAuth from './hooks/useAuth';

import LoadingPage from '../pages/Loading';
import useModal from '../hooks/useModal';


export const Context = createContext();

const SESSION_RENEWAL_NOTIFICATION_KEY = 'SESSION_RENEWAL_NOTIFICATION';
//In seconds
const MIN_SESSION_TIME_LEFT = 600;
const INTERVAL_BETWEEN_NOTIFICATIONS = 60;

export function AuthProvider({ children }) {
  const auth = useAuth();
  const timeoutRef = useRef(undefined);
  const [open, openSnackbar, closeSnackbar] = useModal();
  const { t, } = useTranslation();


  async function onRenewSession() {
    closeNotification();
    if (await auth.renewUserSession()) {
      Notification['success']({
        title: _.capitalize(t('PAGES.SESSION_RENEWAL.user_session_successfully_renewed')),
        placement: 'bottomEnd',
      });
      configureNotificationTimer();
    } else {
      Notification['error']({
        title: _.capitalize(t('ERRORS.SESSION_RENEWAL.failed_to_renew_user_session')),
        placement: 'bottomEnd',
      });
    }
  }

  function showSessionRenewalNotification() {
    Notification['warning']({
      title: _.capitalize(t('PAGES.SESSION_RENEWAL.user_session_will_expire_soon')),
      description: (
        <div>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              onClick={onRenewSession}
              disableElevation
              color="primary"
              size="small"
            >
              {_.capitalize(t('PAGES.SESSION_RENEWAL.renew_session'))}
            </Button>
          </Grid>
        </div>
      ),
      placement: 'bottomEnd',
      key: SESSION_RENEWAL_NOTIFICATION_KEY,
      duration: 0,
      onClose: onCloseNotification,
    });
  }

  function closeNotification() {
    Notification.close(SESSION_RENEWAL_NOTIFICATION_KEY);
  }

  function onCloseNotification() {
    closeNotification();
    createNotificationTimer(INTERVAL_BETWEEN_NOTIFICATIONS * 1000);
  }

  function onShowNotification() {
    showSessionRenewalNotification();
  }

  function createNotificationTimer(delay) {
    timeoutRef.current = setTimeout(onShowNotification, delay);
  }

  function configureNotificationTimer() {
    clearTimeouts();
    const exp = auth.getTokenExp();
    if (exp * 1000 > Date.now()) {
      createNotificationTimer((exp - MIN_SESSION_TIME_LEFT) * 1000 - Date.now());
    }
  }

  function clearTimeouts() {
    clearTimeout(timeoutRef.current);
  }

  useEffect(() => {
    if (auth.authenticated) {
      configureNotificationTimer();
    }

    return clearTimeouts;
  }, [auth.authenticated]);

  useEffect(function setTokenCheckerInterval() {
    if (auth.authenticated) {
      const intervalId = setInterval(() => {
        if (auth.isTokenExpired()) {
          clearInterval(intervalId);
          closeNotification();
          openSnackbar();
          auth.HandleLogout();
        }
      }, 60 * 1000);

      return function cleanup() {
        clearInterval(intervalId);
      };
    }
  }, [auth.authenticated]);

  return (
    <Context.Provider value={auth}>
      {
        auth.loading ?
        <LoadingPage /> :
        children
      }

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={(_, reason) => {
          if (reason === 'clickaway') {
            return;
          }

          closeSnackbar();
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          elevation={6}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={closeSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        >
          { t('PAGES.SESSION_MANAGEMENT.session_expired') }
        </Alert>
      </Snackbar>
    </Context.Provider>
  );
}
