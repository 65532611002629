import { Timeline } from 'rsuite';
import moment from 'moment';

import './styles.css';


export default function TimeLineReg({ log, }) {

  return (
    <Timeline.Item
      time={
        <p className="Time">
          { moment(log.created_at).format('DD/MM/YYYY HH:mm') }
        </p>
      }
    >
      <div className="Acao">
        <strong>Usuário:</strong>
        <small>
          { null != log.user ? log.user : 'Anônimo' }
        </small>
        <br />
        <strong>Descrição:</strong>
        <small>
          { log.description }
        </small>
      </div>
    </Timeline.Item>
  );
}
