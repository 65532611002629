import { memo, } from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { COLLABORATOR_COURSE_PROGRESS_STATES, } from '../../utils/constants';


const BASIC_COLOR = COLLABORATOR_COURSE_PROGRESS_STATES.SENT.color;

const useStyles = makeStyles({
  chip: {
    backgroundColor: (props) => props.backgroundColor,
    borderRadius: 2,
    fontWeight: 500,
    color: 'white',
  },
});


function CollaboratorCourseProgressStateChip({ state, ...props }) {
  const stateInfo = COLLABORATOR_COURSE_PROGRESS_STATES[`${state}`];

  const classes = useStyles({
    backgroundColor: stateInfo ? stateInfo.color : BASIC_COLOR,
  });


  return (
    <Chip
      className={classes.chip}
      size="small"
      label={ stateInfo ? stateInfo.label : '' }
      { ...props }
    />
  );
}

export default memo(CollaboratorCourseProgressStateChip);
