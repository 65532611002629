import { useEffect, useState, memo, } from 'react';
import { Typography, CircularProgress, Box, Button, } from '@material-ui/core';
import moment from 'moment';

import './styles.css';
import api, { getPlano, } from '../../services/api';
import history from '../../history';


function CircularProgressWithLabel(props) {
  return (
    <Box
      position="relative"
      display="inline-flex"
      m={0.5}
    >
      <CircularProgress
        variant="determinate"
        thickness={4}
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >
          <strong>{`${Math.round(props.value)}%`}</strong>
        </Typography>
      </Box>
    </Box>
  );
}


function BodyModalConfigUsers({ body }) {
  const idEmpresa = body._id;
  const [now, setNow] = useState(0);
  const [peso, setPeso] = useState([]);
  const [results, setResults] = useState([]);
  const [andamento, setAndamento] = useState(0);
  const [maturidade, setMaturidade] = useState(0);


  const getData = async () => {
    const {data: results } =  await api.get(`/resposta?resposta=1&idEmpresa=${idEmpresa}`);
    const { data: historyData } = await api.get(`/historico?idEmpresa=${idEmpresa}`);
    const { data: inProgressData, } = await getPlano(idEmpresa);
    const { andamento, } = inProgressData;

    setResults(results);
    setPeso(historyData);
    setAndamento(andamento.length);
  }

  /**
   * Set data to access company
   *
   * @param {object} company - company to access
   */
  function accessCompany(company) {
    history.push(`/companies/${company._id}`);
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    setMaturidade(Math.floor(results * peso))
  }, [results, peso])

  useEffect(() => {
    const media = (results + andamento) * peso;
    setNow(!media ? 0 : Math.floor(media));
  });


  return (
    <tr>
      <td>{body.name}</td>
      <td>{moment(body.createdAt).format('DD/MM/YYYY')}</td>
      <td>Não definido</td>
      <td>
        <Button
          onClick={() => accessCompany(body)}
          color="primary"
          variant="outlined"
          disableElevation
          size="small"
        >
          Acessar
        </Button>
      </td>
      <td>
        <CircularProgressWithLabel value={now} />
      </td>
      <td>
        <CircularProgressWithLabel value={maturidade} />
      </td>
    </tr>
  );
}

export default memo(BodyModalConfigUsers);
