import { useState, useEffect, } from 'react';
import { useSelector, } from 'react-redux';
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';
import fileDownload from 'js-file-download';

import {
  Grid,
  Button,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import { Notification, } from 'rsuite';

import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import DeleteDialog from '../DeleteDialog';
import TemplateQuestionForm from '../TemplateQuestionForm';
import UploadChecklistDialog from '../UploadChecklistDialog';
import QuestionItem from '../QuestionItem';

import {
  selectCurrentTemplate,
  selectCurrentTemplateId,
} from '../../features/questionsTemplatesSlice';
import {
  exportChecklistTemplate,
  listQuestionsTemplateQuestions,
  deleteQuestionsTemplateQuestion,
  createQuestionsTemplateQuestion,
  updateQuestionsTemplateQuestion,
  importTemplateQuestionsFromCSVFile,
} from '../../services/api';

import useModal from '../../hooks/useModal';

import { getUserId, } from '../../utils';
import { errorCodes, } from '../../errors/standard-error';


/**
 * Questions templates management page toolbar
 *
 * @returns Rendered page
 */
export default function QuestionsList() {
  const { t, } = useTranslation();
  const currentTemplateId = useSelector(selectCurrentTemplateId);
  const currentTemplate = useSelector(selectCurrentTemplate)
  const [formModal, openFormModal, closeFormModal] = useModal(selectQuestion, () => setCurrentQuestionId(null));
  const [deleteConfOpen, openDeleteConf, closeDeleteConf] = useModal();
  const [importChecklistDialog, openImportChecklistDialog, closeImportChecklistDialog] = useModal();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [loading, setLoading] = useState(true);
  const currentQuestion = questions.find((question) => question.id === currentQuestionId);


  /**
   * Send request to API to delete question
   */
  async function onDelete() {
    closeDeleteConf();

    if (currentQuestionId == null) {
      return;
    }

    try {
      await deleteQuestionsTemplateQuestion(getUserId(), currentTemplateId, currentQuestionId);

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Tarefa excluída com sucesso!',
      });

      setQuestions((questions) => {
        const updatedQuestions = _.cloneDeep(questions);
        const index = updatedQuestions.findIndex((question) => question.id == currentQuestionId);
        if (index > -1) {
          updatedQuestions.splice(index, 1);
        }

        for (let idx = index; idx < updatedQuestions.length; idx++) {
          updatedQuestions[idx].ordem--;
        }

        return updatedQuestions;
      });
      setCurrentQuestionId(null);
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao excluir tarefa!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }
  }

  function selectQuestion(questionId) {
    setCurrentQuestionId(questionId);
  }

  async function saveQuestion(_data) {
    const editing  = null != currentQuestionId;
    const values = editing ? {
      ...currentQuestion,
      problema: _data.problem,
      acao: _data.action,
      setor: _data.sector,
      category: _data.category,
    } : {
      prioridade: '',
      rapida: 0,
      ordem: questions.length > 0 ? questions[questions.length - 1].ordem + 1 : 0,
      problema: _data.problem,
      acao: _data.action,
      setor: _data.sector,
      category: _data.category,
    };

    try {
      const { data, } = await (editing ? updateQuestionsTemplateQuestion(getUserId(),
        currentTemplateId, currentQuestionId, values) : createQuestionsTemplateQuestion(getUserId(),
        currentTemplateId, values));

      Notification['success']({
        placement: 'bottomEnd',
        title: `Tarefa ${editing ? 'atualizada' : 'criada'} com sucesso!`,
      });

      if (editing) {
        const updatedQuestions = _.cloneDeep(questions);
        const index = updatedQuestions.findIndex((question) => question.id == currentQuestionId);
        if (index > -1) {
          updatedQuestions[index] = data;
        }
        setQuestions(updatedQuestions);
      } else {
        setQuestions((questions) => [...questions, data]);
      }

      closeFormModal();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: `Falha ao ${editing ? 'atualizar' : 'criar'} tarefa!`,
        description: 'Tente novamente dentro de alguns instantes',
      });
    }
  }

  function __openDeleteConf(id) {
    setCurrentQuestionId(id);
    openDeleteConf();
  }

  async function getQuestions() {
    setLoading(true);

    try {
      const { data, } = await listQuestionsTemplateQuestions(getUserId(), currentTemplateId);
      setQuestions(data.questions || []);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao obter tarefas do template!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }

    setLoading(false);
  }

  async function importFromCSVFile(file) {
    closeImportChecklistDialog();
    try {
      const { data, } = await importTemplateQuestionsFromCSVFile(getUserId(), currentTemplateId,
        file);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.MATURITY_CHECKLIST.checklist_successfully_imported')),
      });
      setQuestions(data.questions || []);
    } catch (err) {
      if ((undefined != err.response) && (undefined != err.response.data)) {
        const MESSAGE_MAP = {
          [errorCodes.INCOMPLETE_CHECKLIST_CSV_FILE_ERROR]: t('ERRORS.ERROR_CODES.INCOMPLETE_CHECKLIST_CSV_FILE_ERROR'),
          [errorCodes.EMPTY_FILE_ERROR]: t('ERRORS.ERROR_CODES.EMPTY_FILE_ERROR'),
        };

        const description = MESSAGE_MAP[err.response.data.code] ||
          _.capitalize(t('ERRORS.try_again_later'));
        Notification['error']({
          placement: 'bottomEnd',
          title: _.capitalize(t('ERRORS.MATURITY_CHECKLIST.failed_to_import_checklist_from_csv_file')),
          description,
        });
      } else {
        Notification['error']({
          placement: 'bottomEnd',
          title: _.capitalize(t('ERRORS.MATURITY_CHECKLIST.failed_to_import_checklist_from_csv_file')),
          description: _.capitalize(t('ERRORS.try_again_later')),
        });
      }
    }
  }

  async function onExportChecklist() {
    try {
      const response = await exportChecklistTemplate(getUserId(), currentTemplateId);
      fileDownload(response.data, `${currentTemplate.name}.csv`);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.MATURITY_CHECKLIST.checklist_successfully_exported')),
      });
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.MATURITY_CHECKLIST.failed_to_export_checklist')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  useEffect(() => {
    if (null != currentTemplateId) {
      getQuestions();
    }
  }, [currentTemplateId]);


  return (
    <>
      <Grid
        container
        style={{
          marginTop: '1rem',
        }}
      >
        <Grid
          xs={12}
          item
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddOutlinedIcon />}
            onClick={() => openFormModal(null)}
            size="small"
            style={{
              marginRight: '0.5rem',
            }}
          >
            Nova tarefa
          </Button>

          <Button
            variant="outlined"
            color="primary"
            startIcon={<CloudUploadIcon />}
            onClick={openImportChecklistDialog}
            size="small"
            style={{
              marginRight: '0.5rem',
            }}
          >
            { _.capitalize(t('import')) }
          </Button>

          <Button
            variant="outlined"
            color="primary"
            startIcon={<CloudDownloadIcon />}
            onClick={onExportChecklist}
            size="small"
          >
            { _.capitalize(t('export')) }
          </Button>

        </Grid>

        <Grid
          xs={12}
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          alignContent="center"
          style={{
            marginTop: '1rem',
          }}
        >
          {
            loading || (questions.length === 0) ?
            <>
              { loading ? <CircularProgress size={24} /> : null }
              <Typography
                color="textSecondary"
                variant="body1"
                style={{
                  marginTop: '0.5rem',
                }}
              >
                {
                  loading ?
                  'Carrengando tarefas do template...' :
                  'Você ainda não possui tarefas neste template'
                }
              </Typography>
            </> :
            questions.map(((question) => {
              return (
                <QuestionItem
                  key={question.id}
                  id={question.id}
                  index={question.ordem}
                  question={question.problema}
                  sector={question.setor}
                  onEdit={openFormModal}
                  onDelete={__openDeleteConf}
                  color="#DDDDDD"
                />
              );
            }))
          }
        </Grid>
      </Grid>

      <Dialog
        open={formModal}
        onClose={closeFormModal}
        PaperProps={{
          style: {
            width: '600px',
            maxWidth: '90%',
          },
        }}
      >
        <DialogTitle>
          { null != currentQuestionId ? 'Editar tarefa' : 'Criar nova tarefa' }
        </DialogTitle>
        <TemplateQuestionForm
          data={currentQuestion}
          onSubmit={saveQuestion}
          sectors={Array.from(new Set(questions.map(({ setor, }) => setor)))}
          categories={Array.from(new Set(questions.filter(({ category, }) => category).map(({ category, }) => category)))}
        />
      </Dialog>

      <DeleteDialog
        open={deleteConfOpen}
        confirmationMessage={`Tem certeza que deseja excluir a tarefa "${currentQuestion != null ? currentQuestion.problema : ''}"?`}
        onClose={closeDeleteConf}
        onConfirm={onDelete}
      />

      <UploadChecklistDialog
        open={importChecklistDialog}
        onClose={closeImportChecklistDialog}
        onChange={importFromCSVFile}
      />
    </>
  );
}
