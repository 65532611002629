import { useState } from 'react'
import { Dialog, Button } from '@material-ui/core'
import DocViewer, {
  DocViewerRenderers,
} from 'react-doc-viewer'

export default function Contrato() {
  const [open, setOpen] = useState(false)


  function HandleClose() {
    setOpen(false)
  }
  const docs = [
    {
      uri: 'https://view.officeapps.live.com/op/embed.aspx?src=https://s3.sa-east-1.amazonaws.com/octo.legal.images/f8d7aae7965d92d6053b301c6de97b43TOOR_-_OCTO_LEGAL_-_Prestação_de_serviços_e_Licenciamento_de_Software_-_vf.docx',
    },
  ]
  return (
    <div>
       <div className="boxHeaderPoliticas">
        <h3>Contrato OCTO</h3>
        <button onClick={() => setOpen(true)}>Visualizar</button>
       </div>
      <Dialog
      open={open}
      onClose={HandleClose}
      fullWidth
      maxWidth="lg"
      >
      <iframe
        src="https://view.officeapps.live.com/op/embed.aspx?src=https://s3.sa-east-1.amazonaws.com/octo.legal.images/f8d7aae7965d92d6053b301c6de97b43TOOR_-_OCTO_LEGAL_-_Prestação_de_serviços_e_Licenciamento_de_Software_-_vf.docx"
        width="100%"
        height="560"
        style={{ border: 'none' }}
      />
      <Button onClick={HandleClose} variant="outlined" color="primary">Sair</Button>
      </Dialog>
    </div>
  )
}
