import React from 'react';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';


export default function LoadingPage() {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      style={{
        height: '100vh'
      }}
    >
      <CircularProgress
        variant="indeterminate"
        thickness={5}
        size={60}
      />
    </Grid>
  );
}
