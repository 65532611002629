import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCompaines, listGroupPartnerships } from '../services/api';
import { getUserSession } from '../utils';

const user = getUserSession();

export const partnershipsFetch = createAsyncThunk('data/fetchPartnerships', async (userSessionGroup) => {
  const { data } = await listGroupPartnerships(userSessionGroup);
  return data.partnerships;
});

export const companiesFetch = createAsyncThunk('data/fetchCompanies', async () => {
  const { data } = await getCompaines();
  return data.group;
});


function chunkArray(array, chunkSize) {
  let result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    let chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
}

// } else {
//   Notification['warning']({
//     placement: 'bottomEnd',
//     title: 'Falha ao carregar templates de mapeamento!',
//     description: 'Tente novamente dentro de alguns instantes.'
//   });
// }

const dashBoardStore = createSlice({
  name: 'dashboard',
  initialState: {
    listConpanies: [],
    indexPaginator: 0,
    listItensPerPage: ['5', '10', '15', '20','100'],
    itensPerPage: 5,
    isLoading: true,
    selectedPartnerId: null,

    partnerships: {
      data: [],
      status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
      error: null
    },

    companies: {
      data: [],
      status: 'idle',
      error: null
    }
  },

  reducers: {
    setListCompanies(state, action) {
      state.listConpanies = chunkArray(action.payload, state.itensPerPage);
    },
    setCurrentPaginator(state, action) {
      state.indexPaginator = action.payload;
    },
    setItensPerPage(state, action) {
      state.itensPerPage = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSelectPartnerId(state, action){
      state.selectedPartnerId = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(partnershipsFetch.pending, (state) => {
        state.partnerships.status = 'loading';
      })
      .addCase(partnershipsFetch.fulfilled, (state, action) => {
        state.partnerships.status = 'succeeded';
        state.partnerships.data = action.payload;
      })
      .addCase(partnershipsFetch.rejected, (state, action) => {
        state.partnerships.status = 'failed';
        state.partnerships.error = action.error.message;
      });

    builder
      .addCase(companiesFetch.pending, (state) => {
        state.companies.status = 'loading';
      })
      .addCase(companiesFetch.fulfilled, (state, action) => {
        state.companies.status = 'succeeded';
        state.companies.data = action.payload;
      })
      .addCase(companiesFetch.rejected, (state, action) => {
        state.companies.status = 'failed';
        state.companies.error = action.error.message;
      });
  }
});

export const { setListCompanies, setCurrentPaginator, setLoading, setItensPerPage, setSelectPartnerId, setTotalItens } = dashBoardStore.actions;

export default dashBoardStore.reducer