import React, { useEffect, useState, } from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import { Container, } from '@material-ui/core';
import { Notification, } from 'rsuite';

import './styles.css';

import { listGroupConsultants, } from '../../services/api';

import CadastroUser from '../CriarUsuarios';


function ShowUsers() {
  const { t, } = useTranslation();
  const group = localStorage.getItem('group');
  const [search] = useState('');
  const [users, setUsers] = useState([]);


  async function getUsers() {
    try {
      const { data, } = await listGroupConsultants(group);
      setUsers(data);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.CONSULTANT_MANAGEMENT.failed_to_load_consultants')),
        description: _.capitalize(t('ERRORS.try_again_later'))
      });
    }
  }

  useEffect(() => {
    getUsers()
  }, []);

  const filteredUsers = users.filter((user) => {
    return user.name.toLowerCase().includes(search.toLocaleLowerCase());
  });


  return (

    <Container>
      <CadastroUser
        users={filteredUsers}
        getUsers={getUsers}
      />
    </Container>

  );
}

export default ShowUsers;
