import { useContext, useEffect, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { Switch, Route, useRouteMatch, } from 'react-router-dom';

import { Context, } from '../../context/AuthContext';
import { fetchGroups, getUserGroup, } from '../../features/groupsSlice';

import CompanyArea from '../CompanyArea';
import ConsultantArea from '../ConsultantArea';
import Profile from '../Profile';
import { listDataMappingTemplates, } from '../../features/dataMappingTemplatesSlice';


function LoggedInArea() {
  const match = useRouteMatch();
  const { authenticated, } = useContext(Context);
  const dispatch = useDispatch();
  const { initialized, } = useSelector(state => state.groups);


  useEffect(() => {
    if (authenticated && !initialized) {
      dispatch(fetchGroups());
      dispatch(getUserGroup());
      dispatch(listDataMappingTemplates());
    }
  }, [authenticated, initialized]);


  return (
    <Switch>
      <Route
        exact
        path="/Profile"
        component={Profile}
      />

      <Route
        path="/companies/:companyId"
        component={CompanyArea}
      />

      <Route
        path={match.path}
        component={ConsultantArea}
      />
    </Switch>
  );
}

export default LoggedInArea;
