import { Container, Typography, Grid, } from '@material-ui/core';
import styled from 'styled-components';

import cadastro_consultor from '../../assets/cadastro_consultor.png';
import cadastro_empresario from '../../assets/cadastro_empresario.png';
import '../Login/styles.css';



export const TypeTitle = styled.strong`
  font-family: 'Sora';
  font-weight: 500;
  transition: all 0.2s;
  font-size: 18px;
  line-height: 17px;
  color: #1c265a;
  margin: 5px 0;

  @media (max-width: 414px) {
    font-size: 17px;
  }
`;

function RegistrationTypeItem({ onClick, src, alt, title, desc, }) {
  return (
    <div
      className="ContainerSeletoresCad"
      onClick={onClick}
    >
      <img
        className="ImgLoginType"
        src={src}
        alt={alt}
      />
      <TypeTitle>
        { title }
      </TypeTitle>
      <p>{ desc }</p>
    </div>
  );
}


export default function SelectTypeCad({ openSelectRender, openUnique, }) {
  return (
    <Container
      className="ContainerAllModal"
      disableGutters
      maxWidth="xl"
    >
      <Typography
        variant="h4"
        align="center"
        display="block"
      >
        Escolha seu perfil
      </Typography>

      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        <div className="BoxAllSelectCad">
          <RegistrationTypeItem
            onClick={openSelectRender}
            src={cadastro_consultor}
            alt="Consultor"
            title="Sou Consultor"
            desc="Quero ajudar meus clientes"
          />

          <RegistrationTypeItem
            onClick={openUnique}
            src={cadastro_empresario}
            alt="Empresário"
            title="Sou Empresário"
            desc="Quero adequar minha empresa"
          />
        </div>
      </Grid>
    </Container>
  );
}
