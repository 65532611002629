import { useEffect, useState } from "react";
import { useSelector, } from 'react-redux';
import { useParams, } from 'react-router-dom';

import { Form, } from "react-bootstrap";
import { Button, Notification, Tag, Icon, } from "rsuite";
import { Grid, Container, Paper, Typography, Dialog, } from "@material-ui/core";

import {
  getUsers,
  removeMeetingParticipant,
  getReunionsParticipants,
} from "../../../services/api";

import EditModal from "./EditModal";

import { selectCompany, } from "../../../features/companySlice";


export default function Participants() {
  const { _id: idEmpresa, } = useSelector(selectCompany);
  const { meetingId, } = useParams();
  const [showParticipants, setShowParticipants] = useState(false);
  const [allMenbers, setAllMenbers] = useState([]);
  const [participantsReunion, setParticipantsReunion] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [user, setUser] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  async function getData() {
    await getReunionsParticipants(idEmpresa, meetingId).then(({ data }) => {
      setAllMenbers(data);
    });
    await getUsers().then(({ data }) => {
      setUser(data);
    });
  }
  useEffect(() => {
    getData();
  }, []);

  function handleCloseModal() {
    setOpenModal(false);
  }

  const deleteParticipants = async (value) => {
    await removeMeetingParticipant(idEmpresa, meetingId, value)
      .then(() => {
        Notification["success"]({
          placement: "bottomEnd",
          title: "Participante removido ",
        });
        getData();
      })
      .catch(() => {
        Notification["error"]({
          placement: "bottomEnd",
          title: "Erro ao remopver participante",
        });
      });
  };


  return (
    <Grid
      container
      spacing={2}
      style={{
        height: "100%",
        width: "100%",
        maxWidth: "100%",
        margin: 0,
        marginBottom: 25,
        marginTop: 15,
      }}
    >
      <Grid item style={{ padding: 0 }} xs={12}>
        <div className="BoxNameEmpresa">
          <p>Participantes</p>
          <Icon
            icon="edit"
            onClick={(event) => {
              event.preventDefault();
              setOpenModal(true);
            }}
          />
        </div>
        <Container
          component={Paper}
          style={{
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#95ADC3",
            borderRadius: 8,
            maxHeight: "100%",
            width: "100%",
            maxWidth: "100%",
            padding: 5,
            marginTop: 8,
          }}
        >
          <Typography variant="body1" style={{ color: "#54708C" }}>
            {"  "}
            <div style={{ marginLeft: 5, marginRight: 5 }}>
              <div
                style={{
                  display: "inline-flex",
                  flexWrap: "wrap",
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                {allMenbers.map((participante, index) => {
                  return (
                    <Tag
                      style={{ marginBottom: 10 }}
                      color="blue"
                      key={index}
                      closable
                      onClose={() => deleteParticipants(participante.id)}
                    >
                      {participante.name}
                    </Tag>
                  );
                })}
                <EditModal
                  openModal={openModal}
                  closeModal={handleCloseModal}
                  participants={allMenbers}
                  users={user}
                  getData={getData}
                />
              </div>
            </div>
          </Typography>
          <br />
          <br />
        </Container>
      </Grid>
      <Dialog
        open={showParticipants}
        onClose={() => {
          setShowParticipants(false);
        }}
      >
        <Icon
          icon="close"
          onClick={() => {
            setShowParticipants(false);
          }}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            padding: 3,
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 25,
          }}
        >
          <Grid item xs={6}>
            <Form.Control
              as="select"
              multiple
              defaultValue="Escolha os participantes"
              onChange={(e) => {
                // addMember(e.target.value);
              }}
            >
              {selectOptions.map((user) => {
                if (user.empresa === idEmpresa)
                  return <option key={user._id}>{user.name}</option>;
              })}
            </Form.Control>
            <Button
              size="sm"
              block
              variant="outline-dark"
              onClick={() => {
                setParticipantsReunion([]);
              }}
            >
              Limpar seleção
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Form.Control
              as="select"
              multiple
              defaultValue="Escolha os participantes"
              onChange={(e) => {
                // removeMember(e.target.value);
              }}
            >
              {participantsReunion.map((user, index) => {
                return <option key={index}>{user}</option>;
              })}
            </Form.Control>
            <Button
              size="sm"
              block
              variant="outline-dark"
              onClick={() => {
                // addParticipante();
                setShowParticipants(false);
              }}
            >
              Adicionar participante
            </Button>
          </Grid>
        </div>
      </Dialog>
    </Grid>
  );
}
