import React from 'react';
import { Grid, Paper, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ProgressBarCustom from '../ProgressBar';
import RiskOverviewCard from '../RiskOverviewCard';


const useStyles = makeStyles({
  gridContainer: {
    marginBottom: '1rem',
    marginTop: '0.2rem',
  },
  gridItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  paperStyle: {
    flex: 1,
    display: 'flex',
  },
  title: {
    marginBottom: '0.9rem',
    color: '#4D5884',
    fontSize: '1.6rem',
  },
  gridInnerContainer: {
    flex: 1,
    padding: '1rem',
  },
  gridInnerItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  titleInnerItem: {
    color: '#3B66FF',
    display: 'inline',
    margin: '0',
    fontFamily: 'Inter',
    fontWeight: 'bolder',
    marginRight: '0.5rem',
  },
  subtitleInnerItem: {
    color: '#54708C',
    display: 'inline',
    fontFamily: 'Inter',
    margin: '0',
  },
})

export const HeaderProgress = () => {
  const classes = useStyles();


  return (
    <Grid
      container
      spacing={3}
      className={classes.gridContainer}
    >
      <Grid
        item
        md={6}
        className={classes.gridItem}
      >
        <p className={classes.title}>
          Progresso e aderência
        </p>
        <ProgressBarCustom />
      </Grid>

      <Grid
        item
        md={6}
        className={classes.gridItem}
      >
        <p className={classes.title}>
          Relatório de riscos
        </p>

        <Paper
          style={{
            padding: '0.3rem 0.6rem',
            display: 'flex',
            flexGrow: 1,
          }}
        >
          <RiskOverviewCard />
        </Paper>
      </Grid>
    </Grid>
  );
}
