import React from 'react';
import {
  Container,
  Grid,
  Typography,
  Paper,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CourseLevelChip from './CourseLevelChip';
import AspectRatio from '../AspectRatio';


const useStyles = makeStyles({
  courseContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  videoContainer: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  video: {
    border: 'transparent',
    backgroundColor: 'black',
    borderRadius: 5,
  },
  courseFieldRow: {
    marginTop: '0.4rem',
    marginBottom: '0.4rem',
  },
  levelChip: {
    borderRadius: 2,
  },
});


/**
 * Component used to show single course info row
 *
 * @param {Component} children - component children
 * @param {string} label - field label
 *
 * @returns rendered row
 */
function CourseInfoRow({ children, label, }) {
  const classes = useStyles();


  return (
    <>
      <Grid
        container
        fullWidth
        className={classes.courseFieldRow}
      >
        <Grid
          item
          xs={3}
        >
          <Typography variant="body1" >
            { label }
          </Typography>
        </Grid>
        <Grid
          item
          xs={9}
        >
          <Typography variant="body1" >
            { children }
          </Typography>
        </Grid>
      </Grid>

      <Divider />
    </>
  );
}


/**
 * Component used to show a course
 *
 * @param {object} course - course data
 * @param {object} author - course author data
 *
 * @returns rendered component
 */
export default function Course({ course, author, }) {
  const classes = useStyles();


  return (
    <Container
      disableGutters
      maxWidth="xl"
    >
      <Typography
        variant="h5"
        display="block"
        gutterBottom
      >
        { (undefined != course) && course.title ? course.title : '' }
      </Typography>

      <Container
        maxWidth="xl"
        component={Paper}
      >
        {
          (undefined != course) ?
          <Container
            className={classes.courseContainer}
            disableGutters
          >
            <Grid
              container
              className={classes.videoContainer}
              fullWidth
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <AspectRatio>
                <iframe
                  src={ course.video_url }
                  className={classes.video}
                  width="100%"
                  height="100%"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Vídeo aula"
                />
              </AspectRatio>
            </Grid>

            <Typography
              variant="h5"
              gutterBottom
              display="block"
            >
              Sobre o curso
            </Typography>

            <Divider />

            <CourseInfoRow
              label="Professor(a)"
            >
              <Typography variant="body1" >
                { author.name ? author.name : 'N/A' }
              </Typography>
            </CourseInfoRow>

            <CourseInfoRow label="Descrição do curso" >
              <Typography variant="body1" >
                { course.description }
              </Typography>
            </CourseInfoRow>

            <CourseInfoRow label="Quantidade de aulas" >
              <Typography variant="body1" >
                1 aula
              </Typography>
            </CourseInfoRow>

            <CourseInfoRow label="Duração total em horas" >
              <Typography variant="body1" >
                {
                  course.duration ?
                  `${course.duration} ${ Math.abs(course.duration).toFixed(0) === '1' ? 'hora' : 'horas' }` :
                  'N/A'
                }
              </Typography>
            </CourseInfoRow>

            <CourseInfoRow label="Dificuldade do curso" >
              <CourseLevelChip level={course.level} />
            </CourseInfoRow>
          </Container> :
          <Grid
            container
            fullWidth
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
            <Typography
              variant="body1"
              display="block"
            >
              Carregando
            </Typography>
          </Grid>
        }
      </Container>
    </Container>
  );
}
