import DataMappingField from '../DataMappingAnswerPanel/Field';


export default function FieldPreview({ field, }) {
  return (
    <DataMappingField
      field={field}
      previewOnly
    />
  );

}
