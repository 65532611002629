import styled from "styled-components";

export const ContainerFooter = styled.footer`
  bottom: 0;
  width: 100%;
  height: 4rem;

  margin: 0;
  padding: 0 4rem;

  background: transparent;

  @media (max-width: 1024px) {
    max-width: 1024px;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const ContentFooter = styled.div`
  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  span {
    color: var(--primary);

    font-family: "Sora", sans-serif;
    font-weight: 600;
    cursor: pointer;
  }
`;

export const SocialMediaIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 425px) {
    display: none;
  }
`;

export const FooterLeft = styled.div`
  height: 100%;

  display: flex;
  align-items: center;

  > * {
    margin-right: 1rem;
  }

  font-size: 14px;

  @media (max-width: 425px) {
    font-size: 10px;
  }
`;
