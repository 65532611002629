import { Grid } from "@material-ui/core";

export default function SubtitleColorPrimary({ description, fontSize }) {
  return (
    <Grid item xs={12} sm={10}>
    <strong style={{ fontSize: fontSize, color: "#3B66FF" }}>
      {description}
    </strong>
    </Grid>
  );
}
