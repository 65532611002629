import React, { useEffect, useState, } from "react";
import { useSelector, } from 'react-redux';
import { useParams, } from 'react-router-dom';

import { Button, Icon } from "rsuite";
import { Grid, Container, Paper, CircularProgress, TextareaAutosize, } from "@material-ui/core";

import Loading from "../../components/Global/Loading";
import ItensRetomada from "./itensRetomadas";
import ItensSelicionadosPauta from "./itensSelecionados";

import {
  getItensForReunions,
  getRiscoBetweenReunions,
} from "../../services/api";

import { selectCompany, } from "../../features/companySlice";

import styles from "./styles.module.scss";


export default function Itens({ ata, insertAta, setAta, handlePrint, users, reunion, }) {
  const { meetingId, } = useParams();
  const [body, setBody] = useState([]);
  const [body2, setBody2] = useState([]);
  const [isRetomada, setIsRetomada] = useState(false);
  const [loading, setLoading] = useState(true);
  const company = useSelector(selectCompany);


  async function getData() {
    await getItensForReunions(company._id, meetingId)
      .then(({ data }) => {
        setBody(data);
      })
      .catch(() => {
        setBody([]);
      });
    await getRiscoBetweenReunions(company._id, meetingId)
      .then(({ data }) => {
        setBody2(data);
      })
      .catch(() => {
        setBody2([]);
      });
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  const filteredArr = body2.reduce((acc, current) => {
    const x = acc.find((item) => item.id === current.id);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (filteredArr.length >= 1) {
        setIsRetomada(true);
      } else {
        setIsRetomada(false);
      }
    }, 3000);
  });


  return (
    <Grid
      item
      xs={12}
      style={{
        height: "100%",
        width: "100%",
        maxWidth: "100%",
        marginTop: 30,
        padding: 0,
      }}
    >
      <Loading value={loading} />
      <Container
        style={{
          height: "100%",
          width: "100%",
          maxWidth: "100%",
          paddingLeft: 0,
          paddingInlineEnd: 0,
        }}
      >
        <strong className={styles.titleItens}>
          Itens selecionados para pauta
        </strong>
        <br />
        <br />

        {body.map((acao, index) => {
          return (
            <ItensSelicionadosPauta
              acao={acao}
              index={index}
              users={users}
              reunion={reunion}
            />
          );
        })}

        {isRetomada && (
          <>
            <br />
            <strong className={styles.titleItens}>
              Retomada de outros itens
            </strong>
            <br />
            <br />

            {filteredArr.map((acao, index) => {
              return loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <ItensRetomada
                  acao={acao}
                  index={index}
                  users={users}
                  reunion={reunion}
                />
              );
            })}
            <br />
          </>
        )}

        <Container
          component={Paper}
          maxWidth="xl"
          style={{
            padding: '0.5rem',
          }}
        >
          <p
            className={styles.titleItens}
            style={{
              margin: '0.5rem 0',
            }}
          >
            Observações
          </p>

          <TextareaAutosize
            value={ata}
            aria-label="minimum height"
            minRows={6}
            placeholder={ata}
            onChange={(e) => {
              setAta(e.target.value);
            }}
            style={{ width: "100%" }}
          />
          <br />

          <Button
            color="blue"
            onClick={insertAta}
            style={{
              width: "10rem",
            }}
          >
            Salvar
          </Button>{" "}
          <Button
            color="cyan"
            onClick={handlePrint}
            style={{
              width: "10rem",
            }}
          >
            Imprimir
          </Button>{" "}
          <Button
            style={{
              background: "#4169E1",
              color: "#fff",
            }}
          >
            {" "}
            <Icon icon="envelope-o" /> Enviar por E-mail
          </Button>
        </Container>
      </Container>
    </Grid>
  );
}
