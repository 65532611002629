import React from 'react';

import { Container, } from '@material-ui/core';

import IlustraLGPDImg from '../../assets/ilustra.svg';
import './tutorial.styles.css';


export default function Tutorial({ onCreate, }) {
  return (
    <Container maxWidth="xl" >
      <div className="Container2MapeamentoTutorial">
        <div className="ContainerImgIlustra">
          <img
            className="IlustraSvg"
            src={IlustraLGPDImg}
            alt="Cadastro Octo"
          />
          <h3 className="TitleH3Tutorial">
            Você ainda não cadastrou nenhum processo.
          </h3>
          <span className="SubTitleTutorial">
            Para começar é muito simples, você vai precisar
            seguir esses passos.
          </span>
        </div>

        <div className="ConatinerTimeLine">
          <div className="StepTimeLineBox">
            <div className="Step1 Active">1</div>
            <p className="DescriptionTimeLine">
              Criar uma aba
            </p>
          </div>
          <div className="StepTimeLineBox">
            <div className="Step2 Active">2</div>
            <p className="DescriptionTimeLine">
              Criar os campos
            </p>
          </div>
          <div className="StepTimeLineBox">
            <div className="Step3">3</div>
            <p className="DescriptionTimeLine">
              Salvar o Processo
            </p>
          </div>
        </div>

        <button
          className="ButtonNovoProcesso"
          onClick={onCreate}
        >
          Novo processo
        </button>
      </div>
    </Container>
  );
}
