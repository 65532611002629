import { createSlice } from '@reduxjs/toolkit';

const cookiesStore = createSlice({
    name: 'gestaoDeCookies',
    initialState: {
        indexSelectSite: 0,
        sites: []
    },

    reducers: {
        setIndexSelectSite(state, action) {
            state.indexSelectSite = action.payload;
        },
        setSites(state, action) {
            state.sites = action.payload;
        }
    }
});

export const { setIndexSelectSite, setSites } = cookiesStore.actions;

export default cookiesStore.reducer
