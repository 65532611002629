import React, { useEffect, } from 'react';
import { useDispatch, } from 'react-redux';

import { Grid, Breadcrumbs, Link, Container, } from '@material-ui/core';

import { setDocs } from '../../features/docsSlice'
import { getDocsWorkshop } from '../../services/api'

import Title from '../../components/Global/PageTitle'
import { WorkshopGrid } from '../../components/Workshop'
import Sheet from '../../components/Sheet';


const PAGES = Object.freeze({
  workshop: {
    id: 'workshop',
    titleTab: "Workshop",
    titlePage: "Workshop",
    component: <WorkshopGrid />,
  },
});

const ARRAY_PAGES = Object.values(PAGES);


export default function Workshop() {

  const dispatch = useDispatch();

  function getDocs() {
    getDocsWorkshop().then(({ data }) => {
      dispatch(setDocs(data));
    });
  }

  useEffect(() => {
    getDocs()
  }, []);


  return (
    <Sheet pages={ARRAY_PAGES} />
  );
}
