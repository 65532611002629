import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { red, } from '@material-ui/core/colors';

import Dropdown from '../Dropdown';
import DeleteDialog from '../DeleteDialog';

import useModal from '../../hooks/useModal';


export default function TemplateToolbar({ items, value, onDelete, onCreate, onEdit, onSelected, }) {
  const [deleteConfOpen, openDeleteConf, closeDeleteConf] = useModal();


  async function _onDelete() {
    closeDeleteConf();
    onDelete(value.id);
  }


  return (
    <>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        <Dropdown
          items={items}
          value={value ? value.id : ''}
          setValue={onSelected}
          labelKey="name"
        />

        {
          (null != value) ?
          <>
            <IconButton
              aria-label="edit"
              onClick={() => onEdit(value)}
            >
              <EditIcon />
            </IconButton>

            <IconButton
              aria-label="delete"
              onClick={openDeleteConf}
            >
              <DeleteIcon
                style={{
                  color: red[500],
                }}
              />
            </IconButton>
          </> :
          null
        }

        <IconButton
          aria-label="add"
          onClick={onCreate}
        >
          <AddCircleIcon />
        </IconButton>
      </Grid>

      <DeleteDialog
        open={deleteConfOpen}
        confirmationMessage={`Tem certeza que deseja excluir ${value != null ? value.name : ''}?`}
        onClose={closeDeleteConf}
        onConfirm={_onDelete}
      />
    </>
  );
}
