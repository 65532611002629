import React, { useState, useEffect, memo, } from 'react';
import { useSelector, } from 'react-redux';

import { ListGroup, Badge } from 'react-bootstrap';
import { Grid, Collapse, Container, Paper, Button } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import ContainerShow from './ContainerShow';
import ActionContainer from '../CorpoPlano/ActionPlanBody';

import { getUsers, } from '../../services/api';

import { selectCompanySectorById, } from '../../features/companySectorsSlice';


/**
 * Component to manage actions
 *
 * @param {object} data - action data
 * @param {array} - company meetings
 * @param {array} - array of users to be used
 *
 * @returns rendered component
 */
const AnalisRisc = ({ data, reunions, users }) => {
  const [openList, setOpenList] = useState(false);
  const [observacao, setObservacao] = useState([]);
  const [riscClose, setRiscClose] = useState(false);
  const [planoClose, setPlanoClose] = useState(true);
  const [user, setUser] = useState(users || []);
  const sector = useSelector((state) => selectCompanySectorById(state, data.sector_id));
  const [statusBG, setStatusBG] = useState('#607d8b');
  const [statusRisco, setStatusRisco] = useState('Pendente');

  const [statusBorder, setStatusBorder] = useState('');
  const [status, setStatus] = useState('');
  const [statusClassRisco, setStatusClassRiscor] = useState('Sem dados');
  const [value, setValue] = useState(0);


  /**
   * Use effect to update container border color
   */
  useEffect(() => {
    const mediaBorder = data.probabilidade * data.impacto

    if (mediaBorder >= 1 && mediaBorder <= 4) {
      setStatusBorder('green')
      return
    } else if (mediaBorder >= 5 && mediaBorder <= 14) {
      setStatusBorder('yellow')
      return
    } else if (mediaBorder >= 15) {
      setStatusBorder('red')
      return
    } else {
      setStatusBorder('blue')
    }
  }, [data.probabilidade, data.impacto])

  /**
   * Use effect to update action status and status label
   */
  useEffect(() => {
    const media = data.probabilidade * data.impacto

    if (media >= 1 && media <= 4) {
      setStatus('success')
      setStatusClassRiscor('Risco: Baixo')
      return
    } else if (media >= 5 && media <= 14) {
      setStatus('warning')
      setStatusClassRiscor('Risco: Médio')
      return
    } else if (media >= 15) {
      setStatus('danger')
      setStatusClassRiscor('Risco: Alto')
      return
    } else {
      setStatus('info')
      setStatusClassRiscor('Risco não definido')
    }
  }, [data.probabilidade, data.impacto])

  useEffect(() => {
    if (data.status === null) {
      setStatusRisco('Status: Pendente')
      setStatusBG('secondary')
    } else if (data.status === 'Pendente') {
      setStatusRisco('Status: Pendente')
      setStatusBG('secondary')
    } else if (data.status === 'Em andamento') {
      setStatusRisco('Status: Em andamento')
      setStatusBG('warning')
    } else if (data.status === 'Concluido') {
      setStatusRisco('Status: Concluido')
      setStatusBG('success')
    }
  });

  async function getUser() {
    const { data, } = await getUsers();
    setUser(data);
  }

  const tabOptions = [
    {
      label: 'Ação',
      onClick() {
        if (!planoClose) {
          setRiscClose(false);
          setPlanoClose(true);
        }
      },
    },
    {
      label: 'Risco',
      onClick() {
        if (!riscClose) {
          setRiscClose(true)
          setPlanoClose(false)
        }
      },
    },
  ];

  useEffect(() => {
    getUser();
  }, []);


  return (
    <ListGroup>
      <ListGroup.Item
        style={{
          borderColor: statusBorder,
          cursor: 'pointer',
        }}
        onClick={() => setOpenList(!openList)}
        active={openList}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={10}
          >
            <div
              style={{
                display: 'block',
                cursor: 'default',
                marginBottom: '-2rem',
              }}
            >
              Atividade {data.ordem + 1}
              <Badge bg="warning">
                {sector != undefined ? sector.name : 'N/A'}
              </Badge>
              <br />
              <label
                style={{
                  cursor: 'pointer',
                }}
              >
                {data.problema}
              </label>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                }}
              >
                {openList ? <ExpandLess fontSize="large" /> : <ExpandMore fontSize="large" />}
              </div>
            </div>
            <br />
          </Grid>

          <Grid
            item
            xs={12}
            sm={2}
          >
            <Container>
              <Badge
                bg={statusBG}
                color="dark"
                style={{
                  height: '1.1rem',
                  minWidth: '115px',
                  alignItems: 'center',
                  marginRight: '5%',
                }}
              >
                {statusRisco}
              </Badge>{' '}
              <Badge
                bg={status}
                color="dark"
                style={{
                  height: '1.1rem',
                  minWidth: '7.2rem',
                  alignItems: 'center',
                  marginRight: '2%',
                }}
              >
                {statusClassRisco}
              </Badge>
            </Container>
          </Grid>
        </Grid>
      </ListGroup.Item>

      <Collapse in={openList}>
        <Container
          maxWidth="xl"
          component={Paper}
          style={{
            padding: 10,
          }}
        >
          {
            tabOptions.map((tabOption, index) => {
              const isActive = value === index;
              return (
                <Button
                  variant="contained"
                  disableElevation
                  key={index}
                  onClick={() => {
                    setValue(index);
                    tabOption.onClick();
                  }}
                  style={{
                    backgroundColor: isActive ? 'white' : 'transparent',
                    color: isActive ? '#0000FF' : '#404040',
                    border: isActive ? '2px solid #0000FF55' : '2px solid transparent',
                    borderRadius: 0,
                  }}
                >
                  {tabOption.label}
                </Button>
              );
            })
          }
          <Container
            maxWidth="xl"
            style={{
              border: '2px solid #0000FF55',
              marginTop: -2,
            }}
          >
            {riscClose && (
              <ContainerShow
                data={data}
                reunions={reunions}
              />
            )}
            {planoClose && (
              <ActionContainer
                data={data}
                users={user}
                planoClose={planoClose}
                getUser={getUser}
              />
            )}
          </Container>
        </Container>
      </Collapse>
      <br />
    </ListGroup>
  )
}
export default memo(AnalisRisc);
