import { createSlice } from '@reduxjs/toolkit'

export const mensagemSolicitacao = createSlice({
  name: 'mensagensSolicitacao',
  initialState: {
    value: [],
    loading: true,
  },
  reducers: {
    resetMsg: (state) => {
      state.value = []
    },
    setMsg: (state, action) => {
      state.value = action.payload
    },
    loadingTrue: (state) => {
      state.loading = true
    },
    loadingFalse: (state) => {
      state.loading = false
    },
  },
})

export const {
  setMsg,
  resetMsg,
  loadingFalse,
  loadingTrue,
} = mensagemSolicitacao.actions

export default mensagemSolicitacao.reducer
