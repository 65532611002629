import { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
  Grid,
  IconButton,
} from '@material-ui/core';


/**
 * Modal used to edit a group
 *
 * @param {boolean} open - indicates whether the modal is open or not
 * @param {function} onClose - callback function called when the modal is closed
 * @param {function} onSave - callback function called when the user clicks save
 * @param {object} data - object representing the group to be edited
 *
 * @returns Rendered modal component
 */
export default function EditFieldModal ({ open, onClose, data, onSave, }) {
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState({
    name: 'Field name',
  });

  /**
   * Use effect to validate the updated data
   */
  useEffect(() => {
    setIsValid(model.name.length > 0);
  }, [model]);

  /**
   * Use effect to update internal reference
   */
  useEffect(() => {
    setModel(data);
  }, [data]);

  /**
   * Handle click in cancel button
   */
  const onCancel = () => {
    onClose();
  }

  /**
   * Handle click in save button
   */
  const __onSave = async () => {
    setLoading(true);
    await onSave(model);
    setLoading(false);
  }

  /**
   * Handle change in the property name of the group
   *
   * @param {string} name - updated name
   */
  const onChangeName = (name) => {
    setModel({
      ...model,
      name,
    });
  }


  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          backgroundColor: '#3B66FF',
          paddingBottom: 5,
          paddingTop: 5,
          padding: 0,
          paddingLeft: 10,
          width: 500,
          maxWidth: '100%',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="h5"
            style={{
              color: 'white',
            }}
          >
            Editar grupo
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon
              style={{
                color: 'white',
              }}
            />
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <TextField
          value={model.name}
          onChange={(ev) => onChangeName(ev.target.value)}
          label="Nome do grupo"
          fullWidth
          helperText="Como você deseja chamar o grupo?"
          margin="normal"
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onCancel}
          style={{
            borderColor: "#3B66FF",
          }}
          variant="outlined"
        >
          Cancelar
        </Button>
        <Button
          onClick={__onSave}
          disabled={!isValid}
          variant="contained"
          style={{
            backgroundColor: "#3B66FF",
            color: 'white'
          }}
          disableElevation={true}
          loading={loading}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
