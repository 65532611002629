import React from 'react';
import { useSelector, } from 'react-redux';

import SensitiveDataOptionModal from '../SensitiveDataOptionModal';

import {
  listCompanySensitiveDataOptions,
  createCompanySensitiveDataOption,
  updateCompanySensitiveDataOption,
  deleteCompanySensitiveDataOption,
} from '../../services/api';

import { selectCompany, } from '../../features/companySlice';


function CompanySensitiveDataOptionPage({ open, onClose, }) {
  const company = useSelector(selectCompany);


  async function onFetch() {
    const response = await listCompanySensitiveDataOptions(company._id);
    return response.data.options;
  }

  async function onDelete(id) {
    return await deleteCompanySensitiveDataOption(company._id, id);
  }

  async function onUpdate(data) {
    const response = await updateCompanySensitiveDataOption(company._id, data.id, data.name);
    return response.data
  }

  async function onCreate(data) {
    const response = await createCompanySensitiveDataOption(company._id, data.name);
    return response.data;
  }


  return (
    <SensitiveDataOptionModal
      open={true}
      onFetch={onFetch}
      onDelete={onDelete}
      onUpdate={onUpdate}
      onCreate={onCreate}
      onClose={onClose}
      isModal={false}
    />
  );
}

export default CompanySensitiveDataOptionPage;
