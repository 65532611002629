import { useEffect, useState, memo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useDispatch, } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';
import { useLocation, } from 'react-router-dom';

import { TablePagination, Typography, Container, } from '@material-ui/core';
import { Notification, } from 'rsuite';

import Loading from '../../components/Global/Loading';
import PublicPageLayout from '../../components/PublicPageLayout';
import QuestionItem from './QuestionItem';
import PageError from '../../components/PageError';

import {
  getPublicMaturityQuestions,
  getCompanyMaturityChecklistShareLink,
} from '../../services/api';

import { setCompany, } from '../../features/companySlice';
import { listCompanySectors, } from '../../features/companySectorsSlice';
import { listCompanyCategories, } from '../../features/companyCategoriesSlice';

import { errorCodes, } from '../../errors/standard-error';


function PublicMaturityQuestions() {
  const { t, } = useTranslation();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
    total: 0,
  });
  const [errorData, setErrorData] = useState({
    show: false,
    code: '',
  });
  const [questions, setQuestions] = useState([]);
  const [shareLinkData, setShareLinkData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const { search } = useLocation();
  const { slid: shareLinkId, } = queryString.parse(search);


  async function getQuestions() {
    try {
      const { data, } = await getPublicMaturityQuestions(shareLinkId);
      setQuestions(data);
      setPagination((pagination) => ({
        ...pagination,
        page: 0,
        total: data.length,
      }));
      setErrorData({
        show: false,
        code: '',
      });
    } catch (err) {
      if ((undefined != err.response) && (undefined != err.response.data)) {
        setErrorData({
          show: true,
          code: err.response.data.code,
        });
      }
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.PUBLIC_MATURITY_CHECKLIST.failed_to_load_questions')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function getData() {
    try {
      const { data, } = await getCompanyMaturityChecklistShareLink(shareLinkId);
      setShareLinkData(data);
      if (undefined != data && undefined != data.company) {
        dispatch(setCompany(data.company));
        dispatch(listCompanySectors());
        dispatch(listCompanyCategories());
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.PUBLIC_MATURITY_CHECKLIST.failed_to_validate_link')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function initialize() {
    setLoading(true);
    await Promise.all([getData(), getQuestions()]);
    setLoading(false);
  }

  useEffect(() => {
    initialize();
  }, []);

  function handleChangePage(event, newPage) {
    setPagination((pagination) => ({
      ...pagination,
      page: newPage,
    }));
  }

  function handleChangeRowsPerPage(event) {
    setPagination((pagination) => ({
      ...pagination,
      page: 0,
      pageSize: parseInt(event.target.value, 10),
    }));
  }


  return (
    <PublicPageLayout
      title={errorData.show ? '' : _.capitalize(t('PAGES.PUBLIC_MATURITY_CHECKLIST.all_questions'))}
      companyName={undefined != shareLinkData && undefined != shareLinkData.company ? shareLinkData.company.name || '' : ''}
    >
      {
        loading ?
        <Loading value={loading} /> :
        pagination.total > 0 ?
        <Container maxWidth="xl" >
          {
            questions.slice(pagination.page * pagination.pageSize, (pagination.page + 1) * pagination.pageSize).map((question) => (
              <QuestionItem
                key={question.id}
                data={question}
                shareLinkId={shareLinkId}
              />
            ))
          }

          <TablePagination
            rowsPerPageOptions={[
              5,
              10,
              25,
              { label: 'Todos', value: pagination.total },
            ]}
            component="div"
            SelectProps={{
              inputProps: { 'aria-label': 'Linhas' },
              native: true,
            }}
            count={pagination.total}
            rowsPerPage={pagination.pageSize}
            page={pagination.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Container> :
        errorData.show && (errorCodes.LINK_EXPIRED_ERROR == errorData.code) ?
        <PageError
          title={_.capitalize(t('ERRORS.LINK_EXPIRED_ERROR.title'))}
          description={_.capitalize(t('ERRORS.LINK_EXPIRED_ERROR.description'))}
        /> :
        <Typography
          variant="h5"
          display="block"
          align="center"
          style={{
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          {_.capitalize(t('PAGES.PUBLIC_MATURITY_CHECKLIST.no_questions_found'))}
        </Typography>
      }
    </PublicPageLayout>
  );
}

export default memo(PublicMaturityQuestions);
