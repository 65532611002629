import { useEffect, useState } from "react";
import { Form, ProgressBar, Button, Image, Badge } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "../../services/api";
import FormData from "form-data";
import NavBar from "../../components/NavBar/NavBar.js";
import "./styles.css";
import logo from "../../assets/logo 2.png";
import logoLgpd from "../../assets/logo-LGPD.png";

import { Collapse, Container, Paper } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
function MainProfile() {
  
  localStorage.setItem("logo", logoLgpd);
  const user = localStorage.getItem("user");
  useEffect(() => {
    api
      .get(`user?id=${user}`)
      .then((response) => {
        const result = response.data;
        localStorage.setItem("avatar", result.avatar);
        localStorage.setItem("group", result.group);
        localStorage.setItem("email", result.email);
        localStorage.setItem("name", result.name);
        localStorage.setItem("userGoogle", result.userGoogle);
      })
      .catch((err) => {
        alert("erro ao recuperar dados do usuario");
      });
  }, []);
  const photo = localStorage.getItem("avatar");
  const nameLocal = localStorage.getItem("name");
  const mail = localStorage.getItem("email");
  const userGoogle = localStorage.getItem("userGoogle");

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(mail);
  const [name, setName] = useState(nameLocal);
  const [avatar, setAvatar] = useState("");
  const [now, setNow] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);

  useEffect(() => {
    if (userGoogle === true) setDisabled(true);
  }, []);

  const data = new FormData();
  data.append("avatar", avatar);
  data.append("my_buffer", new Buffer(10));

  async function handleResetData() {
    const form = new FormData();
    form.append("avatar", avatar);
    form.append(now, new Buffer(10));
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setNow(percent);
      },
    };
    await api
      .post(
        `/upload/avatar?id=${user}`,

        form,
        options,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then(() => {
        alert("Usuario alterado com sucesso");
      })
      .catch((err) => {
        alert(err);
      });
  }

  const alterUser = async () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
    if (!password) {
      return alert("Digite sua senha para alterar os seus dados");
    }
    await api
      .post(`/user/alter?id=${user}`, {
        name,
        password,
        email,
      })
      .then(() => {
        return handleResetData();
      })
      .catch(({ response }) => {
        alert("erro ao alterar usuario");
      });
  };

  return (
    <div>
      <NavBar >
        <Container style={{ marginTop: "2%" }} component={Paper}>
          <br />
          <Collapse in={disabled}>
            <Alert>
              <AlertTitle>
                As alterações da sua conta devem ser feitas através do google.{" "}
              </AlertTitle>
              <Badge>
                Painel exclusico para usuarios que se registaram via e-mail.
            </Badge>
            </Alert>
            <br />
          </Collapse>
          <form>
            <Image
              src={photo}
              roundedCircle
              width={250}
              height={200}
              style={{ marginLeft: "40%" }}
            />
            <br />
            <label>Nome </label>
            <Form.Control
              disabled={disabled}
              placeholder="Coloque seu nome"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <br />
            <Collapse in={openStatus}>
              <Alert severity="error">Formato do arquivo não suportado</Alert>
            </Collapse>
            <br />
            <Form.File
              label="Escolha sua foto de perfil"
              disabled={disabled}
              onChange={(e) => {
                var n = e.target.files[0].type.search("image");
                if (n >= 0) {
                  setAvatar(e.target.files[0]);
                  setOpenStatus(false);
                } else {
                  setOpenStatus(true);
                }
              }}
            />
            <br />
            <Collapse in={open}>
              <ProgressBar
                now={now}
                animated
                variant="success"
                label={`${now}%`}
              />
            </Collapse>

            <br />
            <label>Email </label>
            <Form.Control
              disabled={disabled}
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <br />
            <label>Senha</label>
            <Form.Control
              disabled={disabled}
              type="password"
              className="form-control"
              border-color="blue"
              placeholder="Enter password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />

            <br />
            <Button disabled={disabled} onClick={alterUser} block variant="light">
              Alterar
          </Button>
          </form>
          <br />
        </Container>
      </NavBar>
    </div>
  );
}

export default MainProfile;
