import React from 'react';

import { WidgetType, } from '../../entities/impact-report-widget';

import BaseWidget from './BaseWidget';
import TextWidget from './TextWidget';
import ObjectiveWidget from './ObjectiveWidget';
import SignaturesWidget from './SignaturesWidget';
import ImageWidget from './ImageWidget';
import RevisionHistoryWidget from './RevisionHistoryWidget';
import RiskIdentificationsAndAssessmentsWidget from './RiskIdentificationsAndAssessmentsWidget';
import RiskTreatmentMeasuresWidget from './RiskTreatmentMeasuresWidget';
import mappingWidget from './mappingWidget';


const FIELD_COMPONENT_MAP = Object.freeze({
  [WidgetType.TEXT]: TextWidget,
  [WidgetType.OBJECTIVE]: ObjectiveWidget,
  [WidgetType.SIGNATURES]: SignaturesWidget,
  [WidgetType.IMAGE]: ImageWidget,
  [WidgetType.REVISION_HISTORY]: RevisionHistoryWidget,
  [WidgetType.RISK_IDENTIFICATIONS_AND_ASSESSMENTS]: RiskIdentificationsAndAssessmentsWidget,
  [WidgetType.RISK_TREATMENT_MEASURES]: RiskTreatmentMeasuresWidget,
  [WidgetType.MAPPING_TO_RIPD]: mappingWidget,
});


/**
 * @param {function} updateWidget - API call to update widget
 * @param {function} onChange - update local widgets state
 */
function ImpactReportWidget({
  data,
  readOnly,
  onCreateWidget = (position) => {},
  onDeleteWidget = (widgetId) => {},
  updateWidget = (updatedWidget) => {},
  onChange = (updatedWidget) => {},
  isPrintMode,
}) {
  const Widget = FIELD_COMPONENT_MAP[data.type];

  return (
    <BaseWidget
      data={data}
      readOnly={readOnly}
      updateWidget={updateWidget}
      onCreateWidget={onCreateWidget}
      onDeleteWidget={onDeleteWidget}
      isPrintMode={isPrintMode}
      render={() => {
        return (
          <Widget
            data={data}
            readOnly={readOnly}
            onChange={onChange}
          />
        );
      }}
    />
  );
}

export default React.memo(ImpactReportWidget);
