import { useEffect, useState } from 'react';
import { useSelector, } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { Dialog, DialogTitle, Paper, } from '@material-ui/core';
import { Container, Form, } from 'react-bootstrap';
import { Icon, Notification, } from 'rsuite';

import { getUserCompanies, simpleUserUpdate, listAffiliateCompanies, } from '../../services/api';
import BodyModalConfigUsers from './BodyModalConfigUsers';
import ComboBox from '../../components/ComboBox';
import CompaniesTable from '../../components/CompaniesTable';
import { Box, Grid, Grid2 } from '@mui/material';


/**
 * Modal to manage user info
 *
 * @param {boolean} open - indicates if the modal is open or not
 * @param {function} onClose - callback function called when the modal closed
 * @param {object} infoUsers - user info
 *
 * @returns Rendered component
 */
export default function ModalConfigUser({ open, onClose, infoUsers, }) {
  const [userData, setUserData] = useState({});

  const [editName, setEditName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);

  const [userCompanies, setUserCompanies] = useState([]);
  const [affiliateCompanies, setAffiliateCompanies] = useState([]);
  const { groups, } = useSelector(state => state.groups);

  /**
   * Fetch the companies that the user has access from the API
   */
  const getCompanies = async () => {
    try {
      const { data, } = await getUserCompanies(infoUsers._id);
      setUserCompanies(data.companies);
    } catch {
      console.log('Failed get user companies');
    }
  }

  /**
   * Handle change in user data
   *
   * @param {object} ev - event that triggered the change
   */
  function updateUserData(ev) {
    setUserData({
      ...userData,
      [ev.target.name]: ev.target.value,
    });
  }

  /**
   * Handle change in selected group for user
   *
   * @param {string} selectedGroupId - selected group ID
   */
  function onUserGroupChange(selectedGroupId) {
    if (null != selectedGroupId) {
      setUserData({
        ...userData,
        group: selectedGroupId,
      });
    }
  }

  const handleClose = () => {
    setEditName(false);
    setEditEmail(false);
    setEditPhone(false);
    setUserCompanies([]);
    onClose();
  }

  const updateUser = async () => {
    try {
      await simpleUserUpdate(infoUsers._id, userData);

      return Notification['success']({
        placement: 'bottomEnd',
        title: 'Tudo certo!',
        description: 'Usuário alterado com sucesso',
      });
    } catch {
      return Notification['error']({
        placement: 'bottomEnd',
        title: 'Atenção!',
        description: 'Erro ao alterar o usuário',
      });
    }
  }

  async function getAffiliateLinkCompanies() {
    try {
      const { data, status, } = await listAffiliateCompanies(infoUsers._id);
      if (200 == status) {
        setAffiliateCompanies(data.companies);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (open) {
      getCompanies();
    } else {
      setAffiliateCompanies([]);
    }
  }, [open]);

  useEffect(() => {
    setUserData(_.pick(infoUsers, ['name', 'email', 'group', 'tel']));
    if (infoUsers._id) {
      getAffiliateLinkCompanies();
    }
  }, [infoUsers]);


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      className="ContainerModalConfig"
    >
      <DialogTitle className="TitleModalConfig">
        Configurações do Usuário
        <Icon
          icon="close"
          onClick={handleClose}
        />
      </DialogTitle>
      <Container
        component={Paper}
      >

        <Grid container spacing={2} style={{ padding: "20px" }}>
          <Grid item xs order={{ xs: 2, sm: 1 }}>
            <div className="">
              <div className="boxAllName">
                <span onClick={() => setEditName(true)}>
                  Nome
                  <Icon icon="edit" />
                </span>
                {editName ? (
                  <Form.Control
                    onChange={updateUserData}
                    value={userData.name}
                    type="text"
                    name="name"
                  />
                ) : (
                  <h5>
                    {userData.name}
                  </h5>
                )}
              </div>

              <div className="boxAllEmail">
                <span onClick={() => setEditPhone(true)}>
                  Tel
                  <Icon icon="edit" />
                </span>
                {editPhone ? (
                  <Form.Control
                    onChange={updateUserData}
                    value={userData.tel}
                    type="text"
                    name="tel"
                  />
                ) : (
                  <h5>
                    {userData.tel}
                  </h5>
                )}
              </div>

              <div className="boxAllEmail">
                <span onClick={() => setEditEmail(true)}>
                  Email
                  <Icon icon="edit" />
                </span>
                {editEmail ? (
                  <Form.Control
                    onChange={updateUserData}
                    value={userData.email}
                    type="text"
                    name="email"
                  />
                ) : (
                  <h5>
                    {userData.email}
                  </h5>
                )}
              </div>

              <div className="boxAllCreatedAt">
                <span>Usuário desde</span>
                <h5>
                  {moment(infoUsers.createdAt).format('DD/MM/YYYY')}
                </h5>
              </div>
            </div>
          </Grid>
          <Grid item order={{ xs: 1, sm: 2 }}>
            <ComboBox
              items={groups}
              value={userData.group}
              setValue={onUserGroupChange}
              label="Grupo"
            />
          </Grid>
        </Grid>

        <div style={{ padding: 20, flexDirection: "row-reverse", display: "flex", justifyContent: "space-between" }}>
        </div>


        <div className="NewBoxBodyUsers">
          <div className="TitleBodyUsers">
            <h3>Empresas que o usuário tem acesso</h3>
          </div>
          <div className="BoxNewTable">
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Data de criação</th>
                  <th>Parceiros</th>
                  <th>Acessar</th>
                  <th>Progresso</th>
                  <th>Aderência</th>
                </tr>
              </thead>
              <tbody className="BodyNewTable">
                {userCompanies.map((company) => {
                  return (
                    <BodyModalConfigUsers
                      key={company._id}
                      body={company}
                    />
                  )
                })}
              </tbody>
            </table>
          </div>

          <div
            className="TitleBodyUsers"
            style={{
              marginTop: '1rem'
            }}
          >
            <h3>Empresas criados com o link de parceiro</h3>
          </div>
          <CompaniesTable
            companies={affiliateCompanies}
            groups={groups}
          />
        </div>


        <div className="box-BTN-botton-modal">
          <button
            onClick={handleClose}
            className="BTN-Cancelar"
          >
            Cancelar
          </button>
          <button
            className="BTN-Salvar"
            onClick={updateUser}
          >
            Salvar
          </button>
        </div>
      </Container>
    </Dialog>
  );
}
