import React from 'react';
import { useTranslation, } from 'react-i18next';
import { useSelector, } from 'react-redux';

import { createStyles, makeStyles, } from '@material-ui/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Container from '@material-ui/core/Container';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import DeleteDialog from '../DeleteDialog';

import useModal from '../../hooks/useModal';

import {
  selectIsGlobal,
} from '../../features/impactReportsSlice';

import { REQUIRED_WIDGET_TYPES, } from '../../entities/impact-report-widget';


const useStyles = makeStyles(() =>
  createStyles({
    root: {
      overflow: 'hidden',
      borderRadius: '10px',
      outline: ({ isGlobal, }) =>  isGlobal ? '1px solid #DDD' : undefined,
      '&:hover': {
        outline: '1px solid #95ADC3',
      },
    },
    accordion: {
      overflow: 'hidden',
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
    },
    accordionSummary: {
      backgroundColor: '#3B66FF',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      minHeight: '30px !important',
      padding: '0px 5px',
    },
    accordionSummaryContent: {
      margin: '0 !important',
      flexDirection: 'row-reverse',
      padding: 1,
    },
    actionButton: {
      margin: '0.2rem 0',
      backgroundColor: '#fff',
      borderLeft: '5px solid #3B66FF',
      cursor: 'pointer',
      padding: '0.1rem',
      borderRadius: '5px',
    },
  }),
);

export default function BaseWidget({
  data,
  readOnly,
  render,
  onCreateWidget = (position) => {},
  onDeleteWidget = (widgetId) => {},
  updateWidget = (updatedWidget) => {},
  isPrintMode,
}) {
  const isGlobal = useSelector(selectIsGlobal);
  const { t, } = useTranslation();
  const classes = useStyles({ isGlobal, });
  const initialized = React.useRef(false);
  const [expanded, setExpanded] = React.useState(true);
  const [showActionButtons, setShowActionButtons] = React.useState(false);
  const [deleteConfirmationOpen, onOpenDeleteConfirmation, onCloseDeleteConfirmation] = useModal();
  const isDeleteDisabled = REQUIRED_WIDGET_TYPES.includes(data.type);


  const toggleAccordion = () => {
    setExpanded((expanded) => !expanded);
  };

  React.useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      return;
    }

    if (readOnly) {
      return;
    }

    const timeoutId = setTimeout(() => {
      updateWidget(data);
    }, 500);

    return function cleanup () {
      clearTimeout(timeoutId);
    };
  }, [data.model]);


  return (
    <Grid
      container
      onMouseEnter={() => setShowActionButtons(true)}
      onMouseLeave={() => setShowActionButtons(false)}
    >
      <Fade in={!readOnly && showActionButtons} >
        <Grid
          item
          style={{
            marginRight: '0.4rem',
          }}
        >
          <Grid
            container
            direction="column"
          >
            <div
              className={classes.actionButton}
              style={{
                marginTop: 0,
              }}
              onClick={() => onCreateWidget(data.position + 1)}
            >
              <IconButton size="small">
                <AddCircleOutlineIcon />
              </IconButton>
            </div>

            <div
              className={classes.actionButton}
              style={{
                marginBottom: 0,
                borderLeft: isDeleteDisabled ? '5px solid #808080' : undefined,
              }}
              onClick={() => {
                if (!isDeleteDisabled) {
                  onOpenDeleteConfirmation();
                }
              }}
            >
              <IconButton
                size="small"
                disabled={isDeleteDisabled}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Fade>

      <Grid
        item
        xs={true}
      >
        <div className={classes.root}>
          <Accordion
            expanded={expanded}
            onChange={toggleAccordion}
            className={classes.accordion}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.accordionSummary}
              classes={{
                content: classes.accordionSummaryContent,
              }}
            >
              {!isPrintMode &&
                <IconButton
                  aria-label="delete"
                  size="small"
                  style={{
                    display: 'flex',
                    transition: 'transform 0.3s ease',
                    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                >
                  <ExpandMoreIcon
                    style={{
                      color: 'white',
                      fontSize: 22,
                    }}
                  />
                </IconButton>
              }
            </AccordionSummary>

            <AccordionDetails>
              <Container maxWidth="xl" >
                {render()}
              </Container>
            </AccordionDetails>
          </Accordion>
        </div>
      </Grid>

      <DeleteDialog
        open={deleteConfirmationOpen}
        confirmationMessage={t('PAGES.IMPACT_REPORTS.v2.delete_confirmation')}
        onClose={onCloseDeleteConfirmation}
        onConfirm={() => {
          onCloseDeleteConfirmation();
          onDeleteWidget(data.id);
        }}
      />
    </Grid>
  );
}
