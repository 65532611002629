import { useState, } from 'react';
import _ from 'lodash';
import { useSelector, } from 'react-redux';
import { useTranslation, } from 'react-i18next';

import { Notification, } from 'rsuite';
import Paper from '@material-ui/core/Paper';

import TemplateDialog from './TemplateDialog';
import DueDiligenceTemplateList from '../../components/DueDiligenceTemplateList';

import useModal from '../../hooks/useModal';
import {
  createDueDiligenceTemplate,
  updateDueDiligenceTemplate,
  deleteDueDiligenceTemplate,
} from '../../services/api';

import { selectCompany, } from '../../features/companySlice';


export default function DueDiligenceTemplates({
  templates,
  onNewTemplate,
  onTemplateUpdated,
  onTemplateDeleted,
}) {
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const [loading, setLoading] = useState(false);
  const [templateModalOpen, openTemplateModal, closeTemplateModal] = useModal(onSelectTemplate,
    onSelectTemplate);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const selectedTemplate = templates.find((item) => item.id === selectedTemplateId);


  function onSelectTemplate(id) {
    setSelectedTemplateId(id);
  }

  async function onDeleteTemplate(templateId) {
    try {
      await deleteDueDiligenceTemplate(company._id, templateId);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DUE_DILIGENCE.template_successfully_deleted')),
      });
      onTemplateDeleted(templateId);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_delete_template')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function editTemplate(data) {
    setLoading(true);
    try {
      const { data: template, } = await updateDueDiligenceTemplate(company._id, data.id, data);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DUE_DILIGENCE.template_successfully_updated')),
      });
      onTemplateUpdated(template);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_update_template')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
    setLoading(false);
  }

  async function createTemplate(data) {
    setLoading(true);
    try {
      const { data: template, } = await createDueDiligenceTemplate(company._id, data);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DUE_DILIGENCE.template_successfully_created')),
      });
      onNewTemplate(template);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_create_template')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
    setLoading(false);
  }


  return (
    <>
      <DueDiligenceTemplateList
        templates={templates}
        onDelete={onDeleteTemplate}
        onUpdate={editTemplate}
        onCreate={createTemplate}
        onOpen={openTemplateModal}
        component={Paper}
        isGlobalReadOnly={true}
      />

      <TemplateDialog
        open={templateModalOpen}
        template={selectedTemplate}
        onClose={() => closeTemplateModal()}
      />
    </>
  );
}
