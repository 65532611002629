import React from 'react';

import Sheet from '../../components/Sheet';
import IncidentRegistry from './NotificacoesPrivacidadePage';

const PAGES = Object.freeze({
  editar: {
    id: 'editar',
    titleTab: "Editar",
    titlePage: "Editar",
    component: <IncidentRegistry />,
  },
});

const ARRAY_PAGES = Object.values(PAGES);

export default function MapNotificacoesPrivacidade() {

  return (
    <Sheet pages={ARRAY_PAGES} />
  );
}
