import { createSlice } from '@reduxjs/toolkit';

const FILTERS = Object.freeze({
    SETOR: {
        id: 'SETOR',
        title: 'SETOR'
    },
    REUNIAO: {
        id: 'REUNIAO',
        title: 'REUNIÃO'
    },
    RESPONSAVEL: {
        id: 'RESPONSAVEL',
        title: 'RESPONSÁVEL'
    },
});

const ARRAY_FILTERS = Object.values(FILTERS);

const planoAcaoStore = createSlice({
    name: 'planAction',
    initialState: {
        statusMenuLeft: false,
        currentMeeting: null,
        currentSector: '',
        totalClosedItens: '0',
        totalInProgress: '0',
        totalPending: '0',
        filters: ARRAY_FILTERS,
        users: null,
        selectedFilters: [
            {
                id: 1,
                type: null,
                value: null
            }
        ]
    },

    reducers: {
        setStatusMenuLeft(state, action) {
            state.statusMenuLeft = action.payload;
        },
        setCurrentMeeting(state, action) {
            state.currentMeeting = action.payload;
        },
        setCurrentSector(state, action) {
            state.currentSector = action.payload;
        },
        setTotalItens(state, action) {
            switch (action.payload.name) {
                case 'CLOSED':
                    state.totalClosedItens = action.payload.value.toString();
                    break;
                case 'IN_PROGRESS':
                    state.totalInProgress = action.payload.value.toString();
                    break;
                case 'PENDING':
                    state.totalPending = action.payload.value.toString();
                    break;
                default:
                    break;
            }
        },
        setSelectedFilters(state, action) {
            state.selectedFilters[action.payload.index].type = action.payload.type;
        },
        setValueFilter(state, action) {
            state.selectedFilters[action.payload.index].value = action.payload.value;
        },
        setUsers(state, action) {
            state.users = action.payload;
        },
        setNewLineFilter(state, action) {
            state.selectedFilters = [...state.selectedFilters, {
                id: state.selectedFilters.length ? state.selectedFilters[state.selectedFilters.length - 1].id + 1 : 1,
                type: null,
                value: null
            },];
        },
        removeLineFilter(state, action) {
            const totalItens = state.selectedFilters.length;
            state.selectedFilters.splice(action.payload, 1);
            if (totalItens == 1) {
                state.selectedFilters = [{
                    id: 1,
                    type: null,
                    value: null
                }]
            }
        },
        removeAllFilters(state, action) {
            state.selectedFilters = [
                {
                    id: 1,
                    type: null,
                    value: null
                }
            ]
        }
    }
});

export const {
    setStatusMenuLeft,
    setCurrentMeeting,
    setCurrentSector,
    setTotalItens,
    setSelectedFilters,
    setNewLineFilter,
    setValueFilter,
    removeLineFilter,
    removeAllFilters
} = planoAcaoStore.actions;

export default planoAcaoStore.reducer
