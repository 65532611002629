import React from 'react';


export default function ChartLegend({ color, label, size = 'medium', }) {
  const sizeValue = 'small' === size ? '1rem' : '1.5rem';
  const fontValue = 'small' === size ? '0.75rem' : '1rem';


  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: sizeValue,
          height: sizeValue,
          borderRadius: 500,
          marginRight: '0.3rem',
          background: color,
        }}
      ></div>
      <p
        style={{
          color: '#54708C',
          fontSize: fontValue,
        }}
      >
        {label}
      </p>
    </div>
  );
}
