import React, { useLayoutEffect, useRef, useState, } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Formik, Field, FieldArray, } from 'formik';
import { Notification, Button, } from 'rsuite';
import { useParams, } from 'react-router-dom';

import {
  Container,
  Grid,
  Divider,
  Typography,
} from '@material-ui/core';

import { answerCourseTestSchema, } from '../../validation-schemas';
import Error from '../Error';
import AnswerQuestionOptionsForm from './AnswerQuestionOptionsForm';
import useQuery from '../../hooks/useQuery';
import { answerCourseTest, } from '../../services/api';


const useStyles = makeStyles({
  submitButton: {
    marginLeft: '1rem',
  },
  buttonContainer: {
    marginTop: '1rem',
  },
  question: {
    marginBottom: '0.50rem',
  }
});

const INITIAL_VALUES = Object.freeze({
  id: 0,
  questions: [],
});


export default function CourseTestAnswerForm({ test, course, }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const formikRef = useRef(null);

  const { courseId, } = useParams();
  const query = useQuery();


  /**
   * Send course test answer to the API
   */
  async function sendAnswer(data) {
    setLoading(true);

    const companyId = course.company_id;
    const testId = test.id;

    const reqData = {
      collaboratorId: query.get('t'),
      answers: [],
    };

    for (const question of data.questions) {
      const alternative = question.alternatives.find((alt) => alt.isCorrect == true);
      reqData.answers.push({
        questionId: question.id,
        alternativeId: alternative.id,
      });
    }

    try {
      await answerCourseTest(companyId, courseId, testId, reqData);
      Notification['success']({
        placement: 'bottomEnd',
        title: 'Resposta enviada com sucesso!',
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao enviar resposta!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    } finally {
      setLoading(false);
    }
  }

  /**
   * Clear modal
   */
  function clearModal() {
    formikRef.current.resetForm({
      values: INITIAL_VALUES,
    });
  }

  useLayoutEffect(() => {
    const _test = test != undefined ? _.cloneDeep(test): INITIAL_VALUES;
    if (formikRef.current != null) {
      formikRef.current.resetForm({
        values: _test,
      });
    }
  }, [test]);


  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={sendAnswer}
      validationSchema={answerCourseTestSchema}
      innerRef={formikRef}
    >
    {
      (formik) => {
        return (
          <Container disableGutters >

            <FieldArray name="questions">
              {
                () => (
                  <Container disableGutters >
                    {
                      formik.values.questions &&
                      formik.values.questions.length > 0 &&
                      formik.values.questions.map((question, idx) => {
                        return (
                          <div key={idx}>
                            <Field name={`questions[${idx}].statement`} >
                              {
                                ({ field, }) => {
                                  return (
                                    <>
                                      <Typography
                                        display="block"
                                        variant="caption"
                                      >
                                        { `Pergunta ${idx + 1}` }
                                      </Typography>
                                      <Typography
                                        display="block"
                                        variant="body1"
                                        className={classes.question}
                                      >
                                        {field.value}
                                      </Typography>
                                    </>
                                  );
                                }
                              }
                            </Field>

                            <AnswerQuestionOptionsForm questionIdx={idx} />

                            <Error name={`questions[${idx}].alternatives`} />
                            <Divider />
                            <br />
                          </div>
                        );
                      })
                    }
                  </Container>
                )
              }
            </FieldArray>

            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              className={classes.buttonContainer}
            >
              <Button
                appearance="ghost"
                loading={loading}
                onClick={clearModal}
              >
                Cancelar
              </Button>

              <Button
                className={classes.submitButton}
                appearance="primary"
                loading={loading}
                onClick={formik.handleSubmit}
              >
                Finalizar
              </Button>
            </Grid>
          </Container>
        );
      }
    }
    </Formik>
  );
}
