import React, { useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch, } from 'react-redux';
import { useTranslation, } from 'react-i18next';

import { Notification, } from 'rsuite';
import { Container, Paper, Dialog, DialogTitle, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import ImportExportIcon from '@material-ui/icons/ImportExport';
import BookIcon from '@material-ui/icons/Book';

import Loading from '../../components/Global/Loading'
import Notifications from '../../utils/Notification';
import EditDataMappingContent from '../../components/EditDataMapping';
import CloneDataMappingTabForm from '../../components/CloneDataMappingTabForm';
import EditCompanyDataMappingContextProvider from './EditCompanyDataMappingContextProvider';
import OctoDialog from '../../components/Dialog';
import SaveAsTemplateForm from '../../components/SaveAsTemplateForm';
import ImportTemplateForm from '../../components/ImportTemplateForm';

import {
  copyDataMappingPhase,
  saveDataMappingAsTemplate,
  importDataMappingTemplate,
} from '../../services/api';

import { selectCompany, } from '../../features/companySlice';

import EditDataMappingContext from '../../components/EditDataMapping/context';

import useModal from '../../hooks/useModal';

import {
  selectTemplates,
  listDataMappingTemplates,
} from '../../features/dataMappingTemplatesSlice';
import { setGlobalMenuFixed } from '../../features/fixedMenuPages';
import PartialCustomMenuFixed from '../../components/CustomMenuFixed/PartialCustomMenuFixed';


const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));


function EditDataMapping() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const dataMappingTemplates = useSelector(selectTemplates);
  const { phases, loading, listPhases, } = React.useContext(EditDataMappingContext);
  const [cloneTabModalOpen, openCloneTabModal, handleClose] = useModal();
  const [dialOpen, onOpenDial, onCloseDial] = useModal();
  const [importTemplateModalOpen, onOpenImportTemplateModal, onCloseImportTemplateModal] =
    useModal();
  const [saveAsTemplateModalOpen, onOpenSaveAsTemplateModal, onCloseSaveAsTemplateModal] =
    useModal();

  useEffect(() => {
    dispatch(setGlobalMenuFixed(false))
    return () => {
      dispatch(setGlobalMenuFixed(true))
    }
  }, []);

  const actions = [
    {
      icon: <BookIcon />,
      name: _.capitalize(t('PAGES.save_as_template')),
      onClick: onOpenSaveAsTemplateModal,
    },
    {
      icon: <ImportExportIcon />,
      name: _.capitalize(t('PAGES.import_template')),
      onClick: onOpenImportTemplateModal,
    },
  ];


  function copyPhase({ provider: provedora, receiver: recebedora, }) {
    if (provedora === 0 || recebedora === 0) {
      return Notifications('warning', 'Selecione uma etapa para copiar', 'Ops!');
    } else if (provedora === recebedora) {
      return Notifications('warning', 'Selecione uma etapa diferente', 'Ops!');
    }

    copyDataMappingPhase(company._id, provedora, recebedora)
      .then(() => {
        handleClose();
        Notifications('success', 'Aba copiada com sucesso!', 'Tudo certo!');
      })
      .catch(() => {
        Notifications('error', 'Erro ao copiar aba!', 'Ops!');
      });
  }

  async function onImportTemplate(data) {
    try {
      onCloseImportTemplateModal();
      await importDataMappingTemplate(company._id, data);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DATA_MAPPING.template_successfully_imported')),
      });
      listPhases();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DATA_MAPPING.failed_to_import_template')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function onSaveAsTemplate(data) {
    try {
      onCloseSaveAsTemplateModal();
      await saveDataMappingAsTemplate(company._id, {
        template: data.createNew ? undefined : data.template,
        name: data.createNew ? data.name : undefined,
      });
      dispatch(listDataMappingTemplates());
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DATA_MAPPING.successfully_saved_data_mapping_as_template')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DATA_MAPPING.failed_to_save_as_template')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  const MENU_FIXED = Object.freeze({
    import: {
      id: 'import',
      title: 'Importar templates',
      icon: <></>,
      onClick: () => onOpenImportTemplateModal(),
    },
    save: {
      id: 'save',
      title: 'Salvar como template',
      icon: <></>,
      onClick: () => onOpenSaveAsTemplateModal(),
    }
  });


  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={cloneTabModalOpen}
      >
        <DialogTitle id="simple-dialog-title">
          Configurações da aba
        </DialogTitle>
        <CloneDataMappingTabForm
          tabs={phases}
          onCancel={handleClose}
          onSubmit={copyPhase}
        />
      </Dialog>

      {
        loading ?
          <Loading value={loading} /> :
          <Container
            component={Paper}
            maxWidth="xl"
          >
            <EditDataMappingContent phases={phases} />
          </Container>
      }

      {/* <SpeedDial
        direction="up"
        open={dialOpen}
        ariaLabel="speed-dial"
        icon={<SpeedDialIcon />}
        className={classes.speedDial}
        onOpen={onOpenDial}
        onClose={onCloseDial}
      >
        {
          actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.onClick}
            />
          ))
        }
      </SpeedDial> */}

      <PartialCustomMenuFixed menuFixed={MENU_FIXED}/>

      <OctoDialog
        open={saveAsTemplateModalOpen}
        onClose={onCloseSaveAsTemplateModal}
        title={_.capitalize(t('PAGES.save_as_template'))}
      >
        <SaveAsTemplateForm
          items={dataMappingTemplates.filter((item) => !item.isGlobal)}
          onSubmit={onSaveAsTemplate}
          onCancel={onCloseSaveAsTemplateModal}
        />
      </OctoDialog>

      <OctoDialog
        open={importTemplateModalOpen}
        onClose={onCloseImportTemplateModal}
        title={_.capitalize(t('PAGES.import_template'))}
      >
        <ImportTemplateForm
          items={dataMappingTemplates}
          onSubmit={onImportTemplate}
          onCancel={onCloseImportTemplateModal}
          warning={(
            <>
              {_.capitalize(t('PAGES.DATA_MAPPING.import_warning1'))}
              <strong>
                {` ${t('PAGES.DATA_MAPPING.import_warning2')}`}
              </strong>
            </>
          )}
        />
      </OctoDialog>
    </>
  );
}

export default function EditDataMappingWrapper() {
  return (
    <EditCompanyDataMappingContextProvider>
      <EditDataMapping />
    </EditCompanyDataMappingContextProvider>
  );
}
