export const RISK_STATUSES = Object.freeze({
  VERYLOW: {
    key: 'VERYLOW',
    color: '#31D9A6',
  },
  LOW: {
    key: 'LOW',
    color: '#31D9A6',
  },
  MEDIUM: {
    key: 'MEDIUM',
    color: '#FFB54D',
  },
  HIGH: {
    key: 'HIGH',
    color: '#F73944',
  },
  VERYHIGTH: {
    key: 'VERYHIGTH',
    color: '#F73944',
  },
  UNKNOWN: {
    key: 'UNKNOWN',
    color: '#54708C',
  },
});

export const PROBABILITY_OPTIONS = Object.freeze([
  {
    value: 1,
    key: 'PROBABILITY_OPTIONS.very_low',
  },
  {
    value: 2,
    key: 'PROBABILITY_OPTIONS.low',
  },
  {
    value: 3,
    key: 'PROBABILITY_OPTIONS.moderate',
  },
  {
    value: 4,
    key: 'PROBABILITY_OPTIONS.high',
  },
  {
    value: 5,
    key: 'PROBABILITY_OPTIONS.very_high',
  },
]);

export const IMPACT_OPTIONS = Object.freeze([
  {
    value: 1,
    key: 'IMPACT_OPTIONS.very_low',
  },
  {
    value: 2,
    key: 'IMPACT_OPTIONS.low',
  },
  {
    value: 3,
    key: 'IMPACT_OPTIONS.moderate',
  },
  {
    value: 4,
    key: 'IMPACT_OPTIONS.high',
  },
  {
    value: 5,
    key: 'IMPACT_OPTIONS.very_high',
  },
]);

export default class Risk {
  static calcValue(probability, impact) {
    const i = undefined != impact ? impact : 0;
    const p = undefined != probability ? probability : 0;

    return p * i;
  }

  static getRisk(probability, impact) {
    const value = Risk.calcValue(probability, impact);

    let ret = RISK_STATUSES.UNKNOWN;
    if (value >= 1 && value <= 2) {
      ret = RISK_STATUSES.VERYLOW;
    } else if (value >= 2 && value <= 4) {
      ret = RISK_STATUSES.LOW
    } else if (value >= 5 && value <= 14) {
      ret = RISK_STATUSES.MEDIUM;
    } else if (value >= 15 && value <= 17) {
      ret = RISK_STATUSES.HIGH;
    } else if (value >= 17) {
      ret = RISK_STATUSES.VERYHIGTH;
    }

    return ret;
  }
}
