import { ContentInfoLeft } from "./styles";
import InitialWorkshopImg from '../../assets/workoshop_new_ilustra.svg'
export function FeedbackEnvio() {
  return (
    <ContentInfoLeft >
      <img src={InitialWorkshopImg} alt="Executivo de terno Ilustração" />
      <h1>Sua solicitação foi enviada</h1>
      <p>
        Fique atento ao E-mail cadastrado, para acompanhar o status dessa solicitação
      </p>
    </ContentInfoLeft>
  );
}