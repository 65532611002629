import React from 'react';
import { useSelector, } from 'react-redux';

import { Chip, } from '@material-ui/core';

import BaseWidget from './BaseWidget';

import { getCompanyPersonalDataTypesClassification, } from '../../../services/api';

import { selectCompany, } from '../../../features/companySlice';


function PersonalDataTypeReportWidget(props) {
  const company = useSelector(selectCompany);
  const [personalData, setPersonalData] = React.useState([]);
  const [sensitiveData, setSensitiveData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);


  async function getData() {
    setLoading(true);
    try {
      const response = await getCompanyPersonalDataTypesClassification(company._id);
      setPersonalData(response.data.classification);
      setSensitiveData(response.data.sensitiveData);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    getData();
  }, []);


  return (
    <BaseWidget
      {...props}
      color="#DCE0EE"
      loading={loading}
    >
      <div>
        {
          sensitiveData
            .map((item) => {
              return (
                <Chip
                  key={item.id}
                  label={item.name}
                  color="default"
                  sx={{
                    mr: 1,
                    mb: 1,
                  }}
                />
              );
            })
        }
      </div>
    </BaseWidget>);
}

export default PersonalDataTypeReportWidget;
