import React from 'react';
import { useTranslation, } from 'react-i18next';

import { Typography, } from '@material-ui/core';

import ImpactReportReviewHistoryTable from '../ImpactReportReviewHistoryTable';

import { WidgetType, } from '../../entities/impact-report-widget';


export default function RevisionHistoryWidget({ data, readonly, onChange, }) {
  const { t, } = useTranslation();


  return (
    <>
      <Typography
        variant="h5"
        style={{
          marginBottom: '1rem',
          textAlign: 'center',
        }}
      >
        { t(`PAGES.IMPACT_REPORTS.v2.WIDGETS.${WidgetType.REVISION_HISTORY}.label`) }
      </Typography>

      <ImpactReportReviewHistoryTable />
    </>
  );
}
