import { useState } from "react";

import {
  ContentInfoRight,
  ContentNavInfoRight,
  InfoRight,
  NavWorkShopInfoRight,
} from "./styles";

import { ContainerApresentacaoWorkshop } from "./ContainerRightApresenta";
import { ContainerIntegrantesWorkshop } from "./ContainerRightIntegrantes";
import { ContainerDescriptionWorkshop } from "./ContainerRightDescription";


const TAB_OPTIONS = Object.freeze({
  PRESENTATION: {
    key: 'PRESENTATION',
    label: 'Apresentação',
  },
  DESCRIPTION: {
    key: 'DESCRIPTION',
    label: 'Descrição',
  },
  MEMBERS: {
    key: 'MEMBERS',
    label: 'Integrantes',
  },
});
const TAB_OPTIONS_ARRAY = Object.values(TAB_OPTIONS);


export function ContainerRight({ user, participantes, id, constituicao, setOpenModal, docs, }) {
  const [tab, setTab] = useState(TAB_OPTIONS.PRESENTATION.key);


  return (
    <ContentInfoRight>
      <InfoRight>
        <NavWorkShopInfoRight>
          <ContentNavInfoRight>
            {
              TAB_OPTIONS_ARRAY.map(({ key, label, }) => {
                const selected = (tab === key);
                return (
                  <div
                    key={key}
                    onClick={() => setTab(key)}
                    className={selected ? "active" : " "}
                  >
                    { label }
                  </div>
                );
              })
            }
          </ContentNavInfoRight>
        </NavWorkShopInfoRight>

        {
          TAB_OPTIONS.DESCRIPTION.key === tab ?
          <ContainerDescriptionWorkshop
            id={id}
            constituicao={constituicao}
          /> :
          TAB_OPTIONS.MEMBERS.key === tab ?
          <ContainerIntegrantesWorkshop
            user={user}
            participantes={participantes}
            id={id}
            setOpenModal={setOpenModal}
          /> :
          <ContainerApresentacaoWorkshop
            id={id}
            docs={docs}
          />
        }
      </InfoRight>
    </ContentInfoRight>
  );
}
