import { createSlice } from '@reduxjs/toolkit'

export const fixedMenuPages = createSlice({
	name: 'fixedMenuPages',
	initialState: {
		rigthMenu: [],
		modeLine: true,
		modalIsOpen: false,
		widgetIsVisible: false,
		menuLeftName: '',
		menuRigthIsVisible: false,
		menuLeftIsVisible: false,
		globalMenuFixed: true
	},
	reducers: {
		setModeMenu: (state) => {
			state.modeLine = !state.modeLine;
		},
		replaceMenus: (state, action) => {
			state.rigthMenu = action.payload;
		},
		setModalIsOpen: (state, action) => {
			state.modalIsOpen = action.payload;
		},
		setMenuRigthIsVisible: (state, action) => {
			state.menuRigthIsVisible = action.payload;
			if (action.payload) {
				state.menuLeftIsVisible = true;
			}
		},
		setMenuLeftIsVisible: (state, action) => {
			state.menuLeftIsVisible = action.payload;
		},
		setWidgetIsVisible: (state, action) => {
			state.widgetIsVisible = action.payload;
		},
		setMenuLeftName: (state, action) => {
			state.menuLeftName = action.payload;
		},
		setGlobalMenuFixed: (state, action) => {
			state.globalMenuFixed = action.payload;
		},
	},
})

export const {
	setModeMenu,
	replaceMenus,
	setModalIsOpen,
	setMenuRigthIsVisible,
	setMenuLeftIsVisible,
	setWidgetIsVisible,
	setMenuLeftName,
	setGlobalMenuFixed
} = fixedMenuPages.actions

export default fixedMenuPages.reducer
