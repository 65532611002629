import React from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import useModal from '../../hooks/useModal';


export default function ModalButton({ render, label, }) {
  const { t, } = useTranslation();
  const [open, openModal, closeModal] = useModal();


  return (
    <>
      <div className="boxHeaderPoliticas">
        <h3>
          { label }
        </h3>

        <button onClick={openModal}>
          { _.capitalize(t('view')) }
        </button>
      </div>

      { render({
          open,
          onClose: closeModal,
        })
      }
    </>
  );
}
