import React from 'react';

import PersonalDataTypeModal from '../PersonalDataTypeModal';

import {
  listGlobalPersonalDataTypes,
  createGlobalPersonalDataType,
  updateGlobalPersonalDataType,
  deleteGlobalPersonalDataType,
} from '../../services/api';


function GlobalPersonalDataTypeModal({ open, onClose, }) {
  async function onFetch() {
    const response = await listGlobalPersonalDataTypes();
    return response.data.options;
  }

  async function onDelete(id) {
    return await deleteGlobalPersonalDataType(id);
  }

  async function onUpdate(data) {
    const response = await updateGlobalPersonalDataType(data.id, data);
    return response.data
  }

  async function onCreate(data) {
    const response = await createGlobalPersonalDataType(data);
    return response.data;
  }


  return (
    <PersonalDataTypeModal
      open={open}
      onFetch={onFetch}
      onDelete={onDelete}
      onUpdate={onUpdate}
      onCreate={onCreate}
      onClose={onClose}
    />
  );
}


export default GlobalPersonalDataTypeModal;
