import { Grid, Container, Paper, } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';

import BarChart from '../ChartComponent'
import Label from '../Global/Label'
import QuestionsCategoriesMaturityLevelChart from '../QuestionsCategoriesMaturityLevelChart';


export default function CardsMon({ categoriesReport, }) {
  const { t, } = useTranslation();


  return (
    <Grid
      container
      spacing={1}
      style={{
        marginTop: 20,
      }}
    >
      <Grid
        item
        xs={12}
        sm={5}
      >
        <Label
          description="Status por setor"
          fontSize={20}
        />
        <Container component={Paper}>
          <br />
          <BarChart />
          <br />
        </Container>
      </Grid>

      <Grid
        item
        xs={12}
        sm={7}
      >
        <Label
          description={_.capitalize(t('maturity'))}
          fontSize={20}
        />
        <Container
          component={Paper}
          maxWidth="xl"
          style={{ height: '40rem' }}
        >
          <QuestionsCategoriesMaturityLevelChart data={categoriesReport}/>
        </Container>
      </Grid>
    </Grid>
  );
}
