import { useState, useEffect, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Notification } from 'rsuite';

import Dialog from '../Dialog';
import SaveAsTemplateForm from '../SaveAsTemplateForm';
import LimitedBackdropLoader from '../LimitedBackdropLoader';

import { getUserId, } from '../../utils';
import {
  createQuestionsTemplateFromCompanyQuestions,
  selectNonGlobalQuestionsTemplates,
  listQuestionsTemplates,
  overwriteQuestionsTemplateFromCompanyQuestions,
} from '../../features/questionsTemplatesSlice';
import { selectCompany } from '../../features/companySlice';


function SaveQuestionsAsTemplateFormDialog({ open, onClose, }) {
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const [loading, setLoading] = useState(false);
  const templates = useSelector(selectNonGlobalQuestionsTemplates);


  async function onSubmit(values) {
    setLoading(true);

    try {
      await dispatch(values.createNew ?
        createQuestionsTemplateFromCompanyQuestions({
          companyId: company._id,
          userId: getUserId(),
          name: values.name,
        }) :
        overwriteQuestionsTemplateFromCompanyQuestions({
          companyId: company._id,
          userId: getUserId(),
          templateId: Number(values.template),
        })
      ).unwrap();

      Notification['success']({
        placement: 'bottomEnd',
        title: `Template de tarefas armanezado com sucesso`,
      });

      onClose();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: `Falha ao salvar template de tarefas!`,
        description: 'Tente novamente dentro de alguns instantes.'
      });
    }

    setLoading(false);
  }

  useEffect(() => {
    if (open) {
      dispatch(listQuestionsTemplates({
        userId: getUserId(),
      }));
    }
  }, [open]);


  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Salvar como template de tarefas"
    >
      <SaveAsTemplateForm
        items={templates}
        onCancel={onClose}
        onSubmit={onSubmit}
      />
      <LimitedBackdropLoader open={loading} />
    </Dialog>
  );
}

SaveQuestionsAsTemplateFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SaveQuestionsAsTemplateFormDialog;
