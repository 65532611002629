import React, {useCallback, useState} from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { TableCell, TableRow, IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Table from '../Table';
import Score from '../Score';
import DeleteDialog from '../DeleteDialog';

import useModal from '../../hooks/useModal';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Collapse, TableBody, TableHead, Typography } from '@mui/material';

export default function DueDiligenceRequestsTable({
  loading,
  data,
  showError,
  processingAgentsMap,
  onView,
  onResend,
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState(null);
  const [openRow, setOpenRow] = React.useState(null);
  const [
    confirmModalOpen,
    onOpenConfirmModal,
    onCloseConfirmModal
  ] = useModal((id) => setSelected(id), () => setSelected(null));

  const [dataResend, setDataResend] = useState(null);

  function _onResend() {
    if (null == selected) {
      return;
    }

    const newDate = {
      processingAgents: [dataResend.processingAgentId],
      template: dataResend.template.id
    }

    onResend(newDate);
    onCloseConfirmModal();
  }

  function handleRowClick(id) {
    setOpenRow(openRow === id ? null : id);
  }

  const deletedStyle = useCallback( (item) => {
    return item.processingAgent.isDeleted ? {'font-style': 'italic', color: 'lightgray'} : {};
  }, []);

  return (
    <>
      <Table
        headers={[
          {
            label: 'Envios',
            align: 'center',
          },
          {
            label: _.capitalize(t('PAGES.DUE_DILIGENCE.score')),
            align: 'center',
          },
          {
            label: '',
            align: 'center',
          },
          {
            label: _.capitalize(t('name')),
            align: 'center',
          },
          {
            label: _.capitalize(t('email')),
            align: 'center',
          },
          {
            label: _.capitalize(t('responsible')),
            align: 'center',
          },
          {
            label: _.capitalize(t('template')),
            align: 'center',
          },
          {
            label: _.capitalize(t('PAGES.DUE_DILIGENCE.sent_at')),
            align: 'center',
          },
          {
            label: _.capitalize(t('PAGES.DUE_DILIGENCE.opened_at')),
            align: 'center',
          },
          {
            label: _.capitalize(t('PAGES.DUE_DILIGENCE.finished_at')),
            align: 'center',
          },
          // {
          //   label: '',
          //   align: 'center',
          // },
        ]}
        data={data}
        loading={loading}
        errorMessage={_.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_load_due_diligence_requests'))}
        noDataMessage={_.capitalize(t('PAGES.DUE_DILIGENCE.no_due_diligence_requests'))}
        disablePagination
        showError={showError}
      >
        {
          (item, props) => {

            const firstIten = item[0];

            const isRowOpen = openRow === firstIten.id;
            return (
              <>
                <TableRow {...props}>
                  <TableCell align="center">
                    <div onClick={() => handleRowClick(firstIten.id)} style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor:"pointer",
                      userSelect:"none"
                    }}>

                      <div style={{
                        height: '30px',
                        width: '30px',
                        background: "rgb(189, 189, 189)",
                        border: '3px solid rgb(59 102 255)',
                        borderRadius: '15px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: '600',
                        fontSize: '10px'
                      }}>
                        {item.length}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <Score grade={firstIten.score} />
                  </TableCell>
                  <TableCell>
                    <div style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', justifyContent:"center"}}>
                      {/* <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleRowClick(firstIten.id)}
                        disabled={!(item.length > 1)}
                      >
                        {isRowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton> */}
                      { !firstIten.processingAgent.isDeleted &&
                        <IconButton
                          onClick={() => {onOpenConfirmModal(firstIten.id), setDataResend(firstIten)}}
                          size="small"
                          style={{marginRight: '0.5rem',}}
                        >
                          <SendIcon fontSize="small"/>
                        </IconButton>
                      }

                      <IconButton onClick={() => onView(firstIten.id)} size="small">
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell align="center" style={deletedStyle(firstIten)}>{firstIten.processingAgent.name}</TableCell>
                  <TableCell align="center" style={deletedStyle(firstIten)}>{firstIten.processingAgent.email}</TableCell>
                  <TableCell align="center" style={deletedStyle(firstIten)}>{firstIten.processingAgent.dpoName}</TableCell>
                  <TableCell align="center" style={deletedStyle(firstIten)}>{firstIten.template?.name || ''}</TableCell>
                  <TableCell align="center" style={deletedStyle(firstIten)}>{moment(firstIten.createdAt).format('HH:mm, DD/MM/YYYY')}</TableCell>
                  <TableCell align="center" style={deletedStyle(firstIten)}>{firstIten.openedAt ? moment(firstIten.openedAt).format('HH:mm, DD/MM/YYYY') : '-'}</TableCell>
                  <TableCell align="center" style={deletedStyle(firstIten)}>{firstIten.finishedAt ? moment(firstIten.finishedAt).format('HH:mm, DD/MM/YYYY') : '-'}</TableCell>
                </TableRow>

                {item.slice(1)?.map((obj) => {
                  return (
                    <TableRow style={{ display: isRowOpen ? 'table-row' : 'none' }}>
                      <TableCell align="center" />
                      <TableCell align="center" >
                        <Score grade={obj.score} />
                      </TableCell>
                      <TableCell align="center">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            alignItems: 'center',
                            justifyContent:"center"
                          }}
                        >
                          {
                            !firstIten.processingAgent.isDeleted &&
                            <IconButton
                              onClick={() => {
                                onOpenConfirmModal(obj.id);
                                setDataResend(obj)
                              }}
                              size="small"
                              style={{
                                marginRight: '0.5rem',
                              }}
                          >
                            <SendIcon fontSize="small"/>
                          </IconButton>
                        }

                          <IconButton
                            onClick={() => onView(obj.id)}
                            size="small"
                          >
                            <VisibilityIcon fontSize="small" />
                          </IconButton>
                        </div>
                      </TableCell>

                      <TableCell align="center" style={deletedStyle(obj)}>{obj.processingAgent.name}</TableCell>
                      <TableCell align="center" style={deletedStyle(obj)}>{obj.processingAgent.email}</TableCell>
                      <TableCell align="center" style={deletedStyle(obj)}>{obj.processingAgent.dpoName}</TableCell>
                      <TableCell align="center" style={deletedStyle(obj)}>{obj.template?.name || ''}</TableCell>
                      <TableCell align="center" style={deletedStyle(obj)}>{moment(obj.createdAt).format('HH:mm, DD/MM/YYYY')}</TableCell>
                      <TableCell align="center" style={deletedStyle(obj)}>{obj.openedAt ? moment(obj.openedAt).format('HH:mm, DD/MM/YYYY') : '-'}</TableCell>
                      <TableCell align="center" style={deletedStyle(obj)}>{obj.finishedAt ? moment(obj.finishedAt).format('HH:mm, DD/MM/YYYY') : '-'}</TableCell>
                    </TableRow>
                  )
                })}
              </>
            );
          }
        }
      </Table>

      <DeleteDialog
        onConfirm={_onResend}
        open={confirmModalOpen}
        onClose={onCloseConfirmModal}
        confirmationMessage={_.capitalize(t('PAGES.DUE_DILIGENCE.resend_confirmation'))}
      />
    </>
  );
}
