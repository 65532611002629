import { Container, Dialog, Paper } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Toggle, Radio, Divider } from 'rsuite'
import { useCookies } from 'react-cookie'
import Users from '../Master/Users'
export default function Config({ open, setOpen }) {
  const [value, setValue, removeValue] = useCookies()

  const [check, setCheck] = useState(false)
  const [radioColorOne, setRadioColorOne] = useState(false)
  const [radioColorTwo, setRadioColorTwo] = useState(false)

  useEffect(() => {
    if (value.NavPref) {
      setCheck(true)
    } else {
      setCheck(false)
    }
  }, [setCheck, value])

  useEffect(() => {
    if (!value.navColor) {
      setRadioColorOne(true)
      setRadioColorTwo(false)
    } else {
      setRadioColorOne(false)
      setRadioColorTwo(true)
    }
  }, [value.navColor])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      maxWidth="lg"
    >
      <Container component={Paper} maxWidth="xl">
        <label>
          Aqui você pode escolher algumas definiçoes, como:
        </label>
        <br />
        <strong>Preferencias da sidebar:</strong> <br />
        <label>Aberta ou fechada</label>
        <br />
        <Toggle
          size="lg"
          checkedChildren="Fechada"
          unCheckedChildren="Aberta"
          checked={check}
          onChange={() => {
            if (!check) {
              setCheck(true)
              setValue('NavPref', true)
            } else {
              setCheck(false)
              removeValue('NavPref')
            }
          }}
        />
        <Divider />
        Cor:
        <Radio
          checked={radioColorOne}
          onChange={() => {
            setRadioColorTwo(false)
            setRadioColorOne(true)
            removeValue('navColor')
          }}
        >
          {' '}
          Default
        </Radio>
        <Radio
          checked={radioColorTwo}
          onChange={() => {
            setRadioColorTwo(true)
            setRadioColorOne(false)
            setValue('navColor', '#001219')
          }}
        >
          {' '}
          Black
        </Radio>{' '}
        <Users master={false} sm={parseInt(12)} />
      </Container>
    </Dialog>
  )
}
