import { memo, } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup, FormControl, Button, } from 'react-bootstrap';
import { Icon, } from 'rsuite';
import { IconButton, } from '@material-ui/core';
import { Notification, } from 'rsuite';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import './dpostyles.css';


//? Cookie banners
const COMPLETE_COOKIE_MANAGEMENT = Object.freeze({
  title: 'Peça para o programador colar na TAG <HEAD> do site',
  warnMessage: '',
  url: process.env.REACT_APP_OCTO_ENV === 'prod' ? 'https://c.octo.legal/popup.js' : 'https://dev.c.octo.legal/popup.js',
});


/**
 * Tag banner
 *
 * @param {string} popupId - popup ID
 *
 * @returns Rendered banner
 */
function TagBanner({ popupId, }) {
  const CONFIG = COMPLETE_COOKIE_MANAGEMENT;

  const url = CONFIG.url;
  const __script = `<meta name="octo-website-id" content="${popupId}" />
<script src="${url}" type="module"></script>`;

  /**
   * Copy script to clipboard
   */
  const onCopyScript = async () => {
    await navigator.clipboard.writeText(__script);

    Notification['success']({
      placement: 'bottomEnd',
      title: 'Código copiado com sucesso!',
    });
  }

  return (
    <Form.Group
      controlId="formBasicEmail"
      className="MainPageCookies"
      style={{
        border: '2px solid #3b66ff',
        paddingRight: 15,
        paddingLeft: 15,
        borderRadius: 10,
      }}
    >
      <br />
      <div className="TagOcto">
        <h6
          variant="h6"
          style={{
            color: '#1C265A',
          }}
        >
          {CONFIG.title}
          <br />
          <small
            style={{
              color: 'red',
            }}
          >
            {CONFIG.warnMessage}
          </small>
        </h6>
      </div>
      <br />
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <InputGroup className="mb-3" style={{ width: '80%' }}>
          <FormControl
            as="textarea"
            value={__script}
            readOnly
            rows={2}
            style={{ background: "transparent", border: "none", width: '80%', color:"#3f51b5" }}
          />
        </InputGroup>
      </div>
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <Typography
          style={{
            marginBottom: '20px'
          }}
          variant="body1"
          color="primary"
        >
          <IconButton onClick={() => onCopyScript(popupId)}
            style={{ background: "#fff", padding: 5, marginRight: '10px' }}
            children={<Icon icon="code" style={{ color: "#3b66ff", fontSize: "20px" }} />}
          />
          copiar código HTML
        </Typography>
      </div>
    </Form.Group>
  );
}


/**
 * Component to show how to install the cookie management popup
 *
 * @returns rendered component
 */
function CookiePopupInstallation({ popupId, }) {
  return (
    <>
      <Grid
        container
        spacing={4}
        disabledGutters
        style={{
          marginTop: 1,
        }}
      >
        <Grid
          item
          xs={12}
        >
          <TagBanner
            popupId={popupId}
          />
        </Grid>
      </Grid>
    </>
  );
};

CookiePopupInstallation.propTypes = {
  popupId: PropTypes.string.isRequired,
};

export default memo(CookiePopupInstallation);
