import React, {useState, useEffect, memo, useMemo} from "react";
import { useTranslation, } from 'react-i18next';
import moment from 'moment';

import {Badge, Card,} from "react-bootstrap";
import { Icon } from "rsuite";
import OfflinePinSharpIcon from "@material-ui/icons/OfflinePinSharp";
import { useSelector, } from 'react-redux';

import { getHistoricoObservacoes, } from "../../services/api";
import './styles.css';

import { selectUserById, } from '../../features/usersSlice';
import { selectCollaboratorById, } from '../../features/collaboratorsSlice';
import { selectCompanySectorById, } from '../../features/companySectorsSlice';

import { ModalPlanoAcao2, } from '../CorpoMon/ModalCorpoPlanAcao2';
import {QUESTION_STATUSES, REVERSED_QUESTION_CHANGE_REASONS} from "../../utils/constants";
import {Grid} from "@material-ui/core";


function ActionPlanField({ label, value, defaultValue, }) {
  return (
    <div>
      <label
        for="consequencia"
        className="labelPergunta"
      >
        { label }
      </label>
      <div id="consequencia">
        {
          value ?
          <p>
            { value }
          </p> :
          defaultValue
        }
      </div>
    </div>
  );
}


const CorpoResposta = ({ data, users, meetings, }) => {
  const { t, } = useTranslation();
  const [obs, setObs] = useState([]);
  const [classBackground, setClassBackGround] = useState("");
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const sector = useSelector((state) => selectCompanySectorById(state, data.sector_id));
  const user = useSelector((state) => selectUserById(state, data.user_id));
  const collaborator = useSelector((state) => selectCollaboratorById(state, data.collaborator_id));
  const changeReason = REVERSED_QUESTION_CHANGE_REASONS[data.change_reason];


  const status = useMemo(() => {
    if (!data.reg_resposta) return QUESTION_STATUSES.UNANSWERED ;
    if (data.status === 'Em andamento') return QUESTION_STATUSES.IN_PROGRESS;
    if (data.status === 'Pendente') return QUESTION_STATUSES.ANSWERED_NOT_COMPLIANT;
    if (data.status === 'Concluido') return QUESTION_STATUSES.COMPLIANT;
    return { color: 'info', label: '-' };
  }, [data.status]);


  function handlerCloseModalEdit() {
    setOpenModalEdit(false)
  }

  function backgroundHeader() {
    if (data.classificacao_risco === "Alto") {
        setClassBackGround("HeaderTitle alto")
    } else if (data.classificacao_risco === "Baixo") {
        setClassBackGround("HeaderTitle baixo")
    } else if (data.classificacao_risco === "Médio") {
        setClassBackGround("HeaderTitle medio")
    } else {
      setClassBackGround("HeaderTitle notDefine")
    }
  }

  useEffect(() => {
    getHistoricoObservacoes(data.id).then((response) => {
      const respost = response.data;
      setObs(respost);
    });
    backgroundHeader();
  }, []);

  return (
    <div>
      <Card>
        <ModalPlanoAcao2
          isOpen={openModalEdit}
          onRequestClose={handlerCloseModalEdit}
          users={users}
          reunionUnique={meetings}
          data={data}
        />
        <div className={classBackground}>
          <div className="TitlePergunta" onClick={() => {setOpenModalEdit(true);}}>{data.problema}</div>
        </div>
        <Card.Body>
          <Grid sx={{height: '100%'}}>
            <Badge className="setorAcao" bg="#64CAD8" style={{background: '#64CAD8 !important'}}>{sector?.name || 'N/A'}</Badge>
            <Badge className="riscoAcao" bg="white">{data.classificacao_risco ? `Risco ${data.classificacao_risco}` : 'Não definido'}</Badge>
            <Badge bg={status.color}>{status.label}</Badge>
          </Grid>
          <ActionPlanField
            label="Risco consequência"
            value={data.risco}
            defaultValue="Risco não definido"
          />

          <ActionPlanField
            label="Ação"
            value={data.acao}
            defaultValue="Nenhuma ação definida"
          />

          <ActionPlanField
            label="Como"
            value={data.como}
            defaultValue="Não definido"
          />

          <div className="boxObs">
            <div className="TitleObs">Histórico de observações</div>

            <div className="contentObsActive">
              {
                obs[0] ?
                obs.map((body) => {
                return (
                  <div
                    className="boxObsMap"
                    key={body.id}
                  >
                    <div className="ColunaObs">
                      <p className="LinhaObs">
                        { body.observacao }
                      </p>
                    </div>
                    <div className="ColunaObsData">
                      <p className="LinhaObsData">
                        { new Date(body.data).toLocaleDateString() }
                      </p>
                    </div>
                  </div>
                );
                }) :
                <p className="LinhaObs">Nenhuma observação registrada</p>
              }
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '0.5rem',
            }}
          >
              <div className="acaoRapida">
                {
                  (data.rapida === 1) ?
                  <>
                    <OfflinePinSharpIcon/>Item de ação rapida
                  </> :
                  null
                }
              </div>

            {
              (undefined != changeReason) && ((undefined != user) || (undefined != collaborator)) ?
              <div
                style={{
                  color: '#3B66FF',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                {
                  t(`QUESTION_CHANGE_REASONS.${changeReason}`, {
                    name: undefined != user ? user.name : undefined != collaborator ? collaborator.name : '',
                    date: moment(data.changed_at).format('DD/MM/YY'),
                  })
                }
              </div> :
              null
            }
          </div>
        </Card.Body>
      </Card>

      <br />
    </div>
  );
};

export default memo(CorpoResposta);
