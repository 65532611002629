import {useEffect, useState} from "react";
import {createFolder, listFolders} from "../../../services/document.api";
import {useSelector} from "react-redux";
import {selectCompany} from "../../../features/companySlice";
import useToggle from "../../../hooks/useToggle";


export default function useFolderCreate(onCreated) {

  const company = useSelector(selectCompany);
  const [name, setName] = useState([]);
  const [loading, toggle] = useToggle(false);

  return {
    form: {
      name:{value: name, set: setName}
    },
    loading,
    send: async () => {
      toggle();
      const res = await createFolder(company, {name: name});
      toggle();
      onCreated(res);
    }
  }
}
