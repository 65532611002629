import React from 'react';
import { useSelector, } from 'react-redux';

import { Notification, } from 'rsuite';

import { selectCompany, } from '../../features/companySlice';

import EditDataMappingContext from '../../components/EditDataMapping/context';

import {
  getEtapas,
  createDataMappingPhaseField,
  cadastroEtapa,
  updateDatMappingPhase,
  deleteDataMappingPhase,
  deleteDataMappingField,
  updateDataMappingField,
  updateMappingFieldOrder,
  listDataMappingPhaseFields,
} from '../../services/api';


export default function EditCompanyDataMappingContextProvider({ children, }) {
  const company = useSelector(selectCompany);
  const [phases, setPhases] = React.useState([]);
  const [loading, setLoading] = React.useState(true);


  React.useEffect(() => {
    listPhases();
  }, []);

  async function listPhases() {
    try {
      const { data, } = await getEtapas();
      setPhases(data);
    } catch {
      //TODO: handle error
    }
    setLoading(false);
  }

  async function deletePhase(phaseId) {
    let ret = false;
    try {
      const { status, } = await deleteDataMappingPhase(company._id, phaseId);
      if (status === 200) {
        Notification['success']({
          title: 'Item deletado com sucesso',
          placement: 'bottomEnd',
        });

        setPhases(phases.filter((phase) => phase.id != phaseId));

        ret = true;
      } else {
        Notification['warning']({
          title: 'Item não pode ser deletado',
          placement: 'bottomEnd',
        });
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao deletar o campo.',
      });
    }

    return ret;
  }

  async function updatePhase(updatedPhase) {
    try {
      await updateDatMappingPhase(company._id, updatedPhase);
      Notification['success']({
        title: 'Aba alterada com sucesso',
        placement: 'bottomEnd',
      });

      const _phases = [...phases];
      const idx = _phases.findIndex((phase) => updatedPhase.id == phase.id);
      if (idx > -1) {
        _phases[idx] = {
          ..._phases[idx],
          ...updatedPhase,
        };
        setPhases(_phases);
      }
    } catch {
      Notification['error']({
        title: 'Falha ao alterar aba',
        placement: 'bottomEnd',
      });
    }
  }

  async function createPhase(phase) {
    try {
      const { data, } = await cadastroEtapa(company._id, phase);
      Notification["success"]({
        title: "Categoria cadastrada com sucesso",
        placement: "bottomEnd",
      });

      setPhases([
        ...phases,
        data,
      ]);
      return data;
    } catch {
      Notification["error"]({
        title: "Categoria não cadastrada com sucesso",
        placement: "bottomEnd",
      });
    }
  }

  async function createField(phase, field) {
    try {
      const { data, } = await createDataMappingPhaseField(company._id, phase.id, {
        ...field,
        order: field.ordem,
      });
      Notification['success']({
        title: 'Campo cadastrado com sucesso!',
        placement: 'bottomEnd',
      });

      return data;
    } catch (e) {
      Notification['error']({
        title: e?.response?.data?.error ?? 'Erro ao cadastrar campo',
        placement: 'bottomEnd',
      });
    }
  }

  async function updateField(phase, updatedField) {
    try {
      const { data, } = await updateDataMappingField(company._id, phase.id, updatedField);

      Notification['success']({
        title: 'Campo alterado com sucesso',
        placement: 'bottomEnd',
      });

      return data;
    } catch {
      Notification['error']({
        title: 'Falha ao editar campo',
        placement: 'bottomEnd',
      });
    }
  }

  async function deleteTabField(phaseId, fieldId) {
    let ret = false;

    try {
      const { status, } = await deleteDataMappingField(company._id, phaseId, fieldId);
      if (status === 200) {
        Notification['success']({
          title: 'Campo deletado com sucesso',
          placement: 'bottomEnd',
        });

        ret = true;
      } else if (status === 204) {
        Notification['warning']({
          title: 'Campo não pode ser deletado',
          placement: 'bottomEnd',
        });
      }
    } catch {
      Notification['warning']({
        title: 'Campo não pode ser deletado',
        placement: 'bottomEnd',
      });
    }

    return ret;
  }

  async function listTabFields(phaseId) {
    try {
      const { data, } = await listDataMappingPhaseFields(company._id, phaseId);
      return data;
    } catch {
      Notification['error']({
        title: 'Falha ao carregar campos',
        placement: 'bottomEnd',
      });
      return [];
    }
  }

  async function updateTabFieldOrder(phase, field, updatedOrder) {
    try {
      await updateMappingFieldOrder(company._id, phase.id, field.id, updatedOrder);
    } catch {
      //TODO: handle error
    }
  }


  return (
    <EditDataMappingContext.Provider
      value={{
        phases,
        loading,
        listPhases,
        deletePhase,
        updatePhase,
        createPhase,
        createField,
        updateField,
        deleteTabField,
        listTabFields,
        updateTabFieldOrder,
      }}
    >
      { children }
    </EditDataMappingContext.Provider>
  );
}
