import React from 'react';
import { useSelector, } from 'react-redux';

import { Typography, TableCell, TableRow, } from '@material-ui/core';

import Table from '../Table';

import { getCompanyImpactReportWidgetData, } from '../../services/api';

import { selectCompany, } from '../../features/companySlice';
import { selectCurrentReportId, } from '../../features/impactReportsSlice';
import { selectIsGlobal, } from '../../features/impactReportsSlice';

import Risk from '../../core/entities/risk';


const HEADERS = Object.freeze([
  {
    label: 'Id',
    align: 'center',
  },
  {
    label: 'Risco',
    align: 'left',
  },
  {
    label: 'P',
    align: 'center',
  },
  {
    label: 'I',
    align: 'center',
  },
  {
    label: 'Nível de risco',
    align: 'center',
  },
]);


export default function RiskIdentificationsAndAssessmentsWidget({ data, readonly, onChange, }) {
  const company = useSelector(selectCompany);
  const reportId = useSelector(selectCurrentReportId);
  const isGlobal = useSelector(selectIsGlobal);
  const [actionPlanItems, setActionPlanItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showError, setShowError] = React.useState(false);


  async function initialize() {
    try {
      const { data: widgetData, } = await getCompanyImpactReportWidgetData(company._id, reportId,
        data.id);
      setActionPlanItems(widgetData.actionPlanItems);
      setLoading(false);
    } catch (error) {
      setShowError(true);
    }
  }

  React.useEffect(() => {
    if (!isGlobal) {
      initialize();
    }
  }, []);

  return (
    <>
      <Typography
        variant="subtitle1"
        style={{
          fontWeight: 500,
          marginBottom: '10px',
        }}
      >
        Identificação e avaliação de riscos
      </Typography>

      <Table
        headers={HEADERS}
        data={actionPlanItems || []}
        loading={loading}
        showError={showError}
        errorMessage="Falha ao carregar itens"
        noDataMessage="Nenhum item encontrado"
        disablePagination
        addBorders
      >
        {
          (item, props) => {
            const risk = Risk.getRisk(item.probability, item.impact);

            return (
              <TableRow {...props}>
                <TableCell
                  align="center"
                  style={{
                    outline: '1px solid #E0E0E0',
                  }}
                >
                  { `R${item.order+1}` }
                </TableCell>
                <TableCell
                  style={{
                    width: '100%',
                    outline: '1px solid #E0E0E0',
                  }}
                >
                  { item.problem }
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    outline: '1px solid #E0E0E0',
                  }}
                >
                  { item.probability }
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    outline: '1px solid #E0E0E0',
                  }}
                >
                  { item.impact }
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: risk.color,
                    outline: '1px solid #E0E0E0',
                  }}
                >
                  { Risk.calcValue(item.probability, item.impact) }
                </TableCell>
              </TableRow>
            )
          }
        }
      </Table>
    </>
  );
}
