import { useEffect, useState, } from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';
import { useSelector, } from 'react-redux';

import { Notification, } from 'rsuite';

import {
  getDueDiligenceTemplate,
  createDueDiligenceTemplateQuestion,
  deleteDueDiligenceTemplateQuestion,
  updateDueDiligenceTemplateQuestion,
} from '../../services/api';

import { selectCompany, } from '../../features/companySlice';
import DueDiligenceTemplateDialog from '../../components/DueDiligenceTemplateDialog';


export default function TemplateDialog({ open, template, onClose, }) {
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);


  async function getTemplateQuestions(templateId) {
    setLoading(true);
    try {
      const { data, } = await getDueDiligenceTemplate(company._id, templateId);
      setQuestions(data.questions || []);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_load_template_questions')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
    setLoading(false);
  }

  async function onDeleteQuestion(questionId) {
    if (undefined == template) {
      return;
    }

    setLoading(true);
    try {
      await deleteDueDiligenceTemplateQuestion(company._id, template.id, questionId);
      setQuestions((questions) => questions
        .filter((question) => question.id != questionId)
        .map((question, idx) => ({
          ...question,
          order: idx + 1,
        })));
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DUE_DILIGENCE.question_successfully_deleted')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_delete_question')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
    setLoading(false);
  }

  async function editQuestion(data) {
    if (undefined == template) {
      return;
    }

    setLoading(true);
    try {
      const questionId = data.id;
      const { data: updatedQuestion, } = await updateDueDiligenceTemplateQuestion(company._id,
        template.id, questionId, {
        ...data,
      });
      setQuestions((questions) => {
        const idx = questions.findIndex((question) => question.id == questionId);
        if (idx > -1) {
          questions[idx] = updatedQuestion;
        }
        return questions;
      });
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DUE_DILIGENCE.question_successfully_updated')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_update_question')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
    setLoading(false);
  }

  async function createQuestion(data) {
    if (undefined == template) {
      return;
    }

    setLoading(true);
    try {
      const { data: newQuestion, } = await createDueDiligenceTemplateQuestion(company._id,
        template.id, {
        ...data,
        order: questions.length + 1,
      });
      setQuestions((questions) => [...questions, newQuestion]);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DUE_DILIGENCE.question_successfully_created')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_create_question')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
    setLoading(false);
  }

  useEffect(() => {
    if (undefined != template) {
      getTemplateQuestions(template.id);
    } else {
      setQuestions([]);
    }
  }, [template]);


  return (
    <DueDiligenceTemplateDialog
      open={open}
      template={template}
      onClose={onClose}
      loading={loading}
      questions={questions}
      onDelete={onDeleteQuestion}
      onUpdate={editQuestion}
      onCreate={createQuestion}
      onMoveItem={() => template ? getTemplateQuestions(template.id) : []}
      readOnly={undefined != template ? template.isGlobal : false}
    />
  );
}
