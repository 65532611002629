import { useState, } from 'react';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { useSelector, } from 'react-redux';

import { Container, Grid, } from '@material-ui/core';
import { IconButton, Icon, Notification, } from 'rsuite';

import { HeaderProgress, } from '../../components/HeaderProgress';
import ActionsByRisk from '../../components/ActionsByRisk';

import { downloadRiskReport, } from '../../services/api.js';

import { selectCompany, } from '../../features/companySlice';


function RiskReport() {
  const company = useSelector(selectCompany);
  const [downloading, setDownloading] = useState(false);


  /**
   * Download risk report
   */
  const onDownload = async () => {
    if (!downloading) {
      setDownloading(true);
      const key = 'downloading-risk-report';
      Notification['info']({
        placement: 'bottomEnd',
        title: 'O relatório está sendo gerado!',
        description: 'Não é necessário atualizar a página, você poderá encontrar o arquivo na pasta downloads em alguns instantes.',
        duration: 0,
        key,
      });

      try {
        const { data, } = await downloadRiskReport(company._id);
        const reportName = `relatório-de-riscos-${company.name}-${moment().format('YYYYMMDD')}.pdf`;
        fileDownload(data, reportName);
      } catch (err) {
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Download do relatório falhou!',
          description: 'Um erro ocorreu durante o download do relatório solicitado, tente novamente mais tarde ou entre em contato com o suporte.',
        });
      }

      Notification.close(key);
      setDownloading(false);
    } else {
      Notification['warning']({
        placement: 'bottomEnd',
        title: 'O relatório já está sendo gerado!',
        description: 'O processamento da última requisição ainda foi finalizado.',
      });
    }
  }


  return (
    <div>
      <HeaderProgress />
      <Container
        maxWidth="xl"
        disableGutters
        style={{
          marginBottom: 10,
        }}
      >
        <IconButton
          icon={<Icon icon="print" />}
          color="blue"
          onClick={onDownload}
        >
          Baixar relatório
        </IconButton>
      </Container>

      <Grid
        container
        direction="column"
        alignItems="center"
        xs={12}
      >
        <Grid
          container
          item
          xs={12}
        >
          <ActionsByRisk />
        </Grid>
      </Grid>
    </div>
  );
}

export default RiskReport;
