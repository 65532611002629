import React, { useState } from 'react';
import { useSelector, } from 'react-redux';

import QuestionsCategoriesMaturityLevelChart from '../../QuestionsCategoriesMaturityLevelChart';

import { selectCompany, } from '../../../features/companySlice';
import { getQuestionsCategoriesReport, } from '../../../services/api';
import BaseWidget from './BaseWidget';
import { Toggle } from 'rsuite';


function CategoryMaturityLevelReportWidget(props) {

  const company = useSelector(selectCompany);
  const [loading, setLoading] = React.useState(true);
  const [categoriesReport, setCategoriesReport] = React.useState([]);
  const [typeGraphic, setTypeGraphic] = useState('radar');


  async function initialize() {
    setLoading(true);
    try {
      const { data, } = await getQuestionsCategoriesReport(company._id);
      setCategoriesReport(data);
    } catch {
      //TODO: handle error
    }
    setLoading(false);
  }

  React.useEffect(() => {
    initialize();
  }, []);


  function handlerSetTypeGraphic() {
    typeGraphic === 'radar' ? setTypeGraphic('column') : setTypeGraphic('radar');
  }

  const TypeGraphic = () => {
    return (
      <Toggle
        size="md"
        checked={typeGraphic === 'radar'}
        onChange={handlerSetTypeGraphic}
        checkedChildren={`Coluna`}
        unCheckedChildren={`Radar${'\u00A0\u00A0'}`}
      />
    );
  }


  return (
    <BaseWidget
      componentTopRight={<TypeGraphic />}
      {...props}
      loading={loading}
    >
      <QuestionsCategoriesMaturityLevelChart data={categoriesReport} typeGraphic={typeGraphic} />
    </BaseWidget>
  );
}

export default CategoryMaturityLevelReportWidget;
