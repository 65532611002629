import React, { useEffect, useState, } from 'react';
import { useSelector, } from 'react-redux';

import {
  ContainerWorkshop,
  ContentWorkshop,
} from './styles'

import {
  getUsers,
  listCompanyUsers,
  GetOrPostWorkshop,
} from '../../services/api'
import { ContainerLeft } from './ContainerLeft'
import { ContainerRight } from './ContainerRight'
import { ModalCreateUser } from '../../pages/CriarUsuarios/ModalCreatUser'

import { selectCompany, } from '../../features/companySlice';


export function WorkshopGrid({ docs }) {
  const company = useSelector(selectCompany);
  const [body, setBody] = useState([])
  const [workshopID, setWorkshopID] = useState(0)
  const [constituicao, setConstituicao] = useState(0)
  const [open, setOpen] = useState(false)


  const getData = async () => {
    listCompanyUsers(company._id).then(({ data, }) => {
      setBody(data);
    });

    await GetOrPostWorkshop().then(({ data }) => {
      setWorkshopID(data[0].id)
      setConstituicao(parseInt(data[0].comite))
    })
  }

  useEffect(() => {
    getData()
  }, [])


  return (
    <ContainerWorkshop>
      <ContentWorkshop>
        <ContainerLeft />
        <ModalCreateUser
          open={open}
          setOpen={setOpen}
          getUsers={getData}
        />
        {workshopID !== 0 && (
          <ContainerRight
            user={body}
            id={workshopID}
            constituicao={constituicao}
            setOpenModal={setOpen}
            docs={docs}
          />
        )}
      </ContentWorkshop>
    </ContainerWorkshop>
  );
}
