import React, { Fragment, useEffect, useState, useRef, useContext } from "react";

import { useThemeContext } from "../../../../context/ThemeAppContext";
import { AppBar as AppBarUi, Toolbar, ButtonBase } from "@material-ui/core";
import { makeStyles, } from "@material-ui/styles";
import { Image, } from 'react-bootstrap';
import { Icon, } from 'rsuite';

import routesNavBar from "../../../NavBar/constants/routesConfig";
import logo from '../../../../assets/logo.svg';
import history from "../../../../history";
import { useStyles } from "../../../NavBar/Styles";
import { useSelector, } from 'react-redux';
import { selectCompany } from "../../../../features/companySlice";
import { Context } from "../../../../context/AuthContext";
import { getUserCompany, } from "../../../../services/api";
import { getUserSession } from "../../../../utils";

export default function AppBar({ isMaster = false }) {

    const { stylesLayout, } = useThemeContext();
    const { groupName } = getUserSession();

    const styleAppBar = styles({ stylesLayout });
    const classes = useStyles();

    const divMenu = useRef(null);
    const divSubMenu = useRef(null);
    const data = useSelector(selectCompany);
    const { HandleLogout, adminauth, ...props } = useContext(Context)

    function resizeMenu() {
        if (!divMenu.current || !divSubMenu.current) {
            return;
        }
        const altura = divMenu.current.offsetHeight;
        const largura = divMenu.current.offsetWidth;
        divSubMenu.current.style.top = `${altura}px`;
        divSubMenu.current.style.width = `${largura}px`;
    }

    useEffect(() => {
        window.addEventListener('resize', resizeMenu);
        return () => {
            window.removeEventListener('resize', resizeMenu);
        }
    }, []);

    return (
        <>
            <AppBarUi
                className={styleAppBar.appBar}
            >
                <Toolbar style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <div className={styleAppBar.contentContainer}>
                        <Image src={logo} onClick={() => history.push('/')} />
                        <p className={styleAppBar.nameCompany}>{groupName}</p>
                        <div className={styleAppBar.containerIcons}>
                            {isMaster &&
                                <ButtonBase onClick={() => history.push('/DashboardMaster')} style={{ flexDirection: "row", display: "flex", marginRight: "30px" }}>
                                    <Icon
                                        icon="people-group"
                                        size="lg"
                                        className={styleAppBar.styleIcons}
                                    />
                                    <p>Console Master</p>
                                </ButtonBase>
                            }
                            <Icon
                                icon="cog"
                                onClick={() => history.push('/Usuarios/Config')}
                                size="lg"
                                className={styleAppBar.styleIcons}
                            />
                            <Icon
                                icon="sign-out"
                                onClick={HandleLogout}
                                size="lg"
                                className={styleAppBar.styleIcons}
                                style={{ margin: 0 }}
                            />
                        </div>
                    </div>
                </Toolbar>
            </AppBarUi>
            <div className={classes.toolbar} style={{ marginBottom: "50px" }} />
        </>
    )
}

const styles = makeStyles((theme) => ({
    appBar: {
        background: 'var(--primary-color)',
        height: "auto",
        paddingTop: "10px"
    },
    contentContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: 'center',
        width: "100%",
        marginBottom: "10px"
    },
    nameCompany: {
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: 22,
        color: 'var(--text-secondary-color)'
    },
    containerIcons: {
        display: "flex",
        flexDirection: "row"
    },
    styleIcons: {
        cursor: 'pointer',
        marginRight: '20px'
    },
    containerSwitch: {
        display: "flex",
        flexDirection: "row",
        cursor: 'pointer'
    },
    containerTabs: {
        display: "flex",
        flex: '1',
        flexDirection: "row",
        alignItems: 'center',
        flexWrap: "wrap",
        position: "relative"
        // width: "100%"
    }
}));

