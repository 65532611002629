import { useEffect, useState, memo, } from 'react';
import moment from 'moment';
import { useSelector, } from 'react-redux';

import { withStyles, } from "@material-ui/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import CATEGORIES_ICONS from './cookies-categories-icons';

import { getPopupConsents, getCookiesCategories, } from '../../services/api';

import { selectCompany } from '../../features/companySlice';
import Paginator from '../../components/Paginator/Paginator';


//? Components used to create a table with loading backdrop
const LimitedBackdrop = withStyles({
  root: {
    position: 'absolute',
    zIndex: 10,
  }
})(Backdrop);

const StyledTableContainer = withStyles({
  root: {
    position: 'relative',
    marginBottom: 10,
  }
})(TableContainer);


/**
 * Table to show consent logs
 *
 * @param {string} popupId - company popup ID
 *
 * @returns Rendered component
 */
function CookieConsentLogTable({ popupId, }) {
  const company = useSelector(selectCompany);
  const [consentLogs, setConsentLogs] = useState([]);
  const [totalConsents, setTotalConsents] = useState(0);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);

  /**
   * Get consents and cookies categories from the API
   */
  const getData = async () => {
    setLoading(true);
    setShowError(false);
    const companyId = company._id;

    try {
      if (categories.length === 0) {
        const { data: cookiesCategories, } = await getCookiesCategories();
        setCategories(cookiesCategories);
      }

      const { data, } = await getPopupConsents(companyId, popupId, page, pageSize);
      const { consents, total, } = data;

      for (const consent of consents) {
        try {
          consent.accepted_categories = JSON.parse(consent.accepted_categories);
        } catch (err) {
          consent.accepted_categories = Array([]).fill(0);
        }
      }

      setConsentLogs(consents);
      setTotalConsents(total);
    } catch {
      setShowError(true);
    }
    setLoading(false);
  }

  /**
   * Handle page change
   *
   * @param {object} event - event that triggered the change
   * @param {number} newPage - new page
   */
  const onPageChange = (event, newPage) => {
    setPage(newPage);
  }

  /**
   * Handle change in page size
   *
   * @param {object} event - event thar triggered the change
   */
  const onPageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  }

  /**
   * Use effect to update categories and consent logs
   */
  useEffect(() => {
    const timeoutId = setTimeout(getData, 300);
    return () => clearTimeout(timeoutId);
  }, [page, pageSize, popupId]);


  return (
    <Container
      maxWidth="xl"
      disableGutters
    >
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">UUID do usuário</TableCell>
              <TableCell align="center">Consentimento</TableCell>
              <TableCell align="center">Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              consentLogs.map((log) => (
                <TableRow key={log.id}>
                  <TableCell align="center">
                    {log.id}
                  </TableCell>
                  <TableCell align="center">
                    {
                      log.accepted_categories.slice(0, 5).map((accepted, index) => {
                        const category = categories[index];
                        const { name, } = category;
                        const { namePt, } = category.data;
                        const Icon = CATEGORIES_ICONS[name];

                        return (
                          <Tooltip
                            key={index}
                            title={namePt}
                          >
                            <Icon
                              style={{
                                color: accepted ? '#000000' : '#909090',
                                marginLeft: 2,
                                marginRight: 2,
                              }}
                            />
                          </Tooltip>
                        );
                      })
                    }
                  </TableCell>
                  <TableCell align="center">
                    {moment(log.createdAt).format('DD/MM/YYYY, HH:MM:ss')}
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <LimitedBackdrop open={loading && (consentLogs.length > 0)}>
          <CircularProgress />
        </LimitedBackdrop>
      </StyledTableContainer>

      {
        showError || loading || (consentLogs.length === 0) ?
          <Grid
            container
            justifyContent="center"
            fullWidth
            style={{
              marginTop: 10,
            }}
          >
            {loading && (consentLogs.length === 0) ? <CircularProgress /> : null}
            {
              !loading && showError ?
                <Typography
                  variant="body1"
                  align="center"
                  display="block"
                  color="error"
                >
                  Falhar ao carregar registros de consentimento
                </Typography> :
                null
            }
            {
              !loading && !showError && (consentLogs.length === 0) ?
                <Typography
                  variant="body1"
                  align="center"
                  display="block"
                  style={{
                    color: '#808080',
                  }}
                >
                  Você ainda não possui nenhum registro de consentimento
                </Typography> :
                null
            }
          </Grid> :
          null
      }
      <TablePagination
        labelRowsPerPage={`Linhas por página`}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        rowsPerPageOptions={[5, 10, 15, 20, 25]}
        component="div"
        rowsPerPage={pageSize}
        page={page}
        count={totalConsents}
        onPageChange={onPageChange}
        onRowsPerPageChange={onPageSizeChange}
        ActionsComponent={() => <Paginator page={page + 1} rowsPerPage={pageSize} count={totalConsents} onChange={onPageSizeChange} />}
      />
    </Container>
  )
}

export default memo(CookieConsentLogTable);
