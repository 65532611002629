import {useCallback, useState} from "react";

/**
 * @template T
 * @template G
 * @param asyncFn {function(T): Promise<G>}
 * @param defaultValue {T}
 * @returns {{request: ((function(...[*]): Promise<G>)|*), setValue: <T>(value: ((<T>(prevState: T) => T) | T)) => void, loading: boolean, error: unknown, value: T}}
 */
export default function useAsyncState(asyncFn, defaultValue) {
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = useCallback(async (...args) => {
    try {
      setLoading(true);
      const res = await asyncFn(...args);
      setValue(res);
      return res;
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [value, loading, error, asyncFn]);

  return {value, loading, request, error, setValue};
}
