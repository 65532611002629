import { memo, } from 'react';
import { Button, } from '@material-ui/core';
import { makeStyles, } from '@material-ui/styles';


const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
  },
  button: {
    backgroundColor: '#FFFFFF',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
});


function UnderlinedPageSelector({ option, options, onSelectedOption, size, ...props }) {
  const classes = useStyles();


  return (
    <div
      className={classes.container}
      {...props}
    >
      {
        options.map(({ label, id, }, index) => {
          const isActive = (id === option);

          return (
            <Button
              key={index}
              onClick={() => onSelectedOption(id)}
              className={classes.button}
              size={size}
              style={{
                color: isActive ? '#0000FF' : '#404040',
                borderBottom: isActive ? '3px solid #0000FF' : '',
              }}
            >
              { label }
            </Button>
          );
        })
      }
    </div>
  );
}

function FilledPageSelector({ option, options, onSelectedOption, size, ...props }) {
  const classes = useStyles();


  return (
    <div
      className={classes.container}
      {...props}
    >
      {
        options.map(({ label, id, }, index) => {
          const isActive = (id === option);

          return (
            <Button
              key={index}
              onClick={() => onSelectedOption(id)}
              className={classes.button}
              size={size}
              style={{
                color: isActive ? 'white' : '#1C265A',
                backgroundColor: isActive ? '#1C265A' : 'white',
              }}
            >
              { label }
            </Button>
          );
        })
      }
    </div>
  );
}


const PAGE_SELECTOR_VARIANTS = Object.freeze({
  FILLED: 'filled',
  UNDERLINED: 'underlined',
});

/**
 * Page selector
 *
 * @param {'underlined' | 'filled'} variant - component variant to be rendered
 * @param {'large' | 'medium' | 'small'} size - button size
 * @param {number | string} option - selected option ID
 * @param {Object[]} options - selector options
 * @param {(optionId: string | number) => void} onSelectedOption - callback called when option is
 * changed
 *
 * @returns Rendered page
 */
function PageSelector({
  variant = PAGE_SELECTOR_VARIANTS.UNDERLINED,
  size = 'medium',
  ...props
}) {
  return PAGE_SELECTOR_VARIANTS.FILLED === variant ?
    <FilledPageSelector
      size={size}
      {...props}
    /> :
    <UnderlinedPageSelector
      size={size}
      {...props}
    />;
}


export default PageSelector;
