
import React, { useState, useEffect, } from 'react';
import moment from 'moment';

import { TableCell, TableRow, } from '@material-ui/core';
import { Notification, } from 'rsuite';

import Table from '../../components/Table';
import Score from '../../components/Score';
import CollaboratorCourseProgressStateChip from '../../components/CollaboratorCourseProgressStateChip';

import { getCourseCollaboratorsTracking, } from '../../services/api';
import { COLLABORATOR_COURSE_PROGRESS_STATES, } from '../../utils/constants';


const HEADERS = Object.freeze([
  {
    label: 'Nome',
    align: 'center',
  },
  {
    label: 'E-mail',
    align: 'center',
  },
  {
    label: 'Score',
    align: 'center',
  },
  {
    label: 'Enviado em',
    align: 'center',
  },
  {
    label: 'Aberto em',
    align: 'center',
  },
  {
    label: 'Status',
    align: 'center',
  },
]);


export default function CollaboratorsCourseProgress({ course, }) {
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [collaboratorsProgress, setCollaboratorsProgress] = useState([]);

  async function getData() {
    setLoading(true);
    setShowError(false);

    try {
      const { data, } = await getCourseCollaboratorsTracking(course.company_id, course.id);
      setCollaboratorsProgress(data);
    } catch (err) {
      setShowError(true);
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar progresso dos colaboradores!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }

    setLoading(false);
  }

  useEffect(() => {
    if ((null != course) && (null != course.id)) {
      getData();
    }
  }, [course]);


  return (
    <Table
        headers={HEADERS}
        data={collaboratorsProgress}
        loading={loading}
        errorMessage="Falha ao carregar progresso dos colaboradores"
        noDataMessage="Nenhum colaborador encontrado"
        disablePagination
        showError={showError}
        style={{
          backgroundColor: '#F2F4F8',
        }}
      >
        {
          (item, props) => {
            const { grade, } = item;
            let state = COLLABORATOR_COURSE_PROGRESS_STATES.SENT.value;
            if (item.openedAt) {
              state = COLLABORATOR_COURSE_PROGRESS_STATES.OPENED.value;
            }

            return (
              <TableRow {...props}>
                <TableCell align="center" >
                  { item.collaboratorName }
                </TableCell>
                <TableCell align="center" >
                  { item.collaboratorEmail }
                </TableCell>
                <TableCell align="center" >
                  <Score grade={grade} />
                </TableCell>
                <TableCell align="center" >
                  { moment(item.requestedAt).format('HH:mm, DD/MM/YYYY') }
                </TableCell>
                <TableCell align="center" >
                  { item.openedAt ? moment(item.openedAt).format('HH:mm, DD/MM/YYYY') : '-' }
                </TableCell>
                <TableCell align="center" >
                  <CollaboratorCourseProgressStateChip state={state} />
                </TableCell>
              </TableRow>
            )
          }
        }
      </Table>
  );
}
