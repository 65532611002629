import React from "react";
import CustomMenuFixed from ".";

import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { Icon, } from 'rsuite';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { makeStyles } from '@material-ui/styles';
import TuneIcon from '@material-ui/icons/Tune';

import Trash from "../Icons/Trash";
import Pin from "../Icons/Pin";
import Pointers from "../Icons/Pointers";
import Ajust from "../Icons/Ajust";
import Plus from "../Icons/Plus";
import Eye from "../Icons/Eye";
import Arrow from "../Icons/Arrow";
import Check from "../Icons/Check";
import Book from "../Icons/Book";
import FourArrow from "../Icons/FourArrow";

import useModal from '../../hooks/useModal';
import {
	setItens,
	loadingTrue,
	loadingFalse,
	setModalTasks
} from '../../features/responderPerguntas';
import {
	getPerguntasOrdem,
	getPerguntasAdequadas,
	getPerguntaSemReposta,
	getPerguntasNaoAdequadas,
} from '../../services/api';

export default function DiagnosticMenuFixed({ menuFixed }) {

	const dispatch = useDispatch();
	const stateReduxMenu = useSelector((state) => state.fixedMenuPages);


	async function fetchQuestions(fetcher, ...args) {
		dispatch(loadingTrue());

		try {
			const { data, } = await fetcher(...args);
			dispatch(setItens(data));
		} catch {
			//TODO: show error
		}

		dispatch(loadingFalse());
	}

	const MENU_FIXED = Object.freeze({
		showItens: {
			id: 'showItens',
			title: 'Mostrar todas',
			icon: <></>,
			onClick: async function getAllQuestions() {
				fetchQuestions(getPerguntasOrdem);
			},
		},
		notResponse: {
			id: 'notResponse',
			title: 'Sem resposta',
			icon: <></>,
			onClick: async function getUnansweredQuestions() {
				fetchQuestions(getPerguntaSemReposta, 1);
			},
		},
		adequados: {
			id: 'adequados',
			title: 'Adequados',
			icon: <></>,
			onClick: async function getCompliantQuestions() {
				fetchQuestions(getPerguntasAdequadas, 1);
			},
		},
		review: {
			id: 'review',
			title: 'Revisão',
			icon: <></>,
			onClick: async function getInReviewQuestions() {
				fetchQuestions(getPerguntasNaoAdequadas, 1);
			},
		},
		allReiew: {
			id: 'allReiew',
			title: 'Marcar todos como revisar',
			icon: <></>,
			onClick: () => {
				dispatch(setModalTasks(true));
			}
		},
	});

	return <CustomMenuFixed itensLeft={MENU_FIXED} />
}
