import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, } from '@material-ui/core';
import { PieChart, Pie, Cell, ResponsiveContainer, } from 'recharts';

import ChartLegend from '../ChartLegend';


const COLORS = Object.freeze([
  '#59A14F',
  '#F28E2B',
  '#499894',
  '#F1CE63',
  '#B07AA1',
  '#FABFD2',
  '#D37295',
  '#4E79A7',
  '#000000',
  '#86BCB6',
  '#FFBE7D',
  '#8CD17D',
  '#B6992D',
  '#E15759',
  '#FF9D9A',
  '#79706E',
  '#BAB0AC',
  '#D4A6C8',
  '#9D7660',
  '#D7B5A6',
  '#A0CBE8',
]);


export default function DataManagementRoleChart({ dataManagementRoleClassification, }) {
  const { t, } = useTranslation();
  const total = dataManagementRoleClassification.reduce((prev, { amount, }) => prev + amount, 0);


  return total > 0 ? (
    <Grid
      container
      justifyContent="space-between"
      alignContent="center"
      style={{
        flexGrow: 1,
      }}
    >
      <Grid
        item
        xs={5}
        container
        style={{
          height: '100%',
        }}
      >
        <ResponsiveContainer
          width="100%"
          height="100%"
        >
          <PieChart>
            <Pie
              data={dataManagementRoleClassification}
              cx="50%"
              cy="50%"
              dataKey="amount"
            >
              {
                dataManagementRoleClassification.map(({ id, }, index) => {
                const color = COLORS[index%COLORS.length];

                  return (
                    <Cell
                      key={id}
                      fill={color}
                    />
                  );
                })
              }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Grid>

      <Grid
        item
        container
        xs={7}
        justifyContent="center"
        direction="column"
      >
        {
          dataManagementRoleClassification.map(({ name, id, amount, }, index) => {
            const color = COLORS[index%COLORS.length];

            return (
              <div
                key={id}
                style={{
                  color: '#54708C',
                  fontFamily: 'Inter',
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '0.5rem',
                  justifyContent: 'space-between'
                }}
              >
                <ChartLegend
                  color={color}
                  label={name}
                  size="small"
                />

                <strong
                  style={{
                    color,
                    fontFamily: 'Inter',
                    fontSize: '0.75rem',
                  }}
                >
                  {
                    total > 0 ?
                    t('PAGES.DATA_MANAGEMENT_ROLE_MANAGEMENT.role_percentage', {
                      value: (amount / total * 100).toFixed(0),
                      count: amount,
                    }) :
                    ''
                  }
                </strong>
              </div>
            );
          })
        }
      </Grid>
    </Grid>
  ) : null;
}
