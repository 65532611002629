import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import LabelOffIcon from '@material-ui/icons/LabelOff';
import PhoneIcon from '@material-ui/icons/Phone';
import TuneIcon from '@material-ui/icons/Tune';

const CATEGORIES_ICONS = Object.freeze({
  "Necessary": VerifiedUserIcon,
  "Functional": TuneIcon,
  "Analytics": TrendingUpIcon,
  "Marketing": PhoneIcon,
  "Unclassified": LabelOffIcon,
});

export default CATEGORIES_ICONS;
