import React, { useState, } from 'react';
import {
  Card,
  IconButton,
  Button,
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ShareIcon from '@material-ui/icons/Share';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/Delete';

import CourseLevelChip from './CourseLevelChip';
import AspectRatio from '../AspectRatio';
import LimitedBackdrop from '../LimitedBackdrop';


const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
  },
  courseTitle: {
    color: '#3B66FF',
    fontWeight: 'bold',
    padding: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    lineHeight: 1.1,
    height: '2.2em',
  },
  accessButton: {
    textTransform: 'none',
    fontWeight: 'normal',
    margin: '0.2rem',
  },
  accessButtonContainer: {
    padding: '0.2rem',
  },
  thumbnailContainer: {
    position: 'relative',
    backgroundColor: '#EEEEEE',
  },
  buttonContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    marginTop: '0.25rem',
    marginRight: '0.25rem',
  },
  actionButton: {
    margin: '0.1rem',
    backgroundColor: '#FFFFFF',
    '&:focus': {
      backgroundColor: '#FFFFFFDD',
    },
    '&:hover': {
      backgroundColor: '#FFFFFFDD',
    },
  },
  actionButtonIcon: {
    color: '#3B66FF',
    width: '1rem',
    height: '1rem',
  },
});


export default function CourseCard({ data, onEdit, onShare, onAccessCourse, onDelete, }) {
  const classes = useStyles();

  const [showImageLoader, setShowImageLoader] = useState(true);


  return (
    <Card
      variant="outlined"
      className={classes.root}
      elevation={0}
    >
      <Grid
        className={classes.thumbnailContainer}
        container
        alignItems="center"
        justifyContent="center"
      >
        <AspectRatio>
          <img
            src={data.thumbnail_url || ''}
            alt={`${data.title} thumbnail`}
            width="100%"
            height="100%"
            onLoad={() => setShowImageLoader(false)}
            onError={() => setShowImageLoader(false)}
          />
          <LimitedBackdrop
            open={showImageLoader}
          >
            <CircularProgress />
          </LimitedBackdrop>
        </AspectRatio>

        <Grid
          container
          alignItems="flex-end"
          direction="column"
          className={classes.buttonContainer}
        >
          <IconButton
            className={classes.actionButton}
            size="small"
            onClick={() => onShare(data.id)}
          >
            <ShareIcon className={classes.actionButtonIcon} />
          </IconButton>

          <IconButton
            className={classes.actionButton}
            size="small"
            onClick={() => onEdit(data.id)}
          >
            <EditOutlinedIcon className={classes.actionButtonIcon} />
          </IconButton>

          <IconButton
            className={classes.actionButton}
            size="small"
            onClick={() => onDelete(data.id)}
          >
            <DeleteIcon className={classes.actionButtonIcon} />
          </IconButton>
        </Grid>

        <CourseLevelChip
          level={data.level}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      </Grid>

      <Typography
        className={classes.courseTitle}
        variant="body1"
        style={{
        }}
      >
        { data.title }
      </Typography>

      <Grid
        className={classes.accessButtonContainer}
        container
        alignItems="center"
        justifyContent="center"
      >
        <Button
          className={classes.accessButton}
          variant="contained"
          disableElevation
          color="primary"
          fullWidth
          onClick={() => onAccessCourse(data.id)}
        >
          Acessar
        </Button>
      </Grid>
    </Card>
  );
}
