import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector, } from 'react-redux';
import { useParams, } from 'react-router-dom';

import { Form } from "react-bootstrap";
import { Container, Grid, Paper, Button, } from "@material-ui/core";
import { Icon, Notification, } from "rsuite";

import {
  getReunionsUnique,
  saveMinuteOfMeeting,
  getUsers,
  listCompanyMeetings,
  updateMeetingEnd,
  updateMeetingDate,
  updateMeetingTitle,
  updateMeetingStart,
  registerMeeting,
} from "../../services/api";

import Title from "../../components/Global/PageTitle";
import Participants from "./Participants";
import MeetingItems from './MeetingItems';
import DocumentViewer from "../../components/DocumentViewer";
import ConfirmDialog from "../../components/ConfirmDialog";
import LimitedBackdropLoader from '../../components/LimitedBackdropLoader';

import history from "../../history";
import openNotification from "../../utils/Notification";

import { selectCompany, } from "../../features/companySlice";

import useModal from "../../hooks/useModal";


function Meeting() {
  const { meetingId: id, } = useParams();
  const [body, setBody] = useState({});
  const [ata, setAta] = useState("");
  const [users, setUsers] = useState([]);
  const [reunions, setReunions] = useState([]);
  const [responsiveScreem, setResponsiveScreem] = useState("");
  const [title, setTitle] = useState("");
  const [titleLoading, setTitleLoading] = useState(false);
  const [valueState, setValueState] = useState(body.date);
  const [valueInicio, setValueInicio] = useState(body.inicio);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const company = useSelector(selectCompany);
  let MediaScreem = window.screen.width;
  const [isRegistering, setIsRegistering] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [
    registerConfirmModalOpen,
    onOpenRegisterConfirmModal,
    onCloseRegisterConfirmModal
  ] = useModal();


  function ResponsiveJustFy() {
    if (MediaScreem <= 635) {
      setResponsiveScreem("center");
    } else {
      setResponsiveScreem("flex-start");
    }
  }

  const handleOnChange = (event) => {
    setTitleLoading(true);
    setTitle(event.target.value);
  };

  const insertTitle = () => {
    if (titleLoading) {
      updateMeetingTitle(company._id, id, title)
        .then(() => {
          setTitleLoading(false);
          openNotification("success", "", "Titulo alterado");
        })
        .catch(() => {
          alert("Erro ao alterar titulo");
        });
    }
  };

  async function getData() {
    await getReunionsUnique(company._id, id).then(({ data }) => {
      setBody(data);
      setTitle(data.title);
      setAta(data.ata);
      setIsRegistered(null !== data.registrationId);
    });

    await getUsers().then(({ data }) => {
      setUsers(data);
    });

    await listCompanyMeetings(company._id).then(({ data }) => {
      return setReunions(data);
    });
  }

  useEffect(() => {
    getData();
    ResponsiveJustFy();
  }, []);

  const insertAta = () => {
    saveMinuteOfMeeting(company._id, id, ata)
      .then(() => {
        alert("Ata inserida com sucesso");
      })
      .catch(() => {
        alert("Erro ao inserir ata");
      });
  };

  const insertData = (value) => {
    updateMeetingDate(company._id, id, value)
      .then(() => {
        openNotification("success");
        getData();
      })
      .catch(() => {
        alert("Erro ao alterar data");
      });
  };

  const insertInicio = (value) => {
    updateMeetingStart(company._id, id, value)
      .then(() => {
        openNotification("success", "", "Inicio alterado");
        getData();
      })
      .catch(() => {
        alert("Erro ao alterar inicio");
      });
  };

  const insertFim = (value) => {
    updateMeetingEnd(company._id, id, value)
      .then(() => {
        openNotification("success", "", "Fim alterado");
        getData();
      })
      .catch(() => {
        alert("Erro ao alterar fim");
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => insertTitle(), 1300);
    return () => clearTimeout(timeoutId);
  }, [title]);

  async function onRegisterMinute() {
    setIsRegistering(true);
    onCloseRegisterConfirmModal();
    try {
      await registerMeeting(company._id, id);
      Notification['success']({
        placement: 'bottomEnd',
        title: 'Ata da reunião registrada com sucesso!',
        description: 'Atualize a página para visualizar a versão registrada.',
      });
      setIsRegistered(true);
    } catch (error) {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao registrar reunião',
      });
    }

    setIsRegistering(false);
  }


  return (
    <Container
      maxWidth="xl"
      ref={componentRef}
      style={{
        marginTop: 0,
        position: 'relative',
      }}
    >
      <Icon
        icon="arrow-circle-left"
        size="2x"
        onClick={() => {
          history.push(`/companies/${company._id}/meetings`);
        }}
        className="IconReturn"
      />

      <Title description="Detalhes da Reunião" />

      <LimitedBackdropLoader open={isRegistering} />

      {
        null == body.registrationId ?
        <>
          <Grid
            container
            spacing={2}
          >
            <br />
            {body && (
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 15,
                }}
              >
                <Container
                  component={Paper}
                  maxWidth="xl"
                  style={{
                    height: "370px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { responsiveScreem },
                    paddingTop: 0,
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingBotton: 0,
                    marginBotton: "35px",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#54708C",
                  }}
                >
                  <div className="BoxAllTitle">
                    <div className="BoxNameEmpresa">
                      <p>Assunto</p>
                      <Icon icon="edit" />
                    </div>
                    <div className="TitleReuniao">
                      <span>
                        <Form.Control
                          onChange={(e) => {
                            handleOnChange(e);
                          }}
                          disabled={isRegistered}
                          value={title}
                        />
                      </span>
                    </div>
                    <div className="bocTitle2">
                      <div className="boxContentTitle">
                        <div className="BoxNameEmpresa">
                          <p>Local</p>
                          <Icon icon="edit" />
                        </div>
                        <div className="TitleReuniao">
                          <span>
                            <Form.Control
                              disabled
                              value={body.local ? body.local : "Á definir"}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="boxContentTitle">
                        <div className="BoxNameEmpresa">
                          <p>Data</p>
                          <Icon icon="edit" />
                        </div>
                        <div className="TitleReuniao">
                          <span>
                            <Form.Control
                              value={valueState ? valueState : body.date}
                              disabled={isRegistered}
                              onChange={(e) => {
                                insertData(e.target.value);
                                setValueState(e.target.value);
                              }}
                              type="date"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="boxContentTitle">
                        <div className="BoxNameEmpresa">
                          <p>inicio</p>
                          <Icon icon="edit" />
                        </div>
                        <div className="TitleReuniao">
                          <span>
                            <Form.Control
                              value={valueInicio ? valueInicio : body.inicio}
                              disabled={isRegistered}
                              onChange={(e) => {
                                insertInicio(e.target.value);
                                setValueInicio(e.target.value);
                              }}
                              type="time"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="boxContentTitle">
                        <div className="BoxNameEmpresa">
                          <p>Fim</p>
                          <Icon icon="edit" />
                        </div>
                        <div className="TitleReuniao">
                          <span>
                            <Form.Control
                              value={body.fim}
                              disabled={isRegistered}
                              onChange={(e) => {
                                insertFim(e.target.value);
                                setValueState(e.target.value);
                              }}
                              type="time"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <Participants users={users} />
                  </div>
                </Container>
              </Grid>
            )}
          </Grid>

          <MeetingItems
            ata={ata}
            insertAta={() => {
              if (!isRegistered) {
                insertAta();
              }
            }}
            setAta={setAta}
            handlePrint={handlePrint}
            users={users}
            reunion={reunions}
          />

          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            style={{
              margin: '0.5rem 0',
            }}
          >
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={onOpenRegisterConfirmModal}
              style={{
                textTransform: 'none'
              }}
              disabled={isRegistering || isRegistered}
            >
              Registrar
            </Button>
          </Grid>

          <ConfirmDialog
            open={registerConfirmModalOpen}
            confirmationMessage="Tem certeza que deseja registar a ata da reunião?"
            secondaryText="Depois de registrada, a reunião não poderá ser alterada."
            onClose={onCloseRegisterConfirmModal}
            onConfirm={onRegisterMinute}
          />
        </> :
        <div
          style={{
            width: '800px',
            maxWidth: '100%',
            margin: '0 auto',
          }}
        >
          <DocumentViewer
            fileLink={body.minuteLink}
            fileType="application/pdf"
            height="800px"
          />
        </div>
      }
    </Container>
  );
}

export default Meeting;
