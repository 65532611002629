import { createSlice } from "@reduxjs/toolkit";

export const mapeamentoCadastroAba = createSlice({
  name: "abaCadastro",
  initialState: {
    value: 0,
  },
  reducers: {
    getAbaId: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { getAbaId } = mapeamentoCadastroAba.actions;

export default mapeamentoCadastroAba.reducer;
