import { Route, Switch, } from 'react-router-dom';

import Changepass from './pages/Login/passChange';
import MapeamentoDadosPublico from './pages/MapeamentoDados/MapeamentoPublico';
import Login from './pages/Login/Login';
import LoggedInArea from './pages/LoggedInArea';
import PrivacyPolicy from './pages/Site/PrivacyPolicy';
import PublicCoursePage from './pages/Treinamentos/PublicCoursePage';
import PublicMaturityQuestions from './pages/PublicMaturityQuestions';
import PublicDueDiligencePage from './pages/PublicDueDiligence';
import PublicDocumentSignatureRequestsPage from './pages/PublicDocumentSignatureRequests';
import RequireAuth from './components/RequireAuth';
import SolicitacaoDados from './pages/Monitoramento/SolicitacaoDados';
import CompanyMaturityDiagnosis from './pages/CompanyMaturityDiagnosis';
import DownloadEvidencePage from './pages/DownloadEvidence';
import MapPageDiagnosis from './pages/CompanyMaturityDiagnosis/MapPageDiagnosis';


export default function Routes() {
  return (
    <Switch>
      <Route
        exact
        path="/login"
        component={Login}
      />

      <Route
        exact
        path="/solicitacao/dados"
        component={SolicitacaoDados}
      />

      <Route
        exact
        path="/mapeamento/publico"
        component={MapeamentoDadosPublico}
      />

      <Route
        exact
        path="/trocar_senha"
        component={Changepass}
      />

      <Route
        exact
        path="/curso/:courseId"
        component={PublicCoursePage}
      />

      <Route
        exact
        path="/document-sign-requests/:requestId"
        name="document-sign-requests"
        component={PublicDocumentSignatureRequestsPage}
      />

      <Route
        exact
        path="/maturity-checklist/public"
        component={PublicMaturityQuestions}
      />

      <Route
        exact
        path="/privacy-policy"
        component={PrivacyPolicy}
      />

      <Route
        exact
        path="/due-diligence/public"
        component={PublicDueDiligencePage}
      />

      <Route
        exact
        path={[
          '/diagnostico/:affiliateId',
          '/diagnóstico/:affiliateId',
          '/diagnostico',
          '/diagnóstico',
        ]}
        component={CompanyMaturityDiagnosis}
      />

      <Route
        exact
        path="/evidences/:evidenceId/download"
        name="download-evidence"
        component={DownloadEvidencePage}
      />

      <Route path="/">
        <RequireAuth>
          <LoggedInArea />
        </RequireAuth>
      </Route>
    </Switch>
  );
}
