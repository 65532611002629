import { useEffect, } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { TableCell, TableRow, IconButton, } from '@material-ui/core';

import DescriptionIcon from '@material-ui/icons/Description';

import Table from '../Table';

import {
  selectCurrentReportId,
  selectCurrentVersionId,
  selectCurrentReport,
  getImpactReport,
  setCurrentVersionId,
  selectIsGlobal,
} from '../../features/impactReportsSlice';


const HEADERS = Object.freeze([
  {
    label: 'Data',
    align: 'left',
  },
  {
    label: 'Versão',
    align: 'left',
  },
  {
    label: 'Descrição',
    align: 'left',
  },
  {
    label: 'Autor',
    align: 'left',
  },
  {
    label: '',
    align: 'right',
  },
]);


/**
 * Impact report review history table component
 */
export default function ImpactReportReviewHistoryTable() {
  const reportId = useSelector(selectCurrentReportId);
  const report = useSelector(selectCurrentReport);
  const versionId = useSelector(selectCurrentVersionId);
  const isGlobal = useSelector(selectIsGlobal);
  const dispatch = useDispatch();


  /**
   * Handle change in selected review version ID
   *
   * @param {number} newVersionId - updated review version ID
   */
  function setReportReviewVersion(newVersionId) {
    dispatch(setCurrentVersionId(newVersionId == versionId ? null : newVersionId));
  }

  useEffect(() => {
    if (!isGlobal && (report != null)) {
      dispatch(getImpactReport(report.id));
    }
  }, [reportId]);


  return (
    <Table
      headers={HEADERS}
      data={(report != null) && _.isArray(report.reviewHistory) ? report.reviewHistory : []}
      loading={!isGlobal && ((report == null) || !_.isArray(report.reviewHistory))}
      errorMessage="Falha ao carregar histórico de revisão"
      noDataMessage="Nenhum item encontrado no histórico de revisão"
      disablePagination
    >
      {
        (item, props) => {
          return (
            <TableRow {...props}>
              <TableCell >
                { moment(item.date).format('DD/MM/YYYY') }
              </TableCell>
              <TableCell >
                { item.version }
              </TableCell>
              <TableCell
                component="th"
                scope="row"
              >
                { item.description }
              </TableCell>
              <TableCell >
                { item.createdBy }
              </TableCell>
              <TableCell align="right">
                <IconButton
                  aria-label="add-impact-report"
                  onClick={() => setReportReviewVersion(item.id)}
                >
                  <DescriptionIcon
                    style={{
                      color: item.id == versionId ? 'black' : 'grey',
                    }}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          )
        }
      }
    </Table>
  );
}
