import { Notification } from 'rsuite'

export default function Notifications(
  type,
  description,
  title
) {
  return Notification[type]({
    top: 70,
    placement: 'topEnd',
    title: title,
    description: description,
  })
}
