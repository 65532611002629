import React from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import { Typography, } from '@material-ui/core';

import EditDataMapping from '../EditDataMapping';
import EditDataMappingContext from '../EditDataMapping/context';
import EditDataMappingTemplateContextProvider from './edit-data-mapping-template-context-provider';
import LimitedBackdropLoader from '../LimitedBackdropLoader';


function DataMappingTemplate({ dataMappingTemplate, }) {
  const { t, } = useTranslation();
  const { loading, phases, } = React.useContext(EditDataMappingContext);


  return (
    <>
      {
        !loading && (undefined != dataMappingTemplate) ?
        <EditDataMapping phases={phases} /> :
        (undefined == dataMappingTemplate) ?
        <Typography
          display="block"
          align="center"
          variant="h6"
          color="textSecondary"
          style={{
            marginTop: '1.5rem',
          }}
        >
          { _.capitalize(t('PAGES.DATA_MAPPING_TEMPLATES.select_a_template')) }
        </Typography> 
        
        :
        <LimitedBackdropLoader open={loading} />
      }
    </>
  );
}


export default function DataMappingTemplateWrapper({ dataMappingTemplate, isGlobal = false, }) {
  return (
    <EditDataMappingTemplateContextProvider
      isGlobal={isGlobal}
      dataMappingTemplate={dataMappingTemplate}
    >
      <DataMappingTemplate dataMappingTemplate={dataMappingTemplate} />
    </EditDataMappingTemplateContextProvider>
  );
}
