import { useState } from 'react'
import { Dialog, Container, IconButton, } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Button, Loader } from 'rsuite'
import parse from 'html-react-parser'
import moment from 'moment'

import {
  ContainerFooter,
  ContentFooter,
  FooterLeft,
  SocialMediaIcons,
} from './StyleFooter'
import './styles.css'
import { getPolitica } from '../../../services/api';


const SOCIAL_MEDIAS = Object.freeze([
  {
    IconComponent: FacebookIcon,
    link: 'https://www.facebook.com/octo.legall/',
  },
  {
    IconComponent: InstagramIcon,
    link: 'https://instagram.com/octo.legal',
  },
  {
    IconComponent: LinkedInIcon,
    link: 'https://www.linkedin.com/company/octolegal',
  },
  {
    IconComponent: WhatsAppIcon,
    link: 'https://wa.me/5541988377401',
  },
]);


export function FooterGlobal() {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(true);


  async function getData() {
    setLoading(true);
    const { data, } = await getPolitica('politica');
    setText(data[0].value);
    setLoading(false);
  }


  return (
    <ContainerFooter>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Container>
          <div className="PoliticaContents">
            <h1>Aviso de Privacidade</h1>
            {loading && (
              <Loader vertical content="Carregando..." />
            )}
            {!loading && <label>{parse(text)}</label>}
            <Button
              block
              appearance="primary"
              onClick={() => setOpen(false)}
            >
              Fechar
            </Button>
            <br />
          </div>
        </Container>
      </Dialog>

      <ContentFooter>
        <FooterLeft>
          <span>
            <span>&copy;</span> { moment().format("YYYY") } Octo, Inc.
          </span>

          <span
            onClick={() => {
              setOpen(!open)
              getData()
            }}
            style={{
              textDecoration: 'underline',
            }}
          >
            Política de privacidade
          </span>
        </FooterLeft>

        <SocialMediaIcons>
          {
            SOCIAL_MEDIAS.map(({ link, IconComponent, }, index) => {
              return (
                <a
                  key={index}
                  href={link}
                  target="_blank"
                >
                  <IconButton>
                    <IconComponent />
                  </IconButton>
                </a>
              );
            })
          }
        </SocialMediaIcons>
      </ContentFooter>
    </ContainerFooter>
  )
}
