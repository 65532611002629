import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import {CircularProgress, Dialog,} from '@material-ui/core';
import Label from '../../Global/Label';
import {
  Icon,
  IconButton,
  Notification,
  Message,
  Divider,
} from 'rsuite';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import { useSelector, } from 'react-redux';

import { postLinkPublico, getLinksPublico, } from '../../../services/api';
import './GeraLinkStyles.css';
import Links from './Link';
import { selectCompanySectors, } from '../../../features/companySectorsSlice';


const INITIAL_VALUES = Object.freeze({
  time: 1,
  sector: 0,
});


export default function LinkEsternoMapeamento() {
  const [link, setLink] = useState([]);
  const [open, setOpen] = useState(false);
  const companySectors = useSelector(selectCompanySectors);
  const [loading, setLoading] = useState(false);

  const VALID_SECTORS = companySectors.map((sector) => sector.id);


  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: generateLink,
    validationSchema: Yup.object({
      time: Yup.number().required('Obrigatório').min(1, 'A validade deve ser de pelo menos 1 dia'),
      sector: Yup.number().required('Obrigatório').oneOf(VALID_SECTORS, 'Deve ser um setor válido'),
    }),
  });

  async function generateLink(values) {
    try {
      const { data, } = await postLinkPublico(values.time, values.sector);

      if (data) {
        setLink(data);
      }

      setOpen(!open);

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Link criado com sucesso!',
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao criar link!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }
  }

  const getLinks = async () => {
    try {
      setLoading(true)
      const {data,} = await getLinksPublico();
      if (data) {
        setLink(data);
      }
      setLoading(false);
    } catch (e) {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao buscar links!',
        description: 'Tente novamente dentro de alguns instantes',
      });
      setLoading(false);
    }
  }

  function onCloseModal() {
    setOpen(false);
    formik.resetForm({
      values: INITIAL_VALUES,
    });
  }

  useEffect(() => {
    getLinks();
  }, []);


  return (
    <div>
      <Label
        description="Links para resposta"
        fontSize={18}
      />
      <Form>
        <IconButton
          className="GenerateButton"
          color="blue"
          placement="right"
          icon={<Icon icon="pencil" />}
          onClick={() => setOpen(true)}
        >
          Cadastrar link
        </IconButton>
        <Dialog
          open={open}
          onClose={onCloseModal}
        >
          <Message
            onClick={onCloseModal}
            closable
            type="info"
            description="Criar link externo"
          />

          <div className="ContainerForm">
            <Form.Group className="mb-3">
              <Form.Label className="inputLabel">
                Dias de validade do link
              </Form.Label>
              <Form.Control
                className="formInput"
                id="time"
                isInvalid={formik.touched.time && formik.errors.time}
                {...formik.getFieldProps('time')}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.time}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3"
              style={{
                width: '100%',
              }}
            >
              <Form.Label className="inputLabel">
                Setor
              </Form.Label>
              <Form.Control
                className="selectContent"
                as="select"
                id="sector"
                isInvalid={formik.touched.sector && formik.errors.sector}
                style={{
                }}
                {...formik.getFieldProps('sector')}
              >
                <option
                  key={0}
                  value={0}
                >
                  Escolha um setor
                </option>
                {
                  companySectors.map(({ id, name, }) => (
                    <option
                      key={id}
                      value={id}
                    >
                      {name}
                    </option>
                  ))
                }
              </Form.Control>

              <Form.Control.Feedback type="invalid">
                {formik.errors.sector}
              </Form.Control.Feedback>
            </Form.Group>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <IconButton
                color="blue"
                placement="right"
                icon={<Icon icon="ok-circle" />}
                type="submit"
                onClick={formik.handleSubmit}
              >
                Gerar link
              </IconButton>
            </div>
          </div>
        </Dialog>
      </Form>

      {
        loading &&
        <div style={{display: "flex", justifyContent: "center", margin: "0.5rem"}}>
          <CircularProgress/>
        </div>

      }

      {link &&
        link.map((link) => (
          <Links link={link} getLinks={getLinks} />
        ))}
      <Divider />
      <br />
    </div>
  )
}
