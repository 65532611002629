import React, { useEffect, useState } from 'react';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { useDispatch, useSelector, } from 'react-redux';

import { Button, Notification, } from 'rsuite';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import api from '../../services/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

import ActionList from '../../components/Resposta/ActionList';
import { HeaderProgress, } from '../../components/HeaderProgress';

import { listUsers, } from '../../features/usersSlice';
import { selectCompany, } from '../../features/companySlice';


export default function CompliantActions() {
  const { _id: idEmpresa, name: companyName, } = useSelector(selectCompany);
  const [body, setBody] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const dispatch = useDispatch();


  const onDownload = async () => {
    if (!downloading) {
      setDownloading(true);
      const key = 'downloading-report';
      Notification['info']({
        placement: 'bottomEnd',
        title: 'O relatório está sendo gerado!',
        description: 'Não é necessário atualizar a página, você poderá encontrar o arquivo na pasta downloads em alguns instantes.',
        duration: 0,
        key,
      });

      const url = `/companies/${idEmpresa}/risk-analysis-report/download?answered=1`;

      try {
        const { data, } = await api.get(url, {
          responseType: 'arraybuffer',
        });

        const reportName = `relatório-ações-em-conformidade-${companyName}-${moment().format('YYYYMMDD')}.pdf`;
        fileDownload(data, reportName);
      } catch (err) {
        console.log(err);
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Download do relatório falhou!',
          description: 'Um erro ocorreu durante o download do relatório solicitado, tente novamente mais tarde ou entre em contato com o suporte.',
        });
      }

      Notification.close(key);
      setDownloading(false);
    } else {
      Notification['warning']({
        placement: 'bottomEnd',
        title: 'O relatório já está sendo gerado!',
        description: 'O processamento da última requisição ainda foi finalizado.',
      });
    }
  }

  const initialize = async () => {
    const { data, } = await api.get(`/results?resposta=1&idEmpresa=${idEmpresa}`);
    setBody(data);
  }

  useEffect(() => {
    initialize();
    dispatch(listUsers());
  }, []);


  return (
    <>
      <Button
        onClick={onDownload}
        style={{
          backgroundColor: '#007bff',
          color: 'white',
          marginBottom: 20,
        }}
      >
        <DownloadIcon
          style={{
            marginRight: 8,
          }}
        />
        Baixar relatório
      </Button>

      <Container
        component={Paper}
        maxWidth="xl"
      >
        <br />
        {
          [null, 'Alto', 'Médio', 'Baixo'].map((risk) => {
            return (
              <ActionList questions={body.filter((item) => item.classificacao_risco === risk)} />
            );
          })
        }
      </Container>
    </>
  );
}
