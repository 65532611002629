
import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import { TableCell, TableRow, IconButton, } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';

import Table from '../../components/Table';


export default function ShareLinksProgressTable({
  loading,
  shareLinks,
  showError,
  collaboratorsMap,
  onEdit,
  onDelete,
  onResend,
}) {
  const { t, } = useTranslation();


  return (
    <Table
        headers={[
          {
            label: _.capitalize(t('name')),
            align: 'center',
          },
          {
            label: _.capitalize(t('email')),
            align: 'center',
          },
          {
            label: _.capitalize(t('PAGES.MATURITY_CHECKLIST_SHARE_LINKS.sent_at')),
            align: 'center',
          },
          {
            label: _.capitalize(t('PAGES.MATURITY_CHECKLIST_SHARE_LINKS.opened_at')),
            align: 'center',
          },
          {
            label: _.capitalize(t('PAGES.MATURITY_CHECKLIST_SHARE_LINKS.answered_at')),
            align: 'center',
          },
          {
            label: _.capitalize(t('PAGES.MATURITY_CHECKLIST_SHARE_LINKS.expires_at')),
            align: 'center',
          },
          {
            label: '',
            align: 'center',
          },
        ]}
        data={shareLinks}
        loading={loading}
        errorMessage={_.capitalize(t('ERRORS.MATURITY_CHECKLIST_SHARE_LINKS.failed_to_load_share_links'))}
        noDataMessage={_.capitalize(t('PAGES.MATURITY_CHECKLIST_SHARE_LINKS.no_share_links'))}
        disablePagination
        showError={showError}
      >
        {
          (shareLink, props) => {
            const collaborator = collaboratorsMap[shareLink.collaboratorId] || {};

            return (
              <TableRow {...props}>
                <TableCell align="center" >
                  { collaborator.name }
                </TableCell>
                <TableCell align="center" >
                  { collaborator.email }
                </TableCell>
                <TableCell align="center" >
                  { moment(shareLink.createdAt).format('HH:mm, DD/MM/YYYY') }
                </TableCell>
                <TableCell align="center" >
                  { shareLink.openedAt ? moment(shareLink.openedAt).format('HH:mm, DD/MM/YYYY') : '-' }
                </TableCell>
                <TableCell align="center" >
                  { shareLink.answeredAt ? moment(shareLink.answeredAt).format('HH:mm, DD/MM/YYYY') : '-' }
                </TableCell>
                <TableCell align="center" >
                  { moment(shareLink.expiresAt).format('HH:mm, DD/MM/YYYY') }
                </TableCell>
                <TableCell align="center">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <IconButton
                      onClick={() => onResend(shareLink.id)}
                      size="small"
                    >
                      <SendIcon fontSize="small" />
                    </IconButton>

                    <IconButton
                      onClick={() => onEdit(shareLink.id)}
                      size="small"
                    >
                      <CreateIcon fontSize="small" />
                    </IconButton>

                    <IconButton
                      onClick={() => onDelete(shareLink.id)}
                      color="secondary"
                      size="small"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            );
          }
        }
      </Table>
  );
}
