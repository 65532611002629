import React, { useEffect, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import _ from 'lodash';

import Container from '@material-ui/core/Container';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import Table from '../../components/Table';
import SearchField from '../../components/SearchField';
import { normalizeString } from '../../utils';
import {
  fetchCollaborators,
  selectFeatureStatus,
  selectCollaborators,
  setCollaboratorsModal,
} from '../../features/collaboratorsSlice';
import { CRUD_STATUSES, } from '../../utils/constants';
import { makeStyles } from '@material-ui/styles';


const HEADERS = Object.freeze([
  {
    label: 'Colaborador',
    align: 'left',
  },
  {
    label: 'E-mail',
    align: 'left',
  },
]);

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  selectAll: {
    paddingLeft: 16,
  },
});


/**
 * Table to show and select collaborators
 *
 * @param {array} selected - array of selected collaborators
 * @param {function} setSelected - function to request parent component to set selected
 * collaborators
 *
 * @returns rendered component
 */
function CollaboratorsTable({ selected, setSelected }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [searchString, setSearchString] = useState('');

  const allCollaborators = useSelector(selectCollaborators);
  const collaborators = useSelector((state) => {
    if (searchString.length > 0) {
      const normalizedSearchString = normalizeString(searchString);

      return state.collaborators.collaborators.filter((collaborator) => {
        const normalizedName = normalizeString(collaborator.name);
        return normalizedName.includes(normalizedSearchString);
      });
    } else {
      return state.collaborators.collaborators;
    }
  });
  const status = useSelector(selectFeatureStatus);


  /**
   * Toggle collaborator selection
   *
   * @param {number} collaboratorId - collaborator ID
   */
  function toggleCollaborator(collaboratorId) {
    if (selected.includes(collaboratorId)) {
      setSelected(selected.filter((id) => id != collaboratorId));
    } else {
      setSelected([...selected, collaboratorId]);
    }
  }

  function openCollaboratorsModal() {
    dispatch(setCollaboratorsModal(true));
  }

  /**
   * Toggle select all collaborators
   */
  function toggleSelectAllCollaborators() {
    const updatedSelectAll = !selectAll;
    setSelected(updatedSelectAll ? allCollaborators.map(({ id, }) => id) : []);
    setSelectAll(updatedSelectAll);
  }

  /**
   * Use effect to fetch collaborators
   */
  useEffect(() => {
    dispatch(fetchCollaborators());
  }, []);

  /**
   * Use effect to update loading state
   */
  useEffect(() => {
    setLoading((status !== CRUD_STATUSES.FETCH_FAILED) &&
      (status !== CRUD_STATUSES.FETCH_SUCCESSFUL));
  }, [status]);


  return (
    <Container
      maxWidth="xl"
      component={Paper}
      elevation={0}
      disableGutters
    >
    <div className={classes.container} >
      <FormControlLabel
        control={
          <Checkbox
            checked={selectAll}
            onChange={toggleSelectAllCollaborators}
            name="select-all-collaborators"
            color="primary"
          />
        }
        label="Selecionar todos"
        className={classes.selectAll}
      />
      <div className={classes.container} >
        <SearchField onChange={(searchString) => setSearchString(searchString || '')} />
        <IconButton onClick={openCollaboratorsModal} >
          <AddCircleIcon
            color="primary"
            fontSize="large"
          />
        </IconButton>
      </div>
    </div>

      <Table
        headers={HEADERS}
        data={collaborators}
        loading={loading}
        errorMessage="Falha ao carregar colaboradores"
        noDataMessage="Nenhum colaborador encontrado"
        disablePagination
        maxHeight={350}
      >
        {
          (collaborator, props) => {
            return (
              <TableRow {...props} >
                <TableCell align="left" >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Checkbox
                      checked={selected.includes(collaborator.id)}
                      onChange={() => toggleCollaborator(collaborator.id)}
                      name={ `select-${collaborator.name}` }
                      color="primary"
                      style={{
                        paddingLeft: 0,
                      }}
                    />
                    { collaborator.name }
                  </div>
                </TableCell>
                <TableCell align="left">
                  { collaborator.email }
                </TableCell>
              </TableRow>
            )
          }
        }
      </Table>
    </Container>
  );
}

export default CollaboratorsTable;
