import { createSlice } from '@reduxjs/toolkit'

export const classifcacaoMapeamento = createSlice({
  name: 'value',
  initialState: {
    value: [],
  },

  reducers: {
    resetCampos: (state) => {
      state.value = []
    },
    getCampos: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { getCampos, resetCampos } =
  classifcacaoMapeamento.actions

export default classifcacaoMapeamento.reducer
