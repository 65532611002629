import { useState } from 'react';

import { Form } from 'react-bootstrap'
import { Container, Dialog } from '@material-ui/core'
import { Icon, Button, Notification } from 'rsuite';

import Label from '../../components/Global/Label'

import { updateCompany, deleteCompany, } from '../../services/api'

import './styles.css'


/**
 * Modal to edit company
 *
 * @param {function} onClose - callback function called when the modal is closed
 * @param {object} data - object representing the company
 * @param {function} onDeleted - callback function called when company is deleted
 *
 * @returns rendered modal
 */
export default function EditCompanyModal({ onClose, data, onDeleted, }) {
  const [nameCompanies, setNameCompanies] = useState(data.name);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  /**
   * Send request to API to delete company
   *
   * @param {object} - company to delete
   */
  const onDeleteCompany = async () => {
    setLoading(true);

    try {
      await deleteCompany(data._id);
      setLoading(false);
      Notification['success']({
        placement: 'bottomEnd',
        title: 'Empresa excluída com sucesso!',
      });
      onDeleted();
    } catch {
      setLoading(false);
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Erro ao apagar empresa!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }
  }

  /**
   * Update company name in API
   */
  const updateName = async () => {
    setLoading(true);

    try {
      await updateCompany(data._id, {
        name: nameCompanies,
      });

      setLoading(false);
      Notification['success']({
        placement: 'bottomEnd',
        title: 'Nome alterado com sucesso!',
      });
    } catch {
      setLoading(false);
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Erro ao alterar nome da empresa!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }
  }


  return (
    <Container className="ContainerAll">
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <Container>
          <br />
          <Label
            description="Tem certeza que deseja deletar a empresa?"
            fontSize={25}
          />
          <br />
          <Button
            appearance="ghost"
            block
            loading={loading}
            onClick={onDeleteCompany}
          >
            Sim
          </Button>
          <Button
            appearance="primary"
            block
            loading={loading}
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
          <br />
        </Container>
      </Dialog>
      <div
        onClick={() => setOpen(true)}
        className="DeleteEnterprise"
      >
        <span className="SpanDelete">
          <Icon
            className="IconDelete"
            icon="trash"
            size="lg"
          />
          Excluir Empresa
        </span>
      </div>
      <Label
        description="Digite abaixo as informações que serão editadas"
        fontSize={20}
      />
      <Form className="FormContent">
        <Form.Group type="text">
          <Form.Control
            placeholder="Nome da empresa"
            value={nameCompanies}
            onChange={(event) => setNameCompanies(event.target.value)}
          />
        </Form.Group>
      </Form>
      <div className="ButtonsActionForm">
        <Button
          className="ButtonCancelarEdit"
          appearance="subtle"
          loading={loading}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          className="ButtonSalvar"
          appearance="ghost"
          color="primary"
          loading={loading}
          onClick={updateName}
        >
          Salvar
        </Button>
      </div>
    </Container>
  );
}
