import React from 'react';
import { useTranslation, } from 'react-i18next';

import { createStyles, } from '@material-ui/styles';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import {
  WidgetType,
  WIDGET_TYPE_ARRAY,
  REQUIRED_WIDGET_TYPES,
} from '../../../../entities/impact-report-widget';

// TODO: implement these before allowing the user to use them
const DISABLED_WIDGETS = [
  WidgetType.RISK_TREATMENT_MEASURES,
];


const useStyles = makeStyles(() =>
  createStyles({
    widgetTypeCard: {
      '&:hover': {
        outline: '2px solid #95ADC3',
      },
    },
  }),
);


export default function WidgetSelectionPanel({
  onSelected = (widgetType) => {},
}) {
  const classes = useStyles();
  const { t, } = useTranslation();
  const [selectedType, setSelectedType] = React.useState(WidgetType.TEXT);

  return (
    <Grid
      container
      spacing={1}
      style={{
        padding: '0.5rem',
      }}
    >
      {
        WIDGET_TYPE_ARRAY
          .filter((widgetType) => {
            return (
              !REQUIRED_WIDGET_TYPES.includes(widgetType) &&
              !DISABLED_WIDGETS.includes(widgetType)
            );
          })
          .map((widgetType) => {
            return (
              <Grid
                key={widgetType}
                item
                xs={12}
                sm={6}
                onClick={() => setSelectedType(widgetType)}
              >
                <Paper
                  variant="outlined"
                  className={classes.widgetTypeCard}
                  style={{
                    outline: widgetType === selectedType ? '2px solid #95ADC3' : undefined,
                    minHeight: '80px',
                  }}
                >
                  <Typography
                    gutterBottom
                    style={{
                      padding: '0.5rem',
                      fontWeight: 500,
                    }}
                  >
                    {t(`PAGES.IMPACT_REPORTS.v2.WIDGETS.${widgetType}.label`)}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                  >
                    {t(`PAGES.IMPACT_REPORTS.v2.WIDGETS.${widgetType}.description`)}
                  </Typography>
                </Paper>
              </Grid>
            );
          })
      }

      <Grid
        item
        xs={12}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
        >
          <Button
            disableElevation
            color="primary"
            onClick={() => onSelected(selectedType)}
            variant="contained"
          >
            {t('PAGES.IMPACT_REPORTS.v2.create_widget')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
