import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Notification, } from 'rsuite';

import * as api from '../services/api';
import { CRUD_STATUSES, } from '../utils/constants';


const initialState = {
  templates: [],
  currTemplateId: null,
  status: CRUD_STATUSES.IDLE,
};

export const listDataMappingTemplates = createAsyncThunk('dataMappingTemplates/fetch', async () => {
  const { data, status, } = await api.listDataMappingTemplates();
  if (200 === status) {
    return data.templates;
  } else {
    Notification['warning']({
      placement: 'bottomEnd',
      title: 'Falha ao carregar templates de mapeamento!',
      description: 'Tente novamente dentro de alguns instantes.'
    });
  }
});

export const createDataMappingTemplate = createAsyncThunk('dataMappingTemplates/create', async (payload) => {
  const { templateData, isGlobal = false, } = payload;
  const func = isGlobal ? api.createGlobalDataMappingTemplate : api.createDataMappingTemplate;
  const { data, } = await func(templateData);
  return data;
});

export const updateDataMappingTemplate = createAsyncThunk('dataMappingTemplates/update', async (payload) => {
  const { templateData, isGlobal = false, } = payload;
  const func = isGlobal ? api.updateGlobalDataMappingTemplate : api.updateDataMappingTemplate;
  const { data, } = await func(templateData);
  return data;
});

export const deleteDataMappingTemplate = createAsyncThunk('dataMappingTemplates/delete', async (payload) => {
  const { templateId, isGlobal = false, } = payload;
  const func = isGlobal ? api.deleteGlobalDataMappingTemplate : api.deleteDataMappingTemplate;
  const { status, } = await func(templateId);

  return 200 === status ? templateId : null;
});

export const dataMappingTemplatesSlice = createSlice({
  name: 'dataMappingTemplates',
  initialState,
  reducers: {
    setStatus: (state, { payload: status, }) => {
      if (status != null) {
        state.status = status;
      } else {
        state.status = initialState.status;
      }
    },
    setCurrentTemplate(state, { payload: currTemplateId, }) {
      state.currTemplateId = currTemplateId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listDataMappingTemplates.pending, (state) => {
      state.status = CRUD_STATUSES.LOADING;
    }).addCase(listDataMappingTemplates.fulfilled, (state, { payload: templates, }) => {
      state.status = CRUD_STATUSES.FETCH_SUCCESSFUL;
      state.templates = templates;
    }).addCase(listDataMappingTemplates.rejected, (state) => {
      state.status = CRUD_STATUSES.FETCH_FAILED;
      state.templates = initialState.templates;
    });

    builder.addCase(createDataMappingTemplate.fulfilled, (state, { payload: template, }) => {
      state.templates.push(template);
      if (template.id != undefined) {
        state.currTemplateId = template.id;
      }
    });

    builder.addCase(updateDataMappingTemplate.fulfilled, (state, { payload: template, }) => {
      const index = state.templates.findIndex(({ id, }) => id === template.id);
      if (index > -1) {
        state.templates[index] = template;
      }
    });

    builder.addCase(deleteDataMappingTemplate.fulfilled, (state, { payload: templateId, }) => {
      const index = state.templates.findIndex(({ id, }) => id === templateId);
      if (index > -1) {
        state.templates.splice(index, 1);

        if (templateId === state.currTemplateId) {
          state.currTemplateId = null;
        }
      }
    });
  }
});

export const selectFeatureStatus = (state) => {
  return state.dataMappingTemplates.status;
}

export const selectFeatureLoading = (state) => {
  const { status, } = state.dataMappingTemplates;
  return (status !== CRUD_STATUSES.FETCH_FAILED) && (status !== CRUD_STATUSES.FETCH_SUCCESSFUL);
}

export const selectTemplates = (state) => {
  return state.dataMappingTemplates.templates;
}

export const selectTemplatesByGlobalFlag = (state, isGlobal = false) => {
  return state.dataMappingTemplates.templates
    .filter((item) => Boolean(item.isGlobal) == isGlobal);
}

export const selectCurrentTemplate = (state) => {
  return state.dataMappingTemplates.templates.find((item) => {
    return item.id == state.dataMappingTemplates.currTemplateId;
  });
}

export const selectCurrentTemplateId = (state) => {
  return state.dataMappingTemplates.currTemplateId;
}

export const { setStatus, setCurrentTemplate, } = dataMappingTemplatesSlice.actions;
export default dataMappingTemplatesSlice.reducer;
