import { useEffect, useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';
import { useParams, } from 'react-router-dom';

import {
  Button,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { Notification, } from 'rsuite';

import Loading from '../../components/Global/Loading';
import ConfirmDialog from '../../components/ConfirmDialog';
import PublicPageLayout from '../../components/PublicPageLayout';

import {
  signDocument,
  getPublicCompany,
  getPublicDocumentSignatureRequest,
} from '../../services/api';
import useModal from '../../hooks/useModal';
import DocumentViewer from '../../components/DocumentViewer';


export default function PublicDueDiligencePage() {
  const { t, } = useTranslation();
  const { requestId, } = useParams();
  const [modalOpen, openModal, closeModal] = useModal();
  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState(undefined);
  const [company, setCompany]= useState(undefined);


  async function getData() {
    try {
      const { data, } = await getPublicDocumentSignatureRequest(requestId);
      setRequest(data);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.PUBLIC_DOCUMENT_SIGNATURE_REQUEST.failed_to_load_document')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function initialize() {
    setLoading(true);
    await Promise.all([getData()]);
    setLoading(false);
  }

  async function onSignDocument() {
    try {
      const { data, } = await signDocument(requestId);
      setRequest(data);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.PUBLIC_DOCUMENT_SIGNATURE_REQUEST.document_successfully_signed')),
      });
      closeModal();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.PUBLIC_DOCUMENT_SIGNATURE_REQUEST.failed_to_sign_document')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function getCompanyData(companyId) {
    try {
      const { data, } = await getPublicCompany(companyId);
      setCompany(data);
    } catch {
      //? Drop error
    }
  }

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (undefined != request && undefined != request.companyId) {
      getCompanyData(request.companyId);
    }
  }, [request]);


  return (
    <PublicPageLayout
      title=""
      companyName={undefined != company ? company.name : ''}
    >
      {
        loading ?
        <Loading value={loading} /> :
        undefined == request ?
        <Typography
          display="block"
          align="center"
          variant="h6"
          style={{
            marginTop: '5rem',
          }}
        >
          {_.capitalize(t('PAGES.PUBLIC_DOCUMENT_SIGNATURE_REQUEST.document_not_found'))}
        </Typography> :
        <Container maxWidth="xl" >
          <Typography
            display="block"
            align="center"
            variant="h5"
            style={{
              marginBottom: '2rem',
            }}
          >
            {_.capitalize(t('PAGES.PUBLIC_DOCUMENT_SIGNATURE_REQUEST.document'))}
          </Typography>

          <DocumentViewer
            fileLink={request.documentLink}
            fileType={request.documentType}
            googleRender
          />

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
              marginTop: '2rem',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={openModal}
              disabled={null != request.signedAt}
            >
              {_.capitalize(t('PAGES.PUBLIC_DOCUMENT_SIGNATURE_REQUEST.sign'))}
            </Button>
          </Grid>

          <ConfirmDialog
            open={modalOpen}
            confirmationMessage={t('PAGES.PUBLIC_DOCUMENT_SIGNATURE_REQUEST.are_you_sure_you_want_to_sign_document')}
            onClose={closeModal}
            onConfirm={onSignDocument}
          />
        </Container>
      }
    </PublicPageLayout>
  );
}
