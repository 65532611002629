import { Notification, } from 'rsuite';

import OctoDocument from '../../core/entities/document';


export default function validateFile(file) {
  if (undefined == file) {
    Notification['warning']({
      placement: 'bottomEnd',
      title: 'Arquivo inválido!',
    });
    return false;
  }

  if (file.size >= 10240000) {
    Notification['warning']({
      placement: 'bottomEnd',
      title: 'Arquivo inválido!',
      description: 'Selecione um arquivo menor',
    });
    return false;
  }

  if (!OctoDocument.validateFilenameExtension(file.name)) {
    const ext = OctoDocument.getFilenameExtension(file.name);

    Notification['warning']({
      placement: 'bottomEnd',
      title: 'Arquivo inválido!',
      description: `Extensão "${ext}" inválida`,
    });
    return false;
  }

  if (!OctoDocument.validateFilenameName(file.name)) {
    const invalidSymbols = OctoDocument.getInvalidSymbolsInFilename(file.name);
    Notification['warning']({
      placement: 'bottomEnd',
      title: 'Arquivo inválido!',
      description: `O nome do arquivo não deve conter: ${invalidSymbols.join(' ')}`,
    });
    return false;
  }

  return true;
}
