import React from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import { Icon, } from 'rsuite';
import TuneIcon from '@material-ui/icons/Tune';
import SpeedDial from '@material-ui/lab/SpeedDial';
import { makeStyles } from '@material-ui/styles';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import useModal from '../../hooks/useModal';


const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));


export default function SpeedDials({
  viewMode,
  onDownload,
  onNewReport,
  onChangeViewMode,
  onExportDataMapping,
}) {
  const { t, }= useTranslation();
  const actions = [
    {
      icon: <Icon icon="plus-square" size="lg" />,
      name: (
        <span style={{ fontSize: '15px' }}>
          Novo relatório
        </span>
      ),
      onClick: onNewReport,
    },
    {
      icon: <Icon icon={viewMode === 'table' ? 'print' : 'download'} size="lg" />,
      name: (
        <span style={{ fontSize: '15px' }}>
          { viewMode === 'table' ? 'Imprimir' : 'Download' }
        </span>
      ),
      onClick: onDownload,
    },
    {
      icon: (
        <Icon
          icon="cloud-download"
          size="lg"
        />
      ),
      name: (
        <span
          style={{
            fontSize: '15px',
          }}
        >
          { _.capitalize(t('PAGES.DATA_MAPPING_REPORT.export_data_mapping')) }
        </span>
      ),
      onClick: onExportDataMapping,
    },
  ];
  const classes = useStyles();
  const [open, handleOpen, handleClose] = useModal();


  return (
    <SpeedDial
      ariaLabel="SpeedDial example"
      className={classes.speedDial}
      icon={<TuneIcon />}
      onClick={handleOpen}
      onClose={handleClose}
      open={open}
      direction="up"
    >
      {
        actions.map((action, index) => (
          <SpeedDialAction
            key={index}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.onClick}
          />
        ))
      }
    </SpeedDial>
  );
}
