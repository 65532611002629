import React from 'react';

import Sheet from '../../components/Sheet';
import Classification from '.';

const PAGES = Object.freeze({
    classificacao: {
      id: 'classificacao',
      titleTab: "Classificaçao",
      titlePage: "Classificaçao",
      component: <Classification />,
    },
});

const ARRAY_PAGES = Object.values(PAGES);

export default function MapClassificacao() {

  return (
    <Sheet pages={ARRAY_PAGES} />
  );
}
