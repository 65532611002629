import { useState, } from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';

import DeleteDialog from '../DeleteDialog';
import DueDiligenceTemplateForm from '../DueDiligenceTemplateForm';

import logo from '../../assets/logo-blue.svg';

import useModal from '../../hooks/useModal';


export default function DueDiligenceTemplateList({
  templates,
  onDelete,
  onUpdate,
  onCreate,
  onOpen,
  component,
  isGlobalReadOnly = false,
}) {
  const { t, } = useTranslation();
  const [formModalOpen, openFormModal, closeFormModal] = useModal(onSelectTemplate,
    onSelectTemplate);
  const [deleteModalOpen, openDeleteModal, closeDeleteModal] = useModal(onSelectTemplate,
    onSelectTemplate);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const selectedTemplate = templates.find((item) => item.id === selectedTemplateId);


  function onSelectTemplate(id) {
    setSelectedTemplateId(id);
  }

  function _onDelete(selectedTemplateId) {
    onDelete(selectedTemplateId);
    closeDeleteModal();
  }


  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        style={{
          marginBottom: '1rem',
        }}
      >
        <Button
          disableElevation
          onClick={() => openFormModal()}
          variant="contained"
          color="primary"
          startIcon={<AddIcon/>}
        >
          {_.capitalize(t('PAGES.DUE_DILIGENCE.new_template'))}
        </Button>
      </Grid>

      <Container
        maxWidth="xl"
        style={{
          marginTop: '1rem',
          paddingTop: '1rem',
          paddingBottom: '1rem',
        }}
        component={component}
      >
        <List dense>
          {
            templates.sort((a, b) => {
              if (_.lowerCase(a.name) > _.lowerCase(b.name)) {
                return 1;
              } else if (_.lowerCase(b.name) > _.lowerCase(a.name)) {
                return -1;
              } else {
                return 0;
              }
            }).map((template) => {
              return (
                <ListItem
                  key={template.id}
                  style={{
                    marginBottom: '0.5rem',
                    backgroundColor: '#EEEEEE',
                  }}
                >
                  <ListItemText
                    primary={(
                      <Grid
                        container
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-start"
                      >
                        {template.name}
                        {
                          template.isGlobal ?
                          <img
                            src={logo}
                            height="12px"
                            style={{
                              marginLeft: '0.45rem',
                            }}
                          /> :
                          null
                        }
                      </Grid>
                    )}
                    secondary={template.description}
                  />

                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="open-template-management-modal"
                      onClick={() => onOpen(template.id)}
                    >
                      <LaunchIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="open-question-edit-modal"
                      onClick={() => openFormModal(template.id)}
                      disabled={isGlobalReadOnly && template.isGlobal}
                    >
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      edge="end"
                      aria-label="open-question-delete-modal"
                      onClick={() => openDeleteModal(template.id)}
                      disabled={isGlobalReadOnly && template.isGlobal}
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })
          }
        </List>

        <DeleteDialog
          open={deleteModalOpen}
          confirmationMessage={t('PAGES.DUE_DILIGENCE.are_you_sure_you_want_to_delete_template', {
            template: undefined != selectedTemplate ? selectedTemplate.name : '',
          })}
          onClose={() => closeDeleteModal()}
          onConfirm={() => _onDelete(selectedTemplateId)}
        />

        <Dialog
          open={formModalOpen}
          onClose={() => closeFormModal()}
        >
          <DialogTitle>
            {_.capitalize(t(`PAGES.DUE_DILIGENCE.${undefined != selectedTemplate ? 'edit' : 'create'}_due_diligence_template`))}
          </DialogTitle>
          <DialogContent>
            <DueDiligenceTemplateForm
              data={selectedTemplate}
              onSubmit={async (data) => {
                (undefined != selectedTemplate ? onUpdate(data) : onCreate(data));
                closeFormModal();
              }}
            />
          </DialogContent>
        </Dialog>

        {
          undefined == templates || templates.length === 0 ?
          <Typography
            variant="body1"
            display="block"
            align="center"
            style={{
              color: '#808080',
            }}
          >
            {_.capitalize(t('PAGES.DUE_DILIGENCE.no_due_diligence_templates_found'))}
          </Typography> :
          null
        }
      </Container>
    </>
  );
}
