import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Notification, } from 'rsuite';
import { t, } from 'i18next';

import * as api from '../services/api';
import { CRUD_STATUSES, } from '../utils/constants';
import { errorCodes, } from '../errors/standard-error';


const initialState = {
  companyCategories: [],
  companyCategoriesMap: {},
  status: CRUD_STATUSES.IDLE,
  managementModalOpen: false,
};


export const listCompanyCategories = createAsyncThunk('companyCategories/fetch', async (_, thunkAPI) => {
  const { data, status, } = await api.listCompanyCategories(thunkAPI.getState().company.company._id);
  if (200 === status) {
    return data.categories;
  } else {
    Notification['warning']({
      placement: 'bottomEnd',
      title: _.capitalize(t('ERRORS.COMPANY_CATEGORIES.failed_to_load_company_categories')),
      description: _.capitalize(t('ERRORS.try_again_later')),
    });
  }
});

export const createCompanyCategory = createAsyncThunk('companyCategories/create', async (companyCategory, thunkAPI) => {
  const { data, } = await api.createCompanyCategory(thunkAPI.getState().company.company._id, companyCategory);
  return data;
});


export const updateCompanyCategory = createAsyncThunk('companyCategories/update', async (companyCategory, thunkAPI) => {
  const { data, } = await api.updateCompanyCategory(thunkAPI.getState().company.company._id, companyCategory.id, companyCategory);
  return data;
});


export const deleteCompanyCategory = createAsyncThunk('companyCategories/delete', async (companyCategoryId, thunkAPI) => {
  try {
    const { status, } = await api.deleteCompanyCategory(thunkAPI.getState().company.company._id, companyCategoryId);
    return 200 === status ? companyCategoryId : null;
  } catch(err) {
    if ((undefined != err.response) && (undefined != err.response.data) &&
      errorCodes.COMPANY_CATEGORY_REFERENCED_ERROR === err.response.data.code) {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.COMPANY_CATEGORIES.failed_to_delete_company_category')),
        description: _.capitalize(t('ERRORS.COMPANY_CATEGORIES.company_category_referenced_error')),
      });

      return null;
    } else {
      throw(err);
    }
  }
});


export const companyCategoriesSlice = createSlice({
  name: 'companyCategories',
  initialState,
  reducers: {
    setStatus: (state, { payload: status, }) => {
      if (status != null) {
        state.status = status;
      } else {
        state.status = initialState.status;
      }
    },
    setCompanyCategoriesModal(state, { payload, }) {
      state.managementModalOpen = payload;
    },
    cleanCompanyCategories(state) {
      state.companyCategories = [];
      state.companyCategoriesMap = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listCompanyCategories.pending, (state) => {
      state.status = CRUD_STATUSES.LOADING;
    }).addCase(listCompanyCategories.fulfilled, (state, { payload: companyCategories, }) => {
      state.status = CRUD_STATUSES.FETCH_SUCCESSFUL;
      state.companyCategories = companyCategories;
      state.companyCategoriesMap = Object.fromEntries(companyCategories.map((category) => {
        return [category.id, category];
      }));
    }).addCase(listCompanyCategories.rejected, (state) => {
      state.status = CRUD_STATUSES.FETCH_FAILED;
      state.companyCategories = initialState.companyCategories;
      state.companyCategoriesMap = initialState.companyCategoriesMap;
    });

    builder.addCase(createCompanyCategory.fulfilled, (state, { payload: companyCategory, }) => {
      state.companyCategories.push(companyCategory);
      state.companyCategoriesMap[companyCategory.id] = companyCategory;
    });

    builder.addCase(updateCompanyCategory.fulfilled, (state, { payload: companyCategory, }) => {
      state.companyCategoriesMap[companyCategory.id] = companyCategory;
      const index = state.companyCategories.findIndex(({ id, }) => id === companyCategory.id);
      if (index > -1) {
        state.companyCategories[index] = companyCategory;
      }
    });

    builder.addCase(deleteCompanyCategory.fulfilled, (state, { payload: companyCategoryId, }) => {
      delete state.companyCategoriesMap[companyCategoryId];
      const index = state.companyCategories.findIndex(({ id, }) => id === companyCategoryId);
      if (index > -1) {
        state.companyCategories.splice(index, 1);
      }
    });
  }
});

/**
 * Custom select to get status
 *
 * @param {object} state - company categories slice state
 *
 * @returns the feature status
 */
export const selectFeatureStatus = (state) => {
  return state.companyCategories.status;
}

/**
 * Custom select to get company categories
 *
 * @param {object} state - company categories slice state
 *
 * @returns companyCategories
 */
export const selectCompanyCategories = (state) => {
  return state.companyCategories.companyCategories;
}

/**
 * Custom select to get company categories by ID
 *
 * @param {object} state - company categories slice state
 * @param {number} value - category ID
 *
 * @returns companyCategories
 */
export const selectCompanyCategoryById = (state, value) => {
  return state.companyCategories.companyCategoriesMap[value];
}

/**
 * Custom select to get company categories management modal state
 *
 * @param {object} state - company categories slice state
 *
 * @returns company categories management modal state
 */
export const selectCompanyCategoryModalState = (state) => {
  return state.companyCategories.managementModalOpen;
}

export const {
  setStatus,
  setCompanyCategoriesModal,
  cleanCompanyCategories,
} = companyCategoriesSlice.actions;
export default companyCategoriesSlice.reducer;
