import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, } from 'react-redux';

import { Input, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import Checkbox from './Checkbox';
import Tooltip from '../MapaDados/Tooltip';

import { MAPPING_FIELD_TYPES, } from '../../data/constants';

import Risk from '../../core/entities/risk';

import { getFieldType, } from '../../core/entities/data-mapping-field';
import { parseFieldResponse, } from '../../utils/mapping-field-select';
import { getCompanyDataMappingCellResponse, } from '../../services/api';

import { selectCompany } from '../../features/companySlice';


const useStyles = makeStyles({
  textField: {
    color: '#54708C',
    border: 'none !important',
    background: '#F2F4F8',
    height: '50px',
    overflow: 'hidden',
    padding: '0.4rem',
    fontFamily: 'Inter',
    borderRadius: 5,
    marginBottom: '0.5rem',
  },
});


const TextInput = ({ uid, field, }) => {
  const classes = useStyles();
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const [loading, setLoading] = useState(true);
  const type = getFieldType(field.asType, field.typePre, field.inputType) || MAPPING_FIELD_TYPES.TEXT;
  const [resp, setResp] = useState();


  async function getResponse() {
    setLoading(true);

    try {
      const { data, } = await getCompanyDataMappingCellResponse(company._id, uid, field.id);
      if (data[0]) {
        setResp(data[0].resp);
      }
    } catch (error) {
      //Drop error
    }

    setLoading(false);
  }

  useEffect(() => {
    getResponse();
  }, []);


  if (loading) {
    return (
      <Skeleton
        variant="rect"
        className={classes.textField}
      />
    );
  }

  if (type.value !== MAPPING_FIELD_TYPES.CHECKBOX.value) {
    if ([
      MAPPING_FIELD_TYPES.TEXT.value,
    ].includes(type.value)) {
      return (
        <Tooltip
          arrow
          title={resp}
          PopperProps={{
            modifiers: {
              offset: {
                enabled: true,
                offset: '0px, 0px',
              }
            }
          }}
          placement="top"
        >
          <Input
            type={field.inputType}
            value={resp}
            disableUnderline
            disabled
            className={classes.textField}
            margin="normal"
          />
        </Tooltip>
      );
    } else if ([
      MAPPING_FIELD_TYPES.PROCESSING_AGENTS.value,
      MAPPING_FIELD_TYPES.COLLABORATORS.value,
      MAPPING_FIELD_TYPES.SECTOR.value,
      MAPPING_FIELD_TYPES.PRESET.value,
      MAPPING_FIELD_TYPES.LEGAL_BASES.value,
      MAPPING_FIELD_TYPES.PURPOSE.value,
      MAPPING_FIELD_TYPES.PROCESSING_TYPE.value,
      MAPPING_FIELD_TYPES.DATA_MANAGEMENT_ROLE.value,
      MAPPING_FIELD_TYPES.PERSONAL_DATA_TYPE.value,
    ].includes(type.value)) {
      const parsedResp = Array.from(new Set(parseFieldResponse(resp)))
        .map((item) => Number(item));
      const value = parsedResp
        .map((id) => field.options.find((item) => item.id === id))
        .filter((item) => undefined != item)
        .map((item) => item.title)
        .join(', ');

      return (
        <Tooltip
          arrow
          title={value}
          PopperProps={{
            modifiers: {
              offset: {
                enabled: true,
                offset: '0px, 0px',
              }
            }
          }}
          placement="top"
        >
          <Input
            type={field.inputType}
            value={value}
            disableUnderline
            disabled
            className={classes.textField}
            margin="normal"
          />
        </Tooltip>
      );
    } else if (type.value == MAPPING_FIELD_TYPES.RISK.value) {
      let value = {
        probability: 0,
        impact: 0,
      };
      try {
        value = JSON.parse(resp);
      } catch {
      }
      const risk = Risk.getRisk(value.probability, value.impact);

      return (
        <div>
          <Input
            type={field.inputType}
            value={t(`RISK_STATUSES.${risk.key}`)}
            disableUnderline
            disabled
            className={classes.textField}
            margin="normal"
            style={{
              color: risk.color,
              outline: `1px solid ${risk.color}`,
            }}
          />
        </div>
      );
    } else if (
      [MAPPING_FIELD_TYPES.SENSITIVE_DATA_OPTION.value,
        MAPPING_FIELD_TYPES.SENSITIVE_DATA_CATEGORY.value].includes(type.value)) {
      const parsedResp = Array.from(new Set(parseFieldResponse(resp)));
      const value = parsedResp
        .map((id) => field.options.find((item) => item.id === id))
        .filter((item) => undefined != item)
        .map((item) => item.title)
        .join(', ');

      return (
        <Tooltip
          arrow
          title={value}
          PopperProps={{
            modifiers: {
              offset: {
                enabled: true,
                offset: '0px, 0px',
              }
            }
          }}
          placement="top"
        >
          <Input
            type={field.inputType}
            value={value}
            disableUnderline
            disabled
            className={classes.textField}
            margin="normal"
          />
        </Tooltip>
      );
    } else {
      return (
        <div>
          <Input
            type={field.inputType}
            value={resp}
            disableUnderline
            disabled
            className={classes.textField}
            margin="normal"
          />
        </div>
      );
    }
  } else {
    return (
      <div>
        <div>
          <Checkbox
            checked={resp}
            disabled
          />
        </div>
      </div>
    );
  }
}

export default TextInput;
