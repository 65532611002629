import { Typography, CircularProgress, Box, } from '@material-ui/core';


export default function CircularProgressWithLabel(props) {
  return (
    <Box
      position="relative"
      display="inline-flex"
    >
      <CircularProgress
        variant="determinate"
        thickness={6}
        {...props}
      />
      <Box
        top={0}
        left={10}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >
          <strong>
            { `${Math.round(props.value)}%` }
          </strong>
        </Typography>
      </Box>
    </Box>
  );
}
