import { useState } from 'react';
import { Grid, Typography, TablePagination, } from '@material-ui/core';
import { Table } from 'react-bootstrap';
import Container from '../Global/ContainerWithPaper';
import Label from '../Global/Label';
import CorpoPlanoDeAcao from '../CorpoMon';
import './MonitoraStyles.css';


export default function AcoesAndamento({ body, users, reunionUnique, }) {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);


  const onChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const offset = page * rowsPerPage;


  return (
    <Grid
      item
      xs={12}
    >
      <Label
        description="Ações em andamento"
        fontSize={16}
      />
      <Container>
        <Grid
          className="boxEmAndamento"
          container
          direction="row"
          alignItems="center"
        >
          <Typography>
            <strong
              style={{
                fontSize: 18,
                color: '#3B66FF',
              }}
            >
              Ações
            </strong>
          </Typography>
        </Grid>

        <div className="containerTr">
          <Table
            responsive
            className="TableEmAndamento"
          >
            <thead>
              <tr>
                <th>Quem?</th>
                <th>O que?</th>
                <th>Quando</th>
                <th></th>
              </tr>
            </thead>
            {
              body.slice(offset, offset + rowsPerPage).map((body) => {
                return (
                  <CorpoPlanoDeAcao
                    key={body.id}
                    data={body}
                    users={users}
                    reunionUnique={reunionUnique}
                  />
                );
              })
            }
          </Table>
        </div>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={body.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Container>
    </Grid>
  );
}
