import React, {useEffect, useState,} from 'react';

import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid, TextField,
} from '@material-ui/core';
import {useFormik} from "formik";
import {getAffiliatesLink} from "../../services/api";
import LimitedBackdropLoader from "../../components/LimitedBackdropLoader";
import {Notification} from "rsuite";

const INITIAL_VALUES = Object.freeze({
    link: '',
});
let apiHost;

switch (window.location.host) {
    case 'localhost:3000':
        apiHost = `${process.env.REACT_APP_API_LOCAL}/`

        break
    case 'dev.octo.legal':
        apiHost = 'https://apidev.octo.legal/'
        break
    case 'homologacao.octo.legal':
        apiHost = 'https://apihomologacao.octo.legal/'
        break
    case 'octo.legal':
        apiHost = process.env.REACT_APP_API_PROD

    default:
        apiHost = process.env.REACT_APP_API_PROD
}

export default function AffiliatesModal({open, onClose}) {
    const formik = useFormik({
        initialValues: INITIAL_VALUES,
    });

    const [loading, setLoading] = useState(false);
    const userId = localStorage.getItem('user');

    const initialize = async () => {
        try {
            setLoading(true);
            const getAffiliatedLinkRes = await getAffiliatesLink(userId);
            formik.setValues({
                link: `${apiHost}al/${getAffiliatedLinkRes.data.id}`, // TODO FORMAT LINK TO CORRECT URL
            });
            setLoading(false);
        } catch (e) {
            Notification['error']({
                placement: 'bottomEnd',
                title: `Falha ao buscar o link de Afiliado!`,
                description: 'Tente novamente dentro de alguns instantes.'
            });
            onClose();
        }
    }

    useEffect(() => {
        initialize();
    }, []);


    const copyToClipBoard = async () => {
        if (!navigator.clipboard) return;
        const formattedTextToClipBoard = `${window.location.host}/al/${formik.values.link}`
        await navigator.clipboard.writeText(formik.values.link).catch(() => {
            console.log("Navigator clipboard: Cold not copy");
        })
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: {
                    minWidth: '700px',
                    maxWidth: '90%',
                },
            }}
        >
            <DialogContent>
                <Typography
                    variant="body1"
                    display="block"
                    align="start"
                >
                    Link de Comissionamento
                </Typography>
                <Grid
                    container
                    justifyContent="start"
                    alignContent="center"
                >
                    <LimitedBackdropLoader open={loading}/>
                    {!loading && (<div>
                        <TextField
                            margin="dense"
                            id="link"
                            variant="filled"
                            style={{width: '550px'}}
                            type="text"
                            error={formik.errors.name && formik.touched.name}
                            helperText={formik.touched.name && formik.errors.name}
                            disabled
                            {...formik.getFieldProps('link')}
                        />

                        <Button
                            loading={true}
                            onClick={copyToClipBoard}
                            style={{
                                marginLeft: '1rem',
                                borderColor: "black",
                                height: '48px',
                                marginTop: '0.5rem',
                            }}
                            variant="outlined"
                        >
                            Copiar
                        </Button>
                    </div>)
                    }
                </Grid>
            </DialogContent>

            <DialogActions>
                <Grid
                    container
                    justifyContent="center"
                >
                    <Button
                        onClick={onClose}
                        style={{
                            backgroundColor: "white",
                            marginRight: '0.5rem',
                            borderColor: "black",
                        }}
                        variant="outlined"
                    >
                        <Typography
                            variant="primary"
                            align="center"
                            style={{

                            }}
                        >
                            Fechar
                        </Typography>
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
