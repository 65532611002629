import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import { Notification, } from 'rsuite';
import * as api from '../services/api';
import { CRUD_STATUSES, } from '../utils/constants';

const initialState = {
  courses: [],
  status: CRUD_STATUSES.IDLE,
};

export const fetchCourses = createAsyncThunk('courses/fetchCourses', async (_, thunkAPI) => {
  const companyId = thunkAPI.getState().company.company._id;

  const { data, status, } = await api.getCourses(companyId);
  if (200 === status) {
    return data;
  } else {
    Notification['warning']({
      placement: 'bottomEnd',
      title: 'Falha ao carregar cursos!',
      description: 'Tente novamente dentro de alguns instantes.'
    });
  }
});

export const getCourse = createAsyncThunk('courses/getCourse', async (courseId, thunkAPI) => {
  const companyId = thunkAPI.getState().company.company._id;

  const { data, } = await api.getCompanyCourse(companyId, courseId);
  return data;
});

export const createCourse = createAsyncThunk('courses/createCourse', async (course, thunkAPI) => {
  const companyId = thunkAPI.getState().company.company._id;

  const { data, } = await api.createCourse(companyId, course);
  return data;
});

export const updateCourse = createAsyncThunk('courses/updateCourse', async (course, thunkAPI) => {
  const companyId = thunkAPI.getState().company.company._id;

  const { data, } = await api.updateCourse(companyId, course.id, course);
  return data;
});

export const deleteCourse = createAsyncThunk('courses/deleteCourse', async (courseId, thunkAPI) => {
  const companyId = thunkAPI.getState().company.company._id;

  const { data, } = await api.deleteCourse(companyId, courseId);
  return courseId;
});

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setStatus: (state, { payload: status, }) => {
      if (status != null) {
        state.status = status;
      } else {
        state.status = initialState.status;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCourses.pending, (state) => {
      state.status = CRUD_STATUSES.LOADING;
    }).addCase(fetchCourses.fulfilled, (state, { payload: courses, }) => {
      state.status = CRUD_STATUSES.FETCH_SUCCESSFUL;
      state.courses = courses;
    }).addCase(fetchCourses.rejected, (state) => {
      state.status = CRUD_STATUSES.FETCH_FAILED;
      state.courses = initialState.courses;
    });

    builder.addCase(getCourse.pending, (state) => {
      state.status = CRUD_STATUSES.LOADING;
    }).addCase(getCourse.fulfilled, (state, { payload: course, }) => {
      state.status = CRUD_STATUSES.FETCH_SUCCESSFUL;

      const index = state.courses.findIndex(({ id, }) => id === course.id);
      if (index > -1) {
        if (course.test && course.test.questions) {
          for (const question of course.test.questions) {
            for (const alt of question.alternatives) {
              alt.isCorrect = Boolean(alt.is_correct);
              delete alt.is_correct;
            }
          }
        }
        state.courses[index] = course;
      }
    }).addCase(getCourse.rejected, (state) => {
      state.status = CRUD_STATUSES.FETCH_FAILED;
    });

    builder.addCase(createCourse.fulfilled, (state, { payload: course, }) => {
      state.courses.push(course);
    });

    builder.addCase(updateCourse.fulfilled, (state, { payload: course, }) => {
      const index = state.courses.findIndex(({ id, }) => id === course.id);
      if (index > -1) {
        state.courses[index] = course;
      }
    });

    builder.addCase(deleteCourse.fulfilled, (state, { payload: courseId, }) => {
      const index = state.courses.findIndex(({ id, }) => id === courseId);
      if (index > -1) {
        state.courses.splice(index, 1);
      }
    });
  }
});

/**
 * Custom select to select a course by ID
 *
 * @param {object} state - course slice state
 * @param {number} courseId - course ID
 *
 * @returns the selected course
 */
export const selectCourseById = (state, courseId) => {
  return state.courses.courses.find(course => course.id == courseId);
}

/**
 * Custom select to get status
 *
 * @param {object} state - course slice state
 *
 * @returns the feature status
 */
export const selectFeatureStatus = (state) => {
  return state.courses.status;
}

/**
 * Custom select to get status
 *
 * @param {object} state - course slice state
 *
 * @returns the feature status
 */
export const selectLoadingCourses = (state) => {
  const { status, courses, } = state.courses;
  return (0 === courses.length) && (CRUD_STATUSES.FETCH_SUCCESSFUL !== status) &&
    (CRUD_STATUSES.FETCH_FAILED !== status);
}

/**
 * Custom select to get courses
 *
 * @param {object} state - course slice state
 *
 * @returns courses
 */
export const selectCourses = (state) => {
  return state.courses.courses;
}

export const { setStatus, } = courseSlice.actions;
export default courseSlice.reducer;
