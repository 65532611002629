import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { deepPurple, } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 250,
    width: 250,
    margin: 10,
    padding: 0,
    boxShadow: '0 8px 18px 0 rgba(0,0,0,0.12)',
    borderRadius: '1.5rem',
    position: 'absolute',
    zIndex: (props) => props.zIndex || 1,
  },
  header: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 5,
    paddingRight: 5,
  },
  button: {
    "&:hover": {
      backgroundColor: 'transparent',
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    paddingBottom: '0.2rem',
    paddingTop: '0.2rem',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: '#3B66FF',
  },
  playIcon: {
    color: '#3B66FF',
  }
}));


export default function RecipeReviewCard(props) {
  const { title, avatarLabel, actions, left, top, onOpenVideo, videoUrl, } = props;
  const classes = useStyles(props);
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      className={classes.root}
      style={{
        left,
        top,
      }}
    >
      <CardHeader
        className={classes.header}
        avatar={
          <Avatar
            className={classes.avatar}
          >
            { avatarLabel }
          </Avatar>
        }
        action={
          <IconButton
            disableRipple
            disableFocusRipple
            className={classes.button}
            onClick={() => onOpenVideo(videoUrl)}
          >
            <PlayCircleOutlineIcon
              color="primary"
              fontSize="large"
              className={classes.playIcon}
            />
          </IconButton>
        }
        title={title}
        titleTypographyProps={{
          variant: "body1",
        }}
      />

      <CardActions
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        <IconButton
          disableRipple
          disableFocusRipple
          className={clsx(classes.expand, classes.button, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>

      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <CardContent
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          <Typography
            variant="body1"
            style={{
              color: "#54708C",
            }}
          >
            <ul>
              {
                actions.map((action) => {
                  return (
                    <li>
                      { action }
                    </li>
                  );
                })
              }
            </ul>
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
