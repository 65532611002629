import React from 'react';

import Sheet from '../../components/Sheet';
import DueDiligencePage from '.';

import DueDiligenceRequests from './DueDiligenceRequests';

const PAGES = Object.freeze({
  templates: {
    id: 'templates',
    titleTab: "Templates",
    titlePage: "Templates",
    component: <DueDiligencePage />,
  },
  dueDiligencePage: {
    id: 'dueDiligencePage',
    titleTab: "Avaliações enviadas",
    titlePage: "Avaliações enviadas",
    component: <DueDiligenceRequests />,
  },
});

const ARRAY_PAGES = Object.values(PAGES);

export default function MapDueDiligence() {

  return (
    <Sheet pages={ARRAY_PAGES} />
  );
  
}
