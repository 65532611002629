import { Collapse, Grid } from "@material-ui/core";
import { Loader } from "rsuite";
import ContainerPapered from "./ContainerWithPaper";

export default function Loading({ value }) {
  return (
    <Grid item xs={12}>
      <Collapse in={value}>
        <ContainerPapered>
          <Loader backdrop vertical speed="slow" content="Carregando" />
        </ContainerPapered>
      </Collapse>
    </Grid>
  );
}
