import { Container, Dialog } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import AnalisRisc from '../../components/RiscoAnaliseComponent/RiscAnalisisShow'


export function ModalPlanoAcao2({ isOpen, onRequestClose, users, reunionUnique, data, }) {
  return (
    <Dialog
      open={isOpen}
      onClose={onRequestClose}
      maxWidth="xl"
    >
      <Container maxWidth="xl" disableGutters>
        <Alert onClose={onRequestClose} severity="info" />
        <AnalisRisc
          users={users}
          data={data}
          reunions={reunionUnique}
          description="Itens para revisão"
        />
      </Container>
    </Dialog>
  );
}
