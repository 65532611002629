import React from 'react';
import _ from 'lodash';
import {
  Grid,
  Checkbox,
  Typography,
  IconButton,
} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/styles';
import { FieldArray, Field, useFormikContext, } from 'formik';
import { green, blue, } from '@material-ui/core/colors';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import Error from '../Error';
import QuestionOptionInput from './QuestionOptionInput';


const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
    padding: 0,
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const INITIAL_OPTION_DATA = Object.freeze({
  statement: '',
  isCorrect: false,
});


const useStyles = makeStyles({
  title: {
    color: blue[400],
  },
  fieldContainer: {
    marginBottom: '0.5rem',
  },
});


export default function QuestionOptionsForm({ questionIdx, }) {
  const classes = useStyles();
  const { values, } = useFormikContext();


  return (
    <FieldArray name={`test.questions[${questionIdx}].alternatives`}>
      {
        ({ push, remove, }) => {
          return (
            <>
              <Typography variant="subtitle1">
                Opções de resposta
                <IconButton
                  className={classes.title}
                  onClick={(ev) => {
                    ev.preventDefault();
                    push(_.cloneDeep(INITIAL_OPTION_DATA));
                  }}
                >
                  <AddCircleIcon />
                </IconButton>
              </Typography>
              {
                values.test.questions[questionIdx].alternatives &&
                values.test.questions[questionIdx].alternatives.length > 0 &&
                values.test.questions[questionIdx].alternatives.map((option, idx) => {
                  return (
                    <Grid
                      className={classes.fieldContainer}
                      key={idx}
                      container
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Field name={`test.questions[${questionIdx}].alternatives[${idx}].statement`} >
                        {
                          ({ field, }) => (
                            <QuestionOptionInput
                              idx={idx}
                              {...field}
                              onRemoveOption={(ev) => {
                                ev.preventDefault();
                                if (values.test.questions[questionIdx].alternatives.length > 2) {
                                  remove(idx);
                                }
                              }}
                            />
                          )
                        }
                      </Field>
                      <Field name={`test.questions[${questionIdx}].alternatives[${idx}].isCorrect`} >
                        {
                          ({ field, }) => (
                            <GreenCheckbox
                              {...field}
                              checked={field.value}
                              size="medium"
                            />
                          )
                        }
                      </Field>
                      <Error name={`test.questions[${questionIdx}].alternatives[${idx}].statement`} />
                    </Grid>
                  );
                })
              }
            </>
          );
        }
      }
    </FieldArray>
  );
}
