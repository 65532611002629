import { createGlobalStyle } from 'styled-components'


export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root {
    --blue-800: #0059F7;
    --blue-1000: #1C265A;
    --blue-500: #7E8BB6;
    --blue-400: #689EFF;

    --Title-blue: #273464;

    --text-gray: #070E27;

    --background-body: #3B66FF3A;

    --white: #FFFFFF;

    --primary: #3B66FF;
  }

  @media (max-width: 1080px) {
    html {
      font-size: 93.75%;
    }
  }

  @media (max-width: 728px) {
    html {
      font-size: 87.5%;
    }
  }

  body {
    background: var(--background-body);
    color: var(--text);
  }

  body, input, textarea, select, button {
    font: 400 1rem "Sora", sans-serif;
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;
