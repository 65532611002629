import React, { useMemo, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Form, Col, Image, Row } from 'react-bootstrap';
import { Button, IconButton, Icon, Whisper, Popover } from 'rsuite';
import { Badge, Container, Grid, Paper, Chip } from '@material-ui/core';

import impactImg from '../../assets/impacto.jpeg';
import probabilityImg from '../../assets/probabilidade.jpeg';
import riskMatrixImg from '../../assets/matrizderisco.jpeg';
import maturityLevelChartImg from '../../assets/maturity-level-chart.png';

import {
  MATURITY_OPTIONS,
  QUESTION_STATUSES,
  REVERSED_QUESTION_CHANGE_REASONS,
} from '../../utils/constants';
import { PROBABILITY_OPTIONS, IMPACT_OPTIONS } from '../../core/entities/risk';

import QuestionObservationList from '../QuestionObservationList';
import EvidenceButton from '../EvidenceButton';

function QuestionSelectField({
  label,
  subtitle,
  helpImage,
  value,
  defaultValue,
  onChange,
  options
}) {
  const { t } = useTranslation();

  return (
    <Form.Group>
      <Form.Label>
        <strong
          style={{
            color: '#3B66FF',
            fontSize: '18px',
          }}
        >
          {label}{' '}
          {helpImage ? (
            <Whisper
              placement="auto"
              trigger="click"
              speaker={
                <Popover>
                  <Image src={helpImage} alt={label} width={720} />
                </Popover>
              }
            >
              <IconButton
                appearance="ghost"
                size="xs"
                icon={<Icon icon="question" className="fill-color" />}
              />
            </Whisper>
          ) : null}
        </strong>
      </Form.Label>

      <Form.Control
        as="select"
        style={{ alignItems: 'center' }}
        value={value}
        defaultValue={defaultValue}
        onChange={(event) => onChange(event.target.value)}
      >
        <option value={0}>{_.capitalize(t('PAGES.choose_a_value'))}</option>
        {options.map(({ value, key }) => (
          <option key={value} value={value}>
            {_.capitalize(t(key))}
          </option>
        ))}
      </Form.Control>
      {subtitle ? <p style={{ color: 'grey' }}>{subtitle}</p> : null}
    </Form.Group>
  );
}

const QuestionItemCard = ({
  data,
  observations,
  loading,
  questionStatus,
  questionRiskStatus,
  obs,
  category,
  user,
  users = [],
  collaborator,
  collaborators = [],
  onUpdateProbability,
  onUpdateImpact,
  onUpdateMaturityLevel,
  onSubmitReview,
  onSubmitCompliant,
  onInsertObs,
  onToggleFastActionItem,
  onDeleteObservation,
  setObs,
  existEvidences,
  onAttachEvidence = () => { },
  onDownloadEvidence = () => { },
}) => {
  const { t } = useTranslation();
  const changeReason = REVERSED_QUESTION_CHANGE_REASONS[data.change_reason];
  const isUnanswered = QUESTION_STATUSES.UNANSWERED.label === questionStatus.label;

  function _onDeleteObservation(obsId) {
    onDeleteObservation(data.id, obsId);
  }

  const [evidenceStatus, setEvidenceStatus] = useState(existEvidences);

  useEffect(() => {
    setEvidenceStatus(existEvidences);
  }, [existEvidences]);

  return (
    <Container maxWidth="xl" component={Paper}>
      <br />
      <Row>
        <Col xs={6}>
          <p>{data.problema}</p>
        </Col>

        <Col xs={2}>
          <QuestionSelectField
            label={_.capitalize(t('probability'))}
            helpImage={probabilityImg}
            onChange={onUpdateProbability}
            value={data.probabilidade}
            defaultValue={_.capitalize(t('PAGES.choose_a_value'))}
            options={PROBABILITY_OPTIONS}
          />
        </Col>

        <Col xs={2}>
          <QuestionSelectField
            label={_.capitalize(t('impact'))}
            helpImage={impactImg}
            onChange={onUpdateImpact}
            value={data.impacto}
            defaultValue={_.capitalize(t('PAGES.choose_a_value'))}
            options={IMPACT_OPTIONS}
          />
        </Col>

        <Col xs={2}>
          <QuestionSelectField
            label={_.capitalize(t('maturity'))}
            subtitle={category}
            helpImage={maturityLevelChartImg}
            onChange={onUpdateMaturityLevel}
            value={data.category_maturity_level}
            defaultValue={_.capitalize(t('PAGES.choose_a_value'))}
            options={MATURITY_OPTIONS}
          />
        </Col>
      </Row>

      <Row>
        <Form.Group as={Col}>
          <strong style={{ color: '#3B66FF', fontSize: '18px' }}>
            {_.capitalize(t('observation', { count: 2 }))}
          </strong>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder={_.capitalize(t('observation', { count: 1 }))}
            value={obs}
            onChange={(e) => setObs(e.target.value)}
          />
          <br />
          <IconButton
            style={{ marginLeft: '95%' }}
            icon={<Icon icon="save" />}
            onClick={onInsertObs}
            color="green"
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Container style={{ float: 'right', height: '250px' }}>
            <strong style={{ color: '#3B66FF', fontSize: '20px' }}>
              {_.capitalize(t('PAGES.history'))}:
            </strong>

            <QuestionObservationList
              users={users}
              collaborators={collaborators}
              observations={observations}
              onDelete={onDeleteObservation ? _onDeleteObservation : undefined}
            />
          </Container>
        </Form.Group>
      </Row>

      <Grid container direction="row" alignItems="center" justifyContent="space-between">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Form.Check
            type="checkbox"
            label={_.capitalize(t('PAGES.QUESTION_ITEM_CARD.fast_action_item'))}
            checked={data.rapida}
            onChange={onToggleFastActionItem}
            style={{ marginRight: '1rem' }}
          />

          <div style={{ marginRight: '1rem' }}>
            <Whisper
              placement="auto"
              trigger="click"
              speaker={
                <Popover>
                  <Image src={riskMatrixImg} alt={_.capitalize(t('risk'))} width={820} />
                </Popover>
              }
            >
              <Badge color="primary" badgeContent="?">
                <Button
                  style={{
                    color: '#3B66FF',
                    fontWeight: 'bold',
                    backgroundColor: 'transparent',
                  }}
                  disableElevation
                  disableFocusRipple
                  disableRipple
                >
                  {_.capitalize(t('risk'))}:
                </Button>
              </Badge>
            </Whisper>
            <Chip
              style={{
                marginLeft: '1rem',
                backgroundColor: questionRiskStatus.color,
                color: 'white',
              }}
              label={t(`RISK_STATUSES.${questionRiskStatus.key}`)}
              size="small"
            />
          </div>

          {changeReason && (user || collaborator) && (
            <div style={{ marginRight: '1rem', color: '#3B66FF', fontWeight: 'bold' }}>
              {t(`QUESTION_CHANGE_REASONS.${changeReason}`, {
                name: user ? user.name : collaborator ? collaborator.name : '',
                date: moment(data.changed_at).format('DD/MM/YY'),
              })}
            </div>
          )}

          <EvidenceButton
            questionId={data.id}
            existEvidences={evidenceStatus}
            onAttach={(file) => onAttachEvidence(data.id, file)}
            onDownload={onDownloadEvidence}
          />
        </div>

        <Grid item xs="auto">
          <Button
            color={questionStatus.reviewBtnColor}
            loading={loading}
            onClick={onSubmitReview}
            style={{ border: isUnanswered ? '1px solid red' : '' }}
          >
            {t('PAGES.QUESTION_ITEM_CARD.need_review')}
          </Button>{' '}
          <Button
            color={questionStatus.compliantBtnColor}
            loading={loading}
            onClick={onSubmitCompliant}
            style={{ border: isUnanswered ? '1px solid green' : '' }}
          >
            {t('PAGES.QUESTION_ITEM_CARD.compliant')}
          </Button>
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};

export default React.memo(QuestionItemCard);
