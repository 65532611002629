import React from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import DataMappingAnswerPanelContext from './context';


export default function DataMappingIndexMenuColumn({ answers, onNewLineBelow, onDeleteLine, }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedAnswer, setSelectedAnswer] = React.useState({});
  const { allowCreation = true, } = React.useContext(DataMappingAnswerPanelContext);


  const handleClick = (event, answer) => {
    setAnchorEl(event.currentTarget);
    setSelectedAnswer(answer);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedAnswer({});
  };


  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '0px 2px 0 0',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          margin: 0,
          padding: '5px',
          height: '2.5rem',
          marginBottom: '2px',
        }}
      >
      </div>
      {
        answers
          .map((answer) => {
            return (
              <div
                key={answer.uid}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '1.44px',
                  height: '38px',
                  width: '100%',
                  color: 'gray',
                }}
              >
                <Button
                  size="small"
                  disableElevation
                  variant="contained"
                  aria-haspopup="true"
                  onClick={(ev) => handleClick(ev, answer)}
                  aria-controls="simple-menu"
                  startIcon={<MoreHorizIcon />}
                  style={{
                    height: '100%',
                  }}
                >
                  { answer.ordem }
                </Button>
              </div>
            );
          })
      }

      <Menu
        keepMounted
        id="simple-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        MenuListProps={{
          dense: true,
          style: {
            border: '1px solid #00000020',
            padding: 0,
          },
        }}
        elevation={0}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          dense
          style={{
            padding: '0.2rem',
            display: allowCreation ? undefined : 'none',
          }}
          onClick={() => onNewLineBelow(selectedAnswer)}
        >
          <KeyboardArrowDownIcon />
          Adicionar uma linha abaixo
        </MenuItem>
        <MenuItem
          dense
          style={{
            padding: '0.2rem',
          }}
          onClick={() => {
            onDeleteLine(selectedAnswer);
            handleClose();
          }}
        >
          <DeleteForeverIcon />
          Deletar linha
        </MenuItem>
      </Menu>
    </div>
  );
}
