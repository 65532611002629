import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


export const Container = styled.div`
  width: 100%;
  max-width: 100%;
`;


function TabPanel({ children, value, index, ...other }) {
  return (value === index) ? (
    <Container
      role="tabpanel"
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      { children }
    </Container>
  ) :
  null;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel;
