import React, { useEffect, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import {
  Grid,

  Container,
  Typography,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import {makeStyles,} from '@material-ui/styles';

import { CRUD_STATUSES, } from '../../utils/constants';
import QuestionsTemplateToolbar from '../TemplateQuestionsDialog/QuestionsTemplateToolbar';
import {
  selectFeatureStatus,
  selectCurrentTemplate,
  listQuestionsTemplates,
  setCurrentTemplate,
  selectNonGlobalQuestionsTemplates,
} from '../../features/questionsTemplatesSlice';
import { getUserId, } from '../../utils';
import QuestionsList from '../TemplateQuestionsDialog/QuestionsList';

export default function TemplateQuestionsPage({ open, closeModal, }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const status = useSelector(selectFeatureStatus);
  const templateId = useSelector(selectCurrentTemplate);
  const templates = useSelector(selectNonGlobalQuestionsTemplates);
  const loading = (status !== CRUD_STATUSES.FETCH_FAILED) && (status !== CRUD_STATUSES.FETCH_SUCCESSFUL);

  return (

    <Container
      maxWidth="xl"
      component={Paper}
      style={{
        padding: '1rem',
      }}
      elevation={0}
    >
      <p className={classes.title} >
        Templates de tarefas
      </p>

      <QuestionsTemplateToolbar />

      {
        loading || (null == templateId) || (templates.length === 0) ?
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            {loading ? <CircularProgress size={24} /> : null}

            <Typography
              color="textSecondary"
              variant="body1"
              style={{
                marginTop: '0.5rem',
              }}
            >
              {
                loading ? 'Carrengando templates de tarefas...' :
                  (templates.length === 0) ? 'Você ainda não possui templates de tarefas' :
                    (null == templateId) ? 'Selecione um relatório de impacto ou crie um novo' :
                      null
              }
            </Typography>
          </Grid> :
          <>
            <QuestionsList />
          </>
      }
    </Container>
  )
}

const useStyles = makeStyles({
  title: {
    color: '#4D5884',
    fontSize: '1.4rem',
    fontFamily: 'Inter',
    marginTop: '0.3rem',
    marginBottom: '0.8rem',
  },
});
