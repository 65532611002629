import {Button} from "react-bootstrap";
import {TextField} from "@mui/material";
import Dialog from "../Dialog";
import React, {useEffect} from "react";
import useFolderCreate from "../../hooks/useCases/Documents/useFolderCreate";
import {Grid} from "@material-ui/core";


export default function CreateFolderDialog({open, onClose, onConfirm}) {

  const { form, loading, send } = useFolderCreate((res) => {
    onConfirm(res);
    onClose();
  });

  useEffect(() => {
    if (open) {
      form.name.set("");
    }
  }, [open]);

  return <Dialog
    title="Nova Pasta"
    maxWidth="sm"
    open={open}
    onClose={onClose}
    actions={<><Button variant="contained" color="primary" loading={loading} onClick={send}>Cadastrar</Button></>}
  >
    <Grid sx={{mt: 2}}>
      <TextField label="Nome" value={form.name.value} onChange={(e) => form.name.set(e.target.value)}/>
    </Grid>
  </Dialog>
}
