import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Notification, } from 'rsuite';

import * as api from '../services/api';
import { CRUD_STATUSES, } from '../utils/constants';


const initialState = {
  questionsTemplates: [],
  currTemplateId: null,
  status: CRUD_STATUSES.IDLE,
};

export const listQuestionsTemplates = createAsyncThunk('questionsTemplates/fetch', async (payload) => {
  const { userId, } = payload;
  const { data, status, } = await api.listQuestionsTemplates(userId);
  if (200 === status) {
    return data.templates;
  } else {
    Notification['warning']({
      placement: 'bottomEnd',
      title: 'Falha ao carregar templates de tarefas!',
      description: 'Tente novamente dentro de alguns instantes.'
    });
  }
});

export const createQuestionsTemplate = createAsyncThunk('questionsTemplates/create', async (payload) => {
  const { userId, templateData, } = payload;
  const { data, } = await api.createQuestionsTemplate(userId, templateData);
  return data;
});

export const createQuestionsTemplateFromCompanyQuestions = createAsyncThunk('questionsTemplates/createFromCompanyQuestions',
  async (payload) => {
  const { companyId, userId, name, } = payload;
  const { data, } = await api.createQuestionsTemplateFromCompanyQuestions(userId, {
    companyId,
    name,
  });
  return data;
});

export const overwriteQuestionsTemplateFromCompanyQuestions = createAsyncThunk('questionsTemplates/overwriteWithCompanyQuestions',
  async (payload) => {
  const { companyId, userId, templateId, } = payload;
  const { data, } = await api.overwriteQuestionsTemplateWithCompanyQuestions(userId, templateId, {
    companyId,
  });
  return data;
});

export const updateQuestionsTemplate = createAsyncThunk('questionsTemplates/update', async (payload) => {
  const { userId, templateId, templateData, } = payload;
  const { data, } = await api.updateQuestionsTemplate(userId, templateId, templateData);
  return data;
});

export const deleteQuestionsTemplate = createAsyncThunk('questionsTemplates/delete', async (payload) => {
  const { userId, templateId } = payload;
  const { status, } = await api.deleteQuestionsTemplate(userId, templateId);

  return 200 === status ? templateId : null;
});

export const questionsTemplatesSlice = createSlice({
  name: 'questionsTemplates',
  initialState,
  reducers: {
    setStatus: (state, { payload: status, }) => {
      if (status != null) {
        state.status = status;
      } else {
        state.status = initialState.status;
      }
    },
    setCurrentTemplate(state, { payload: currTemplateId, }) {
      state.currTemplateId = currTemplateId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listQuestionsTemplates.pending, (state) => {
      state.status = CRUD_STATUSES.LOADING;
    }).addCase(listQuestionsTemplates.fulfilled, (state, { payload: questionsTemplates, }) => {
      state.status = CRUD_STATUSES.FETCH_SUCCESSFUL;
      state.questionsTemplates = questionsTemplates;
    }).addCase(listQuestionsTemplates.rejected, (state) => {
      state.status = CRUD_STATUSES.FETCH_FAILED;
      state.questionsTemplates = initialState.questionsTemplates;
    });

    builder.addCase(createQuestionsTemplate.fulfilled, (state, { payload: questionsTemplate, }) => {
      state.questionsTemplates.push(questionsTemplate);
      if (questionsTemplate.id != undefined) {
        state.currTemplateId = questionsTemplate.id;
      }
    });

    builder.addCase(createQuestionsTemplateFromCompanyQuestions.fulfilled, (state, { payload: questionsTemplate, }) => {
      state.questionsTemplates.push(questionsTemplate);
    });

    builder.addCase(overwriteQuestionsTemplateFromCompanyQuestions.fulfilled, (state, { payload: questionsTemplate, }) => {
      const index = state.questionsTemplates.findIndex(({ id, }) => id === questionsTemplate.id);
      if (index > -1) {
        state.questionsTemplates[index] = questionsTemplate;
      }
    });

    builder.addCase(updateQuestionsTemplate.fulfilled, (state, { payload: questionsTemplate, }) => {
      const index = state.questionsTemplates.findIndex(({ id, }) => id === questionsTemplate.id);
      if (index > -1) {
        state.questionsTemplates[index] = questionsTemplate;
      }
    });

    builder.addCase(deleteQuestionsTemplate.fulfilled, (state, { payload: templateId, }) => {
      const index = state.questionsTemplates.findIndex(({ id, }) => id === templateId);
      if (index > -1) {
        state.questionsTemplates.splice(index, 1);

        if (templateId === state.currTemplateId) {
          state.currTemplateId = null;
        }
      }
    });
  }
});

/**
 * Custom select to get status
 *
 * @param {object} state - questionsTemplates slice state
 *
 * @returns the feature status
 */
export const selectFeatureStatus = (state) => {
  return state.questionsTemplates.status;
}

/**
 * Custom select to get questions templates
 *
 * @param {object} state - store state
 *
 * @returns questions templates
 */
export const selectQuestionsTemplates = (state) => {
  return state.questionsTemplates.questionsTemplates;
}

/**
 * Custom select to get non global questions templates
 *
 * @param {object} state - store state
 *
 * @returns questions templates
 */
export const selectNonGlobalQuestionsTemplates = (state) => {
  return state.questionsTemplates.questionsTemplates.filter((item) => !item.isGlobal);
}

/**
 * Select current questions template
 *
 * @param {object} state - store state
 *
 * @returns current questions template
 */
export const selectCurrentTemplate = (state) => {
  return state.questionsTemplates.questionsTemplates.find((item) => {
    return item.id == state.questionsTemplates.currTemplateId;
  });
}

/**
 * Select current questions template ID
 *
 * @param {object} state - store state
 *
 * @returns current questions template ID
 */
export const selectCurrentTemplateId = (state) => {
  return state.questionsTemplates.currTemplateId;
}

export const { setStatus, setCurrentTemplate, } = questionsTemplatesSlice.actions;
export default questionsTemplatesSlice.reducer;
