import React, {useRef} from 'react';

import Dashboard from '../../components/Dashboard';

import { DashboardWidgetType, } from '../../core/entities/dashboard-widget';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Card, Menu} from "@material-ui/core";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import WidgetSelectionPanel from "../ImpactReport/ImpactReportEditor/v2/WidgetSelectionPanel";
import Dialog from "../../components/Dialog";
import {REQUIRED_WIDGET_TYPES, WIDGET_TYPE_ARRAY} from "../../entities/impact-report-widget";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SensitiveDataCategoryReportWidget from "../../components/Dashboard/Widget/SensitiveDataCategoryReportWidget";
import HeatMapWidget from "../../components/Dashboard/Widget/HeatMapWidget";
import LegalBasesReportWidget from "../../components/Dashboard/Widget/LegalBasesReportWidget";
import SensitiveDataTypeReportWidget from "../../components/Dashboard/Widget/SensitiveDataTypeReportWidget";
import PersonalDataTypeReportWidget from "../../components/Dashboard/Widget/SensitiveDataTypeReportWidget";
import CompanyObjectiveWidget from "../../components/Dashboard/Widget/CompanyObjectiveWidget";
import CompanyProgressWidget from "../../components/Dashboard/Widget/CompanyProgressWidget";
import RiskReportWidget from "../../components/Dashboard/Widget/RiskReportWidget";
import DataMappingReportWidget from "../../components/Dashboard/Widget/DataMappingReportWidget";
import PurposeReportWidget from "../../components/Dashboard/Widget/PurposeReportWidget";
import ProcessingTypeReportWidget from "../../components/Dashboard/Widget/ProcessingTypeReportWidget";
import CategoryMaturityLevelReportWidget from "../../components/Dashboard/Widget/CategoryMaturityLevelReportWidget";
import ActionPlanReportWidget from "../../components/Dashboard/Widget/ActionPlanReportWidget";
import DpoContactRequestReportWidget from "../../components/Dashboard/Widget/DpoContactRequestReportWidget";
import PrivacyCultureWidget from "../../components/Dashboard/Widget/PrivacyCultureWidget";
import useToggle from "../../hooks/useToggle";
import DashboardPdfDialog from "./DashboardPdfDialog";
import Monitoramento from "../Monitoramento";
import StatusPerSectorWidget from "../../components/Dashboard/Widget/StatusPerSectorWidget";

const layout = [
  {
    name: 'Objetivo da Companhia',
    type: DashboardWidgetType.COMPANY_OBJECTIVE,
    i: DashboardWidgetType.COMPANY_OBJECTIVE,
    component: CompanyObjectiveWidget,
    x: 0,
    y: 0,
    w: 12,
    h: 1.5,
  },
  {
    name: 'Progresso e aderência',
    type: DashboardWidgetType.COMPANY_PROGRESS,
    i: DashboardWidgetType.COMPANY_PROGRESS,
    component: CompanyProgressWidget,
    x: 0,
    y: 1.5,
    w: 6,
    h: 2,
  },
  {
    name: 'Relatório de Riscos',
    type: DashboardWidgetType.RISK_REPORT,
    i: DashboardWidgetType.RISK_REPORT,
    component: RiskReportWidget,
    x: 6,
    y: 1.5,
    w: 6,
    h: 2,
  },
  {
    name: 'Relatório de Mapeamento',
    type: DashboardWidgetType.DATA_MAPPING_REPORT,
    i: DashboardWidgetType.DATA_MAPPING_REPORT,
    component: DataMappingReportWidget,
    x: 0,
    y: 3.5,
    w: 12,
    h: 2.5,
  },
  {
    name: 'Relatório de Finalidade',
    type: DashboardWidgetType.PURPOSE_REPORT,
    i: DashboardWidgetType.PURPOSE_REPORT,
    component: PurposeReportWidget,
    x: 0,
    y: 6,
    w: 6,
    h: 2,
  },
  {
    name: 'Relatório de Tipo de Processamento',
    type: DashboardWidgetType.PROCESSING_TYPE_REPORT,
    i: DashboardWidgetType.PROCESSING_TYPE_REPORT,
    component: ProcessingTypeReportWidget,
    x: 0,
    y: 8,
    w: 6,
    h: 1,
  },
  {
    name: 'Relatório de Tipo de Dados Pessoais',
    type: DashboardWidgetType.PERSONAL_DATA_TYPE_REPORT,
    i: DashboardWidgetType.PERSONAL_DATA_TYPE_REPORT,
    component: PersonalDataTypeReportWidget,
    x: 6,
    y: 6,
    w: 6,
    h: 1.5,
  },
  {
    name: 'Relatório de Dados Sensíveis',
    type: DashboardWidgetType.SENSITIVE_DATA_REPORT,
    i: DashboardWidgetType.SENSITIVE_DATA_REPORT,
    component: SensitiveDataTypeReportWidget,
    x: 6,
    y: 6,
    w: 6,
    h: 1.5,
  },
  {
    name: 'Relatório de Bases Legais',
    type: DashboardWidgetType.LEGAL_BASES_REPORT,
    i: DashboardWidgetType.LEGAL_BASES_REPORT,
    component: LegalBasesReportWidget,
    x: 0,
    y: 9,
    w: 12,
    h: 4,
  },
  {
    name: 'Relatório de Categorias de Dados Sensíveis',
    type: DashboardWidgetType.SENSITIVE_DATA_CATEGORY_REPORT,
    i: DashboardWidgetType.SENSITIVE_DATA_CATEGORY_REPORT,
    component: SensitiveDataCategoryReportWidget,
    x: 0,
    y: 9,
    w: 12,
    h: 4,
  },
  {
    name: 'Mapa de Calor',
    type: DashboardWidgetType.HEAT_MAP,
    i: DashboardWidgetType.HEAT_MAP,
    component: HeatMapWidget,
    x: 0,
    y: 13,
    w: 6,
    h: 5,
  },
  {
    name: 'Maturidade',
    type: DashboardWidgetType.CATEGORY_MATURITY_LEVEL_REPORT,
    i: DashboardWidgetType.CATEGORY_MATURITY_LEVEL_REPORT,
    component: CategoryMaturityLevelReportWidget,
    x: 6,
    y: 13,
    w: 6,
    h: 5,
  },
  {
    name: 'Status Por Setor',
    type: DashboardWidgetType.PER_SECTOR_STATUS,
    i: DashboardWidgetType.PER_SECTOR_STATUS,
    component: StatusPerSectorWidget,
    x: 0,
    y: 17,
    w: 12,
    h: 5,
  },
  {
    name: 'Relatório de Plano de Ação',
    type: DashboardWidgetType.ACTION_PLAN_REPORT,
    i: DashboardWidgetType.ACTION_PLAN_REPORT,
    component: ActionPlanReportWidget,
    x: 0,
    y: 20,
    w: 12,
    h: 1.5,
  },
  {
    name: 'Relatório de Solicitações de Contato do DPO',
    type: DashboardWidgetType.DPO_CONTACT_REQUEST_REPORT,
    i: DashboardWidgetType.DPO_CONTACT_REQUEST_REPORT,
    component: DpoContactRequestReportWidget,
    x: 0,
    y: 21.5,
    w: 12,
    h: 1.5,
  },
  {
    name: 'Cultura de Privacidade',
    type: DashboardWidgetType.PRIVACY_CULTURE,
    i: DashboardWidgetType.PRIVACY_CULTURE,
    component: PrivacyCultureWidget,
    x: 0,
    y: 23,
    w: 12,
    h: 1.5,
  },
];


function MainDashboard({}) {

  return (
    <>
      <DashboardPdfDialog layout={layout}/>
      <Dashboard layout={layout}/>
    </>
  );
}

export default MainDashboard;
