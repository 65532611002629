import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    value: 0,
    valueRelatorio: 0,
  },
  reducers: {
    increment: (state) => {
      state.value -= 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    backToDefault: (state) => {
      state.value = 0
    },
    incrementByAmount: (state, action) => {
      state.value = action.payload
    },
    incrementByAmountRelatorio: (state, action) => {
      state.valueRelatorio = action.payload
    },
  },
})

export const {
  increment,
  decrement,
  incrementByAmount,
  backToDefault,
  incrementByAmountRelatorio,
} = counterSlice.actions

export default counterSlice.reducer
