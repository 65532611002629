import { createSlice, } from '@reduxjs/toolkit';


const initialState = {
  company: {},
};


export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompany: (state, { payload: company, }) => {
      if (company != null) {
        state.company = company;
      } else {
        state.company = initialState.company;
      }
    },
  },
});

/**
 * Custom select to select current company
 *
 * @param {object} state - course slice state
 *
 * @returns the selected course
 */
export const selectCompany = (state) => {
  return state.company.company;
}


export const { setCompany, } = companySlice.actions;
export default companySlice.reducer;
