import React from "react";
import { useThemeContext } from "../../../context/ThemeAppContext";
import { Button } from "@material-ui/core";

export default function TimeLineItem({ alingNumber, handleOpenModal }) {

    const { stylesLayout } = useThemeContext();

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "80%", marginTop: "-10%" }}>
            <h4 style={{ marginBottom: "10%" }}>
                <span style={{ color: stylesLayout.colors.textBlue }}>Passo a passo </span>
                <span style={{ fontWeight: "400" }}>para criar o seu primeiro projeto</span>
            </h4>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flex: "1", marginRight: "2px" }}>
                    <div style={{ width: "100%", position: "relative" }}>
                        <div style={{ border: '1px dashed #b2b6c9', width: "100%", position: "absolute", top: '20px', zIndex: 0 }}></div>
                        <div style={{ position: "relative", zIndex: 1, width: "100%", display: "flex", justifyContent: alingNumber || 'start' }}>
                            <div style={{ width: '40px', height: '40px', display: "flex", justifyContent: "center", alignItems: "center", background: stylesLayout.colors.secondaryColor, borderRadius: "25px" }}>
                                <p style={{ color: '#fff', fontSize: '1.4rem', fontWeight: "400" }}>1</p>
                                <p style={{ position: "absolute", whiteSpace: "nowrap", bottom: "-20px", fontWeight: "700", color: stylesLayout.colors.textPrimary }}>Crie uma empresa</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flex: "1", marginRight: "2px" }}>
                    <div style={{ width: "100%", position: "relative" }}>
                        <div style={{ border: '1px dashed #b2b6c9', width: "100%", position: "absolute", top: '20px', zIndex: 0 }}></div>
                        <div style={{ position: "relative", zIndex: 1, width: "100%", display: "flex", justifyContent: alingNumber || 'center' }}>
                            <div style={{ width: '40px', height: '40px', display: "flex", justifyContent: "center", alignItems: "center", background: stylesLayout.colors.secondaryColor, borderRadius: "25px" }}>
                                <p style={{ color: '#fff', fontSize: '1.4rem', fontWeight: "400" }}>2</p>
                                <p style={{ position: "absolute", bottom: "-40px", width: "200px", textAlign: "center", fontWeight: "700", color: stylesLayout.colors.textPrimary }}>Personalize o Diagnóstico e o Mapeamento</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flex: "1", marginRight: "2px" }}>
                    <div style={{ width: "100%", position: "relative" }}>
                        <div style={{ border: '1px dashed #b2b6c9', width: "100%", position: "absolute", top: '20px', zIndex: 0 }}></div>
                        <div style={{ position: "relative", zIndex: 1, width: "100%", display: "flex", justifyContent: alingNumber || 'end' }}>
                            <div style={{ width: '40px', height: '40px', display: "flex", justifyContent: "center", alignItems: "center", background: stylesLayout.colors.secondaryColor, borderRadius: "25px" }}>
                                <p style={{ color: '#fff', fontSize: '1.4rem', fontWeight: "400" }}>3</p>
                                <p style={{ position: "absolute", whiteSpace: "nowrap", bottom: "-20px", fontWeight: "700", color: stylesLayout.colors.textPrimary }}>Inicie o trabalho!</p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div style={{ display: "flex", flexDirection: "row", marginTop: "15%", marginBottom: "10vh", width: "100%", justifyContent:"center" }}>
                <Button variant="contained" color="primary" style={{padding:"8px 30px"}} onClick={handleOpenModal}>Criar empresa</Button>
            </div>
        </div>
    )
}