import React, { createContext } from 'react';

import Sheet from '../../components/Sheet';
import RespondMapping from './RespondMapping';
import DataMappingReport from './Relatorio';
import EditDataMappingWrapper from './Cadastro';
import Classification from '../Classificacao';
import LinkRespostas from '../../components/MapaDados/GeraLinkEsterno/GeraLinkEsterno';
import SheetContext from "./SheetContext";
import Organization from '../Organization/Organization';

const ModeColumn = () => <DataMappingReport mode='table' />;
const ModeList = () => <DataMappingReport mode='none'/>;


const PAGES = Object.freeze({
  respostas: {
    id: 'respostas',
    titleTab: "Link De Resposta",
    titlePage: "Link De Resposta",
    component: <LinkRespostas />,
  },
  classificacao: {
    id: 'classificacao',
    titleTab: "Classificação",
    titlePage: "Classificação",
    component: <Classification />,
  },
  editar: {
    id: 'editar',
    titleTab: "Editar",
    titlePage: "Editar",
    component: <EditDataMappingWrapper />,
  },
  relatorio: {
    id: 'relatorio',
    titleTab: "Relatório",
    titlePage: "Relatório",
    subMenu: {
      modeColumn: {
        id: 'modeColumn',
        titleTab: "Modo Coluna",
        component: <ModeColumn />,
      },
      modeList: {
        id: 'modeList',
        titleTab: "Modo Lista",
        component: <ModeList />,
      },
    }
  },
  responder: {
    id: 'responder',
    titleTab: "Responder",
    titlePage: "Responder",
    component: <RespondMapping />,
    subMenu: [
      {
        id: 0,
        titleTab: "Modo Coluna",
      },
      {
        id: 1,
        titleTab: "Modo Lista",
      },
    ],
  }
});

const ARRAY_PAGES = Object.values(PAGES);

export default function MapPageDiagnosis() {
  const [viewMode, setViewMode] = React.useState('table');

  function changeView(page) {
    if (page === 0) {
      return setViewMode('table');
    }
    if (page === 1) {
      return setViewMode('list');
    }
  }

  return (
    <SheetContext.Provider value={{ viewMode }}>
      <Sheet pages={ARRAY_PAGES} callbackSubMenu={changeView} />
    </SheetContext.Provider>
  );
}


