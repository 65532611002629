import React, { memo, useEffect, } from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';
import moment from 'moment';

import {
  Container,
  TextField,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Button,
  Typography,
} from '@material-ui/core';
import { useFormik, } from 'formik';
import * as Yup from 'yup';


const INITIAL_VALUES = Object.freeze({
  observation: '',
  answer: 0,
});

const InputProps = {
  disableUnderline: true,
};

const ANSWER_OPTIONS = [
  {
    key: 'NOT_IMPLEMENTED',
    value: 0,
  },
  {
    key: 'PARTIALLY_IMPLEMENTED',
    value: 1,
  },
  {
    key: 'COMPLETELY_IMPLEMENTED',
    value: 2,
  },
  {
    key: 'NOT_APPLICABLE',
    value: 3,
  },
];


function DueDiligenceRequestQuestionForm({ data, onSubmit, readOnly, agent = null }) {
  const { t, } = useTranslation();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: submitProcessingAgent,
    validationSchema: Yup.object({
      observation: Yup.string(),
      answer: Yup.number().required(_.capitalize(t('VALIDATION_MESSAGES.required', {
        context: 'female',
      }))),
    }),
  });

  async function submitProcessingAgent(values) {

    if (undefined != onSubmit) {
      onSubmit({
        observation: values.observation,
        answer: Number(values.answer),
      });
    }
  }

  useEffect(() => {
    if (null != data.id) {
      formik.setValues({
        answer: data.answer,
        observation: data.observation,
      });
    }
  }, [data]);


  return (
    <Container
      maxWidth="xl"
      style={{
        padding: '0.6rem',
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <FormControl
            component="fieldset"
            error={formik.errors.answer && formik.touched.answer}
            disabled={readOnly}
            fullWidth
          >
            <RadioGroup
              aria-label="answer"
              {...formik.getFieldProps('answer')}
              value={Number(formik.values.answer)}
            >
              {
                ANSWER_OPTIONS.map(({ value, key, }, index) => {
                  return (
                    <FormControlLabel
                      key={key}
                      value={value}
                      control={<Radio />}
                      label={_.capitalize(t(`DUE_DILIGENCE_QUESTION_ANSWERS.${key}`))}
                      style={{
                        color: '#1C265A',
                      }}
                    />
                  );
                })
              }
            </RadioGroup>

            <FormHelperText>
              {formik.touched.answer && formik.errors.answer}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
        >
          <Typography
            display="block"
            style={{
              color: '#1C265A',
            }}
          >
            {_.capitalize(t('observation', { count: 2, }))}
          </Typography>
          <TextField
            margin="dense"
            fullWidth
            variant="filled"
            multiline
            rows={5}
            disabled={readOnly}
            InputProps={InputProps}
            id="observation"
            type="text"
            error={formik.errors.observation && formik.touched.observation}
            helperText={formik.touched.observation && formik.errors.observation}
            {...formik.getFieldProps('observation')}
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
      >
        {((agent?.email && readOnly) && data?.updatedAt) &&
          <div
            style={{
              marginRight: '1rem',
              color: '#3B66FF',
              fontWeight: 'bold',
            }}
          >
            {"Respondido por " + agent?.email + " "}
            {data?.updatedAt &&
              "em " + moment(data?.updatedAt).format('DD/MM/YY HH:mm')
            }
          </div>
        }
      </Grid>

      {
        !readOnly ?
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            {(agent?.email && data?.updatedAt) &&
              <div
                style={{
                  marginRight: '1rem',
                  color: '#3B66FF',
                  fontWeight: 'bold',
                }}
              >
                {"Respondido por " + agent?.email + " "}
                {data?.updatedAt &&
                  "em " + moment(data?.updatedAt).format('DD/MM/YY HH:mm')
                }
              </div>
            }
            <Button
              color="primary"
              onClick={formik.handleSubmit}
              variant="contained"
              disableElevation
            >
              {_.capitalize(t('save'))}
            </Button>
          </Grid> :
          null
      }
    </Container>
  );
}

export default memo(DueDiligenceRequestQuestionForm);
