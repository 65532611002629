import { useState, useEffect, } from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import { Toggle, Icon, } from 'rsuite';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Snackbar,
  Container,
  Paper,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

//TODO: use the MappingField component instead of its inner fields/components
import RiskField from '../DataMappingAnswerPanel/Field/RiskField';
import SensitiveDataCategoriesField from '../DataMappingAnswerPanel/Field/SensitiveDataCategoriesField';
import ProcessingTypeField from '../DataMappingAnswerPanel/Field/ProcessingTypeField';
import DataManagementRoleField from '../DataMappingAnswerPanel/Field/DataManagementRoleField';
import PersonalDataTypeField from '../DataMappingAnswerPanel/Field/PersonalDataTypeField';
import SensitiveDataOptionsField from '../DataMappingAnswerPanel/Field/SensitiveDataOptionField';

import styles from './styles.module.scss';

import { MAPPING_FIELD_TYPES, MAPPING_FIELD_TYPES_ARRAY, } from '../../data/constants';
import { getFieldType, } from '../../core/entities/data-mapping-field';


function FormFieldContainer({ label, children, }) {
  return (
    <Container
      elevation={0}
      disableGutters
      component={Paper}
      style={{
        backgroundColor: '#F2F4F8',
        marginBottom: '0.75rem',
        padding: '0.3rem',
      }}
    >
      <DialogContentText
        className={styles.HeaderModal}
        id="alert-dialog-description"
        style={{
          margin: 0,
          fontWeight: 500,
        }}
        variant="caption"
      >
        { label }
      </DialogContentText>

      { children }
    </Container>
  );
}


function FormField({ label, placeholder, onChange, value, }) {
  return (
    <FormFieldContainer label={label} >
      <input
        className={styles.inputField}
        onChange={(e) =>  onChange(e.target.value)}
        value={value}
        type="text"
        placeholder={placeholder}
      />
    </FormFieldContainer>
  );
}

/**
 * Modal used to create a new mapping field
 *
 * @param {boolean} show - indicates whether the modal should be visible or not
 * @param {object} fieldData - object representing the field being created/edited
 * @param {function} setFieldData - callback to set the field data
 * @param {function} post - callback used to create the field
 * @param {function} close - callback called when the modal is closed
 *
 * @returns Rendered modal
 */
export default function FieldFormModal({ show, post, close, fieldData, setFieldData, }) {
  const { t, } = useTranslation();
  const [model, setModel] = useState({});
  const [nameError, setNameError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [showTypeChangeWarning, setShowTypeChangeWarning] = useState(false);
  const [initialType, setInitialType] = useState(undefined);
  const [typesStatuses, setTypesStatuses] = useState(_.mapValues(MAPPING_FIELD_TYPES, (item) => {
    return item.value === MAPPING_FIELD_TYPES.TEXT.value;
  }));

  /**
   * Handler change in and item in the options array. If index in the options range,
   * the option at the index position is updated, otherwise a new item is pushed
   *
   * @param {number} index - option item index
   * @param {string} newValue - option value
   */
  const onOptionChange = (index = -1, newValue = '') => {
    const updatedModel = _.cloneDeep(model);

    if (!Array.isArray(updatedModel.options)) {
      updatedModel.options = [];
    }

    if ((index >= 0) && (index < updatedModel.options.length)) {
      updatedModel.options[index].title = newValue;
    } else {
      updatedModel.options.push({
        title: newValue,
      });
    }

    setModel(updatedModel);
  }

  /**
   * Handler to update field name
   *
   * @param {string} newValue - new field name
   */
  const onNameChange = (newValue) => {
    const updatedModel = _.cloneDeep(model);
    updatedModel.name = newValue;
    setModel(updatedModel);
  }

  /**
   * Handler to update field description
   *
   * @param {string} newValue - new field description
   */
  const onDescriptionChange = (newValue) => {
    const updatedModel = _.cloneDeep(model);
    updatedModel.description = newValue;
    setModel(updatedModel);
  }

  /**
   * Toggle the selection state of a type
   *
   * @param {string} fieldType - field type to change. Values must the same as the keys of
   * MAPPING_FIELD_TYPES
   *
   * @returns type object
   */
  const updateFieldType = (fieldType) => {
    const currState = typesStatuses[fieldType];
    const updatedTypesStatuses = _.mapValues(typesStatuses, () => false);
    updatedTypesStatuses[fieldType] = !currState;
    setTypesStatuses(updatedTypesStatuses);

    return updatedTypesStatuses[fieldType] ? MAPPING_FIELD_TYPES[fieldType] : null;
  }

  /**
   * Toggle the selection state of a type
   *
   * @param {string} fieldType - field type to change. Values must the same as the keys of
   * MAPPING_FIELD_TYPES
   */
  function toggleFieldType(fieldType) {
    const updatedModel = _.cloneDeep(model);
    const type = updateFieldType(fieldType);
    if (type != null) {
      const { asType, inputType, typePre, } = type;
      updatedModel.asType = asType;
      updatedModel.inputType = inputType;
      updatedModel.typePre = typePre;
    }
    setShowTypeChangeWarning(updatedModel.id != undefined && fieldType !== initialType);
    setModel(updatedModel);
  }

  /**
   * Use effect used to initialize the asType property
   */
  useEffect(() => {
    if (show) {
      setModel(fieldData);

      let typeToToggle = MAPPING_FIELD_TYPES.TEXT.value;
      if ((fieldData.id != undefined)) {
        const type = getFieldType(fieldData.asType, fieldData.typePre, fieldData.inputType);
        if (type != undefined) {
          typeToToggle = type.value;
        }
      }
      updateFieldType(typeToToggle);
      setInitialType(typeToToggle);
    } else {
      setNameError('');
      setTypeError('');
      setTypesStatuses(_.mapValues(typesStatuses, () => false));
      setShowTypeChangeWarning(false);
    }
  }, [show]);

  /**
   * Use effect to update parent fieldData
   */
  useEffect(() => {
    if (show) {
      setFieldData(model);
    }
  }, [model]);

  /**
   * Handle click in save button
   */
  const __onSave = () => {
    let isValid = true;

    if (model.name.length === 0) {
      isValid = false;
      setNameError('Campo obrigatório');
    } else {
      setNameError('');
    }

    if (!Object.values(typesStatuses).reduce((prev, curr) => prev || curr, false)) {
      isValid = false;
      setTypeError('Selecione um tipo para o campo');
    } else {
      setTypeError('');
    }

    if (isValid) {
      post();
    }
  }


  return (
    <Container disableGutters >
      <Dialog
        open={show}
        onClose={close}
        maxWidth="md"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: '550px',
            maxWidth: '95%',
          },
        }}
      >
        <DialogTitle
          className={styles.boxTitle}
          id="alert-dialog-title"
        >
          Adicionar novo campo
          <Icon onClick={close} icon="close" />
        </DialogTitle>

        <DialogContent>
          <Typography
            variant="caption"
            align="right"
            color="error"
            display="block"
          >
            *Obrigatório
          </Typography>

          <FormField
            value={model.name}
            onChange={onNameChange}
            label="1. Como você deseja chamar este campo?*"
            placeholder="Existem dados sensíveis? Realiza Transferência Internacional?"
          />

          {nameError.length > 0 ?
            <Typography
              variant="caption"
              color="error"
              display="block"
            >
              {nameError}
            </Typography> :
            null
          }

          <FormField
            value={model.description}
            onChange={onDescriptionChange}
            label="2. Qual a descrição do campo?"
            placeholder="Descrição do campo acima que ajuda a explicá-lo melhor"
          />

          <FormFieldContainer label="3. Escolha o tipo do campo*" >
            {typeError.length > 0 ?
              <Typography
                variant="caption"
                color="error"
              >
                {typeError}
              </Typography> :
              null
            }

            <div className={styles.boxConstructorAll}>
              <div className={styles.boxSelectOptions}>
                {
                  MAPPING_FIELD_TYPES_ARRAY.map((type) => {
                    return (
                      <button
                        key={type.value}
                        onClick={() => toggleFieldType(type.value)}
                        className={!typesStatuses[type.value] ? styles.option : styles.optionSelect}
                      >
                        {t(`MAPPING_FIELD_TYPES.${type.value}`) }
                      </button>
                    );
                  })
                }
              </div>

              <div className={styles.boxExample}>
                <span>
                  Veja como vai ficar
                </span>

                <div className={styles.ExampleForm}>
                  <div className={styles.ExampleTitle}>
                    {(model.name != undefined) && (model.name.length > 0) ? model.name : 'Nome do Campo'}
                  </div>

                  {
                    typesStatuses[MAPPING_FIELD_TYPES.CHECKBOX.value] ?
                    <div className={styles.ExampleLineCenter}>
                      <Toggle
                        size="md"
                        checkedChildren="Sim"
                        unCheckedChildren="Não"
                      />
                    </div> :
                    (typesStatuses[MAPPING_FIELD_TYPES.PURPOSE.value] ||
                      typesStatuses[MAPPING_FIELD_TYPES.PRESET.value]) ?
                    <>
                      {
                        Array.isArray(model.options) ? model.options.map((option, index) => (
                          <div
                            className={styles.ExampleLine}
                            key={index}
                          >
                            <input
                              className={styles.inputTextOptions}
                              onChange={(e) => onOptionChange(index, e.target.value)}
                              type="text"
                              placeholder={option.title}
                            />
                          </div>
                        )) :
                        null
                      }
                      <div
                        onClick={onOptionChange}
                        className={styles.ExampleLineCenterPlus}
                      >
                        <Icon icon="plus" />
                        Adicionar nova opção
                      </div>
                    </> :
                    (typesStatuses[MAPPING_FIELD_TYPES.SECTOR.value] ||
                      typesStatuses[MAPPING_FIELD_TYPES.LEGAL_BASES.value] ||
                      typesStatuses[MAPPING_FIELD_TYPES.PROCESSING_AGENTS.value] ||
                      typesStatuses[MAPPING_FIELD_TYPES.RIPD.value] ||
                      typesStatuses[MAPPING_FIELD_TYPES.COLLABORATORS.value]) ?
                    <div className={styles.ExampleLine}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          flexGrow: 1,
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </div>
                    </div> :
                    (typesStatuses[MAPPING_FIELD_TYPES.RISK.value]) ?
                    <RiskField previewOnly /> :
                    (typesStatuses[MAPPING_FIELD_TYPES.SENSITIVE_DATA_CATEGORY.value]) ?
                    <SensitiveDataCategoriesField
                      field={{
                        options: [
                          {
                            id: '1',
                            title: 'Consentimento',
                          },
                          {
                            id: '2',
                            title: 'Cumprimento de obrigação legal ou regulatória',
                          },
                        ],
                      }}
                      previewOnly
                    /> :
                    (typesStatuses[MAPPING_FIELD_TYPES.SENSITIVE_DATA_OPTION.value]) ?
                    <SensitiveDataOptionsField
                      field={{
                        options: [
                          {
                            id: '1',
                            title: 'Origem racial ou étnica',
                          },
                          {
                            id: '2',
                            title: 'Convicção religiosa',
                          },
                        ],
                      }}
                      previewOnly
                    /> :
                    (typesStatuses[MAPPING_FIELD_TYPES.PROCESSING_TYPE.value]) ?
                    <ProcessingTypeField
                      field={{
                        options: [
                          {
                            id: 1,
                            title: 'Coleta',
                          },
                          {
                            id: 2,
                            title: 'Produção',
                          },
                          {
                            id: 3,
                            title: 'Recepção',
                          },
                        ],
                      }}
                      previewOnly
                    /> :
                    (typesStatuses[MAPPING_FIELD_TYPES.DATA_MANAGEMENT_ROLE.value]) ?
                    <DataManagementRoleField
                      field={{
                        options: [
                          {
                            id: 1,
                            title: 'Controlador',
                          },
                          {
                            id: 2,
                            title: 'Operador',
                          },
                        ],
                      }}
                      previewOnly
                    /> :
                    (typesStatuses[MAPPING_FIELD_TYPES.PERSONAL_DATA_TYPE.value]) ?
                    <PersonalDataTypeField
                      field={{
                        options: [],
                      }}
                      previewOnly
                    /> :
                    <div className={styles.ExampleLine}>
                      Texto
                    </div>
                  }
                </div>
              </div>
            </div>
          </FormFieldContainer>
        </DialogContent>

        <DialogActions>
          <button
            className={styles.BTNCancel}
            onClick={close}
          >
            Cancelar
          </button>

          <button
            onClick={__onSave}
            className={styles.BTNSave}
          >
            Salvar
          </button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        color="warning"
        open={showTypeChangeWarning}
      >
      <Alert
        severity="warning"
        onClose={() => setShowTypeChangeWarning(false)}
      >
        Trocar o tipo do campo fará com que todas as respostas sejam invalidadas
      </Alert>
    </Snackbar>
    </Container>
  )
}
