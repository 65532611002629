import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, } from 'react-redux';
import _ from 'lodash';

import { Loader } from 'rsuite';
import { setDocs } from '../../features/docsSlice';

import BreadCrumbs from '../../components/Global/BreadCrumbs';
import Title from '../../components/Global/PageTitle';
import Header from '../../components/MonitoramentoComponents/Header';
import AcoesAndamento from '../../components/MonitoramentoComponents/AcoesAndamento';
import Charts from '../../components/MonitoramentoComponents/Charts';

import {
  getDocs,
  getPlano,
  listCompanyMeetings,
  getUsers,
  getHistoricoObservacoes,
  getQuestionsCategoriesReport,
} from '../../services/api';

import { selectCompany, } from '../../features/companySlice';


function Monitoring() {
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const [andamento, setAndamento] = useState([]);
  const [users, setUsers] = useState([]);
  const [observacao, setObservacao] = useState([]);
  const [reunionUnique, setReunionUnique] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoriesReport, setCategoriesReport] = useState([]);


  async function getData() {
    setLoading(true);

    try {
      await Promise.all([
        getDocs().then(({ data }) => {
          dispatch(setDocs(data));
        }),
        getPlano(company._id).then(({ data }) => {
          const { andamento, } = data;
          setAndamento(andamento);
        }),
        listCompanyMeetings(company._id).then(({ data }) => {
          setReunionUnique(data);
        }),
        getUsers().then(({ data }) => {
          setUsers(data);
        }),
        getHistoricoObservacoes().then(({ data }) => {
          setObservacao(data);
        }),
      ]);
    } catch (err) {
      //? Drop error
    }

    setLoading(false);
  }

  async function getCategoriesReport() {
    try {
      const { data, } = await getQuestionsCategoriesReport(company._id);
      setCategoriesReport(data);
    } catch {
      //TODO: handle error
    }
  }

  /**
   * Use effect to initialize component data
   */
  useEffect(() => {
    getData();
    getCategoriesReport();
  }, []);


  return (
    <>
      {
        loading ?
        <Loader
          backdrop
          vertical
          content="Carregando"
        /> :
        <Header />
      }

      <AcoesAndamento
        users={users}
        reunionUnique={reunionUnique}
        observacao={observacao}
        body={andamento}
      />
      <Charts categoriesReport={categoriesReport} />
    </>
  )
}

export default Monitoring;
