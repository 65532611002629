import _ from 'lodash';
import React from 'react';

import DataMappingAnswerPanel from '../DataMappingAnswerPanel';
import DataMappingAnswerPanelContext from '../DataMappingAnswerPanel/context';

import useBaseDataMappingPanel from '../../hooks/useBaseDataMappingPanel';


export default function HeatMapDataMappingPanel({ items = [], }) {
  const base = useBaseDataMappingPanel();


  return (
    <DataMappingAnswerPanelContext.Provider
      value={{
        ...base,
        answers: base.answers.filter((a) => items.includes(a.uid)),
        allowCreation: false,
      }}
    >
      < DataMappingAnswerPanel />
    </DataMappingAnswerPanelContext.Provider>
  );
}
