import React, { useEffect, useState, memo, } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckedIcon from '@material-ui/icons/CheckCircleOutline';
import UncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import Table from '../../components/Table';
import SearchField from '../../components/SearchField';
import DeleteDialog from '../DeleteDialog';
import { normalizeString } from '../../utils';
import { deleteGroup, changeGroupActiveState, updateGroup, } from '../../features/groupsSlice';
import EditGroupModal from '../EditGroupModal';


const DEFAULT_GROUP = Object.freeze({
  id: undefined,
  name: '',
});

const HEADERS = Object.freeze([
  {
    label: 'Nome',
    align: 'center',
  },
  {
    label: 'Criado em',
    align: 'center',
  },
  {
    label: 'Adequações ativas',
    align: 'center',
  },
  {
    label: 'Consultores ativos',
    align: 'center',
  },
  {
    label: 'Configurações',
    align: 'center',
  },
  {
    label: 'Ativo',
    align: 'center',
  },
  {
    label: 'Deletar',
    align: 'center',
  },
]);


/**
 * Pane used to show company data in the admin console
 *
 * @returns Rendered pane
 */
function GroupsTable() {
  const { groups, } = useSelector(state => state.groups);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [currGroup, setCurrGroup] = useState(DEFAULT_GROUP);
  const [openConfigModal, setOpenConfigModal] = useState(false);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);


  /**
   * Toggle group active status in API
   *
   * @param {object} group - group to toggle active state
   */
  const toggleGroupActiveState = (group) => {
    dispatch(changeGroupActiveState({
      groupId: group.id,
      active: !group.active,
    }));
  }

  /**
   * Set states to open config modal
   *
   * @param {object} group - group to load
   */
  const onOpenConfigModal = (group) => {
    setCurrGroup(group);
    setOpenConfigModal(true);
  }

  /**
   * Close config modal and clears current group state
   */
  const onCloseConfigModal = () => {
    setCurrGroup(DEFAULT_GROUP);
    setOpenConfigModal(false);
  }

  /**
   * Send request to API to update group
   *
   * @param {object} group - object with updated data
   */
  const onUpdateGroup = async (group) => {
    dispatch(updateGroup(group));
    onCloseConfigModal();
  }

  /**
   * Set states to open modal to delete group
   *
   * @param {object} group - group to delete
   */
  const onDeleteGroup = (group) => {
    setCurrGroup(group);
    setDeleteConfirmationDialog(true);
  }

  /**
   * Close delete confirmation modal and clears current group state
   */
  const clearDeleteConfirmationDialog = () => {
    setCurrGroup(DEFAULT_GROUP);
    setDeleteConfirmationDialog(false);
  }

  /**
   * Send request to API to delete group
   */
  const __deleteGroup = async () => {
    dispatch(deleteGroup(currGroup.id));
    clearDeleteConfirmationDialog();
  }

  /**
   * Search groups using the current search string
   *
   * @param {string} searchString - search string
   */
  async function searchGroups(searchString = '') {
    setSearchString(searchString);
    setLoading(true);

    if (searchString.length > 0) {
      const normalizedSearchString = normalizeString(searchString);
      setFilteredGroups(groups.filter((group) => {
        const normalizedName = normalizeString(group.name);
        return normalizedName.includes(normalizedSearchString);
      }));
    } else {
      setFilteredGroups(groups);
    }

    setLoading(false);
  }

  useEffect(() => {
    searchGroups(searchString);
  }, [groups]);


  return (
    <Container
      maxWidth="xl"
      component={Paper}
    >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        paddingBottom: 15,
        paddingTop: 15,
      }}
    >
      <SearchField onChange={searchGroups} />
    </div>

      <Table
        headers={HEADERS}
        data={filteredGroups}
        loading={loading}
        errorMessage="Falha ao carregar grupos"
        noDataMessage="Nenhum grupo encontrado"
      >
        {
          (group, props) => {
            return (
              <TableRow {...props}>
                <TableCell align="center">
                  { group.name }
                </TableCell>
                <TableCell align="center">
                  { moment(group.created_at).format('DD/MM/YYYY, HH:MM:ss') }
                </TableCell>
                <TableCell align="center">
                  { 12 }
                </TableCell>
                <TableCell align="center">
                  { 2 }
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => onOpenConfigModal(group)}
                  >
                    <SettingsIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => toggleGroupActiveState(group)}
                  >
                    { group.active ?  <CheckedIcon /> : <UncheckedIcon /> }
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => onDeleteGroup(group)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          }
        }
      </Table>

      <EditGroupModal
        open={openConfigModal}
        onClose={onCloseConfigModal}
        onSave={onUpdateGroup}
        data={currGroup}
      />
      <DeleteDialog
        open={deleteConfirmationDialog}
        confirmationMessage={`Tem certeza que deseja excluir o grupo ${currGroup.name}?`}
        onClose={clearDeleteConfirmationDialog}
        onConfirm={__deleteGroup}
      />
    </Container>
  );
}

export default memo(GroupsTable);
