
import React from 'react';

import { Avatar, Grid, } from '@material-ui/core';


export default function Score({ grade, }) {
  let color = undefined;
  if (grade != null) {
    if (grade >= 8) {
      color = '#4CAF50';
    } else if (grade > 5) {
      color = '#FF6600';
    } else {
      color = '#F44336';
    }
  }


  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
    >
      <Avatar
        style={{
          fontSize: '0.75rem',
          backgroundColor: color,
          fontWeight: 'bold',
        }}
      >
        { grade != null ? `${Math.round(100*Number(grade)/10).toFixed(0)} %` : '-' }
      </Avatar>
    </Grid>
  );
}
