import { useState, } from 'react';

import { Form, } from 'react-bootstrap';
import { Button, Whisper, Tooltip, } from 'rsuite';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Container,
} from '@material-ui/core';


export default function DataMappingPhaseFormDialog({ open, onSubmit, onCancel, }) {
  const [name, setName] = useState('');


  function _onSubmit() {
    onSubmit({
      name,
    });
  }


  return (
    <Container disableGutters >
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Cadastrar uma nova aba?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Como deseja chamar essa aba?
          </DialogContentText>
          <Whisper
            trigger="focus"
            speaker={(
              <Tooltip>
                Required
              </Tooltip>
            )}
          >
            <Form.Control
              style={{ width: 300 }}
              placeholder="Setor RH, Currículo, Processo..."
              onChange={(e) => setName(e.target.value)}
            />
          </Whisper>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onCancel}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <Button
            onClick={_onSubmit}
            color="primary"
            autoFocus
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
