import { useContext, useEffect, useState, } from 'react';
import { Redirect, Route, Switch, useRouteMatch, useHistory, } from 'react-router-dom';

import { Context, } from '../../context/AuthContext';

import Dashboard from '../DashboardAdmin';
import DashboardMaster from '../DashboardMaster/ConsoleMasterPage';
import TodosUsuarios from '../Usuarios/ShowUsers';
import PagesConfigAdmin from '../PagesConfigAdmin';

import { getUserCompanyId, getUserSession } from '../../utils';
import AppBar from '../../components/CustomAppBar/V2/Admin/AppBarAdmin';
import api from '../../services/api';


function ConsultantArea() {
  const history = useHistory();
  const match = useRouteMatch();
  const { adminauth, loading, } = useContext(Context);

  if (!loading && !adminauth) {
    history.push(`/companies/${getUserCompanyId()}`);
  }

  const [userIsMaster, setUserIsMaster] = useState(false);


  useEffect( () => {
    api.get('/user-in-master-group').then(res => {
      const { isInMasterGroup, } = res.data;
      setUserIsMaster(isInMasterGroup);
    });
  }, []);


  return (
    <>
      <AppBar isMaster={userIsMaster} />
      <Switch>
        <Route
          isPrivate
          exact
          path="/Usuarios/Todos"
          component={TodosUsuarios}
        />

        <Route
          isPrivate
          exact
          path="/Usuarios/Config"
          component={PagesConfigAdmin}
        />

        <Route
          exact
          component={Dashboard}
          path="/Dashboard"
        />

        <Route
          exact
          component={DashboardMaster}
          path="/DashboardMaster"
        />

        <Route path={match.path}>
          <Redirect
            exact
            to="/Dashboard"
          />
        </Route>
      </Switch>
    </>
  );
}

export default ConsultantArea;
