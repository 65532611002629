import { useEffect, useState } from 'react';
import moment from 'moment';

import { Dialog, DialogTitle, Paper, Typography, } from '@material-ui/core';
import {
  Container,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Table,
  Form,
  Col,
  Row,
  InputGroup,
} from 'react-bootstrap';
import { Button, Icon, Notification } from 'rsuite';
import MuiAlert from '@material-ui/lab/Alert';

import api, {
  deleteCompanyPartner,
  addCompanyPartner,
  listAllCompanyPartners,
  listConsultants,
} from '../../services/api';

import DeleteDialog from '../DeleteDialog';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const INITIAL_DELETED_PARTNER = Object.freeze({
  //? Partner relationship ID
  id: undefined,
  //? User ID
  user: undefined,
  //? Company ID
  _id: undefined,
});


export default function CompanyModal({ open, onClose, infoUsers: company, }) {
  const [hist, setHist] = useState([]);
  const [companyPartnerships, setCompanyPartnerships] = useState([]);
  const [newName, setNewName] = useState(company.name);
  const [newEmail, setNewEmail] = useState(company.email);
  const [addUser, setAddUser] = useState(false)
  const [searchResult, setSearchResult] = useState([])
  const [search, setSearch] = useState('')
  const [addUserOk, setAddUserOk] = useState(false);
  const [editName, setEditName] = useState(false)
  const [editEmail, setEditEmail] = useState(false)
  const [isInc, setIsInc] = useState(company.owner);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
  const [deletedPartner, setDeletedPartner] = useState(INITIAL_DELETED_PARTNER);


  useEffect(() => {
    if (addUserOk) {
      const timeout = setTimeout(() => {
        setAddUserOk(false);
        setAddUser(false);
      }, 5000);

      return function cleanup() {
        clearTimeout(timeout);
      };
    }
  }, [addUserOk]);

  /**
   * Fetches login history and company partners from the API
   */
  async function getData() {
    try {
      const promises = [
        api.get(`/hist/byuser?id=${company._id}`),
        listAllCompanyPartners(company._id)
      ];
      const [histResponse, partnersResponse] = await Promise.all(promises);
      setHist(histResponse.data);
      setCompanyPartnerships(partnersResponse.data);
    } catch {
      return Notification['error']({
        placement: 'bottomEnd',
        title: 'Erro!',
        description: 'Não foi possível obter as informações da empresa.',
      });
    }
  }

  async function searchUser() {
    setSearchResult([]);
    try {
      const { data, } = await listConsultants({
        email: search,
      });
      setSearchResult(data);
    } catch {
      console.log('Failed to search user');
    }
  }

  /**
   * Creates a new partner for the company
   */
  const handleAddPartner = async () => {
    try {
      await addCompanyPartner(company._id, {
        userId: searchResult[0]._id,
      });
      getData();
      setAddUserOk(true);
    } catch {
      return Notification['error']({
        placement: 'bottomEnd',
        title: 'Erro!',
        description:
          'Erro ao adicionar esse usuario a empresa',
      });
    }
  }


  async function onSave() {
    if (!isInc) {
      await api
        .post('/user/update', {
          id: company._id,
          name: newName,
          email: newEmail,
        })
        .then(() => {
          return Notification['success']({
            placement: 'bottomEnd',
            title: 'Tudo certo!',
            description: 'Usuario alterado com sucesso',
          });
        })
        .catch(() => {
          return Notification['error']({
            placement: 'bottomEnd',
            title: 'Atenção!',
            description: 'Erro ao alterar o usuario',
          });
        });
    } else {
      return Notification['error']({
        placement: 'bottomEnd',
        title: 'empresa!',
        description: 'Erro ao alterar o usuario',
      });
    }
  }

  useEffect(() => {
    if ((undefined != company) && (undefined != company._id)) {
      listAllCompanyPartners(company._id)
        .then(({ data }) => {
          setCompanyPartnerships(data);
        });
    }
  }, [addUserOk]);

  /**
   * Callback for the delete partner button
   *
   * @param {object} partner - object representing the relationship between the company and the
   * partner
   */
  const onDeletePartner = (partner) => {
    setDeleteConfirmationDialog(true);
    setDeletedPartner(partner);
  }

  /**
   * Delete the partner identified by the deletedPartner state
   */
  const deletePartner = async () => {
    const companyId = deletedPartner.companyId;
    const partnerId = deletedPartner.id;

    try {
      await deleteCompanyPartner(companyId, partnerId);
      clearDeleteConfirmationDialog();
      setCompanyPartnerships(companyPartnerships.filter((item) => item.id != partnerId));

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Parceiro removido com sucesso!',
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao remover parceiro!',
      });
    }
  }

  /**
   * Close delete confirmation dialog and clears partner to be deleted data
   */
  const clearDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(false);
    setDeletedPartner(INITIAL_DELETED_PARTNER);
  }

  const dateFrontCreatedAt = company.createdAt != undefined ?
    moment(company.createdAt).format('DD/MM/YYYY') : '';

  /**
   * Use effect used to update the login history and partners in the modal
   */
  useEffect(() => {
    if ((company != undefined) && (company._id != undefined) && open) {
      getData();
    }
  }, [company, open]);


  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      className="ContainerModalConfig"
    >
      <DialogTitle className="TitleModalConfig">
        {'Configurações da empresa '}
        <Icon icon="close" onClick={onClose} />
      </DialogTitle>
      <Dialog
        open={addUser}
        onClose={() => setAddUser(false)}
        maxWidth="md"
        fullWidth
        className="ContainerModalConfig"
      >
        <DialogTitle className="TitleModalConfig">
          Adicionar um novo parceiro
          <Icon
            icon="close"
            onClick={() => setAddUser(false)}
          />
        </DialogTitle>
        <Container
          maxWidth="xl"
          component={Paper}
          disableGutters
        >
          {addUserOk &&
            <Alert
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
              severity="success"
            >
              Usuario Adicionado com sucesso!
            </Alert>}
          <br />
          <Row>
            <Col xs={6}>
              <Form.Label>
                Email
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="email"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                />
                <Button
                  variant="outline-secondary"
                  id="button-addon2"
                  onClick={searchUser}
                >
                  Pesquisar
                </Button>
              </InputGroup>
            </Col>
            <Col xs={6}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label value={searchResult}>
                  Usuarios encontrados
                </Form.Label>
                <Form.Control
                  as="select"
                  multiple
                >
                  {searchResult.map((user) => {
                    return (
                      <option
                        key={user._id}
                        onClick={handleAddPartner}
                      >
                        {user.name}
                      </option>
                    )
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </Dialog>

      <Container
        maxWidth="xl"
        component={Paper}
        disableGutters
      >
        <div className="boxAllTitle">
          <div className="boxAllName">
            <span onClick={() => setEditName(true)}>
              Empresa
              <Icon icon="edit" />
            </span>
            {editName ? (
              <Form.Control
                onChange={(e) => {
                  setNewName(e.target.value)
                }}
                value={newName}
                type="text"
              />
            ) : (
              <h5>{company.name}</h5>
            )}
          </div>
          <>
            <div className="boxHeaderRightIsInc">
              <div className="boxAllCreatedAt">
                <span>Criada em</span>

                <h5>{dateFrontCreatedAt}</h5>
              </div>
              <div className="boxAllEmail">
                <span onClick={() => setEditEmail(true)}>
                  Criada por <Icon icon="edit" />
                </span>
                {editEmail ? (
                  <Form.Control
                    onChange={(e) => {
                      setNewEmail(e.target.value)
                    }}
                    value={newEmail}
                    type="text"
                  />
                ) : (
                  <h5>{company.owner}</h5>
                )}
              </div>
            </div>
          </>
        </div>

        <Typography
          variant="h6"
          display="block"
          paragraph
        >
          Grupos parceiros
        </Typography>

        <table className="BoxParcerias">
          <thead className="headerParcerias">
            <th>Nome</th>
            <th>Adicionado em</th>
            <th>Deletar</th>
          </thead>

          <tbody
            style={{
              width: '100%',
            }}
          >
            {
              companyPartnerships.map((partnership, index) => {
                return (
                  <tr key={index+""} className="bodyParcerias" >
                    <td>
                      { partnership.groupName }
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      { moment(partnership.createdAt).format('DD/MM/YYYY HH:mm') }
                    </td>
                    <td
                      style={{
                        textAlign: 'right',
                      }}
                      onClick={() => onDeletePartner(partnership)}
                    >
                        <Icon
                          icon="trash"
                          style={{
                            cursor: 'pointer',
                          }}
                        />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>

        <Button
          appearance="ghost"
          onClick={() => setAddUser(true)}
          style={{
            marginTop: '3%',
            marginLeft: '2%',
          }}
        >
          Adicionar um novo parceiro
        </Button>

        <div className="boxAcessos">
          <div className="headerAcessos">
            <h5>Últimos acessos</h5>
            <div>
              <span>Filtrar por</span>
              <DropdownButton
                disabled
                as={ButtonGroup}
                title="Empresa"
                id="bg-vertical-dropdown-1"
              >
                <Dropdown.Item eventKey="1">
                  {company.group}
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>

          <div className="TableLogs">
            <Table
              responsive
              borderless
              striped
            >
              <thead>
                <th>Nome</th>
                <th>Data</th>
                <th>Navegador</th>
                <th>OS</th>
                <th>IP</th>
              </thead>
              {hist.map((item) => {
                return (
                  <tbody>
                    <tr>
                      <td>
                        <div className="infosBodyLogs">
                          {item.usuario}
                        </div>
                      </td>
                      <td>
                        <div className="infosBodyLogs">
                          {moment(item.data_login).format(
                            'DD/MM/YYYY'
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="infosBodyLogs">
                          {item.navegador}
                        </div>
                      </td>
                      <td>
                        <div className="infosBodyLogs">
                          {item.os}
                        </div>
                      </td>
                      <td>
                        <div className="infosBodyLogs">
                          {item.ip}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )
              })}
            </Table>
          </div>
        </div>
        <div className="box-BTN-botton-modal">
          <button
            onClick={onClose}
            className="BTN-Cancelar"
          >
            Cancelar
          </button>
          <button
            className="BTN-Salvar"
            onClick={onSave}
          >
            Salvar
          </button>
        </div>
      </Container>

      <DeleteDialog
        open={deleteConfirmationDialog}
        confirmationMessage="Tem certeza que deseja remover o parceiro?"
        onClose={clearDeleteConfirmationDialog}
        onConfirm={deletePartner}
      />
    </Dialog>
  );
}
