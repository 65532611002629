import React from "react";
import Pagination from '@material-ui/lab/Pagination';
import styled from 'styled-components'

const CustomPaginator = styled(Pagination)({
    ul: {
        flexWrap: 'nowrap'
    }
});

export default function Paginator({ page = 1, count, onChange, rowsPerPage = 5 }) {

    const totalPages = parseInt(Math.ceil(count / rowsPerPage), 10);

    return (
        <CustomPaginator page={page} count={totalPages} color="primary" onChange={onChange} />
    )

}