import React from 'react';

import Sheet from '../../components/Sheet';
import ActivityRegistry from './RegistroDPO';

const PAGES = Object.freeze({
    register: {
      id: 'register',
      titleTab: "Registro",
      titlePage: "Registro",
      component: <ActivityRegistry />,
    },
});

const ARRAY_PAGES = Object.values(PAGES);

export default function MapRegisterDpo() {

  return (
    <Sheet pages={ARRAY_PAGES} />
  );
}
