import React, { useEffect, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';
import fileDownload from 'js-file-download';
import { Link, } from 'react-router-dom';

import { Button, Notification, IconButton, Icon, } from 'rsuite';
import {Container, IconButton as MUIIconButton, Grid, MenuItem, Input, Select, FormControl,} from '@material-ui/core';
import QuestionIcon from '@mui/icons-material/HelpCenterOutlined';
import {makeStyles,} from '@material-ui/styles';
import { Row, Col, Form, Badge, Image, } from 'react-bootstrap';

import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';

import impactoIMG from '../../assets/impacto.jpeg';
import probabilidadeIMG from '../../assets/probabilidade.jpeg';
import matrizderiscoIMG from '../../assets/matrizderisco.jpeg';

import { setSave, updateAction, } from '../../features/matrizDeRisco';
import {
  selectFeatureStatus,
  selectImpactReports,
  listImpactReports,
} from '../../features/impactReportsSlice';
import { selectCompany } from '../../features/companySlice';

import api, {
  updateImpactOrProbability,
  updateMaturityQuestion,
  updateCompanyActionMeetings,
  downloadEvidence,
  addQuestionEvidence,
  updateCompanyActionImpactReports,
} from '../../services/api';

import { CRUD_STATUSES, RISK_RESPONSES, } from '../../utils/constants';
import * as Risk from '../../core/entities/risk';

import Tooltip from '../MapaDados/Tooltip';
import EvidenceButton from '../EvidenceButton';


const STEPS_NOTIFICATIONS = Object.freeze({
  probabilidade: {
    success: 'Probabilidade registrada com sucesso',
    error: 'Falha ao registrar probabilidade',
  },
  impacto: {
    success: 'Impacto registrado com sucesso',
    error: 'Falha ao registrar impacto',
  },
});


const useStylesHelpButton = makeStyles({
  tooltip: {
    maxWidth: 'none',
    backgroundColor: 'white',
    boxShadow: 'none',
    border: '1px solid grey',
    borderRadius: '1rem',
  },
});


function HelpButton({ imageSrc, }) {
  const classes = useStylesHelpButton();


  return (
    <Tooltip
      title={
        <Image
          src={imageSrc}
          alt="impacto"
          width={820}
        />
      }
      PopperProps={{
        modifiers: {
          offset: {
            enabled: true,
            offset: '0px, 0px',
          }
        }
      }}
      placement="top"
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      <QuestionIcon className="fill-color" fontSize="small"/>
    </Tooltip>
  );
}

const useStyles = makeStyles({
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  control: {
    border: '1px solid #CED4DA',
    borderRadius: '0.25rem',
    color: '#495057',
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: '400px',
    },
  },
};


function getStyles(id, selectedItems) {
  const selected = selectedItems.indexOf(id) > -1;
  return {
    backgroundColor: selected ? 'lightblue' : 'white',
    color: selected ? 'blue' : 'black',
  };
}


/**
 * Component used to edit the received action (data prop)
 *
 * @param {object} data - object representing the action to edit
 * @param {array} reunions - available company meetings
 *
 * @returns rendered component
 */
export default function ActionContainerShow({ data, reunions }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const { t, } = useTranslation();
  const [buttonClassname, setButtonClassname] = useState('blue');
  const [probabilidade, setProbabilidade] = useState(data.probabilidade);
  const [impacto, setImpacto] = useState(data.impacto);
  const [respRisco, setRespRisco] = useState(data.resposta);
  const [acao, setAcao] = useState(data.acao);
  const [risk, setRisk] = useState(data.risco);
  const [meetings, setMeetings] = useState(data.meetings);
  const [impactReports, setImpactReports] = useState(data.impactReports || []);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [statusLabel, setStatusLabel] = useState('Sem dados');
  const [actionData, setActionData] = useState(data);
  const availableImpactReports = useSelector(selectImpactReports);
  const impactReportsFeatureStatus = useSelector(selectFeatureStatus);

  const impactOptions = Object.freeze([
    'Escolha um valor',
    ...Risk.IMPACT_OPTIONS.map((item) => t(item.key)  ),
  ]);

  const probabilityOptions = Object.freeze([
    'Escolha um valor',
    ...Risk.PROBABILITY_OPTIONS.map((item) => t(item.key)),
  ]);


  useEffect(() => {
    setActionData({
      ...actionData,
      ...data,
    });
  }, [data]);

  useEffect(() => {
    if ((0 === availableImpactReports.length) && (CRUD_STATUSES.IDLE == impactReportsFeatureStatus)) {
      dispatch(listImpactReports());
    }
  }, [availableImpactReports, impactReportsFeatureStatus]);


  /**
   * Calculate action risk and update related states
   */
  const CalcRisco = () => {
    const riskValue = probabilidade * impacto;

    let actionStatus = 'info';
    let actionStatusLabel = 'Risco não definido';

    if (riskValue >= 1 && riskValue <= 4) {
      actionStatus = 'success';
      actionStatusLabel = 'Baixo';
    } else if (riskValue >= 5 && riskValue <= 14) {
      actionStatus = 'warning';
      actionStatusLabel = 'Médio';
    } else if (riskValue >= 15) {
      actionStatus = 'danger';
      actionStatusLabel = 'Alto';
    }

    setStatus(actionStatus);
    setStatusLabel(actionStatusLabel);
  }

  /**
   * Update meetings related to the current action
   *
   * @param {number} meetings - question meetings
   */
  const updateMeetings = async (meetings) => {
    try {
      setMeetings(meetings);
      await updateCompanyActionMeetings(company._id, data.id, meetings);

      const updatedAction = _.cloneDeep(data);
      updatedAction.meetings = meetings;
      dispatch(updateAction(updatedAction));

      Notification['success']({
        top: 70,
        placement: 'topEnd',
        title: 'Item adicionado a reunião com sucesso',
      });
    } catch {
      Notification['error']({
        top: 70,
        placement: 'topEnd',
        title: 'Erro ao anexar item',
      });
    }
  }

  /**
   * Update impact reports related to the current action
   *
   * @param {number[]} impactReports - question impact reports
   */
  const updateImpactReports = async (impactReports) => {
    try {
      setImpactReports(impactReports);
      await updateCompanyActionImpactReports(company._id, data.id, impactReports);

      const updatedAction = _.cloneDeep(data);
      updatedAction.impactReports = impactReports;
      dispatch(updateAction(updatedAction));

      Notification['success']({
        top: 70,
        placement: 'topEnd',
        title: 'Item adicionado ao relatório de impacto com sucesso',
      });
    } catch {
      Notification['error']({
        top: 70,
        placement: 'topEnd',
        title: 'Erro ao anexar item ao relatório de impacto',
      });
    }
  }

  /**
   * Handle click in save button
   */
  const onSave = async () => {
    setLoading(true);
    dispatch(setSave(false));

    try {
      await api.post('/updateanaliserisco', {
        id: data.id,
        resposta: respRisco,
      });

      setButtonClassname('warning')

      await updateMaturityQuestion(company._id, data.id, {
        id: data.id,
        ordem: 0,
        pergunta: data.problema,
        probabilidade: probabilidade,
        impacto: impacto,
        resposta: respRisco,
        acao: acao,
        cad_risco: 1,
        risco: risk,
      });

      Notification['success']({
        top: 70,
        placement: 'topEnd',
        title:
        'Análise de risco alterada com sucesso!',
      });

      const updatedAction = _.extend(_.cloneDeep(data), {
        ordem: 0,
        probabilidade: probabilidade,
        impacto: impacto,
        resposta: respRisco,
        acao: acao,
        cad_risco: 1,
      });
      dispatch(updateAction(updatedAction));
    } catch {
      Notification['error']({
        top: 70,
        placement: 'topEnd',
        title: 'Falha ao atualizar análise de risco!',
      });
    }

    setLoading(false);
  }

  /**
   * Update action impact/probability
   *
   * @param {string} step - action step
   * @param {number} value - impact/probability value
   */
  async function updateQuestionItens(step, value) {
    if ((step != 'probabilidade') && (step != 'impacto')) {
      return;
    }

    try {
      await updateImpactOrProbability(data.id, value, step);
      Notification['success']({
        top: 70,
        placement: 'topEnd',
        title: STEPS_NOTIFICATIONS[step].success,
      });

      const updatedAction = _.cloneDeep(data);
      updatedAction[step] = value;
      dispatch(updateAction(updatedAction));
    } catch {
      Notification['error']({
        top: 70,
        placement: 'topEnd',
        title: STEPS_NOTIFICATIONS[step].error,
      });
    }
  }

  async function onAttachEvidence(file) {
    try {
      const response = await addQuestionEvidence(company._id, actionData.id, file);
      setActionData({
        ...actionData,
        ...(response.data),
      });

      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.EVIDENCE_MANAGEMENT.evidence_successfully_attached')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.EVIDENCE_MANAGEMENT.failed_to_attach_evidence')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function onDownloadEvidence() {
    try {
      const response = await downloadEvidence(actionData.evidence_id);

      const headerLine = response.headers['content-disposition'];
      const startIdx = headerLine.indexOf('"') + 1;
      const endIdx = headerLine.lastIndexOf('"');
      const filename = headerLine.substring(startIdx, endIdx);

      fileDownload(response.data, filename);

      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.EVIDENCE_MANAGEMENT.evidence_successfully_downloaded')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.EVIDENCE_MANAGEMENT.failed_to_download_evidence')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  /**
   * Use effect to initialize component
   */
  useEffect(() => {
    CalcRisco();
  }, []);


  return (
    <Container
      maxWidth="xl"
      disableGutters
    >
      <label
        style={{
          display: 'flex',
          alignItems: 'center',
          color: '#3B66FF',
          fontSize: '25px',
          marginTop: 10,
        }}
      >
        Risco:
        <Badge
          pill
          size="lg"
          variant={status}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginLeft: '1rem',
            alignItems: 'center',
            marginRight: '1rem',
          }}
        >
          {statusLabel}
        </Badge>
        <HelpButton
          imageSrc={matrizderiscoIMG}
        />
      </label>

      <Row
        style={{
          marginBottom: 15,
          justifyContent: 'space-around',
        }}
      >
        <Col
          xs={12}
          md={6}
          lg={2}
          style={{
            marginTop: '0.5rem',
          }}
        >
          <Form.Label
            style={{
              marginRight: '1rem',
            }}
          >
            Probabilidade
          </Form.Label>
          <HelpButton
            imageSrc={probabilidadeIMG}
          />
          <Form.Control
            as="select"
            style={{ alignItems: 'center' }}
            value={probabilidade}
            defaultValue="Escolha um valor"
            onChange={(event) => {
              setProbabilidade(event.target.value)
              updateQuestionItens('probabilidade', event.target.value)
            }}
          >
            {
              probabilityOptions.map((item, index) => {
                return (
                  <option
                    key={index}
                    value={index}
                  >
                    { item }
                  </option>
                );
              })
            }
          </Form.Control>
        </Col>

        <Col
          xs={12}
          md={6}
          lg={2}
          style={{
            marginTop: '0.5rem',
          }}
        >
          <Form.Label style={{ marginRight: '5%' }}>
            Impacto
          </Form.Label>
          <HelpButton
            imageSrc={impactoIMG}
          />
          <Form.Control
            as="select"
            value={impacto}
            defaultValue="Escolha um valor"
            onChange={(event) => {
              setImpacto(event.target.value)
              updateQuestionItens('impacto', event.target.value)
            }}
          >
          {
            impactOptions.map((item, index) => {
              return (
                <option
                  key={index}
                  value={index}
                >
                  { item }
                </option>
              );
            })
          }
          </Form.Control>
        </Col>

        <Col
          xs={12}
          md={6}
          lg={2}
          style={{
            marginTop: '0.5rem',
          }}
        >
          <div>
            <Form.Label>Resposta </Form.Label>
            <Form.Control
              as="select"
              defaultValue="Escolha um valor..."
              value={respRisco}
              onChange={(event) => setRespRisco(event.target.value)}
            >
              <option value={null}>
                Escolha um valor...
              </option>
              {
                Object.values(RISK_RESPONSES).map((riskResponse) => (
                  <option>
                    { riskResponse }
                  </option>
                ))
              }
            </Form.Control>
          </div>
        </Col>

        <Col
          xs={12}
          md={6}
          lg={2}
          style={{
            marginTop: '0.5rem',
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid
              item
              xs={true}
            >
              <Form.Label>
                { _.capitalize(t('PAGES.ACTION_PLAN.attach_to_meeting')) }
              </Form.Label>
            </Grid>
            <Grid
              item
              xs="auto"
            >
              <Link to={`/companies/${company._id}/meetings`}>
                <MUIIconButton size="small">
                  <AddBoxOutlinedIcon color="primary"/>
                </MUIIconButton>
              </Link>
            </Grid>
          </Grid>

          <FormControl
            fullWidth
            className={classes.control}
          >
            <Select
              multiple
              classes={{
                root: classes.select,
              }}
              labelId="meetings-select-label"
              value={meetings}
              name="select"
              onChange={(ev) => updateMeetings(ev.target.value)}
              input={<Input />}
              disableUnderline
              MenuProps={MenuProps}
            >
              {
                reunions.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    style={getStyles(item.id, meetings)}
                  >
                    { item.title }
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Col>

        <Col
          xs={12}
          md={6}
          lg={2}
          style={{
            marginTop: '0.5rem',
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid
              item
              xs={true}
            >
              <Form.Label>
                Relatório de impacto
              </Form.Label>
            </Grid>
            <Grid
              item
              xs="auto"
            >
              <Link to={`/companies/${company._id}/dpo/impact-reports`}>
                <MUIIconButton size="small">
                  <AddBoxOutlinedIcon color="primary"/>
                </MUIIconButton>
              </Link>
            </Grid>
          </Grid>

          <FormControl
            fullWidth
            className={classes.control}
          >
            <Select
              multiple
              classes={{
                root: classes.select,
              }}
              labelId="impact-reports-select-label"
              value={impactReports}
              name="select"
              onChange={(ev) => updateImpactReports(ev.target.value)}
              input={<Input />}
              disableUnderline
              MenuProps={MenuProps}
            >
              {
                availableImpactReports.map((item) => {
                  return (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      style={getStyles(item.id, impactReports)}
                    >
                      { item.title }
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        </Col>
      </Row>

      <Row>
        <Col
          xs={12}
          sm={6}
        >
          <Form.Label>Risco</Form.Label>
          <Form.Control
            as="textarea"
            value={risk}
            onClick={() => dispatch(setSave(true))}
            rows={4}
            onChange={(event) => setRisk(event.target.value)}
          />
        </Col>
        <Col
          xs={12}
          sm={6}
        >
          <Form.Label>Ação</Form.Label>
          <Form.Control
            as="textarea"
            value={acao}
            onClick={() => dispatch(setSave(true))}
            rows={4}
            onChange={(event) => setAcao(event.target.value)}
          />
        </Col>
      </Row>

      <Row
        style={{
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <EvidenceButton
            evidenceId={actionData.evidence_id}
            onAttach={onAttachEvidence}
            onDownload={onDownloadEvidence}
          />
          <Button
            color={buttonClassname}
            loading={loading}
            onClick={onSave}
            style={{
              marginLeft: '1rem',
            }}
          >
            Salvar
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
