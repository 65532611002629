import { useEffect, useState, useContext } from 'react';
import { useSelector, } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import rgbHex from 'rgb-hex';
import DpoContext from './context';

import { Notification, Icon, } from 'rsuite';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Radio, RadioGroup, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ChromePicker, } from "react-color";
import BannerSite from '../../components/BannerSite';
import ContainerTimeLine from '../../components/Containers/ContainerTimeLine.jsx';
import CookiePopupInstallation from './CookiePopupInstallation.jsx';

import { updateCompanyPopup, getCompanyPopup, } from '../../services/api';

import { selectCompany, } from '../../features/companySlice';

import store from '../../redux/store';
import { BannerType } from '../../entities/banner-site.js'

const INITIAL_VALUES = Object.freeze({
  host: '',
  policyPath: '/politica.html',
  useTermsPath: '/termos.html',
  companyId: store.getState().company.company._id,
  lightMode: true, // TODO: remove lightMode in the future, since it won't be used anymore, reference: https://app.clickup.com/t/86a3b2mk6
  bannerType: null,
  highlightColor: null,
  backgroundColor: null,
  policyLinkEnabled: true,
  useTermsLinkEnabled: true,
  talkToDpoLinkEnabled: true,
});


/**
 * Form to control the cookie management popup
 *
 * @param {string} popupId - popup ID
 * @param {function} onConfigUpdated - callback called when config is updated
 * @param {object} props - props to be passed to the container (same props expected by the Grid)
 *
 * @returns Rendered component
 */
function CookiePopupConfigForm({ popupId, onConfigUpdated, ...props }) {
  const company = useSelector(selectCompany);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState(INITIAL_VALUES);
  const { setModalCode, onCopyScript } = useContext(DpoContext);
  const { sites, indexSelectSite } = useSelector(state => state.gestaoDeCookies);

  const classButom = useAspectRatioStyles();

  /**
   * Get popup config data from the API
   */
  const getData = async () => {
    if (undefined == popupId) {
      // Don't request the data if the popupId is undefined
      return;
    }

    try {
      const { data, } = await getCompanyPopup(popupId);
      setForm({
        host: data.host ? data.host : INITIAL_VALUES.host,
        policyPath: data.privacy_policy_path ? data.privacy_policy_path : INITIAL_VALUES.policyPath,
        useTermsPath: data.use_terms_path ? data.use_terms_path : INITIAL_VALUES.useTermsPath,
        companyId: data.company_id,
        id: data.id,
        lightMode: data.light_mode,
        highlightColor: data.highlight_color,
        backgroundColor: data.background_color,
        policyLinkEnabled: Boolean(data.policy_link_enabled),
        useTermsLinkEnabled: Boolean(data.use_terms_link_enabled),
        talkToDpoLinkEnabled: Boolean(data.talk_to_dpo_link_enabled),
        bannerType: data.banner_type,
      });

    } catch (err) {
      if (404 !== err.response.status) {
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Erro ao carregar configuração do popup!',
        });
      }
    }
  }

  /**
   * Save popup config
   */
  const saveConfig = async () => {
    setSaving(true);

    if ((form.policyPath.length === 0) || (form.useTermsPath.length === 0)) {
      Notification['warning']({
        placement: 'bottomEnd',
        title: 'Configuração inválida!',
        description: 'Os campos não podem ser deixados vazios.',
      });
      setSaving(false);
      return;
    }

    try {
      const { status, } = await updateCompanyPopup(company._id, popupId, form);
      if (200 === status) {
        Notification['success']({
          placement: 'bottomEnd',
          title: 'Configuração do popup atualizada com sucesso!',
        });

        if (_.isFunction(onConfigUpdated)) {
          onConfigUpdated(_.cloneDeep(form));
        }
      } else {
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Não foi possível salvar a configuração do popup!',
        });
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Erro ao carregar salvar configuração do popup!',
      });
    }

    setSaving(false);
  }

  /**
   * Use effect to get popup config from the API
   */
  useEffect(() => {
    getData();
  }, [popupId]);


  return (
    <Grid
      container
      spacing={2}
      direction="column"
      style={{
        marginTop: 5,
        marginBottom: 5,
      }}
      {...props}
    >
      <ContainerTimeLine number='1' title='Escolha o tipo do seu banner'>
        <p style={{ fontWeight: "600", fontSize: "16px", color: "rgba(84, 112, 140, 1)", marginBottom: "15px" }}>Modelo</p>
        <div style={{ marginLeft: "40px" }}>
          <Grid
            item
            xs={12}
            md={9}
            lg={6}
          >
            <Typography
              style={{
                fontWeight: 'bold',
              }}
              variant="body1"
              color="primary"
            >
              Host
            </Typography>
            <TextField
              fullWidth
              value={form.host}
              onChange={(ev) => setForm({ ...form, host: ev.target.value, })}
              required
            />
          </Grid>

          <Grid
            item
            container
            alignItems="start"
            direction="column"
          >
            <RadioGroup aria-label="position" name="position" defaultValue="top" onChange={(event) => setForm({ ...form, bannerType: event.target.value })}>
              <FormControlLabel value={BannerType.BASIC} control={<Radio color="primary" />} label="discreto" checked={form.bannerType == "BASIC" || (!form.bannerType)} />
              <BannerSite data={{ type: "BASIC", ...form }} />
              <FormControlLabel value={BannerType.EXTENDED} control={<Radio color="primary" />} label="estendido" checked={form.bannerType == "EXTENDED"} />
              <BannerSite data={{ type: "EXTENDED", ...form }} />
            </RadioGroup>
          </Grid>
        </div>
      </ContainerTimeLine>

      <ContainerTimeLine number='2' title='Configure a cor e botões'>
        <Grid
          item
          container
          alignItems="start"
          direction="column"
          style={{ marginBottom: "20px" }}
        >
          <Grid
            container
            justifyContent='space-between'
            spacing={4}
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
            >
              <Grid
                container
                justifyContent='center'
                style={{ border: "2px solid #3b66ff", borderRadius: "8px", padding: '20px' }}
              >
                <Grid
                  item
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      marginBottom: '20px'
                    }}
                    variant="body1"
                    color="primary"
                  >
                    Cor da fonte
                  </Typography>
                  <ChromePicker
                    color={form.highlightColor || '#3b66ff'}
                    onChange={(color) => setForm({
                      ...form,
                      highlightColor: `#${rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a)}`,
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={4}
            >
              <Grid
                container
                justifyContent='center'
                style={{ border: "2px solid #3b66ff", borderRadius: "8px", padding: '20px' }}
              >
                <Grid
                  item
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      marginBottom: '20px'
                    }}
                    variant="body1"
                    color="primary"
                  >
                    Cor do fundo
                  </Typography>
                  <ChromePicker
                    color={form.backgroundColor || '#fff'}
                    onChange={(color) => setForm({
                      ...form,
                      backgroundColor: `#${rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a)}`,
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={4}
            >
              <Grid
                container
                direction="column"
                style={{ border: "2px solid #3b66ff", borderRadius: "8px", padding: '20px', height: '100%' }}
              >
                <Grid container>
                  <Grid container direction="row" justifyContent='space-between'>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                      }}
                      variant="body1"
                      color="primary"
                    >
                      Aviso de privacidade
                    </Typography>


                    <Switch
                      checked={form.policyLinkEnabled}
                      onChange={(event) => setForm({ ...form, [event.target.name]: event.target.checked })}
                      name="policyLinkEnabled"
                      color="primary"
                    />
                  </Grid>

                  <TextField
                    fullWidth
                    value={form.policyPath}
                    onChange={(ev) => setForm({ ...form, policyPath: ev.target.value, })}
                    required
                    disabled={!form.policyLinkEnabled}
                  />
                </Grid>

                <Grid container>
                  <Grid container direction="row" justifyContent='space-between'>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                      }}
                      variant="body1"
                      color="primary"
                    >
                      Termos de uso
                    </Typography>

                    <Switch
                      checked={form.useTermsLinkEnabled}
                      onChange={(event) => setForm({ ...form, [event.target.name]: event.target.checked })}
                      name="useTermsLinkEnabled"
                      color="primary"
                    />
                  </Grid>
                  <TextField
                    fullWidth
                    value={form.useTermsPath}
                    onChange={(ev) => setForm({ ...form, useTermsPath: ev.target.value, })}
                    required
                    disabled={!form.useTermsLinkEnabled}
                  />
                </Grid>

                <Grid container>
                  <Grid container direction="row" justifyContent='space-between'>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                      }}
                      variant="body1"
                      color="primary"
                    >
                      falar com DPO
                    </Typography>
                    <Switch
                      checked={form.talkToDpoLinkEnabled}
                      onChange={(event) => setForm({ ...form, [event.target.name]: event.target.checked })}
                      name="talkToDpoLinkEnabled"
                      color="primary"
                    />
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ContainerTimeLine>

      {
        sites.length > 0 &&
        <Grid
          container
        >
          <ContainerTimeLine number='3' title='Copie o código HTML e insira em seu site' lineVerical={false}>
            <Grid
              item
            >
              <CookiePopupInstallation
                popupId={sites[indexSelectSite].id}
              />
            </Grid>
          </ContainerTimeLine>
        </Grid>
      }

      <Grid direction="row-reverse" container>
        <Grid
          item
          style={{ marginRight: "20px" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={saveConfig}
            className={classButom.root}
          >
            {saving ? <CircularProgress /> : 'Salvar'}
          </Button>

        </Grid>
      </Grid>
    </Grid>
  );
}

CookiePopupConfigForm.propTypes = {
  popupId: PropTypes.string.isRequired,
};

export default CookiePopupConfigForm;

const useAspectRatioStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(49, 217, 166, 1)',
    '&:hover': { backgroundColor: 'rgba(39, 174, 133, 1)' },
    paddingLeft: "30px",
    paddingRight: "30px"
  }
});
