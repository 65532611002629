import styled from "styled-components";


export const MainWorkshopInputIntegra = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  padding: 0 1.5rem;
  margin: 0;

  .mb-3 {
    flex-direction: column;
    width: 100%;
  }
`;

export const InputTitleWorckshopIntegra = styled.div`
  width: 100%;
  max-width: 100%;
  justify-content: center;

  h1 {
    top: 0;
    padding: 1.5rem 0 0 0;
    color: rgb(59, 102, 255);
    text-align: left;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: 0;
  }

  p {
    text-align: left;
    padding: 0;
    margin: 1rem  0;
  }
`;

export const TemplateWorkshopIntegra = styled.div`
  width: 100%;
  padding: 0;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: left;

  .ChipIntegrante {
    background: #6F8EFF;
    color: #F2F4F8;
  }
`;

export const BoxButtonReunion = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-grow: 1;
    margin: 1rem 0;
`;

export const BTNNewReunion = styled.button`
  width: 150px;
  max-width: 150px;
  height: 35px;
  border: 2px solid rgb(59, 102, 255);
  background: #ffffff;
  border-radius: 4px;
  margin-right: 0;
  font-family: Inter, sans-serif;
  font-weight: 600;
  color: rgb(59, 102, 255);
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9)
  }
`;
