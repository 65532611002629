import { useState, useEffect, memo, } from 'react';
import { useSelector, } from 'react-redux';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { Notification, } from 'rsuite';

import { selectCompany } from '../../features/companySlice';
import { selectCompanySectors, } from '../../features/companySectorsSlice';

import { getQuestionsBySectorAndResponse, } from '../../services/api';


function ChartBar() {
  const company = useSelector(selectCompany);
  const companySectors = useSelector(selectCompanySectors);
  const [questionsBySectorAndResponse, setQuestionsBySectorAndResponse] = useState([]);

  async function initialize() {
    try {
      const { data, } = await getQuestionsBySectorAndResponse(company._id);
      setQuestionsBySectorAndResponse(data);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar dados do gráfico!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }
  }

  /**
   * Use effect to initialize component data
   */
  useEffect(() => {
    initialize()
  }, []);


  const data = questionsBySectorAndResponse.map(({ sectorId, compliant, pending, total, }) => {
    const sector = companySectors.find(({ id, }) => sectorId == id);

    return {
      name: (undefined != sector) ? sector.name : '',
      'Adequado': compliant,
      'Adequação': pending,
      amt: total,
    };
  });


  return (
    <ResponsiveContainer
      width="100%"
      height={600}
    >
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="Adequado"
          stackId="a"
          fill="#00C851"
        />

        <Bar
          dataKey="Adequação"
          stackId="a"
          fill="#ff4444"
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default memo(ChartBar);
