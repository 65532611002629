import styled from "styled-components";

export const MainWorkshopInputApresenta = styled.div`
  width: 100%;
  height: 88%;
  display: flex;
  top: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 0 2rem 2rem 2rem;
  margin: 0;

  .BoxMSGSucessoFile {
    margin-top: 2rem;
  }

  .linkMsgSucessFile {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }

`;

export const InputTitleWorckshopApresenta = styled.div`
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;

  h1 {
    top: 0;
    padding: 1.5rem 0 0 0;
    color: rgb(59, 102, 255);
    text-align: left;
    font-size: 1.5rem;
    line-height: 1.5rem;

    margin: 0;
  }

  p {
    text-align: left;
    padding: 0;
    margin: 1rem  0;
  }
`;



export const InputUploadWorkshopApres = styled.div`
  width: 100%;
  
  height: 20%;
  border-radius: 0.75rem;
  background: transparent;
 
  padding: 0;
  padding-top: 0;
  margin-bottom: 1rem;

  align-items: center;
  justify-content: flex-start;
  
  input {

    display: none;
    height: 15%;
    
  }

  label {
    
    width: 25%;
    max-width: 25%;
    height: 20%;
    max-height: 20%;
    color: white;

    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: rgb(59, 102, 255);
    font-weight: bold;
    border: 2px dashed #95ADC3;
    border-radius: 0.5rem;

    margin-right: 3px;
    

    background: transparent;

    font-size: 13px;

    cursor: pointer;

    @media (min-width: 1700px) {
      width: 23%;
      max-width: 23%;
    }

    @media (min-width: 1700px) {
      width: 21%;
      max-width: 21%;
    }

    span {
      width: 45%;
      font-family: Inter;
      font-weight: normal;
      font-size: 11px;
   
      text-align: center;
      color: #54708C;
    }

    p {
      font-family: Inter;
      font-weight: normal;
      color: black;
      margin-bottom: 5px;
    }

    img {
    width: 1.8rem;
    margin: 0;
    padding: 0;

  }

}

`;



export const ButtonSaveProgress = styled.div`
  width: 100%;
  height: 4rem;
  margin-top: 5rem;
  margin-bottom: 0rem;
  display: grid;
  grid-template-rows: 1fr;
  align-items: flex-end;
  justify-content: flex-end;

  button {
    background: rgb(59, 102, 255);
    color: #fff;
    height: 2rem;
    border-radius: 0.25rem;

  
  }


`;
