import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useThemeContext, } from "../../../context/ThemeAppContext";
import { Icon } from "rsuite";
import { setStatusMenuLeft } from "../../../features/planAction";

export default function CardMenuLeft({ children }) {
    const { stylesLayout } = useThemeContext();
    const planActionStore = useSelector(state => state.planAction);
    const className = styles({ stylesLayout, menuIsOpen: planActionStore.statusMenuLeft });
    const dispatch = useDispatch();

    function handlerSetMenuIsOpen() {
        dispatch(setStatusMenuLeft(!planActionStore.statusMenuLeft));
    }

    return (
        <div className={className.externalContainer}>
            <div>
                <div className={className.container}>
                    <div onClick={handlerSetMenuIsOpen} className={className.header}>
                        <p>Responsável</p>
                        <div className={className.icon}>
                            <Icon
                                className="EditItem"
                                icon="angle-left"
                                size="lg"
                            />
                        </div>
                    </div>
                    {planActionStore.statusMenuLeft &&
                        <div className={className.body}>
                            <div className={className.containerItem}>
                                {children}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

const styles = makeStyles({
    externalContainer: {
        minWidth: '200px',
        position: (props) => !props.menuIsOpen ? 'absolute' : 'static',
        marginLeft: '20px'
    },
    container: {
        display: "flex",
        flexDirection: "column",
        background: (props) => !props.menuIsOpen ? "#b4b4b4" : 'var(--gray-color)',
        overflow: "hidden",
        maxWidth: "200px",
        borderRadius: "4px"
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "10px 20px",
        width: "100%",
        background: 'var(--gray-color)',
        borderRadius: "4px"
    },
    body: {
        display: "flex",
        flexDirection: "column",
        background: "#fff",
        margin: "0px 3px 3px 3px",
        borderRadius: "4px",
        padding: "10px 10px"
    },
    containerItens: {

    },
    item: {
        padding: "10px 0px"
    },
    totlip: {
        writingMode: "vertical-lr",
        padding: "5px 8px"
    },
    icon: {
        transition: 'transform 0.1s ease-in-out',
        transform: (props) => !props.menuIsOpen ? 'rotate(0deg)' : 'rotate(-90deg)'
    }

});
