import { useState, useEffect } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, } from 'react-i18next';

import { Container, Paper, Dialog, Chip, Avatar, TextareaAutosize, } from '@material-ui/core';
import { Row, Col, Form, ListGroup, Image, } from 'react-bootstrap';
import { Button, Icon, Notification, IconButton, } from 'rsuite';

import { setMsg, loadingFalse, loadingTrue, } from '../../features/mensagensSolicitacao';

import api, {
  uploadContactRequestDocument,
  updateDPOContactRequest,
  listApplicantContactRequestObservations,
} from '../../services/api';

import styles from './styleChatResp.module.scss';

import { errorCodes } from '../../errors/standard-error';


export default function ChatRespDPO({ solicitacaoDados, companyId, token, }) {
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const [file, setFile] = useState(null)
  const [position, setPosition] = useState(0)
  const [resposta, setResposta] = useState('')
  const [openFileViewer, setOpenFileViewer] = useState(false)
  const observacao = useSelector((state) => state.mensagensSolicitacao.value)


  async function handleSendDoc() {
    try {
      await uploadContactRequestDocument(solicitacaoDados.id, file, resposta);
      updateSolicitacao();
    } catch(err) {
      if ((undefined != err.response) && (undefined != err.response.data) &&
        (errorCodes.INVALID_FILE_ERROR == err.response.data.code)) {
        Notification['error']({
          placement: 'bottomEnd',
          title: t('ERRORS.ERROR_CODES.INVALID_FILE_ERROR'),
        });
      } else {
        Notification['error']({
          placement: 'bottomEnd',
          title: t('ERRORS.CONTACT_REQUEST.failed_to_send_message'),
          description: t('ERRORS.try_again_later'),
        });
      }
    }
  }

  async function getObs() {
    dispatch(loadingTrue());

    try {
      const response = await listApplicantContactRequestObservations(token, companyId,
        solicitacaoDados.id);
      dispatch(setMsg(response.data.observations));
    } catch {
      //TODO: show notification
    }

    dispatch(loadingFalse());
  }

  useEffect(() => {
    getObs();

    return function cleanup() {
      dispatch(setMsg([]));
    };
  }, []);

  const sendObs = async () => {
    if (!resposta && !file)
      return Notification['warning']({
        placement: 'bottomEnd',
        title: 'Sem conteudo de observação',
      })

    if (!file) {
      await api
        .post(`/solicitacao/obs`, {
          solicitacao: solicitacaoDados.id,
          value: resposta,
          user: solicitacaoDados.name,
        })
        .then(() => {
          Notification['success']({
            placement: 'bottomEnd',
            title: 'Observação salva com sucesso',
          })
          updateSolicitacao()
        })
    } else {
      await handleSendDoc()
    }
  }

  async function updateSolicitacao() {
    try {
      await updateDPOContactRequest({
        id: solicitacaoDados.id,
        response: resposta,
        status: solicitacaoDados.status,
        email: solicitacaoDados.email,
        name: solicitacaoDados.name,
      });

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Notificação respondida com sucesso',
      });
      getObs();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Erro ao atualizar a notificação',
      });
    }
  }

  return (
    <Container
      className={styles.ContainerChat}
      maxWidth="xl"
      component={Paper}
    >
      <br />
      <Form>
        <br />
        <Row>
          <Col xs={12}>
            <Form.Label
              style={{
                color: '#3B66FF',
                fontSize: '14px',
              }}
            >
              Solicitação
            </Form.Label>
            <Form.Control
              disabled
              value={solicitacaoDados.obs}
            />
            <br />
            <ListGroup className={styles.boxExternMapObs}>
              {observacao.map((resp, index) => {
                return (
                  <div
                    className={
                      resp.user === 'DPO'
                        ? styles.BoxAllObsDPO
                        : styles.BoxAllObs
                    }
                  >
                    <div
                      key={resp.id}
                      className={
                        resp.user === 'DPO'
                          ? styles.ChatLeftUserDPO
                          : styles.ChatLeftUser
                      }
                    >
                      <div>
                        <Chip
                          variant="filled"
                          style={{ border: 'none' }}
                          avatar={
                            <Avatar
                              className={styles.Avatar}
                            >
                              {<Icon icon="user" />}
                            </Avatar>
                          }
                          label={
                            'Mensagem' + ' ' + resp.user
                          }
                        />
                      </div>
                      {
                        !resp.file ?
                        <p
                          style={{
                            width: '100ch',
                            maxWidth: '100%',
                            whiteSpace: 'pre-line',
                            wordBreak: 'break-word',
                          }}
                        >
                          {resp.resposta}
                        </p> :
                        <p
                          style={{
                            width: '100ch',
                            maxWidth: '100%',
                            whiteSpace: 'pre-line',
                            wordBreak: 'break-word',
                          }}
                        >
                          {resp.resposta} - {resp.size}Kb
                          <Icon
                            style={{
                              marginLeft: '20px',
                              color: '#3B66FF',
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              (window.location.href = `https://s3.sa-east-1.amazonaws.com/octo.legal.images/${resp.file}`)
                            }
                            size="lg"
                            icon="cloud-download"
                          />
                          <Icon
                            style={{
                              marginLeft: '10px',
                              color: '#3B66FF',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setOpenFileViewer(true)
                              setPosition(index)
                            }}
                            size="lg"
                            icon="external-link"
                          />
                        </p>
                      }

                      {index === position && (
                        <Dialog
                          open={openFileViewer}
                          onClose={() =>
                            setOpenFileViewer(false)
                          }
                          maxWidth="xl"
                        >
                          <Container
                            maxWidth="xl"
                            style={{
                              width: '800px',
                              height: '600px',
                            }}
                            disableGutters
                          >
                            {resp.doc_type ===
                            'application/pdf' ? (
                              <iframe
                                src={`https://docs.google.com/viewer?url=https://s3.sa-east-1.amazonaws.com/octo.legal.images/${resp.file}&hl=en&pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
                                width="100%"
                                height="100%"
                              />
                            ) : (
                              <Image
                                src={`https://s3.sa-east-1.amazonaws.com/octo.legal.images/${resp.file}`}
                                fluid
                              />
                            )}
                          </Container>
                        </Dialog>
                      )}
                      <span className={styles.dateMSG}>
                        {moment(resp.created_at).format(
                          'HH:MM - DD/MM/YYYY '
                        )}
                      </span>
                    </div>
                  </div>
                )
              })}
            </ListGroup>
            <Form.Label
              style={{
                color: '#3B66FF',
                fontSize: '19px',
                fontWeight: 'bold',
              }}
            >
              Resposta
            </Form.Label>
            {!file && (
              <TextareaAutosize
                value={resposta}
                aria-label="minimum height"
                minRows={2}
                onChange={(e) => {
                  setResposta(e.target.value)
                }}
                style={{ width: '100%' }}
              />
            )}
          </Col>
        </Row>
        <br />
        <Row>
          {file && (
            <Col xs={12}>
              <div className={styles.ContainerFile}>
                <span>Verifique antes de anexar</span>
                <small>
                  Suportamos arquivos de imagem e PDF
                  menores que 10Mb.
                </small>
                <p>{file.name}</p>
                <small>{file.size} kb</small>
                <br />
                <Button
                  appearance="ghost"
                  onClick={() => setFile(null)}
                >
                  Cancelar
                </Button>
                <br />
              </div>
            </Col>
          )}
          <Col xs={12} className={styles.boxBTN}>
            <Form.Label htmlFor="contained-button-file">
              <IconButton
                componentClass="span"
                appearance="ghost"
                icon={<Icon icon="paperclip" />}
                style={{ marginRight: '10px' }}
              />
            </Form.Label>

            <Button
              onClick={sendObs}
              color="blue "
              style={{ marginBottom: '10px' }}
            >
              Enviar resposta
            </Button>
          </Col>
        </Row>
        <input
          id="contained-button-file"
          type="file"
          style={{ display: 'none' }}
          onChange={(e) => {
            if ((undefined == e.target.files) || (undefined == e.target.files[0])) {
              return;
            }

            if (e.target.files[0].size >= 10**7) {
              alert('Selecione um arquivo menor');
            } else {
              setFile(e.target.files[0]);
            }
          }}
        />
      </Form>
      <br />
    </Container>
  )
}
