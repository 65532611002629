import { useEffect, useState } from 'react'
import {
  Container,
  Paper,
  Grid,
  Typography,
} from '@material-ui/core'
import Modal from '../CorpoPlano/new.plano/ModalRanking'
import { listCompanyMeetings, getUsers } from '../../services/api'
export default function CardsMon({
  description,
  value,
  type,
  itens,
}) {
  const [open, setOpen] = useState(false)
  const [users, setUsers] = useState([])
  const [reunions, setReunios] = useState([])
  const [descRevisao, setDescRevisao] = useState('')
  const getData = async () => {
    await getUsers().then(({ data }) => {
      setUsers(data)
    })
    await listCompanyMeetings().then(({ data }) => {
      setReunios(data)
    })
  }
  const close = () => setOpen(!open)

  return (
    <Grid item xs={12} sm={4}>
      {!type && (
        <Container
          component={Paper}
          style={{ height: '101px' }}
        >
          <Typography
            variant="body1"
            style={{
              color: '#54708C',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <strong
              style={{
                fontSize: 48,
                color: '#3B66FF',
                padding: 20,
              }}
            >
              {value}
            </strong>
            {'  '}
            {description}{' '}
          </Typography>
        </Container>
      )}

      {type && (
        <div>
          <Container
            onClick={() => {
              getData()
              if (value) {
                setOpen(true)
              }
            }}
            component={Paper}
            style={{ height: '101px', cursor: 'pointer' }}
          >
            <Typography
              variant="body1"
              style={{
                color: '#54708C',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <strong
                style={{
                  fontSize: 48,
                  color: '#3B66FF',
                  padding: 20,
                }}
              >
                {value}
              </strong>
              {'  '}
              {description}{' '}
            </Typography>
          </Container>
          <Modal
            isOpen={open}
            onRequestClose={close}
            body={itens}
            reunionUnique={reunions}
            users={users}
          />
        </div>
      )}
    </Grid>
  )
}
