import React from 'react';

import Sheet from '../../components/Sheet';
import CommitteeMeetings from '.';

const PAGES = Object.freeze({
    committeeMeetings: {
      id: 'committeeMeetings',
      titleTab: "Reunião do comitê",
      titlePage: "Reunião do comitê",
      component: <CommitteeMeetings />,
    },
});

const ARRAY_PAGES = Object.values(PAGES);

export default function MapMeetings() {

  return (
    <Sheet pages={ARRAY_PAGES} />
  );
}
