import React from 'react';


/**
 * @param {Function} fn - debouncer callback
 * @param {number} delay - debouncer delay in milliseconds
 * @returns debounced function
 */
export default function useDebounce(fn, delay = 100) {
  const timeoutRef = React.useRef(null);


  function debouncedFn(...params) {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      fn(...params);
    }, delay);
  }

  React.useEffect(() => {
    return function cleanup() {
      clearTimeout(timeoutRef.current);
    };
  }, []);


  return debouncedFn;
}
