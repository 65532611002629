import { useRef } from 'react';
import { Container, Dialog } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useReactToPrint } from 'react-to-print';
import { Table } from 'react-bootstrap';
import { ModalPlanoAcao2 } from '../../CorpoMon/ModalCorpoPlanAcao2';
import PlanoComObs from '../../CorpoMon';
import './styles.css';
import { Button } from 'rsuite';


export default function ModalRanking({ isOpen, onRequestClose, body, quem, users, reunionUnique }) {
  const componentRef = useRef();


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  return (
    <Dialog
      open={isOpen}
      onClose={onRequestClose}
      maxWidth="xl"
      ref={componentRef}
    >
      <Alert
        onClose={onRequestClose}
        severity="info"
        className="AlertBar"
      >
        { quem ? `Ações destinadas a ${quem}` : 'Sem responsável' }
      </Alert>
      <Container maxWidth="xl">
        <ModalPlanoAcao2
          users={users}
          reunionUnique={reunionUnique}
          data={body}
        />

        <Table
          striped
          hover
          responsive
        >
          <thead>
            <tr className="Header">
              <th>Quem</th>
              <th>O quê</th>
              <th></th>
              <th>Quando</th>
              <th>
                <Button
                  block
                  onClick={handlePrint}
                >
                  Imprimir
                </Button>
              </th>
            </tr>
          </thead>
          {body.map((body) => {
            if (quem && body.quem === quem) {
              return (
                <PlanoComObs
                  key={body.id}
                  data={body}
                  users={users}
                  reunionUnique={reunionUnique}
                />
              )
            } else if (!quem) {
              return (
                <PlanoComObs
                  key={body.id}
                  data={body}
                  users={users}
                  reunionUnique={reunionUnique}
                />
              )
            }
          })}
        </Table>
      </Container>
    </Dialog>
  );
}
