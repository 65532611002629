import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Notification, } from 'rsuite';

import * as api from '../services/api';
import { CRUD_STATUSES, } from '../utils/constants';


const initialState = {
  impactReports: [],
  currReportId: null,
  versionId: null,
  status: CRUD_STATUSES.IDLE,
  isGlobal: false,
};

export const listImpactReports = createAsyncThunk('impactReports/fetch', async (_, thunkAPI) => {
  const { isGlobal, } = thunkAPI.getState().impactReports;
  const { data, status, } = isGlobal ? await api.listGlobalImpactReportTemplates() :
    await api.listImpactReports(thunkAPI.getState().company.company._id);
  if (200 === status) {
    return data.reports;
  } else {
    Notification['warning']({
      placement: 'bottomEnd',
      title: 'Falha ao carregar relatórios de impacto!',
      description: 'Tente novamente dentro de alguns instantes.'
    });
  }
});

export const getImpactReport = createAsyncThunk('impactReports/get', async (reportId, thunkAPI) => {
  const { data, } = await api.getImpactReport(thunkAPI.getState().company.company._id, reportId);
  return data;
});

export const createImpactReport = createAsyncThunk('impactReports/create', async (impactReport, thunkAPI) => {
  const { isGlobal, } = thunkAPI.getState().impactReports;
  const { data, } = isGlobal ? await api.createAppImpactReportTemplate(impactReport) :
    await api.createImpactReport(thunkAPI.getState().company.company._id, impactReport);
  return data;
});

export const deleteImpactReport = createAsyncThunk('impactReports/delete', async (reportId, thunkAPI) => {
  const { isGlobal, } = thunkAPI.getState().impactReports;
  const { status, } = isGlobal ? await api.deleteGlobalImpactReportTemplate(reportId) :
    await api.deleteImpactReport(thunkAPI.getState().company.company._id, reportId);
  return 200 === status ? reportId : null;
});

export const impactReportsSlice = createSlice({
  name: 'impactReports',
  initialState,
  reducers: {
    setStatus: (state, { payload: status, }) => {
      if (status != null) {
        state.status = status;
      } else {
        state.status = initialState.status;
      }
    },
    setIsGlobal(state, { payload: _isGlobal, }) {
      state.isGlobal = _isGlobal;
    },
    setCurrentReport(state, { payload: currReportId, }) {
      state.currReportId = currReportId;
      state.versionId = null;
    },
    setCurrentVersionId(state, { payload: versionId, }) {
      state.versionId = versionId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listImpactReports.pending, (state) => {
      state.status = CRUD_STATUSES.LOADING;
    }).addCase(listImpactReports.fulfilled, (state, { payload: impactReports, }) => {
      state.status = CRUD_STATUSES.FETCH_SUCCESSFUL;
      const reports = impactReports;
      if ((reports.length > 0) && (state.currReportId == null)) {
        state.currReportId = reports[0].id;
      }
      state.impactReports = reports;
    }).addCase(listImpactReports.rejected, (state) => {
      state.status = CRUD_STATUSES.FETCH_FAILED;
      state.impactReports = initialState.impactReports;
    });

    builder.addCase(getImpactReport.fulfilled, (state, { payload: impactReport, }) => {
      if ((impactReport == null) || (impactReport.id == null)) {
        return;
      }

      const idx = state.impactReports.findIndex(({ id, }) => id === impactReport.id);
      if (idx > -1) {
        state.impactReports[idx] = impactReport;
      }
    });

    builder.addCase(createImpactReport.fulfilled, (state, { payload: impactReport, }) => {
      state.impactReports.push(impactReport);
      if (impactReport.id != undefined) {
        state.currReportId = impactReport.id;
      }
    });

    builder.addCase(deleteImpactReport.fulfilled, (state, { payload: reportId, }) => {
      const index = state.impactReports.findIndex(({ id, }) => id === reportId);
      if (index > -1) {
        state.impactReports.splice(index, 1);

        if (reportId === state.currReportId) {
          state.currReportId = state.impactReports.length > 0 ? state.impactReports[0].id : null;
          state.versionId = null;
        }
      }
    });
  }
});

/**
 * Custom select to get status
 *
 * @param {object} state - impactReports slice state
 *
 * @returns the feature status
 */
export const selectFeatureStatus = (state) => {
  return state.impactReports.status;
}

/**
 * Custom select to get is global setting
 *
 * @param {object} state - impactReports slice state
 *
 * @returns {boolean} is global status
 */
export const selectIsGlobal = (state) => {
  return state.impactReports.isGlobal;
}

/**
 * Custom select to get impact reports
 *
 * @param {object} state - store state
 *
 * @returns impact reports
 */
export const selectImpactReports = (state) => {
  return state.impactReports.impactReports;
}

/**
 * Select current impact report
 *
 * @param {object} state - store state
 *
 * @returns current impact report
 */
export const selectCurrentReport = (state) => {
  return state.impactReports.impactReports.find((item) => {
    return item.id == state.impactReports.currReportId;
  });
}

/**
 * Select current impact report ID
 *
 * @param {object} state - store state
 *
 * @returns current impact report ID
 */
export const selectCurrentReportId = (state) => {
  return state.impactReports.currReportId;
}

/**
 * Select current version ID
 *
 * @param {object} state - store state
 *
 * @returns current version ID
 */
export const selectCurrentVersionId = (state) => {
  return state.impactReports.versionId;
}

export const {
  setStatus,
  setCurrentReport,
  setCurrentVersionId,
  setIsGlobal,
} = impactReportsSlice.actions;
export default impactReportsSlice.reducer;
