import {useState, useMemo} from 'react';


/**
 *
 * @param items {array}
 * @param options {{page?: number, itemsPerPage?: number}}
 * @returns {{currentItems: *, itemsPerPage: *, paginate: (function(*): *), setItemsPerPage: *}}
 */
export function usePaginator(items, options = {}) {
  const [currentPage, setCurrentPage] = useState(options?.page || 1);
  const [itemsPerPage, setItemsPerPage] = useState(options?.itemsPerPage || 10);

  const paginatedItems = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return items.slice(indexOfFirstItem, indexOfLastItem);
  }, [items, currentPage, itemsPerPage]);

  return {
    totalItems: items.length,
    currentPage,
    itemsPerPage,
    setCurrentPage,
    setItemsPerPage,
    paginate: paginatedItems,
  };
}
