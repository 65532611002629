import React, { useEffect, useState, } from 'react';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { useDispatch, useSelector, } from 'react-redux';

import { Button, Notification, } from 'rsuite';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import api, { byStatus, } from '../../services/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

import ActionList from '../../components/Resposta/ActionList';
import BreadCrumbs from '../../components/Global/BreadCrumbs';
import { HeaderProgress, } from '../../components/HeaderProgress';

import { listUsers, } from '../../features/usersSlice';
import { selectCompany, } from '../../features/companySlice';

import { Checkbox, FormControlLabel, } from '@material-ui/core';

import PendingTasks from '../Resultado/index';
import FinishedTasks from '../Resultado/ResultAppropriate';


function MaturityReport() {
  const [semStatus, setSemStatus] = useState([]);
  const [baixo, setBaixo] = useState([]);
  const [medio, setMedio] = useState([]);
  const [alto, setAlto] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const [checked, setChecked] = React.useState({
    checkedPending: true,
    checkedFinished: true
  });

  const handleChange = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };


  const onDownload = async () => {
    if (!downloading) {
      setDownloading(true);
      const key = 'downloading-report';
      Notification['info']({
        placement: 'bottomEnd',
        title: 'O relatório está sendo gerado!',
        description: 'Não é necessário atualizar a página, você poderá encontrar o arquivo na pasta downloads em alguns instantes.',
        duration: 0,
        key,
      });

      const url = `/companies/${company._id}/risk-analysis-report/download`;
      try {
        const { data, } = await api.get(url, {
          responseType: 'arraybuffer',
        });

        const reportName = `relatório-aderência-${company.name}-${moment().format('YYYYMMDD')}.pdf`;
        fileDownload(data, reportName);
      } catch (err) {
        console.log(err);
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Download do relatório falhou!',
          description: 'Um erro ocorreu durante o download do relatório solicitado, tente novamente mais tarde ou entre em contato com o suporte.',
        });
      }

      Notification.close(key);
      setDownloading(false);
    } else {
      Notification['warning']({
        placement: 'bottomEnd',
        title: 'O relatório já está sendo gerado!',
        description: 'O processamento da última requisição ainda foi finalizado.',
      });
    }
  }

  const getData = async () => {
    const { data, } = await byStatus('all', 'all');
    const { semStatus, low, medium, high, } = data;
    setSemStatus(semStatus);
    setBaixo(low);
    setMedio(medium);
    setAlto(high);
  }

  useEffect(() => {
    getData();
    dispatch(listUsers());
  }, []);


  function MainComponent() {
    return (
      <>
        <div>
          <Container maxWidth="xl">
            <Button
              onClick={onDownload}
              style={{
                backgroundColor: '#007bff',
                color: 'white',
              }}
            >
              <DownloadIcon
                style={{
                  marginRight: 8,
                }}
              />
              Baixar relatório
            </Button>
            <br />
            <br />
            <Container
              component={Paper}
              maxWidth="xl"
            >
              <br />
              {
                [semStatus, alto, medio, baixo].map((questions) => {
                  return (
                    <ActionList questions={questions} />
                  );
                })
              }
              <br />
            </Container>
          </Container>
        </div>
      </>
    )
  }


  return (
    <>
      <div style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked.checkedFinished}
              onChange={handleChange}
              name="checkedFinished"
              color="primary"
            />
          }
          label="concluídas"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={checked.checkedPending}
              onChange={handleChange}
              name="checkedPending"
              color="primary"
            />
          }
          label="pendentes"
        />
      </div>
      {(checked.checkedPending && checked.checkedFinished) && <MainComponent />}
      {(checked.checkedPending && !checked.checkedFinished) && <PendingTasks />}
      {(!checked.checkedPending && checked.checkedFinished) && <FinishedTasks />}

    </>
  );
}

export default MaturityReport;
