import React from 'react';

import Sheet from '../../components/Sheet';
import CompanyMaturityDiagnosisWrapper from '.';

const PAGES = Object.freeze({
  diagnosis: {
    id: 'diagnosis',
    titleTab: "Diagnóstico",
    titlePage: "Diagnóstico",
    component: <CompanyMaturityDiagnosisWrapper />,
  },
});

const ARRAY_PAGES = Object.values(PAGES);

export default function MapPageDiagnosis() {

  return (
    <Sheet pages={ARRAY_PAGES} />
  );
}
