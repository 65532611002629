import { useState, useEffect, useMemo, } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Icon, } from 'rsuite';
import { Notification, } from 'rsuite';
import { Dialog, IconButton, } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import DeleteDialog from '../../DeleteDialog';

import styles from './style.module.scss';

import {
  createCustomReport,
  deleteCustomReport,
} from '../../../features/customMappingReportSlice';

import { CRUD_STATUSES as STATUSES, } from '../../../utils/constants';

import useModal from '../../../hooks/useModal';
import DataMappingFieldFilterSelector from '../../DataMappingFieldFilterSelector';


const CUSTOM_REPORT_COLOR_OPTIONS = Object.freeze([
  '#ffb54d',
  '#c0a1f2',
  '#b2dd7b',
  '#54708c',
  '#f0988e',
]);

const DEFAULT_FORM_DATA = Object.freeze({
  name: '',
  color: CUSTOM_REPORT_COLOR_OPTIONS[0],
  items: [],
  filters: [],
});


/**
 * @param {Object} props - component props
 * @param {boolean} props.open - if the modal is open or not
 * @param {DataMappingPhaseData[]} props.phases - data mapping phases
 * @param {Record<number, DataMappingFieldData[]>} props.phaseToFieldsMap - map from phase ID to
 * field array
 * @param {() => void} props.onClose - on close modal callback
 */
export default function ModalPersonalizedReport({ open, onClose, phases, phaseToFieldsMap, }) {
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [data, setData] = useState({ ...DEFAULT_FORM_DATA, });
  const [selectedPhaseId, setSelectedPhaseId] = useState(undefined);
  const { status, customReports, } = useSelector((state) => state.customMappingReport);
  const allFields = useMemo(() => Object.values(phaseToFieldsMap).flat(), [phaseToFieldsMap]);
  const fields = useMemo(() => {
    return phaseToFieldsMap[selectedPhaseId] || [];
  }, [selectedPhaseId, phaseToFieldsMap]);
  const [selectedReportId, setSelectedReportId] = useState(undefined);
  const selectedCustomReport = useMemo(() => {
    return customReports.find(({ id, }) => selectedReportId === id);
  }, [selectedReportId, customReports]);
  const [deleteModalOpen, openDeleteModal, closeDeleteModal] = useModal(
    (customReportId) => setSelectedReportId(customReportId),
    () => setSelectedReportId(undefined)
  );


  async function onCreateCustomReport() {
    const hasUndefinedFilterItem = Boolean(data.filters.find((item) => undefined == item.fieldId));
    if (hasUndefinedFilterItem) {
      Notification['warning']({
        placement: 'bottomEnd',
        title: t('ERRORS.CUSTOM_DATA_MAPPING_REPORTS.invalid_filter'),
        description: t('ERRORS.CUSTOM_DATA_MAPPING_REPORTS.must_set_filtered_field'),
      });

      return;
    }

    const hasEmptyFilterItem = Boolean(data.filters.find((item) => 0 == item.values.length));
    if (hasEmptyFilterItem) {
      Notification['warning']({
        placement: 'bottomEnd',
        title: t('ERRORS.CUSTOM_DATA_MAPPING_REPORTS.invalid_filter'),
        description: t('ERRORS.CUSTOM_DATA_MAPPING_REPORTS.must_set_filter_values'),
      });

      return;
    }

    try {
      setLoading(true);

      await dispatch(createCustomReport(data)).unwrap();
      clearData();
      Notification['success']({
        placement: 'bottomEnd',
        title: t('PAGES.CUSTOM_DATA_MAPPING_REPORTS.report_successfully_created'),
      });
    } catch (error) {
      Notification['error']({
        placement: 'bottomEnd',
        title: t('ERRORS.CUSTOM_DATA_MAPPING_REPORTS.failed_to_create_report'),
        description: t('ERRORS.try_again_later'),
      });
    }
    setLoading(false);
  }

  async function onDelete() {
    setDeleting(true);

    try {
      await dispatch(deleteCustomReport(selectedReportId)).unwrap();
      closeDeleteModal();
      Notification['success']({
        placement: 'bottomEnd',
        title: t('PAGES.CUSTOM_DATA_MAPPING_REPORTS.report_successfully_deleted'),
      });
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: t('ERRORS.CUSTOM_DATA_MAPPING_REPORTS.failed_to_delete_report'),
        description: t('ERRORS.try_again_later'),
      });
    }

    setDeleting(false);
  }

  function onTogglePhase(phase) {
    setSelectedPhaseId(selectedPhaseId != phase.id ? phase.id : undefined);
  }

  function onChange(name, value) {
    setData({
      ...data,
      [name]: value,
    });
  }

  function onToggleDataMappingField(field) {
    const items = data.items.includes(field.id) ?
      data.items.filter((fieldId) => fieldId != field.id) :
      [...data.items, field.id];
    onChange('items', items);
  }

  useEffect(() => {
    switch(status) {
      case STATUSES.FETCH_FAILED:
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Falha ao carregar relatórios customizados!',
          description: 'Tente novamente mais tarde ou entre em contato com o suporte.',
        });
        break;
      default:
        break;
    }
  }, [status]);


  function clearData() {
    setData({
      ...DEFAULT_FORM_DATA,
      items: allFields.map((f) => f.id),
    });
  }

  useEffect(() => {
    if (open) {
      clearData();
    }
  }, [open])


  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <div className={styles.headerModal}>
        <h3>Adicionar novo relatório</h3>
        <Icon
          icon="close"
          onClick={onClose}
        />
      </div>
      <main className={styles.ContainerMain}>
        <div className={styles.containerLeft}>
          <div className={styles.BoxNameReport}>
            <p className={styles.TitleBlockStandart}>
              Como você deseja chamar este relatório?*
            </p>

            <input
              type="text"
              className={styles.InputNameReport}
              placeholder="Ex.: Relatório de processos..."
              name="name"
              value={data.name}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </div>

          <div className={styles.ContainerboxColor}>
            <p className={styles.TitleBlockStandart}>
              Escolha uma cor para o relatório
            </p>

            <div className={styles.boxColor}>
              {
                CUSTOM_REPORT_COLOR_OPTIONS
                  .map((colorOption) => {
                    const isSelected = colorOption === data.color;
                    return (
                      <div
                        style={{
                          width: '22px',
                          height: '22px',
                          background: colorOption,
                          borderRadius: '22px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          marginLeft: '0.2rem',
                          ...(
                            isSelected ?
                            {
                              border: '2px solid #3b66ff',
                              borderRadius: '28px',
                            } :
                            {}
                          ),
                        }}
                        onClick={() => onChange('color', colorOption)}
                      >
                      </div>
                    );
                  })
              }
            </div>
          </div>

          <div className={styles.ContainerAbas}>
            <p className={styles.TitleBlockStandart}>
              Escolha as abas e campos a constar no relatório*
            </p>
            <div className={styles.boxSelectAbas}>
              <div className={styles.boxleft}>
                {
                  phases
                    .map((phase) => {
                      return (
                        <div
                          className={styles.aba}
                          onClick={() => onTogglePhase(phase)}
                        >
                          <p>
                            { phase.name }
                          </p>
                        </div>
                      );
                    })
                }
              </div>

              <div className={styles.boxRigth}>
                {
                  fields
                    .map((field) => {
                      const isSelected = Boolean(data.items.find((val) => val === field.id));
                      return (
                        <div
                          key={field.id}
                          className={`${styles.Campo} ${isSelected ? styles.Selected : null}`}
                          onClick={() => onToggleDataMappingField(field)}
                        >
                          <input
                            readOnly
                            type="checkbox"
                            checked={isSelected}
                          />
                          <p>
                            { field.name }
                          </p>
                        </div>
                      );
                    })
                }
              </div>
            </div>
          </div>

          <p
            className={styles.TitleBlockStandart}
            style={{
              margin: '0.7rem 0',
            }}
          >
            { t('PAGES.CUSTOM_DATA_MAPPING_REPORTS.select_report_filters') }
          </p>
          <DataMappingFieldFilterSelector
            fields={allFields}
            value={data.filters}
            onChange={(filters) => onChange('filters', filters)}
          />
        </div>

        <div className={styles.containerRigth}>
          <p className={styles.TitleBlockStandart}>
            Seus templates
          </p>
          <div className={styles.containerDrafts}>
            {
              customReports
                .map((report) => {
                  return (
                    <div
                      key={report.id}
                      className={styles.draftGroup}
                    >
                      <div className={`${styles.draft} ${styles.color1}`} >
                        <div
                          style={{
                            backgroundColor: report.color,
                          }}
                        />
                        <p>
                          { report.name }
                        </p>
                      </div>

                      <IconButton
                        size="small"
                        color="secondary"
                        disabled={deleting}
                        onClick={() => openDeleteModal(report.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  );
              })
            }
          </div>
          <div className={styles.boxBtn}>
            <button
              className={styles.cancel}
              onClick={onClose}
            >
              Cancelar
            </button>
            <button
              className={styles.save}
              onClick={onCreateCustomReport}
              disabled={loading}
            >
              Salvar
            </button>
          </div>
        </div>
      </main>

      <DeleteDialog
        open={deleteModalOpen}
        confirmationMessage={t('PAGES.CUSTOM_DATA_MAPPING_REPORTS.delete_confirmation', {
          name: undefined != selectedCustomReport ? selectedCustomReport.name : '',
        })}
        onClose={closeDeleteModal}
        onConfirm={onDelete}
      />
    </Dialog>
  );
}
