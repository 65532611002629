import { useState, useEffect, } from 'react';
import { useSelector, } from 'react-redux';
import moment from 'moment'
import { useFormik, } from 'formik';
import * as Yup from 'yup';

import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import { Dialog, } from '@material-ui/core';
import { Icon, IconButton, Notification, Toggle, } from 'rsuite';

import { changeDataMappingRevocationState, updateTokenValidity, } from '../../../services/api';
import Label from '../../Global/Label'
import './GeraLinkStyles.css'

import { selectCompany, } from '../../../features/companySlice';
import {
  selectCompanySectorById,
  selectCompanySectors,
} from '../../../features/companySectorsSlice';


const INITIAL_VALUES = Object.freeze({
  time: 1,
  sector: 0,
});


export default function Link({ link, getLinks, }) {
  const { name: companyName, _id: companyId, } = useSelector(selectCompany);
  const [config, setConfig] = useState(false);
  const [revoke, setRevoke] = useState(link.revoke);

  const companySectors = useSelector(selectCompanySectors);
  const VALID_SECTORS = companySectors.map((sector) => sector.id);
  const companySector = useSelector((state) => selectCompanySectorById(state, link.setor));
  const url = `${window.location.origin}/mapeamento/publico?idEmpresa=${companyId}&token=${link.token}&setor=${link.setor}&empresa=${companyName}`;

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: updateLink,
    validationSchema: Yup.object({
      time: Yup.number().required('Obrigatório').min(1, 'A validade deve ser de pelo menos 1 dia'),
      sector: Yup.number().required('Obrigatório').oneOf(VALID_SECTORS, 'Deve ser um setor válido'),
    }),
  });

  function resetForm(values) {
    formik.resetForm({
      values,
    });
  }

  function onClose() {
    setConfig(false);
    resetForm(INITIAL_VALUES);
  }

  useEffect(() => {
    if (null != link) {
      resetForm({
        sector: link.setor,
        time: 1,
      });
    }

    return function cleanup() {
      resetForm(INITIAL_VALUES);
    };
  }, [link]);

  async function disableToken() {
    setRevoke(0);

    try {
      await changeDataMappingRevocationState(link.id, 0);
      getLinks();
    } catch {
      Notification['warning']({
        placement: 'bottomEnd',
        title: 'Atenção!',
        description: 'Erro ao atualizar link',
      });
    }
  }

  async function updateLink(values) {
    try {
      await updateTokenValidity(link.id, values.sector, Number(values.time));
      Notification['success']({
        placement: 'bottomEnd',
        title: 'Link atualizado com sucesso!',
      });
      getLinks();
    } catch {
      Notification['warning']({
        placement: 'bottomEnd',
        title: 'Atenção!',
        description: 'Erro ao atualizar link',
      });
    }
  }


  return (
    <div className="ContainerLink">
      <label
        style={{
          minWidth: '10rem',
          maxWidth: '25rem',
          textAlign: 'center',
        }}
      >
        Expira: {moment(link.expiresIn).fromNow()}
      </label>
      <label
        style={{
          minWidth: '10rem',
          maxWidth: '25rem',
          textAlign: 'center',
        }}
      >
        Setor: { undefined != companySector ? companySector.name : link.setor }
      </label>
      <Form.Control className="BoxLink" value={url} />
      <IconButton
        color="blue"
        icon={<Icon icon="copy" />}
        type="submit"
        onClick={() => {
          navigator.clipboard.writeText(`${url}`)
          return Notification['info']({
            placement: 'bottomEnd',
            title: 'Texto copiado!',
          })
        }}
        circle
      />
      <IconButton
        disabled
        color="green"
        icon={<Icon icon="whatsapp" />}
        circle
        onClick={() => {
          navigator.clipboard.writeText(`${url}`)
          return Notification['info']({
            placement: 'bottomEnd',
            title: 'Texto copiado!',
          })
        }}
      />

      <IconButton
        appearance="subtle"
        icon={<Icon icon="cog" />}
        onClick={() => {
          setConfig(!config)
        }}
      />
      <Dialog
        open={config}
        onClose={onClose}
        maxWidth="md"
      >
        {config && (
          <div className="configBox">
            <Label description="Configuração do link" />
            <br />
            <Row>
              <Col>
                <Form.Group className="mb-3"
                  style={{
                    width: '100%',
                  }}
                >
                  <Form.Label>
                    Setor
                  </Form.Label>
                  <Form.Control
                    className="selectContent"
                    as="select"
                    id="sector"
                    isInvalid={formik.touched.sector && formik.errors.sector}
                    style={{
                    }}
                    {...formik.getFieldProps('sector')}
                  >
                    <option
                      key={0}
                      value={0}
                    >
                      Escolha um setor
                    </option>
                    {
                      companySectors.map(({ id, name, }) => (
                        <option
                          key={id}
                          value={id}
                        >
                          { name }
                        </option>
                      ))
                    }
                  </Form.Control>

                  <Form.Control.Feedback type="invalid">
                    { formik.errors.sector }
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Dias de validade do link
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="number"
                      className="formInput"
                      id="time"
                      isInvalid={formik.touched.time && formik.errors.time}
                      {...formik.getFieldProps('time')}
                    />
                    <IconButton
                      appearance="ghost"
                      icon={<Icon icon="refresh" />}
                      onClick={formik.handleSubmit}
                    />
                  </InputGroup>

                  <Form.Control.Feedback type="invalid">
                    { formik.errors.time }
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Toggle
                checked={revoke}
                onChange={disableToken}
                checkedChildren="Habilitado"
                unCheckedChildren="Desabilitado"
              />
            </Row>
          </div>
        )}
      </Dialog>
    </div>
  );
}
