import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import CustomPaginator from "../Paginators/CustomPaginator";

export default function DefaultTable({
	columns = [],
	rows = [],
	loading = false,
	rowsPerPage = null,
	currentPage = null,
	setRowsPerPage = null,
	setCurrentPage = null,
	componetTopLeftPaginator = null
}) {
	const [internalCurrentPage, setInternalCurrentPage] = useState(1);
	const [internalRowsPerPage, setInternalRowsPerPage] = useState(5);

	function handlerSetRowsPerPage(item) {
		const itensPerPage = item.target.value;
		setInternalRowsPerPage(itensPerPage);
		if (typeof setRowsPerPage === 'function') {
			setRowsPerPage(itensPerPage);
		}
	}

	function handlerSetCurrentPage(item, page) {
		setInternalCurrentPage(page);
		if (typeof setCurrentPage === 'function') {
			setCurrentPage(page);
		}
	}

	function handlerPaginate(array) {
		const pageNumber = currentPage || internalCurrentPage;
		const pageSize = rowsPerPage || internalRowsPerPage;
		const start = (pageNumber - 1) * pageSize;
		const end = start + pageSize;
		return array.slice(start, end);
	}

	function handlerRenderComponent({ component: Component, row, colum }) {
		if (!!Component && typeof Component === 'function') {
			return <Component row={row} colum={colum} field={colum.field} />
		} else {
			return row[colum.field]
		}
	}


	function handlerReaderComponentHeader({ component: Component, colum }) {
		if (!!Component && typeof Component === 'function') {
			return <Component colum={colum} />
		} else {
			return colum.label
		}
	}



	console.log(rows);


	return (
		<div style={{ width: '100%' }}>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				{componetTopLeftPaginator || <div></div>}
				<CustomPaginator
					data={rows}
					rowsPerPage={rowsPerPage || internalRowsPerPage}
					page={currentPage || internalCurrentPage}
					onRowsPerPageChange={handlerSetRowsPerPage}
					onPageChange={handlerSetCurrentPage}
				/>
			</div>

			<TableContainer>
				<Table sx aria-label="simple table">
					<TableHead>
						<TableRow>
							{columns.map((colum, index) => (
								<TableCell key={index + ''}>
									{handlerReaderComponentHeader({ component: colum?.componentHeader, colum })}
								</TableCell>
							))}
						</TableRow>
					</TableHead>

					<TableBody>
						{handlerPaginate(rows).map((row, index) => (
							<TableRow
								key={index}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								{columns.map((colum, index) => (
									<TableCell key={index + ''}>
										{handlerRenderComponent({ component: colum?.componentBody, row, colum })}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<CustomPaginator
				data={rows}
				rowsPerPage={rowsPerPage || internalRowsPerPage}
				page={currentPage || internalCurrentPage}
				onRowsPerPageChange={handlerSetRowsPerPage}
				onPageChange={handlerSetCurrentPage}
			/>
		</div>
	)
}