import React from "react";

import AppBarMobileOrWeb from "./V2";

export default function CustomAppBar({ data, }) {
  
  return (
    <AppBarMobileOrWeb data={data} />
  );
}

