import React from 'react';
import { useSelector, } from 'react-redux';

import { Container, Paper, Grid, Typography, } from '@material-ui/core';
import { Form, InputGroup, Button, FormControl, Col, } from 'react-bootstrap';

import { selectCompany, } from '../../features/companySlice';


export default function GeraLinkTitular({
  pushButtonEmailDPO,
  emailComunication,
  setEmailComunication,
  emailCom,
}) {
  const company = useSelector(selectCompany);
  const linkSolicitacao = `${window.location.origin}/solicitacao/dados?idEmpresa=${company._id}`;


  return (
    <Grid
      item
      xs={12}
      style={{ background: "#fff", marginTop: "20px" }}
      component={Paper}
    >
      <Typography
        variant="h6"
        style={{ color: '#1C265A' }}
      >
        Comunicação com títular de dados
      </Typography>
      <Container
        maxWidth="xl"
        style={{
          display: "flex",
          alignItems: "center",
          paddingBottom: 20,
        }}
      >
        <Col sm={6}>
          <Form.Group controlId="formBasicEmail">
            <br />
            <Form.Label>
              Link para o fomulario da solicitação de
              dados
            </Form.Label>
            <InputGroup className="mb-3">
              <FormControl
                disabled
                aria-describedby="basic-addon1"
                value={linkSolicitacao}
              />
                <Button
                  disabled={emailCom}
                  variant="outline-primary"
                  onClick={() =>
                    navigator.clipboard
                      .writeText(linkSolicitacao)
                      .then(() => {
                        return alert('Copiado')
                      })
                  }
                >
                  Copiar
                </Button>
                <Button
                  disabled={emailCom}
                  variant="outline-primary"
                  onClick={() =>
                    navigator.clipboard
                      .writeText(
                        `<a href="${linkSolicitacao}" style="color: #999999; font-size: 12px; text-align: center; text-decoration: none;" target="_blank">Solicitar informações sobre meus dados</a>.`
                      )
                      .then(() => {
                        return alert('Copiado')
                      })
                  }
                >
                  Copiar em HTML
                </Button>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group
            controlId="formBasicEmail"
            style={{
              height: "100%",
              marginBottom: 0,
            }}
          >
            <Form.Label>
              Email para contato
            </Form.Label>
            <InputGroup
              style={{
                marginBottom: "none",
                background: "#F2F4F8",
              }}
            >
              <FormControl
                style={{ background: "#F2F4F8" }}
                value={emailComunication}
                placeholder={company.emailContato}
                onChange={(e) => {
                  setEmailComunication(
                    e.target.value
                  )
                }}
              />
                <Button
                  type="submit"
                  variant="primary"
                  onClick={pushButtonEmailDPO}
                >
                  Salvar
                </Button>
            </InputGroup>
          </Form.Group>
        </Col>
      </Container>
    </Grid>
  );
}
