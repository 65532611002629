import React from 'react';
import _ from 'lodash';
import { ErrorMessage, } from 'formik';


export default function QuestionOptionsForm({ name, }) {
  return (
    <ErrorMessage name={name} >
      {
        (msg) => (
          <div
            style={{
              color: 'red',
            }}
          >
            { _.isString(msg) ? msg : null }
          </div>
        )
      }
    </ErrorMessage>
  );
}
