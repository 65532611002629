import React from 'react';
import _ from 'lodash';
import {
  Container,
  TextField,
  Button,
  IconButton,
} from '@material-ui/core';
import {makeStyles,} from '@material-ui/styles';
import { FieldArray, Field, useFormikContext, } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import QuestionOptionsForm from './QuestionOptionsForm';
import Error from '../Error';


const useStyles = makeStyles({
  addButton: {
    textTransform: 'none',
    marginTop: '0.25rem',
    marginBottom: '1rem',
  },
});

const INITIAL_QUESTION_DATA = Object.freeze({
  statement: '',
  alternatives: [
    {
      isCorrect: true,
      statement: '',
    },
    {
      isCorrect: false,
      statement: '',
    },
  ],
});


export default function QuestionForm() {
  const classes = useStyles();
  const { values, } = useFormikContext();


  return (
    <FieldArray name="test.questions">
      {
        ({ push, remove, }) => (
          <Container disableGutters >
            {
              values.test && values.test.questions && values.test.questions.length > 0 &&
              values.test.questions.map((question, idx) => {
                return (
                  <div key={idx}>
                    <Field name={`test.questions[${idx}].statement`} >
                      {
                        ({ field, }) => {
                          return (
                            <TextField
                              label={`Pergunta ${idx + 1}`}
                              fullWidth
                              type="text"
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    size="small"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      remove(idx);
                                    }}
                                  >
                                    <CloseIcon/>
                                  </IconButton>
                                ),
                              }}
                              {...field}
                            />
                          );
                        }
                      }
                    </Field>
                    <Error name={`test.questions[${idx}].statement`} />

                    <QuestionOptionsForm questionIdx={idx} />
                    <Error name={`test.questions[${idx}].alternatives`} />
                  </div>
                );
              })
            }
            <Button
              variant="contained"
              color="primary"
              endIcon={<AddIcon/>}
              className={classes.addButton}
              onClick={(ev) => {
                ev.preventDefault();
                push(_.cloneDeep(INITIAL_QUESTION_DATA));
              }}
              disableElevation
            >
              Adicionar pergunta
            </Button>
          </Container>
        )
      }
    </FieldArray>
  );
}
