import { createSlice } from '@reduxjs/toolkit'

export const docsSlice = createSlice({
  name: 'docs',
  initialState: {
    value: [],
  },
  reducers: {
    resetDocs: (state) => {
      state.value = []
    },
    setDocs: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { resetDocs, setDocs } = docsSlice.actions

export default docsSlice.reducer
