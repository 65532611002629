import { useState, useEffect, } from 'react';
import { makeStyles, } from "@material-ui/styles";
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import LimitedBackdrop from './LimitedBackdrop';


const useStyles = makeStyles({
  tableContainer: {
    position: 'relative',
    marginBottom: 10,
    maxHeight: (props) => props.maxHeight ? props.maxHeight : undefined,
  },
});


/**
 * Table component
 *
 * @param {Array} data - table data
 * @param {object[]} headers - table headers
 * @param {boolean} serverSidePagination - indicates if the pagination is server side or not
 * @param {function} getData - callback to update the visible data (required only if the pagination
 * is server side)
 * @param {boolean} loading - indicates if the table data is being loaded
 * @param {boolean} showError - indicates if the error message should be rendered
 * @param {string} errorMessage - error message to show
 * @param {string} noDataMessage - message to show in case the data array is empty
 * @param {array} rowsPerPageOptions - rows per page options passed to paginator
 * @param {number} totalItems - total of items in the data array, required if using server side
 * pagination
 * @param {boolean} disablePagination - totally disable pagination
 *
 * @returns Rendered component
 */
export default function CustomTable({
  data,
  headers,
  serverSidePagination,
  getData,
  loading,
  showError,
  errorMessage,
  noDataMessage,
  rowsPerPageOptions,
  totalItems,
  children,
  disablePagination,
  addBorders = false,
  ...props
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setPageSize] = useState(5);

  const classes = useStyles(props);


  /**
   * Handle page change
   *
   * @param {object} event - event that triggered the change
   * @param {number} newPage - new page
   */
  const onPageChange = (event, newPage) => {
    setPage(newPage);
    if (serverSidePagination) {
      getData(newPage, rowsPerPage);
    }
  }

  /**
   * Handle change in page size
   *
   * @param {object} event - event thar triggered the change
   */
  const onRowsPerPageChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setPage(0);
    if (serverSidePagination) {
      getData(0, newPageSize);
    }
  }

  useEffect(() => {
    setPage(0);
  }, [data]);


  const __dataToRender = (serverSidePagination || disablePagination) ? data :
    data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


  return (
    <Container
      maxWidth="xl"
      disableGutters
      {...props}
    >
      <TableContainer
        className={classes.tableContainer}
        style={{
          outline: addBorders ? '1px solid #E0E0E0' : undefined,
        }}
      >
        <Table size="small" >
          <TableHead>
            <TableRow>
              {
                headers.map((header, index) => {
                  return (
                    <TableCell
                      key={index}
                      align={header.align || "left"}
                      style={{
                        outline: addBorders ? '1px solid #E0E0E0' : undefined,
                      }}
                    >
                      { header.label }
                    </TableCell>
                  );
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              __dataToRender.map((item, index) => children(item, { key: item.id || index, }))
            }
          </TableBody>
        </Table>
        <LimitedBackdrop open={loading && (data.length > 0)}>
          <CircularProgress />
        </LimitedBackdrop>
      </TableContainer>

      {
        showError || loading || (data.length === 0) ?
        <Grid
          container
          justifyContent="center"
          style={{
            marginTop: 10,
          }}
        >
          { loading && (data.length === 0) ? <CircularProgress /> : null }
          {
            !loading && showError ?
            <Typography
              variant="body1"
              align="center"
              display="block"
              color="error"
            >
              { errorMessage }
            </Typography> :
            null
          }
          {
            !loading && !showError && (data.length === 0) ?
            <Typography
              variant="body1"
              align="center"
              display="block"
              style={{
                color: '#808080',
              }}
            >
              { noDataMessage }
            </Typography> :
            null
          }
        </Grid> :
        null
      }
      {
        (disablePagination === true) ?
        null :
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions || [5, 10, 15, 20, 25]}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          count={totalItems || data.length}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      }
    </Container>
  )
}
