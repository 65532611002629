import React from 'react';
import _ from 'lodash';

import {
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { IconButton, Icon, Whisper, Popover, } from 'rsuite';

import { COMPANY_MATURITY_DIAGNOSIS_FIELD_TYPES, } from './context';


export default function CompanyMaturityDiagnosisQuestionItem({ order, question, onChange, onNext, }) {
  function numberToLetter(value) {
    const letters = [
      'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I',
      'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S',
      'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
    ];

    return letters[value % letters.length];
  }

  function handleEnter(ev) {
    if (ev.key == 'Enter') {
      onNext();
    }
  }


  return (
    <Container
      component={Paper}
      style={{
        margin: '1rem 0',
        padding: '0.5rem 0',
      }}
      elevation={0}
    >
      <Container maxWidth="sm" >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            item
            xs={true}
          >
            <Typography
              variant="h5"
              align="center"
              style={{
                fontWeight: '600',
                color: '#3B66FF',
              }}
            >
              { `${order}. ${question.question}` }
            </Typography>
          </Grid>

          {
            question.helperText ?
            <Grid item>
              <Whisper
                placement="bottomEnd"
                trigger="click"
                speaker={
                  <Popover
                    style={{
                      width: '300px',
                      maxWidth: '90%',
                    }}
                  >
                    { question.helperText }
                  </Popover>
                }
              >
                <IconButton
                  appearance="ghost"
                  size="xs"
                  icon={
                    <Icon
                      icon="question"
                      className="fill-color"
                    />
                  }
                />
              </Whisper>
            </Grid> :
            null
          }
        </Grid>

        {
          COMPANY_MATURITY_DIAGNOSIS_FIELD_TYPES.NUMBER == question.type ?
          <TextField
            fullWidth
            type="number"
            margin="normal"
            variant="outlined"
            value={question.value}
            placeholder={question.placeholder}
            onKeyDownCapture={handleEnter}
            onChange={(ev) => onChange(ev.target.value)}
          /> :
          COMPANY_MATURITY_DIAGNOSIS_FIELD_TYPES.SELECT == question.type ?
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
            style={{
              marginTop: '0.5rem',
            }}
          >
            {
              question.options.map((item, idx) => {
                const selected = question.multiple ? question.value.includes(idx) :
                  question.value === idx;

                return (
                  <Grid
                    item
                    container
                    key={idx}
                    style={{
                      width: '100%',
                      cursor: 'pointer',
                      minHeight: '40px',
                      margin: '0.5rem 0',
                      borderRadius: '20px',
                      border: '1px solid #D0D5E7',
                      color: selected ? '#FFFFFF' : '#3B66FF',
                      backgroundColor: selected ? '#3B66FF' : 'transparent',
                    }}
                    alignItems="center"
                    justifyContent="flex-start"
                    onClick={() => {
                      let value = idx;
                      if (question.multiple) {
                        value = [...question.value];
                        if (value.includes(idx)) {
                          value = value.filter((item) => idx != item);
                        } else {
                          value.push(idx);
                        }
                      }

                      onChange(value);
                    }}
                  >
                    <div
                      style={{
                        height: '32px',
                        width: '32px',
                        borderRadius: '30px',
                        backgroundColor: selected ? '#FFFFFF' : '#F3F4F7',
                        color: '#3B66FF',
                        marginLeft: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '0.75rem',
                        fontWeight: 'bold',
                      }}
                    >
                      { numberToLetter(idx) }
                    </div>
                    <Grid
                      item
                      xs={true}
                    >
                      { item.value }
                    </Grid>
                  </Grid>
                );
              })
            }
          </Grid> :
          <TextField
            fullWidth
            type="text"
            margin="normal"
            variant="outlined"
            value={question.value}
            placeholder={question.placeholder}
            onKeyDownCapture={handleEnter}
            onChange={(ev) => onChange(ev.target.value)}
          />
        }
      </Container>
    </Container>
  );
}

