import React, { useEffect, useState } from "react";
import { listCompanyDataMappingReport, getRespostaMapeamento } from "../../services/api.js";
import { useSelector } from "react-redux";
import { selectCompany } from "../../features/companySlice";
import _ from "lodash";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { makeStyles } from '@material-ui/styles';
import { Grid } from "@material-ui/core";
import {selectCurrentReportId} from "../../features/impactReportsSlice";


export default function mappingWidget() {
    const reportId = useSelector(selectCurrentReportId);
    const [loadding, setLoading] = useState(false);
    const [reportPage, setReportPage] = useState({
        phases: [],
    });
    const company = useSelector(selectCompany);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(10);
    const [lines, setLines] = useState(null);

    const classes = useStyles();

    async function getReportPage() {
        setLoading(true);

        try {
            const lines = await getRespostaMapeamento();
            const { data, } = await listCompanyDataMappingReport(company._id, 0, lines.data.length, null, null, [{
              field: "RIPD",
              value: reportId
            }]);
          console.log(data);
          const newData = data.report.map(element => {
                return element['phases'][0]['fields'];
            });

            setLines(newData);

        } catch (err) {
            console.log(err);
        }

        setLoading(false);
    }

    useEffect(() => {
        (async () => {
            await getReportPage();

        })()
    }, [page]);

    const Line = ({ obj: { campo, resp }}) => {
        return (
            <Grid container direction="row" className={classes.row}>
                <Grid container item sm={12} md={5} className={classes.cellBlue}>
                    <Typography variant="body1" style={{ fontWeight: '500' }}>{campo}</Typography>
                </Grid>
                <Grid container item sm={12} md className={classes.cell}>
                    <Typography variant="body1" style={{ fontWeight: '500' }}>{resp}</Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <Box>
            {lines &&
                lines.map((arryFields, index) => (
                    <TableContainer key={index + ""} component={Paper} elevation={0} className={classes.mb20 + " " + classes.table}>
                        <Box className={classes.header}>
                            <Typography variant="h5">
                                Dados vindos do mapeamento linha {index + 1 + ""}
                            </Typography>
                        </Box>
                        {arryFields.map((obj, index) => (
                            <Line obj={obj} key={index + ""} />
                        ))
                        }

                    </TableContainer>
                ))
            }
        </Box>
    );

}


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    header: {
        backgroundColor: 'rgb(59 102 255)',
        color: 'white',
        textAlign: "center",
        padding: 20,
        marginBottom: 10
    },
    cellBlue: {
        color: '#fff',
        backgroundColor: 'rgb(59 102 255)',
        padding: '10px 20px'
    },
    cell: {
        color: 'rgb(59 102 255)',
        background: "#f2f4f8",
        padding: '10px 20px'
    },

    mb20: {
        marginBottom: 20
    },
    row: {
        marginBottom: 10,
        borderRadius: 8,
        overflow: "hidden"
    }
});

