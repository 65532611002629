import React from 'react';

import { Link, Typography, } from '@material-ui/core';


export default function SignaturesWidgetHeader() {
  return (
    <>
      <Typography
        variant="subtitle1"
        style={{
          fontWeight: 500,
          marginBottom:'0.5rem',
        }}
      >
        Aprovação
      </Typography>

      <Typography
        style={{
          marginBottom:'0.5rem',
        }}
      >
        Esta seção visa formalizar a aprovação do RIPD por meio da obtenção das assinaturas do  Responsável pela elaboração do RIPD, pelo encarregado e pelas autoridades que representam o controlador e operador. O responsável pela elaboração do Relatório pode ser o próprio encarregado ou qualquer outra pessoa designada pelo controlador com conhecimento necessário para realizar tal tarefa.
      </Typography>
      <Typography
        style={{
          marginBottom:'0.5rem',
        }}
      >
        O RIPD deve ser revisto e atualizado anualmente ou sempre que existir qualquer tipo de mudança que afete o tratamento dos dados pessoais realizados pela instituição. Detalhes sobre a necessidade de revisão do RIPD podem ser observados no{' '}
        <Link
          href="https://www.gov.br/anpd/pt-br/canais_atendimento/agente-de-tratamento/relatorio-de-impacto-a-protecao-de-dados-pessoais-ripd"
          target="_blank"
        >
          Guia da ANPD sobre o RIPD
        </Link>
        .
      </Typography>
    </>
  );
}
