import ResourceClassification from './ResourceClassification';

import styles from './styleBodyNew.module.scss';
import './StylesClassificacao.css';


export default function ResourcesClassification({
  basesClassification,
  sectorsClassification,
  purposeClassification,
}) {
  return (
    <div className={styles.ContainerAllClassifica}>
      <ResourceClassification
        title="Bases legais"
        classification={basesClassification}
      />

      <ResourceClassification
        title="Setores"
        classification={sectorsClassification}
      />

      <ResourceClassification
        title="Finalidades"
        classification={purposeClassification}
      />
    </div>
  );
}
