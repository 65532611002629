import React, { useState, useEffect, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import {
  Grid,
  Dialog,
  Container,
  Typography,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import useModal from '../../hooks/useModal';
import PageSelector from '../PageSelector/PageSelector';
import ImpactReportEditor from '../../pages/ImpactReport/ImpactReportEditor';
import ImpactReportToolbar from '../../pages/ImpactReport/ImpactReportToolbar';

import { CRUD_STATUSES, } from '../../utils/constants';

import {
  selectImpactReports,
  selectFeatureStatus,
  selectCurrentReportId,
  setIsGlobal,
  listImpactReports,
  setCurrentReport,
  setCurrentVersionId,
} from '../../features/impactReportsSlice';


const useStyles = makeStyles({
  title: {
    color: '#4D5884',
    fontSize: '1.4rem',
    fontFamily: 'Inter',
    marginTop: '0.3rem',
    marginBottom: '0.8rem',
  },
});


const IMPACT_REPORT_OPTIONS = Object.freeze({
  VIEW: {
    id: 'VIEW',
    label: 'Visualizar',
  },
  EDIT: {
    id: 'EDIT',
    label: 'Editar',
  },
});
const IMPACT_REPORT_OPTIONS_ARRAY = Object.values(IMPACT_REPORT_OPTIONS);



export default function GlobalImpactReportTemplates() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const status = useSelector(selectFeatureStatus);
  const reportId = useSelector(selectCurrentReportId);
  const reports = useSelector(selectImpactReports);

  const loading = (status !== CRUD_STATUSES.FETCH_FAILED) && (status !== CRUD_STATUSES.FETCH_SUCCESSFUL);


  const [open, openModal, closeModal] = useModal();
  const [selectedOption, setSelectedOption] = useState(IMPACT_REPORT_OPTIONS.VIEW.id);


  function initialize() {
    dispatch(setIsGlobal(true));
    dispatch(listImpactReports());
  }

  /**
   * Set selected option
   *
   * @param {string} option - option ID
   */
  function onSelectedOption(option) {
    setSelectedOption(option);
  }

  /**
   * Use effect to initialize component
   */
  useEffect(() => {
    initialize();
    return () => {
      dispatch(setCurrentReport(null));
      dispatch(setCurrentVersionId(null));
      dispatch(setIsGlobal(false));
    };
  }, []);

  /**
   * Use effect to initialize component
   */
  useEffect(() => {
    if (!open) {
      setSelectedOption(IMPACT_REPORT_OPTIONS.VIEW.id)
    }
  }, [open]);


  return (
    <Grid
      item
      xs={12}
    >
      <div className="boxHeaderPoliticas">
        <h3>
          Templates de Relatórios de Impacto
        </h3>

        <button onClick={openModal}>
          Visualizar
        </button>
      </div>

      <Dialog
        open={open}
        onClose={closeModal}
        fullScreen
        fullWidth
      >
          <Container
            maxWidth="xl"
            component={Paper}
            style={{
              padding: '1rem',
            }}
            elevation={0}
          >
            <p className={classes.title} >
              Templates de Relatório de Impacto
            </p>

            <ImpactReportToolbar />

            {
              loading || (null == reportId) || (reports.length === 0) ?
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                alignContent="center"
              >
                { loading ? <CircularProgress size={24} /> : null }

                <Typography
                  color="textSecondary"
                  variant="body1"
                  style={{
                    marginTop: '0.5rem',
                  }}
                >
                  {
                    loading ? 'Carrengando relatórios de impacto...' :
                    (reports.length === 0) ? 'Você ainda não possui relatórios de impacto' :
                    (null == reportId) ? 'Selecione um relatório de impacto ou crie um novo' :
                    null
                  }
                </Typography>
              </Grid>:
              <>
                <PageSelector
                  option={selectedOption}
                  options={IMPACT_REPORT_OPTIONS_ARRAY}
                  onSelectedOption={onSelectedOption}
                  style={{
                    marginTop: '3rem',
                    marginBottom: '3rem',
                  }}
                />

                <ImpactReportEditor
                  readOnly={selectedOption === IMPACT_REPORT_OPTIONS.VIEW.id}
                />
              </>
            }
        </Container>
      </Dialog>
    </Grid>
  )
}
