import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, } from 'react-redux';
import moment from 'moment';

import { Grid, Typography, Box, } from '@material-ui/core';

import BaseWidget from './BaseWidget';
import DataManagementRoleChart from '../../DataManagementRoleChart';

import { DashboardWidgetType, } from '../../../core/entities/dashboard-widget';

import { getCompanyDataManagementRolesClassification, } from '../../../services/api';

import { selectCompany, } from '../../../features/companySlice';


function HeaderItem({ label, value, }) {
  return (
    <div
      style={{
        fontFamily: 'Inter',
        color: '#54708C',
        padding: '0.4em',
      }}
    >
      <strong style={{ color: '#1D4ED8', }} >{label}</strong>: { value }
    </div>
  );
}


function CompanyObjectiveWidget(props) {
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);


  async function getData() {
    setLoading(true);
    try {
      const response = await getCompanyDataManagementRolesClassification(company._id);
      setData(response.data.classification);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    getData();
  }, []);


  return (
    <BaseWidget
      loading={loading}
      {...props}
      header={(
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexGrow: 1,
          }}
        >
          <Box
            display={{
              xs: 'none',
              md: 'block',
            }}
          >
            <HeaderItem
              label={t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.COMPANY_OBJECTIVE}.report_date`)}
              value={moment().format('DD/MM/YYYY')}
            />
          </Box>
          <HeaderItem
            label={t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.COMPANY_OBJECTIVE}.project_start`)}
            value={moment(company.createdAt).format('DD/MM/YYYY')}
          />
        </Box>
      )}
    >
      <Grid
        container
        style={{
          flexGrow: 1,
        }}
        justifyContent="space-between"
      >
        <Grid
          item
          xs={12}
          md={7}
          style={{
            paddingRight: '1rem',
          }}
        >
          <Typography
            style={{
              color: '#3B66FF',
              fontFamily: 'Inter',
              fontWeight: 'bold',
            }}
            variant="subtitle1"
          >
            { t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.COMPANY_OBJECTIVE}.subheader`) }
          </Typography>

          <Typography
            style={{
              color: '#54708C',
              fontFamily: 'Inter',
            }}
          >
            { t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.COMPANY_OBJECTIVE}.content`, { companyName: company.name, }) }
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={5}
          style={{
            flexGrow: 1,
          }}
          container
          alignItems="center"
          justifyContent="flex-end"
          direction="column"
        >
          <DataManagementRoleChart dataManagementRoleClassification={data} />
        </Grid>
      </Grid>
    </BaseWidget>
  );
}

export default CompanyObjectiveWidget;
