import { useState, useEffect } from 'react';
import { getUserOnline, } from '../../services/api';


/**
 * Component to show user online state
 *
 * @param {string} userId - ID of the user to show the online state
 *
 * @returns Rendered component
 */
export default function UserOnlineStateBadge({ userId, }) {
  const [online, setOnline] = useState(false)

  /**
   * Fetch required data to load component from the API
   */
  const getData = async () => {
    const { status, } = await getUserOnline(userId);
    //todo: refactor this online state update after the endpoint is refactored
    setOnline(status === 200);
  }

  /**
   * Use effect to trigger the fetch process from the API
   */
  useEffect(() => {
    getData()
  }, []);


  return (
    <span
      style={{
        height: 20,
        width: 20,
        border: '1px solid #0000FF',
        backgroundColor: online ? '#31D9A6' : '#bbb',
        borderRadius: '50%',
        display: 'inline-block',
      }}
    />
  )
}
