import { useTranslation, } from 'react-i18next';

import { PieChart, Pie, Cell, ResponsiveContainer, } from 'recharts';

import { RISK_STATUSES, } from '../../core/entities/risk';


export default function RiskPizzaChart({ semStatus, baixo, medio, alto, }) {
  const { t, } = useTranslation();
  const values = {
    [RISK_STATUSES.LOW.key]: baixo,
    [RISK_STATUSES.MEDIUM.key]: medio,
    [RISK_STATUSES.HIGH.key]: alto,
    [RISK_STATUSES.UNKNOWN.key]: semStatus,
  };
  const data = Object.entries(values).map(([status, count]) => {
    const color = RISK_STATUSES[status].color;
    return {
      name: t(`RISK_STATUSES.${status}`),
      value: count,
      color,
      status,
    };
  });


  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
    const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }


  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      minHeight={170}
    >
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          fill="#8884d8"
          dataKey="value"
        >
          {
            data.map(({ status, color, }) => (
              <Cell
                key={status}
                fill={color}
              />
            ))
          }
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
