import { useState, } from 'react';

import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import DueDiligenceRequestQuestionForm from '../../components/DueDiligenceRequestQuestionForm';


export default function DueDiligenceRequestQuestionList({ questions, readOnly, onSubmit, agent = null }) {
  const [selectedQuestions, setSelectedQuestions] = useState(new Set());


  function handleToggleQuestion(questionId) {
    setSelectedQuestions((_selectedQuestions) => {
      const selectedQuestions = new Set(_selectedQuestions);
      if (selectedQuestions.has(questionId)) {
        selectedQuestions.delete(questionId);
      } else {
        selectedQuestions.add(questionId);
      }
      return selectedQuestions;
    });
  }


  return (
    <List>
      {
        questions.map((question) => {
          const open = selectedQuestions.has(question.id);
          return (
            <Container
              key={question.id}
              maxWidth="xl"
              disableGutters
              component={Paper}
              style={{
                marginBottom: '1rem',
                borderRadius: 5,
              }}
              >
              <ListItem
                onClick={() => handleToggleQuestion(question.id)}
                style={{
                  cursor: 'pointer',
                  backgroundColor: open ? '#3B66FF' : 'transparent',
                  borderRadius: 5,
                }}
              >
                <ListItemText
                  primary={`${question.order}. ${question.question}`}
                  style={{
                    color: open ? 'white' : '#1C265A',
                  }}
                />
                { open ? <ExpandLess /> : <ExpandMore /> }
              </ListItem>

              <Collapse
                in={open}
                unmountOnExit
              >
                <DueDiligenceRequestQuestionForm
                  agent={agent}
                  readOnly={readOnly}
                  data={question}
                  onSubmit={(data) => {
                    if (undefined != onSubmit) {
                      onSubmit(question.id, data);
                    }
                  }}
                />
              </Collapse>
            </Container>
          );
        })
      }
    </List>
  );
}
