import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { Icon, } from 'rsuite';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { makeStyles } from '@material-ui/styles';
import TuneIcon from '@material-ui/icons/Tune';

import useModal from '../../hooks/useModal';
import {
  setItens,
  loadingTrue,
  loadingFalse,
  setModalTasks
} from '../../features/responderPerguntas';
import {
  getPerguntasOrdem,
  getPerguntasAdequadas,
  getPerguntaSemReposta,
  getPerguntasNaoAdequadas,
} from '../../services/api';


const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

export default function ActionButton() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, handleOpen, handleClose] = useModal();


  async function fetchQuestions(fetcher, ...args) {
    dispatch(loadingTrue());

    try {
      const { data, } = await fetcher(...args);
      dispatch(setItens(data));
    } catch {
      //TODO: show error
    }

    dispatch(loadingFalse());
  }

  const actions = [
    {
      icon: 'reply-all',
      name: 'Mostrar todas',
      onClick: async function getAllQuestions() {
        fetchQuestions(getPerguntasOrdem);
      },
    },
    {
      icon: 'question',
      name: 'Sem resposta',
      onClick: async function getUnansweredQuestions() {
        fetchQuestions(getPerguntaSemReposta, 1);
      },
    },
    {
      icon: 'check-square-o',
      name: 'Adequados',
      onClick: async function getCompliantQuestions() {
        fetchQuestions(getPerguntasAdequadas, 1);
      },
    },
    {
      icon: 'edit2',
      name: 'Revisão',
      onClick: async function getInReviewQuestions() {
        fetchQuestions(getPerguntasNaoAdequadas, 1);
      },
    },
    {
      icon: 'info',
      name: 'marcar todas como revisar',
      onClick: () => {
        dispatch(setModalTasks(true));
      }
    },
  ];


  return (
    <SpeedDial
      ariaLabel="Answer company diagnosis questions speed dial"
      className={classes.speedDial}
      icon={<TuneIcon />}
      onClick={handleOpen}
      onClose={handleClose}
      open={open}
      direction="up"
    >
      {
        actions.map((action, idx) => (
          <SpeedDialAction
            key={idx}
            icon={(
              <Icon
                icon={action.icon}
                size="lg"
              />
            )}
            tooltipTitle={(
              <span
                style={{
                  fontSize: '15px',
                }}
              >
                { action.name }
              </span>
            )}
            onClick={action.onClick}
          />
        ))
      }
    </SpeedDial>
  );
}
