import { Paper, Container } from "@material-ui/core";

export default function ContainerPapered({ children, elevation }) {
  return (
    <Container
      maxWidth="xl"
      component={Paper}
      elevation={elevation}
      disableGutters
    >
      <br />
      {children}
      <br />
    </Container>
  );
}
