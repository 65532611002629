import React from 'react';

import { Icon, } from 'rsuite';
import styled, { css, } from 'styled-components';

import Tooltip from '../Tooltip';
import FieldPreview from '../FieldPreview';
import { Typography } from '@mui/material';


const draggingActive = css`
  background: #b8ccc9;
  border-radius: 0.25rem;
  border: 1px solid #54708c;

  strong {
    color: #54708c;
  }
`;

const Container = styled.div.attrs({
  className: 'ActiveDrop',
})`
  max-width: 100%;
  height: 2.5rem;
  margin: 0;
  margin-bottom: 2px;
  border: none;
  border-radius: 0.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #54708c;

  strong {
    color: #fff;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    text-align: center;
    justify-content: center;
  }
  ${props => props.isActive && draggingActive}
`;


function formatDescription(description, name) {
  return <div>
    <div style={{ marginBottom: description ? '20px' : '0px' }}>
      Nome do campo: {name}
    </div>
    {description && `Descrição: ${description}`}
  </div>;
}


/**
 * Component use as the title of the field
 *
 * @param {Object} data - field data
 * @param {boolean} isDragging - if the user is dragging the field
 * @param {function} onDelete - callback called when user wants to delete field
 * @param {function} onEdit - callback called when user wants to edit field
 *
 * @returns Rendered field component
 */
export default function MappingField({ data, isDragging, onDelete, onEdit, }) {
  const showTooltip = (data.description != undefined) && (data.description != '');
  const iconStyle = {
    color: isDragging ? '#54708c' : 'white',
  };

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Container isActive={isDragging} >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ padding: '10px 10px', cursor: "pointer" }} onClick={onDelete}>
            <Icon
              icon="trash"
              style={iconStyle}
            />
          </div>
          <Tooltip
            arrow
            title={formatDescription(data?.description, data.name)}
            PopperProps={{
              modifiers: {
                offset: {
                  enabled: true,
                  offset: '0px, 0px'
                }
              }
            }}
            placement="top"
          >
            <Typography noWrap={true} color='#fff' sx={{ fontWeight: 500 }}>{data.name}</Typography>
          </Tooltip>{' '}
          <div style={{ padding: '10px 10px', cursor: "pointer" }} onClick={onEdit}>
            <Icon
              icon="edit2"
              style={iconStyle}
            />
          </div>
        </div>
      </Container>

      <FieldPreview field={data} />
    </div>
  );
}

