import React from 'react';

import LogoOctoImg from '../../../assets/octo-legal-logo.svg';
import { HeaderContainer, HeaderContent, LogoContainer, } from './StyleHeader';


export function HeaderGlobal() {
  return (
    <HeaderContainer>
      <HeaderContent>
        <LogoContainer>
          <a
            href="https://octo.legal"
            target="_blank"
          >
            <img
              className="LogoDecoration"
              src={LogoOctoImg}
              alt="Logo Octo"
            />
          </a>
        </LogoContainer>
      </HeaderContent>
    </HeaderContainer>
  );
}
