import React, { useState } from 'react'
import { Icon, } from 'rsuite';
import { Container, } from '@material-ui/core';

import SelectTypeReg from './SelectorTypeReg';
import Registration, { REGISTRATION_TYPES, } from './Registration';
import './styles.css';


export default function CreateNewAccount({ openLogin, }) {
  const [selectedType, setSelectedType] = useState(false);
  const [type, setType] = useState(REGISTRATION_TYPES.CONSULTANT);


  function handleSelectEntrepreneur() {
    setSelectedType(true);
    setType(REGISTRATION_TYPES.ENTREPRENEUR);
  }

  function handleSelectConsultant() {
    setSelectedType(true);
    setType(REGISTRATION_TYPES.CONSULTANT);
  }

  function onCancel() {
    setSelectedType(false);
  }


  return (
    <Container
      disableGutters
      maxWidth="xl"
    >
      <div
        onClick={openLogin}
        className="BoxIconReturn"
      >
        <Icon
          className="IconReturn"
          icon="angle-left"
          size="lg"
        />
        <p>Voltar</p>
      </div>

      {
        selectedType ?
        <Registration
          openLogin={openLogin}
          onCancel={onCancel}
          type={type}
        /> :
        <SelectTypeReg
          openSelectRender={handleSelectConsultant}
          openUnique={handleSelectEntrepreneur}
        />
      }
    </Container>
  );
}
