import React from 'react';
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';

import {
  Container,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  FormHelperText,
  Grid,
  IconButton
} from '@material-ui/core';
import {makeStyles,} from '@material-ui/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import { Button, } from 'rsuite';
import moment from 'moment';


const INITIAL_VALUES = Object.freeze({
  collaboratorId: 0,
  expiresAt: 7,
  sectors: [],
});

const useStyles = makeStyles({
  buttonContainer: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '0.1rem',
  },
  cancelBtn: {
    marginLeft: '1rem',
    borderColor: '#3B66FF',
    color: '#3B66FF !important',
    background: 'transparent !important',
    boxShadow: 'none',
    borderWidth: 2,
    border: '2px solid #3B66FF !important',
    '&:hover': {
      background: 'transparent !important',
      border: '2px solid #3B66FF !important',
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const InputProps = {
  disableUnderline: true,
};


function MaturityChecklistShareLinkForm({
  data,
  onSubmit,
  sectors,
  collaborators,
  isUpdate,
  onCancel,
  onCreateCollaborator,
}) {
  const classes = useStyles();
  const { t, } = useTranslation();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: submitMaturityChecklistShareLink,
    validationSchema: Yup.object({
      collaboratorId: Yup.number().required(_.capitalize(t('VALIDATION_MESSAGES.required', {
        context: 'male',
      }))).min(1, _.capitalize(t('VALIDATION_MESSAGES.required', {
        context: 'male',
      }))),
      expiresAt: Yup.number().required(_.capitalize(t('VALIDATION_MESSAGES.required', {
        context: 'male',
      }))).min(1, _.capitalize(t('VALIDATION_MESSAGES.must_have_at_least_one_day_of_validity'))),
      sectors: Yup.array().of(Yup.string()).required(_.capitalize(t('VALIDATION_MESSAGES.required', {
        context: 'male',
      }))).min(1, _.capitalize(t('VALIDATION_MESSAGES.select_at_least_one_sector'))),
    }),
  });

  const [loading, setLoading] = React.useState(false);

  /**
   * Save processing agent in API
   */
  async function submitMaturityChecklistShareLink(values) {
    setLoading(true);

    const expiresAt = moment.utc();
    expiresAt.add(values.expiresAt, 'day');

    const _data = {
      ...data,
      ...values,
      expiresAt: expiresAt.format('YYYY-MM-DD HH:mm:ss'),
    };

    if (await onSubmit(_data)) {
      formik.resetForm({
        values: INITIAL_VALUES,
      });
    }

    setLoading(false);
  }

  function _onCancel() {
    onCancel();
    formik.resetForm({
      values: INITIAL_VALUES,
    });
  }

  React.useEffect(() => {
    if (undefined != data) {
      const createdAt = moment(data.createdAt);
      const expiresAt = moment(data.expiresAt);
      formik.setValues({
        ...data,
        expiresAt: expiresAt.diff(createdAt, 'days'),
      });
    } else {
      formik.resetForm({
        values: INITIAL_VALUES,
      });
    }
  }, [data]);


  return (
    <Container
      disableGutters
      fullWidth
      maxWidth="xl"
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid
          item
          xs={true}
        >
          <FormControl
            fullWidth
            variant="filled"
            margin="dense"
            error={formik.errors.collaboratorId && formik.touched.collaboratorId}
            disabled={isUpdate}
          >
            <InputLabel
              id="collaborator-id-label"
            >
              { _.capitalize(t('collaborator', { count: 1, })) }
            </InputLabel>
            <Select
              labelId="collaborator-id-label"
              id="collaboratorId"
              disableUnderline
              {...formik.getFieldProps('collaboratorId')}
              MenuProps={MenuProps}
            >
              {collaborators.map((collaborator) => (
                <MenuItem
                  key={collaborator.id}
                  value={collaborator.id}
                >
                  {collaborator.name} - {collaborator.email}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {formik.touched.collaboratorId && formik.errors.collaboratorId}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid
          item
          xs="auto"
        >
          <IconButton
            aria-label="add collaborator"
            onClick={onCreateCollaborator}
            color="primary"
          >
            <AddCircleIcon />
          </IconButton>
        </Grid>
      </Grid>

      <TextField
        margin="dense"
        label={_.capitalize(t('PAGES.MATURITY_CHECKLIST_SHARE_LINKS.link_validity_days'))}
        fullWidth
        id="expiresAt"
        variant="filled"
        InputProps={InputProps}
        type="number"
        error={formik.errors.expiresAt && formik.touched.expiresAt}
        helperText={formik.touched.expiresAt && formik.errors.expiresAt}
        {...formik.getFieldProps('expiresAt')}
      />

      <FormControl
        fullWidth
        variant="filled"
        margin="dense"
        error={formik.errors.sectors && formik.touched.sectors}
      >
        <InputLabel
          id="sectors-label"
        >
          { _.capitalize(t('sector', { count: 2, })) }
        </InputLabel>
        <Select
          labelId="sectors-label"
          id="sectors"
          multiple
          disableUnderline
          {...formik.getFieldProps('sectors')}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  size="small"
                  color="primary"
                  key={value}
                  label={(sectors.find(({ id, }) => id == value) || {}).name}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {sectors.map((sector) => (
            <MenuItem
              key={sector.id}
              value={sector.id}
            >
              {sector.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {formik.touched.sectors && formik.errors.sectors}
        </FormHelperText>
      </FormControl>

      <Grid
        className={classes.buttonContainer}
      >
        <Button
          className={classes.saveButton}
          appearance="primary"
          loading={loading}
          onClick={formik.handleSubmit}
        >
          { _.capitalize(t('save')) }
        </Button>

        <Button
          appearance="primary"
          className={classes.cancelBtn}
          onClick={_onCancel}
          loading={loading}
        >
          { _.capitalize(t('cancel')) }
        </Button>
      </Grid>
    </Container>
  );
}

export default React.memo(MaturityChecklistShareLinkForm);
