import { useEffect, useState } from 'react';
import fileDownload from 'js-file-download';
import { useDispatch, useSelector, } from 'react-redux';
import moment from 'moment';
import { useTranslation, } from 'react-i18next';

import { Form } from 'react-bootstrap';
import { IconButton, Dialog, } from '@material-ui/core';
import { Message, Icon, Loader, Notification, Button, } from 'rsuite';

import ShareIcon from '@material-ui/icons/Share';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { setDocs, } from '../../features/docsSlice';
import { selectCompany, } from '../../features/companySlice';

import {
  getDocs,
  downloadFile,
  updateDocument,
  deleteDocument,
  getFileVisualizationLink,
} from '../../services/api';

import './DocsStyles.css';
import useModal from '../../hooks/useModal';

import DocumentViewer from '../DocumentViewer';
import Tooltip from '../MapaDados/Tooltip';

import { errorCodes, } from '../../errors/standard-error';


const FILE_OPTIONS = Object.freeze({
  'pdf': 'file-pdf-o',
  'plain': 'file-excel-o',
  'vnd.openxmlformats-officedocument.presentationml.presentation': 'file-powerpoint-o',
  'msword': 'file-word-o',
  'vnd.openxmlformats-officedocument.wordprocessingml.document': 'file-word-o',
  'octet-stream': 'file-text-o',
  'mp4': 'file-movie-o',
  'avi': 'file-movie-o',
  'jpeg': 'file-image-o',
  'jpg': 'file-image-o',
  'png': 'file-image-o',
  'javascript': 'file-code-o',
  'html': 'file-code-o',
});


export default function FilesHandler({ data, onShareClick, }) {
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const [nameFile, setNameFile] = useState(data.orginalname);
  const [name, setName] = useState('');
  const [openStatus, setOpenStatus] = useState(false);
  const [iconType, setIconType] = useState('file');
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editName, setEditName] = useState(false);

  const [viewerOpen, openViewerModal, closeViewerModal] = useModal();
  const [fileLink, setFileLink] = useState('');

  const dataType = data.type.split('/');


  const updateDocsState = async () => {
    try {
      const { data, } = await getDocs();
      dispatch(setDocs(data));
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar arquivos, tente novamente dentro de alguns instantes',
      });
    }
  }

  /**
   * Set effect to update file icon
   */
  useEffect(() => {
    setIconType(FILE_OPTIONS[`${dataType[1]}`] || 'file');
  }, []);

  const handleChange = (e) => {
    setName(e.target.value)
    setNameFile(e.target.value)
  }

  async function changeFileName() {
    if (!name) {
      return;
    }

    try {
      await updateDocument(company._id, data.id, {
        name: nameFile,
      });

      updateDocsState();

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Arquivo alterado!',
      });
    } catch (err) {
      setNameFile(data.orginalname);
      if ((undefined != err.response) && (undefined != err.response.data) &&
        (errorCodes.INVALID_FILE_ERROR == err.response.data.code)) {
        Notification['error']({
          placement: 'bottomEnd',
          title: t('ERRORS.DOCUMENT_MANAGEMENT.invalid_filename'),
        });
      } else {
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Falha ao alterar o nome do arquivo!',
          description: t('ERRORS.try_again_later'),
        });
      }
    }

    setEditName(false);
  }

  useEffect(() => {
    const timeoutId = setTimeout(changeFileName, 1300);
    return () => clearTimeout(timeoutId);
  }, [name]);

  function deleteFile() {
    setShow(true);
    deleteDocument(company._id, data.id)
      .then(() => {
        updateDocsState()
        setShow(false)
        if (window.location.pathname === '/Workshop')
          return window.location.reload()
      })
      .catch(() => {
        Notification['error']({
          placement: 'bottomEnd',
          title: 'Falha ao excluir arquivo, tente novamente dentro de alguns instantes',
        });
      })
  }

  /**
   * Request file from API
   */
  async function getArchive() {
    try {
      const response = await downloadFile(company._id, data.id);
      fileDownload(response.data, data.orginalname);
      Notification['success']({
        placement: 'bottomEnd',
        title: 'Arquivo baixado com sucesso!',
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao baixar arquivo, tente novamente dentro de alguns instantes',
      });
    }
    setOpenStatus(false);
  }

  function onDownloadFile() {
    setOpenStatus(true);
    getArchive();
  }

  async function __openViewer() {
    try {
      if (fileLink.length === 0) {
        let link = `https://s3.sa-east-1.amazonaws.com/octo.legal.images/${data.name_doc}`;

        if (!data.workshop) {
          const { data: resp, } = await getFileVisualizationLink(company._id, data.id);
          link = resp.fileLink;
        }

        setFileLink(link);
      }
      openViewerModal();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar arquivo, tente novamente dentro de alguns instantes',
      });
    }
  }

  function _onShare() {
    if (undefined != onShareClick) {
      onShareClick(data.id);
    }
  }


  const actionButtons = [
    {
      key: 'DOWNLOAD',
      onClick: onDownloadFile,
      icon: <GetAppIcon />,
      showInWorkshop: false,
    },
    {
      key: 'SHARE',
      onClick: _onShare,
      icon: <ShareIcon />,
      showInWorkshop: false,
    },
    {
      key: 'DELETE',
      onClick: deleteFile,
      icon: <DeleteOutlineIcon />,
      showInWorkshop: true,
    },
  ];


  return (
    <div className="cardDocs">
      <Dialog
        open={viewerOpen}
        onClose={closeViewerModal}
        PaperProps={{
          style: {
            width: '800px',
            maxWidth: '95%',
          },
        }}
      >
        <DocumentViewer
          fileLink={fileLink}
          fileType={data.type}
          googleRender
        />
      </Dialog>

      <div className="boxIntern">
        <div className="ContainerArchive">
          <div className="headerCardDocs">
            {
              editName ?
              <Form.Control
                style={{
                  maxHeight: 30,
                }}
                value={nameFile}
                onChange={handleChange}
              /> :
              <Tooltip
                arrow
                title={nameFile}
                PopperProps={{
                  modifiers: {
                    offset: {
                      enabled: true,
                      offset: '0px, 0px',
                    }
                  }
                }}
                placement="bottom"
              >
                <p onClick={() => setEditName(true)}>
                  {nameFile}
                </p>
              </Tooltip>
            }
            <IconButton
              onClick={() => setEdit(!edit)}
              size="small"
            >
              <InfoOutlinedIcon />
            </IconButton>
          </div>

          {
            show ?
            <Loader content="Deletando" /> :
            openStatus ?
            <Message description="Seu download começará em alguns instantes..." /> :
            !edit ?
            <Icon
              icon={iconType}
              size="5x"
              className="boxNameIcon"
            /> :
            <div className="ContainerDetailsDoc">
              <p>
                { `Tamanho: ${data.size ? data.size : 'Não definido'}` }
              </p>

              <p>
                Data de upload:
                <br />
                { data.data ? `${moment(data.data).format('DD/MM/YYYY ')}` : 'Não registrado' }
              </p>

              <p>
                Autor: { data.author ? data.author : 'Não registrado' }
              </p>
            </div>
          }

          <div className="button-container">
            {
              actionButtons.filter(({ showInWorkshop }) => showInWorkshop || !data.workshop).
                map(({ onClick, icon, key, }) => (
                  <IconButton
                    key={key}
                    onClick={onClick}
                    size="small"
                  >
                    { icon }
                  </IconButton>
                ))
            }
          </div>
        </div>

        <Button
          appearance="ghost"
          block
          onClick={__openViewer}
          style={{
            marginTop: '0.5rem',
          }}
        >
          Abrir visualização
        </Button>
      </div>
    </div>
  );
}
