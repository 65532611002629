import React from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import { Notification, } from 'rsuite';
import {
  Dialog,
  Container,
  Paper,
  Typography,
} from '@material-ui/core';

import LimitedBackdropLoader from '../../../components/LimitedBackdropLoader';
import DueDiligenceTemplateList from '../../../components/DueDiligenceTemplateList';

import useModal from '../../../hooks/useModal';

import {
  createGlobalDueDiligenceTemplate,
  listGlobalDueDiligenceTemplates,
  deleteGlobalDueDiligenceTemplate,
  updateGlobalDueDiligenceTemplate,
} from '../../../services/api';
import TemplateDialog from './TemplateDialog';


export default function GlobalDueDiligenceDialog({ open, onClose, }) {
  const { t, } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [templates, setTemplates] = React.useState([]);
  const [templateId, setTemplateId] = React.useState(null);
  const template = React.useMemo(() => {
    return templates.find((t) => t.id == templateId);
  }, [templateId, templates]);
  const [templateModelOpen, openTemplateModal, closeTemplateModal] = useModal(
    (id) => setTemplateId(id), () => setTemplateId(null));


  async function listTemplates() {
    try {
      const { data, } = await listGlobalDueDiligenceTemplates();
      setTemplates(data.templates);
    } catch (err) {
      console.debug(err);
    }
  }

  async function initialize() {
    setLoading(true);
    await listTemplates();
    setLoading(false);
  }

  React.useEffect(() => {
    if (open) {
      initialize();
    }
  }, [open]);

  async function createTemplate(data) {
    setLoading(true);
    try {
      const { data: template, } = await createGlobalDueDiligenceTemplate(data);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DUE_DILIGENCE.template_successfully_created')),
      });
      setTemplates([...templates, template]);
    } catch (err) {
      console.debug(err);
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_create_template')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
    setLoading(false);
  }

  async function updateTemplate(data) {
    setLoading(true);
    try {
      const { data: template, } = await updateGlobalDueDiligenceTemplate(data.id, data);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DUE_DILIGENCE.template_successfully_updated')),
      });
      const templateIdx = templates.findIndex((t) => t.id == template.id);
      if (templateIdx > -1) {
        const updatedTemplates = [...templates];
        updatedTemplates[templateIdx] = template;
        setTemplates(updatedTemplates);
      }
    } catch (err) {
      console.debug(err);
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_update_template')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
    setLoading(false);
  }

  async function deleteTemplate(templateId) {
    setLoading(true);
    try {
      await deleteGlobalDueDiligenceTemplate(templateId);
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DUE_DILIGENCE.template_successfully_deleted')),
      });
      setTemplates(templates.filter((t) => t.id != templateId));
    } catch (err) {
      console.debug(err);
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DUE_DILIGENCE.failed_to_delete_template')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
    setLoading(false);
  }


  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      fullWidth
    >
        <Container
          maxWidth="xl"
          component={Paper}
          style={{
            padding: '1rem',
          }}
          elevation={0}
        >
          <Typography
            variant="h6"
            style={{
              color: '#4D5884',
              fontFamily: 'Inter',
              marginTop: '0.3rem',
              marginBottom: '0.8rem',
            }}
          >
            {_.capitalize(t('due_diligence_template', { count: 2, }))}
          </Typography>

          <DueDiligenceTemplateList
            templates={templates}
            onCreate={createTemplate}
            onUpdate={updateTemplate}
            onDelete={deleteTemplate}
            onOpen={openTemplateModal}
          />
      </Container>

      <LimitedBackdropLoader open={loading} />

      <TemplateDialog
        template={template}
        open={templateModelOpen}
        onClose={closeTemplateModal}
      />
    </Dialog>
  );
}
