import { useState, useEffect } from 'react'
import { Dialog, DialogTitle } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { Icon } from 'rsuite'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'

import { InnerNotification } from '../../services/api'

import styles from './styleModalNotification.module.scss'

function Alert(props) {
  return (
    <MuiAlert elevation={6} variant="filled" {...props} />
  )
}

export default function ModalAddNewNotification({
  openModalNotification,
  onClose,
  getData,
}) {
  const id = localStorage.getItem('user')

  const [nameInvited, setNameInvited] = useState('')
  const [dateOfOccurrence, setDateOfOccurrence] = useState()
  const [timeOfOccurrence, setTimeOfOccurrence] = useState()
  const [localeOfOccurrence, setLocaleOfOccurrence] =
    useState('')
  const [problemOccurred, setProblemOccurred] = useState('')
  const [consequence, setConsequence] = useState('')
  const [uncovered, setUncovered] = useState('')
  const [resolvedOccurrence, setResolvedOccurrence] =
    useState('')
  const [mitigateRisck, setMitigateRisck] = useState('')

  const [openNotification, setOpenNotification] =
    useState(false)
  const [msgNotification, setMsgNotification] = useState('')
  const [severetNotification, setSeveretNotification] =
    useState('')

  //CHeckValidation

  const [validated, setValidated] = useState(false)

  const sendNotification = async (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      await InnerNotification(
        nameInvited,
        id,
        dateOfOccurrence,
        timeOfOccurrence,
        localeOfOccurrence,
        problemOccurred,
        consequence,
        uncovered,
        resolvedOccurrence,
        mitigateRisck
      )
        .then(() => {
          setOpenNotification(true)
          setSeveretNotification('success')
          setMsgNotification(
            'Notificação salva com sucesso!'
          )
          setTimeout(() => {
            onClose()
            getData()
          }, 2000)
        })
        .catch((error) => {
          setOpenNotification(true)
          setMsgNotification(
            `Erro ao enviar notificação!, ${error}`
          )
          setSeveretNotification('error')
          setTimeout(() => {
            setOpenNotification(false)
          }, 2000)
        })
    }

    setValidated(true)
  }

  return (
    <Dialog
      open={openModalNotification}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      className={styles.BoxModal}
    >
      <Form
        noValidate
        validated={validated}
        onSubmit={sendNotification}
      >
        <DialogTitle className={styles.TitleModalConfig}>
          Adicionar nova notificação de privacidade
          <Icon icon="close" onClick={onClose} />
        </DialogTitle>
        <div className={styles.BoxFormAll}>
          <div className={styles.box3Form}>
            <InputGroup
              hasValidation
              className={styles.InputName}
            >
              <div>
                <Form.Label>
                  Nome da pessoa que está relatando*
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setNameInvited(e.target.value)
                  }}
                  type="text"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Campo obrigatório.
                </Form.Control.Feedback>
              </div>
            </InputGroup>
            <InputGroup
              hasValidation
              className={styles.InputDate}
            >
              <div>
                <Form.Label>Data*</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setDateOfOccurrence(e.target.value)
                  }}
                  type="date"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Campo obrigatório.
                </Form.Control.Feedback>
              </div>
            </InputGroup>
            <InputGroup
              hasValidation
              className={styles.InputTime}
            >
              <div>
                <Form.Label>Hora*</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setTimeOfOccurrence(e.target.value)
                  }}
                  type="time"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Campo obrigatório.
                </Form.Control.Feedback>
              </div>
            </InputGroup>
          </div>
          <InputGroup hasValidation>
            <div>
              <Form.Label>
                Localização do incidente*
              </Form.Label>
              <Form.Control
                onChange={(e) => {
                  setLocaleOfOccurrence(e.target.value)
                }}
                type="text"
                required
              />
              <Form.Control.Feedback type="invalid">
                Campo obrigatório.
              </Form.Control.Feedback>
            </div>
          </InputGroup>
          <InputGroup hasValidation>
            <div>
              <Form.Label>Qual o problema*</Form.Label>
              <Form.Control
                onChange={(e) => {
                  setProblemOccurred(e.target.value)
                }}
                as="textarea"
                required
              />
              <Form.Control.Feedback type="invalid">
                Campo obrigatório.
              </Form.Control.Feedback>
            </div>
          </InputGroup>
          <InputGroup hasValidation>
            <div>
              <Form.Label>
                Qual o efeito do incidente?*
              </Form.Label>
              <Form.Control
                onChange={(e) => {
                  setConsequence(e.target.value)
                }}
                as="textarea"
                required
              />
              <Form.Control.Feedback type="invalid">
                Campo obrigatório.
              </Form.Control.Feedback>
            </div>
          </InputGroup>
          <InputGroup hasValidation>
            <div>
              <Form.Label>Como foi descoberto?*</Form.Label>
              <Form.Control
                onChange={(e) => {
                  setUncovered(e.target.value)
                }}
                as="textarea"
                required
              />
              <Form.Control.Feedback type="invalid">
                Campo obrigatório.
              </Form.Control.Feedback>
            </div>
          </InputGroup>
          <InputGroup hasValidation>
            <div>
              <Form.Label>
                Quais ações foram tomadas?*
              </Form.Label>
              <Form.Control
                onChange={(e) => {
                  setResolvedOccurrence(e.target.value)
                }}
                as="textarea"
                required
              />
              <Form.Control.Feedback type="invalid">
                Campo obrigatório.
              </Form.Control.Feedback>
            </div>
          </InputGroup>
          <InputGroup hasValidation>
            <div>
              <Form.Label>
                O que foi feito para evitar que isto ocorra
                novamente?*
              </Form.Label>
              <Form.Control
                onChange={(e) => {
                  setMitigateRisck(e.target.value)
                }}
                as="textarea"
                required
              />
              <Form.Control.Feedback type="invalid">
                Campo obrigatório.
              </Form.Control.Feedback>
            </div>
          </InputGroup>
        </div>
        <div className={styles.boxBTNModal}>
          <button
            className={styles.BTNCancel}
            onClick={onClose}
            type="button"
          >
            Cancelar
          </button>
          <button
            className={styles.BTNSubmit}
            onSubmit={sendNotification}
            type="submit"
          >
            Enviar
          </button>
        </div>
      </Form>
      <Dialog
        open={openNotification}
        onClose={() => {
          setOpenNotification(false)
        }}
        Backdrop={false}
        maxWidth="xs"
        fullWidth
      >
        <Alert
          style={{ margin: 20 }}
          severity={severetNotification}
        >
          {msgNotification}
        </Alert>
      </Dialog>
    </Dialog>
  )
}
