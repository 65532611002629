import React, { useEffect, useState, useRef, useContext } from "react";
import { useSelector, } from 'react-redux';

import { useThemeContext } from "../../../../context/ThemeAppContext";
import { AppBar as AppBarUi, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Image, } from 'react-bootstrap';
import { Icon, } from 'rsuite';
import Tab from "./Tab";
import { Context, } from "../../../../context/AuthContext";
import { selectCompany } from "../../../../features/companySlice";

import routesNavBar from "../../../NavBar/constants/routesConfig";
import logo from '../../../../assets/logo.svg';
import history from "../../../../history";

export default function AppBar({
  handleUserModalIsOpen,
  openCollaboratorsModal,
  openProcessingAgentsModal,
  openCompanySectorsModal,
  openCompanyCategoriesModal,
  onOpenPersonalDataTypesModal,
  onOpenSensitiveDataOptionModal,
  data: { ...data }
}) {
  const { stylesLayout, divMenu, divSubMenu, setHeightToobar, isMobile } = useThemeContext();
  const [selectedObjectMenu, setSelectedObjectMenu] = useState(routesNavBar(data?.companyId).Monitoramento);
  const [historySubMenu, setHistorySubMenu] = useState({});
  const [subMenuIsVisible, setSubMenuIsVisible] = useState(!!selectedObjectMenu?.subMenu);
  const ARRAY_ROUTES_NAVBAR = Object.values(routesNavBar(data?.companyId));
  const { HandleLogout, } = useContext(Context);
  const company = useSelector(selectCompany);

  const [initValueScroll, setInitValueScroll] = useState(true);

  const styleAppBar = styles({ stylesLayout, subMenuIsVisible });

  function resizeMenu() {
    if (!divMenu.current || !divSubMenu.current) {
      return;
    }
    setHeightMenu();
  }

  const handleScroll = () => {
    setSubMenuIsVisible(false);
    setHeightMenu();
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', resizeMenu);
    return () => {
      window.removeEventListener('resize', resizeMenu);
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    setHeightMenu();
  }, [selectedObjectMenu])

  function handlerHistoryMenu(Menu, subMenu) {
    setHistorySubMenu({ ...historySubMenu, [Menu]: { idSubMenu: subMenu } });
  }

  function setHeightMenu() {
    if (divSubMenu?.current?.offsetHeight && divMenu?.current?.offsetHeight) {
      setHeightToobar(divSubMenu?.current?.offsetHeight + 20 + divMenu?.current?.offsetHeight + 'px');
    } else {
      setHeightToobar(divMenu?.current?.offsetHeight + 20 + 'px');
    }
  }

  const MapMenu = () => {
    return (
      ARRAY_ROUTES_NAVBAR.map(({ icon, description, go, id, ...props }, index) => {
        return (
          <Tab key={index} icon={icon} title={description} go={go}
            setSelectedObjectMenu={() => { setSelectedObjectMenu(ARRAY_ROUTES_NAVBAR[index]); setSubMenuIsVisible(!!props?.subMenu); setInitValueScroll(false) }}
            isActive={selectedObjectMenu?.id === id}
          />
        )
      })
    )
  }

  function SubMenu({ keyMenu, data }) {
    const ARRAY_ROUTES_NAVBAR_SUBMENU = Object.values(data);
    return (
      <div ref={divSubMenu} className={styleAppBar.containerSubMenu} style={{ width: divMenu.current?.offsetWidth }}>
        {ARRAY_ROUTES_NAVBAR_SUBMENU.map(({ description, id, go }, index) => (
          <Typography
            onClick={() => { history.push(go); handlerHistoryMenu(keyMenu, id) }}
            key={index} name={id} variant="subtitle2"
            className={styleAppBar.itensSubMenu}
            style={{ textDecoration: historySubMenu?.[keyMenu]?.idSubMenu == id && "underline" }}>
            {description || "Workshop"}
          </Typography>
        ))}
      </div>
    )
  }

  return (
    <AppBarUi
      className={styleAppBar.appBar}
    >
      <Toolbar className={styleAppBar.containerMenu}>
        <div className={styleAppBar.contentContainer}>
          <Image src={logo} onClick={() => history.push(`/companies/${company._id}/Monitoramento`)} />
          <p className={styleAppBar.nameCompany}>{data?.companyName || ''}</p>
          <div className={styleAppBar.containerIcons}>
            <div onClick={() => history.push('/')} className={styleAppBar.containerSwitch}>
              <Icon
                icon="exchange"
                size="lg"
                className={styleAppBar.styleIcons}
                style={{ marginRight: '10px', color: stylesLayout.colors.textSecundary }}
              />
              <p style={{ marginRight: '20px' }}>Home</p>
            </div>
            <Icon
              icon="cog"
              onClick={() => history.push(`/companies/${data?.companyId}/config`)}
              size="lg"
              className={styleAppBar.styleIcons}
            />
            <Icon
              icon="sign-out"
              onClick={HandleLogout}
              size="lg"
              className={styleAppBar.styleIcons}
              style={{ margin: 0 }}
            />
          </div>
        </div>

        {/* //aqui se for menu web remover */}
        {!isMobile &&
          <div className={styleAppBar.containerTabs} ref={divMenu}>
            <MapMenu />
            {selectedObjectMenu?.subMenu &&
              <SubMenu keyMenu={selectedObjectMenu?.id} data={selectedObjectMenu?.subMenu} />
            }
          </div>
        }
      </Toolbar>
    </AppBarUi>
  )
}

const styles = makeStyles((theme) => ({
  appBar: {
    background: "var(--primary-color)",
    height: "auto",
    paddingTop: "10px",
    boxShadow: 'none',
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
    alignItems: 'center',
    width: "100%",
    marginBottom: "10px"
  },
  nameCompany: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: 22,
    color: 'var(--text-scondary-color)'
  },
  containerIcons: {
    display: "flex",
    flexDirection: "row"
  },
  styleIcons: {
    cursor: 'pointer',
    marginRight: '20px'
  },
  containerSwitch: {
    display: "flex",
    flexDirection: "row",
    cursor: 'pointer'
  },
  containerTabs: {
    display: "flex",
    flex: '1',
    flexDirection: "row",
    alignItems: 'center',
    flexWrap: "wrap",
    position: "relative"
  },
  containerSubMenu: {
    display: 'flex',
    background: '#dbdce1',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    position: "absolute",
    flexWrap: "wrap",
    top: "100%",
    height: props => props.subMenuIsVisible ? 'auto' : '0px',
    overflow: "hidden",
    transition: 'height 0.3s ease-in-out'
  },
  itensSubMenu: {
    display: "flex",
    fontWeight: "500",
    color: "#1C265A",
    padding: '10px 10px',
    cursor: "pointer",
    textDecoration: 'none',
    transition: 'text-decoration 0.3s ease-in-out',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  containerMenu: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
}));

