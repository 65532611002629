import React, { useState, useEffect, memo } from 'react';

import { useSelector, useDispatch, } from 'react-redux';
import Sheet from '../../components/Sheet';
import _, { values } from 'lodash';
import ActionPlan from '.';
import ActivityRank from './ActivityRank';
import PDCA from '../PDCA';
import CardMenuLeft from '../../components/Menus/CardMenuLeft';
import Dropdown from '../../components/Dropdown';
import FilterListIcon from "@mui/icons-material/FilterList";
import Trash from '../../components/Icons/Trash';
import { Close } from "@mui/icons-material"

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Button
} from '@material-ui/core';
import { useStyles } from '../../components/NavBar/Styles';

import {
  setCurrentMeeting,
  setCurrentSector,
  setSelectedFilters,
  setNewLineFilter,
  setValueFilter,
  removeLineFilter,
  removeAllFilters
} from "../../features/planAction";

import { setItens, updateTitle, } from '../../features/matrizDeRisco';
import { selectCompany, } from '../../features/companySlice';

import { ACTION_STATUSES, } from '../../core/entities/action-plans';
import { TASK_STATUSES, } from '../../core/entities/checklist-tasks';

import {
  listCompanyMeetings,
  notReunion,
  getRiscoNotResponsible,
  getRiscoByReunionID,
  listCompanyActions,
  getRisco,
  listCompanyUsers,
} from '../../services/api';
import DefaultModal from '../../components/Modals/DefaultModal/DefaultModal';
import SelectSetor from '../../components/Selects/SelectSetor/SelectSetor';
import SelectorMeetings from '../../components/Selects/SelectorMeetings/SelectorMeetings';
import SelectUsers from '../../components/Selects/SelectUsers/SelectUsers';
import { Badge, Chip } from '@mui/material';

const ActionPlanPending = () => <ActionPlan progress="PENDING" />;
const ActionPlanInProgress = () => <ActionPlan progress="IN_PROGRESS" />;
const ActionPlanClossed = () => <ActionPlan progress="CLOSED" />;

const MenuLeft = memo(() => (
  <div style={{ margin: '0px 20px 0px 0px' }}>
    <ActivityRank />
  </div>
))

const INITIAL_VALUES = Object.freeze({
  [ACTION_STATUSES.PENDING]: {
    values: ['Pendente', null],
    total: 0,
  },
  [ACTION_STATUSES.IN_PROGRESS]: {
    values: ['Em andamento'],
    total: 0,
  },
  [ACTION_STATUSES.CLOSED]: {
    values: ['Concluido'],
    total: 0,
  },
});

export default function MapPlanoDeAcao() {

  const [actionsStatuses, setActionsStatuses] = useState(INITIAL_VALUES);
  const [itemMenu, setItemMenu] = useState(null);
  const [riskAcepted, setRiskAcepted] = useState(null);
  const [modalFilter, setModalFilter] = useState(false);
  const [users, setUsers] = useState(null);
  const actions = useSelector((state) => state.matriz.value);
  const meetings = useSelector((state) => state.reunions.reunions);
  const planAction = useSelector((state) => state.planAction);
  const company = useSelector(selectCompany);
  const dispatch = useDispatch();
  const classes = useStyles();

  function filterActions() {
    const updatedActionsStatuses = _.cloneDeep(actionsStatuses);
    for (const actionStatus of Object.values(updatedActionsStatuses)) {
      actionStatus.total = (actions.filter((item) => {
        return (item.resposta !== 'Aceitar') && actionStatus.values.includes(item.status);
      })).length;
    }
    setActionsStatuses(updatedActionsStatuses);
  }

  async function fetchRisckAcepted() {
    await getRisco(true, 1).then(({ data }) => {
      setRiskAcepted(data.pdcaAceite)
    })
  }

  async function getUsersCompany() {
    const response = await listCompanyUsers(company._id);
    setUsers(response.data);
  }

  useEffect(() => {
    filterActions();
    fetchRisckAcepted();
  }, [actions]);

  useEffect(() => {
    getUsersCompany();
    return () => {
      dispatch(setCurrentSector(''));
    }
  }, []);

  const PAGES = Object.freeze({
    PDCA: {
      id: 'PDCA',
      titleTab: "PDCA",
      titlePage: "PDCA",
      component: <PDCA />,
      badge: riskAcepted?.length || '0'
    },
    concluido: {
      id: 'concluido',
      titleTab: "Concluído",
      titlePage: "Concluído",
      component: <ActionPlanClossed />,
      // badge: actionsStatuses?.['CLOSED']?.total || '0'
      badge: planAction.totalClosedItens
    },
    emandamento: {
      id: 'emandamento',
      titleTab: "Em andamento",
      titlePage: "Em andamento",
      component: <ActionPlanInProgress />,
      // badge: actionsStatuses?.['IN_PROGRESS']?.total || '0'
      badge: planAction.totalInProgress
    },
    pendente: {
      id: 'pendente',
      titleTab: "Pendente",
      titlePage: "Pendente",
      component: <ActionPlanPending />,
      // badge: actionsStatuses?.['PENDING']?.total || '0'
      badge: planAction.totalPending
    },
  });

  const ARRAY_PAGES = Object.values(PAGES);

  function calbackItemMenu(itemMenu) {
    setItemMenu(itemMenu);
  }

  /**
   * Fetch all actions from the API
   */
  async function getAllActions() {
    const { data, } = await listCompanyActions(company._id, {
      taskStatus: [TASK_STATUSES.IN_PROGRESS, TASK_STATUSES.CLOSED],
    });
    const filtered = data.actions;
    updateActions(filtered, 'Itens para revisão');
  }

  /**
 * Update actions array and other related states
 *
 * @param {array} actions - updated actions array
 * @param {string} newTitle - updated title
 * @param {number | null} meetingId - meeting ID
 */
  function updateActions(actions, newTitle, meetingId = null) {
    dispatch(updateTitle(newTitle));
    dispatch(setItens(actions));
    dispatch(setCurrentMeeting(meetingId));
  }

  /**
  * Handle the change of the current meeting
  *
  * @param {number} meetingId - meeting ID
  */
  const onCurrentMeetingChange = async (meetingId) => {
    const isValid = !(_.isString(meetingId) && meetingId.length === 0);
    if (isValid) {
      //pode existir mais de ma reunião setada.
      const { data, } = await getRiscoByReunionID(meetingId);
      //posso colocar aqui o filtro depois de pegar o data


      updateActions(data, 'Itens de reunião', meetingId);
    } else {
      getAllActions();
    }
  }

  function handlerSetTypeFilter(index, type) {
    dispatch(setSelectedFilters({
      index,
      type
    }));
  }

  function hadlerSetValueFilter(index, value, label) {

    console.log(index, value);

    dispatch(setValueFilter({
      index,
      value,
      label
    }));
  }

  function SelectReunion({ obj, index }) {

    const [type, setType] = useState(obj.type);
    const currentValue = planAction?.selectedFilters[index].value;

    return (
      <Box style={{ display: "flex", marginBottom: 20, flexDirection: "column" }}>

        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap' }}>

          <Dropdown
            defaultItem="Escolha um filtro"
            items={planAction.filters}
            value={type}
            labelKey="title"
            setValue={(type) => { handlerSetTypeFilter(index, type), setType(type) }}
          />


          {type &&
            <div style={{ flexDirection: 'row', display: 'flex' }}>

              {type === 'SETOR' &&
                <SelectSetor value={currentValue} setValue={(value) => hadlerSetValueFilter(index, value)} />
              }
              {type === 'REUNIAO' &&
                <SelectorMeetings
                  items={meetings}
                  value={currentValue}
                  setValue={(value) => { hadlerSetValueFilter(index, value) }}
                />
              }
              {type === 'RESPONSAVEL' &&
                <SelectUsers
                  items={users}
                  value={currentValue}
                  setValue={(value) => hadlerSetValueFilter(index, value)}
                />
              }
              <div onClick={() => dispatch(removeLineFilter(index))} style={{ paddingLeft: 10, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <Trash />
              </div>
            </div>
          }
        </div>
      </Box>
    );
  }

  function ComponentActiveFilter() {


    function agroupFiltersType(filters) {
      const totalFilters = filters.reduce((acc, item) => {
        if (item.type === null || item.value === '' || item.value === null) return acc;
        const tipo = item.type;
        if (!acc[tipo]) {
          acc[tipo] = [];
        }
        acc[tipo].push(item.value);
        return acc;
      }, {});

      let total = 0;

      for (const key in totalFilters) {
        if (Array.isArray(totalFilters[key])) {
          total += totalFilters[key].length;
        }
      }

      return total || 0
    }

    return (

      // @aqui
      <div style={{ display: "flex", flexDirection: "row-reverse", position: "absolute", right: 0 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {agroupFiltersType(planAction.selectedFilters) > 0 &&
            <div onClick={() => dispatch(removeAllFilters())} style={{ background: "#fff", cursor: 'pointer' }} >
              <Close fontSize="small" color='primary' />
            </div>
          }
        </div>
        <Badge badgeContent={agroupFiltersType(planAction.selectedFilters)} color="primary">
          <Box onClick={() => setModalFilter(true)} style={{ background: '#fff', padding: '5px 10px', cursor: 'pointer', display: 'flex' }}>
            <Typography style={{ fontWeight: 500, marginRight: 10 }}>Filtro</Typography>
            <FilterListIcon fontSize="small" />
          </Box>
        </Badge>
      </div >
    )
  }


  return (
    <>
      <Sheet pages={ARRAY_PAGES} outsideLeftComponent={<MenuLeft />} outsideRightComponent={<ComponentActiveFilter />} calbackItemMenu={calbackItemMenu} />
      <DefaultModal open={modalFilter} onClose={() => setModalFilter(false)} maxWidth='sm' title="Filtro">
        <Box style={{ padding: "20px", minHeight: 300 }}>
          {planAction.selectedFilters.map((obj, index) => <SelectReunion obj={obj} index={index} />)}
        </Box>
        <Box style={{ padding: 20 }}>
          <Button onClick={() => dispatch(setNewLineFilter())} variant="outlined" size="small">
            Adicionar + filtros
          </Button>
        </Box>
      </DefaultModal>
    </>
  );
}
