import React, { useEffect, useState } from "react";
import { Box, ButtonBase, Grid, Paper, Tooltip, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Trash from "../Icons/Trash";
import Pin from "../Icons/Pin";
import Pointers from "../Icons/Pointers";
import Ajust from "../Icons/Ajust";
import Plus from "../Icons/Plus";
import Eye from "../Icons/Eye";
import Arrow from "../Icons/Arrow";
import Check from "../Icons/Check";
import Book from "../Icons/Book";
import FourArrow from "../Icons/FourArrow";
import { Icon } from "rsuite";
import { getUserShortcut, deletUserShortcut } from "../../services/api";
import UpdateMenus from "../Modals/UpdateMenus/UpdateMenus";
import history from "../../history";
import routesMenuFixed from "../NavBar/constants/routesMenuFixed";
import { setModalCreateNewTaskIsVisible } from "../../features/perguntasSlice";
import { setModalCreateNewTraining } from "../../features/trainings";

import { useSelector, useDispatch } from 'react-redux';
import { replaceMenus, setModeMenu, setModalIsOpen, setMenuRigthIsVisible, setMenuLeftIsVisible } from "../../features/fixedMenuPages";
import { setModalReunion } from "../../features/reunionsSlice";

export default function CustomMenuFixed({ itensLeft }) {
	const stateReduxMenu = useSelector((state) => state.fixedMenuPages);
	const companyId = useSelector((state) => state.company.company._id);
	const dispatch = useDispatch();
	const [miniMenuOpen, setMiniMenuOpen] = useState(false);
	const [itensMenuLeft, setItensMenuLeft] = useState(itensLeft ? Object.values(itensLeft) : null);
	const menuArray = Object.values(routesMenuFixed(companyId));

	const menuIsActive = (key) => {
		const menuIsActive = stateReduxMenu.rigthMenu.indexOf(key);
		return menuIsActive !== -1;
	}

	useEffect(() => {
		getUserShortcut().then(res => {
			dispatch(replaceMenus(res.data));
			setItensMenuLeft(itensLeft ? Object.values(itensLeft) : null);
		});
	}, [stateReduxMenu.menuLeftName]);

	const className = styles({ miniMenuIsOpen: miniMenuOpen });


	async function fetchDeletShortcut(key) {
		const postData = {
			type: key
		}
		const { data } = await deletUserShortcut(postData);
		dispatch(replaceMenus(data));
	}

	function handlerNaigationMenuBootom(id, onClick) {
		switch (id) {
			case 'CREATE_MEETING':
				dispatch(setModalReunion(true))
				break;
			case 'NEW_TASK':
				dispatch(setModalCreateNewTaskIsVisible(true))
				break;
			case 'NEW_COURSE':
				onClick()
				dispatch(setModalCreateNewTraining(true))
				break;
			default:
				onClick()
				break;
		}
	}


	const LineMenus = React.memo(({ id, title, icon, onClick }) => {
		return (
			<Box style={{ cursor: "pointer" }}>
				<Grid
					container
					direction="row"
					className={className.lineItem}
				>
					<Grid
						container
						direction="row"
					>
						<Grid item xs>
							<Box onClick={onClick}>
								<Typography variant="subtitle2" component="h2">{title}</Typography>
							</Box>
						</Grid>
						<Grid item className={className.iconFile}>
							{icon ? icon : <Box onClick={() => fetchDeletShortcut(id)} style={{ fontWeight: '600', fontSize: "15px" }}>x</Box>}
						</Grid>
					</Grid>
				</Grid>
			</Box>
		)
	});

	const FooterRigth = React.memo(() => {
		return (
			<Box style={{ background: "#fff", borderTopRightRadius: stateReduxMenu.menuRigthIsVisible ? 'auto' : '20px' }}>
				{stateReduxMenu.menuRigthIsVisible && <Divider style={{ width: "100%", background: "#D0DFED" }} />}
				<Box style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", padding: '5px 8px', alignItems: "center" }}>
					<Box onClick={() => dispatch(setMenuRigthIsVisible(!stateReduxMenu.menuRigthIsVisible))} style={{ background: '#D9D9D9', width: '30px', height: '30px', display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "15px", cursor: "pointer" }}>
						<Pointers />
					</Box>
					<Box style={{ marginLeft: '5px', marginBottom: "3px", cursor: "pointer" }} onClick={() => dispatch(setModeMenu())}>
						{stateReduxMenu.menuRigthIsVisible ? <Pin /> : <></>}
					</Box>
				</Box>
			</Box>
		)
	});

	const FooterLeft = React.memo(() => {
		return (
			<Box style={{ background: "#D6E3EF", borderTopLeftRadius: stateReduxMenu.menuLeftIsVisible ? 'auto' : '20px' }}>
				<Box style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", padding: '5px 8px', alignItems: "center" }}>
					<Box onClick={() => dispatch(setMenuLeftIsVisible(!stateReduxMenu.menuLeftIsVisible))} style={{ background: '#3B66FF', width: '30px', height: '30px', display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "15px", cursor: "pointer" }}>
						<Ajust />
					</Box>
				</Box>
			</Box>
		)
	});

	const BodyMenuRigth = React.memo(() => {
		return (
			<Box style={{ width: "250px", color: '#1C265A', paddingTop: "10px", background: "#fff", height: "100%" }}>
				<Box style={{ padding: '0px 10px', background: "#fff", paddingBottom: "10px" }}>
					<Box onClick={() => dispatch(setModalIsOpen(true))} style={{ marginBottom: '10px', marginRight: '10px', marginTop: "10px", cursor: "pointer", display: "flex", flexDirection: "row-reverse" }}><Plus /></Box>
					{menuArray.map(({ id, title, icon, onClick }) => {
						return (
							menuIsActive(id) ? <LineMenus key={id} id={id} title={title} icon={icon} onClick={onClick} /> : <></>
						)
					})}

				</Box>
			</Box>
		)
	});

	const BodyMenuLeft = React.memo(() => {
		return (
			<Box style={{ width: "250px", color: '#1C265A', height: "100%", background: '#D6E3EF', paddingTop: "10px" }}>
				<Box style={{ padding: '0px 10px', background: "#D6E3EF" }}>
					{itensMenuLeft?.map(({ id, title, icon, onClick }) => {
						return (
							<LineMenus key={id} id={id} title={title} icon={icon} onClick={onClick} />
						)
					})}

				</Box>
				<Divider style={{ width: "100%", background: "#D6E3EF" }} />
			</Box>
		)
	});

	const CardMenu = React.memo(({ header, body, footer }) => {
		return (
			<Box style={{ display: "flex", flexDirection: "column", flex: '1' }}>
				{header && header}
				<Box style={{ flex: '1' }}>
					{body && body}
				</Box>
				{footer && footer}
			</Box>
		)
	});


	const MenuModeList = React.memo(() => {
		return (
			<Box
				className={className.row}
			>
				{stateReduxMenu.menuRigthIsVisible && <CardMenu body={stateReduxMenu.menuLeftIsVisible ? <BodyMenuLeft /> : <></>} footer={<FooterLeft />} />}
				<CardMenu body={stateReduxMenu.menuRigthIsVisible ? <BodyMenuRigth /> : <></>} footer={<FooterRigth />} />
			</Box>
		)
	});

	const SimpleLine = React.memo(({ id, title, icon, onClick }) => {
		return (
			<Box style={{ cursor: "pointer", maxWidth: '200px' }}>
				<Grid
					container
					direction="row"
				>
					<Box
						style={{ flexDirection: "row", display: "flex", alignItems: "center" }}
					>
						<Box onClick={onClick}>
							<Typography variant="subtitle2" component="h2" style={{ whiteSpace: "nowrap", color: "#1C265A" }}>{title}</Typography>
						</Box>
						<Box className={className.iconFile} style={{ marginBottom: "2px", marginLeft: '10px', marginRight: "40px" }}>
							{icon ? icon : <Box onClick={() => fetchDeletShortcut(id)} style={{ fontWeight: '600', fontSize: "15px", color: "#000" }}>x</Box>}
						</Box>
					</Box>
				</Grid>
			</Box>
		)
	});

	const FooterLeftLine = React.memo(() => {

		return (
			<Box style={{ display: "flex", flexDirection: "row-reverse" }}>
				<Box style={{ background: "#D9D9D9", width: stateReduxMenu.menuLeftIsVisible ? "100%" : 'auto', borderTopLeftRadius: '20px' }}>
					<Box style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", padding: '5px 8px', alignItems: "center" }}>
						<Box onClick={() => dispatch(setMenuLeftIsVisible(!stateReduxMenu.menuLeftIsVisible))} style={{ background: '#fff', width: '30px', height: '30px', display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "15px", cursor: "pointer" }}>
							<Ajust />
						</Box>
						{stateReduxMenu.menuLeftIsVisible &&
							<Box style={{ padding: '0px 10px', background: "transparent", flexDirection: "row", display: "flex" }}>
								{itensMenuLeft?.map(({ id, title, icon, onClick }) => {
									return (
										<SimpleLine key={id} id={id} title={title} icon={icon} onClick={onClick} />
									)
								})}
							</Box>
						}
					</Box>
				</Box>
			</Box>
		)
	});

	const FooterRigthLine = React.memo(() => {
		return (
			<Box style={{ display: "flex", flexDirection: "row-reverse" }}>
				<Box style={{ background: "#D6E3EF", width: stateReduxMenu.menuRigthIsVisible ? "100%" : 'auto', borderBottomLeftRadius: '20px' }}>
					<Box style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", padding: '5px 8px', alignItems: "center" }}>
						<Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
							{stateReduxMenu.menuRigthIsVisible && <Box onClick={() => dispatch(setModalIsOpen(true))} style={{ marginBottom: '5px', marginRight: '10px', cursor: "pointer" }}><p style={{ color: "#000", fontWeight: '600', fontSize: "20px" }}>+</p></Box>}
							<Box onClick={() => dispatch(setMenuRigthIsVisible(!stateReduxMenu.menuRigthIsVisible))} style={{ background: '#3B66FF', width: '30px', height: '30px', display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "15px", cursor: "pointer" }}>
								<Pointers />
							</Box>
						</Box>
						{stateReduxMenu.menuRigthIsVisible &&
							<>
								<Box style={{ padding: '0px 10px', background: "transparent", flexDirection: "row-reverse", display: "flex" }}>
									{menuArray.map(({ id, title, icon, onClick }) => {
										return (
											menuIsActive(id) ? <SimpleLine key={id} id={id} title={title} icon={icon} onClick={() => handlerNaigationMenuBootom(id, onClick)} /> : <></>
										)
									})}
								</Box>
								{/* <Box style={{ marginLeft: '5px', marginBottom: "3px", cursor: "pointer" }} onClick={() => dispatch(setModeMenu())}>
									<Pin />
								</Box> */}
							</>
						}
					</Box>
				</Box>
			</Box>
		)
	});

	const MenuModeLine = React.memo(() => {
		return (
			<Box>
				{(stateReduxMenu.menuRigthIsVisible && itensLeft) && <CardMenu footer={<FooterLeftLine />} />}
				<CardMenu footer={<FooterRigthLine />} />
			</Box>
		)
	});

	return (
		<>
			<Box className={className.container}>
				<Box className={className.containerListMenu}>
					{stateReduxMenu.modeLine ? <MenuModeLine /> : <MenuModeList />}
				</Box>
			</Box >
			<UpdateMenus open={stateReduxMenu.modalIsOpen} />
		</>
	)
}


const styles = makeStyles((theme) => ({
	container: {
		background: "#4e4e4e",
		position: "fixed",
		bottom: '5px',
		right: '0px',
		marginRight: '5px',
		borderTopLeftRadius: '5px',
		borderTopRightRadius: '5px',
		color: "#fff",
		zIndex: "10"
	},
	containerListMenu: {
		position: 'absolute',
		bottom: '100%',
		right: '0px',
		borderRadius: '20px',
		overflow: "hidden",
		color: "#fff",
		transform: (props) => !props.miniMenuIsOpen ? 'scale(1)' : 'scale(0)'
	},
	row: {
		display: "flex",
		flexDirection: "row"
	},
	lineItem: {
		padding: '10px 0px',
	},
	iconFile: {
		marginRight: '10px'
	},
}));
