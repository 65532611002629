import { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Icon, Input, InputGroup } from 'rsuite'
import { useDispatch, } from 'react-redux';
import {
  setItens,
  loadingFalse,
  loadingTrue,
  updateTitle,
} from '../../features/matrizDeRisco'
import { searchRisco, } from '../../services/api';


export default function RiskMatrixHeader() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [widthSearch, setWidthSearch] = useState('400px')


  /**
   * Search questions
   *
   * @param {object} e - event with the search string to use
   */
  const searchQuestion = async (e) => {
    setLoading(true)
    dispatch(loadingTrue())
    localStorage.setItem('currentSearchMatriz', e.target.value);

    await searchRisco(e.target.value).then(({ data }) => {
      dispatch(updateTitle(`Resultado da pesquisa`))
      setLoading(false)
      dispatch(setItens(data))
      dispatch(loadingFalse())
    })
  }

  /**
   * Use effect to update the width of the search field
   */
  useEffect(() => {
    if (window.screen.width <= 1370) {
      setWidthSearch('280px')
    } else if (window.screen.width <= 1200) {
      setWidthSearch('180px')
    } else {
      setWidthSearch('400px')
    }
  });


  return (
    <Grid
      container
      direction="row"
      alignItems="center "
      justifyContent="space-evenly"
    >
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 30,
        }}
        justifyContent="space-between"
      >
        <Typography
          variant="h4"
          style={{
            width: '30%',
            color: '#4D5884',
          }}
        >
          Matriz de Risco{' '}
        </Typography>

        <InputGroup
          inside
          style={{
            width: `${widthSearch}`,
          }}
          onChange={searchQuestion}
        >
          <Input
            placeholder="Pesquisar"
            type="search"
          />
          <InputGroup.Button loading={loading}>
            <Icon icon="search" />
          </InputGroup.Button>
        </InputGroup>
      </Grid>
    </Grid>
  )
}
