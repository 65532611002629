import React from 'react';
import { useTranslation, } from 'react-i18next';

import { Notification, } from 'rsuite';

import styled from 'styled-components';


const Container = styled.div`
  width: 100%;
  height: 15%;
  border-radius: 0.75rem;
  background: transparent;
  align-items: center;
  justify-content: center;

  input {
    display: none;
    height: 100%;
    width: 100%;
  }

  label {
    height: 15%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(59, 102, 255);
    font-weight: bold;
    font-size: 13px;
    cursor: pointer;

    span {
      font-family: Inter;
      font-weight: normal;
      font-size: 11px;
      color: #54708C;
    }
}`;


function FileUploadInput({
  id = 'file-input',
  checkFileSize = true,
  maxFileSize = 10485760,
  children,
  label,
  onChange,
}) {
  const { t, } = useTranslation();


  async function _onChange(event) {
    if ((null == event.target.files) || (undefined == event.target.files[0]) ||
      (checkFileSize && event.target.files[0].size > maxFileSize)) {
      Notification['error']({
        placement: 'bottomEnd',
        title: t('ERRORS.FILE_UPLOAD.invalid_file_title'),
        description: t('ERRORS.FILE_UPLOAD.invalid_file_description'),
      })
      return;
    }

    const file = event.target.files[0];
    onChange(file);
  }


  return (
    <Container>
      <label htmlFor={id}>
        { children }
        { label }
      </label>
      <input
        id={id}
        type="file"
        onChange={_onChange}
        onClick={(ev) => {
          ev.target.value = null;
        }}
      />
    </Container>
  );
}

export default FileUploadInput;
