import React, { useState, useEffect } from "react";
import { useTranslation, } from 'react-i18next';
import _ from 'lodash';
import { showToast } from "../../ui-lib/toast";
import "react-toastify/dist/ReactToastify.css";
import { Icon, Message, } from "rsuite";
import { Card, Form } from "react-bootstrap";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";

import api from "../../services/api";
import QuestionItem from '../QuestionItem';

import { DEFAULT_QUESTION_CATEGORIES, } from '../../utils/constants';


const EditQuestion = ({ data: _data, provided, index }) => {
  const { t, } = useTranslation();
  const [ordem, setOrdem] = useState(_data.ordem);
  const [data, setData] = useState(_data);

  const timer = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [openModalQuestion, setOpenModalQuestion] = useState(false);
  const [openModalConfirmDelet, setOpenModalConfirmDelet] = useState(false);


  function refreshPage(info) {
    window.location.reload(info);
  }

  function handleChange(ev) {
    setData({
      ...data,
      [ev.target.name]: ev.target.value,
    });
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (!loading) {
      api.post("/pergunta/update/template", {
          ordem: index,
          ...data,
        })
        .then(() => {
          setLoading(true);
          timer.current = window.setTimeout(() => {
            setLoading(false);
          }, 2000);
        })
        .catch((response) => {
          return showToast({
            type: "error",
            message: "Erro ao criar a análise de risco",
          });
        });
    }
  };

  const handleButtonClickDelete = () => {
    if (!loading) {
      api.post("/template/update/delete", {
          id: data.id,
        })
        .then(() => {
          setLoading(true);
          timer.current = window.setTimeout(() => {
            setLoading(false);
            refreshPage();
          }, 2000);
        })
        .catch(({ error }) => {
          return showToast({
            type: "error",
            message: "Erro ao deletar a análise de risco",
          });
        });
    }
  };

  function handleClose() {
    setOpenModalQuestion(!true);
  }

  function handleCloseAlertDelet() {
    setOpenModalConfirmDelet(!true);
  }

  useEffect(() => {
    if (index !== ordem) {
      api.post('/update/ordem/template', {}, {
        params: {
          id: data.id,
          ordem: index,
        },
      });
      setOrdem(index);
    }
  }, [index]);


  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      className="boxAllQuestions"
    >
      <Dialog
        open={openModalConfirmDelet}
        onClose={handleCloseAlertDelet}
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: '500px',
            maxWidth: '90%',
          },
        }}
      >
        {loading && (
          <div className="progressDelet">
            <CircularProgress />
          </div>
        )}
        {!loading && (
          <>
            <DialogTitle className="boxTitleAlert" id="alert-dialog-title">
              <Message
                showIcon
                type="error"
                description="Excluir de forma permanente a pergunta?"
              />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {data.problema}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAlertDelet}>{_.capitalize(t('cancel'))}</Button>
              <Button onClick={handleButtonClickDelete}>Excluir</Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Dialog
        open={openModalQuestion}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '800px',
            maxWidth: '90%',
          },
        }}
      >
        {loading && (
          <div className="progressDelet">
            <CircularProgress />
          </div>
        )}

        {!loading && (
          <>
            <div className="HeaderTitleGestao">
              <div className="itemIndexModal">Item {index + 1}</div>
              <div className="TitlePerguntaGestao">{data.problema}</div>

              <div className="boxLeftGestao">
                <div className="setorAcao">{data.setor}</div>
              </div>
              <Icon
                className="iconCloseGestao"
                onClick={handleClose}
                icon="close"
              />
            </div>
            <form>
              <Card.Body>
                <div>
                  <label for="pergunta2" className="labelPergunta">
                    {_.capitalize(t('question'))}
                  </label>
                  <Form.Control
                    as="textarea"
                    value={data.problema}
                    id="pergunta2"
                    name="problema"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label for="pergunta2" className="labelPergunta">
                    {_.capitalize(t('risk_consequence'))}
                  </label>
                  <Form.Control
                    as="textarea"
                    value={data.risco}
                    name="risco"
                    id="pergunta2"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label for="pergunta2" className="labelPergunta">
                    {_.capitalize(t('action'))}
                  </label>
                  <Form.Control
                    as="textarea"
                    value={data.acao}
                    id="pergunta2"
                    name="acao"
                    onChange={handleChange}
                  />
                </div>
              </Card.Body>
              <div className="boxOptionsModalGestao">
                <div className="boxSelectModal">
                  <label for="select-question-sector" className="labelPergunta">
                    {_.capitalize(t('sector'))}
                  </label>
                  <div id="select-question-sector">
                    <Form.Control
                      className="selectContent"
                      as="select"
                      defaultValue={_.capitalize(t('PAGES.EDIT_QUESTION_TEMPLATE.choose_a_sector'))}
                      value={data.setor}
                      name="setor"
                      onChange={handleChange}
                    >
                      <option>Escolha um setor</option>
                      <option>Administrativo</option>
                      <option>Juridico</option>
                      <option>TI</option>
                      <option>RH</option>
                      <option>Outro</option>
                    </Form.Control>
                  </div>

                  <label for="select-question-category" className="labelPergunta">
                    {_.capitalize(t('category'))}
                  </label>
                  <div id="select-question-category">
                    <Form.Control
                      className="selectContent"
                      as="select"
                      defaultValue={_.capitalize(t('PAGES.EDIT_QUESTION_TEMPLATE.choose_a_category'))}
                      value={data.category}
                      name="category"
                      onChange={handleChange}
                    >
                      <option>Escolha uma categoria</option>
                      {
                        DEFAULT_QUESTION_CATEGORIES.map((category, idx) => (
                          <option key={idx}>
                            { category }
                          </option>
                        ))
                      }
                    </Form.Control>
                  </div>
                </div>
              </div>
            </form>
            <div className="box-btn-modal">
              <button
                className="btn-exclude"
                onClick={() => setOpenModalConfirmDelet(true)}
              >
                Excluir pergunta
              </button>
              <button className="btn-actualise" onClick={handleButtonClick}>
                Atualizar
              </button>
            </div>
          </>
        )}
      </Dialog>

      <QuestionItem
        key={data.id}
        id={data.id}
        index={index}
        question={data.problema}
        sector={data.setor}
        onEdit={() => setOpenModalQuestion(true)}
        onDelete={() => setOpenModalConfirmDelet(true)}
      />
    </div>
  );
};
export default EditQuestion;
