import React, { useState, memo, useMemo, } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useSelector, useDispatch, } from 'react-redux';

import Container from '@material-ui/core/Container';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Notification, } from 'rsuite';

import DeleteIcon from '@material-ui/icons/Delete';
import NearMeIcon from '@material-ui/icons/NearMe';
import CheckedIcon from '@material-ui/icons/CheckCircleOutline';
import UncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

import Table from '../../components/Table';
import SearchField from '../../components/SearchField';
import DeleteDialog from '../DeleteDialog';
import ModalConfigUser from '../../pages/DashboardMaster/modalConfigUsers';

import api, { sendPasswordRecoveryEmail, } from '../../services/api';

import { checkSearch, } from '../../utils';
import { selectUsers, setUsers, } from '../../features/usersSlice';


const DEFAULT_USER = Object.freeze({
  _id: undefined,
  name: '',
  group: '',
  empresa: '',
});

const HEADERS = Object.freeze([
  {
    label: 'Nome',
    align: 'center',
  },
  {
    label: 'Grupo',
    align: 'center',
  },
  {
    label: 'Criado em',
    align: 'center',
  },
  {
    label: 'Aceitou o contrato',
    align: 'center',
  },
  {
    label: 'Configurações',
    align: 'center',
  },
  {
    label: 'Acessar',
    align: 'center',
  },
  {
    label: 'Enviar senha',
    align: 'center',
  },
  {
    label: 'Ativo',
    align: 'center',
  },
  {
    label: 'Deletar',
    align: 'center',
  },
]);


/**
 * Pane used to show users data in the admin console
 *
 * @returns Rendered pane
 */
function UsersTable() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currUser, setCurrUser] = useState(DEFAULT_USER);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
  const [openConfigModal, setOpenConfigModal] = useState(false);
  const users = useSelector(selectUsers);
  const [searchString, setSearchString] = useState('');
  const { groups, } = useSelector(state => state.groups);
  const filteredUsers = useMemo(() => {
    if (searchString.length > 0) {
      return users.filter((user) => checkSearch(user.name, searchString));
    } else {
      return users;
    }
  }, [searchString, users]);


  /**
   * Toggle user active status in API
   *
   * @param {object} user - user to toggle active state
   */
  const toggleUserActiveState = async (user) => {
    setLoading(true);

    const { _id: userId, ativo: isActive, } = user;
    try {
      const { status, } = await api.post(`/user/${userId}/change-active-state`, {
        active: !isActive,
      });

      if (status === 204) {
        Notification['success']({
          placement: 'bottomEnd',
          title: `Usuário ${isActive ? 'inativado' : 'ativado'} com sucesso!`,
        });

        const updatedUsers = _.cloneDeep(users);
        const updatedUser = updatedUsers.find(({ _id, }) => _id === userId);
        if (updatedUser != undefined) {
          updatedUser.ativo = !isActive;
        }
        dispatch(setUsers(updatedUsers));
      } else {
        Notification['warning']({
          placement: 'bottomEnd',
          title: `Não foi possível ${isActive ? 'inativar' : 'ativar'} o usuário!`,
          description: 'Tente novamente dentro de alguns instantes.',
        });
      }
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: `Falha ao ${isActive ? 'inativar' : 'ativar'} usuário!`,
        description: 'Tente novamente dentro de alguns instantes.',
      });
    }

    setLoading(false);
  }

  /**
   * Set states to open config modal
   *
   * @param {object} user - user to load
   */
  const onOpenConfigModal = (user) => {
    setCurrUser(user);
    setOpenConfigModal(true);
  }

  /**
   * Close config modal and clears user to be deleted data
   */
  const onCloseConfigModal = () => {
    setCurrUser(DEFAULT_USER);
    setOpenConfigModal(false);
  }

  /**
   * Set states to open modal to delete user
   *
   * @param {object} user - user to delete
   */
  const onDeleteUser = (user) => {
    setCurrUser(user);
    setDeleteConfirmationDialog(true);
  }

  /**
   * Send request to API to delete currUser
   */
  const deleteUser = async () => {
    setLoading(true);

    const { _id: userId, } = currUser;
    try {
      const { status, } = await api.delete(`/user/${userId}`);

      if (status === 204) {
        Notification['success']({
          title: 'Usuário deletado com sucesso',
          placement: 'bottomEnd',
        });
        dispatch(setUsers(users.filter(({ _id, }) => _id !== userId)));
      } else {
        Notification['warning']({
          title: 'Não foi possível deletar o usuário',
          placement: 'bottomEnd',
        });
      }
    } catch {
      Notification['error']({
        title: 'Um erro ocorreu e o usuário não pôde ser deletado!',
        description: 'Tente novamente em alguns instantes.',
        placement: 'bottomEnd',
      });
    }

    clearDeleteConfirmationDialog();
    setLoading(false);
  }

  /**
   * Close delete confirmation dialog and clears user to be deleted data
   */
  const clearDeleteConfirmationDialog = () => {
    setDeleteConfirmationDialog(false);
    setCurrUser(DEFAULT_USER);
  }

  /**
   * Configure local info to access system as the received user
   *
   * @param {object} user - user to represent
   */
  const accessUser = async (user) => {
    Notification['warning']({
      placement: 'bottomEnd',
      title: 'Funcionalidade não implementada!',
    });
  }

  /**
   * Send request to API to send password recovery email to user
   *
   * @param {object} user - user to send email
   */
  const sendPasswordRecoveryLinkToUser = async (user) => {
    setLoading(true);

    try {
      await sendPasswordRecoveryEmail(user.email);

      Notification['success']({
        placement: 'bottomEnd',
        title: 'E-mail para recuperação de senha enviado com sucesso!',
      });
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao enviar e-mail para recuperação de senha!',
        description: 'Tente novamente dentro de alguns instantes.',
      });
    }

    setLoading(false);
  }


  return (
    <Container
      maxWidth="xl"
      component={Paper}
    >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        paddingBottom: 15,
        paddingTop: 15,
      }}
    >
      <SearchField onChange={(value) => setSearchString(value || '')} />
    </div>

      <Table
        headers={HEADERS}
        data={filteredUsers}
        loading={loading}
        noDataMessage="Nenhum usuário encontrado"
        errorMessage="Falha ao carregar usuários"
      >
        {
          (user, props) => {
            const group = groups.find(({id}) => id === user.group);

            const Icon = user.accept_contract ? CheckedIcon : UncheckedIcon;

            return (
              <TableRow {...props}>
                <TableCell align="center">
                  { user.name }
                </TableCell>
                <TableCell align="center">
                  { ((group != undefined) && (group.name != undefined)) ? group.name : '' }
                </TableCell>
                <TableCell align="center">
                  { moment(user.createdAt).format('DD/MM/YYYY, HH:MM:ss') }
                </TableCell>
                <TableCell align="center">
                  <Icon
                    style={{
                      color: '#808080'
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => onOpenConfigModal(user)}
                  >
                    <SettingsIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() => accessUser(user)}
                    color="primary"
                    variant="outlined"
                    disableElevation
                    size="small"
                  >
                    Acessar
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => sendPasswordRecoveryLinkToUser(user)}
                  >
                    <NearMeIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => toggleUserActiveState(user)}
                  >
                    { user.ativo ? <CheckedIcon /> : <UncheckedIcon /> }
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => onDeleteUser(user)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          }
        }
      </Table>

      <ModalConfigUser
        open={openConfigModal}
        onClose={onCloseConfigModal}
        infoUsers={currUser}
      />

      <DeleteDialog
        open={deleteConfirmationDialog}
        confirmationMessage={`Tem certeza que deseja excluir o usuário ${currUser.name}?`}
        onClose={clearDeleteConfirmationDialog}
        onConfirm={deleteUser}
      />
    </Container>
  );
}

export default memo(UsersTable);
