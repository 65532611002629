import RowSolicitacoes from './RowShowSolicitacoes'
import styles from './styleChatResp.module.scss'


export default function ShowSolicitacoes({ data, companyId, token, }) {
  return (
    <table className={styles.ContainerShowSolic}>
      <thead className={styles.HeaderShowSolic}>
        <th>Protocolo</th>
        <th>Status</th>
        <th>Solicitante</th>
        <th>Assunto</th>
        <th>Data de criação</th>
      </thead>

      <tbody className={styles.bodyShowSolic}>
        {
          data.map((dados) => (
            <RowSolicitacoes
              key={dados.id}
              dados={dados}
              companyId={companyId}
              token={token}
            />
          ))
        }
      </tbody>
    </table>
  );
}
