import {listCompanySectors} from "../../services/api";
import {useSelector} from "react-redux";
import {selectCompany} from "../../features/companySlice";
import {useEffect, useState} from "react";


export default function useSector() {

  const company = useSelector(selectCompany);

  const [sectors, setSectors] = useState([]);
  const [loading, setLoading] = useState(true);

  async function initialize() {
    try {
      const { data } = await listCompanySectors(company._id);
      setSectors(data.sectors);
    } catch {
      setSectors([]);
    }
    setLoading(false);
  }

  useEffect(() => {
    initialize();
  }, []);

  return { sectors, loading };
}
