import React from 'react';
import { Button, CircularProgress, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));


export default function CircularIntegration({ loading, children, ...props }) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button
        disabled={loading}
        disableElevation
        {...props}
      >
        { children }
      </Button>
      { loading && <CircularProgress size={24} className={classes.buttonProgress} /> }
    </div>
  );
}
