import React, {useEffect} from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import {
  Box,
  TextField,
  MenuItem,
  Grid,
  IconButton,
  Chip,
  Select,
  FormControl,
  Input,
  Paper,
  Typography,
} from '@material-ui/core';
import { grey, } from '@material-ui/core/colors';

import CloseIcon from '@material-ui/icons/Close';

import { DataMappingFieldType, } from '../../data/constants';
import { getFieldType, DataMappingFieldData, } from '../../core/entities/data-mapping-field';


const ALLOWED_FIELD_TYPES = Object.freeze([
  // DataMappingFieldType.TEXT,
  // DataMappingFieldType.CHECKBOX,
  DataMappingFieldType.PURPOSE,
  DataMappingFieldType.SECTOR,
  DataMappingFieldType.PRESET,
  DataMappingFieldType.LEGAL_BASES,
  DataMappingFieldType.PROCESSING_AGENTS,
  DataMappingFieldType.COLLABORATORS,
]);


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


/**
 * @typedef FieldFilterData
 * @type {object}
 * @property {number} fieldId - field ID
 * @property {string[]} values - allowed values for the field
 */


/**
 * @param {Object} props - component props
 * @param {DataMappingFieldData[]} props.fields - available fields
 * @param {FieldFilterData[]} props.value - current component value
 * @param {(value: FieldFilterData[]) => void} props.onChange - on change callback
 */
function DataMappingFieldFilterSelector(props) {
  const { t, } = useTranslation();
  const selectedFields = React.useMemo(() => {
    return new Set(props.value.map(({ fieldId, }) => fieldId));
  }, [props.value])
  const fieldMap = React.useMemo(() => new Map(props.fields.map((f) => [f.id, f])), [props.fields]);
  const fields = React.useMemo(() => {
    return props.fields
      .filter((f) => {
        const fieldType = getFieldType(f.asType, f.typePre, f.inputType);
        return (undefined != fieldType && ALLOWED_FIELD_TYPES.includes(fieldType.value)) &&
          !selectedFields.has(f.id);
      });
  }, [props.fields, selectedFields]);


  /**
   * @param {FieldFilterData} data - current field filter item data
   * @param {number} fieldId - field ID
   */
  function onChangeField(data, fieldId) {
    const filters = _.cloneDeep(props.value);
    const item = filters.find((item) => item.fieldId == data.fieldId);
    if (undefined != item) {
      item.fieldId = fieldId;
      props.onChange(filters);
    }
  }

  function onNewItem() {
    const hasUndefinedItem = Boolean(props.value.find((item) => item.fieldId == undefined));
    if (!hasUndefinedItem) {
      props.onChange([
        ...props.value,
        {
          fieldId: undefined,
          values: [],
        },
      ]);
    }
  }

  /**
   * @param {number} fieldId - field ID
   * @param {string[]} values - field allowed values
   */
  function onChangeValues(fieldId, values) {
    const filters = _.cloneDeep(props.value);
    const item = filters.find((item) => item.fieldId == fieldId);
    if (undefined != item) {
      item.values = values
        .filter((value) => 'none' != value)
        .map((value) => `${value}`);
      props.onChange(filters);
    }
  }

  /**
   * @param {number | undefined} fieldId - filter ID
   */
  function onRemoveItem(fieldId) {
    props.onChange(props.value.filter((item) => item.fieldId != fieldId));
  }


  return (
    <Box>
      {
        0 === props.value.length ?
        <Typography
          display="block"
          variant="body2"
          color="textSecondary"
          align="center"
        >
          { t('PAGES.CUSTOM_DATA_MAPPING_REPORTS.no_filter_applied') }
        </Typography> :
        null
      }

      {
        props.value
          .map((filterItem) => {
            const filterItemField = fieldMap.get(filterItem.fieldId);
            const availableFields = undefined != filterItemField ? [...fields, filterItemField] :
              fields;

            return (
              <Paper
                key={filterItem.fieldId}
                style={{
                  marginBottom: '0.3rem',
                  padding: '0.2rem 0.5rem 0.5rem 0.5rem',
                  borderRadius: 15,
                }}
                square
                variant="outlined"
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid
                    item
                    xs={5}
                  >
                    <TextField
                      select
                      fullWidth
                      size="small"
                      id="filter-item-field-select"
                      value={filterItem.fieldId || 'none'}
                      onChange={(ev) => onChangeField(filterItem, ev.target.value)}
                      InputProps={{
                        style: {
                          color: undefined == filterItem.fieldId ? grey[500] : undefined,
                        },
                      }}
                    >
                      {
                        undefined == filterItem.fieldId ?
                        <MenuItem
                          value="none"
                          disabled
                          style={{
                            fontStyle: 'italic',
                          }}
                        >
                          { t('PAGES.CUSTOM_DATA_MAPPING_REPORTS.filtered_field') }
                        </MenuItem> :
                        null
                      }

                      {
                        availableFields
                          .map((field) => {
                            return (
                              <MenuItem
                                key={field.id}
                                value={field.id}
                              >
                                { field.name }
                              </MenuItem>
                            );
                          })
                      }
                    </TextField>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                  >
                    <FormControl
                      fullWidth
                      size="small"
                      InputProps={{
                        placeholder: '12',
                      }}
                    >
                      <Select
                        multiple
                        value={filterItem.values.length > 0 ? filterItem.values : ['none']}
                        onChange={(ev) => onChangeValues(filterItem.fieldId, ev.target.value)}
                        input={<Input />}
                        disabled={filterItem.fieldId == undefined}
                        MenuProps={MenuProps}
                      >
                        {
                          0 === filterItem.values.length ?
                          <MenuItem
                            value="none"
                            disabled
                            style={{
                              fontStyle: 'italic',
                            }}
                          >
                            { t('PAGES.CUSTOM_DATA_MAPPING_REPORTS.values') }
                          </MenuItem> :
                          null
                        }

                        {
                          undefined != filterItemField && undefined != filterItemField.options &&
                            filterItemField.options.length > 0 ?
                          filterItemField.options
                            .map((option) => {
                              return (
                                <MenuItem
                                  key={option.id}
                                  value={`${option.id}`}
                                >
                                  { option.title }
                                </MenuItem>
                              );
                            }) :
                          null
                        }
                      </Select>
                    </FormControl>
                  </Grid>

                  <IconButton
                    size="small"
                    onClick={() => onRemoveItem(filterItem.fieldId)}
                  >
                    <CloseIcon
                      style={{
                        fontSize: 20,
                      }}
                    />
                  </IconButton>
                </Grid>
              </Paper>
            );
          })
      }

      <Grid
        item
        xs={true}
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        style={{
          margin: '0.35rem 0',
        }}
      >
        <Chip
          size="small"
          color="primary"
          onClick={onNewItem}
          label={t('PAGES.CUSTOM_DATA_MAPPING_REPORTS.add_filter_item')}
        />
      </Grid>
    </Box>
  );
}


export default DataMappingFieldFilterSelector;
