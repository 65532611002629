import { useContext, } from 'react';

import { Loader, } from 'rsuite';
import { Redirect, } from 'react-router-dom';

import { Context, } from '../../context/AuthContext';


export default function RequireAuth({ children, }) {
  const { loading, authenticated, } = useContext(Context);


  return (
    <>
      {
        loading ?
        <Loader
          backdrop
          content="Carregando..."
          vertical
        /> :
        authenticated ?
        children :
        <Redirect
          exact
          to="/login"
        />
      }
    </>
  );
}
