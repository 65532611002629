import { useDispatch, useSelector, } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import DeleteIcon from '@material-ui/icons/Delete';
import { blue, red, } from '@material-ui/core/colors';
import { Notification, } from 'rsuite';

import Dropdown from '../../components/Dropdown';
import DeleteDialog from '../../components/DeleteDialog';
import RegisterImpactReportDialog from '../../components/RegisterImpactReportDialog/RegisterImpactReportDialog';
import RegisterImpactReportReviewDialog from '../../components/RegisterImpactReportReviewDialog/RegisterImpactReportReviewDialog';
import {
  selectImpactReports,
  selectCurrentReport,
  setCurrentReport,
  deleteImpactReport,
  selectIsGlobal,
} from '../../features/impactReportsSlice';
import useModal from '../../hooks/useModal';


/**
 * Impact report management page toolbar
 *
 * @returns Rendered page
 */
export default function ImpactReportToolbar(props) {
  const impactReports = useSelector(selectImpactReports);
  const currentReport = useSelector(selectCurrentReport);
  const isGlobal = useSelector(selectIsGlobal);
  const dispatch = useDispatch();

  const [registerOpen, openRegisterModal, closeRegisterModal] = useModal();
  const [newReviewOpen, openNewReviewModal, closeNewReviewModal] = useModal();
  const [deleteConfOpen, openDeleteConf, closeDeleteConf] = useModal();

  /**
   * Handle change in select impact report
   */
  async function onReportSelected(reportId) {
    dispatch(setCurrentReport(reportId));
  }

  /**
   * Send request to API to delete impact report
   */
  async function onDeleteImpactReport() {
    closeDeleteConf();

    if (currentReport == null) {
      return;
    }

    try {
      await dispatch(deleteImpactReport(currentReport.id)).unwrap();

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Relatório de impacto excluído com sucesso!',
      });
    } catch (err) {
      console.log(err);
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao excluir relatório de impacto!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }
  }

  function __openDeleteConf() {
    if (currentReport != null) {
      openDeleteConf();
    }
  }


  return (
    <>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        <Dropdown
          items={impactReports}
          value={currentReport ? currentReport.id : ''}
          setValue={onReportSelected}
          labelKey="title"
        />

        {
          currentReport != null && !isGlobal ?
          <IconButton
            aria-label="add-impact-report"
            onClick={openNewReviewModal}
          >
            <OfflinePinIcon
              style={{
                color: blue[500],
              }}
            />
          </IconButton> :
          null
        }

        {
          currentReport != null ?
          <IconButton
            aria-label="add-impact-report"
            onClick={__openDeleteConf}
          >
            <DeleteIcon
              style={{
                color: red[500],
              }}
            />
          </IconButton> :
          null
        }

        <IconButton
          aria-label="add-impact-report"
          onClick={openRegisterModal}
        >
          <AddCircleIcon />
        </IconButton>

        { props.children }
      </Grid>

      <RegisterImpactReportDialog
        open={registerOpen}
        onClose={closeRegisterModal}
      />

      <RegisterImpactReportReviewDialog
        open={newReviewOpen}
        onClose={closeNewReviewModal}
        reportId={currentReport != null ? currentReport.id : 0}
      />

      <DeleteDialog
        open={deleteConfOpen}
        confirmationMessage={`Tem certeza que deseja excluir o relatório de impacto ${currentReport != null ? currentReport.title : ''}?`}
        onClose={closeDeleteConf}
        onConfirm={onDeleteImpactReport}
      />
    </>
  );
}
