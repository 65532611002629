import _ from 'lodash';

const SEPARATOR = ', ';
const SPLIT_SEPARATOR = /[,|;]/;

/**
 * Convert mapping field, of type select, response to string
 *
 * @param {string[]} items - field response items
 *
 * @returns {string} stringified field response
 */
export function stringifyFieldResponse(items, separator=SEPARATOR) {
  return _.isArray(items) ? items.join(separator) : '';
}

/**
 * Parse field options from string to array
 *
 * @param {string} response - field response
 *
 * @returns {string[]} items in array response
 */
export function parseFieldResponse(response, separator=SPLIT_SEPARATOR) {
  return _.isString(response) && response.length > 0 ? response.split(separator) : [];
}
