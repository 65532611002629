import { createSlice } from '@reduxjs/toolkit'

export const trainingsSlice = createSlice({
  name: 'trainings',
  initialState: {
    modalCreateNewTraining: false
  },
  reducers: {
    setModalCreateNewTraining: (state, action) => {
      state.modalCreateNewTraining = action.payload
    },
  },
})

export const { setModalCreateNewTraining } =
  trainingsSlice.actions

export default trainingsSlice.reducer
