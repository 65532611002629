import React from "react";
import Icon from "../../assets/pointersWhite.svg"

export default function Pointers() {
    return (
        <img
            className="BoxIconSvg"
            src={Icon}
            width="16px"
            height="16px"
        />
    )
}