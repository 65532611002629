import { useState, } from 'react';
import { CircularProgress, } from '@material-ui/core';
import LimitedBackdrop from './LimitedBackdrop';
import AspectRatio from './AspectRatio';


export default function Image({ src, alt, ratio, ...props }) {
  const [loading, setLoading] = useState(true);


  return(
    <AspectRatio ratio={ratio} >
      <img
        src={src}
        alt={alt}
        width="100%"
        height="100%"
        onLoad={() => setLoading(false)}
        onError={() => setLoading(false)}
        {...props}
      />
      <LimitedBackdrop
        open={loading}
      >
        <CircularProgress />
      </LimitedBackdrop>
    </AspectRatio>
  );
}
