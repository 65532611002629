import React from 'react';
import { useSelector, } from 'react-redux';

import { Typography, } from '@material-ui/core';

import BaseWidget from './BaseWidget';

import { getCompanyPurposeClassification, } from '../../../services/api';

import { selectCompany, } from '../../../features/companySlice';


function PurposeReportWidget(props) {
  const company = useSelector(selectCompany);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);


  async function getData() {
    setLoading(true);
    try {
      const response = await getCompanyPurposeClassification(company._id);
      setData(response.data.classification);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    getData();
  }, []);



  return (
    <BaseWidget
      {...props}
      color="#DCE0EE"
      loading={loading}
    >
      <ul>
        {
          data
            .filter((item) => item.amount > 0)
            .map((item) => {
              return (
                <li key={item.id} >
                  <Typography
                    style={{
                      fontFamily: 'Inter',
                      color: '#54708C',
                    }}
                  >
                    { item.name }
                  </Typography>
                </li>
              );
            })
        }
      </ul>
    </BaseWidget>
  );
}

export default PurposeReportWidget;
