import React, { useEffect, useState, } from 'react';
import { Autocomplete, Grid,} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { TextField } from '@mui/material';


const useStyles = makeStyles({
  container: {
    minWidth: 300,
  },
});


/**
 * Combo box with support for search and autocomplete
 *
 * @param {Array} items - available items
 * @param {any} value - current value of the combo box
 * @param {function} setValue - callback called when the selected value is changed
 * @param {String} valueKey - key in each item to use as value
 * @param {String} labelKey - key in each item to use as label
 * @param {Object} props - props to be passed to the container
 *
 * @returns rendered combo box
 */
const ComboBox = ({ items, value, setValue, valueKey, labelKey, label, ...props }) => {
  const __valueKey = valueKey || 'id';
  const __labelKey = labelKey || 'name';

  const classes = useStyles();
  const [model, setModel] = useState(null);

  const onChange = (selected) => {
    setModel(selected);
    setValue(selected != null ? selected[__valueKey] : null);
  }

  useEffect(() => {
    const item = items.find((item) => item[__valueKey] === value);
    setModel(item || null);
  }, [value]);


  return (
    <Grid
      className={classes.container}
      {...props}
    >
      <Autocomplete
        options={items}
        getOptionLabel={(item) => item[__labelKey] || ''}
        style={{
          width: 300
        }}
        renderInput={(params) => <TextField {...params} size="small" label={label}/>}
        onChange={(ev, newValue) => onChange(newValue)}
        value={model}
      />
    </Grid>
  );
}

export default ComboBox;
