import React, { useState } from "react";
import { Divider, IconButton } from '@material-ui/core'
import { GridView, Reorder } from '@mui/icons-material';


export default function TypeTable({ value = null, onPress = null }) {

    const [selectItem, setSelectItem] = useState(value || 'LIST');

    function handlerSelectItem(type) {
        setSelectItem(type);
        if (typeof onPress === 'function') {
            onPress(type);
        }
    }

    const colorItemSelected = (type) => {
        return selectItem === type ? 'primary' : 'primary';
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <IconButton onClick={() => handlerSelectItem('LIST')}>
                    <Reorder color={selectItem === 'LIST' ? 'primary' : ''} />
                </IconButton>
                <Divider orientation="vertical" flexItem style={{ borderColor: 'rgba(0, 0, 0, 0.6)' }} />
                <IconButton onClick={() => handlerSelectItem('ITEM')}>
                    <GridView color={selectItem === 'ITEM' ? 'primary' : ''} />
                </IconButton>
            </div>
        </div>
    )
}