import React from 'react';
import { useTranslation, Trans, } from 'react-i18next';
import { useSelector, } from 'react-redux';

import { Grid, Typography, } from '@material-ui/core';

import BaseWidget from '../BaseWidget';
import MetricCard from '../components/MetricCard';

import { ACTION_STATUS_COLOR, } from '../../../../core/entities/action-plans';
import { DashboardWidgetType, } from '../../../../core/entities/dashboard-widget';

import { getCompanyActionPlanStats, } from '../../../../services/api';

import { selectCompany, } from '../../../../features/companySlice';

import styles from './styles.module.css';


function ActionPlanReportWidget(props) {
  const { t, } = useTranslation();
  const company = useSelector(selectCompany);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({
    total: 0,
    statusCount: {},
  });
  const cards = React.useMemo(() => {
    return [
      {
        value: data.statusCount.CLOSED,
        label: t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.ACTION_PLAN_REPORT}.closed_tasks`),
        color: ACTION_STATUS_COLOR.CLOSED,
      },
      {
        value: data.statusCount.IN_PROGRESS,
        label: t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.ACTION_PLAN_REPORT}.in_progress_tasks`),
        color: ACTION_STATUS_COLOR.IN_PROGRESS,
      },
      {
        value: data.statusCount.PENDING,
        label: t(`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.ACTION_PLAN_REPORT}.pending_tasks`),
        color: ACTION_STATUS_COLOR.PENDING,
      },
    ];
  }, [data]);


  async function initialize() {
    setLoading(true);
    try {
      const result = await getCompanyActionPlanStats(company._id);
      setData(result.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    initialize();
  }, []);


  return (
    <BaseWidget
      {...props}
      loading={loading}
    >
      <Typography className={styles.description} >
        <Trans
          t={t}
          i18nKey={`PAGES.DASHBOARD.WIDGET.${DashboardWidgetType.ACTION_PLAN_REPORT}.description`}
          count={data.total}
          values={{
            total: data.total,
          }}
        />
      </Typography>

      <Grid
        container
        className={styles.cardContainer}
      >
        {
          cards
            .map(({ value, label, color, }, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={4}
                  className={styles.metricCard}
                >
                  <MetricCard
                    value={value}
                    color={color}
                    label={label}
                    variant="filled"
                  />
                </Grid>
              );
            })
        }
      </Grid>
    </BaseWidget>
  );
}

export default ActionPlanReportWidget;
