import { useEffect, useState } from 'react';
import { useSelector, } from 'react-redux';
import {getQuestionsBySectorAndResponse, getQuestionsCategoriesReport} from "../../../services/api";
import {selectCompany} from "../../../features/companySlice";
import BarChart from "../../ChartComponent";
import {Bar, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {selectCompanySectors} from "../../../features/companySectorsSlice";
import {Notification} from "rsuite";
import BaseWidget from "./BaseWidget";


function StatusPerSectorWidget(props) {
  const company = useSelector(selectCompany);
  const companySectors = useSelector(selectCompanySectors);
  const [questionsBySectorAndResponse, setQuestionsBySectorAndResponse] = useState([]);
  const [loading, setLoading] = useState(false);

  async function initialize() {
    try {
      setLoading(true);
      const { data, } = await getQuestionsBySectorAndResponse(company._id);
      setQuestionsBySectorAndResponse(data);
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao carregar dados do gráfico!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    } finally {
      setLoading(false);
    }
  }

  /**
   * Use effect to initialize component data
   */
  useEffect(() => {
    initialize()
  }, []);


  const data = questionsBySectorAndResponse.map(({ sectorId, compliant, pending, total, }) => {
    const sector = companySectors.find(({ id, }) => sectorId === id);

    return {
      name: (undefined != sector) ? sector.name : '',
      'Adequado': compliant,
      'Adequação': pending,
      amt: total,
    };
  });


  return (
    <BaseWidget {...props} loading={loading}>
      <ResponsiveContainer width="100%" height="100%" maxHeight="600px">
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="Adequado"
            stackId="a"
            fill="#00C851"
          />

          <Bar
            dataKey="Adequação"
            stackId="a"
            fill="#ff4444"
          />
        </BarChart>
      </ResponsiveContainer>
    </BaseWidget>
  )
}

export default StatusPerSectorWidget;
