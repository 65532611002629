import { useState, } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { Container, Grid, CircularProgress, Typography, } from '@material-ui/core';
import { Notification, } from 'rsuite';

import CourseCard from './CourseCard';
import { CRUD_STATUSES, } from '../../utils/constants';
import { CourseModal, } from '../CourseModal';
import useModal from '../../hooks/useModal';
import ShareCourseModal from './ShareCourseModal';
import { selectLoadingCourses, getCourse, } from '../../features/courseSlice';
import history from '../../history';
import DeleteDialog from '../DeleteDialog';

import { selectCourseById, deleteCourse, } from '../../features/courseSlice';
import { selectCompany, } from '../../features/companySlice';


export default function CarouselTraining({ onlyLevel, }) {
  const company = useSelector(selectCompany);
  const [courseId, setCourseId] = useState(null);
  const [editModal, openEditModal, closeEditModal] = useModal(onEdit, clearCourse);
  const [deleteModal, openDeleteModal, closeDeleteModal] = useModal(setCourse, clearCourse);
  const [shareModal, openShareModal, closeShareModal] = useModal(setCourse, clearCourse);

  const { courses, status, } = useSelector((state) => state.courses);
  const loading = useSelector(selectLoadingCourses);
  const dispatch = useDispatch();
  const course = useSelector((state) => selectCourseById(state, courseId));


  /**
   * Clear current course
   */
  function clearCourse() {
    setCourseId(null);
  }

  /**
   * Set current course
   *
   * @param {number} courseId - course ID
   */
  function setCourse(courseId) {
    setCourseId(courseId);
  }

  /**
   * Callback called when the user clicks in the button to update course data
   *
   * @param {number} courseId - course that will be edited
   */
  function onEdit(courseId) {
    dispatch(getCourse(courseId));
    setCourse(courseId);
  }

  /**
   * Callback called when user confirms that the course should be deleted
   */
  async function onDeleteCourseConfirmation() {
    try {
      await dispatch(deleteCourse(courseId)).unwrap();
      Notification['success']({
        placement: 'bottomEnd',
        title: course != null ? `Curso ${course.title} excluído com sucesso!` :
          'Curso excluído com sucesso!',
      });

      closeDeleteModal();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao excluir curso',
        description: 'Tente novamente dentro de alguns instantes.'
      });
    }
  }

  /**
   * Callback called when the user requests to access the course page
   * @param {number} courseId - selected course ID
   */
  function onAccessCourse(courseId) {
    dispatch(getCourse(courseId));
    history.push(`/companies/${company._id}/treinamentos/curso/${courseId}`);
  }


  return (
    <Container
      disableGutters
      fullWidth
      maxWidth="xl"
    >
      {
        (loading || (0 === courses.length)) || (CRUD_STATUSES.FETCH_FAILED === status) ?
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          {
            loading ?
            <>
              <CircularProgress disableShrink />
              <Typography variant="body1" >
                Carregando...
              </Typography>
            </> :
            null
          }
          {
            (0 === courses.length) && (CRUD_STATUSES.FETCH_SUCCESSFUL === status) ?
            <Typography variant="body1" >
              Você ainda não possui cursos
            </Typography> :
            null
          }
          {
            (CRUD_STATUSES.FETCH_FAILED === status) ?
            <Typography
              variant="body1"
              color="error"
            >
              Não foi possível carregar seus cursos.
            </Typography> :
            null
          }
        </Grid> :
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="row"
          spacing={2}
        >
          {
            courses.filter(({ level, }) => (onlyLevel == null) || (onlyLevel === level)).map((data) => {
              return (
                <Grid
                  item
                  key={data.id}
                  xs={6}
                  md={3}
                  lg={2}
                >
                  <CourseCard
                    data={data}
                    onEdit={openEditModal}
                    onShare={openShareModal}
                    onDelete={openDeleteModal}
                    onAccessCourse={onAccessCourse}
                  />
                </Grid>
              )
            })
          }
        </Grid>
      }
      <CourseModal
        open={editModal}
        courseId={courseId}
        handleClose={closeEditModal}
        PaperProps={{
          style: {
            width: '600px',
            maxWidth: '95%',
          }
        }}
      />

      <ShareCourseModal
        open={shareModal}
        courseId={courseId}
        handleClose={closeShareModal}
      />

      <DeleteDialog
        open={deleteModal}
        confirmationMessage={null != course ?
          `Tem certeza que deseja excluir o curso ${course.title}?` :
          `Tem certeza que deseja excluir o curso?`
        }
        onClose={closeDeleteModal}
        onConfirm={onDeleteCourseConfirmation}
      />
    </Container>
  )
}
