import React from 'react';

import { Toggle, } from 'rsuite';

import BaseFieldLine from "./BaseFieldLine";


/**
 * It uses the same props as BaseField component
 */
export default function CheckboxLineField(props) {
  return (
    <BaseFieldLine
      {...props}
      render={(resp, onChangeResp) => {
        return (
          <Toggle
            size="md"
            checkedChildren="Sim"
            unCheckedChildren="Não"
            onChange={(checked) => onChangeResp(String(checked))}
            checked={(typeof resp === 'string') && (resp.trim() === 'true')}
            disabled={props.disabled}
          />
        );
      }}
    />
  );
}
