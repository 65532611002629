import React from "react";
import Dropdown from "../../Dropdown";

export default function SelectorMeetings({ items, value = null, setValue = () => { } }) {

	function handlerSelectItem(value) {
		if (typeof setValue === 'function') {
			setValue(value);
		}
	}

	return (
		<Dropdown
			defaultItem="Escolha uma reunião"
			items={items}
			value={value}
			labelKey="title"
			setValue={handlerSelectItem}
		/>
	);
}