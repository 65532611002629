import { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import styled from 'styled-components';

import { TablePagination, Container, Breadcrumbs, Link, Typography, Chip } from '@material-ui/core';
import { IconButton, Icon, Notification, Loader } from 'rsuite';

import DataMappingAnswerPanelContext from './context';
import DataMappingFieldColumn from './DataMappingFieldColumn';
import DataMappingIndexMenuColumn from './DataMappingIndexMenuColumn';
import Paginator from '../Paginator/Paginator';

const TableContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const IndexColumnContainer = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  z-index: 5;
`;

export const PhaseTableContainer = styled.div`
  width: 100%;
  maxWidth: 100%;
  margin-top: 1rem;
`;

export default function PhaseTable({ phase }) {
  const {
    page,
    answers,
    filters,
    setFilters,
    rowsPerPage,
    listPhaseFields,
    onCreateAnswer,
    onDeleteAnswer,
    onChangePage,
    onChangeRowsPerPage,
    allowCreation = true,
    setTemporaryList,
    temporaryAwersome,
  } = useContext(DataMappingAnswerPanelContext);

  const { t } = useTranslation();
  const [phaseFields, setPhaseFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingBT, setLoadingBT] = useState(false);
  const [temporaryFilter, setTemporaryFilter] = useState([]);

  const answersToLoad = answers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const temporaryanswersToLoad = temporaryAwersome.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  async function loadPhaseFields() {
    setLoading(true);
    try {
      const fields = await listPhaseFields(phase.id);
      setPhaseFields(fields);
    } catch (err) {
      Notification.error({
        placement: 'bottomEnd',
        title: 'Falha ao carregar informações do campo!',
      });
    }
    setLoading(false);
    setLoadingBT(false);
  }

  useEffect(() => {
    loadPhaseFields();
  }, []);

  function _onChangePage(event, newPage) {
    setLoading(true);
    onChangePage(newPage - 1);
    setTimeout(() => setLoading(false), 100);
  }

  const updateFieldOptions = (fieldId, newOption) => {
    const updatedPhaseFields = _.cloneDeep(phaseFields);
    const fieldIdx = updatedPhaseFields.findIndex(({ id }) => id === fieldId);
    if (fieldIdx > -1 && Array.isArray(updatedPhaseFields[fieldIdx].options)) {
      updatedPhaseFields[fieldIdx].options.push(newOption);
      setPhaseFields(updatedPhaseFields);
    }
  };

  const handlerSetFilter = useCallback(
    (filter) => {
      if (filter?.items?.length) {
        setFilters((prevFilters) => [...prevFilters, filter]);
        // setTemporaryFilter([]);
      }
    },
    [setFilters]
  );

  const handlerSetTemporaryFilter = useCallback(
    (filter) => {
      if (filter?.items?.length) {
        setTemporaryFilter([...filters, filter]);
        setTemporaryList([...filters, filter]);
      } else if (temporaryFilter.length) {
        setTemporaryFilter([]);
      }
    },
    [filters, setTemporaryList, temporaryFilter]
  );

  const newAnswers = temporaryFilter.length ? temporaryanswersToLoad : answersToLoad;

  return (
    <PhaseTableContainer>
      <Breadcrumbs className="mt-2" aria-label="breadcrumb">
        {filters.map((filter, idx) => (
          <Chip
            key={idx}
            label={`${filter.field}: ${filter.items.map((v) => v.title).join(', ')}`}
            onDelete={() => {
              setTemporaryFilter([]);
              const newFilters = [...filters];
              newFilters.splice(idx, 1);
              setFilters(newFilters);
            }}
          />
        ))}
      </Breadcrumbs>
      {loading ? (
        <Container
          disableGutters
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '2rem 0',
          }}
        >
          <Loader vertical content="Carregando suas respostas" />
        </Container>
      ) : (
        <TableContainer>
          <IndexColumnContainer>
            <DataMappingIndexMenuColumn
              answers={newAnswers}
              onDeleteLine={(answer) => onDeleteAnswer(answer.uid)}
              onNewLineBelow={(answer) => onCreateAnswer(parseInt(answer.ordem + 1), 'down')}
            />
          </IndexColumnContainer>
          {/* {@aqui} */}
          {phaseFields.map((field) => (
            <DataMappingFieldColumn
              key={field.id}
              phase={phase}
              field={field}
              answers={newAnswers}
              updateOptions={(newOption) => updateFieldOptions(field.id, newOption)}
              onAddFilter={handlerSetFilter}
              setTemporaryFilter={handlerSetTemporaryFilter}
            />
          ))}
        </TableContainer>
      )}
      {phaseFields[0] && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: allowCreation ? 'space-between' : 'flex-end',
          }}
        >
          <IconButton
            loading={loadingBT}
            icon={<Icon icon="plus" style={{ backgroundColor: '#54708C' }} />}
            appearance="primary"
            size="sm"
            onClick={() => onCreateAnswer(null, null)}
            style={{
              backgroundColor: '#54708C',
              display: allowCreation ? undefined : 'none',
            }}
          >
            Nova linha
          </IconButton>

          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={answers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={_onChangePage}
            onRowsPerPageChange={(ev) => {
              onChangeRowsPerPage(parseInt(ev.target.value, 10));
            }}
            labelRowsPerPage={`${_.capitalize(t('PAGES.rows_per_page'))}:`}
            labelDisplayedRows={({ from, to, count }) =>
              t('PAGES.displayed_rows', {
                to,
                from,
                count: count || to,
              })
            }
            ActionsComponent={() => (
              <Paginator page={page + 1} count={answers.length} rowsPerPage={rowsPerPage} onChange={_onChangePage} />
            )}
          />
        </div>
      )}
    </PhaseTableContainer>
  );
}
