import api from "./api";

/**
 * @param company
 * @returns {Promise<{id: string, name: string, documents: {id: number, name: string}[]}[]>}
 */
export async function listFolders(company) {
  const res = await api.get(`/company/${company._id}/folders`);
  return res.data;
}


/**
 * @param company
 * @returns {Promise<{id: string, name: string, documents: {id: number, name: string}[]}[]>}
 */
export async function createFolder(company, {name}) {
  const res = await api.post(`/company/${company._id}/folders`, {name: name});
  return res.data;
}



