import React, { useEffect } from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';
import { useDispatch, useSelector, } from 'react-redux';

import { Notification } from 'rsuite';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Container,
  Paper,
  Typography,
} from '@material-ui/core';



import DataMappingTemplate from '../DataMappingTemplatesDialog/data-mapping-template.jsx';
import DataMappingTemplateForm from '../DataMappingTemplateForm';
import LimitedBackdropLoader from '../LimitedBackdropLoader';
import TemplateToolbar from '../TemplateToolbar';

import useModal from '../../hooks/useModal';

import {
  selectTemplatesByGlobalFlag,
  selectFeatureLoading,
  selectCurrentTemplate,
  setCurrentTemplate,
  createDataMappingTemplate,
  deleteDataMappingTemplate,
  updateDataMappingTemplate,
} from '../../features/dataMappingTemplatesSlice';


export default function DataMappingTemplatePage({ open, closeModal, isGlobal = false, }) {
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const loading = useSelector(selectFeatureLoading);
  const templates = useSelector((state) => selectTemplatesByGlobalFlag(state, isGlobal));
  const selectedTemplate = useSelector(selectCurrentTemplate);
  const [formData, setFormData] = React.useState(undefined);
  const [formModalOpen, openFormModal, closeFormModal] = useModal(function onOpen(template) {
    if (undefined != template) {
      setFormData(_.cloneDeep(template));
    } else {
      setFormData(undefined);
    }
  }, function onClose() {
    setFormData(undefined);
  });


  async function onDelete(templateId) {
    try {
      await dispatch(deleteDataMappingTemplate({
        templateId,
        isGlobal,
      })).unwrap();
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DATA_MAPPING_TEMPLATES.template_successfully_deleted')),
      });
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DATA_MAPPING_TEMPLATES.failed_to_delete_template')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function onCreate(data) {
    try {
      await dispatch(createDataMappingTemplate({
        templateData: data,
        isGlobal,
      })).unwrap();
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DATA_MAPPING_TEMPLATES.template_successfully_created')),
      });
      closeFormModal();
    } catch {
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DATA_MAPPING_TEMPLATES.failed_to_create_template')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  async function onEdit(data) {
    try {
      await dispatch(updateDataMappingTemplate({
        templateData: data,
        isGlobal,
      })).unwrap();
      Notification['success']({
        placement: 'bottomEnd',
        title: _.capitalize(t('PAGES.DATA_MAPPING_TEMPLATES.template_successfully_updated')),
      });
      closeFormModal();
    } catch (err) {
      console.log(err);
      Notification['error']({
        placement: 'bottomEnd',
        title: _.capitalize(t('ERRORS.DATA_MAPPING_TEMPLATES.failed_to_update_template')),
        description: _.capitalize(t('ERRORS.try_again_later')),
      });
    }
  }

  function onSave(data) {
    undefined != data.id ? onEdit(data) : onCreate(data);
  }

  function onSelected(templateId) {
    dispatch(setCurrentTemplate(templateId));
  }

  useEffect(() => {
    if (!selectedTemplate) {
      onSelected(templates[templates.length - 1]?.id);
    }
  }, [!!selectedTemplate]);


  return (
    <>
      <Container
        maxWidth="xl"
        component={Paper}
        style={{
          padding: '1rem',
        }}
        elevation={0}
      >
        <Typography
          variant="h6"
          style={{
            color: '#4D5884',
            fontFamily: 'Inter',
            marginTop: '0.3rem',
            marginBottom: '0.8rem',
          }}
        >
          {_.capitalize(t('data_mapping_template', { count: 2, }))}
        </Typography>

        <TemplateToolbar
          items={templates}
          value={selectedTemplate}
          onEdit={openFormModal}
          onCreate={() => openFormModal()}
          onDelete={onDelete}
          onSelected={onSelected}
        />

        <DataMappingTemplate
          isGlobal={isGlobal}
          dataMappingTemplate={selectedTemplate}
        />

        <Dialog
          open={formModalOpen}
          onClose={closeFormModal}
        >
          <DialogTitle>
            {_.capitalize(t(`PAGES.DATA_MAPPING_TEMPLATES.${undefined != formData ? 'update' : 'create'}_template`))}
          </DialogTitle>
          <DialogContent>
            <DataMappingTemplateForm
              data={formData}
              onSubmit={onSave}
              onCancel={closeFormModal}
            />
          </DialogContent>
        </Dialog>
      </Container>

      <LimitedBackdropLoader open={loading} />
    </>

  );
}
