import React from 'react';

/**
 * Expected context
 *
 * @param {number} page - current page
 * @param {DataMappingPhase[]} phases - data mapping phases
 * @param {DataMappingAnswer[]} answers - answers to display
 * @param {boolean} loading - whether the loader should be shown or not
 * @param {boolean} isPublic - whether the public version should be shown or not, defaults to false
 * @param {string} token - data mapping link token, only needed if loading component in public mode
 * @param {boolean} allowCreation - whether answer creation is allowed or not, defaults to true
 * @param {number} rowsPerPage - how much rows should be displayed by page
 * @param {number} selectedPhaseIdx - the current selected page
 * @param {(position: number | null, positionType: null | string) => void} onCreateAnswer - on
 * create answer callback
 * @param {(answerUniqueId: string) => void} onDeleteAnswer - on delete answer callback
 * @param {(phaseId: number) => DataMappingField[]} listPhaseFields - function to load phase fields
 * @param {(value: number) => void} onChangePage - on change page callback
 * @param {(value: number) => void} onChangeRowsPerPage - on change rows per page callback
 * @param {(value: number) => void} onChangeSelectedPhaseIdx - on change selected phase callback
 */
const DataMappingAnswerPanelContext = React.createContext();

export default DataMappingAnswerPanelContext;
