import React from "react";
import Icon from "../../assets/ajustBlue.svg"

export default function Ajust() {
    return (
        <img
            className="BoxIconSvg"
            src={Icon}
            width="14px"
            height="14px"
        />
    )
}