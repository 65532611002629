import React, { useState, useEffect, } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch, } from 'react-redux';
import { useTranslation, } from 'react-i18next';

import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';

import useModal from '../../hooks/useModal';

import ShareLinksProgressTable from '../ShareLinksProgressTable';
import DeleteDialog from '../DeleteDialog';
import MaturityChecklistShareLinkForm from '../MaturityChecklistShareLinkForm';

import {
  createShareLink,
  deleteShareLink,
  selectLoadingShareLinks,
  selectShareLinkById,
  selectShareLinks,
  updateShareLink,
  listShareLinks,
} from '../../features/maturityChecklistShareLinksSlice';
import { selectCollaborators, selectCollaboratorsMap, setCollaboratorsModal, } from '../../features/collaboratorsSlice';
import { selectCompanySectors, } from '../../features/companySectorsSlice';
import { selectCompany, } from '../../features/companySlice';

import { resendMaturityChecklistShareLinkToCollaborator, } from '../../services/api';


export default function MaturityChecklistShareLinkDialog({ open, onClose, }) {
  const { t, } = useTranslation();
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const shareLinks = useSelector(selectShareLinks);
  const sectors = useSelector(selectCompanySectors);
  const collaboratorsMap = useSelector(selectCollaboratorsMap);
  const collaborators = useSelector(selectCollaborators);
  const loading = useSelector(selectLoadingShareLinks);
  const [formModalOpen, openFormModal, closeFormModal] = useModal(selectShareLink, selectShareLink);
  const [deleteModalOpen, openDeleteModal, closeDeleteModal] = useModal(selectShareLink, selectShareLink);
  const [currShareLinkId, setCurrShareLinkId] = React.useState(null);
  const selectedShareLink = useSelector((state) => selectShareLinkById(state, currShareLinkId));
  const [alert, setAlert] = useState({
    severity: 'success',
    message: '',
    open: false,
  });


  function selectShareLink(shareLinkId = null) {
    setCurrShareLinkId(shareLinkId);
  }

  function onCloseAlert() {
    setAlert((alert) => ({
      ...alert,
      open: false,
    }));
  }

  function onOpenAlert(message, severity) {
    setAlert({
      open: true,
      message,
      severity,
    });
  }

  async function onDeleteShareLink() {
    try {
      await dispatch(deleteShareLink(currShareLinkId)).unwrap();
      closeDeleteModal();
      onOpenAlert('PAGES.MATURITY_CHECKLIST_SHARE_LINKS.share_link_successfully_deleted', 'success');
    } catch {
      onOpenAlert('ERRORS.MATURITY_CHECKLIST_SHARE_LINKS.failed_to_delete_share_link', 'error');
    }
  }

  async function saveShareLink(data) {
    const isUpdate = null != currShareLinkId;
    try {
      if (isUpdate) {
        await dispatch(updateShareLink({
          ...data,
          id: currShareLinkId,
        })).unwrap();
        closeFormModal();
        onOpenAlert('PAGES.MATURITY_CHECKLIST_SHARE_LINKS.share_link_successfully_updated', 'success');
      } else {
        await dispatch(createShareLink(data)).unwrap();
        closeFormModal();
        onOpenAlert('PAGES.MATURITY_CHECKLIST_SHARE_LINKS.share_link_successfully_created', 'success');
      }
    } catch {
      if (isUpdate) {
        onOpenAlert('ERRORS.MATURITY_CHECKLIST_SHARE_LINKS.failed_to_update_share_link', 'error');
      } else {
        onOpenAlert('ERRORS.MATURITY_CHECKLIST_SHARE_LINKS.failed_to_create_share_link', 'error');
      }
    }
  }

  async function resendShareLinkNotification(shareLinkId) {
    try {
      await resendMaturityChecklistShareLinkToCollaborator(company._id, shareLinkId);
      onOpenAlert('PAGES.MATURITY_CHECKLIST_SHARE_LINKS.share_link_to_collaborator_resent', 'success');
    } catch {
      onOpenAlert('ERRORS.MATURITY_CHECKLIST_SHARE_LINKS.failed_to_resend_share_link_to_collaborator', 'error');
    }
  }

  function onCreateCollaborator() {
    dispatch(setCollaboratorsModal(true));
  }

  useEffect(() => {
    if (open) {
      dispatch(listShareLinks());
    }
  }, [open]);


  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        { _.capitalize(t('PAGES.MATURITY_CHECKLIST_SHARE_LINKS.share_links')) }
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => openFormModal()}
          >
            { _.capitalize(t('PAGES.MATURITY_CHECKLIST_SHARE_LINKS.new_link')) }
          </Button>
        </Grid>

        <Container
          maxWidth="xl"
          component={Paper}
          disableGutters
          style={{
            marginTop: '1rem',
          }}
        >
          <ShareLinksProgressTable
            shareLinks={shareLinks}
            loading={loading}
            collaboratorsMap={collaboratorsMap}
            onEdit={openFormModal}
            onDelete={openDeleteModal}
            onResend={resendShareLinkNotification}
          />
        </Container>

      <Dialog
        open={formModalOpen}
        onClose={closeFormModal}
        PaperProps={{
          style: {
            width: '500px',
            maxWidth: '90%',
          }
        }}
      >
        <DialogTitle>
          {
            null != currShareLinkId ?
            _.capitalize(t('PAGES.MATURITY_CHECKLIST_SHARE_LINKS.edit_share_link')) :
            _.capitalize(t('PAGES.MATURITY_CHECKLIST_SHARE_LINKS.create_share_link'))
          }
        </DialogTitle>

        <DialogContent>
          <MaturityChecklistShareLinkForm
            onSubmit={saveShareLink}
            onCancel={closeFormModal}
            data={selectedShareLink}
            sectors={sectors}
            collaborators={collaborators}
            isUpdate={null != currShareLinkId}
            onCreateCollaborator={onCreateCollaborator}
          />
        </DialogContent>
      </Dialog>

      <DeleteDialog
        open={deleteModalOpen}
        confirmationMessage={_.capitalize(t('PAGES.MATURITY_CHECKLIST_SHARE_LINKS.are_you_sure_you_want_to_delete_share_link'))}
        onClose={closeDeleteModal}
        onConfirm={onDeleteShareLink}
      />

      <Snackbar
        onClose={onCloseAlert}
        open={alert.open}
        autoHideDuration={5000}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        disableWindowBlurListener
      >
        <Alert
          elevation={6}
          onClose={onCloseAlert}
          severity={alert.severity}
        >
        {_.capitalize(t(alert.message))}
        </Alert>
      </Snackbar>
      </DialogContent>
    </Dialog>
  );
}
