import React, { createContext, useState, useContext, useEffect, CSSProperties, useRef } from "react";
import { useCookies } from "react-cookie";

const ThemeContext = createContext();

const useStylesTemplate = {
  "TEMPLATE1": {
    colors: {
      primaryColor: "rgba(28, 38, 90, 1)",
      textSecundary: "#3B66FF",
    }
  },
  "TEMPLATE2": {
    colors: {
      primaryColor: "rgba(28, 38, 90, 1)",
      secondaryColor: "rgba(59, 102, 255, 1)",
      textPrimary: "rgba(28, 38, 90, 1)",
      textSecundary: "#3B66FF",
      textBlue: "rgba(59, 102, 255, 1)",
      gray: "rgba(230, 233, 241, 1)"
    }
  }
}

//dependendo do template vai receber as configs especificas.
const STYLES_LAYOUT_MAP = Object.freeze({
  "TEMPLATE1": useStylesTemplate["TEMPLATE1"],
  "TEMPLATE2": useStylesTemplate["TEMPLATE2"]
})

const ThemeProvider = ({ children }) => {
  //verificar se esta no mobile
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const [layoutName, setLayoutName] = useState("TEMPLATE2");
  const [stylesLayout, setStylesLayout] = useState(STYLES_LAYOUT_MAP[layoutName]);
  const [navColor, setNavColor] = useState('#1C265A');
  const [cookie] = useCookies();
  const [heightToobar, setHeightToobar] = useState('0px');

  const divMenu = useRef(null);
  const divSubMenu = useRef(null);

  function defineIsMobile() {
    // setIsMobile(window.innerWidth < 576);
    if(typeof window !== 'undefined'){
      setIsMobile(window?.innerWidth < 576 || navigator?.userAgentData?.mobile || (/iPhone|iPad|iPod|Android|Windows Phone/i.test(navigator?.userAgent)));
    }
  }

  useEffect(() => {
    defineIsMobile();

    if (cookie.navColor) {
    } else if ('app.octo.legal' !== window.location.hostname) {

    }

    window.addEventListener('resize', defineIsMobile);
    return () => {
      window.removeEventListener('resize', defineIsMobile);
    }
  }, []);

  const data = {
    isMobile,
    layoutName,
    navColor,
    stylesLayout,
    divMenu,
    divSubMenu,
    heightToobar,
    setHeightToobar
  }

  return (
    <ThemeContext.Provider value={data}>
      {children}
    </ThemeContext.Provider>
  );
};

const useThemeContext = () => {
  return useContext(ThemeContext);
}

export {
  ThemeProvider,
  useThemeContext
};

