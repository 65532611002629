import React, { useState, } from "react";
import { Icon, } from 'rsuite';
import { AppBar as AppBarUi, Toolbar, ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import routesNavBar from "../../../NavBar/constants/routesConfig";
import history from "../../../../history";


export default function AppBar({
  // handleUserModalIsOpen,
  // openCollaboratorsModal,
  // openProcessingAgentsModal,
  // openCompanySectorsModal,
  // openCompanyCategoriesModal,
  // onOpenPersonalDataTypesModal,
  // onOpenSensitiveDataOptionModal,
  // adminauth,
  // HandleLogout,
  data: { ...data }
}) {
  const ARRAY_ROUTES_NAVBAR = Object.values(routesNavBar(data.companyId));
  const [selectedObjectMenu, setSelectedObjectMenu] = useState(routesNavBar(data.companyId).Workshop);

  const className = styles();

  const ItemMenu = ({ data: { icon, description, go, id, onClick, index } }) => {
    return (
      <ButtonBase onClick={onClick ? onClick : () => { history.push(go); setSelectedObjectMenu() }}>
        <div className={className.itemMenu}>
          <div className={className.contentItemMenu}>
            <Icon
              icon={icon}
              // onClick={() => setIsOpenRightMenu(!isOpenRightMenu)}
              size="lg"
              className={className.icon}
            />
          </div>
          <div style={{ minHeight: "60px" }}>
            <p className={className.textMenu}>{description}</p>

          </div>
        </div>
      </ButtonBase>
    )
  }

  const MapMenu = () => {
    return (
      ARRAY_ROUTES_NAVBAR.map((obj, index) => {
        if (obj.id == 'DPO') {
          return Object.values(obj?.subMenu).map((obj, indexSubMenu) => {
            return (
              <ItemMenu key={index+''} data={{ ...obj, index: index + indexSubMenu + "" }} />
            )
          });
        }
        return (<ItemMenu key={index+''} data={{ ...obj, index }} />)
      })
    );
  }

  return (
    <div className={className.container}>
      <div className={className.contentContainer}>
        <MapMenu />
      </div>
    </div>
  )
}

const styles = makeStyles(() => ({
  container: {
    width: "100%",
    overflow: "hidden",
    marginTop: "80px"
  },
  contentContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    overflowX: "auto",
    paddingBottom: "20px",
    '&::-webkit-scrollbar': {
      background: "transparent" /* Altura da barra de rolagem horizontal */
    },
  },
  itemMenu: {
    width: "70px",
    marginRight: "20px"
  },
  contentItemMenu: {
    height: "70px",
    width: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "34px",
    background: "rgba(219, 220, 225, 1)",
    marginBottom: "10px"
  },
  icon: {
    color: 'rgba(59, 102, 255, 1)'
  },
  textMenu: {
    textAlign: "center"
  }

}));
