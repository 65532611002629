import CircularProgress from '@material-ui/core/CircularProgress';

import LimitedBackdrop from '../LimitedBackdrop';


export default function LimitedBackdropLoader({ open, invisible, }) {
  return (
    <LimitedBackdrop open={open} invisible={invisible} >
      <CircularProgress />
    </LimitedBackdrop>
  );
}
