import React from 'react';
import _ from 'lodash';
import { useTranslation, } from 'react-i18next';

import { Grid, Typography, } from '@material-ui/core';

import Dialog from '../../../../components/Dialog';
import UploadFile from '../../../../components/UploadFile';

import FileUploaderImg from '../../../../assets/Vector.svg';
import FileDownloadImg from '../../../../assets/DowloadIcon.svg';
import useDownloadProcessingAgentsCVSTemplate from '../../../../hooks/useCases/ProcessingAgents/DownloadProcessingAgentsCVSTemplate'


function ImportExportActions({ onChange }) {
  const { t, } = useTranslation();
const { downloadProcessingAgentsCSVTemplate } = useDownloadProcessingAgentsCVSTemplate();

  return (
    <Grid
      title={_.capitalize(t('PAGES.PROCESSING_AGENTS.import_checklist'))}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="space-around"
      >
        <Grid
          item
          xs={6}
          container
          alignItems="center"
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              align="left"
              display="block"
              variant="subtitle2"
              color="textSecondary"
            >
              { _.capitalize(t('PAGES.step', { count: 1, })) }
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            direction="column"
            alignItems="center"
            onClick={() => downloadProcessingAgentsCSVTemplate()}
            style={{
              cursor: 'pointer',
            }}
          >
            <img
              src={FileDownloadImg}
              alt="Imagem de Documento"
              style={{
                marginBottom: '1rem',
                height: 44,
              }}
            />

            <Grid
              item
              xs={12}
            >
              <Typography
                align="left"
                display="block"
                style={{
                  color: 'rgb(59, 102, 255)',
                  fontWeight: 'bold',
                  fontSize: '13px',
                }}
              >
                a) { t('PAGES.PROCESSING_AGENTS.download_template') }
              </Typography>

              <Typography
                align="left"
                display="block"
                style={{
                  color: 'rgb(59, 102, 255)',
                  fontWeight: 'bold',
                  fontSize: '13px',
                }}
              >
                b) { t('PAGES.PROCESSING_AGENTS.import_checklist_guide') }
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={6}
          container
          alignItems="center"
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              align="left"
              display="block"
              variant="subtitle2"
              color="textSecondary"
            >
              { _.capitalize(t('PAGES.step', { count: 2, })) }
            </Typography>
          </Grid>

          <UploadFile
            title={t('PAGES.PROCESSING_AGENTS.select_a_file')}
            subtitle=""
            onChange={onChange}
            image={(
              <img
                src={FileUploaderImg}
                alt="Imagem de Documento"
                style={{
                  marginBottom: '1rem',
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default React.memo(ImportExportActions);
