import React from "react";
import CustomMenuFixed from ".";
import { useDispatch, useSelector } from 'react-redux'

export default function GlobalMenuFixed() {
    
    const reduxState = useSelector((state) => state.fixedMenuPages);

    return (
        <>
            {reduxState.globalMenuFixed &&
                <CustomMenuFixed itensLeft={null} />
            }
        </>
    )
}