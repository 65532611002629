import { Image, } from 'react-bootstrap';

import IlustraLGPDImg from '../../assets/ilustra.svg';


export default function PageError({ title, description, }) {
  return (
    <div className="ContainerGeral">
      <Image
        src={IlustraLGPDImg}
        className="ImageInicial"
        fluid
      />
      <div className="Container">
        <strong>
          { title }
        </strong>
        <br />
      </div>
      <div className="Container">
        <label>
          { description }
          <br />
        </label>
      </div>
    </div>
  );
}
