import React from 'react';

import Sheet from '../../components/Sheet';
import MaturityChecklist from '../Respostas/Respostas';
import MaturityReport from '../Resultado/ResultAppropriateAll';
import EditMaturityChecklist from '../EditarPerguntas';
import TemplateQuestionsPage from '../../components/TemplateQuestionsPage';

const PAGES = Object.freeze({
  editar: {
    id: 'editar',
    titleTab: "Editar",
    titlePage: "Editar",
    component: <EditMaturityChecklist />,
  },
  relatorio: {
    id: 'relatorio',
    titleTab: "Relatório",
    titlePage: "Relatório",
    component: <MaturityReport />,
  },
  responder: {
    id: 'responder',
    titleTab: "Responder",
    titlePage: "Responder",
    component: <MaturityChecklist />,
  },
});

const ARRAY_PAGES = Object.values(PAGES);

export default function MapPageDiagnosis() {

  return (
    <Sheet pages={ARRAY_PAGES} />
  );
}
