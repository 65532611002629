import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';

import api, {
  listCustomDataMappingReports,
  createCustomDataMappingReport,
  deleteCustomDataMappingReport,
} from '../services/api';
import { CRUD_STATUSES as STATUSES, } from '../utils/constants';


const initialState = {
  customReports: [],
  report: {
    id: null,
    name: 'Relatório completo',
    items: [],
    filters: [],
  },
  filterInputSelect: null,
  orderByFilter: null,
  status: STATUSES.IDLE,
};

export const fetchCustomReports = createAsyncThunk('customReports/fetchCustomReports', async (_, thunkAPI) => {
  const companyId = thunkAPI.getState().company.company._id;
  const { data, } = await listCustomDataMappingReports(companyId);
  return data;
});

export const createCustomReport = createAsyncThunk('customReports/createCustomReport', async (customReport, thunkAPI) => {
  const companyId = thunkAPI.getState().company.company._id;
  const { data, } = await createCustomDataMappingReport(companyId, {
    ...customReport,
  });
  return data;
});

export const updateCustomReport = createAsyncThunk('customReports/updateCustomReport', async (customReport) => {
  const { data, } = await api.put(`/mapeamento/relatorios/${customReport.id}`, customReport);
  return data;
});

export const deleteCustomReport = createAsyncThunk('customReports/deleteCustomReport', async (customReportId, thunkAPI) => {
  const companyId = thunkAPI.getState().company.company._id;
  const response = await deleteCustomDataMappingReport(companyId, customReportId);
  return response.data;
});

export const customMappingReportSlice = createSlice({
  name: 'customMappingReport',
  initialState,
  reducers: {
    setReport: (state, { payload: report, }) => {
      if (report != null) {
        state.report = report;
      } else {
        state.report = initialState.report;
      }
    },
    setFilterInputSelect: (state, action) => {
      state.filterInputSelect = action.payload
    },
    setFilterOrderBy: (state, action) => {
      state.orderByFilter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomReports.pending, (state) => {
      state.status = STATUSES.LOADING;
      state.report = initialState.report;
    }).addCase(fetchCustomReports.fulfilled, (state, { payload: customReports, }) => {
      state.status = STATUSES.FETCH_SUCCESSFUL;
      state.customReports = customReports;
    }).addCase(fetchCustomReports.rejected, (state) => {
      state.status = STATUSES.FETCH_FAILED;
    });

    builder.addCase(createCustomReport.pending, (state) => {
    }).addCase(createCustomReport.fulfilled, (state, { payload: customReport, }) => {
      state.customReports.push(customReport);
    }).addCase(createCustomReport.rejected, (state) => {
    });

    builder.addCase(updateCustomReport.pending, (state) => {
    }).addCase(updateCustomReport.fulfilled, (state, { payload: customReport, }) => {
      const index = state.customReports.findIndex(({ id, }) => id === customReport.id);
      if (index > -1) {
        state.customReports[index] = customReport;
      }
    }).addCase(updateCustomReport.rejected, (state) => {
    });

    builder.addCase(deleteCustomReport.pending, (state) => {
      state.status = STATUSES.LOADING;
    }).addCase(deleteCustomReport.fulfilled, (state, { payload: customReport, }) => {
      const index = state.customReports.findIndex(({ id, }) => id === customReport.id);
      if (index > -1) {
        state.customReports.splice(index, 1);
      }
    }).addCase(deleteCustomReport.rejected, (state) => {
    });
  }
})

export const { setReport, setFilterInputSelect, setFilterOrderBy } = customMappingReportSlice.actions;
export default customMappingReportSlice.reducer;
