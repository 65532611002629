import React from 'react';
import { Dialog, Container, } from '@material-ui/core';


/**
 * Dialog to show video
 *
 * @param {string} title - video title
 * @param {string} videoUrl - video URL
 * @param {boolean} open - indicates whether the dialog is open or not
 * @param {function} onClose - callback function called when the dialog is closed
 *
 * @returns rendered dialog
 */
export default function VideoCard({ videoUrl, open, onClose, }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
    >
      <Container
        maxWidth
        disableGutters
      >
        <iframe
          src={videoUrl}
          width="840"
          height="429"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Video"
        />
      </Container>
    </Dialog>
  );
}
