import { useState, useEffect, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';

import {
  Breadcrumbs,
  Typography,
  Link,
  Container,
  Paper,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ImpactReportToolbar from './ImpactReportToolbar';
import PageSelector from '../../components/PageSelector/PageSelector';
import ImpactReportReviewHistory from './ImpactReportReviewHistory';
import ImpactReportEditor from './ImpactReportEditor';
import ImpactReportProcessingAgents from './ImpactReportProcessingAgents.jsx';
import history from '../../history';
import {
  listImpactReports,
  selectFeatureStatus,
  selectCurrentReportId,
  selectImpactReports,
  setCurrentReport,
  setCurrentVersionId,
  selectCurrentReport,
} from '../../features/impactReportsSlice.js';
import { CRUD_STATUSES, } from '../../utils/constants.js';

import { ImpactReportVersion, } from '../../entities/impact-report-widget.js';
import DownloadPdfButton from "../../composable/services/GeneratePdf";
import  { useRef } from 'react';
import {selectCompany} from "../../features/companySlice";

const useStyles = makeStyles({
  title: {
    color: '#4D5884',
    fontSize: '1.4rem',
    fontFamily: 'Inter',
    marginTop: '0.3rem',
    marginBottom: '0.8rem',
  },
});

const IMPACT_REPORT_OPTIONS = Object.freeze({
  VIEW: {
    id: 'VIEW',
    label: 'Visualizar',
  },
  EDIT: {
    id: 'EDIT',
    label: 'Editar',
  },
});
const IMPACT_REPORT_OPTIONS_ARRAY = Object.values(IMPACT_REPORT_OPTIONS);


/**
 * Page used to show impact reports
 */
export default function ImpactReport() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState(IMPACT_REPORT_OPTIONS.VIEW.id);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const company = useSelector(selectCompany);
  const status = useSelector(selectFeatureStatus);
  const reportId = useSelector(selectCurrentReportId);
  const reports = useSelector(selectImpactReports);
  const impactReport = useSelector(selectCurrentReport);

  const componentToPrintInPDFRef = useRef();

  const pdfFileName = `${company.name}-ripd-Relatório-de-impacto`;

  const loading = (status !== CRUD_STATUSES.FETCH_FAILED) && (status !== CRUD_STATUSES.FETCH_SUCCESSFUL);

  /**
   * Reroute user to selected route
   *
   * @param {string} route - selected route
   */
  const go = (route) => {
    history.push(route);
  }

  /**
   * Set selected option
   *
   * @param {string} option - option ID
   */
  function onSelectedOption(option) {
    setSelectedOption(option);
  }

  useEffect(() => {
    dispatch(listImpactReports());
    return () => {
      dispatch(setCurrentReport(null));
      dispatch(setCurrentVersionId(null));
    };
  }, []);

  function onLoading(value) {
    // setIsPdfLoading(!isPdfLoading);
    setIsPdfLoading(value);
  }
  return (
    <>
      <p className={classes.title} >
        Relatório de impacto
      </p>

      {/* <ImpactReportProcessingAgents /> */}

      <ImpactReportToolbar>
        { (selectedOption === IMPACT_REPORT_OPTIONS.VIEW.id) &&
          <DownloadPdfButton
              component={componentToPrintInPDFRef}
              onLoading={onLoading}
              filename={pdfFileName}
          />
        }
      </ImpactReportToolbar>

      {
        loading || (null == reportId) || (reports.length === 0) ?
        <Container
          maxWidth="xl"
          component={Paper}
          style={{
            padding: '1rem',
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            { loading ? <CircularProgress size={24} /> : null }

            <Typography
              color="textSecondary"
              variant="body1"
              style={{
                marginTop: '0.5rem',
              }}
            >
              {
                loading ? 'Carrengando relatórios de impacto...' :
                (reports.length === 0) ? 'Você ainda não possui relatórios de impacto' :
                (null == reportId) ? 'Selecione um relatório de impacto ou crie um novo' :
                null
              }
            </Typography>
          </Grid>
        </Container> :
        <>
          {
            ImpactReportVersion.VERSION_1 == impactReport.version ?
            <ImpactReportReviewHistory /> :
            null
          }

          <PageSelector
            option={selectedOption}
            options={IMPACT_REPORT_OPTIONS_ARRAY}
            onSelectedOption={onSelectedOption}
            style={{
              margin: '2rem 0',
            }}
          />

          <div ref={componentToPrintInPDFRef}>
            <ImpactReportEditor
              readOnly={selectedOption === IMPACT_REPORT_OPTIONS.VIEW.id}
              isPrintMode={isPdfLoading}
            />
          </div>

        </>
      }

    </>
  );
}
