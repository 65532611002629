import React from 'react';

import { makeStyles,  } from '@material-ui/styles';

import EditDataMappingContext from './context';

import TabBar from './tab-bar';
import TabPanel from './tab-panel';
import PhaseFields from './phase-fields';
import DataMappingPhaseFormDialog from '../DataMappingPhaseFormDialog';

import useModal from '../../hooks/useModal';
import Tutorial from './tutorial';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function EditDataMapping({ phases, }) {
  const {
    deletePhase,
    updatePhase,
    createPhase,
  } = React.useContext(EditDataMappingContext);
  const classes = useStyles();
  const [tab, setTab] = React.useState(undefined);
  const [phaseFormModalOpen, openPhaseFormModal, closePhaseFormModal] = useModal();


  async function onCreatePhase(data) {
    const phase = await createPhase(data);
    if (undefined != phase) {
      setTab(phase.id);
    } else {
      setTab((phases[0] || {}).id);
    }
    closePhaseFormModal();
  }


  React.useEffect(() => {
    const phase = phases.find((phase) => tab == phase.id);
    if (undefined == phase) {
      setTab((phases[0] || {}).id);
    }
  }, [phases]);

  return (
    <div className={classes.root}>
      {
        (phases.length > 0 && tab) ?
        <>
          <TabBar
            tab={tab}
            phases={phases}
            onChangePhase={setTab}
            onDeletePhase={deletePhase}
            onUpdatePhase={updatePhase}
            onNewPhase={openPhaseFormModal}
          />

          {
            phases.map((phase) => {
              return (
                <div
                  key={phase.id}
                  style={{
                    width: '100%',
                  }}
                >
                  <TabPanel
                    value={tab}
                    index={phase.id}
                  >
                    <PhaseFields phase={phase} />
                  </TabPanel>
                </div>
              );
            })
          }
        </>

        :
        <Tutorial onCreate={openPhaseFormModal} />
      }

      <DataMappingPhaseFormDialog
        open={phaseFormModalOpen}
        onSubmit={onCreatePhase}
        onCancel={closePhaseFormModal}
      />
    </div>
  );
}
