import { Typography } from "@material-ui/core";

import './GlobalStyle.css';

export default function Label({ description, fontSize }) {
  return (
    <Typography>
      <strong style={{fontSize: fontSize }} className="labelCards">
        {description}
      </strong>{" "}
    </Typography>
  );
}
