export default {
  translations: {
    name: 'Nome',
    responsible: 'responsável',
    email: 'e-mail',
    password: 'senha',
    sector: 'setor',
    sector_one: 'setor',
    sector_other: 'setores',
    category: 'categoria',
    category_one: 'categoria',
    category_other: 'categorias',
    question: 'pergunta',
    action: 'ação',
    risk_consequence: 'risco consequência',
    risk: 'risco',
    save: 'Salvar',
    view: 'visualizar',
    cancel: 'Cancelar',
    confirm: 'confirmar',
    send: 'enviar',
    maturity: 'maturidade',
    observation_one: 'observação',
    observation_other: 'observações',
    probability: 'probabilidade',
    impact: 'impacto',
    checklist_one: 'checklist',
    checklist_other: 'checklists',
    consultant_one: 'consultor',
    consultant_other: 'consultores',
    logout: 'sair',
    monitoring: 'monitoramento',
    collaborator_one: 'colaborador',
    collaborator_other: 'colaboradores',
    description_one: 'descrição',
    description_other: 'descrições',
    data_mapping_template_one: 'template de mapeamento',
    data_mapping_template_other: 'templates de mapeamento',
    data_mapping: 'mapeamento de dados',
    mapping_one: 'mapeamento',
    mapping_other: 'mapeamentos',
    diagnosis_one: 'diagnóstico',
    diagnosis_other: 'diagnósticos',
    checklist_template_one: 'template de checklist',
    checklist_template_other: 'templates de checklist',
    import : 'importar',
    export : 'exportar',
    evidence_one: 'evidência',
    evidence_other: 'evidências',
    due_diligence_template_one: 'template de avaliação de terceiros',
    due_diligence_template_other: 'templates de avaliação de terceiros',
    ERRORS: {
      try_again_later: 'Tente novamente dentro de alguns instantes.',
      failed_to_download_report: 'Falha ao baixar o relatório',
      not_allowed: 'Usuário não autorizado!',
      select_a_smaller_file: 'Selecione um arquivo menor',
      ERROR_CODES: {
        INCOMPLETE_CHECKLIST_CSV_FILE_ERROR: 'O arquivo CSV está incompleto, preencha setor e categoria corretamente para todas as tarefas.',
        EMPTY_FILE_ERROR: 'O arquivo está vazio, faça o upload de um arquivo válido.',
        COMPANY_CATEGORY_REFERENCED_ERROR: 'A categoria está em uso',
        LINK_EXPIRED_ERROR: 'Link expirado',
        MAIL_SERVICE_ERROR: 'Erro no serviço de e-mail',
        SECTOR_IN_USE_ERROR: 'Setor está em uso',
        SERVER_ERROR: 'Erro do servidor',
        CHARTING_SERVICE_ERROR: 'Erro no serviço de gráficos',
        USER_IS_INACTIVE: 'Usuário está inativo',
        CSV_SERVICE_ERROR: 'Erro no serviço de CSV',
        MISSING_PARAM_ERROR: 'Parâmetro faltando',
        INVALID_EMAIL_ERROR: 'E-mail inválido',
        SHEET_SERVICE_ERROR: 'Erro no serviço de planilhas',
        EVIDENCE_SERVICE_ERROR: 'Erro no serviço de evidências',
        INVALID_ACTION_STATUS_ERROR: 'Status de ação inválido',
        NOT_GLOBAL_TEMPLATE_ERROR: 'O template não é global',
        INVALID_DATA_MAPPING_FIELD_TYPE_ERROR: 'Tipo de campo de mapeamento de dados inválido',
        SAME_NAME_FIELD_ERROR: 'Um campo com o mesmo nome já existe',
        DATA_MAPPING_FIELD_MUST_HAVE_OPTIONS_ERROR: 'Este tipo de campo de mapeamento de dados deve possuir opções de resposta',
        INVALID_FILE_ERROR: 'Arquivo inválido',
      },
      LINK_EXPIRED_ERROR: {
        title: 'esse link expirou!',
        description: 'Solicite um link válido para o seu consultor',
      },
      SECTOR_NOT_FOUND_ERROR: {
        title: 'Setor do link não encontrado!',
        description: 'Solicite um link válido para o seu consultor',
      },
      COOKIE_MANAGEMENT: {
        failed_to_create_cookie: 'falha ao criar cookie',
        failed_to_load_cookies: 'falha ao carregar cookies',
        failed_to_load_cookie_categories: 'falha ao carregar categories dos cookies',
      },
      COMPANY_CATEGORIES: {
        failed_to_load_company_categories: 'falha ao carregar categorias!',
        failed_to_create_company_category: 'falha ao criar categoria!',
        failed_to_update_company_category: 'falha ao atualizar categoria!',
        failed_to_delete_company_category: 'falha ao excluir categoria!',
        company_category_referenced_error: 'categoria está em uso no checklist de maturidade.',
      },
      QUESTION_ITEM_CARD: {
        failed_to_update_question_maturity_level: 'falha ao atualizar o nível de maturidade da tarefa',
        failed_to_register_observation: 'falha ao registrar observação',
        failed_to_delete_observation: 'falha ao excluir observação',
      },
      MATURITY_CHECKLIST_SHARE_LINKS: {
        failed_to_load_share_links: 'falha ao carregar links de compartilhamento do checklist de maturidade',
        failed_to_create_share_link: 'falha ao criar link de compartilhamento do checklist de maturidade',
        failed_to_delete_share_link: 'falha ao excluir link de compartilhamento do checklist de maturidade',
        failed_to_update_share_link: 'falha ao atualizar link de compartilhamento do checklist de maturidade',
        failed_to_resend_share_link_to_collaborator: 'falha ao reenviar o link do checklist de maturidade para o colaborador',
      },
      PUBLIC_MATURITY_CHECKLIST: {
        failed_to_load_questions: 'falha ao carregar tarefas',
        failed_to_validate_link: 'falha ao validar link',
      },
      MATURITY_CHECKLIST: {
        failed_to_import_checklist_from_csv_file: 'falha ao importar tarefas',
        failed_to_export_checklist: 'falha ao exportar tarefas',
      },
      DUE_DILIGENCE: {
        failed_to_load_due_diligence_requests: 'falha ao carregar avaliações de terceiros',
        failed_to_resend_due_diligence_request_notification: 'falha ao reenviar notificação de avaliação de terceiro',
        failed_to_load_company_due_diligence_templates: 'falha ao carregar templates de avaliação de terceiros da empresa',
        failed_to_create_due_diligence_request: 'falha ao enviar avaliação de terceiro',
        failed_to_load_template_questions: 'falha ao carregar perguntas do template de avaliação de terceiros',
        failed_to_create_template: 'falha ao criar template de avaliação de terceiros',
        failed_to_update_template: 'falha ao atualizar template de avaliação de terceiros',
        failed_to_delete_template: 'falha ao excluir template de avaliação de terceiros',
        failed_to_delete_question: 'falha ao excluir pergunta de avaliação de terceiros',
        failed_to_update_question: 'falha ao editar pergunta de avaliação de terceiros',
        failed_to_create_question: 'falha ao criar pergunta de avaliação de terceiros',
      },
      DUE_DILIGENCE_REQUEST: {
        failed_to_get_due_diligence_request: 'falha ao carregar avaliação de terceiro',
      },
      PUBLIC_DUE_DILIGENCE_REQUEST: {
        failed_to_get_due_diligence_request: 'falha ao carregar avaliação de terceiro',
        failed_to_answer_question: 'falha ao responder pergunta',
        unanswered_questions: 'perguntas pendentes!',
        answer_the_question: 'Responda a pergunta "{{position}}. {{question}}"',
        failed_to_finish_due_diligence_request: 'falha ao finalizar avaliação',
        already_finished: 'avaliação já finalizada',
      },
      SESSION_RENEWAL: {
        failed_to_renew_user_session: 'falha ao renovar sessão',
      },
      ANSWER_MAPPING: {
        failed_to_save_field_answer: 'erro ao salvar resposta',
      },
      DOCUMENT_SIGNATURE_REQUEST: {
        failed_to_load_document_signatures: 'falha ao carregar assinaturas do documento',
        failed_to_load_signature_requests: 'falha ao carregar requisições de assinatura do documento',
        failed_to_resend_document_to_collaborator: 'falha ao reenviar para documento o colaborador',
        failed_to_send_to_collaborators: 'falha ao enviar documento aos colaboradores selecionados',
        select_at_least_one_collaborator: 'selecione ao menos um colaborador',
      },
      PUBLIC_DOCUMENT_SIGNATURE_REQUEST: {
        failed_to_load_document: 'falha ao carregar documento',
        failed_to_sign_document: 'falha ao assinar documento',
      },
      IMPACT_REPORTS: {
        failed_to_save_report: 'falha ao salvar relatório',
        failed_to_download_report: 'falha ao baixar o relatório',
        v2: {
          failed_to_create_widget: 'Falha ao criar widget',
          failed_to_delete_widget: 'Falha ao excluir widget',
          failed_to_list_widgets: 'Falha ao listar widgets do relatório',
        },
      },
      CONSULTANT_MANAGEMENT: {
        failed_to_load_consultants: 'falha ao carregar consultores',
      },
      COMPANY_AREA: {
        access_denied: 'acesso negado!',
        not_allowed_to_access_company: 'vocẽ não tem permissão para acessar esta empresa',
      },
      CONSULTANT_DASHBOARD: {
        failed_to_load_companies: 'falha ao carregar empresas',
        failed_to_load_partnerships: 'falha ao carregar parceiros',
      },
      DATA_MAPPING_TEMPLATES: {
        failed_to_list_templates: 'falha ao listar templates de mapeamento',
        failed_to_create_template: 'falha ao criar template de mapeamento',
        failed_to_update_template: 'falha ao atualizar template de mapeamento',
        failed_to_delete_template: 'falha ao excluir template de mapeamento',
      },
      DATA_MAPPING: {
        failed_to_list_fields: 'falha ao listar campos de mapeamento',
        failed_to_create_field: 'falha ao criar campo do mapeamento',
        failed_to_update_field: 'falha ao atualizar campo do mapeamento',
        failed_to_delete_field: 'falha ao excluir campo do mapeamento',
        failed_to_list_phases: 'falha ao listar etapas de mapeamento',
        failed_to_create_phase: 'falha ao criar etapa do mapeamento',
        failed_to_update_phase: 'falha ao atualizar etapa do mapeamento',
        failed_to_delete_phase: 'falha ao excluir etapa do mapeamento',
        failed_to_import_template: 'falha ao importar template',
        failed_to_save_as_template: 'falha ao salvar mapeamento de dados como template',
      },
      DATA_MAPPING_REPORT: {
        failed_to_export_data_mapping: 'falha ao export mapeamento de dados',
      },
      EVIDENCE_MANAGEMENT: {
        failed_to_attach_evidence: 'falha ao anexar evidência',
        failed_to_download_evidence: 'falha ao baixar evidência',
      },
      DOCUMENT_MANAGEMENT: {
        failed_to_send_document: 'Falha ao enviar documento',
        invalid_filename: 'Nome de arquivo inválido',
      },
      CONTACT_REQUEST: {
        failed_to_send_message: 'Falha ao enviar mensagem',
        failed_to_send_email_verification: 'Falha ao enviar verificação de e-mail',
        access_not_authorized: 'Acesso não autorizado!',
      },
      CUSTOM_DATA_MAPPING_REPORTS: {
        invalid_filter: 'Filtro inválido',
        must_set_filtered_field: 'Selecione o campo filtrado em todos os filtros',
        must_set_filter_values: 'Selecione pelo menos um valor em cada filtro',
        failed_to_create_report: 'Falha ao criar relatório customizado!',
        failed_to_delete_report: 'Falha ao excluir relatório customizado!',
        failed_to_update_report: 'Falha ao atualizar relatório customizado!',
      },
      PERSONAL_DATA_TYPE_MANAGEMENT: {
        failed_to_delete: 'Falha ao excluir tipo de dado pessoal',
        failed_to_create: 'Falha ao criar tipo de dado pessoal',
        failed_to_update: 'Falha ao atualizar tipo de dado pessoal',
        failed_to_load: 'Falha ao carregar tipos de dado pessoal',
      },
      DATA_PROCESSING_TYPE_MANAGEMENT: {
        failed_to_delete: 'Falha ao excluir tipo de tratamento de dados',
        failed_to_create: 'Falha ao criar tipo de tratamento de dados',
        failed_to_update: 'Falha ao atualizar tipo de tratamento de dados',
        failed_to_load: 'Falha ao carregar tipos de tratamento de dados',
      },
      DATA_MANAGEMENT_ROLE_MANAGEMENT: {
        failed_to_delete: 'Falha ao excluir tipo de atuação',
        failed_to_create: 'Falha ao criar tipo de atuação',
        failed_to_update: 'Falha ao atualizar tipo de atuação',
        failed_to_load: 'Falha ao carregar tipos de atuação',
      },
      SENSITIVE_DATA_OPTION_MANAGEMENT: {
        failed_to_delete: 'Falha ao excluir opção de dado sensível',
        failed_to_create: 'Falha ao criar opção de dado sensível',
        failed_to_update: 'Falha ao atualizar opção de dado sensível',
        failed_to_load: 'Falha ao carregar opções de dados sensíveis',
      },
      FILE_UPLOAD: {
        invalid_file_title: 'O arquivo não é válido!',
        invalid_file_description: 'Formato não suportado ou arquivo grande demais.',
      },
    },
    PAGES: {
      attention: 'atenção',
      new_male: 'novo',
      new_female: 'nova',
      overwrite: 'sobrescrever',
      choose_a_value: 'escolha um valor',
      history: 'histórico',
      rows_per_page: 'linhas por página',
      displayed_rows: '{{from}}-{{to}} de {{count}}',
      download_report: 'baixar relatório',
      downloading_report: 'baixando relatório',
      downloading_report_description: 'O download será finalizado em instantes',
      save_as_template: 'salvar como template',
      import_template: 'importar template',
      step_one: 'passo {{count}}',
      step_other: 'passo {{count}}',
      COOKIE_MANAGEMENT: {
        classify_cookies: 'classificar cookies',
        consent_logs: 'logs de consentimento',
        new_cookie: 'novo cookie',
        create_cookie: 'criar cookie',
        cookie_successfully_created: 'cookie criado com sucesso',
      },
      COMPANY_CATEGORIES: {
        all_categories: 'todas as categorias',
        add_new_category: 'adicionar nova categoria',
        no_categories_found: 'nenhuma categoria encontrada',
        company_category_successfully_created: 'categoria criada com sucesso!',
        company_category_successfully_updated: 'categoria atualizada com sucesso!',
        company_category_successfully_deleted: 'categoria excluída com sucesso!',
      },
      NAV_BAR: {
        profile: 'perfil',
        users_management: 'usuários',
        processing_agents: 'terceiros',
        sectors_management: 'setores',
        categories_management: 'categorias',
        personal_data_types_management: 'Dados pessoais',
        collaborators: 'colaboradores',
        pdca_cycle: 'Ciclo PDCA',
        cookie_management: 'gestão de cookies',
        document_management: 'gestão de documentos',
        activity_record: 'registro de atividades',
        incident_record: 'registro de incidentes',
        risk_report: 'relatório de riscos',
        impact_report: 'relatório de impacto',
        committee_meetings: 'reuniões do comitê',
        trainings: 'treinamentos',
        due_diligence: 'avaliação de terceiros',
        sensitive_data_option_management: 'Dados sensíveis',
      },
      NAV_BAR_ADMIN: {
        companies_management: 'gestão empresas',
        admin_console: 'console master',
        commissions_management: 'Comissionamento',
      },
      MATURITY_QUESTION_FORM: {
        choose_a_sector: 'escolha um setor',
        choose_a_category: 'escolha uma categoria',
      },
      QUESTION_ITEM_CARD: {
        need_review: 'Revisar',
        compliant: 'Adequado',
        fast_action_item: 'item de ação rápida?',
        question_maturity_level_successfully_updated: 'nível de maturidade da tarefa atualizado com sucesso',
        observation_successfully_registered: 'observação registrada com sucesso',
        observation_successfully_deleted: 'observação excluída com sucesso',
        delete_observation_confirmation: 'tem certeza que deseja excluir a observação?',
      },
      EDIT_QUESTION_TEMPLATE: {
        choose_a_sector: 'escolha um setor',
        choose_a_category: 'escolha uma categoria',
      },
      CREATE_QUESTION_TEMPLATE: {
        choose_a_sector: 'escolha um setor',
        choose_a_category: 'escolha uma categoria',
      },
      MATURITY_CHECKLIST_SHARE_LINKS: {
        share_links: 'links para resposta',
        sent_at: 'enviado em',
        opened_at: 'aberto em',
        answered_at: 'respondido em',
        expires_at: 'expira em',
        no_share_links: 'nenhum link de compartilhamento encontrado',
        new_link: 'novo link',
        edit_share_link: 'editar link de compartilhamento',
        create_share_link: 'criar link de compartilhamento',
        link_validity_days: 'dias de validade do link',
        share_link_successfully_created: 'link de compartilhamento criado com sucesso',
        share_link_successfully_updated: 'link de compartilhamento atualizado com sucesso',
        share_link_successfully_deleted: 'link de compartilhamento excluído com sucesso',
        are_you_sure_you_want_to_delete_share_link: 'tem certeza que deseja excluir o link de compartilhamento?',
        share_link_to_collaborator_resent: 'link reenviado com sucesso',
      },
      MATURITY_CHECKLIST: {
        all_questions: 'todas as tarefas',
        import_checklist: 'importar tarefas',
        import_checklist_guide_title: 'Ajuda',
        download_template: 'Baixe nosso template .xlsx',
        select_a_file: 'Importe o CSV',
        checklist_successfully_imported: 'tarefas criadas com sucesso',
        import_checklist_guide: 'Insira os dados e exporte em CSV',
        checklist_successfully_exported: 'checklist exportado com sucesso',
        export_checklist: 'exportar tarefas',
      },
      ADMIN_CONSOLE: {
        personal_data_types: 'Tipos de dado pessoal',
        data_management_roles: 'Tipos de atuação',
        data_processing_types: 'Tipos de tratamento',
        sensitive_data_option: 'Dados sensíveis',
      },
      PUBLIC_MATURITY_CHECKLIST: {
        all_questions: 'todas as tarefas',
        no_questions_found: 'nenhuma pergunta encontrada',
      },
      DUE_DILIGENCE: {
        due_diligence: 'avaliação de terceiros',
        sent_at: 'enviado em',
        opened_at: 'aberto em',
        finished_at: 'finalizado em',
        score: 'score',
        no_due_diligence_requests: 'nenhuma avaliação de terceiros encontrada',
        due_diligences_sent: 'avaliações enviadas',
        templates: 'templates',
        template: 'template',
        operator: 'operador',
        due_diligence_request_notification_successfully_resent: 'notificação de avaliação de terceiro reenviada com sucesso',
        send_due_diligence: 'enviar avaliação',
        due_diligence_request_successfully_created: 'avaliação de terceiro enviada com sucesso',
        new_question: 'nova pergunta',
        new_template: 'novo template',
        are_you_sure_you_want_to_delete_question: 'Tem certeza que deseja excluir a pergunta {{question}}?',
        are_you_sure_you_want_to_delete_template: 'Tem certeza que deseja excluir o template {{template}}?',
        edit_due_diligence_template: 'editar template de avaliação de terceiros',
        create_due_diligence_template: 'criar template de avaliação de terceiros',
        template_successfully_created: 'template de avaliação de terceiros criado com sucesso',
        template_successfully_updated: 'template de avaliação de terceiros atualizado com sucesso',
        template_successfully_deleted: 'template de avaliação de terceiros excluído com sucesso',
        create_due_diligence_question: 'criar pergunta de avaliação de terceiros',
        edit_due_diligence_question: 'editar pergunta de avaliação de terceiros',
        question_successfully_deleted: 'pergunta excluída com sucesso',
        question_successfully_updated: 'pergunta editada com sucesso',
        question_successfully_created: 'pergunta criada com sucesso',
        no_due_diligence_templates_found: 'nenhum template de avaliação de terceiros encontrado',
        no_questions_found: 'nenhuma pergunta encontrada no template de avaliação de terceiros',
        resend_confirmation: 'Você tem certeza que deseja reenviar a avaliação?',
      },
      DUE_DILIGENCE_REQUEST:{
        due_diligence_request: 'avaliação de terceiro',
      },
      PUBLIC_DUE_DILIGENCE_REQUEST:{
        due_diligence_request: 'avaliação de terceiro',
        finish: 'finalizar',
        due_diligence_request_question_successfully_answered: 'pergunta da avaliação respondida com sucesso',
        due_diligence_request_successfully_finished: 'avaliação finalizada com sucesso',
        are_you_sure_you_want_to_finish_due_diligence_request: 'Tem certeza que deseja finalizar a avaliação? Não será possível fazer alterações depois que a avaliação estiver finalizada.',
      },
      SESSION_RENEWAL: {
        user_session_successfully_renewed: 'sessão renovada com sucesso',
        renew_session: 'renovar sessão',
        user_session_will_expire_soon: 'sua sessão vai expirar em breve!',
      },
      ANSWER_MAPPING: {
        field_answered: '{{field}} respondido',
      },
      DOCUMENT_SIGNATURE_REQUEST: {
        sent_at: 'enviado em',
        opened_at: 'aberto em',
        signed_at: 'assinado em',
        no_document_signature_requests: 'nenhuma requisição de assinatura do documento encontrada',
        send_to_collaborators: 'enviar para colaboradores',
        signatures: 'assinaturas',
        request_successfully_resent_to_collaborator: 'requisição reenviada com sucesso ao colaborador',
        requests_successfully_sent_to_collaborators: 'requisições enviadas com sucesso aos colaboradores',
      },
      PUBLIC_DOCUMENT_SIGNATURE_REQUEST: {
        sign: 'assinar',
        document: 'documento',
        document_not_found: 'documento não encontrado',
        document_successfully_signed: 'documento assinado com sucesso',
        are_you_sure_you_want_to_sign_document: 'Tem certeza que deseja assinar o documento?',
      },
      ACTION_PLAN: {
        uncheck_all_options: 'desmarcar opções',
        attach_to_meeting: 'Anexar a reunião?',
      },
      IMPACT_REPORTS: {
        download_report: 'baixar relatório',
        download_in_progress: 'download em andamento',
        report_will_be_available_soon: 'o download será concluído assim que o relatório for gerado',
        v2: {
          create_widget: 'Criar widget',
          select_widget: 'Selecione o widget',
          widget_successfully_created: 'Widget criado com sucesso',
          widget_successfully_deleted: 'Widget excluído com sucesso',
          delete_confirmation: 'Tem certeza que deseja excluir o widget?',
          WIDGETS: {
            TEXT: {
              label: 'Texto',
              description: '',
            },
            OBJECTIVE: {
              label: 'Objetivo',
              description: '',
            },
            SIGNATURES: {
              label: 'Assinaturas',
              description: '',
              registration_id_label: 'Matrícula/SIAPE',
            },
            IMAGE: {
              label: 'Imagem',
              description: '',
              change_image_label: 'Clique para alterar a imagem',
              upload_image_label: 'Clique aqui e selecione uma imagem',
              restrictions_warning: 'A imagem deve possuir o tamanho máximo de 1MB',
            },
            REVISION_HISTORY: {
              label: 'Histórico de revisão',
              description: '',
            },
            RISK_IDENTIFICATIONS_AND_ASSESSMENTS: {
              label: 'Identificação e avaliação de riscos',
              description: '',
            },
            RISK_TREATMENT_MEASURES: {
              label: 'Medidas para tratar os riscos',
              description: '',
            },
            MAPPING_TO_RIPD: {
              label: 'Mapeamento',
              description: '',
            },
          },
        },
      },
      COMPANY_SECTORS: {
        delete_confirmation: 'Tem certeza que deseja excluir o setor {{name}}?',
        delete_info: 'Todas as tarefas associadas ao setor ficarão sem setor definido.',
        add_new_sector: 'adicionar novo setor',
      },
      DATA_MAPPING_TEMPLATES: {
        select_a_template: 'selecione um template de mapeamento',
        template_successfully_created: 'template de mapeamento criado com sucesso',
        template_successfully_updated: 'template de mapeamento atualizado com sucesso',
        template_successfully_deleted: 'template de mapeamento excluído com sucesso',
        update_template: 'atualizar template',
        create_template: 'criar template',
      },
      DATA_MAPPING: {
        phase_successfully_created: 'etapa criada com sucesso',
        phase_successfully_updated: 'etapa atualizada com sucesso',
        phase_successfully_deleted: 'etapa excluída com sucesso',
        field_successfully_created: 'campo criado com sucesso',
        field_successfully_updated: 'campo atualizado com sucesso',
        field_successfully_deleted: 'campo excluído com sucesso',
        delete_field_confirmation: 'Tem certeza que deseja excluir o campo "{{name}}"?',
        import_warning: '$t(PAGES.DATA_MAPPING.import_warning1) $t(PAGES.DATA_MAPPING.import_warning2)',
        import_warning1: 'Ao importar o template todas as respostas do mapeamento serão',
        import_warning2: 'perdidas permanentemente.',
        template_successfully_imported: 'template importado com sucesso',
        successfully_saved_data_mapping_as_template: 'mapeamento salvo como template com sucesso',
      },
      DATA_MAPPING_REPORT: {
        export_data_mapping: 'exportar mapeamento de dados',
        exporting_data_mapping: 'exportando mapeamento de dados',
        successfully_exported_data_mapping: 'mapeamento de dados exportado com sucesso!',
      },
      EVIDENCE_MANAGEMENT: {
        attach: 'anexar',
        view: 'visualizar',
        download: 'download',
        select_evidence: 'selecione a evidência',
        attach_evidence: 'anexar evidência',
        evidence_successfully_attached: 'evidência anexada com sucesso',
        evidence_successfully_downloaded: 'evidência baixada com sucesso',
      },
      DOWNLOAD_EVIDENCE: {
        title: 'Baixando evidência!',
        subtitle: 'O download será concluído em instantes.',
      },
      COURSE_MANAGEMENT: {
        cancel_without_saving_confirmation: 'Tem certeza que deseja fechar a janela? Todos os dados não salvos serão perdidos.',
      },
      HEAT_MAP: {
        item_in_risk_status_zero: '{{count}} itens em risco {{risk}}',
        item_in_risk_status_one: '{{count}} item em risco {{risk}}',
        item_in_risk_status_other: '{{count}} itens em risco {{risk}}',
      },
      SESSION_MANAGEMENT: {
        session_expired: 'Sua sessão expirou, faça o login para continuar utilizando o Octo!',
      },
      DOCUMENT_MANAGEMENT: {
        document_successfully_sent: 'Documento enviado com sucesso!',
        send_valid_document: 'Envio um documento válido',
      },
      CONTACT_REQUEST: {
        email_verification_successfully_sent: 'Verificação de e-mail enviada com sucesso',
        email_verification_successfully_sent_description: 'Você receberá um e-mail com o código de verificação em instantes!',
      },
      CUSTOM_DATA_MAPPING_REPORTS: {
        filtered_field: 'Campo filtrado',
        values: 'Valores',
        add_filter_item: 'Adicionar filtro',
        select_report_filters: 'Selecione os filtros que deseja aplicar',
        no_filter_applied: 'Nenhum filtro aplicado',
        report_successfully_created: 'Relatório customizado criado com sucesso!',
        report_successfully_deleted: 'Relatório customizado excluído com sucesso!',
        report_successfully_updated: 'Relatório customizado atualizado com sucesso!',
        delete_confirmation: 'Tem certeza que deseja excluir o relatório personalido "{{name}}"?',
      },
      PERSONAL_DATA_TYPE_MANAGEMENT: {
        title: 'Gestão de tipos de dado pessoal',
        delete_confirmation: 'Tem certeza que deseja excluir o dado pessoal "{{name}}"?',
        add_new: 'Adicionar novo tipo de dado pessoal',
        no_items_found: 'Nenhum tipo de dado pessoal encontrado',
        all_items: 'Todos os tipos de dado pessoal',
        successfully_created: 'Tipo de dado pessoal criado com sucesso!',
        successfully_updated: 'Tipo de dado pessoal atualizado com sucesso!',
        successfully_deleted: 'Tipo de dado pessoal excluído com sucesso!',
      },
      DATA_PROCESSING_TYPE_MANAGEMENT: {
        title: 'Gestão de tipos de tratamento de dados',
        delete_confirmation: 'Tem certeza que deseja excluir o tratamento "{{name}}"?',
        add_new: 'Adicionar novo tipo de tratamento',
        no_items_found: 'Nenhum tipo de tratamento encontrado',
        all_items: 'Todos os tipos de tratamento',
        successfully_created: 'Tipo de tratamento criado com sucesso!',
        successfully_updated: 'Tipo de tratamento atualizado com sucesso!',
        successfully_deleted: 'Tipo de tratamento excluído com sucesso!',
      },
      DATA_MANAGEMENT_ROLE_MANAGEMENT: {
        title: 'Gestão de tipos de atuação',
        delete_confirmation: 'Tem certeza que deseja excluir a atuação "{{name}}"?',
        add_new: 'Adicionar novo tipo de atuação',
        no_items_found: 'Nenhum tipo de atuação encontrado',
        all_items: 'Todos os tipos de atuação',
        successfully_created: 'Tipo de atuação criado com sucesso!',
        successfully_updated: 'Tipo de atuação atualizado com sucesso!',
        successfully_deleted: 'Tipo de atuação excluído com sucesso!',
        role_percentage_one: '{{value}}% ({{count}} item)',
        role_percentage_other: '{{value}}% ({{count}} itens)',
      },
      SENSITIVE_DATA_OPTION_MANAGEMENT: {
        title: 'Gestão de opções dados sensíveis',
        delete_confirmation: 'Tem certeza que deseja excluir a opção "{{name}}"?',
        add_new: 'Adicionar nova opção de dados sensível',
        no_items_found: 'Nenhuma opção de dados sensível encontrado',
        all_items: 'Todos as opções de dados sensíveis',
        successfully_created: 'Opção de dado sensível criada com sucesso!',
        successfully_updated: 'Opção de dado sensível atualizada com sucesso!',
        successfully_deleted: 'Opção de dado sensível excluída com sucesso!',
      },
      PROCESSING_AGENTS: {
        import_checklist: 'importar tarefas',
        import_checklist_guide: 'Insira os dados e exporte em CSV',
        download_template: 'Baixe nosso template .xlsx',
        select_a_file: 'Importe o CSV',
      },
      DASHBOARD: {
        quantity: 'Quantidade',
        WIDGET: {
          COMPANY_OBJECTIVE: {
            title: 'Objetivo',
            subheader: 'Atuação',
            content: 'Potencializar a aderência da {{companyName}} à Lei Geral de Proteção de dados Pessoais (Lei Federal no. 13.709/2018) e tornar o ambiente organizacional ainda mais seguro e em conformidade com as legislações vigentes.',
            report_date: 'Data do relatório',
            project_start: 'Início do projeto',
          },
          COMPANY_PROGRESS: {
            title: 'Progresso e aderência',
          },
          RISK_REPORT: {
            title: 'Relatório de riscos',
          },
          DATA_MAPPING_REPORT: {
            title: 'Relatório de mapeamento',
            mapped_sectors: 'Setores mapeados',
            mapped_processing_agents: 'Operadores que tratam dados a pedido do controlador',
            mapped_collaborators: 'Colaboradores entrevistados',
            mapped_personal_data_types: 'Dados pessoais',
            mapped_sensitive_data_categories: 'Dados sensíveis',
            mapped_gaps: 'Gaps encontrados',
          },
          PURPOSE_REPORT: {
            title: 'Finalidade dos tratamentos realizados:',
          },
          PROCESSING_TYPE_REPORT: {
            title: 'Tipo de tratamento realizado',
          },
          PERSONAL_DATA_TYPE_REPORT: {
            title: 'Dados pessoais tratados:',
          },
          SENSITIVE_DATA_REPORT: {
            title: 'Dados Sensíveis Tratados:',
          },
          LEGAL_BASES_REPORT: {
            title: 'Hipóteses de tratamento (bases legais) identificadas',
            description: 'Alguns processos podem utilizar mais de uma base legal, a depender do tipo de tratamento',
          },
          SENSITIVE_DATA_CATEGORY_REPORT: {
            title: 'Hipóteses de tratamento (bases legais) sensíveis identificadas',
            description: 'Alguns processos podem utilizar mais de uma base legal, a depender do tipo de tratamento',
          },
          HEAT_MAP: {
            title: 'Mapa de calor',
          },
          CATEGORY_MATURITY_LEVEL_REPORT: {
            title: 'Maturidade',
          },
          ACTION_PLAN_REPORT: {
            title: 'Plano de ação',
            description_one: 'Da <strong>{{total}} ação</strong> no checklist do Plano de Ação:',
            description_other: 'Das <strong>{{total}} ações</strong> no checklist do Plano de Ação:',
            closed_tasks: 'Tarefas finalizadas',
            in_progress_tasks: 'Ações em andamento',
            pending_tasks: 'Ações não iniciadas',
          },
          DPO_CONTACT_REQUEST_REPORT: {
            title: 'Comunicação com o titular',
            total_requests: 'Contados através do canal do DPO',
            finished_requests: 'Respostas finalizadas',
            average_completion_time: 'Tempo médio em dias',
          },
          PRIVACY_CULTURE: {
            title: 'Cultura de privacidade',
            committee_meetings: 'Reuniões do comitê',
            total_trainings: 'Treinamentos na plataforma',
            total_security_incidents: 'Incidentes de segurança registrados',
          },
          PER_SECTOR_STATUS: {
            title: 'Status Por Setor',
          },
        },
      },
    },
    VALIDATION_MESSAGES: {
      required_male: 'Obrigatório',
      required_female: 'Obrigatória',
      must_be_a_valid_sector: 'Deve ser um setor válido',
      must_be_a_valid_category: 'Deve ser uma categoria válida',
      must_be_a_valid_template: 'Deve ser um template válido',
      select_at_least_one_sector: 'Selecione ao menos um setor',
      must_have_at_least_one_day_of_validity: 'Deve ter pelo menos um dia de validade',
      select_one_operator: 'Selecione um operador na tabela',
    },
    PROBABILITY_OPTIONS: {
      very_low: 'Muito baixa',
      low: 'Baixa',
      moderate: 'Moderada',
      high: 'Alta',
      very_high: 'Muito alta',
    },
    IMPACT_OPTIONS: {
      very_low: 'Muito baixo',
      low: 'Baixo',
      moderate: 'Moderado',
      high: 'Alto',
      very_high: 'Muito alto',
    },
    RISK_STATUSES: {
      VERYLOW:'Muito baixo',
      LOW: 'Baixo',
      MEDIUM: 'Moderado',
      HIGH: 'Alto',
      VERYHIGTH:'Muito alto',
      UNKNOWN: 'Não definido',
    },
    MATURITY_OPTIONS: {
      level_1: 'Nível 1 - Inicial / Ad Hoc',
      level_2: 'Nível 2 - Repetível, mas intuitivo',
      level_3: 'Nível 3 - Processos definidos',
      level_4: 'Nível 4 - Gerenciados e medidos',
      level_5: 'Nível 5 - Otimizado',
    },
    DUE_DILIGENCE_QUESTION_ANSWERS: {
      NOT_IMPLEMENTED: 'não implementado',
      PARTIALLY_IMPLEMENTED: 'parcialmente implementado',
      COMPLETELY_IMPLEMENTED: 'totalmente implementado',
      NOT_APPLICABLE: 'Não se aplica',
    },
    MAPPING_FIELD_TYPES: {
      TEXT: 'Texto',
      CHECKBOX: 'Sim ou Não',
      PURPOSE: 'Finalidade',
      SECTOR: 'Setor',
      PRESET: 'Pré-definido',
      LEGAL_BASES: 'Bases Legais',
      PROCESSING_AGENTS: 'Terceiros',
      COLLABORATORS: 'Colaboradores',
      RISK: 'Risco',
      SENSITIVE_DATA_CATEGORY: 'Bases Sensíveis',
      PROCESSING_TYPE: 'Tratamento',
      DATA_MANAGEMENT_ROLE: 'Atuação',
      PERSONAL_DATA_TYPE: 'Dados Pessoais',
      SENSITIVE_DATA_OPTION: 'Dados Sensíveis',
      RIPD: 'RIPD',
    },
    QUESTION_CHANGE_REASONS: {
      SET_AS_IN_REVIEW: '{{name}} marcou como em revisão em {{- date}}',
      SET_AS_COMPLIANT: '{{name}} marcou como adequada em {{- date}}',
    },
    ACTION_STATUSES: {
      PENDING: 'Pendente',
      IN_PROGRESS: 'Em andamento',
      CLOSED: 'Concluído',
    },
  },
};
