import { useState } from 'react'

import {
  Collapse,
  Dialog,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import MuiAlert from '@material-ui/lab/Alert'
import { Icon } from 'rsuite'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'

import moment from 'moment'
import 'moment/locale/pt-br'

import styles from './styleList.module.scss'
import { EditNotification } from '../../services/api'

function Alert(props) {
  return (
    <MuiAlert elevation={6} variant="filled" {...props} />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

export default function RowNotificacoes({
  dados,
  getData,
}) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const id = localStorage.getItem('user')

  const [nameInvited, setNameInvited] = useState(dados.name)
  const [dateOfOccurrence, setDateOfOccurrence] = useState(
    dados.data
  )
  const [timeOfOccurrence, setTimeOfOccurrence] = useState(
    dados.hora
  )
  const [localeOfOccurrence, setLocaleOfOccurrence] =
    useState(dados.loc_incidente)
  const [problemOccurred, setProblemOccurred] = useState(
    dados.problema
  )
  const [consequence, setConsequence] = useState(
    dados.efeito_incidente
  )
  const [uncovered, setUncovered] = useState(
    dados.como_descoberto
  )
  const [resolvedOccurrence, setResolvedOccurrence] =
    useState(dados.acoes_tomadas)
  const [mitigateRisck, setMitigateRisck] = useState(
    dados.acoes_preventivas
  )
  const [idNotification, setIdNotification] = useState(
    dados.id
  )

  const [openNotification, setOpenNotification] =
    useState(false)
  const [msgNotification, setMsgNotification] = useState('')
  const [severetNotification, setSeveretNotification] =
    useState('')

  //CHeckValidation

  const [validated, setValidated] = useState(false)

  const EditItem = async (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      await EditNotification(
        nameInvited,
        id,
        dateOfOccurrence,
        timeOfOccurrence,
        localeOfOccurrence,
        problemOccurred,
        consequence,
        uncovered,
        resolvedOccurrence,
        mitigateRisck,
        idNotification
      )
        .then(() => {
          setOpenNotification(true)
          setSeveretNotification('success')
          setMsgNotification(
            'Notificação salva com sucesso!'
          )
          setTimeout(() => {
            getData()
          }, 2000)
        })
        .catch((error) => {
          setOpenNotification(true)
          setMsgNotification(
            `Erro ao editar notificação!, ${error}`
          )
          setSeveretNotification('error')
          setTimeout(() => {
            setOpenNotification(false)
          }, 2000)
        })
    }

    setValidated(true)
  }

  const handleClick = () => {
    setOpen(!open)
  }
  const sliceString = (string, length) => {
    if (string.length > length) {
      return string.slice(0, length) + '...'
    }
    return string
  }

  return (
    <>
      <tr
        className={
          !open
            ? styles.RowSolicitacoes
            : styles.RowSolicitacoesSelected
        }
      >
        <td>
          <span>{dados.name}</span>
        </td>
        <td style={{ marginRight: '20%' }}>
          <span>{sliceString(dados.problema, 120)}</span>
        </td>
        <td>
          <span>
            {moment(dados.data).format('DD/MM/YYYY')}{' '}
            {dados.hora}
          </span>
        </td>
        <td onClick={() => setOpen(!open)}>
          {!open ? (
            <Icon icon="angle-down" />
          ) : (
            <Icon icon="angle-up" />
          )}
        </td>
      </tr>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Form
          noValidate
          validated={validated}
          onSubmit={EditItem}
        >
          <div className={styles.BoxFormAll}>
            <div className={styles.box3Form}>
              <InputGroup
                hasValidation
                className={styles.InputName}
              >
                <div>
                  <Form.Label>
                    Nome da pessoa que está relatando*
                  </Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setNameInvited(e.target.value)
                    }}
                    value={nameInvited}
                    type="text"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Campo obrigatório.
                  </Form.Control.Feedback>
                </div>
              </InputGroup>
              <InputGroup
                hasValidation
                className={styles.InputDate}
              >
                <div>
                  <Form.Label>Data*</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setDateOfOccurrence(e.target.value)
                    }}
                    value={moment(dateOfOccurrence).format(
                      'YYYY-MM-DD'
                    )}
                    type="date"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Campo obrigatório.
                  </Form.Control.Feedback>
                </div>
              </InputGroup>
              <InputGroup
                hasValidation
                className={styles.InputTime}
              >
                <div>
                  <Form.Label>Hora*</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setTimeOfOccurrence(e.target.value)
                    }}
                    value={timeOfOccurrence}
                    type="time"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Campo obrigatório.
                  </Form.Control.Feedback>
                </div>
              </InputGroup>
            </div>
            <InputGroup hasValidation>
              <div>
                <Form.Label>
                  Localização do incidente*
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setLocaleOfOccurrence(e.target.value)
                  }}
                  value={localeOfOccurrence}
                  type="text"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Campo obrigatório.
                </Form.Control.Feedback>
              </div>
            </InputGroup>
            <InputGroup hasValidation>
              <div>
                <Form.Label>Qual o problema*</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setProblemOccurred(e.target.value)
                  }}
                  value={problemOccurred}
                  as="textarea"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Campo obrigatório.
                </Form.Control.Feedback>
              </div>
            </InputGroup>
            <InputGroup hasValidation>
              <div>
                <Form.Label>
                  Qual o efeito do incidente?*
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setConsequence(e.target.value)
                  }}
                  value={consequence}
                  as="textarea"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Campo obrigatório.
                </Form.Control.Feedback>
              </div>
            </InputGroup>
            <InputGroup hasValidation>
              <div>
                <Form.Label>
                  Como foi descoberto?*
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setUncovered(e.target.value)
                  }}
                  value={uncovered}
                  as="textarea"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Campo obrigatório.
                </Form.Control.Feedback>
              </div>
            </InputGroup>
            <InputGroup hasValidation>
              <div>
                <Form.Label>
                  Quais ações foram tomadas?*
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setResolvedOccurrence(e.target.value)
                  }}
                  value={resolvedOccurrence}
                  as="textarea"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Campo obrigatório.
                </Form.Control.Feedback>
              </div>
            </InputGroup>
            <InputGroup hasValidation>
              <div>
                <Form.Label>
                  O que foi feito para evitar que isto
                  ocorra novamente?*
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setMitigateRisck(e.target.value)
                  }}
                  value={mitigateRisck}
                  as="textarea"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Campo obrigatório.
                </Form.Control.Feedback>
              </div>
            </InputGroup>
          </div>
          <div className={styles.boxBTNModal}>
            <button
              className={styles.BTNCancel}
              onClick={() => setOpen(!open)}
              type="button"
            >
              Cancelar
            </button>
            <button
              className={styles.BTNSubmit}
              onSubmit={EditItem}
              type="submit"
            >
              Editar
            </button>
          </div>
        </Form>
        <Dialog
          open={openNotification}
          onClose={() => {
            setOpenNotification(false)
          }}
          Backdrop={false}
          maxWidth="xs"
          fullWidth
        >
          <Alert
            style={{ margin: 20 }}
            severity={severetNotification}
          >
            {msgNotification}
          </Alert>
        </Dialog>
      </Collapse>
    </>
  )
}
