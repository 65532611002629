import React from "react";
import Icon from "../../assets/Trash.svg"

export default function Trash() {
    return (
        <img
            className="BoxIconSvg"
            src={Icon}
            width="18px"
            height="18px"
        />
    )
}