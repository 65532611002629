import {useCallback, useState} from "react";

/**
 * @template T
 * @param initialValue {T[]}
 * @param comparator {function(T, T): boolean}
 * @returns {[T[], (function(T): void)]}
 */
export default function useSet(initialValue, comparator = (item1, item2) => item1 === item2) {
  const [value, setValue] = useState(initialValue);

  /**
   *
   * @type {(function(T): void)}
   */
  const add = useCallback((newValue) => {
    const item = value.find((value) => comparator(value, newValue));

    if (item) return;

    setValue([...value, newValue]);
  }, [value]);

  /**
   *
   * @type {(function(T): void)}
   */
  const remove = useCallback((newValue) => {

    setValue(value.filter((item) => !comparator(item, newValue)));

  }, [value]);

  const toggle = useCallback((newValue) => {
    const item = value.find((value) => comparator(value, newValue));

    if (item) {
      remove(newValue);
    } else {
      add(newValue);
    }
  });

  return [value, add, remove, toggle];
}
