import React, { useState, useEffect } from 'react';
import { useSelector, } from 'react-redux';

import { showToast, } from '../../ui-lib/toast';
import 'react-toastify/dist/ReactToastify.css'
import { Icon, Message, Notification, } from 'rsuite';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core';

import {
  updateMaturityQuestion,
  deleteMaturityQuestion,
  updateMaturityQuestionPosition,
} from '../../services/api';
import MaturityQuestionForm from '../MaturityQuestionForm';
import './styles.css';
import QuestionItem from '../QuestionItem';

import { selectCompany, } from '../../features/companySlice';
import { selectCompanySectorById, } from '../../features/companySectorsSlice';


const EditQuestion = ({ data, provided, index, getData, }) => {
  const company = useSelector(selectCompany);
  const [ordem, setOrdem] = useState(data.ordem);
  const sector = useSelector((state) => selectCompanySectorById(state, data.sector_id));
  const [loading, setLoading] = useState(false);
  const [openModalQuestion, setOpenModalQuestion] = useState(false);
  const [openModalConfirmDelet, setOpenModalConfirmDelet] = useState(false);


  function handleClose() {
    setOpenModalQuestion(!true)
  }

  function handleCloseAlertDelet() {
    setOpenModalConfirmDelet(!true)
  }

  /**
   * Send request to API to update maturity question
   */
  async function updateQuestion(values) {
    setLoading(true);

    try {
      const { data: info, } = await updateMaturityQuestion(company._id,
        data.id, {
        id: data.id,
        ordem: index,
        pergunta: values.problem,
        acao: values.action,
        prioridade: data.prioridade,
        setor: values.sector,
        risco: values.risk,
        probabilidade: data.probabilidade,
        impacto: data.impacto,
        category: values.category,
      });
      getData();
      handleClose();

      Notification['success']({
        placement: 'bottomEnd',
        title: 'Tudo certo!',
        description: `${info}`,
      });
    } catch (err) {
      Notification['error']({
        placement: 'bottomEnd',
        title: 'Falha ao atualizar tarefa!',
        description: 'Tente novamente dentro de alguns instantes',
      });
    }

    setLoading(false);
  }

  async function handleButtonClickDelete() {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      await deleteMaturityQuestion(data.id);

      handleCloseAlertDelet();
      getData();
    } catch {
      showToast({
        type: 'error',
        message: 'Erro ao deletar a analise de risco',
      });
    }

    setLoading(false);
  }

  useEffect(() => {
    if (!loading && (index !== ordem)) {
      updateMaturityQuestionPosition(data.id, index).then(() => {
        setOrdem(index);
      });
    }
  }, [index, ordem]);


  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      className="boxAllQuestions"
    >
      <Dialog
        open={openModalConfirmDelet}
        onClose={handleCloseAlertDelet}
        aria-describedby="alert-dialog-description"
      >
        {loading && (
          <div className="progressDelet">
            <CircularProgress />
          </div>
        )}
        {!loading && (
          <>
            <DialogTitle
              className="boxTitleAlert"
              id="alert-dialog-title"
            >
              <Message
                showIcon
                type="error"
                description="Excluir a tarefa permanentemente?"
              />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {data.problema}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAlertDelet}>
                Cancelar
              </Button>
              <Button onClick={handleButtonClickDelete}>
                Excluir
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Dialog
        open={openModalQuestion}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        {loading && (
          <div className="progressDelet">
            <CircularProgress />
          </div>
        )}

        {!loading && (
          <>
            <div className="HeaderTitleGestao">
              <div className="itemIndexModal">
                Item {index + 1}
              </div>
              <div className="TitlePerguntaGestao">
                {data.problema}
              </div>

              <div className="boxLeftGestao">
                <div className="setorAcao">
                  { sector != undefined ? sector.name : 'N/A' }
                </div>
              </div>
              <Icon
                className="iconCloseGestao"
                onClick={handleClose}
                icon="close"
              />
            </div>

            <MaturityQuestionForm
              data={data}
              onSubmit={updateQuestion}
            />
          </>
        )}
      </Dialog>

      <QuestionItem
        index={index}
        question={data.problema}
        sector={ sector != undefined ? sector.name : 'N/A' }
        onEdit={() => {setOpenModalQuestion(true)}}
        onDelete={() => setOpenModalConfirmDelet(true)}
      />
    </div>
  )
}

export default EditQuestion;
